import { all, takeLatest, put } from 'redux-saga/effects';
import { groupCampaignsActions } from './groupCampaignsActions';
import { groupCampaignsApi } from '../../api/groupCampaigns';
import { GET_GROUPCAMPAIGNS_REQUEST } from './groupCampaignsConstants';

function* getGroupCampaignsRequestSaga(action) {
  try {
    const response = yield groupCampaignsApi.getGroupCampaigns(action.payload.companyBot);

    if (response.data) {
      yield put(groupCampaignsActions.getGroupCampaignsSuccess(response.data));
    }
  } catch (err) {}
}

function* sagas() {
  yield all([takeLatest(GET_GROUPCAMPAIGNS_REQUEST, getGroupCampaignsRequestSaga)]);
}

export const groupCampaignsSagas = sagas;
