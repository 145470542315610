import api from './baseApi';

import { tagsApiRPC } from '@connectApi/baseApi';
import {
  BotIdRequest,
  TagTypeDetailPatchRequest,
  TagTypeBulkDeleteRequest,
} from 'wabb-backend-api/build/es/tag/tag_pb';

export const tagTypesApi = {
  getTagTypes: (botId, page, searchName) => {
    return tagsApiRPC.tagTypeList({ botId, page, searchName });
  },

  getTagTypesShort: botId =>
    tagsApiRPC.tagTypeShortList(BotIdRequest.fromJson({ bot_id: botId })),

  removeTagTypes: id => tagsApiRPC.tagTypeDetailDelete({ pk: id }),

  updateTag: data =>
    tagsApiRPC.tagTypeDetailPatch(TagTypeDetailPatchRequest.fromJson(data)),

  createTagTypes: ({ name, botId, description }) =>
    tagsApiRPC.tagTypeCreate({
      bot: botId,
      name,
      description,
    }),

  bulkActionDelete: (botId, selectedOrUnselectedItemIds, selectedAllMode, searchName) => 
    tagsApiRPC.tagTypeBulkDelete(
      TagTypeBulkDeleteRequest.fromJson({
        bot_id: botId,
        selected_or_unselected_items: selectedOrUnselectedItemIds,
        search_name: searchName ? searchName : '',
        selected_all: selectedAllMode,
      }),
    ),
};
