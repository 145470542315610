import {
  ContentState, convertFromRaw, EditorState,
} from 'draft-js';
import camelcaseKeys from 'camelcase-keys';

export const setInitEditorState = (value, decorators) => {
  const camelCasedValue = camelcaseKeys(value, { deep: true });

  
  if (camelCasedValue?.styledText && Object.keys(camelCasedValue.styledText).length) {
    return EditorState.createWithContent(convertFromRaw(camelCasedValue.styledText), decorators);
  }

  if (typeof camelCasedValue === 'string') {
    return EditorState.createWithContent(ContentState.createFromText(camelCasedValue || ''), decorators);
  }

  if (camelCasedValue?.text) {
    return EditorState.createWithContent(ContentState.createFromText(camelCasedValue?.text || ''), decorators);
  }

  return EditorState.createEmpty(decorators);
};