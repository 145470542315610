import React from 'react';

export const collectQueryString = (
  searchName,
  usersSortTypes,
  subscribedSortTypes,
  filterType,
  filters,
) => {
  let query = '';
  if (searchName) query += `search_name=${searchName}&`;

  if (usersSortTypes) {
    query += `users_sort_type=${usersSortTypes}&`;
  }

  if (subscribedSortTypes) {
    query += `subscribed_sort_type=${subscribedSortTypes}&`;
  }

  if (filters.length > 0) {
    query += `filter_type=${filterType}&`;
    filters.forEach(filter => {
      query += `${filter.filterName.replaceAll(' ', '_')}__${filter.condition.replaceAll(
        ' ',
        '_',
      )}=${
        filter.value.value
          ? filter.value.value
          : filter.variableId
          ? `${filter.variableId}::${filter.value}` || ''
          : filter.value
      }&`;
    });
  }

  return query.slice(0, -1);
};
