import React from 'react';

export const CloseSmallIcon = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="Close-small">
      <g id="Vector">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M4.31311 4.31295C4.50838 4.11769 4.82496 4.11769 5.02022 4.31295L11.6869 10.9796C11.8821 11.1749 11.8821 11.4915 11.6869 11.6867C11.4916 11.882 11.175 11.882 10.9798 11.6867L4.31311 5.02006C4.11785 4.8248 4.11785 4.50821 4.31311 4.31295Z"
          fill="#101828"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M11.6869 4.31295C11.8821 4.50821 11.8821 4.8248 11.6869 5.02006L5.02022 11.6867C4.82496 11.882 4.50838 11.882 4.31311 11.6867C4.11785 11.4915 4.11785 11.1749 4.31311 10.9796L10.9798 4.31295C11.175 4.11769 11.4916 4.11769 11.6869 4.31295Z"
          fill="#101828"
        />
      </g>
    </g>
  </svg>
);
