import React from 'react';

export const NumberVariableIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="currentColor"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M2 6C2 5.72386 2.22386 5.5 2.5 5.5H13.5C13.7761 5.5 14 5.72386 14 6C14 6.27614 13.7761 6.5 13.5 6.5H2.5C2.22386 6.5 2 6.27614 2 6Z"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M2 10C2 9.72386 2.22386 9.5 2.5 9.5H13.5C13.7761 9.5 14 9.72386 14 10C14 10.2761 13.7761 10.5 13.5 10.5H2.5C2.22386 10.5 2 10.2761 2 10Z"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M10 2C10.2761 2 10.5 2.22386 10.5 2.5V13.5C10.5 13.7761 10.2761 14 10 14C9.72386 14 9.5 13.7761 9.5 13.5V2.5C9.5 2.22386 9.72386 2 10 2Z"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M6 2C6.27614 2 6.5 2.22386 6.5 2.5V13.5C6.5 13.7761 6.27614 14 6 14C5.72386 14 5.5 13.7761 5.5 13.5V2.5C5.5 2.22386 5.72386 2 6 2Z"
    />
  </svg>
);
