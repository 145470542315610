import React, { useCallback, useEffect, useState } from 'react';
import { useContextSelector } from 'use-context-selector';
import { useSelector } from 'react-redux';
import * as FileSaver from 'file-saver';
import debounce from 'lodash/debounce';

import { SadSmileForFailedDownload } from '@icons/audience/sad-smile-for-failed-download';
import { useInternationalization } from '@hooks/useTranslationHook';
import { AudienceButton } from '@pages/audience/components/audienceButton/audienceButton';
import { ModalContainer } from '../../containers/Modal/ModalContainer';
import { HelpkitInfo } from '@components/common/HelpkitInfo/HelpkitInfo';
import { CheckBox } from '@components/common';
import { getSelectedCompanySelector } from '@redux/companies/companiesSelectors';
import { getModalDataSelector } from '../../containers/Modal/modalSelectors';
import { FranchiseContext } from '../../../contexts/franchiseContext';
import { getQueryString } from '@redux/audience/audienceSelectors';
import { audienceApi } from '@api/audience';
import { filesApi } from '@api/filesApi';
import { MODALS } from '../../containers/Modal/modalConstants';

import './AudienceDownloadReport.scss';

const styles = { width: '470px' };

export const AudienceDownloadReport = () => {
  const { formatMessage } = useInternationalization();
  const { modalData } = useSelector(getModalDataSelector);

  const botId = useSelector(getSelectedCompanySelector).bot;
  const queryString = useSelector(getQueryString);
  const code = useContextSelector(FranchiseContext, ({ code }) => code);

  const [downloading, setDownloading] = useState('preparation');
  const [status, setStatus] = useState({ maxLines: 1, rowsProcessed: 0 });
  const [disableStatus, setDisableStatus] = useState(false);

  const [parametersList, setParametersList] = useState([
    { title: 'First name', checked: true, queryParamName: 'first_name' },
    { title: 'Last name', checked: true, queryParamName: 'last_name' },
    { title: 'Phone', checked: true, queryParamName: 'phone' },
    { title: 'Phone code', checked: true, queryParamName: 'ddd' },
    { title: 'Email', checked: true, queryParamName: 'email' },
    { title: 'Subscribed date', checked: true, queryParamName: 'created_at' },
    {
      title: 'Referral counts',
      checked: true,
      queryParamName: 'referral_counts',
    },
    { title: 'Tags', checked: true, queryParamName: 'tags' },
    { title: 'Sequences', checked: true, queryParamName: 'sequences' },
    { title: 'Campaigns', checked: true, queryParamName: 'ads_sources' },
    { title: 'Variables', checked: true, queryParamName: 'variables' },
  ]);

  useEffect(() => {
    setDisableStatus(
      downloading === 'downloading' || !parametersList.find(el => el.checked === true),
    );
  }, [downloading, parametersList]);

  const updateParametersList = (checked, id) => {
    setParametersList(
      parametersList.map((el, index) =>
        id === index ? { title: el.title, checked } : el,
      ),
    );
  };

  const handleSubmit = () => {
    if (downloading === 'complete') {
      filesApi.downloadPreparedFile(status.fileId).then(res => {
        const file = new File([res.data], 'audience.xlsx', {
          type: 'application/ms-excel',
          header: 'Content-Disposition: attachment; filename=campaigns.xlsx',
        });
        FileSaver.saveAs(file);
      });
      modalData.onClose();
    } else {
      setDownloading('downloading');
      audienceApi
        .downloadAudienceUsers(botId, queryString, parametersList)
        .then(res => {
          if (res.data.status === 'long-running') {
            setDownloading('long-running');
          } else {
            sendDebouncedReq(res.data.file_id, getStatusDownloading);
          }
        })
        .catch(() => setDownloading('long-running'));
    }
  };

  const getStatusDownloading = fileId => {
    downloading !== 'stop' &&
      filesApi
        .checkStatusFile(fileId)
        .then(res => {
          if (res.data.status === 'long-running') {
            setDownloading('long-running');
          } else if (res.data.status === 'complete') {
            setDownloading('complete');
            setStatus({
              maxLines: res.data.max_lines,
              rowsProcessed: res.data.rows_processed,
              fileId: res.data.file_id,
            });
          } else if (res.data.status === 'pending') {
            sendDebouncedReq(res.data.file_id, getStatusDownloading);
            setStatus({
              maxLines: res.data.max_lines,
              rowsProcessed: res.data.rows_processed - Math.floor(Math.random() * 10),
            });
          }
        })
        .catch(() => setDownloading('long-running'));
  };

  const sendDebouncedReq = useCallback(
    debounce((value, callback) => callback(value), 3000),
    [],
  );

  return (
    <ModalContainer
      modal={MODALS.downloadReport}
      onClose={() => {
        if (downloading !== 'downloading') {
          modalData.onClose();
        }
      }}
      customStyles={styles}
    >
      <div className="audience-download-report">
        <div className="audience-download-report__header">
          <div>{formatMessage('Download Report')}</div>
          <HelpkitInfo helpkitCode={'8hukxqazmbwavUoBsqYr9a'} />
        </div>
        {downloading === 'preparation' ? (
          <div className="audience-download-report__body">
            <div className="audience-download-report__body_text">
              <span>
                {formatMessage('Choose the options to be specified in the report')}
              </span>
            </div>
            <div className="audience-download-report__body_filters">
              {parametersList.map((item, index) => (
                <div className="audience-download-report__body_filters_item">
                  <CheckBox
                    checked={item.checked}
                    onChange={event => updateParametersList(event.target.checked, index)}
                  />
                  {formatMessage(item.title)}
                </div>
              ))}
            </div>
          </div>
        ) : downloading === 'downloading' || downloading === 'complete' ? (
          <div className="audience-download-report__body">
            {downloading === 'downloading' && (
              <div className="audience-download-report__body_loader" />
            )}
            <div className="audience-download-report__body_text">
              {status.rowsProcessed > 0
                ? formatMessage(
                    'We are preparing the report for download. You can close this popup when the download finishes. Open BC in new tab to use other pages. Please wait a little time. Added to the report out of contacts',
                    {
                      rowsProcessed: status.rowsProcessed,
                      maxLines: status.maxLines,
                      company: code ? formatMessage('the platform') : formatMessage('BC'),
                    },
                  )
                : formatMessage('Initial preparing ...')}
            </div>
            {/*{downloading !== 'complete' && (*/}
            {/*  <div className="audience-download-report__body_text_stop" onClick={handleCancel}>*/}
            {/*    {formatMessage('Stop preparing')}*/}
            {/*  </div>*/}
            {/*)}*/}
          </div>
        ) : (
          <div className="audience-download-report__body">
            <SadSmileForFailedDownload />
            <div className="audience-download-report__body_text">
              {formatMessage('Unfortunately, something went wrong. Try it again')}
            </div>
          </div>
        )}
        <div className="audience-download-report__footer">
          <AudienceButton
            width={320}
            height={56}
            color="blue"
            onClick={handleSubmit}
            disabled={disableStatus}
          >
            {downloading === 'preparation'
              ? formatMessage('Download Report')
              : downloading === 'downloading'
              ? formatMessage('Preparing report ...')
              : downloading === 'complete'
              ? formatMessage('Get report')
              : formatMessage('Try again')}
          </AudienceButton>
        </div>
      </div>
    </ModalContainer>
  );
};
