import { createSelector } from 'reselect';

export const getUserIdSelector = createSelector(
  [state => state.auth.userId],
  userId => userId,
);

export const getUserEmail = createSelector(
  [state => state.auth.userEmail],
  userEmail => userEmail,
);

export const getUserPassword = createSelector(
  [state => state.auth.userPassword],
  userPassword => userPassword,
);

export const getConfirmationCode = createSelector(
  [state => state.auth.confirmationCode],
  confirmationCode => confirmationCode,
);

export const getFullNameSelector = createSelector(
  [state => state.auth.fullName],
  fullName => fullName,
);

export const isAuthorizedSelector = createSelector(
  [state => state.auth],
  authReducer => !!authReducer.isAuthorized,
);

export const getIsNeedToRedirect = createSelector(
  [
    state => ({
      isNeedToRedirect: state.auth.isNeedToRedirect,
      redirectRoute: state.auth.redirectRoute,
    }),
  ],
  response => response,
);

export const getErrorMessage = createSelector(
  [state => state.auth.errorMessage],
  errorMessage => errorMessage,
);

export const userCreatedSelector = createSelector(
  [state => state.auth.userCreatedSuccess],
  userCreatedSuccess => userCreatedSuccess,
);

export const isFranchiseOwnerSelector = createSelector(
  [state => state.auth.isFranchiseOwner],
  isFranchiseOwner => isFranchiseOwner,
);

export const userTypeSelector = createSelector(
  [state => state.auth.userType],
  userType => userType,
);

export const isEmailRegistrationInProgressSelector = createSelector(
  [state => state.auth],
  authReducer => !!authReducer.isEmailRegistrationInProgress,
);

export const isResendEmailConfirmationSelector = createSelector(
  [state => state.auth.isResendEmailConfirmation],
  isResendEmailConfirmation => isResendEmailConfirmation,
);

export const getResetPasswordDataSelector = createSelector(
  [state => state.auth.resetPasswordData],
  resetPasswordData => resetPasswordData,
);

export const getTwoFaUserIdSelector = createSelector(
  [state => state.auth.twoFaUserId],
  twoFaUserId => twoFaUserId,
);

export const getSessionCodeSelector = createSelector(
  [state => state.auth.sessionCode],
  sessionCode => sessionCode,
);

export const getFranchisePermissionsData = createSelector(
  [state => state.auth.franchisePermissionsData],
  franchisePermissionsData => franchisePermissionsData,
);
