import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import classNames from 'classnames';

// components
import { BcTooltip } from '@components/common/BcTooltip/BcTooltip';
import CompanyItem from './CompanyItem/CompanyItem';
import { settingsHelper } from '@helpers/settingsHelper';
import { Routes } from '../../../../const/routes';
import FormField from '@wabb-ui-kit/FormField/FormField';
import { LoopIcon } from '@wabb-assets/svg/FormField/loop-icon';

// selectors
import {
  getCompaniesSelector,
  getSelectedCompanySelector,
} from '@redux/companies/companiesSelectors';
import {
  getIsSyncProcessSelector,
  getNotificationsSelector,
} from '@redux/notifications/notificationsSelector';

// icons
import { EscVector } from '@icons/navigation/esc-vector';
import { PlusIcon } from '@icons/plus-icon';
import companyAvatar from '../../../../wabb-assets/svg/Avatar/company.svg';
import ArrowDropdown from '../../../../wabb-assets/svg/NavigationMenu/arrowDropdown.svg';

import './NavigationMenuDropdown.scss';

export const NavigationMenuDropdown = ({
  isNavigationState,
  isCompanyMenuOpen,
  setIsCompanyMenuOpen,
}) => {
  const history = useHistory();
  const { statuses, getStatus } = settingsHelper;

  const selectedCompany = useSelector(getSelectedCompanySelector);
  const companies = useSelector(getCompaniesSelector);
  const { connectionStatus } = useSelector(getNotificationsSelector);
  const isScanned = useSelector(getIsSyncProcessSelector);

  const [serverStatus, setServerStatus] = useState('scanning');
  const [queryValue, setQueryValue] = useState('');
  const [filteredCompanies, setFilteredCompanies] = useState(companies);

  useEffect(() => {
    getStatus(connectionStatus, setServerStatus, isScanned);
  }, [connectionStatus, isScanned]);

  useEffect(() => {
    setQueryValue('');
  }, [isCompanyMenuOpen]);

  useEffect(() => {
    if (companies.length) {
      const filteredCompaniesByQuery = companies.filter(company =>
        company.name.includes(queryValue),
      );
      setFilteredCompanies(filteredCompaniesByQuery);
    }
  }, [queryValue, companies]);

  useEffect(() => {
    if (companies.length) {
      setFilteredCompanies(companies);
    }
  }, [companies]);

  const handleClickOnCompany = () => {
    if (isNavigationState) {
      setIsCompanyMenuOpen(prevState => !prevState);
    }
  };

  const getStatusConnection = () => {
    switch (serverStatus) {
      case statuses.connected:
        return (
          <BcTooltip title="WhatsApp Connected" placement="right">
            <div className="companies-dropup__company_name_connected" />
          </BcTooltip>
        );
      case statuses.loading:
      case statuses.scanning:
        return (
          <BcTooltip title="WhatsApp Disconnected" placement="right">
            <div className="companies-dropup__company_name_disconnected" />
          </BcTooltip>
        );
      default:
        return (
          <BcTooltip title="WhatsApp Disconnected" placement="right">
            <div className="companies-dropup__company_name_disconnected" />
          </BcTooltip>
        );
    }
  };

  const getSelectedCompany = () => {
    return (
      <div className="companies-dropup__container" onClick={handleClickOnCompany}>
        <div className="companies-dropup__company">
          <div className="companies-dropup__company_avatar_container">
            <img
              src={!!selectedCompany?.avatar ? selectedCompany?.avatar : companyAvatar}
              className="companies-dropup__company_avatar"
              alt=""
            />
            {!isNavigationState && (
              <div className="companies-dropup__company_indicator" />
            )}
          </div>
          <div
            className={classNames(
              'companies-dropup__company_description',
              isNavigationState ? 'bottom-open' : 'bottom-close',
            )}
          >
            <div className="companies-dropup__company_name">
              <div className="companies-dropup__company_name_name_selected">
                {selectedCompany?.name}
              </div>
              {getStatusConnection()}
            </div>
            <div className="companies-dropup__company_id">
              ID:
              {selectedCompany?.id}
            </div>
          </div>
        </div>
        <div className="companies-dropup__icon">
          <img
            src={ArrowDropdown}
            style={{
              transform: isCompanyMenuOpen ? 'rotate(0)' : 'rotate(-90deg)',
            }}
          />
        </div>
      </div>
    );
  };

  return (
    <>
      {!!companies.length && (
        <>
          {getSelectedCompany()}
          <div
            className={classNames(
              isNavigationState
                ? 'companies-dropup__element'
                : 'companies-dropup__element_close-menu',
              isCompanyMenuOpen
                ? isNavigationState
                  ? 'active-company-menu'
                  : 'active-company-close-menu'
                : '',
            )}
          >
            <div className="companies-dropup__element_title-container">
              <span className="companies-dropup__element_title-container_title">
                My Companies
              </span>
              <div
                className="companies-dropup__element_title-container_icon"
                onClick={handleClickOnCompany}
              >
                <EscVector />
              </div>
            </div>
            <div className="companies-dropup__companies-list">
              <div className="companies-dropup__companies-list_search_container">
                <FormField
                  placeholder="Search"
                  value={queryValue}
                  className="companies-dropup__companies-list_search"
                  onChange={event => {
                    setQueryValue(event.target.value);
                  }}
                  icon={<LoopIcon color="white" />}
                  onCrossClick={() => setQueryValue('')}
                  width={240}
                />
              </div>
            </div>
            <div className="companies-dropup__companies-list-2">
              <div className="companies-dropup__companies-list_companies_container">
                {filteredCompanies.map(filteredCompany => (
                  <CompanyItem key={filteredCompany.id} company={filteredCompany} />
                ))}
              </div>
            </div>
            <div className='add-company-center'>
              {/* <div
                className="companies-dropup__add-company"
                onClick={() => history.push(Routes.AddYourCompanyRoute)}
              >
                <div className="companies-dropup__add-company_text">Add new сompany</div>
                <div className="companies-dropup__add-company_plus">
                  <PlusIcon color="#7A5AF8" />
                </div>
              </div> */}
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default NavigationMenuDropdown;
