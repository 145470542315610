import { combineReducers } from 'redux';
import { modalReducer } from '@components/containers/Modal/modalReducer';
import { authReducer } from './auth/authReducer';
import { constructorReducer } from './constructor/constructorReducer';
import { audienceReducer } from './audience/audienceReducer';
import { chatReducer } from './liveChat/chatReducer';
import { notificationsReducer } from './notifications/notificationsReducer';
import { companiesReducer } from './companies/companiesReducer';
// import { flowsReducer } from './flows/flowsReducer';
import { dashboardReducer } from './dashboard/dashboardReducer';
import { variablesReducer } from './variables/variablesReducer';
import { tagTypesReducer } from './tagTypes/tagTypesReducer';
import { notificationReducer } from './notificationWindow/notificationWindowReducer';
import { integrationEventsReducer } from './integrationEvents/integrationEventsReducer';
import { managersReducer } from './managers/managersReducer';
import { addTemplateReducer } from './addTemplate/addTemplatesReducer';
import { broadCastReducer } from './broadcast/broadcastReducer';
import { customFieldsReducer } from './customFields/customFieldsReducer';
import { loaderReducer } from './loader/loaderReducer';
import { groupCampaignsReducer } from './groupCampaigns/groupCampaignsReducer';
import { groupsReducer } from './groups/groupsReducer';
import { bcSplitTableReducer } from './bcSplitTable/bcSplitTableReducer';
import { webhooksReducer } from './webhooks/webhooksReducer';
import { navigationMenuReducer } from './navigationMenu/navigationMenuReducer';

const appReducer = combineReducers({
  auth: authReducer,
  constructorWidget: constructorReducer,
  audience: audienceReducer,
  chats: chatReducer,
  notifications: notificationsReducer,
  modal: modalReducer,
  companies: companiesReducer,
  // flows: flowsReducer,
  dashboard: dashboardReducer,
  variables: variablesReducer,
  tagTypes: tagTypesReducer,
  notification: notificationReducer,
  integrationEvents: integrationEventsReducer,
  managersPermissions: managersReducer,
  broadCast: broadCastReducer,
  addTemplate: addTemplateReducer,
  groupCampaigns: groupCampaignsReducer,
  groups: groupsReducer,
  customFields: customFieldsReducer,
  loader: loaderReducer,
  bcSplitTable: bcSplitTableReducer,
  webhooks: webhooksReducer,
  navigationMenu: navigationMenuReducer,
});

export const rootReducer = (state, action) => {
  if (action.type === 'LOGOUT') {
    state = undefined;
  }

  return appReducer(state, action);
};
