import React from 'react';
import useMediaQuery from '@mui/material/useMediaQuery';

import { BcToastsList } from '@components/common/BcToast/BcToastsList';

import './NotificationContainer.scss';

export const NotificationContainer = () => {
  const isSmallScreen = useMediaQuery('(max-width:1000px)');

  return (
    <div
      className={
        !isSmallScreen ? 'notification-container' : 'notification-container-small'
      }
    >
      <BcToastsList />
    </div>
  );
};
