import { useMemo } from 'react';

interface Tag {
  [key: string]: any;
}

const useFilteredOptions = (options: Tag[], searchTerm: string, labelName: string) => {
  return useMemo(() => {
    return options.filter(option =>
      option[labelName].toLowerCase().includes(searchTerm.toLowerCase()),
    );
  }, [options, searchTerm, labelName]);
};

export default useFilteredOptions;
