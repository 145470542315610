import {
  ADD_OR_UPDATE_REPORT_CUSTOM_FIELDS_SUCCESS,
  UPDATE_DOWNLOAD_STATUS_REPORT_BOT_FIELDS,
} from './customFieldsConstants';

const initialState = {
  downloadCompleteCustomFields: true,
  maxLines: 1,
  rowsProcessed: 0,
};

export const customFieldsReducer = (state = initialState, action) => {
  switch (action.type) {
    case ADD_OR_UPDATE_REPORT_CUSTOM_FIELDS_SUCCESS:
      return {
        ...state,
        downloadCompleteCustomFields: action.payload,
      };
    case UPDATE_DOWNLOAD_STATUS_REPORT_BOT_FIELDS:
      return {
        ...state,
        maxLines: action.payload.maxLines,
        rowsProcessed: action.payload.rowsProcessed,
      };
    default:
      return state;
  }
};
