export const GET_FLOW_DETAILS_REQUEST = 'GET_FLOW_DETAILS_REQUEST';
export const GET_FLOW_DETAILS_SUCCESS = 'GET_FLOW_DETAILS_SUCCESS';
export const GET_FLOW_DETAILS_FAILURE = 'GET_FLOW_DETAILS_FAILURE';
export const UPDATE_BLOCK_COORDINATES_REQUEST = 'UPDATE_BLOCK_COORDINATES_REQUEST';
export const UPDATE_BLOCK_COORDINATES_SUCCESS = 'UPDATE_BLOCK_COORDINATES_SUCCESS';
export const UPDATE_BLOCK_COORDINATES_FAILURE = 'UPDATE_BLOCK_COORDINATES_FAILURE';
export const ADD_NEW_BLOCK_REQUEST = 'ADD_NEW_BLOCK_REQUEST';
export const ADD_NEW_BLOCK_SUCCESS = 'ADD_NEW_BLOCK_SUCCESS';
export const ADD_NEW_BLOCK_FAILURE = 'ADD_NEW_BLOCK_FAILURE';
export const DELETE_BLOCK_REQUEST = 'DELETE_BLOCK_REQUEST';
export const DELETE_BLOCK_SUCCESS = 'DELETE_BLOCK_SUCCESS';
export const DELETE_BLOCK_FAILURE = 'DELETE_BLOCK_FAILURE';
export const ADD_SELECTED_BLOCK = 'ADD_SELECTED_BLOCK';
export const DELETE_SELECTED_BLOCK = 'DELETE_SELECTED_BLOCK';
export const UPDATE_RANDOMIZER_SUB_BLOCK_PERCENTAGE_REQUEST =
  'UPDATE_RANDOMIZER_SUB_BLOCK_PERCENTAGE_REQUEST';
export const UPDATE_RANDOMIZER_SUB_BLOCK_PERCENTAGE_SUCCESS =
  'UPDATE_RANDOMIZER_SUB_BLOCK_PERCENTAGE_SUCCESS';
export const UPDATE_RANDOMIZER_SUB_BLOCK_PERCENTAGE_FAILURE =
  'UPDATE_RANDOMIZER_SUB_BLOCK_PERCENTAGE_FAILURE';
export const UPDATE_SELECTED_BLOCK = 'UPDATE_SELECTED_BLOCK';
export const DELETE_SUB_BLOCK = 'DELETE_SUB_BLOCK';
export const UPDATE_SELECTED_MENU_BLOCK = 'UPDATE_SELECTED_MENU_BLOCK';
export const DELETE_MENU_SUB_BLOCK = 'DELETE_MENU_SUB_BLOCK';
export const DELETE_CONTENT_SUB_BLOCK = 'DELETE_CONTENT_SUB_BLOCK';
export const UPDATE_SELECTED_CONTENT_BLOCK = 'UPDATE_SELECTED_CONTENT_BLOCK';
export const UPDATE_SELECTED_FILE_CONTENT_BLOCK = 'UPDATE_SELECTED_FILE_CONTENT_BLOCK';
export const UPDATE_SELECTED_START_FLOW_BLOCK = 'UPDATE_SELECTED_START_FLOW_BLOCK';
export const UPDATE_SELECTED_ACTION_BLOCK = 'UPDATE_SELECTED_ACTION_BLOCK';
export const PUSH_TO_SELECTED_CONDITION_BLOCK = 'PUSH_TO_SELECTED_CONDITION_BLOCK';
export const UPDATE_SELECTED_CONDITION_BLOCK = 'UPDATE_SELECTED_CONDITION_BLOCK';
export const LOAD_SUCCESS = 'LOAD_SUCCESS';
export const LOAD_ERROR = 'LOAD_ERROR';
export const LOAD_IN_PROGRESS = 'LOAD_IN_PROGRESS';
export const GET_SHARE_FLOW_DETAILS_REQUEST = 'GET_SHARE_FLOW_DETAILS_REQUEST';
export const GET_SHARE_FLOW_DETAILS_SUCCESS = 'GET_SHARE_FLOW_DETAILS_SUCCESS';
export const GET_SHARE_FLOW_DETAILS_FAILURE = 'GET_SHARE_FLOW_DETAILS_FAILURE';
export const UPDATE_SELECTED_INTEGRATION_BLOCK = 'UPDATE_SELECTED_INTEGRATION_BLOCK';
export const DELETE_INTEGRATION_SUB_BLOCK = 'DELETE_INTEGRATION_SUB_BLOCK';
export const UPDATE_INTEGRATION_BLOCK = 'UPDATE_INTEGRATION_BLOCK';
export const UPDATE_SELECTED_SMART_DELAY_BLOCK_TYPE =
  'UPDATE_SELECTED_SMART_DELAY_BLOCK_TYPE';
export const UPDATE_SELECTED_MENU_BLOCK_TYPE = 'UPDATE_SELECTED_MENU_BLOCK_TYPE';
export const UPDATE_SELECTED_CONDITION_BLOCK_TYPE =
  'UPDATE_SELECTED_CONDITION_BLOCK_TYPE';
