/* eslint-disable import/no-unresolved */
import React from 'react';
import { UserCardInfoPausePanel } from '@components/common/UserCardComponents/UserCardInfo/UserCardInfoPausePanel/UserCardInfoPausePanel';
import { UserCardInfoPanelForAssign } from '@components/common/UserCardComponents/UserCardInfo/UserCardInfoPanelForAssign/UserCardInfoPanelForAssign';
import { ChatAction } from './ChatAction/ChatAction';
import './Actions.scss';

export const Actions = ({ data, updateUserCard, ownerCompanyId, managers, isContacts }) => (
  <div className="actions-wrapper">
    <span className="actions-wrapper__header">Actions</span>
    <div className="actions-wrapper__container">
      <ChatAction updateUserCard={updateUserCard} data={data} isContacts={isContacts}/>
      <UserCardInfoPausePanel data={data} updateUserCard={updateUserCard} isContacts={isContacts} />
      <UserCardInfoPanelForAssign
        data={data}
        updateUserCard={updateUserCard}
        ownerCompanyId={ownerCompanyId}
        managers={managers}
        isContacts={isContacts}
      />
    </div>
  </div>
);
