import { createSelector } from 'reselect';

export const navigationMenuStateSelector = createSelector(
  [state => state.navigationMenu.open],
  open => open,
);

export const headerStateSelector = createSelector(
  [state => state.navigationMenu.isShowHeader],
  isShowHeader => isShowHeader,
);
