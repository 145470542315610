import { all, takeLatest, put } from 'redux-saga/effects';
import { dashboardSettings } from '@api/dashBoard';
import { dashboardActions } from './dashboardActions';
import { dashboardActionTypes as types } from './actionTypes';

function* getDashboardStatistic(action) {
  try {
    const { id, startDate, endDate, graphType, tagId } = action.payload;

    const [responseStatistic, responseTimePeriod] = yield all([
      dashboardSettings.getDashboardStatus(id),
      dashboardSettings.getDashboardTimePeriod(id, startDate, endDate, graphType, tagId),
    ]);

    yield put(
      dashboardActions.setDashboardStatistics({
        stat: responseStatistic.data,
        info: responseTimePeriod.data,
      }),
    );
  } catch (error) {}
}

function* sagas() {
  yield all([takeLatest(types.GET_DASHBOARD_STAT, getDashboardStatistic)]);
}

export const dashBoardSagas = sagas;
