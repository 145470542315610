import { managersActionsTypes } from './managersActionsTypes';

export const managersActions = {
  updateLiveChatPermissionsRequest(data) {
    return {
      type: managersActionsTypes.UPDATE_LIVE_CHAT_PERMISSIONS_REQUEST,
      payload: data,
    };
  },

  updateLiveChatPermissionsSuccess(data) {
    return {
      type: managersActionsTypes.UPDATE_LIVE_CHAT_PERMISSIONS_SUCCESS,
      payload: data,
    };
  },

  updateLiveChatPermissionsRequestFailure(error) {
    return {
      type: managersActionsTypes.UPDATE_LIVE_CHAT_PERMISSIONS_FAILURE,
      payload: error,
    };
  },
};
