import React from 'react';
import clsx from 'clsx';
import Avatar from '@wabb-ui-kit/Avatar/Avatar';
import CloseIcon from '../../../../wabb-assets/svg/Dropdown/close.svg';
import styles from '../../Dropdown.module.scss';

interface SingleSelectDisplayProps {
  selectedOption: {
    id: number;
    bot: number;
    name: string;
    subText?: string;
    img?: string;
  } | null;
  isAvatar: boolean;
  isOpen: boolean;
  isBoldTitle?: boolean;
  placeholder: string | undefined;
  isDisabled: boolean;
  setSelectedOptions: (
    option: {
      id: number;
      bot: number;
      name: string;
      subText?: string;
      img?: string;
    } | null,
  ) => void;
}

const SingleSelectDisplay: React.FC<SingleSelectDisplayProps> = ({
  selectedOption,
  isAvatar,
  isBoldTitle,
  isDisabled,
  placeholder,
  isOpen,
  setSelectedOptions,
}) => (
  <div className={styles.titleWrapper}>
    <div className={styles.title}>
      {isAvatar && selectedOption && (
        <Avatar size="xs" img={selectedOption.img} type="company" />
      )}
      <div
        className={clsx(styles.selectedOption, {
          [styles.isBoldTitle]: isBoldTitle,
          [styles.disabled]: isDisabled,
        })}
      >
        {selectedOption ? (
          selectedOption.name
        ) : (
          <div className={styles.placeholder}>{placeholder || 'Choose here'}</div>
        )}
      </div>
    </div>
    {/* {isOpen && selectedOption && (
      <div onClick={() => setSelectedOptions(null)} className={styles.closeIcon}>
        <img src={CloseIcon} />
      </div>
    )} */}
  </div>
);

export default SingleSelectDisplay;
