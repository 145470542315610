import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useContextSelector } from 'use-context-selector';

import { useInternationalization } from '@hooks/useTranslationHook';
import { FranchiseCompanyApi } from '@api/franchiseCompanyApi';
import { NewBcField } from '@components/common/fields/newBcField/newBcField';
import { Button } from '@components/common/Button';
import { CompaniesSelector } from '@components/modals/createFranchiseUser/companiesSelector/companiesSelector';
import { CrossIcon } from '@icons/franchise/cross-icon';
import { regExp } from '@components/common/fields/newBcField/newBcFieldHelper';
import { BcPhoneInput } from '../../common/fields/BcPhoneInput';
import { getModalDataSelector } from '../../containers/Modal/modalSelectors';
import { MODALS } from '../../containers/Modal/modalConstants';
import { ModalContainer } from '../../containers/Modal/ModalContainer';
import { FranchiseContext } from '../../../contexts/franchiseContext';

import './createFranchiseUser.scss';

const isObjectWithoutError = object => Object.values(object).every(value => value === '');

const styles = {
  width: '34%',
  maxWidth: '440px',
  minWidth: '350px',
};

export const CreateFranchiseUserModal = () => {
  const { formatMessage } = useInternationalization();

  const data = useContextSelector(FranchiseContext, data => data);

  const { id } = data;

  const [inputs, setInputs] = useState({
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
  });

  const [inputsError, setInputsError] = useState({
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
  });

  const [companies, setCompanies] = useState([]);
  const [selectedCompanies, setSelectedCompanies] = useState([]);
  const [userCreationType, changeUserCreationType] = useState(0);
  const [errorInput, setErrorInput] = useState({
    firstName: false,
    lastName: false,
    email: false,
  });
  const { firstName, lastName, email, phone } = inputs;
  const [companiesData, setCompaniesData] = useState({
    companies: [],
    flowsErrors: '',
    page: 1,
    next: null,
  });
  const [status, setStatus] = useState(null);

  useEffect(() => {
    FranchiseCompanyApi.getFranchiseCompaniesShort(id).then(res => {
      const dataCompanies = res.data.map(item => ({
        value: item.id,
        label: item.name,
      }));
      setCompanies(dataCompanies);
    });
  }, []);

  const getFranchiseCompanies = () => {
    FranchiseCompanyApi.getFranchiseCompaniesShort(id).then(res => {
      setCompaniesData({ ...res.data, companies: res.data });
    });
  };

  const handleChangePhone = (fieldName, value) => {
    const formatedValue = removeSpace(value);
    setInputs(inputs => ({ ...inputs, [fieldName]: formatedValue }));
  };

  const validatePhone = value => value.length > 9 && value.length < 19;

  const valueFieldsValidation = () => {
    const errorObject = {};

    for (const [key, value] of Object.entries(inputs)) {
      if (value === '') {
        errorObject[key] = 'This field must not be empty';
      } else if (
        regExp[key] &&
        !regExp[key].expression.test(String(value).toLowerCase())
      ) {
        errorObject[key] = regExp[key].errorMessage;
      } else {
        errorObject[key] = '';
      }
    }

    return errorObject;
  };

  const handleChangeChecked = item => {
    if (selectedCompanies.find(el => el.id === item.id)) {
      setSelectedCompanies(selectedCompanies.filter(el => el.id !== item.id));
    } else {
      setSelectedCompanies([...selectedCompanies, { name: item.name, id: item.id }]);
    }
  };

  const handleSubmit = () => {
    const errorObject = valueFieldsValidation();

    if (!validatePhone(inputs.phone)) {
      errorObject.phone = 'Phone is not valid';
    }

    if (isObjectWithoutError(errorObject)) {
      const userObj = {
        first_name: firstName,
        last_name: lastName,
        franchise: id,
        email,
        phone,
      };

      const data = new FormData();
      data.append('first_name', inputs.firstName);
      data.append('last_name', inputs.lastName);
      data.append('email', inputs.email);
      data.append('franchise', id);
      data.append('phone', inputs.phone);
      const type = userCreationType ? 'email' : 'manual';
      const companiesIds = selectedCompanies.map(company => company.id);
      modalData.createFranchiseUser(
        userObj,
        companiesIds,
        type,
        setInputsError,
        inputsError,
        setStatus,
      );
    } else {
      setInputsError({ ...inputsError, ...errorObject });
    }
  };

  useEffect(() => {
    if (status === 200) {
      modalData.onClose();
    }
  }, [status]);

  const removeSpace = value => {
    const arr = value.split(' ');
    let str = '+';
    arr.map(item => (str += item));
    return str;
  };

  const handleChangeValue = (e, name) => {
    if (e.target && e.target.value) {
      setInputs({ ...inputs, [name]: e.target.value });
      setInputsError({ ...inputsError, [name]: '' });
    } else {
      setInputs({ ...inputs, [name]: '' });
    }
  };

  const { modalData } = useSelector(getModalDataSelector);

  useEffect(() => {
    getFranchiseCompanies();
  }, []);

  return (
    <ModalContainer
      modal={MODALS.createFranchiseUser}
      onClose={modalData.onClose}
      customStyles={styles}
    >
      <div className="create-franchise-user">
        <div className="create-franchise-user__header">
          {formatMessage('Create')} {formatMessage('User')}
        </div>
        <div className="create-franchise-user__container">
          {userCreationType === 0 && (
            <>
              <div className="create-franchise-user__container_field">
                <NewBcField
                  name="firstName"
                  label={formatMessage('First Name')}
                  placeholder={formatMessage('First Name')}
                  value={inputs.firstName}
                  onChange={handleChangeValue}
                  helperText={
                    errorInput.firstName && formatMessage('This field must not be empty')
                  }
                />
              </div>
              <div className="create-franchise-user__container_field">
                <NewBcField
                  name="lastName"
                  label={formatMessage('Last Name')}
                  placeholder={formatMessage('Last Name')}
                  value={inputs.lastName}
                  onChange={handleChangeValue}
                  helperText={
                    Boolean(inputsError.lastName) && formatMessage(inputsError.lastName)
                  }
                />
              </div>
            </>
          )}
          <div className="create-franchise-user__container_label">
            {formatMessage('Phone')}
          </div>
          <div className="create-franchise-user__container_field">
            <BcPhoneInput
              onChange={event => {
                handleChangePhone('phone', event);
              }}
              helperText={Boolean(inputsError.phone) && formatMessage(inputsError.phone)}
            />
          </div>
          <div className="create-franchise-user__container_field">
            <NewBcField
              name="email"
              label={formatMessage('Email')}
              placeholder={formatMessage('Email')}
              value={inputs.email}
              onChange={handleChangeValue}
              helperText={Boolean(inputsError.email) && formatMessage(inputsError.email)}
            />
          </div>
          <div className="create-franchise-user__container_field">
            <CompaniesSelector
              selectedCompanies={selectedCompanies}
              companies={companiesData.companies}
              selectCompany={handleChangeChecked}
            />
            <div className="create-franchise-user__container_field_companies-array">
              {selectedCompanies &&
                selectedCompanies.map(item => (
                  <div className="create-franchise-user__container_field_companies-array_item">
                    {item.name}
                    <div
                      className="create-franchise-user__container_field_companies-array_item_cross"
                      onClick={() => handleChangeChecked(item)}
                    >
                      <CrossIcon />
                    </div>
                  </div>
                ))}
            </div>
          </div>
          <div>
            <Button
              width="100%"
              color="green"
              onClick={handleSubmit}
              customClass="create-franchise-user__button-text"
            >
              {formatMessage('Create')}
            </Button>
          </div>
        </div>
      </div>
    </ModalContainer>
  );
};
