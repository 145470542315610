import React from 'react';
import { PositiveAlertIcon } from '@wabb-assets/svg/Modal/positive-alert-icon';
import './WarningWidget.scss';

export const WarningWidget = ({ title, description }) => {
  return (
    <div className="warning_widget__container">
      <div>
        <PositiveAlertIcon />
      </div>
      <div className="warning_widget__title">{title}</div>
      <div className="warning_widget__description">{description}</div>
    </div>
  );
};
