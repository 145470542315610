//page step 1
export const ADD_ITEM = 'ADD_ITEM';
export const DELETE_ITEM = 'DELETE_ITEM';
export const ADD_ALL_ITEMS = 'ADD_ALL_ITEMS';
export const DELETE_ALL_ITEMS = 'DELETE_ALL_ITEMS';
// page step 2
export const ADD_VARIABLE_TYPES = 'ADD_VARIABLE_TYPES';
export const ADD_BOT_VARIABLES = 'ADD_BOT_VARIABLES';
export const ADD_TAGS = 'ADD_TAGS';
export const ADD_ZAPIER_EVENTS = 'ADD_ZAPIER_EVENTS';
// page step 3
export const FOR_PUBLISHER = 'FOR_PUBLISHER';
export const ADD_NAME = 'ADD_NAME';
export const ADD_AVATAR = 'ADD_AVATAR';
export const ADD_CATEGORY = 'ADD_CATEGORY';
export const ADD_DESCRIPTION = 'ADD_DESCRIPTION';
export const ADD_FEATURES = 'ADD_FEATURES';
export const ADD_INSTRUCTIONS = 'ADD_INSTRUCTIONS';
export const ADD_SCREENSHOTS = 'ADD_SCREENSHOTS';
