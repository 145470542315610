import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { DateTimePopover } from '@pages/audience/components/audienceFilters/filtersPopover/components/dateTimePopover/dateTimePopover';
import FormField from '@wabb-ui-kit/FormField/FormField.tsx';
import Button from '@wabb-ui-kit/Button/Button';
import Dropdown from '@wabb-ui-kit/Dropdown/Dropdown';
import { EInputType } from '@wabb-ui-kit/FormField/types/types';
import { EButtonSize } from '@wabb-ui-kit/Button/types/types';
import { EComponentType } from '@wabb-ui-kit/FormField/types/types';
import { getModalDataSelector } from '@components/containers/Modal/modalSelectors';
import { useInternationalization } from '@hooks/useTranslationHook';
import { foldersApi } from '@api';
import { variablesApi } from '@api/variablesApi';
import { AudienceFiltersMenuArrowIcon } from '@icons/audience/audience-filters-menu-arrow-icon';
import { bcSplitTableActions } from '../../../redux/bcSplitTable/bcSplitTableActions.js';
import { MODALS } from '../../containers/Modal/modalConstants';
import { ModalContainer } from '../../containers/Modal/ModalContainer';
import { CustomFieldsTypes } from '../../../pages/settings/components/userFields/Modals/CreateUserFieldModal/CreateUserFieldModal';
import { BotVariableType } from '../../../pages/settings/components/botFields/types';
import { transformFieldsData } from '../../../pages/settings/components/botFields/utils.jsx';

import './editFieldAndFolderModal.scss';

export const EditFieldAndFolderModal = () => {
  const { pathname } = useLocation();
  const dispatch = useDispatch();
  const folderId = Number(pathname.split('/')[pathname.split('/').length - 1]);
  const { modalData } = useSelector(getModalDataSelector);
  const initialType = CustomFieldsTypes.find((field) => (typeof modalData.item.type === 'number' ? field.id === modalData.item.type : field.name === modalData.item.type));
  const [name, setName] = useState(modalData.type === 'folder' ? modalData.item.name : (modalData.item?.name ?? modalData.item?.key));
  const [description, setDescription] = useState(modalData.item.description);
  const [type, setType] = useState(initialType);
  const [value, setValue] = useState(modalData.item?.value);
  const [anchorEl, setAnchorEl] = useState(null);
  const { formatMessage } = useInternationalization();

  const handleUpdateFolderName = (name) => {
    foldersApi
      .renameFolder(modalData.item.id, name)
      .then((res) => {
        setName('');
        modalData.setFieldName(res.name);
        modalData.actionWithFolders(modalData.item.id, 'Update', {
          ...modalData.item,
          ...res,
        });
        modalData.onClose();
      })
      .catch((error) => {
        console.error('API call failed:', error);
      });
  };

  const handleUpdateFieldName = (name) => {
    if (modalData.type === 'userField') {
      variablesApi.updateVariableTypes({
        key: name,
        description,
        pk: modalData.item.id,
        type: type.id,
        folder: Number.isNaN(folderId) ? null : folderId,
      }, modalData.item.id)
        .then((res) => {
          setName('');
          dispatch(bcSplitTableActions.updateItemInSplitTable(res));
          modalData.onClose();
        })
        .catch((error) => {
          console.error('API call failed:', error);
        });
    } else {
      variablesApi.updateVariableBot({
        key: name,
        description,
        pk: modalData.item.id,
        type: type.id,
        folder: Number.isNaN(folderId) ? null : folderId,
        valueString: type.id === BotVariableType.Text ? value : null,
        valueFloat: type.id === BotVariableType.Number ? value : null,
        dateValue: type.id === BotVariableType.Date ? value : null,
        dateTimeValue: type.id === BotVariableType.DateTime ? value : null,
      }, modalData.item.id)
        .then((res) => {
          setName('');
          dispatch(bcSplitTableActions.updateItemInSplitTable(transformFieldsData([res])[0]));
          modalData.onClose();
        })
        .catch((error) => {
          console.error('API call failed:', error);
        });
    }
  };

  const getValueField = () => {
    switch (type?.id) {
      case 0:
        return (
          <div>
            <FormField
              onChange={(e) => setValue(e.target.value)}
              label="Value"
              value={value}
              onCrossClick={() => setValue('')}
              placeholder="Type here..."
            />
          </div>
        );
      case 1:
        return (
          <div>
            <FormField
              onChange={(e) => setValue(e.target.value)}
              label="Value"
              value={value}
              type={EInputType.Number}
              onCrossClick={() => setValue('')}
              placeholder="Type here..."
            />
          </div>
        );
      case 2:
        return (
          <div className="date-field">
            <div className="date-field__title">{formatMessage('Value')}</div>
            <div
              className={`date-field__filled-input ${!value ? 'empty-state' : ''}`}
              onClick={(event) => setAnchorEl(event.currentTarget)}
            >
              {value || 'Select value'}
              <div className={`date-field-arrow ${anchorEl ? 'open-item' : ''}`}>
                <AudienceFiltersMenuArrowIcon />
              </div>
            </div>
            <DateTimePopover
              id="bot_fields"
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
              }}
              open={Boolean(anchorEl)}
              onClose={() => {
                setAnchorEl(null);
              }}
              dateTime={false}
              format="YYYY-MM-DD"
              changeDate={(dateValue) => setValue(dateValue)}
            />
          </div>
        );
      case 3:
        return (
          <div className="date-field">
            <div className="date-field__title">{formatMessage('Value')}</div>
            <div
              className={`date-field__filled-input ${!value ? 'empty-state' : ''}`}
              onClick={(event) => setAnchorEl(event.currentTarget)}
            >
              {value || 'Select value'}
              <div className={`date-field-arrow ${anchorEl ? 'open-item' : ''}`}>
                <AudienceFiltersMenuArrowIcon />
              </div>
            </div>
            <DateTimePopover
              id="bot_fields"
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
              }}
              open={Boolean(anchorEl)}
              onClose={() => {
                setAnchorEl(null);
              }}
              dateTime
              format="YYYY-MM-DD HH:mm"
              changeDate={(dateValue) => setValue(dateValue)}
            />
          </div>
        );
      default:
        return (
          <div>
            <FormField
              onChange={(e) => setValue(e.target.value)}
              label="Value"
              value={value}
              onCrossClick={() => setValue('')}
              placeholder="Type here..."
            />
          </div>
        );
    }
  };

  return (
    <ModalContainer modal={MODALS.editFieldAndFolderModal} onClose={modalData.onClose}>
      <div className="edit-folder-modal__container">
        <div className="edit-folder-modal__title">Edit</div>
        {modalData.type === 'folder' ? (
          <div className="edit-folder-modal__input">
            <FormField
              onChange={(e) => setName(e.target.value)}
              label="Name"
              isRequired
              value={name}
              onCrossClick={() => setName('')}
              placeholder="Type here..."
            />
          </div>
        ) : modalData.type === 'userField' ? (
          <div className="edit-folder-modal__container__content">
            <div>
              <FormField
                onChange={(e) => setName(e.target.value)}
                label="Name"
                isRequired
                value={name}
                placeholder="Type here..."
                isDisabled
              />
            </div>
            <div className="edit-folder-modal__container__content__select">
              <label className="edit-folder-modal__container__content__select__label">
                Type
              </label>
              <Dropdown
                options={CustomFieldsTypes}
                singleSelectedOption={type}
                setSingleSelectedOption={setType}
                placeholder="Select type"
                isDisabled
              />
            </div>
            <div>
              <FormField
                Component={EComponentType.TextArea}
                onChange={(e) => setDescription(e.target.value)}
                label="Description"
                value={description}
                maxLength={500}
                height={124}
                onCrossClick={() => setDescription('')}
                placeholder="Type here..."
              />
            </div>
          </div>
        ) : (
          <div className="edit-folder-modal__container__content">
            <div>
              <FormField
                onChange={(e) => setName(e.target.value)}
                label="Name"
                isRequired
                value={name}
                placeholder="Type here..."
                isDisabled
              />
            </div>
            <div className="edit-folder-modal__container__content__select">
              <label className="edit-folder-modal__container__content__select__label">
                Type
              </label>
              <Dropdown
                options={CustomFieldsTypes}
                singleSelectedOption={type}
                setSingleSelectedOption={setType}
                placeholder="Select type"
                isDisabled
              />
            </div>
            {getValueField()}
            <div>
              <FormField
                Component={EComponentType.TextArea}
                onChange={(e) => setDescription(e.target.value)}
                label="Description"
                value={description}
                maxLength={500}
                height={124}
                onCrossClick={() => setDescription('')}
                placeholder="Type here..."
              />
            </div>
          </div>
        ) }
        <div className="edit-folder-modal__btns">
          <Button
            size={EButtonSize.L}
            isDisabled={name.length === 0}
            onClick={() => (modalData.type === 'folder'
              ? handleUpdateFolderName(name)
              : handleUpdateFieldName(name))}
          >
            { formatMessage('Save') }
          </Button>
          <Button
            type="secondary"
            style="tint"
            size={EButtonSize.L}
            onClick={modalData.onClose}
          >
            { formatMessage('Cancel') }
          </Button>
        </div>
      </div>
    </ModalContainer>
  );
};
