import React from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';

import './CheckBox.scss';

export const CheckBox = ({ checked, onChange, customClass, disabled }) => (
  <input
    disabled={disabled}
    type="checkbox"
    className={cn('common-custom-checkbox', disabled ? 'disabled' : '', customClass)}
    checked={checked}
    onChange={onChange}
    onClick={event => event.stopPropagation()}
  />
);

CheckBox.propTypes = {
  checked: PropTypes.bool,
  customClass: PropTypes.string,
};
