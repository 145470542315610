import { PureComponent } from 'react';
import ErrorFallbackPage from './ErrorFallbackPage';

class ErrorBoundary extends PureComponent {
  state = {
    hasError: false,
    errorInfo: null,
  };

  static getDerivedStateFromError(err) {
    return { hasError: true, errorInfo: err };
  }

  componentDidCatch(error, errorInfo) {
    console.error('Uncaught error:', error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      return <ErrorFallbackPage errorInfo={this.state.errorInfo} />;
    }
    return this.props.children;
  }
}

export default ErrorBoundary;
