import api from './baseApi';

import { liveChatApiRPC } from '@connectApi/baseApi';
import {
  FastReplyListRequest,
  FastReplyBulkDeleteRequest,
} from 'wabb-backend-api/build/es/live_chat/live_chat_pb';

export const fastRepliesApi = {
  getAllFastReplies: (botId, page, searchName) => {
    return liveChatApiRPC.fastReplyList(
      FastReplyListRequest.fromJson({
        bot_id: botId,
        page,
        search_string: searchName ? searchName : '',
      }),
    );
  },

  createFastReply: data => {
    const { botId, replyName, replyContent } = data;
    return liveChatApiRPC.fastRepliesCreate({
      bot: botId,
      name: replyName,
      reply: replyContent,
    });
  },

  updateFastReply: data => {
    const { botId, replyName, replyContent, replyId } = data;
    return liveChatApiRPC.fastReplyCRUDPatch({
      bot: botId,
      name: replyName,
      pk: replyId,
      reply: replyContent === '' ? '*add_text*' : replyContent,
    });
  },

  deleteFastReply: replyId => liveChatApiRPC.fastReplyCRUDDelete({ pk: replyId }),


  bulkActionDelete: (botId, selectedOrUnselectedItemIds, selectedAllMode) =>
    liveChatApiRPC.fastReplyBulkDelete(
      FastReplyBulkDeleteRequest.fromJson({
        bot_id: botId,
        ids: selectedOrUnselectedItemIds,
        selected_all: selectedAllMode,
      }),
    ),

  getAllFastRepliesNotPagination: botId =>
  liveChatApiRPC.fastReplyShortList({ botId}),

};
