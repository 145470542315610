import React from 'react';

export const WarningToastIcon = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="12" cy="17" r="1" fill="#FFB800" />
    <circle
      cx="12"
      cy="17"
      r="1"
      fill="url(#paint0_linear_4919_133972)"
      fillOpacity="0.1"
    />
    <path
      d="M12 10L12 14"
      stroke="#FFB800"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12 10L12 14"
      stroke="url(#paint1_linear_4919_133972)"
      strokeOpacity="0.1"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M3.44722 18.1056L10.2111 4.57771C10.9482 3.10361 13.0518 3.10362 13.7889 4.57771L20.5528 18.1056C21.2177 19.4354 20.2507 21 18.7639 21H5.23607C3.7493 21 2.78231 19.4354 3.44722 18.1056Z"
      stroke="#FFB800"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M3.44722 18.1056L10.2111 4.57771C10.9482 3.10361 13.0518 3.10362 13.7889 4.57771L20.5528 18.1056C21.2177 19.4354 20.2507 21 18.7639 21H5.23607C3.7493 21 2.78231 19.4354 3.44722 18.1056Z"
      stroke="url(#paint2_linear_4919_133972)"
      strokeOpacity="0.1"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <defs>
      <linearGradient
        id="paint0_linear_4919_133972"
        x1="12"
        y1="16"
        x2="12"
        y2="18"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" />
        <stop offset="1" stopOpacity="0.21" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_4919_133972"
        x1="12.5"
        y1="10"
        x2="12.5"
        y2="14"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" />
        <stop offset="1" stopOpacity="0.21" />
      </linearGradient>
      <linearGradient
        id="paint2_linear_4919_133972"
        x1="12"
        y1="1"
        x2="12"
        y2="21"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" />
        <stop offset="1" stopOpacity="0.21" />
      </linearGradient>
    </defs>
  </svg>
);
