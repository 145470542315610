import React, { useCallback, useEffect, useState } from 'react';
import debounce from 'lodash/debounce';
import { useHistory } from 'react-router-dom';

import { useInternationalization } from '@hooks/useTranslationHook';
import FormField from '@wabb-ui-kit/FormField/FormField';
import { LoopIcon } from '@wabb-assets/svg/FormField/loop-icon';
import { EComponentType, EIconDirection } from '@wabb-ui-kit/FormField/types/types';

import './LocalSearch.scss';

export const LocalSearch = ({ handler, iconDirection = EIconDirection.Left, className }) => {
  const { formatMessage } = useInternationalization();
  const history = useHistory();

  const searchNameFromUrl = new URLSearchParams(history.location.search).get(
    'search_name',
  );

  const [searchName, setSearchName] = useState('');

  const cleanSearchString = () => {
    setSearchName('');
    handler('');
    history.push(history.location.pathname);
  };

  useEffect(() => {
    setSearchName(searchNameFromUrl || '');
  }, [searchNameFromUrl]);
  const sendDebouncedReq = useCallback(
    debounce((value, callback) => callback(value), 800),
    [],
  );

  const handleChange = (e) => {
    setSearchName(e.target.value);
    sendDebouncedReq(e.target.value, delayedUpdateSearchName);
  };

  const delayedUpdateSearchName = (value) => {
    handler(value);

    if (
      !history.location.pathname.includes('audience')
    ) {
      history.push(value ? `?search_name=${value}` : history.location.pathname);
    }
  };

  useEffect(() => {
    if (searchName) {
      handler(searchName);
    }
  }, []);

  return (
    <FormField
      placeholder="Search"
      value={String(searchName)}
      className={className}
      Component={EComponentType.Input}
      onChange={handleChange}
      icon={<LoopIcon color="#667085" />}
      onCrossClick={cleanSearchString}
      width={320}
      iconDirection={iconDirection}
    />
  );
};
