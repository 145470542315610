export const liveChatRooms = [
  {
    fieldName: 'liveChatAll',
    routeParam: 'all',
    queryParamName: 'all',
    name: 'All',
  },
  {
    fieldName: 'liveChatMyBusy',
    routeParam: 'my',
    queryParamName: 'my',
    name: 'Mine',
  },
  {
    fieldName: 'liveChatAllBusy',
    routeParam: 'opened',
    queryParamName: 'all_busy',
    name: 'All busy',
  },
];

export const roomsSort = [
  { text: 'Both', value: 'all' },
  {
    text: 'Opened',
    value: 'open',
  },
  { text: 'Closed', value: 'close' },
];
