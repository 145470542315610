import React from 'react';
import CheckBox from '@wabb-ui-kit/CheckBox/CheckBox';

import './AudienceFiltersEmptyComponent.scss';

const tableTitleName = ['Name', 'WhatsApp', 'Email address', 'Date'];

export const AudienceFiltersEmptyComponent = () => (
  <div className="audience-filters-empty-component__container">
    <div className="audience-filters-empty-component__table">
      <CheckBox type="checkbox" />
      {tableTitleName.map((item) => (
        <div className="audience-filters-empty-component__table__column">{item}</div>
      ))}
    </div>
    <div className="audience-filters-empty-component__no-result">
      <div className="audience-filters-empty-component__no-result__container">
        <div className="audience-filters-empty-component__no-result__title">
          No results match
        </div>
        <div className="audience-filters-empty-component__no-result__description">Remove any filters, or search for something less specific.</div>
      </div>
    </div>
  </div>
);
