import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { getFilters } from '@redux/audience/audienceSelectors';
import { useInternationalization } from '@hooks/useTranslationHook';
import { getBroadcastUsersFiltersSelector } from '@redux/broadcast/broadcastSelector';
import { AudienceFiltersHeader } from './AudienceFiltersHeader/AudienceFiltersHeader';
import { FilterItem } from './FilterItem/FilterItem';

import './audienceFilters.scss';

export const AudienceFilters = ({ broadcasts, userName, setUserName }) => {
  const { formatMessage } = useInternationalization();
  const filters = broadcasts
    ? useSelector(getBroadcastUsersFiltersSelector)
    : useSelector(getFilters);

  const [hiddenFiltersCount, setHiddenFiltersCount] = useState(null);
  const [showAllFilters, setShowAllFilters] = useState(false);

  const buttonGroupElement = document.querySelector('.audience-filters__btn-group');
  const filtersContainerElement = document.querySelector(
    '.audience-filters__btn-group_filters',
  );

  useEffect(() => {
    if (buttonGroupElement && filtersContainerElement && !broadcasts) {
      let lastVisibleFilterIndex = 0;
      Array.from(filtersContainerElement.children).reduce(
        (previousFilterLength, currentFilter, index) => {
          if (
            previousFilterLength + currentFilter.clientWidth
            <= buttonGroupElement.clientWidth - 30
          ) {
            lastVisibleFilterIndex = index + 1;
          }

          return previousFilterLength + currentFilter.clientWidth;
        },
        0,
      );
      setHiddenFiltersCount(filters.length - lastVisibleFilterIndex);
    }
  }, [filters]);

  return (
    <div className="audience-filters">
      <div className="audience-filters__btn-group">
        <AudienceFiltersHeader
          broadcasts={broadcasts}
          userName={userName}
          setUserName={setUserName}
        />
        <div
          className="audience-filters__btn-group_filters"
          style={showAllFilters || broadcasts ? {} : { maxHeight: '24px' }}
        >
          {filters.map((filter, index) => (
            <FilterItem
              key={index + filters.length}
              filter={filter}
              broadcasts={broadcasts}
            />
          ))}
          {Boolean(hiddenFiltersCount) && !broadcasts && (
            <div
              onClick={() => {
                setShowAllFilters(!showAllFilters);
              }}
              className="audience-filters__btn-group_filters_show-less"
            >
              {showAllFilters && formatMessage('Show less')}
            </div>
          )}
        </div>

        {Boolean(hiddenFiltersCount) && !broadcasts && (
          <div
            onClick={() => {
              setShowAllFilters(!showAllFilters);
            }}
            className="audience-filters__btn-group_filters_count"
          >
            {!showAllFilters && `+${hiddenFiltersCount}`}
          </div>
        )}
      </div>
    </div>
  );
};
