import {
  OPEN_NOTIFICATIONS_CONNECTION_REQUEST,
  OPEN_NOTIFICATIONS_CONNECTION_SUCCESS,
  CLOSE_NOTIFICATIONS_CONNECTION_SUCCESS,
  SET_START_NOTIFICATION_VALUES,
  CHANGE_CONNECTION_STATUS,
  CHANGE_COUNT_OF_TRIES,
  CHANGE_QR_SCAN,
  CHANGE_BATTERY_LEVEL,
  CHANGE_DEVICE_INFO,
  CHANGE_UNREAD_CHATS,
  CHANGE_CONNECTED_PHONE,
  CHANGE_SYNC_PROCESS_STATUS,
  GET_NOTIFICATION_CONNECTION_STATUS_SUCCESS,
} from './notificationsConstants';

export const notificationsActions = {
  //Request on open notificationsSocket connection
  openNotifiactionsConnection(data) {
    return {
      type: OPEN_NOTIFICATIONS_CONNECTION_REQUEST,
      payload: data,
    };
  },

  //Open notificationsSocket connection success
  openConnectionSuccess(notificationsScoket) {
    return {
      type: OPEN_NOTIFICATIONS_CONNECTION_SUCCESS,
      payload: notificationsScoket,
    };
  },

  //Close notificationsSocket connection
  closeConnectionSuccess() {
    return {
      type: CLOSE_NOTIFICATIONS_CONNECTION_SUCCESS,
    };
  },

  //Set notification start values
  setInitialValues(data) {
    return {
      type: SET_START_NOTIFICATION_VALUES,
      payload: data,
    };
  },

  changeSyncProcessStatus(data) {
    return {
      type: CHANGE_SYNC_PROCESS_STATUS,
      payload: data,
    };
  },

  changeConnectionStatus(data) {
    return {
      type: CHANGE_CONNECTION_STATUS,
      payload: data,
    };
  },

  getNotificationConnectionStatusSuccess(data) {
    return {
      type: GET_NOTIFICATION_CONNECTION_STATUS_SUCCESS,
      payload: data,
    };
  },

  changeConnectedPhone(data) {
    return {
      type: CHANGE_CONNECTED_PHONE,
      payload: data,
    };
  },

  changeCountOfTries(data) {
    return {
      type: CHANGE_COUNT_OF_TRIES,
      payload: data,
    };
  },

  changeQrScan(data) {
    return {
      type: CHANGE_QR_SCAN,
      payload: data,
    };
  },

  changeBatteryLevel(data) {
    return {
      type: CHANGE_BATTERY_LEVEL,
      payload: data,
    };
  },

  //Change scaned qr device info
  chageUserInfo(data) {
    return {
      type: CHANGE_DEVICE_INFO,
      payload: data,
    };
  },

  changeUnreadChats(data) {
    return {
      type: CHANGE_UNREAD_CHATS,
      payload: data,
    };
  },
};
