import api from './baseApi';

export const webhooksApi = {
  createWebhook: ({ name, botId, clientId, clientSecret, basic }) =>
    api.post('webhooks-automation/', {
      bot: botId,
      name,
      client_id: clientId,
      client_secret: clientSecret,
      basic,
    }),

  getWebhook: hook_id => api.get(`webhooks-automation/${hook_id}/`),
  updateWebhook: (hook_id, data) => api.patch(`webhooks-automation/${hook_id}/`, data),
  deleteWebhook: hook_id => api.delete(`webhooks-automation/${hook_id}/`),
  getWebHooksList: data => {
    const { botId, searchName } = data;

    if (searchName) {
      return api.get(`webhooks-automation/all/${botId}/?search_name=${searchName}`);
    }

    return api.get(`webhooks-automation/all/${botId}/`);
  },
  getTestRequestsList: hookId => api.get(`webhooks-automation/testtriggers/${hookId}/`),
  createWebhookAction: data => api.post('webhooks-automation/action/', data),
  updateWebhookAction: (actionId, data) =>
    api.patch(`webhooks-automation/detail/${actionId}/`, data),
  deleteAction: actionId => api.delete(`webhooks-automation/detail/${actionId}/`),
  catchWebhook: url => api.get(url),
  getWebhookLimit: botId => api.get(`bot/get_webhook_limit_progress/${botId}/`),
};
