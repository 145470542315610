export const CustomFieldsTypes = [
  {
    id: 0,
    bot: 0,
    name: 'Text',
  },
  {
    id: 1,
    bot: 1,
    name: 'Number',
  },
  {
    id: 2,
    bot: 2,
    name: 'Date',
  },
  {
    id: 3,
    bot: 3,
    name: 'Date & Time',
  },
];

export const systemFields = [
  'full_name',
  'first_name',
  'last_name',
  'phone',
  'ddd',
  'referral_name',
  'referral_phone',
  'referrals_count',
  'referral_code',
  'joined_at',
  'nome-completo',
  'primeiro-nome',
  'sobrenome',
  'telefone',
  'nome-indicador',
  'telefone-indicador',
  'numero-de-indicacoes',
  'codigo-indicacao',
  'data-inscricao',
];

export const defineValueFieldName = type => {
  switch (type) {
    case 0:
      return 'value_string';
    case 1:
      return 'value_float';
    case 2:
      return 'date_value';
    case 3:
      return 'date_time_value';
    default:
      return 'value_string';
  }
};
