import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';

import './QrScanningModal.scss';

import { useInternationalization } from '@hooks/useTranslationHook';
import {
  getIsSyncProcessSelector,
  getQrSelector,
} from '@redux/notifications/notificationsSelector';
import { ModalContainer } from '../../containers/Modal/ModalContainer';
import { MODALS } from '../../containers/Modal/modalConstants';
import { getModalDataSelector } from '../../containers/Modal/modalSelectors';
import Button from '@wabb-ui-kit/Button/Button.tsx';
import { EButtonSize } from '@wabb-ui-kit/Button/types/types.ts';

export const QrScanningModal = () => {
  const { formatMessage } = useInternationalization();

  const { modalData } = useSelector(getModalDataSelector);
  const qr = useSelector(getQrSelector);
  const isScanned = useSelector(getIsSyncProcessSelector);

  useEffect(() => {
    isScanned && closeModal();
  }, [isScanned]);

  const closeModal = () => {
    modalData.onClose();
    modalData.setGenerateQr(false);
  };

  return (
    <ModalContainer modal={MODALS.qrScanning} onClose={closeModal}>
      <div className="qr-modal-container">
        <div className="qr-modal-container__text-wrapper">
          <div className="qr-modal-container_label">
            {formatMessage('Connect to WhatsApp')}
          </div>
          <div className="qr-modal-container_sub-label">
            {formatMessage(
              'Go to “Connected Devices” in your WhatsApp settings and click to add a new device. WhatsApp will ask you to scan a QR code. Scan the QR Code below to connect your WhatsApp.',
            )}
          </div>
        </div>

        <img src={qr} className="qr-modal-container__qr-code" alt="qr code" />
        <div className="qr-modal-container__description">
          For security reasons, QR-code will change. New QR will be generated in 30
          seconds
        </div>
        <div className="qr-modal-container__button">
          <Button type="secondary" style="tint" size={EButtonSize.L} onClick={closeModal}>
            {formatMessage('Cancel')}
          </Button>
        </div>
      </div>
    </ModalContainer>
  );
};
