import React from 'react';

export const SadSmileForFailedDownload = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="50"
    height="49"
    viewBox="0 0 50 49"
    fill="none"
  >
    <path
      d="M25 47C37.4264 47 47.5 36.9264 47.5 24.5C47.5 12.0736 37.4264 2 25 2C12.5736 2 2.5 12.0736 2.5 24.5C2.5 36.9264 12.5736 47 25 47Z"
      stroke="#BAC3CC"
      strokeWidth="3.75"
      strokeMiterlimit="10"
    />
    <path
      d="M34.7427 35.751C33.7543 34.0414 32.3335 32.6219 30.623 31.6351C28.9126 30.6482 26.9726 30.1287 24.9979 30.1287C23.0231 30.1286 21.0831 30.6481 19.3726 31.635C17.6621 32.6218 16.2414 34.0412 15.2529 35.7508"
      stroke="#BAC3CC"
      strokeWidth="3.75"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M16.5625 22.625C18.1158 22.625 19.375 21.3658 19.375 19.8125C19.375 18.2592 18.1158 17 16.5625 17C15.0092 17 13.75 18.2592 13.75 19.8125C13.75 21.3658 15.0092 22.625 16.5625 22.625Z"
      fill="#BAC3CC"
    />
    <path
      d="M33.4375 22.625C34.9908 22.625 36.25 21.3658 36.25 19.8125C36.25 18.2592 34.9908 17 33.4375 17C31.8842 17 30.625 18.2592 30.625 19.8125C30.625 21.3658 31.8842 22.625 33.4375 22.625Z"
      fill="#BAC3CC"
    />
    <path
      d="M16.5625 22.625C18.1158 22.625 19.375 21.3658 19.375 19.8125C19.375 18.2592 18.1158 17 16.5625 17C15.0092 17 13.75 18.2592 13.75 19.8125C13.75 21.3658 15.0092 22.625 16.5625 22.625Z"
      fill="#BAC3CC"
    />
    <path
      d="M33.4375 22.625C34.9908 22.625 36.25 21.3658 36.25 19.8125C36.25 18.2592 34.9908 17 33.4375 17C31.8842 17 30.625 18.2592 30.625 19.8125C30.625 21.3658 31.8842 22.625 33.4375 22.625Z"
      fill="#BAC3CC"
    />
  </svg>
);
