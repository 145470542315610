import React, { useState } from 'react';
import { FolderIcon } from '../../../icons/flow/folder-icon';
import { DropdownIcon } from '../../../icons/automation/dropdown-icon';
import { SelectedFolderIcon } from '../../../icons/flow/selected-folder-icon';

import './MoveToModal.scss';

export const FolderItem = ({
  folder,
  selectedFolder,
  setSelectedFolder,
  sourceFolderId,
  deep = 0,
  parent = null,
}) => {
  const [openState, setOpenState] = useState(folder.id === 'all');

  const chooseFolder = (folder, e) => {
    e.stopPropagation();
    setSelectedFolder({ ...folder, parent });
  };

  return (
    sourceFolderId !== folder.id && (
      <>
        <li
          className={
            folder.id === 'all' ? 'move-to-modal__all' : 'move-to-modal__body_folders_folder'
          }
          style={{ paddingLeft: 10, width: 288 - 29 * deep }}
          onClick={(e) => chooseFolder(folder, e)}
        >
          <div
          className='move-to-modal__container'
          >
            <div className="move-to-modal__body_folders_folder_name">
              <div>
                {selectedFolder && selectedFolder.id === folder.id ? (
                  <SelectedFolderIcon />
                ) : (
                  <FolderIcon />
                )}
              </div>
              <div>{folder.name}</div>
            </div>
            {folder.daughterFolders.length > 0 && (
              <div
                onClick={() => setOpenState(!openState)}
                style={
                  openState
                  ? { transform: 'rotate(90deg)' }
                  : { transform: 'rotate(0deg)' }
                }
              >
                <DropdownIcon />
              </div>
            )}
          </div>
          <ul className="move-to-modal__body_folders_list">
            {openState &&
              folder.daughterFolders.length > 0 &&
              folder.daughterFolders.map((item) => (
                <FolderItem
                  key={item.id}
                  folder={item}
                  selectedFolder={selectedFolder}
                  setSelectedFolder={setSelectedFolder}
                  sourceFolderId={sourceFolderId}
                  deep={deep + 1}
                  parent={folder}
                />
              ))}
          </ul>
        </li>
      </>
    )
  );
};
