import React from 'react';

import { BcTextField } from '../../../common/fields/TextField';
import { useInternationalization } from '../../../../hooks/useTranslationHook';

import '../createGroupCampaignModal.scss';

// const useStyles = makeStyles({
//   root: {
//     '& label': {
//       fontWeight: 'normal',
//       fontSize: '15px',
//     },
//     '& label.Mui-focused': {
//       color: 'red',
//     },
//     '& input': {
//       width: '100%',
//       backgroundColor: 'white',
//       borderRadius: '6px',
//     },
//     '& .MuiOutlinedInput-root': {
//       '& fieldset': {
//         borderColor: 'red',
//       },
//       '&.Mui-focused fieldset': {
//         border: '1px solid red',
//       },
//     },
//   },
// });

export const CreateGroupCampaignsPart1 = ({
  form,
  setForm,
  avatar,
  setAvatar,
  warning,
}) => {
  const { formatMessage } = useInternationalization();

  const handleValidationNumberField = event => {
    const { value } = event.target;

    if (!isNaN(value)) {
      setForm({ ...form, start_number: event.target.value });
    }
  };

  return (
    <div style={{ padding: '0 7%' }}>
      <div>
        <h2 className="group-campaigns-created-modal__name">
          {formatMessage("What's the name of the group campaign?")}
        </h2>
        <BcTextField
          fullWidth
          name="campaignName"
          // className={warning === true && form.name.length < 1 ? classes.root : null}
          placeholder={formatMessage('Name of the group campaign')}
          value={form.name}
          onChange={event => {
            setForm({ ...form, name: event.target.value });
          }}
          variant="outlined"
        />
      </div>
      <div className="group-campaigns-created-modal__container">
        <div style={{ width: '45%' }}>
          <h2 className="group-campaigns-created-modal__name">
            {formatMessage("What's will you call your groups?")}
          </h2>
          <BcTextField
            fullWidth
            // className={warning === true && form.groups_name.length < 1 ? classes.root : null}
            name="campaignName"
            placeholder={formatMessage("Example: 'Wabb Launch'")}
            value={form.groups_name}
            onChange={event => {
              setForm({ ...form, groups_name: event.target.value });
            }}
            variant="outlined"
          />
        </div>
        <div style={{ width: '45%' }}>
          <h2 className="group-campaigns-created-modal__name">
            {formatMessage('The first group starts with what number?')}
          </h2>
          <BcTextField
            fullWidth
            // className={warning === true && form.start_number.length < 1 ? classes.root : null}
            name="campaignName"
            placeholder={formatMessage('Insert a number...')}
            value={form.start_number}
            onChange={event => {
              handleValidationNumberField(event);
            }}
            variant="outlined"
          />
        </div>
      </div>
      <h2 className="group-campaigns-created-modal__name">
        {formatMessage('Select your group image')}:
      </h2>
      {avatar ? (
        <div className="group-campaigns-created-modal__image_uploader">
          <div className="group-campaigns-created-modal__image_uploader__container">
            <div className="group-campaigns-created-modal__image_uploader">
              <div className="group-campaigns-created-modal__image_uploader__container__picture">
                <img src={URL.createObjectURL(avatar)} width="100%" height="200px" />
                <div
                  className="group-campaigns-created-modal__image_uploader__deleteImage"
                  onClick={() => setAvatar(null)}
                >
                  X
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="group-campaigns-created-modal__image_uploader">
          {/* <ImageUploader
            onChange={(files) => setAvatar(files[0])}
            imgExtension={['.jpg', '.png', '.jpeg']}
            maxFileSize={2000000}
            maxFiles={1}
            singleImage
            withPreview
            fileContainerStyle={{ marginLeft: 0, marginTop: 8, maxHeight: 215 }}
            withIcon={false}
            fileSizeError=" image size is too big"
            fileTypeError=" is not supported file extension"
            label="Max file size: 2mb, accepted: jpg, jpeg, png"
          /> */}
        </div>
      )}
      <h2 className="group-campaigns-created-modal__name">
        {formatMessage('Please type the group description')}
      </h2>
      <textarea
        placeholder={formatMessage('Type the group description here...')}
        className="group-campaigns-created-modal__textarea"
        defaultValue={form.description}
        onChange={event => {
          setForm({ ...form, description: event.target.value });
        }}
      />
    </div>
  );
};
