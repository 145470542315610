import React from 'react';
import styles from '../../Dropdown.module.scss';

interface MultiSelectDisplayProps {
  selectedOptions: {
    id: number;
    bot: number;
    name: string;
    subText?: string;
    img?: string;
  }[];
  isOpen: boolean;
  setSelectedOptions: (
    options: { id: number; bot: number; name: string; subText?: string; img?: string }[],
  ) => void;
}

const MultiSelectDisplay: React.FC<MultiSelectDisplayProps> = ({
  selectedOptions,
  isOpen,
  setSelectedOptions,
}) => (
  <div className={styles.titleWrapper}>
    <div style={{ display: 'flex' }}>
      {selectedOptions.length >= 5 ? (
        <div className={styles.quantity}>{selectedOptions.length}</div>
      ) : selectedOptions.length > 0 ? (
        selectedOptions.map((o, index) => (
          <div key={o.id} className={styles.selectedOption}>
            {o.name}
            {index < selectedOptions.length - 1 && ', '}
          </div>
        ))
      ) : (
        <div>Choose here</div>
      )}
    </div>
    {isOpen && selectedOptions.length > 0 && (
      <div onClick={() => setSelectedOptions([])} className={styles.clearIcon}>
        x
      </div>
    )}
  </div>
);

export default MultiSelectDisplay;
