import { useSelector } from 'react-redux';
import { Redirect, useLocation } from 'react-router-dom';
import React from 'react';
import classNames from 'classnames';

import { NotPermissionPage } from '@pages/notPermission';
import { isAuthorizedSelector } from '@redux/auth/authSelectors';
import { Routes } from '../const/routes';

export const RequireAuth = ({ children, booleanPermission }) => {
  const isAuthenticated = useSelector(isAuthorizedSelector);
  const location = useLocation();

  if (isAuthenticated && booleanPermission) {
    return children;
  }

  if (isAuthenticated && !booleanPermission) {
    return (
      <>
        {children.length > 1 && children[0]}
        <div className={classNames('app__content-section')}>
          <NotPermissionPage />
        </div>
      </>
    );
  }

  return <Redirect to={{ pathname: Routes.LoginRoute, state: { from: location } }} />;
};
