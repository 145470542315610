import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';

import { MODALS } from '../../containers/Modal/modalConstants';
import { getModalDataSelector } from '../../containers/Modal/modalSelectors';
import { GreenSwitch } from '../../common/Switch/Switch';
import { WarningIcon } from '../../../icons/warning-icon';
import { InfoIcon } from '../../../icons/info-icon';
import { ModalContainer } from '../../containers/Modal/ModalContainer';
import { BcTextField } from '../../common/fields/TextField';
import { BcSelect } from '../../common/Select';
import { Button } from '../../common/Button';
import { flowsApi } from '../../../api/flowsApi';
import { groupCampaignsApi } from '../../../api/groupCampaigns';

import './createPaymentModal.scss';

const styles = {
  width: '30%',
};

export const CreatePaymentModal = () => {
  const { modalData } = useSelector(getModalDataSelector);

  const [flows, setFlows] = useState([]);
  const [activeButton, setActiveButton] = useState(0);
  const [error, setError] = useState(false);

  const [form, setForm] = useState({
    bot: modalData.companyBot,
    name: modalData.item && modalData.item.name ? modalData.item.name : null,
    type: modalData.item && modalData.item.type ? modalData.item.type : null,
    should_subscribe_user:
      modalData.item && modalData.item.should_subscribe_user
        ? modalData.item.should_subscribe_user
        : false,
    boleto_created:
      modalData.item && modalData.item.boleto_created
        ? modalData.item.boleto_created
        : null,
    paid: modalData.item && modalData.item.paid ? modalData.item.paid : null,
    request_to_refund:
      modalData.item && modalData.item.request_to_refund
        ? modalData.item.request_to_refund
        : null,
    refund_is_made:
      modalData.item && modalData.item.refund_is_made
        ? modalData.item.refund_is_made
        : null,
    boleto_is_not_paid:
      modalData.item && modalData.item.boleto_is_not_paid
        ? modalData.item.boleto_is_not_paid
        : null,
  });

  useEffect(() => {
    flowsApi.getFlows(modalData.companyBot).then(res => {
      setFlows([
        { label: 'Trigger will not be activated', value: null },
        ...res.map(item => ({ label: item.name, value: item.id })),
      ]);
    });
  }, []);

  useEffect(() => {
    setForm({ ...form, type: activeButton });
  }, [activeButton]);

  const handleCreatePayment = () => {
    if (modalData.item) {
      groupCampaignsApi.updatePayment(modalData.item.id, form).then(res => {
        modalData.setUpdatePayments(res.data.id);
      });
    } else {
      groupCampaignsApi.createPayment(form).then(res => {
        modalData.setUpdatePayments(res.data.id);
      });
    }

    modalData.onClose();
  };

  const handleError = event => {
    if (event.target.value.length === 0) {
      setError(true);
    } else {
      setError(false);
    }
  };

  return (
    <ModalContainer
      modal={MODALS.payment}
      onClose={modalData.onClose}
      customStyles={styles}
    >
      <div className="payment-created-modal">
        <div className="payment-created-modal__header">New Payment Integration</div>
        <div className="payment-created-modal__container">
          <div className="payment-created-modal__container__field">
            Name this Integrations*:
            <BcTextField
              placeholder="Name of integrations"
              required
              error={error}
              fullWidth
              onBlur={handleError}
              value={form.name}
              onChange={event => setForm({ ...form, name: event.target.value })}
            />
          </div>
          <div className="payment-created-modal__button__container">
            <Button
              style={{ borderRadius: '8px 0 0 8px' }}
              width="33%"
              customClass={
                activeButton === 0
                  ? 'payment-created-modal__button__active'
                  : 'payment-created-modal__button'
              }
              onClick={() => setActiveButton(0)}
              disabled={modalData.item}
            >
              Eduzz
            </Button>
            <Button
              style={{ borderRadius: '0 0 0 0' }}
              width="33%"
              customClass={
                activeButton === 1
                  ? 'payment-created-modal__button__active'
                  : 'payment-created-modal__button'
              }
              onClick={() => setActiveButton(1)}
              disabled={modalData.item}
            >
              Hotmart
            </Button>
            <Button
              style={{ borderRadius: '0 8px 8px 0' }}
              width="33%"
              customClass={
                activeButton === 2
                  ? 'payment-created-modal__button__active'
                  : 'payment-created-modal__button'
              }
              onClick={() => setActiveButton(2)}
              disabled={modalData.item}
            >
              Monetizze
            </Button>
          </div>
          <div className="payment-created-modal__container__field__checkbox">
            Should Subscribe User:
            <GreenSwitch
              defaultChecked={form.should_subscribe_user}
              onChange={event =>
                setForm({ ...form, should_subscribe_user: event.target.checked })
              }
            />
            <Grid item>
              <Tooltip
                followCursor
                placement="right"
                title="If u select this checkbox u can bla-bla"
              >
                <IconButton>
                  <InfoIcon height={25} width={25} color="orange" />
                </IconButton>
              </Tooltip>
            </Grid>
          </div>
          <div className="payment-created-modal__container__warning">
            <div>
              <WarningIcon color="orange" />
            </div>
            <div className="payment-created-modal__container__warning__text">
              Warning: if u send flow wheare triggers u can get banned on WhatsApp for
              spam
            </div>
          </div>
          <div className="payment-created-modal__container__field">
            Select Boleto Created Trigger:
            <BcSelect
              outlined
              value={flows.filter(el => el.value === form.boleto_created)}
              options={flows}
              onChange={value => setForm({ ...form, boleto_created: value.value })}
              placeholder="Trigger will not be activated"
            />
          </div>
          <div className="payment-created-modal__container__field">
            Select Paid Trigger:
            <BcSelect
              outlined
              value={flows.filter(el => el.value === form.paid)}
              options={flows}
              onChange={value => setForm({ ...form, paid: value.value })}
              placeholder="Trigger will not be activated"
            />
          </div>
          <div className="payment-created-modal__container__field">
            Select Request To Refund Trigger:
            <BcSelect
              outlined
              value={flows.filter(el => el.value === form.request_to_refund)}
              options={flows}
              onChange={value => setForm({ ...form, request_to_refund: value.value })}
              placeholder="Trigger will not be activated"
            />
          </div>
          <div className="payment-created-modal__container__field">
            Select Refund Is Made Trigger:
            <BcSelect
              outlined
              value={flows.filter(el => el.value === form.refund_is_made)}
              options={flows}
              onChange={value => setForm({ ...form, refund_is_made: value.value })}
              placeholder="Trigger will not be activated"
            />
          </div>
          <div className="payment-created-modal__container__field">
            Select Boleto Is Not Paid Trigger:
            <BcSelect
              outlined
              value={flows.filter(el => el.value === form.boleto_is_not_paid)}
              options={flows}
              onChange={value => setForm({ ...form, boleto_is_not_paid: value.value })}
              placeholder="Trigger will not be activated"
            />
          </div>
          <div className="payment-created-modal__container__button">
            <Button disabled={!form.name} onClick={handleCreatePayment}>
              {modalData.item
                ? 'Update Payment Integrations'
                : 'Create Payment Integrations'}
            </Button>
          </div>
        </div>
      </div>
    </ModalContainer>
  );
};
