import React, { useEffect, useState } from 'react';
import Select from 'react-select';

import { useInternationalization } from '../../../../hooks/useTranslationHook';
import { GreenSwitch } from '../../../common/Switch/Switch';
import { groupCampaignsApi } from '../../../../api/groupCampaigns';

import '../createGroupCampaignModal.scss';

const customStyles = {
  option: (provided, state) => ({
    ...provided,
    zIndex: '1000000',
    backgroundColor: state.isSelected || state.isFocused ? '#F4F4F4' : 'white',
    color: 'black',
  }),
  control: (styles, state) => ({
    ...styles,
    borderColor: '#E5E5E5',
    backgroundColor: '#FFFFFF',
    boxShadow: state.isFocused || state.isSelected ? 'none' : 'none',
    '&:hover': {
      border: '2px solid rgba(128, 97, 255, 1);',
    },
  }),
};

export const CreateGroupCampaignsPart2 = ({ form, setForm, botId }) => {
  const { formatMessage } = useInternationalization();
  const [payments, setPayments] = useState();

  useEffect(() => {
    groupCampaignsApi.getPayments(botId).then(res => {
      setPayments(
        res.data.map(el => ({
          label: el.name,
          value: el.id,
        })),
      );
    });
  }, []);

  return (
    <div style={{ padding: '0 18%' }}>
      <div className="group-campaigns-created-modal__container">
        <h2 className="group-campaigns-created-modal__name__reduced_indent">
          Only for admins should send message?
        </h2>
        <GreenSwitch
          onChange={event => {
            setForm({ ...form, only_admins_can_send_messages: event.target.checked });
          }}
        />
      </div>
      {/*<div className="group-campaigns-created-modal__container">*/}
      {/*  <h2 className="group-campaigns-created-modal__name__reduced_indent">*/}
      {/*    Remove users from multiple groups?*/}
      {/*  </h2>*/}
      {/*  <GreenSwitch />*/}
      {/*</div>*/}
      {/*<h2 className="group-campaigns-created-modal__name__reduced_indent">*/}
      {/*  Message to be sent before removing user:*/}
      {/*</h2>*/}
      {/*<textarea*/}
      {/*  placeholder={formatMessage(*/}
      {/*    `Example: 'You are being removed because you are already participating in another group. Mulitple entrance is prohibited.'`*/}
      {/*  )}*/}
      {/*  className="group-campaigns-created-modal__textarea"*/}
      {/*  // defaultValue={form.name}*/}
      {/*  // onChange={(event) => {*/}
      {/*  //     setForm({ ...form, name: event.target.value });*/}
      {/*  // }}*/}
      {/*/>*/}
      <h2 className="group-campaigns-created-modal__name__reduced_indent">
        Please insert numbers of admins to be added to groups:
      </h2>
      <textarea
        placeholder={formatMessage('Insert one number per line (Ex.: +5511999999999)')}
        className="group-campaigns-created-modal__textarea"
        defaultValue={form.admins}
        onChange={event => {
          setForm({ ...form, admins: event.target.value });
        }}
      />
      <h2 className="group-campaigns-created-modal__name__reduced_indent">
        Spam keywords:
      </h2>
      <textarea
        placeholder={formatMessage('Spam keywords...')}
        className="group-campaigns-created-modal__textarea"
        defaultValue={form.spam_keywords}
        onChange={event => {
          setForm({ ...form, spam_keywords: event.target.value });
        }}
      />
      <h2 className="group-campaigns-created-modal__name__reduced_indent">
        Select payment webhooks:
      </h2>
      <Select
        styles={customStyles}
        onChange={value => {
          setForm({ ...form, payment_webhooks: value.map(el => el.value) });
        }}
        isMulti
        closeMenuOnSelect={false}
        options={payments}
        menuPlacement="auto"
        placeholder={formatMessage('Select...')}
      />
    </div>
  );
};
