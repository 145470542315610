import {
  OPEN_CHATS_CONNECTION_REQUEST,
  OPEN_CHATS_CONNECTION_SUCCESS,
  CLOSE_CHATS_CONNECTION_SUCCESS,
  GET_CHATS_REQUEST,
  GET_CHATS_SUCCESS,
  GET_CHATS_GROUP_REQUEST,
  GET_CHATS_GROUP_SUCCESS,
  GET_MESSAGES_REQUEST,
  GET_MESSAGES_SUCCESS,
  GET_SEND_MESSAGE,
  UPDATE_CHAT_MESSAGE,
  DOWNLOAD_FILE_MESSAGE_SUCCESS,
  CHANGE_BUSY_STATUS,
  CHANGE_CHAT_AVATAR,
  CHANGE_CHAT_NAME,
  CHANGE_CHAT_COUNT,
  DISABLE_LOADING,
  GET_CONNECTION_STATUS_SUCCESS,
  SET_CHAT_INFO_FROM_AUDIENCE,
  FILTER_CHAT_BY_ID,
  CHANGE_CASE_STATUS,
  SET_CASE_FILTER,
  HANDLE_CHATS_CASE_UPDATING,
  HANDLE_NEW_MESSAGE,
  SET_CHAT_ROOM,
  ADD_NEW_CHAT,
  HANDLE_CHATS_BUSY_UPDATING,
  SET_CURRENT_FILTER,
  UPDATE_CHAT_READ_STATUS,
  CHECK_CHAT_READ_STATUS,
  SET_CHATS_LOADING,
  SET_SOUND_NOTIFICATION,
  SET_PAUSE_AUTOMATION,
  SET_SEARCH_VALUE,
  SET_REPLIED_MESSAGE,
  SET_USER_CARD,
  GET_USER_CARD_REQUEST,
  GET_CHAT_REQUEST,
  CHOOSE_CHAT,
  ADD_SCROLL_BOTTOM,
} from './chatConstants';

export const chatActions = {
  //Request on open chatSocket connection
  openChatsConnection(data) {
    return {
      type: OPEN_CHATS_CONNECTION_REQUEST,
      payload: data,
    };
  },

  //Open chatSocket connection success
  openConnectionSuccess(chatSocket) {
    return {
      type: OPEN_CHATS_CONNECTION_SUCCESS,
      payload: chatSocket,
    };
  },

  getConnectionStatusSuccess(status) {
    return {
      type: GET_CONNECTION_STATUS_SUCCESS,
      payload: status,
    };
  },

  //Close chatSocket connection
  closeConnectionSuccess() {
    return {
      type: CLOSE_CHATS_CONNECTION_SUCCESS,
    };
  },

  //Request on chat rooms list
  getChatsRequest() {
    return {
      type: GET_CHATS_REQUEST,
    };
  },

  //Get chat rooms list success
  getChatsSuccess(data) {
    return {
      type: GET_CHATS_SUCCESS,
      payload: data,
    };
  },

  //Request on group chat rooms list
  getChatsGroupRequest() {
    return {
      type: GET_CHATS_GROUP_REQUEST,
    };
  },

  //Get group chat rooms list success
  getChatsGroupSuccess(data) {
    return {
      type: GET_CHATS_GROUP_SUCCESS,
      payload: data,
    };
  },

  //Switch on some chat room
  chooseCurrentChat(data) {
    return {
      type: CHOOSE_CHAT,
      payload: data,
    };
  },

  //Request to get messages from choosen chat room
  getMessagesRequest() {
    return {
      type: GET_MESSAGES_REQUEST,
    };
  },

  //Get messages from chooses chat room
  getMessagesSuccess(data) {
    return {
      type: GET_MESSAGES_SUCCESS,
      payload: data,
    };
  },

  //mark loading message as false
  disableLoading() {
    return {
      type: DISABLE_LOADING,
    };
  },

  //Get message from sender or you
  getSendMessage(data) {
    return {
      type: GET_SEND_MESSAGE,
      payload: data,
    };
  },

  //Update messages statuses and chats count
  updateChatMessage(data) {
    return {
      type: UPDATE_CHAT_MESSAGE,
      payload: data,
    };
  },

  //Download file message
  downloadFileMessageSuccess(data) {
    return {
      type: DOWNLOAD_FILE_MESSAGE_SUCCESS,
      payload: data,
    };
  },

  //Change chat busy status
  changeBusyStatus(data) {
    return {
      type: CHANGE_BUSY_STATUS,
      payload: data,
    };
  },

  changeCaseStatus(data) {
    return {
      type: CHANGE_CASE_STATUS,
      payload: data,
    };
  },

  filterChatsById(chatId) {
    return {
      type: FILTER_CHAT_BY_ID,
      payload: { chatId },
    };
  },

  //Change chat avatar
  changeChatAvatar(data) {
    return {
      type: CHANGE_CHAT_AVATAR,
      payload: data,
    };
  },

  //Change chat name
  changeChatName(data) {
    return {
      type: CHANGE_CHAT_NAME,
      payload: data,
    };
  },

  //Change chat count
  changeChatCount(data) {
    return {
      type: CHANGE_CHAT_COUNT,
      payload: data,
    };
  },

  setChatInfoFromAudience(data) {
    return {
      type: SET_CHAT_INFO_FROM_AUDIENCE,
      payload: data,
    };
  },

  setCaseFilter(caseFilter) {
    return {
      type: SET_CASE_FILTER,
      payload: { caseFilter },
    };
  },

  handleChatsOnCaseFilterUpdating(chatData) {
    return {
      type: HANDLE_CHATS_CASE_UPDATING,
      payload: { chatData },
    };
  },

  handleNewMessage(data) {
    return {
      type: HANDLE_NEW_MESSAGE,
      payload: data,
    };
  },

  setChatRoom(chatRoom) {
    return {
      type: SET_CHAT_ROOM,
      payload: { chatRoom },
    };
  },

  addNewChat(data) {
    return {
      type: ADD_NEW_CHAT,
      payload: data,
    };
  },

  handleChatsOnBusyUpdating(chatData, leftChat, currentFilter) {
    return {
      type: HANDLE_CHATS_BUSY_UPDATING,
      payload: { chatData, leftCurrentChat: leftChat, currentFilter },
    };
  },

  setCurrentFilter(currentFilter) {
    return {
      type: SET_CURRENT_FILTER,
      payload: { currentFilter },
    };
  },

  checkChatReadStatus(id, status) {
    return {
      type: CHECK_CHAT_READ_STATUS,
      payload: { id, status },
    };
  },

  updateChatReadStatus(updatedChats) {
    return {
      type: UPDATE_CHAT_READ_STATUS,
      payload: { updatedChats },
    };
  },

  setChatLoading(isChatsLoading) {
    return {
      type: SET_CHATS_LOADING,
      payload: isChatsLoading,
    };
  },

  setSoundNotification(isSoundNotification) {
    return {
      type: SET_SOUND_NOTIFICATION,
      payload: isSoundNotification,
    };
  },

  setPauseAutomation(pauseData) {
    return {
      type: SET_PAUSE_AUTOMATION,
      payload: pauseData,
    };
  },

  setSearchValue(searchValue) {
    return {
      type: SET_SEARCH_VALUE,
      payload: searchValue,
    };
  },

  setRepliedMessage(repliedMessage) {
    return {
      type: SET_REPLIED_MESSAGE,
      payload: repliedMessage,
    };
  },

  setUserCard(userCardData) {
    return {
      type: SET_USER_CARD,
      payload: { userCardData },
    };
  },

  getUserCardRequest(userId, jId, botId) {
    return {
      type: GET_USER_CARD_REQUEST,
      payload: { userId, jId, botId },
    };
  },

  getChatRequest() {
    return {
      type: GET_CHAT_REQUEST,
    };
  },

  addScrollBottom(scrollBottom) {
    return {
      type: ADD_SCROLL_BOTTOM,
      payload: { scrollBottom },
    };
  },

  addNewChatRequest(newChat) {
    return {
      type: 'ADD_NEW_CHAT_REQUEST',
      payload: { newChat },
    };
  },

  updateChats(updatedChats) {
    return {
      type: 'UPDATE_CHATS',
      payload: { updatedChats },
    };
  },
};
