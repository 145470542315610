export const flowsActionsTypes = {
  GET_FLOWS_REQUEST: 'GET_FLOWS_REQUEST',
  GET_FLOWS_NEXT: 'GET_FLOWS_NEXT',
  GET_FLOWS_SUCCESS: 'GET_FLOWS_SUCCESS',
  GET_FLOWS_NEXT_SUCCESS: 'GET_FLOWS_NEXT_SUCCESS',
  CREATE_FLOW_REQUEST: 'CREATE_FLOW_REQUEST',
  CREATE_FLOW_ERROR: 'CREATE_FLOW_ERROR',
  RENAME_FLOW_REQUEST: 'RENAME_FLOW_REQUEST',
  RENAME_FLOW_SUCCESS: 'RENAME_FLOW_SUCCESS',
  UPDATE_FLOW_REQUEST: 'UPDATE_FLOW_REQUEST',
  UPDATE_FLOW_SUCCESS: 'UPDATE_FLOW_SUCCESS',
  DELETE_FLOW_REQUEST: 'DELETE_FLOW_REQUEST',
  DELETE_FLOW_FAILURE: 'DELETE_FLOW_FAILURE',
  MOVE_FLOW_REQUEST: 'MOVE_FLOW_REQUEST',
  DUPLICATE_FLOW_REQUEST: 'DUPLICATE_FLOW_REQUEST',
  SEARCH_FLOW_REQUEST: 'SEARCH_FLOW_REQUEST',
  SEARCH_FLOW_REQUEST_SUCCESS: 'GET_SEARCH_FLOW_SUCCESS',
  GET_FLOW_NAME: 'GET_FLOW_NAME',
};
