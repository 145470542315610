import React from 'react';

export const DeleteIcon = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.91663 5.25625C2.91663 4.92877 3.1809 4.6633 3.5069 4.6633H16.493C16.819 4.6633 17.0833 4.92877 17.0833 5.25625C17.0833 5.58372 16.819 5.8492 16.493 5.8492H3.5069C3.1809 5.8492 2.91663 5.58372 2.91663 5.25625Z"
      fill="#D92D20"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.22913 8.22099C8.55513 8.22099 8.8194 8.48646 8.8194 8.81394V13.5575C8.8194 13.885 8.55513 14.1505 8.22913 14.1505C7.90312 14.1505 7.63885 13.885 7.63885 13.5575V8.81394C7.63885 8.48646 7.90312 8.22099 8.22913 8.22099Z"
      fill="#D92D20"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.7708 8.22099C12.0968 8.22099 12.3611 8.48646 12.3611 8.81394V13.5575C12.3611 13.885 12.0968 14.1505 11.7708 14.1505C11.4448 14.1505 11.1805 13.885 11.1805 13.5575V8.81394C11.1805 8.48646 11.4448 8.22099 11.7708 8.22099Z"
      fill="#D92D20"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.68746 4.6633C5.01346 4.6633 5.27774 4.92877 5.27774 5.25625V16.5223H14.7222V5.25625C14.7222 4.92877 14.9865 4.6633 15.3125 4.6633C15.6385 4.6633 15.9027 4.92877 15.9027 5.25625V16.5223C15.9027 16.8368 15.7784 17.1384 15.557 17.3608C15.3356 17.5832 15.0353 17.7082 14.7222 17.7082H5.27774C4.96464 17.7082 4.66436 17.5832 4.44296 17.3608C4.22156 17.1384 4.09718 16.8368 4.09718 16.5223V5.25625C4.09718 4.92877 4.36146 4.6633 4.68746 4.6633Z"
      fill="#D92D20"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.97696 2.81252C7.30905 2.47892 7.75947 2.2915 8.22913 2.2915H11.7708C12.2404 2.2915 12.6909 2.47892 13.023 2.81252C13.3551 3.14611 13.5416 3.59857 13.5416 4.07035V5.25625C13.5416 5.58372 13.2774 5.8492 12.9513 5.8492C12.6253 5.8492 12.3611 5.58372 12.3611 5.25625V4.07035C12.3611 3.91309 12.2989 3.76227 12.1882 3.65107C12.0775 3.53987 11.9273 3.4774 11.7708 3.4774H8.22913C8.07257 3.4774 7.92244 3.53987 7.81174 3.65107C7.70104 3.76227 7.63885 3.91309 7.63885 4.07035V5.25625C7.63885 5.58372 7.37457 5.8492 7.04857 5.8492C6.72257 5.8492 6.45829 5.58372 6.45829 5.25625V4.07035C6.45829 3.59857 6.64486 3.14611 6.97696 2.81252Z"
      fill="#D92D20"
    />
  </svg>
);
