import React, {
  ChangeEvent, ReactNode, useEffect, useState,
} from 'react';
import { EComponentType, EIconDirection, EInputType } from '@wabb-ui-kit/FormField/types/types';
import { useFormFieldStyles } from '@wabb-ui-kit/FormField/utils';
import RedCross from '../../wabb-assets/svg/FormField/red-cross.svg';
import InputCross from '../../wabb-assets/svg/FormField/input-cross.svg';
import EyeIcon from '../../wabb-assets/svg/FormField/eye-icon.svg';
import CloseEyeIcon from '../../wabb-assets/svg/FormField/close-eye-icon.svg';
import styles from './FormField.module.scss';

interface IFormFieldProps {
  Component: EComponentType;
  label?: string;
  value: string;
  name?: string;
  placeholder?: string;
  className?: string;
  errorText?: string;
  infoText?: string;
  isDisabled?: boolean;
  onChange: (e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => void;
  icon?: ReactNode;
  onCrossClick?: () => void;
  onBlur?: () => void;
  maxLength?: number;
  width?: string;
  height?: string;
  type?: EInputType;
  isRequired?: boolean;
  iconDirection?: EIconDirection;
  autoComplete?: string;
}

const FormField = ({
  Component = EComponentType.Input,
  label = '',
  value = '',
  name = '',
  className = '',
  placeholder = '',
  errorText = '',
  infoText,
  isDisabled = false,
  icon,
  onChange,
  onCrossClick,
  onBlur,
  maxLength = 500,
  width,
  height,
  autoComplete,
  type = EInputType.Text,
  isRequired = false,
  iconDirection = EIconDirection.Right,
}: IFormFieldProps) => {
  const [fieldType, setFieldType] = useState('');
  const { fieldClassNames, buttonClassName } = useFormFieldStyles(
    className,
    Component,
    type,
    errorText,
    isDisabled,
    iconDirection,
    value,
  );

  const handleChange = (e) => {
    if (e.target.value.length <= maxLength) {
      onChange(e);
    }
  };

  useEffect(() => {
    setFieldType(type);
  }, [type]);

  return (
    <>
      {label && (
        <label htmlFor={name} className={styles.label}>
          {label}
          {isRequired && <span className={styles.required}>*</span>}
        </label>
      )}
      <div
        className={`${styles.inputContainer} ${
          iconDirection === EIconDirection.Right ? styles.iconRight : styles.iconLeft
        } ${isDisabled ? styles.disabled : ''}`}
      >
        <Component
          id={name}
          name={name}
          placeholder={placeholder}
          value={value}
          onChange={handleChange}
          className={fieldClassNames}
          disabled={isDisabled}
          type={fieldType}
          style={{ width, height }}
          onBlur={onBlur}
          autoComplete={autoComplete}
        />
        {(value && type !== EInputType.Password) ? (
          <button
            onClick={() => onCrossClick?.()}
            className={
              `${Component === EComponentType.Input ? styles.icon : styles.textAreaIcon}
                ${isDisabled ? styles.disabled : ''}`
            }
          >
            <img src={InputCross} />
          </button>
        ) : (
          icon && <div className={buttonClassName}>{icon}</div>
        )}
        {
          (type === EInputType.Password) && (
            <button
              type="button"
              onClick={() => setFieldType(fieldType === EInputType.Password
                ? EInputType.Text : EInputType.Password)}
              className={
                `${Component === EComponentType.Input ? styles.icon : styles.textAreaIcon}
                ${isDisabled ? styles.disabled : ''}`
              }
            >
              <img src={fieldType === EInputType.Password ? CloseEyeIcon : EyeIcon} />
            </button>
          )
        }
        {Component === EComponentType.TextArea && (
          <span className={styles.charCounter}>
            {value.length}
            /
            {maxLength}
          </span>
        )}
        {errorText && (
          <div className={styles.errorContainer}>
            {errorText && <img src={RedCross} />}
            <p className={styles.error}>{errorText}</p>
          </div>
        )}
      </div>
      {infoText && <p className={styles.infoText}>{infoText}</p>}
    </>
  );
};

export default FormField;
