import React from 'react';

export const StringVariableIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="currentColor"
  >
    <g clip-path="url(#clip0_4470_45232)">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M-1.5 0.648649C-1.5 0.29041 -1.20326 0 -0.837209 0H11.5349C11.9009 0 12.1977 0.29041 12.1977 0.648649C12.1977 1.00689 11.9009 1.2973 11.5349 1.2973H-0.837209C-1.20326 1.2973 -1.5 1.00689 -1.5 0.648649Z"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M9.10465 6.27027C9.10465 5.91203 9.40139 5.62162 9.76744 5.62162H16.8372C17.2033 5.62162 17.5 5.91203 17.5 6.27027C17.5 6.62851 17.2033 6.91892 16.8372 6.91892H9.76744C9.40139 6.91892 9.10465 6.62851 9.10465 6.27027Z"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M5.34884 0C5.71489 0 6.01163 0.29041 6.01163 0.648649V15.3514C6.01163 15.7096 5.71489 16 5.34884 16C4.98279 16 4.68605 15.7096 4.68605 15.3514V0.648649C4.68605 0.29041 4.98279 0 5.34884 0Z"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M13.3023 5.62162C13.6684 5.62162 13.9651 5.91203 13.9651 6.27027V15.3514C13.9651 15.7096 13.6684 16 13.3023 16C12.9363 16 12.6395 15.7096 12.6395 15.3514V6.27027C12.6395 5.91203 12.9363 5.62162 13.3023 5.62162Z"
      />
    </g>
    <defs>
      <clipPath id="clip0_4470_45232">
        <rect width="16" height="16" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
