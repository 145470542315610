import {
  GET_VARIABLES_REQUEST,
  GET_VARIABLES_SUCCESS,
  GET_BOT_VARIABLES_REQUEST,
  GET_BOT_VARIABLES_SUCCESS,
  CREATE_VARIABLE_REQUEST,
  CREATE_VARIABLE_ERROR,
  UPDATE_VARIABLES_SUCCESS,
  UPDATE_BOT_VARIABLES_SUCCESS,
} from './variablesConstants';

const initialState = {
  variables: [],
  botVariables: [],
  variablesErrors: '',
};

export const variablesReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_VARIABLES_REQUEST:
      return {
        ...state,
      };

    case GET_VARIABLES_SUCCESS:
      const { data } = action.payload;
      return {
        ...state,
        variables: [...data],
        variablesErrors: '',
      };

    case GET_BOT_VARIABLES_REQUEST:
      return {
        ...state,
      };

    case GET_BOT_VARIABLES_SUCCESS:
      return {
        ...state,
        botVariables: [...action.payload],
        variablesErrors: '',
      };

    case CREATE_VARIABLE_REQUEST:
      return {
        ...state,
        variablesErrors: '',
      };

    case CREATE_VARIABLE_ERROR:
      return {
        ...state,
        variablesErrors: action.payload,
      };

    case UPDATE_VARIABLES_SUCCESS:
      return {
        ...state,
        variables: [
          ...state.variables.filter(el => el.id !== action.payload.id),
          action.payload,
        ],
      };

    case UPDATE_BOT_VARIABLES_SUCCESS:
      return {
        ...state,
        botVariables: [
          ...state.botVariables.filter(el => el.id !== action.payload.id),
          action.payload,
        ],
      };

    default:
      return state;
  }
};
