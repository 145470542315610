import { flowsActionsTypes } from './flowsActionsTypes';

export const flowsActions = {
  getFlowsRequest(flowsObj) {
    return {
      type: flowsActionsTypes.GET_FLOWS_REQUEST,
      payload: flowsObj,
    };
  },

  getNextFlowsRequest(nextFlowsObj) {
    return {
      type: flowsActionsTypes.GET_FLOWS_NEXT,
      payload: nextFlowsObj,
    };
  },

  getFlowsSuccess(data) {
    return {
      type: flowsActionsTypes.GET_FLOWS_SUCCESS,
      payload: { data },
    };
  },

  getNextFlowsSuccess(data) {
    return {
      type: flowsActionsTypes.GET_FLOWS_NEXT_SUCCESS,
      payload: { data },
    };
  },

  createFlowRequest(newFlowObj) {
    return {
      type: flowsActionsTypes.CREATE_FLOW_REQUEST,
      payload: newFlowObj,
    };
  },

  createFlowFailure(error) {
    return {
      type: flowsActionsTypes.CREATE_FLOW_ERROR,
      payload: error,
    };
  },

  renameFlowRequest(renameFlowObj) {
    return {
      type: flowsActionsTypes.RENAME_FLOW_REQUEST,
      payload: renameFlowObj,
    };
  },

  updateFlowRequest(updateFlowObj) {
    return {
      type: flowsActionsTypes.UPDATE_FLOW_REQUEST,
      payload: updateFlowObj,
    };
  },

  renameFlowSuccess(renameFlowObj) {
    return {
      type: flowsActionsTypes.RENAME_FLOW_SUCCESS,
      payload: renameFlowObj,
    };
  },

  updateFlowSuccess(renameFlowObj) {
    return {
      type: flowsActionsTypes.UPDATE_FLOW_SUCCESS,
      payload: renameFlowObj,
    };
  },

  duplicateFlowRequest(duplicateFlowObj) {
    return {
      type: flowsActionsTypes.DUPLICATE_FLOW_REQUEST,
      payload: duplicateFlowObj,
    };
  },

  deleteFlowRequest(deleteFlowObj) {
    return {
      type: flowsActionsTypes.DELETE_FLOW_REQUEST,
      payload: deleteFlowObj,
    };
  },

  moveFlowRequest(moveFlowObj) {
    return {
      type: flowsActionsTypes.MOVE_FLOW_REQUEST,
      payload: moveFlowObj,
    };
  },

  getSearchFlowRequest(data) {
    return {
      type: flowsActionsTypes.SEARCH_FLOW_REQUEST,
      payload: data,
    };
  },

  getSearchFlowsSuccess(data) {
    return {
      type: flowsActionsTypes.SEARCH_FLOW_REQUEST_SUCCESS,
      payload: { searchData: data },
    };
  },

  getFlowName(label) {
    return {
      type: flowsActionsTypes.GET_FLOW_NAME,
      payload: { label },
    };
  },
};
