import { navigationMenuConstants } from './navigationMenuConstants';

const initialState = {
  open: 'open',
  isShowHeader: true,
};

export const navigationMenuReducer = (state = initialState, action) => {
  switch (action.type) {
    case navigationMenuConstants.OPEN_MENU:
      return { ...state, open: 'open' };
    case navigationMenuConstants.CLOSE_MENU:
      return { ...state, open: 'close' };
    case navigationMenuConstants.SHOW_HEADER:
      return { ...state, isShowHeader: true };
    case navigationMenuConstants.HIDE_HEADER:
      return { ...state, isShowHeader: false };
    default:
      return state;
  }
};
