import { all, takeLatest, put, select } from 'redux-saga/effects';
import {  ConnectError, Code } from '@connectrpc/connect';
import io from 'socket.io-client';
import {
  getChatRoomSelector,
  getChatsCaseFilter,
  getChatSelector,
  getChatsSelector,
  getCurrentChatIdSelector,
  getCurrentFilterSelector,
  getScrollToBottomSelector,
  getSearchValueSelector,
  getSoundNotificationSelector,
  getUserCardDataSelector,
} from '@redux/liveChat/chatSelector';
import { getUserIdSelector } from '@redux/auth/authSelectors';
import { audienceApi } from '@api/audience';
import { chatActions } from './chatActions';
import {
  ADD_NEW_CHAT,
  CHECK_CHAT_READ_STATUS,
  GET_USER_CARD_REQUEST,
  HANDLE_CHATS_BUSY_UPDATING,
  HANDLE_CHATS_CASE_UPDATING,
  HANDLE_NEW_MESSAGE,
  OPEN_CHATS_CONNECTION_REQUEST,
  SET_PAUSE_AUTOMATION,
} from './chatConstants';
import notificationSound from '../../audio/notification.ogg';

const checkUpdatedCase = ({ updatedCase, currentCaseValue }) => {
  if (currentCaseValue === 'all') {
    return true;
  }

  return (
    (updatedCase && currentCaseValue === 'open') ||
    (!updatedCase && currentCaseValue === 'close')
  );
};

const checkCurrentRoom = ({ updatedBusyStatus, currentRoomValue, isCurrentUser }) => {
  if (currentRoomValue === 'all') {
    return true;
  }

  return (
    (updatedBusyStatus && currentRoomValue === 'my' && isCurrentUser) ||
    (!updatedBusyStatus && currentRoomValue === 'opened')
  );
};

function* handleChats({
  chats,
  successCondition,
  isCurrentCase,
  isCurrentRoom,
  chatId,
  successFindChatAction,
  chatData,
  userCardData,
  leftCurrentChat,
}) {
  const { chatSocket } = yield select(getChatSelector);
  const currentFilter = yield select(getCurrentFilterSelector);
  const currentChatId = yield select(getCurrentChatIdSelector);
  const searchValue = yield select(getSearchValueSelector);
  const userData = yield select(getUserCardDataSelector);

  if (successCondition) {
    yield put(chatActions.filterChatsById(chatId));

    if (leftCurrentChat && currentChatId === chatId) {
      leftCurrentChat();
    }
  } else if (chats.find(({ id }) => id === chatId)) {
    yield put(chatActions?.[successFindChatAction](chatData));

    if (chatId === currentChatId) {
      yield put(chatActions.setUserCard({ ...userData, ...userCardData }));
    }
  } else if (isCurrentCase && isCurrentRoom && !currentFilter && !searchValue) {
    chatSocket.emit('get-chat', { id: chatId });
  }
}

function* openChatsConnectionRequestSaga(action) {
  try {
    const { companyBot, serverUrl, serverNumber, instanceId } = action.payload;
    const { chatSocket } = yield select(getChatSelector);

    if (chatSocket) {
      chatSocket.disconnect();
      yield put(chatActions.closeConnectionSuccess());
    }

    const token = localStorage.getItem('authToken');

    const socketConnection = io(`${serverUrl}/live_chat`, {
      query: {
        bot: companyBot,
        jwt: token,
        connection_id: serverNumber,
        instance_id: instanceId,
      },
      upgrade: false,
      transports: ['websocket'],
    });

    if (socketConnection) {
      yield put(chatActions.openConnectionSuccess(socketConnection));
      yield put(chatActions.setChatLoading(true));
    }
  } catch (err) { }
}

function* handleUpdatingCaseFilterSaga(action) {
  const { chatData } = action.payload;
  const {
    extraDataForFilters: { isCaseOpened, isBusy, isBusyById },
  } = chatData;
  const { value } = yield select(getChatsCaseFilter);
  const { routeParam } = yield select(getChatRoomSelector);
  const chats = yield select(getChatsSelector);
  const userId = yield select(getUserIdSelector);
  const isCurrentCase = checkUpdatedCase({
    updatedCase: isCaseOpened,
    currentCaseValue: value,
  });
  const isCurrentRoom = checkCurrentRoom({
    updatedBusyStatus: isBusy,
    currentRoomValue: routeParam,
    isCurrentUser: isBusyById === userId,
  });
  const currentChatId = yield select(getCurrentChatIdSelector);
  const userData = yield select(getUserCardDataSelector);

  try {
    switch (value) {
      case 'all':
        yield put(chatActions.changeCaseStatus(chatData));

        if (chatData.id === currentChatId) {
          yield put(
            chatActions.setUserCard({
              ...userData,
              is_case_opened: chatData?.isCaseOpened,
            }),
          );
        }

        break;
      case 'open':
        yield handleChats({
          chats,
          successCondition: !chatData.isCaseOpened,
          isCurrentCase,
          isCurrentRoom,
          chatId: chatData?.id,
          successFindChatAction: 'changeCaseStatus',
          chatData,
          userCardData: { is_case_opened: chatData.isCaseOpened },
        });
        break;
      case 'close':
        yield handleChats({
          chats,
          successCondition: chatData.isCaseOpened,
          isCurrentCase,
          isCurrentRoom,
          chatId: chatData?.id,
          successFindChatAction: 'changeCaseStatus',
          chatData,
          userCardData: { is_case_opened: chatData.isCaseOpened },
        });
    }
  } catch (err) { }
}

function* handleUpdatingBusyStatusSaga(action) {
  const { chatData, leftCurrentChat } = action.payload;
  const {
    extraDataForFilters: { isCaseOpened, isBusy, isBusyById },
  } = chatData;
  const { value } = yield select(getChatsCaseFilter);
  const { routeParam } = yield select(getChatRoomSelector);
  const chats = yield select(getChatsSelector);
  const userId = yield select(getUserIdSelector);
  const isCurrentCase = checkUpdatedCase({
    updatedCase: isCaseOpened,
    currentCaseValue: value,
  });
  const isCurrentRoom = checkCurrentRoom({
    updatedBusyStatus: isBusy,
    currentRoomValue: routeParam,
    isCurrentUser: isBusyById === userId,
  });
  const currentChatId = yield select(getCurrentChatIdSelector);
  const userData = yield select(getUserCardDataSelector);

  try {
    switch (routeParam) {
      case 'all':
        yield put(chatActions.changeBusyStatus(chatData));

        if (chatData.id === currentChatId) {
          chatActions.setUserCard({
            ...userData,
            is_busy: chatData?.isBusy,
            assigned_to: chatData?.isBusyById,
          });
        }

        break;
      case 'my':
        yield handleChats({
          chats,
          successCondition: !chatData.isBusy || isBusyById !== userId,
          isCurrentCase,
          isCurrentRoom,
          chatId: chatData.id,
          successFindChatAction: 'changeBusyStatus',
          chatData,
          userCardData: { is_busy: chatData.isBusy, assigned_to: chatData.isBusyById },
          leftCurrentChat,
        });
        break;
      case 'opened':
        yield handleChats({
          chats,
          successCondition: chatData.isBusy,
          isCurrentCase,
          isCurrentRoom,
          chatId: chatData.id,
          successFindChatAction: 'changeBusyStatus',
          chatData,
          userCardData: { is_busy: chatData.isBusy, assigned_to: chatData.isBusyById },
        });
    }
  } catch (err) { }
}

function* checkAddChatNewMessageSaga(action) {
  const { newMessageData } = action.payload;
  const { chat, message } = newMessageData;
  const { isCaseOpened, id, isBusy, isBusyById } = chat;
  const { value } = yield select(getChatsCaseFilter);
  const { chats } = yield select(getChatSelector);
  const { routeParam } = yield select(getChatRoomSelector);
  const userId = yield select(getUserIdSelector);
  const selectedIdChat = yield select(getCurrentChatIdSelector);
  const isSoundNotification = yield select(getSoundNotificationSelector);
  const isCurrentCase = checkUpdatedCase({
    updatedCase: isCaseOpened,
    currentCaseValue: value,
  });
  const isCurrentRoom = checkCurrentRoom({
    updatedBusyStatus: isBusy,
    currentRoomValue: routeParam,
    isCurrentUser: isBusyById === userId,
  });
  const searchValue = yield select(getSearchValueSelector);
  const scrollToBottom = yield select(getScrollToBottomSelector);
  const audioNotification = new Audio(notificationSound);

  try {
    const chatIndex = chats.findIndex(c => c?.id === id);

    if (
      isBusy &&
      isBusyById === userId &&
      isSoundNotification &&
      id !== selectedIdChat &&
      !message.key.fromMe
    ) {
      void audioNotification?.play();
    }

    if (id === selectedIdChat) {
      scrollToBottom && scrollToBottom();
    }

    switch (true) {
      case selectedIdChat === id:
        //TODO add logic of chat list
        if (chatIndex >= 0) {
          const [currChat] = chats.splice(chatIndex, 1);
          currChat.lastMessage = message;
          chats.unshift(currChat);
        }

        yield put(chatActions.getSendMessage({ ...newMessageData, chats }));
        break;

      default:
        if (chatIndex >= 0) {
          const [currChat] = chats.splice(chatIndex, 1);
          currChat.lastMessage = message;
          chats.unshift(currChat);
          yield put(chatActions.getSendMessage({ ...newMessageData, chats }));
        } else if (isCurrentCase && isCurrentRoom && !searchValue) {
          chat.lastMessage = message;
          chats.unshift(chat);
          yield put(chatActions.getSendMessage({ ...newMessageData, chats }));
        }
    }
  } catch (err) { }
}

function* updateChatReadStatus(action) {
  const chatId = yield select(getCurrentChatIdSelector);
  const chats = yield select(getChatsSelector);
  const { chatSocket } = yield select(getChatSelector);
  const { id, status } = action.payload;

  if (id === chatId && status === false) {
    chatSocket.emit('change-read-status', { id, isChatRead: true });
  } else {
    const updatedChats = chats.map(chat => {
      if (chat.id === id) {
        return { ...chat, isChatRead: status };
      }

      return chat;
    });
    yield put(chatActions.updateChatReadStatus(updatedChats));
  }
}

function* updatePauseAutomation(action) {
  const {
    id,
    automation_paused_till,
    automation_paused_for,
    is_automation_paused_enabled,
  } = action.payload;
  const selectedIdChat = yield select(getCurrentChatIdSelector);
  const userData = yield select(getUserCardDataSelector);

  try {
    if (id === selectedIdChat) {
      chatActions.setUserCard({
        ...userData,
        automation_paused_till,
        automation_paused_for,
        is_automation_paused_enabled,
      });
    }
  } catch (err) { }
}

function* getUserCardRequestSaga(action) {
  const { userId, jId, botId } = action.payload;

  try {
    const data = yield audienceApi.getUserDetails(userId, jId, botId);
    // if (status === 200) {
      yield put(chatActions.setUserCard(data));
    // }
  } catch (e) {
    const connectErr = ConnectError.from(e);
    if (connectErr.code === Code.NotFound || connectErr.code === Code.Unauthenticated) {
      yield put(chatActions.setUserCard(null));
    }
  }
}

function* addNewChatSaga(action) {
  const currentFilter = yield select(getCurrentFilterSelector);

  if (!currentFilter) {
    const newChat = action.payload;
    const chats = yield select(getChatsSelector);
    let flag = true;
    let i = 0;

    while (flag) {
      flag = false;

      if (chats[i]) {
        const { conversationTimestamp } = chats[i];

        if (conversationTimestamp > newChat?.conversationTimestamp) {
          i++;
          flag = true;
        } else {
          chats.splice(i, 0, newChat);
        }
      } else {
        chats.push(newChat);
      }
    }

    yield put(chatActions.updateChats([...chats]));
  }
}

function* sagas() {
  yield all([
    takeLatest(OPEN_CHATS_CONNECTION_REQUEST, openChatsConnectionRequestSaga),
    takeLatest(HANDLE_CHATS_CASE_UPDATING, handleUpdatingCaseFilterSaga),
    takeLatest(HANDLE_NEW_MESSAGE, checkAddChatNewMessageSaga),
    takeLatest(HANDLE_CHATS_BUSY_UPDATING, handleUpdatingBusyStatusSaga),
    takeLatest(CHECK_CHAT_READ_STATUS, updateChatReadStatus),
    takeLatest(SET_PAUSE_AUTOMATION, updatePauseAutomation),
    takeLatest(GET_USER_CARD_REQUEST, getUserCardRequestSaga),
    takeLatest(ADD_NEW_CHAT, addNewChatSaga),
  ]);
}

export const chatSagas = sagas;
