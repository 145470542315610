import React, { cloneElement, memo, Fragment } from 'react';
import {
  useClick, useDismiss, useInteractions, useRole,
} from '@floating-ui/react';
import { Floating } from '@ui-kit/components/Floating';
import { useFloating } from '@ui-kit/hooks/useFloating';

const Popover = ({
  renderTrigger, children, size, placement, controllableOpenState, disabled, visible, style
}) => {
  const {
    refs, context, isOpen, position,
  } = useFloating(controllableOpenState, {
    placement,
    offset: 8,
  });

  const click = useClick(context);
  const dismiss = useDismiss(context);
  const role = useRole(context);
  const { getReferenceProps, getFloatingProps } = useInteractions([click, role, dismiss]);

  return (
    <>
      {cloneElement(
        renderTrigger(isOpen),
        getReferenceProps({
          onClick(event) {
            event.stopPropagation();
          },
          ref: refs.setReference,
        }),
      )}
      <Floating
        ref={refs.setFloating}
        isOpen={isOpen}
        context={context}
        position={position}
        getFloatingProps={getFloatingProps}
        size={size}
        disabled={disabled}
        visible={visible}
        style={style}
      >
        {children}
      </Floating>
    </>
  );
};

const memoized = memo(Popover);

export { memoized as Popover };
