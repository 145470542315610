import React, { useState } from 'react';
import { createContext } from 'use-context-selector';

const FranchiseContext = createContext(undefined);

const FranchiseProvider = ({ children, value }) => {
  const [logoNavigation, setLogoNavigation] = useState(
    value ? value.logo_navigation : null,
  );
  const [logoRegistration, setLogoRegistration] = useState(
    value ? value.logo_navigation : null,
  );
  const [name, setName] = useState(value ? value.name : null);
  const [code, setCode] = useState(value ? value.code : null);
  const [id, setId] = useState(value ? value.id : null);
  const [favicon, setFavicon] = useState(value ? value.logo_navigation : null);
  const [helpLink, setHelpLink] = useState(value ? value.help_center_link : null);

  const [supportEmail, setSupportEmail] = useState(value ? value.support_email : null);
  const [notPaidMessage, setNotPaidMessage] = useState(
    value ? value.not_paid_message : null,
  );
  const [emptyCompaniesMessage, setEmptyCompaniesMessage] = useState(
    value ? value.empty_companies_message : null,
  );
  const [linkToPayProAccount, setLinkToPayProAccount] = useState(
    value ? value.link_to_pay_pro_account : null,
  );
  const [linkToPayStarterAccount, setLinkToPayStarterAccount] = useState(
    value ? value.link_to_pay_starter_account : null,
  );
  const [sendNotPaidEmail, setSendNotPaidEmail] = useState(
    value ? value.send_not_paid_email : null,
  );
  const [sendNotManualRegistrationEmail, setSendNotManualRegistrationEmail] = useState(
    value ? value.send_not_manual_registration_email : null,
  );

  const changeFranchiseData = (
    newLogoRegistration,
    newLogoNavigation,
    newFavicon,
    newName,
    newSupportEmail,
    newNotPaidMessage,
    newEmptyCompaniesMessage,
    newLinkToPayProAccount,
    newLinkToPayStarterAccount,
    newSendNotPaidEmail,
    newSendNotManualRegistrationEmail,
    newHelpLink,
  ) => {
    if (newHelpLink !== helpLink) {
      setHelpLink(newHelpLink);
    }

    if (newLogoRegistration !== logoRegistration) {
      setLogoRegistration(newLogoRegistration);
    }

    if (newLogoNavigation !== logoNavigation) {
      setLogoNavigation(newLogoNavigation);
    }

    if (newFavicon !== favicon) {
      document.querySelector('#shortcut-icon').setAttribute('href', newFavicon);
      setFavicon(newFavicon);
    }

    if (newName !== name) {
      setName(newName);
    }

    if (newSupportEmail !== supportEmail) {
      setSupportEmail(newSupportEmail);
    }

    if (newNotPaidMessage !== notPaidMessage) {
      setNotPaidMessage(newNotPaidMessage);
    }

    if (newEmptyCompaniesMessage !== emptyCompaniesMessage) {
      setEmptyCompaniesMessage(newEmptyCompaniesMessage);
    }

    if (newLinkToPayProAccount !== linkToPayProAccount) {
      setLinkToPayProAccount(newLinkToPayProAccount);
    }

    if (newLinkToPayStarterAccount !== linkToPayStarterAccount) {
      setLinkToPayStarterAccount(newLinkToPayStarterAccount);
    }

    if (newSendNotPaidEmail !== sendNotPaidEmail) {
      setSendNotPaidEmail(newSendNotPaidEmail);
    }

    if (newSendNotManualRegistrationEmail !== sendNotManualRegistrationEmail) {
      setSendNotManualRegistrationEmail(newSendNotManualRegistrationEmail);
    }
  };

  return (
    <FranchiseContext.Provider
      value={{
        logoNavigation,
        logoRegistration,
        name,
        code,
        id,
        favicon,
        supportEmail,
        notPaidMessage,
        emptyCompaniesMessage,
        linkToPayProAccount,
        linkToPayStarterAccount,
        sendNotPaidEmail,
        sendNotManualRegistrationEmail,
        helpLink,
        changeFranchiseData,
      }}
    >
      {children}
    </FranchiseContext.Provider>
  );
};

export { FranchiseProvider, FranchiseContext };
