import { roomsSort, liveChatRooms } from '../../const/liveChatRooms';

import {
  OPEN_CHATS_CONNECTION_SUCCESS,
  CLOSE_CHATS_CONNECTION_SUCCESS,
  GET_CHATS_REQUEST,
  GET_CHATS_SUCCESS,
  GET_CHATS_GROUP_REQUEST,
  GET_CHATS_GROUP_SUCCESS,
  GET_MESSAGES_REQUEST,
  GET_MESSAGES_SUCCESS,
  GET_SEND_MESSAGE,
  UPDATE_CHAT_MESSAGE,
  DOWNLOAD_FILE_MESSAGE_SUCCESS,
  CHANGE_BUSY_STATUS,
  CHANGE_CHAT_AVATAR,
  CHANGE_CHAT_NAME,
  CHANGE_CHAT_COUNT,
  CHECK_CHAT_READ,
  SET_CHAT_BUSY,
  DISABLE_LOADING,
  GET_CONNECTION_STATUS_SUCCESS,
  SET_CHAT_INFO_FROM_AUDIENCE,
  FILTER_CHAT_BY_ID,
  CHANGE_CASE_STATUS,
  SET_CASE_FILTER,
  SET_CHAT_ROOM,
  SET_CURRENT_FILTER,
  UPDATE_CHAT_READ_STATUS,
  SET_CHATS_LOADING,
  SET_SOUND_NOTIFICATION,
  SET_SEARCH_VALUE,
  SET_REPLIED_MESSAGE,
  SET_USER_CARD,
  GET_USER_CARD_REQUEST,
  GET_CHAT_REQUEST,
  CHOOSE_CHAT,
  ADD_SCROLL_BOTTOM,
  UPDATE_CHATS,
} from './chatConstants';

const initialState = {
  chats: [],
  isChatGroup: false,
  currentChatId: null,
  usersStatuses: [],
  messagesUnreadCount: 0,
  newChatsCursor: null,
  chatsLoading: false,
  messagesLoading: false,
  messagesList: [],
  fileMessages: [],
  isMyLustMessage: false,
  messagesListUpdateReason: '',
  newMessagesCursos: null,
  oldMessagesCursos: null,
  notification: 0,
  chatSocket: null,
  connectionStatus: '',
  chatInfoFromAudience: null,
  caseFilter: roomsSort[0],
  currentRoom: liveChatRooms[0],
  currentFilter: null,
  isSoundNotification: localStorage.getItem('messageNotification') === 'true',
  searchValue: '',
  repliedMessage: null,
  userCardData: null,
  isUserCardLoading: false,
  isChatLoading: false,
  scrollBottom: null,
};

export const chatReducer = (state = initialState, action) => {
  switch (action.type) {
    //Open chatSocket connection
    case OPEN_CHATS_CONNECTION_SUCCESS:
      return {
        ...state,
        chatSocket: action.payload,
        chatsLoading: false,
      };

    case GET_CONNECTION_STATUS_SUCCESS:
      return {
        ...state,
        connectionStatus: action.payload.status,
      };

    //Close chatSocket connection
    case CLOSE_CHATS_CONNECTION_SUCCESS:
      return {
        ...state,
        chats: [],
        isChatGroup: false,
        currentChatId: null,
        messagesUnreadCount: 0,
        newChatsCursor: null,
        chatsLoading: false,
        messagesLoading: false,
        messagesList: [],
        fileMessages: [],
        isMyLustMessage: false,
        messagesListUpdateReason: '',
        newMessagesCursos: null,
        oldMessagesCursos: null,
        notification: 0,
        connectionStatus: 0,
        chatSocket: null,
      };

    //Add loader on chat loading by set chatsLoading true
    case GET_CHATS_REQUEST:
      return {
        ...state,
        chatsLoading: true,
      };

    case GET_CHATS_SUCCESS:
      return {
        ...state,
        chats: getPaginatedChats([...state.chats], action.payload),
        isChatGroup: false,
        newChatsCursor: action.payload.next_cursor,
        messagesListUpdateReason: 'no_update',
        chatsLoading: false,
      };

    case GET_CHATS_GROUP_REQUEST:
      return {
        ...state,
        chats: [],
        currentChatId: null,
        newChatsCursor: null,
        messagesList: [],
        newMessagesCursos: null,
        oldMessagesCursos: null,
        messagesListUpdateReason: 'no_update',
        chatsLoading: true,
        cursor: null,
      };

    //Remove loader on chat rooms loading by set chatsLoading false, get the list of chat rooms
    case GET_CHATS_GROUP_SUCCESS:
      return {
        ...state,
        chats: action.payload,
        isChatGroup: true,
        messagesList: [],
        newMessagesCursos: null,
        oldMessagesCursos: null,
        messagesListUpdateReason: 'no_update',
        chatsLoading: false,
      };

    //Choose cauurent chat on click chat component
    case CHOOSE_CHAT:
      return {
        ...state,
        messagesList: [],
        messagesListUpdateReason: 'new_chat_choose',
        newMessagesCursos: null,
        oldMessagesCursos: null,
        currentChatId: action.payload.id,
        messagesUnreadCount: action.payload.messagesUnreadCount,
        messagesLoading: false,
        repliedMessage: null,
        isChatLoading: false,
      };

    //Add loader on messages list loading by set messagesLoading true
    case GET_MESSAGES_REQUEST: {
      return {
        ...state,
        messagesLoading: true,
      };
    }

    //Remove loader on messages list loading by set messagesLoading false, get the list of messages and new newMessagesCursos
    case GET_MESSAGES_SUCCESS: {
      return {
        ...state,
        messagesList: [...action.payload.messages.reverse(), ...state.messagesList],
        messagesListUpdateReason:
          state.messagesListUpdateReason === 'new_chat_choose'
            ? 'new_chat_first_render'
            : 'new_pagination_messages',
        newMessagesCursos: action.payload.next_cursor,
        oldMessagesCursos: action.payload.old_cursor,
        messagesLoading: false,
      };
    }

    //mark loading message as false
    case DISABLE_LOADING: {
      return {
        ...state,
        messagesLoading: false,
        chatsLoading: false,
      };
    }

    //Get message in some chat room and understand to render in current room or ut us a last message in chats list
    case GET_SEND_MESSAGE: {
      return {
        ...state,
        chats: action.payload.chats,
        messagesList: addMessageToChat(
          [...state.messagesList],
          state.currentChatId,
          action.payload,
        ),
        isMyLustMessage: action.payload.message.key.fromMe,
        messagesListUpdateReason:
          action.payload.chat.id === state.currentChatId
            ? 'new_get_messages_current_chat'
            : 'new_get_messages',
      };
    }

    //Update messages statuses and chats count
    case UPDATE_CHAT_MESSAGE: {
      const { chats } = updateChatsUnreadMessages([...state.chats], action.payload);
      return {
        ...state,
        chats,
        messagesList: updateMessagesStatus([...state.messagesList], action.payload),
        messagesListUpdateReason: 'update_message_status',
      };
    }

    //Download file in dialog
    case DOWNLOAD_FILE_MESSAGE_SUCCESS: {
      return {
        ...state,
        fileMessages: [...state.fileMessages, action.payload],
      };
    }

    //Change chat busy status
    case CHANGE_BUSY_STATUS: {
      return {
        ...state,
        chats: changeChatBusyStatus([...state.chats], action.payload),
        messagesListUpdateReason: 'no_update',
      };
    }

    case FILTER_CHAT_BY_ID: {
      const { chatId } = action.payload;

      return {
        ...state,
        chats: [...state.chats.filter(({ id }) => chatId !== id)],
      };
    }

    //Change chat avatar
    case CHANGE_CHAT_AVATAR: {
      return {
        ...state,
        chats: changeChatAvatar([...state.chats], action.payload),
        messagesListUpdateReason: 'no_update',
      };
    }

    //Change chat name
    case CHANGE_CHAT_NAME: {
      return {
        ...state,
        chats: changeChatName([...state.chats], action.payload),
        messagesListUpdateReason: 'no_update',
      };
    }

    //Change chat count
    case CHANGE_CHAT_COUNT: {
      return {
        ...state,
        chats: changeChatCount([...state.chats], action.payload),
        messagesListUpdateReason: 'no_update',
      };
    }

    case CHECK_CHAT_READ: {
      return {
        ...state,
        chats: chekChat([...state.chats], action.payload),
      };
    }

    case SET_CHAT_BUSY: {
      return {
        ...state,
        chats: setBusyChats([...state.chats], action.payload),
      };
    }

    case SET_CHAT_INFO_FROM_AUDIENCE: {
      return {
        ...state,
        chatInfoFromAudience: action.payload,
      };
    }

    case CHANGE_CASE_STATUS: {
      return {
        ...state,
        chats: changeChatCaseStatus([...state.chats], action.payload),
      };
    }

    case SET_CASE_FILTER: {
      const { caseFilter } = action.payload;

      return {
        ...state,
        caseFilter,
      };
    }

    case SET_CHAT_ROOM: {
      const { chatRoom } = action.payload;
      return {
        ...state,
        currentRoom: chatRoom,
      };
    }

    case SET_CURRENT_FILTER: {
      const { currentFilter } = action.payload;

      return {
        ...state,
        currentFilter,
      };
    }

    case UPDATE_CHAT_READ_STATUS: {
      const { updatedChats } = action.payload;
      return {
        ...state,
        chats: updatedChats,
      };
    }

    case SET_CHATS_LOADING: {
      return {
        ...state,
        chatsLoading: action.payload,
      };
    }

    case SET_SOUND_NOTIFICATION: {
      return {
        ...state,
        isSoundNotification: action.payload,
      };
    }

    case SET_SEARCH_VALUE: {
      return {
        ...state,
        searchValue: action.payload,
      };
    }

    case SET_REPLIED_MESSAGE: {
      return {
        ...state,
        repliedMessage: action.payload,
      };
    }

    case SET_USER_CARD: {
      const { userCardData } = action.payload;
      return {
        ...state,
        userCardData,
        isUserCardLoading: false,
      };
    }

    case GET_USER_CARD_REQUEST: {
      return {
        ...state,
        userCardData: null,
        isUserCardLoading: true,
      };
    }

    case GET_CHAT_REQUEST: {
      return {
        ...state,
        isChatLoading: true,
      };
    }

    case ADD_SCROLL_BOTTOM: {
      const { scrollBottom } = action.payload;

      return {
        ...state,
        scrollBottom,
      };
    }

    case UPDATE_CHATS: {
      const { updatedChats } = action.payload;

      return {
        ...state,
        chats: updatedChats,
      };
    }

    default:
      return state;
  }
};

//Get paginated search or default chats list
const getPaginatedChats = (chats, payload) => {
  let updatedChats;

  if (payload.is_pagination) {
    updatedChats = [...chats, ...payload.chats];
  } else {
    updatedChats = [...payload.chats];
  }

  return updatedChats;
};

//Add message to dialog if it is current dialog
const addMessageToChat = (messagesList, currentChatId, payload) => {
  if (currentChatId === payload.chat.id) {
    const { chat, ...messageInfo } = payload.message;
    messagesList.push(messageInfo);
  }

  return messagesList;
};

//Update unread messages amount un updated chat
const updateChatsUnreadMessages = (chats, updatedChat) => {
  const updatedChatIndex = chats.findIndex(chat => chat.id === updatedChat.chat_id);

  // chats[updatedChatIndex].count = updatedChat.chat.unreadCount;
  if (updatedChatIndex !== -1) {
    if (updatedChat.message && updatedChat.message.hasOwnProperty('status')) {
      chats[updatedChatIndex].lastMessage.status = updatedChat.message.status;
    }

    if (updatedChat.message && updatedChat.message.hasOwnProperty('messageStubType')) {
      chats[updatedChatIndex].messageStubType = updatedChat.message.messageStubType;
    }
  }

  return { chats };
};

//Update messages status in current chat
const updateMessagesStatus = (messages, payload) => {
  const updatedMessageIndex = messages.findIndex(
    message => message.id === payload.message?.id,
  );

  if (updatedMessageIndex !== -1) {
    if (payload.message && payload.message.hasOwnProperty('status')) {
      messages[updatedMessageIndex].status = payload.message.status;
    }

    if (payload.message && payload.message.hasOwnProperty('messageStubType')) {
      messages[updatedMessageIndex].messageStubType = payload.message.messageStubType;
    }
  }

  return messages;
};

//Change chat busy
const changeChatBusyStatus = (chats, payload) => {
  const chatChangedIndex = chats.findIndex(chat => chat.id === payload.id);

  if (chatChangedIndex >= 0) {
    chats[chatChangedIndex].isBusy = payload.isBusy;
    chats[chatChangedIndex].isBusyByName = payload.isBusyByName;
  }

  return chats;
};

const changeChatCaseStatus = (chats, payload) => {
  const chatChangedIndex = chats.findIndex(chat => chat.id === payload.id);

  if (chatChangedIndex) {
    chats[chatChangedIndex].isCaseOpened = payload.isCaseOpened;
  }

  return chats;
};

//Find and update chat avatar
const changeChatAvatar = (chats, payload) => {
  const changedChatIndex = chats.findIndex(chat => chat.id === payload.chat_id);

  if (changedChatIndex !== -1) {
    chats[changedChatIndex].avatar = payload.imgUrl;
  }

  return chats;
};

//Find and update chat name
const changeChatName = (chats, payload) => {
  const changedChatIndex = chats.findIndex(chat => chat.id === payload.id);

  if (changedChatIndex !== -1) {
    chats[changedChatIndex].name = payload.name;
  }

  return chats;
};

//Find and update chat unread count
const changeChatCount = (chats, payload) => {
  const changedChatIndex = chats.findIndex(chat => chat.id === payload.id);

  if (changedChatIndex !== -1) {
    chats[changedChatIndex].unreadCount = payload.unreadCount;
  }

  return chats;
};

const chekChat = (chats, idx) => {
  const chatIndex = chats.findIndex(chat => chat.id === idx);
  chats[chatIndex].is_read = true;
  return chats;
};

const setBusyChats = (chats, busyChat) => {
  const chatIndex = chats.findIndex(chat => chat.id === busyChat.id);
  chats[chatIndex].is_busy_by = busyChat.is_busy ? busyChat.name : null;
  return chats;
};
