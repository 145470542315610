import api from './baseApi';

//TODO need to removed, all the same api locate in folderApi
export const flowsFoldersApi = {
  getFlowsFoldersSources: (bot_id, page, currentFolder) => {
    if (currentFolder) {
      return api.get(`/folders/bot/${bot_id}/?page=${page}&folder_id=${currentFolder}`);
    }

    return api.get(`/folders/bot/${bot_id}/?page=${page}`);
  },

  createFlowsFolderSource: data => api.post('/folders/', data),

  renameFlowsFolderSource: (folderId, name) =>
    api.patch(`/folders/${folderId}/`, {
      name,
    }),

  deleteFlowsFolderSource: folderId => api.delete(`/folders/${folderId}/`),

  moveToFolder: (folderId, data) => api.patch(`/folders/${folderId}/`, data),
};
