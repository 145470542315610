import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { useInternationalization } from '@hooks/useTranslationHook';
import { audienceActions } from '@redux/audience/audienceActions';
import { getNext, getSelectedAllMode } from '@redux/audience/audienceSelectors';
import { BCInfiniteScroll } from '@components/common/BCInfiniteScroll/BCInfiniteScroll';
import { NotFoundIcon } from '@icons/not-found-icon';
import { getNextBroadcastUsersSelector } from '@redux/broadcast/broadcastSelector';
import { ArrowBack } from '@icons/audience/arrow-back';
import { broadcastActions } from '@redux/broadcast/broadcastActions';
import { LocalSearch } from '@components/common/LocalSearch/LocalSearch';
import CheckBox from '@wabb-ui-kit/CheckBox/CheckBox';
import { WarningWidget } from '@components/common/WarningWidget/WarningWidget';
import { AudienceFilters } from '../audienceFilters/audienceFilters';
import { UsersTableComponent } from './usersTableComponent/usersTableComponent';
import { AudienceFiltersEmptyComponent } from '../AudienceFiltersEmptyComponent/AudienceFiltersEmptyComponent';

import './UsersTable.scss';

export const UsersTable = ({
  users,
  page,
  isFilterNoResult,
  // usersSortTypes,
  // subscribedSortTypes,
  getNextPageOfUsers,
  broadcasts,
  broadcastUsersCount,
  // broadcastSearchName,
}) => {
  const { formatMessage } = useInternationalization();
  const dispatch = useDispatch();

  const history = useHistory();

  const searchName = new URLSearchParams(history.location.search).get('search_name');

  const audienceNext = useSelector(getNext);
  const broadcastNext = useSelector(getNextBroadcastUsersSelector);
  const selectedAll = useSelector(getSelectedAllMode);

  const [userName, setUserName] = useState();

  const handleChangeSelectAllMode = () => {
    dispatch(audienceActions.switchSelectedAllMode());
  };

  return (
    <div
      className={`audience-user-table${broadcasts ? '__for-broadcasts' : ''}`}
      id="#UserTable"
    >
      <BCInfiniteScroll
        loadMore={getNextPageOfUsers}
        next={broadcasts ? broadcastNext : audienceNext}
        root={document.getElementById('#UserTable')}
        backToTop={page === 2}
        rootMargin="0px"
        threshold={0.25}
      >
        <div className="audience-user-table__header_container">
          {!broadcasts && <AudienceFilters userName={userName} setUserName={setUserName} /> }
        </div>
        {isFilterNoResult && <AudienceFiltersEmptyComponent />}
        {users.length > 0 ? (
          <>
            <div className="audience-user-table__header">
              <div className="audience-user-table__header_cell">
                {!broadcasts && (
                  <div className="audience-user-table__header_cell_checkbox">
                    <CheckBox
                      type="checkbox"
                      isChecked={selectedAll}
                      onChange={handleChangeSelectAllMode}
                    />
                  </div>
                )}
                <span>{formatMessage('Name')}</span>
                {/*<div*/}
                {/*  onClick={() => {*/}
                {/*    broadcasts*/}
                {/*      ? dispatch(broadcastActions.updateBroadcastUsersSortType())*/}
                {/*      : dispatch(audienceActions.updateUsersSortType());*/}
                {/*  }}*/}
                {/*>*/}
                {/*  <AudienceUserTableSortIcon sortType={usersSortTypes} />*/}
                {/*</div>*/}
              </div>
              <div className="audience-user-table__header_cell">
                <span>{formatMessage('WhatsApp')}</span>
              </div>
              <div className="audience-user-table__header_cell_right">
                { broadcasts ? <span>{ formatMessage('Subscribed') }</span> : <span>{ formatMessage('Date') }</span> }
                {/*<div*/ }
                {/*  onClick={() => {*/}
                {/*    broadcasts*/}
                {/*      ? dispatch(broadcastActions.updateBroadcastUsersSubscribedSortType())*/}
                {/*      : dispatch(audienceActions.updateSubscribedSortType());*/}
                {/*  }}*/}
                {/*>*/}
                {/*  <AudienceUserTableSortIcon sortType={subscribedSortTypes} />*/}
                {/*</div>*/}
              </div>
            </div>
            <div className="audience-user-table__body">
              {users.map((user) => (
                <UsersTableComponent key={user.id} user={user} broadcasts={broadcasts} />
              ))}
            </div>
          </>
        ) : (
          (!!searchName
          && users.length < 1) && (
            <div className="audience-user-table__body_not-found">
              <WarningWidget
                title="No results found"
                description={(
                  <>
                    You searched for "
                    {userName}
                    "
                  </>
)}
              />
              {/* <NotFoundIcon />
            <span>{formatMessage('Sorry, no results were found for your search')}</span> */}
            </div>
          )
        )}
      </BCInfiniteScroll>
    </div>
  );
};
