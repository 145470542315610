import api from './baseApi';

import { sequencesApiRPC } from '@connectApi/baseApi';
import {
  SequenceTypeListRequest,
  BotIdRequest,
  SequenceTypesBulkActionDeleteRequest,
} from 'wabb-backend-api/build/es/sequence/sequence_pb';

export const sequencesApi = {
  getSequences: (botId, searchName) => {
    return sequencesApiRPC.sequenceTypeList(
      SequenceTypeListRequest.fromJson({
        bot_id: botId,
        search_name: searchName ? searchName : '',
      }),
    );
  },

  // getSequencesShort: botId => api.get(`/sequence/bot/short/${botId}/`),
  getSequencesShort: botId =>
    sequencesApiRPC.sequenceTypeShortList(BotIdRequest.fromJson({ bot_id: botId })),

  // getSequenceWithSubSequencesModels: seqId => api.get(`sequence/detail/${seqId}/`),
  getSequenceWithSubSequencesModels: seqId => sequencesApiRPC.sequenceTypeAndAllSUbModels({pk:seqId}),

  createSequence: ({ name, botId }) =>
    sequencesApiRPC.sequenceTypeCreate({
      bot: botId,
      name,
    }),

  updateSequence: (name, sequenceId) => {
    return sequencesApiRPC.sequenceTypePatch({ name, pk: sequenceId });
  },

  removeSequence: sequenceId => {
    return sequencesApiRPC.sequenceTypeDelete({ pk: sequenceId });
  },

  createSequenceSub: data => {
    return sequencesApiRPC.sequenceTypeSubModelCreate(data);
  },

  updateSequenceSub: data => {
    return sequencesApiRPC.sequenceTypeSubModelPatch(data);
  },
  ///[TO-DO]: need to check

  removeSequenceSub: sequenceSubId => {
    return sequencesApiRPC.sequenceTypeSubModelDelete({ pk: sequenceSubId });
  },


  bulkActionDelete: (botId, selectedOrUnselectedItemIds, selectedAllMode, searchName) =>
    sequencesApiRPC.sequenceTypesBulkActionDelete(
      SequenceTypesBulkActionDeleteRequest.fromJson({
        bot_id: botId,
        search_name: searchName,
        selected_all: selectedAllMode,
        selected_or_unselected_items: selectedOrUnselectedItemIds,
      }),
    ),
};
