import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { usersApi } from '@api/usersApi';

import { NewBcField } from '@components/common/fields/newBcField/newBcField';
import { BcPhoneInput } from '@components/common/fields/BcPhoneInput';
import { useInternationalization } from '@hooks/useTranslationHook';
import { UploadImage } from '@components/common/UploadImage/UploadImage';
import { Button } from '@components/common/Button';
import { getModalDataSelector } from '../../containers/Modal/modalSelectors';
import { ModalContainer } from '../../containers/Modal/ModalContainer';
import { regExp } from '../../common/fields/newBcField/newBcFieldHelper';
import { MODALS } from '../../containers/Modal/modalConstants';

import './EditProfileModal.scss';

export const EditProfileModal = () => {
  const { modalData } = useSelector(getModalDataSelector);
  const {
    userInfo: { first_name, last_name, avatar },
  } = modalData;
  const [updatedPicture, setUpdatedPicture] = useState(false);
  const [currentAvatar, setCurrentAvatar] = useState(null);

  const [errors, setErrors] = useState({
    first_name: false,
    last_name: false,
    phone: false,
    email: false,
  });

  const [editUserInfo, setEditUserInfo] = useState({
    avatar: modalData.userInfo.avatar,
    first_name: modalData.userInfo.first_name,
    last_name: modalData.userInfo.last_name,
    phone: modalData.userInfo.phone,
    email: modalData.userInfo.email,
  });

  const [userDataErrors, setUserDataErrors] = useState({
    first_name: '',
    last_name: '',
    phone: '',
    email: '',
  });
  const { formatMessage } = useInternationalization();

  const validateTextField = field_name => {
    if (editUserInfo[field_name].length === 0) {
      setErrors({ ...errors, [field_name]: true });
    } else {
      setErrors({ ...errors, [field_name]: false });
    }
  };

  const isObjectWithoutError = object =>
    Object.values(object).every(value => value === '');

  const valueFieldsValidation = () => {
    const errorObject = {};

    for (const [key, value] of Object.entries(editUserInfo)) {
      if (value === '') {
        errorObject[key] = 'This field must not be empty';
      } else if (
        !editUserInfo.first_name &&
        !editUserInfo.last_name &&
        regExp[key] &&
        !regExp[key].expression.test(String(value).toLowerCase())
      ) {
        errorObject[key] = regExp[key].errorMessage;
      } else {
        errorObject[key] = '';
      }
    }

    return errorObject;
  };

  const handleChangeValue = (e, name) => {
    if (e.target && e.target.value) {
      setEditUserInfo({ ...editUserInfo, [name]: e.target.value });
      setUserDataErrors({ ...userDataErrors, [name]: '' });
    } else {
      setEditUserInfo({ ...editUserInfo, [name]: '' });
    }
  };

  const handleChangePhone = value => {
    setEditUserInfo({ ...editUserInfo, phone: value });
    setUserDataErrors({ ...userDataErrors, phone: !value });
  };

  const handleUpdateUserInfo = async () => {
    const errorObject = valueFieldsValidation();

    if (currentAvatar === '') {
      const data = new FormData();

      data.append('avatar', currentAvatar);
      data.append('first_name', editUserInfo.first_name);
      data.append('last_name', editUserInfo.last_name);

      if (editUserInfo.phone !== modalData.phoneState.value) {
        data.append('phone', `+${editUserInfo.phone}`);
      }

      data.append('email', editUserInfo.email);

      usersApi.updateUserInfo(data).then(res => {
        if (res.status === 200) {
          modalData.setUserInfo({
            avatar: res.data.avatar,
            first_name: res.data.first_name,
            last_name: res.data.last_name,
            phone: res.data.phone,
            email: res.data.email,
          });
          setEditUserInfo({ ...editUserInfo, phone: res.data.phone });
          modalData.setPhoneState({ ...modalData.phoneState, value: res.data.phone });
          modalData.onClose();
        } else if (res.status === 208) {
          modalData.onClose();
          modalData.fetchTwoFa(res, modalData.setUserInfo);
        }
      });
    }

    if (currentAvatar && updatedPicture) {
      if (isObjectWithoutError(errorObject)) {
        const data = new FormData();
        data.append('avatar', currentAvatar);
        data.append('first_name', editUserInfo.first_name);
        data.append('last_name', editUserInfo.last_name);

        if (editUserInfo.phone !== modalData.phoneState.value) {
          data.append('phone', `+${editUserInfo.phone}`);
        }

        usersApi.updateUserInfo(data).then(res => {
          if (res.status === 200) {
            modalData.setUserInfo({
              avatar: res.data.avatar,
              first_name: res.data.first_name,
              last_name: res.data.last_name,
              phone: res.data.phone,
              email: res.data.email,
            });
            setEditUserInfo({ ...editUserInfo, phone: res.data.phone });
            modalData.setPhoneState({ ...modalData.phoneState, value: res.data.phone });
            modalData.onClose();
          } else if (res.status === 208) {
            modalData.onClose();
            modalData.fetchTwoFa(res, modalData.setUserInfo);
          }
        });
      } else {
        setUserDataErrors({ ...editUserInfo, ...errorObject });
      }
    } else if (isObjectWithoutError(errorObject)) {
      const data = new FormData();

      data.append('first_name', editUserInfo.first_name);
      data.append('last_name', editUserInfo.last_name);

      if (editUserInfo.phone !== modalData.phoneState.value) {
        data.append('phone', `+${editUserInfo.phone}`);
      }

      usersApi.updateUserInfo(data).then(res => {
        if (res.status === 200) {
          modalData.setUserInfo(prevState => ({
            ...prevState,
            first_name: res.data.first_name,
            last_name: res.data.last_name,
            phone: res.data.phone,
            email: res.data.email,
          }));
          setEditUserInfo({ ...editUserInfo, phone: res.data.phone });
          modalData.setPhoneState({ ...modalData.phoneState, value: res.data.phone });
          modalData.onClose();
        } else if (res.status === 208) {
          modalData.onClose();
          modalData.fetchTwoFa(res, modalData.setUserInfo);
        }
      });
    } else {
      setUserDataErrors({ ...editUserInfo, ...errorObject });
    }
  };

  const updateAvatar = image => {
    setUpdatedPicture(true);
    setCurrentAvatar(image);
  };

  return (
    <ModalContainer modal={MODALS.editProfile} onClose={modalData.onClose}>
      <div className="edit-container">
        <div className="edit-container_label">
          <span className="edit-container_label__text">
            {formatMessage('Edit Profile')}
          </span>
        </div>
        <div className="edit-form">
          <UploadImage
            onUpdate={updateAvatar}
            defaultImage={avatar}
            classNameImageContainer="edit-form__upload_avatar"
            helperText={formatMessage(
              'The uploaded image must be in JPG or PNG format and should not exceed 5MB in size',
            )}
          >
            {first_name.substr(0, 1) + last_name.substr(0, 1)}
          </UploadImage>
          <div className="edit-form__data__content">
            <div
              className={`edit-form__data__content__field ${
                errors.first_name ? 'error' : ''
              }`}
            >
              <NewBcField
                required
                fullWidth
                name="first_name"
                label={formatMessage('First name')}
                size="large"
                placeholder={formatMessage('First name')}
                value={editUserInfo.first_name}
                onChange={handleChangeValue}
                onBlur={() => validateTextField('first_name')}
                helperText={
                  Boolean(userDataErrors.first_name) &&
                  formatMessage(userDataErrors.first_name)
                }
              />
            </div>
            <div
              className={`edit-form__data__content__field ${
                errors.last_name ? 'error' : ''
              }`}
            >
              <NewBcField
                required
                fullWidth
                name="last_name"
                label={formatMessage('Last name')}
                size="large"
                placeholder={formatMessage('Last name')}
                value={editUserInfo.last_name}
                onChange={handleChangeValue}
                onBlur={() => validateTextField('last_name')}
                error={errors.last_name}
                helperText={
                  Boolean(userDataErrors.last_name) &&
                  formatMessage(userDataErrors.last_name)
                }
              />
            </div>
            <div
              className={`edit-form__data__content__field ${errors.phone ? 'error' : ''}`}
            >
              {`${formatMessage('WhatsApp number')}${errors.phone ? '*' : ' '}`}
              <BcPhoneInput
                specialLabel={null}
                containerStyle={{ margin: 0 }}
                isValid={value => value.length >= 10}
                value={modalData.phoneState.value}
                onChange={value => handleChangePhone(value)}
                helperText={
                  Boolean(userDataErrors.phone) &&
                  userDataErrors.phone !== true &&
                  formatMessage(userDataErrors.phone)
                }
                error={errors.phone}
              />
              {errors.phone ? (
                <div className="error-text">Invalid phone number</div>
              ) : null}
            </div>
          </div>
          <Button
            color="green"
            onClick={handleUpdateUserInfo}
            width="100%"
            height="56px"
            customClass="edit-container__button-text"
          >
            {formatMessage('Save Changes')}
          </Button>
        </div>
      </div>
    </ModalContainer>
  );
};
