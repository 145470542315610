import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Button from '@wabb-ui-kit/Button/Button';
import { EButtonSize } from '@wabb-ui-kit/Button/types/types';
import { ModalContainer } from '@components/containers/Modal/ModalContainer';
import { MODALS } from '@components/containers/Modal/modalConstants';
import { getModalDataSelector } from '@components/containers/Modal/modalSelectors';
import { useInternationalization } from '@hooks/useTranslationHook';
import { ShareLinkCopyIcon } from '@icons/flow/share-link-copy-icon';
import { flowsApi } from '@api/flowsApi';
import { bcSplitTableActions } from '@redux/bcSplitTable/bcSplitTableActions';
import CheckBox from '@wabb-ui-kit/CheckBox/CheckBox';
import Switcher from '@wabb-ui-kit/Switcher/Switcher';
import { BcToggleButton } from '@components/common/BcToggleButton/BcToggleButton';

import './ShareFlowModal.scss';

export const ShareFlowModal = () => {
  const { formatMessage } = useInternationalization();
  const { modalData } = useSelector(getModalDataSelector);
  const dispatch = useDispatch();

  const {
    flowId,
    shareEnabled,
    cloneAllow,
    shareCode,
    isFlowProtected,
    allowedCompanies,
  } = modalData;

  const [state, setState] = useState({
    shareEnabled,
    cloneAllow,
    isFlowProtected,
    allowedCompanies: allowedCompanies.join(', '),
  });

  useEffect(() => {
    flowsApi.updateFlowsSource({ flowId, ...state }).then((res) => {
      dispatch(bcSplitTableActions.updateItemInSplitTable(res));
    }).catch((error) => {
      console.error('API call failed:', error);
    });
  }, [state]);

  const handleCopyLink = () => {
    const linkText = document.querySelector('.share-flow-modal__body_sharing_link_bottom a').textContent;

    if (navigator.clipboard) {
      navigator.clipboard
        .writeText(linkText)
        .then(() => { })
        .catch((err) => console.error('Error copying text: ', err));
    }
  };

  return (
    <ModalContainer modal={MODALS.shareFlow} onClose={modalData.onClose}>
      <div className="share-flow-modal">
        <div className="share-flow-modal__header">
          {formatMessage('Share flow')}
        </div>
        <div className="share-flow-modal__body">
          <div className="share-flow-modal__body_note">
            {formatMessage('You can share this flow with other users')}
          </div>
          <div className="share-flow-modal__body_share">
            <div>{formatMessage('Allow access by link for sharing')}</div>
            <div>
              <BcToggleButton
                checked={state.shareEnabled}
                onClick={() => {
                  setState({ ...state, shareEnabled: !state.shareEnabled });
                }}
              />
            </div>
          </div>
          {state.shareEnabled ? (
            <>
              <div className="share-flow-modal__body_sharing_link">
                <div>
                  <div>{formatMessage('Your sharing link')}</div>
                </div>
                <div className="share-flow-modal__body_sharing_link_bottom">
                  <a
                    href={`${window.location.origin}/share-flow?share_code=${shareCode}`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    {`${window.location.origin}/share-flow?share_code=${shareCode}`}
                  </a>
                  <div className="share-flow-modal__body_sharing_link_bottom-icon" onClick={handleCopyLink}>
                    <ShareLinkCopyIcon />
                  </div>
                </div>
              </div>
              <div className="share-flow-modal__body-options">
                <div className="share-flow-modal__body_clone">
                  <div>
                    <CheckBox
                      type="checkbox"
                      isChecked={state.cloneAllow}
                      onChange={() => {
                        setState({ ...state, cloneAllow: !state.cloneAllow });
                      }}
                    />
                  </div>
                  <div>{formatMessage('Allow viewers to save this flow')}</div>
                </div>
                <div className="share-flow-modal__body_clone">
                  <div>
                    <CheckBox
                      type="checkbox"
                      isChecked={state.isFlowProtected}
                      onChange={() => {
                        setState({ ...state, isFlowProtected: !state.isFlowProtected });
                      }}
                    />
                  </div>
                  <div>
                    {formatMessage('Remove sharing option for users that save this flow')}
                  </div>
                </div>
              </div>
              {state.isFlowProtected && (
                <div className="share-flow-modal__body_protected">
                  <span>
                    {formatMessage('Company IDs with permission to save flow')}
                    :
                  </span>
                  <textarea
                    defaultValue={state.allowedCompanies}
                    onBlur={(event) => {
                      setState({ ...state, allowedCompanies: event.target.value });
                    }}
                    placeholder={formatMessage('Specify company IDs separate with commas')}
                  />
                </div>
              )}
            </>
          ) : (
            <div className="share-flow-modal__body_disabled">
              <div>
                {formatMessage(
                  'Since your link to share is disabled, nobody can view or save this flow right now. You must enable it for other users to access it.',
                )}
              </div>
            </div>
          )}
        </div>
        {/* <div className="share-flow-modal__btns">
          <Button
            size={EButtonSize.L}
            isDisabled={!state.shareEnabled}
            onClick={handleSave}
          >
            {formatMessage('Save')}
          </Button>
          <Button
            type="secondary"
            style="tint"
            size={EButtonSize.L}
            onClick={modalData.onClose}
          >
            {formatMessage('Cancel')}
          </Button>
        </div> */}
      </div>
    </ModalContainer>
  );
};
