export const AudienceTypes = {
  GET_USERS_REQUEST: 'GET_USERS_REQUEST',
  GET_USERS_LOADED: 'GET_USERS_LOADED',
  GET_USERS_NEXT_REQUEST: 'GET_USERS_NEXT_REQUEST',
  GET_USERS_NEXT_LOADED: 'GET_USERS_NEXT_LOADED',
  DELETE_USER_REQUEST: 'DELETE_USER_REQUEST',
  DELETE_USER: 'DELETE_USER',
  CREATE_USER: 'CREATE_USER',
  ADD_FILTER: 'ADD_FILTER',
  DELETE_FILTER: 'DELETE_FILTER',
  DELETE_ALL_FILTERS: 'DELETE_ALL_FILTERS',
  GET_STATS: 'GET_STATS',
  GET_ADMINS: 'GET_ADMINS',
  GET_STATS_LOADED: 'GET_STATS_LOADED',
  GET_ADMINS_LOADED: 'GET_ADMINS_LOADED',
  UPDATE_USERS_SORT_TYPE: 'UPDATE_USERS_SORT_TYPE',
  UPDATE_SUBSCRIBED_SORT_TYPE: 'UPDATE_SUBSCRIBED_SORT_TYPE',
  SET_FILTER_TYPE: 'SET_FILTER_TYPE',
  SET_SEARCH_NAME: 'SET_SEARCH_NAME',
  UPDATE_USER_FULL_NAME: 'UPDATE_USER_FULL_NAME',

  SWITCH_SELECTED_ALL_MODE: 'SWITCH_SELECTED_ALL_MODE',
  CHANGE_USER_SELECT_MODE: 'CHANGE_USER_SELECT_MODE',
  CHANGE_BULK_ACTIONS_VISIBILITY: 'CHANGE_BULK_ACTIONS_VISIBILITY',
  GET_BULK_ACTIONS_STATUS: 'GET_BULK_ACTIONS_STATUS',
  UPDATE_BULK_ACTIONS_STATUS: 'UPDATE_BULK_ACTIONS_STATUS',
  SET_QUERY_STRING: 'SET_QUERY_STRING',
  SET_SAVED_FILTER: 'SET_SAVED_FILTER',
  CLEAR_NEXT: 'CLEAR_NEXT',

  RESET_USERS: 'RESET_USERS',
};
