import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import './DisconnectionModal.scss';

import { useInternationalization } from '@hooks/useTranslationHook';
import {
  getConnectedPhoneSelector,
  getConnectionStatusSelector,
  getNotificationsSelector,
  getNotificationStatusSelector,
} from '@redux/notifications/notificationsSelector';
import { getSelectedCompanySelector } from '@redux/companies/companiesSelectors';
import { notificationsActions } from '@redux/notifications/notificationsActions';
import { companiesActions } from '@redux/companies/companiesActions';
import { getModalDataSelector } from '../../containers/Modal/modalSelectors';
import { MODALS } from '../../containers/Modal/modalConstants';
import { ModalContainer } from '../../containers/Modal/ModalContainer';
import { NegativeAlertIcon } from '@wabb-assets/svg/Modal/negative-alert-icon';
import Button from '@wabb-ui-kit/Button/Button';
import { EButtonSize } from '@wabb-ui-kit/Button/types/types.ts';

export const DisconnectionModal = () => {
  const { formatMessage } = useInternationalization();
  const dispatch = useDispatch();

  const { modalData } = useSelector(getModalDataSelector);
  const selectedCompany = useSelector(getSelectedCompanySelector);
  const { notificationsSocket } = useSelector(getNotificationsSelector);
  const notificationStatus = useSelector(getNotificationStatusSelector);
  const phoneNumber = useSelector(getConnectedPhoneSelector);
  const connectionStatus = useSelector(getConnectionStatusSelector);
  const [isLoading, setLoading] = useState(false);

  useEffect(() => {
    if (connectionStatus !== 'open') {
      setLoading(false);
      dispatch(notificationsActions.changeSyncProcessStatus({ IsSyncProcess: false }));
      dispatch(
        companiesActions.setSelectedCompany({
          company: {
            ...selectedCompany,
            serverUrl: null,
          },
          reload: false,
        }),
      );
      modalData.onClose();
    }
  }, [connectionStatus]);

  const closeModal = () => {
    modalData.onClose();
    modalData.setGenerateQr(false);
  };

  const handleLogout = () => {
    if (notificationStatus === 200) {
      setLoading(true);
      notificationsSocket.emit('logout');
      notificationsSocket.close();
      dispatch(notificationsActions.closeConnectionSuccess());
      dispatch(
        companiesActions.setUpdatedCompany({
          ...selectedCompany,
          serverUrl: null,
          serverNumber: null,
        }),
      );
    }
  };

  return (
    <ModalContainer modal={MODALS.disconnection} onClose={closeModal}>
      <div className="disconnection-modal-container">
        <NegativeAlertIcon />
        <div className="disconnection-modal-container__text-wrapper">
          <div className="disconnection-modal-container__text-wrapper__label">
            {formatMessage('Disconnect')}
          </div>
          <div className="disconnection-modal-container__text-wrapper__information">
            {formatMessage('Are you sure you want to disconnect')}
            <span className="disconnection-modal-container__text-wrapper__information__bold">{` “${selectedCompany.name}” `}</span>
            {formatMessage('company from')}
            <span className="disconnection-modal-container__text-wrapper__information__bold">{` +${phoneNumber} `}</span>
            {formatMessage('number')}?
          </div>
        </div>
        <div className="disconnection-modal-container__buttons-wrapper">
          <Button
            width="100%"
            type="negative"
            style="tint"
            size={EButtonSize.L}
            onClick={handleLogout}
            isLoading={isLoading}
          >
            {formatMessage('Disconnect')}
          </Button>
          <Button
            type="secondary"
            style="tint"
            onClick={modalData.onClose}
            size={EButtonSize.L}
          >
            {formatMessage('Cancel')}
          </Button>
        </div>
      </div>
    </ModalContainer>
  );
};
