import React, { FC } from 'react';
import { clsx } from 'clsx';
import { TCheckBoxType } from '@wabb-ui-kit/CheckBox/types/types';
import styles from './CheckBox.module.scss';

interface ICheckBoxProps {
  type: TCheckBoxType;
  title?: string;
  description?: string;
  isChecked?: boolean;
  isDisabled?: boolean;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  isMixed?: boolean;
  isSelector?: boolean;
  name?: string;
  value?: string;
}

const CheckBox: FC<ICheckBoxProps> = ({
  type,
  title,
  description,
  isChecked = false,
  onChange,
  isDisabled,
  isMixed = false,
  isSelector,
  name,
  value,
}) => {
  const inputClasses = clsx(styles['check-box__input'], {
    [styles.mixed]: isMixed,
  });

  return (
    <label className={styles['check-box']}>
      <input
        className={inputClasses}
        type={type}
        disabled={isDisabled}
        checked={isChecked || isMixed}
        onChange={onChange}
        name={name}
        value={value}
      />
      <span
        className={
          isSelector && type === 'checkbox'
            ? styles.selector__box
            : styles[`${type}__box`]
        }
      ></span>
      {!!title && (
        <div className={styles['check-box__text-wrapper']}>
          <p className={styles['check-box__title']}>{title}</p>
          <p className={styles['check-box__description']}>{description}</p>
        </div>
      )}
    </label>
  );
};

export default CheckBox;
