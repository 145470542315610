import { useRef, useState } from "react";

import './2fa.scss'
import CodeInput from "../CodeInput/CodeInput";


const TwoFactorCodeInput = ({codes, setCodes}) => {
  const refs = useRef([]);

  const handleKeyDown = (e, index) => {
    if (e.key === "Backspace" && index - 1 >= 0) {
      e.preventDefault();
      const newCodes = [
        ...codes.slice(0, index),
        "",
        ...codes.slice(index + 1)
      ];
      setCodes(newCodes);
      refs.current[index - 1].focus();
    }
    if (e.key === "ArrowLeft" && index - 1 >= 0) {
      refs.current[index - 1].focus();
    }
    if (e.key === "ArrowRight" && index + 1 < codes.length) {
      refs.current[index + 1].focus();
    }
  };

  const handleCodeChange = (newCode, index) => {
    const newCodes = [
      ...codes.slice(0, index),
      newCode,
      ...codes.slice(index + 1)
    ];
    setCodes(newCodes);
    if (newCode.length > 0 && index + 1 < codes.length) {
      refs.current[index + 1].focus();
    }
  };

  return (
    <div className="code-input-container">
      {codes.map((code, index) => (
        <div key={index} className="code-input-wrapper">
          <CodeInput
            value={codes[index]}
            ref={(component) => {
              refs.current[index] = component;
            }}
            index={index}
            onKeyDown={handleKeyDown}
            onChange={handleCodeChange}
          />
          {index === 2 && <span className="dash">-</span>}
        </div>
      ))}
    </div>
  );
};

export default TwoFactorCodeInput;
