import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import ClipLoader from 'react-spinners/ClipLoader';

import './Button.scss';

export const Button = ({
  children,
  customClass,
  onClick,
  color = 'green',
  width,
  height = 50,
  disabled = false,
  size = 'medium',
  transparent,
  isLoading,
  icon,
  ...otherProps
}) => (
  <button
    disabled={disabled}
    style={{
      width,
      height,
      justifyContent: icon && 'center',
    }}
    onClick={onClick}
    className={classnames(
      'bc-button',
      size,
      `bc-button_${color}${transparent ? '-transparent' : ''}`,
      customClass,
    )}
    {...otherProps}
  >
    {isLoading ? (
      <ClipLoader size={20} color={color} />
    ) : (
      <>
        {children}
        {icon}
      </>
    )}
  </button>
);

Button.propTypes = {
  color: PropTypes.oneOf(['green', 'blue', 'gray', 'red']),
  disabled: PropTypes.bool,
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onClick: PropTypes.func,
  children: PropTypes.any,
  customClass: PropTypes.string,
  transparent: PropTypes.bool,
  size: PropTypes.oneOf(['medium', 'small']),
};
