import {
  GET_BROADCAST_USERS_REQUEST,
  GET_BROADCAST_USERS_SUCCESS,
  GET_BROADCAST_USERS_NEXT_SUCCESS,
  GET_BROADCAST_USERS_NEXT_REQUEST,
  ADD_FILTER_TO_BROADCAST,
  DELETE_FILTER_FROM_BROADCAST,
  DELETE_ALL_FILTERS_FROM_BROADCAST,
  SET_FILTER_TYPE_TO_BROADCAST,
  UPDATE_BROADCAST_USERS_SORT_TYPE,
  UPDATE_BROADCAST_USERS_SUBSCRIBED_SORT_TYPE,
  SET_BROADCAST_QUERY_STRING,
  SET_BROADCAST_USERS_SEARCH_NAME,
  SET_SHOW_USERS_IN_BROADCAST_CREATE_MODAL,
  CLEAR_REDUCER,
  SET_SAVED_FILTER,
  CLEAR_NEXT,
} from './broadcastActionTypes';

export const broadcastActions = {
  getUsersRequest(data) {
    return {
      type: GET_BROADCAST_USERS_REQUEST,
      payload: data,
    };
  },
  getUsersSuccess(data) {
    return {
      type: GET_BROADCAST_USERS_SUCCESS,
      payload: data,
    };
  },
  getNextPageOfUsersRequest(data) {
    return {
      type: GET_BROADCAST_USERS_NEXT_REQUEST,
      payload: data,
    };
  },
  getNextPageOfUsersSuccess(data) {
    return {
      type: GET_BROADCAST_USERS_NEXT_SUCCESS,
      payload: data,
    };
  },
  addFilterToBroadcast(data) {
    return {
      type: ADD_FILTER_TO_BROADCAST,
      payload: data,
    };
  },
  deleteFilterFromBroadcast(data) {
    return {
      type: DELETE_FILTER_FROM_BROADCAST,
      payload: data,
    };
  },
  deleteAllFiltersFromBroadcast() {
    return {
      type: DELETE_ALL_FILTERS_FROM_BROADCAST,
    };
  },
  setFilterTypeToBroadcast(data) {
    return {
      type: SET_FILTER_TYPE_TO_BROADCAST,
      payload: data,
    };
  },
  updateBroadcastUsersSortType() {
    return {
      type: UPDATE_BROADCAST_USERS_SORT_TYPE,
    };
  },
  updateBroadcastUsersSubscribedSortType() {
    return {
      type: UPDATE_BROADCAST_USERS_SUBSCRIBED_SORT_TYPE,
    };
  },
  setBroadcastQueryString(data) {
    return {
      type: SET_BROADCAST_QUERY_STRING,
      payload: data,
    };
  },
  setBroadcastSearchName(data) {
    return {
      type: SET_BROADCAST_USERS_SEARCH_NAME,
      payload: data,
    };
  },

  setShowUsersInBroadcastCreateModal(data) {
    return {
      type: SET_SHOW_USERS_IN_BROADCAST_CREATE_MODAL,
      payload: data,
    };
  },
  clearReducer() {
    return {
      type: CLEAR_REDUCER,
    };
  },
  setSavedFilter(data) {
    return {
      type: SET_SAVED_FILTER,
      payload: data,
    };
  },
  clearNext() {
    return {
      type: CLEAR_NEXT,
    };
  },
};
