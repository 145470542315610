import React from 'react';

export const InfoBrandIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="28"
    height="28"
    viewBox="0 0 28 28"
    fill="none"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M14 4.375C8.68426 4.375 4.375 8.68426 4.375 14C4.375 19.3157 8.68426 23.625 14 23.625C19.3157 23.625 23.625 19.3157 23.625 14C23.625 8.68426 19.3157 4.375 14 4.375ZM2.625 14C2.625 7.71776 7.71776 2.625 14 2.625C20.2822 2.625 25.375 7.71776 25.375 14C25.375 20.2822 20.2822 25.375 14 25.375C7.71776 25.375 2.625 20.2822 2.625 14Z"
      fill="#5925DC"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M12.25 13.125C12.25 12.6418 12.6418 12.25 13.125 12.25H14C14.4832 12.25 14.875 12.6418 14.875 13.125V18.375C15.3582 18.375 15.75 18.7668 15.75 19.25C15.75 19.7332 15.3582 20.125 14.875 20.125H14C13.5168 20.125 13.125 19.7332 13.125 19.25V14C12.6418 14 12.25 13.6082 12.25 13.125Z"
      fill="#5925DC"
    />
    <path
      d="M13.7812 10.5C14.5061 10.5 15.0938 9.91237 15.0938 9.1875C15.0938 8.46263 14.5061 7.875 13.7812 7.875C13.0564 7.875 12.4688 8.46263 12.4688 9.1875C12.4688 9.91237 13.0564 10.5 13.7812 10.5Z"
      fill="#5925DC"
    />
  </svg>
);
