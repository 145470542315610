import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { NegativeAlertIcon } from '@wabb-assets/svg/Modal/negative-alert-icon';
import { useInternationalization } from '@hooks/useTranslationHook';
import Button from '@wabb-ui-kit/Button/Button';
import { EButtonSize } from '@wabb-ui-kit/Button/types/types';
import { ModalContainer } from '../../containers/Modal/ModalContainer';
import { MODALS } from '../../containers/Modal/modalConstants';
import { getModalDataSelector } from '../../containers/Modal/modalSelectors';
import { modalActions } from '../../containers/Modal/modalActions';

import './leaveManagerModal.scss';

export const LeaveManagerModal = () => {
  const { formatMessage } = useInternationalization();

  const dispatch = useDispatch();

  const { modalData } = useSelector(getModalDataSelector);

  const handleLeaveManager = () => {
    modalData.deleteManager();
    dispatch(modalActions.closeModal());
    modalData.setUpdateCompanies();
  };

  return (
    <ModalContainer modal={MODALS.leaveManager} onClose={modalData.onClose}>
      <div className="leave-manager-container">
        <NegativeAlertIcon />
        <div className="leave-manager-container__header">
          <span className="leave-manager-container__header__text">
            {formatMessage('Leave Company')}
          </span>
        </div>
        <div className="leave-manager-container__text">
          {formatMessage('Are you sure you want to leave company: ')}
          <a className="leave-manager-container__text__company-name">
            {modalData.companiesName}
          </a>
        </div>
        <div className='leave-manager-container__buttons'>
        <Button
          width="100%"
          type={'negative'}
          onClick={handleLeaveManager}
          style="tint"
          size={EButtonSize.L}
        >
          {formatMessage('Yes, Leave')}
        </Button>
        <Button
          onClick={() => dispatch(modalActions.closeModal())}
          height="56px"
          size={EButtonSize.L}
          type="secondary"
          style="tint"
        >
          {formatMessage('Cancel')}
        </Button>
        </div>
      </div>
    </ModalContainer>
  );
};
