import React from 'react';

export const ArrowBack = () => (
  <svg
    width="18"
    height="12"
    viewBox="0 0 18 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0.298247 5.25172C0.0994543 5.45318 -2.50974e-07 5.71227 -2.62897e-07 5.98561C-2.74826e-07 6.25905 0.0994467 6.51803 0.298247 6.7195L5.21504 11.6978C5.61263 12.1007 6.2516 12.1007 6.6492 11.6978C7.04679 11.2949 7.04679 10.6473 6.6492 10.2444L3.47899 7.03611L16.9776 7.03611C17.5456 7.03611 18 6.57557 18 6C18 5.42443 17.5456 4.96389 16.9776 4.96389L3.47899 4.96389L6.6492 1.75561C7.04679 1.35268 7.04679 0.705133 6.6492 0.3022C6.2516 -0.100733 5.61263 -0.100733 5.21504 0.3022L0.298247 5.25172Z"
      fill="#3C4449"
    />
  </svg>
);
