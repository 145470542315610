import React from 'react';

export const PlusIcon = ({ color }) => (
  <svg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <g id='Plus'>
      <g id='Vector'>
        <path fill-rule='evenodd' clip-rule='evenodd'
              d='M10.028 4.16602C10.4065 4.16651 10.713 4.47382 10.7125 4.8524L10.6958 15.1481C10.6953 15.5267 10.388 15.8332 10.0094 15.8327C9.6308 15.8322 9.3243 15.5249 9.3248 15.1463L9.34157 4.8506C9.34207 4.47202 9.64937 4.16552 10.028 4.16602Z'
              fill='#5026D9'/>
        <path fill-rule='evenodd' clip-rule='evenodd'
              d='M4.16602 9.99935C4.16602 9.62077 4.47292 9.31387 4.8515 9.31387L15.1472 9.31387C15.5258 9.31387 15.8327 9.62077 15.8327 9.99935C15.8327 10.3779 15.5258 10.6848 15.1472 10.6848L4.8515 10.6848C4.47292 10.6848 4.16602 10.3779 4.16602 9.99935Z'
              fill='#5026D9'/>
      </g>
    </g>
  </svg>
);
