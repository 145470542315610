import { loaderActionsTypes } from './loaderActionTypes';

export const loaderActions = {
  loaderOff() {
    return {
      type: loaderActionsTypes.LOADING_OFF,
    };
  },

  loaderOn() {
    return {
      type: loaderActionsTypes.LOADING_ON,
    };
  },
};
