import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import TextField from '@mui/material/TextField';

import { ModalContainer } from '../../containers/Modal/ModalContainer';
import { MODALS } from '../../containers/Modal/modalConstants';
import { getModalDataSelector } from '../../containers/Modal/modalSelectors';
import { flowsActions } from '../../../redux/flows/flowsActions';
import { getflowsSelector } from '../../../redux/flows/flowsSelector';
import { Button } from '../../common/Button';

import { useInternationalization } from '../../../hooks/useTranslationHook';

import '../modalsComponent.scss';
import './BotsModal.scss';

const styles = {};

export const BotsModal = () => {
  const { formatMessage } = useInternationalization();
  const dispatch = useDispatch();

  const { modalData } = useSelector(getModalDataSelector);
  const { flowsErrors } = useSelector(getflowsSelector);

  const { flowId, flowName, folderId, companyBot } = modalData;

  const [inputs, setInputs] = useState({
    botName: flowName || '',
  });

  const [errorInput, setErrorInput] = useState('');
  const [checkError, setCheckError] = useState(0);

  useEffect(() => {
    checkError && setErrorInput(formatMessage(flowsErrors));
    setCheckError(checkError + 1);
  }, [flowsErrors]);

  const handleChange = e => {
    const { name, value } = e.target;

    if (e.target.value !== '') {
      setErrorInput('');
    }

    setInputs(inputs => ({ ...inputs, [name]: value }));
  };

  const handleSubmit = event => {
    event.preventDefault();

    if (botName === '') {
      setErrorInput(formatMessage('This field can`t be blank'));
    } else if (modalData.action === 'Create') {
      dispatch(
        flowsActions.createFlowRequest({
          botName,
          folderId,
          companyBot,
        }),
      );
    } else if (modalData.action === 'Rename') {
      dispatch(
        flowsActions.renameFlowRequest({
          flowId,
          botName,
        }),
      );
      modalData.onClose();
    }
  };

  const { botName } = inputs;

  return (
    <ModalContainer modal={MODALS.bots} onClose={modalData.onClose} customStyles={styles}>
      <div className="campaigns-modal">
        <div className="campaigns-modal__header">
          {formatMessage(modalData.action)} {formatMessage('Flow')}
        </div>
        <div className="campaigns-modal__form-group">
          <TextField
            error={Boolean(errorInput)}
            name="botName"
            label={formatMessage('Enter flow name')}
            value={botName}
            helperText={errorInput}
            onChange={handleChange}
            variant="outlined"
          />
        </div>
        <div className="campaigns-modal__form-group">
          <Button
            onClick={handleSubmit}
            color="green"
            width={200}
            customClass="bots-modal__submit-button"
          >
            {formatMessage(modalData.action)} {formatMessage('Flow')}
          </Button>
        </div>
      </div>
    </ModalContainer>
  );
};
