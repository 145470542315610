import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useContextSelector } from 'use-context-selector';

import { companiesApi } from '@api/companiesApi';
import { companiesActions } from '@redux/companies/companiesActions';
import { useInternationalization } from '@hooks/useTranslationHook';
import { isFranchiseOwnerSelector } from '@redux/auth/authSelectors';
import { Button } from '@components/common/Button';
import { ModalContainer } from '../../containers/Modal/ModalContainer';
import { MODALS } from '../../containers/Modal/modalConstants';
import { getModalDataSelector } from '../../containers/Modal/modalSelectors';
import { modalActions } from '../../containers/Modal/modalActions';
import { FranchiseContext } from '../../../contexts/franchiseContext';
import { NewBcField } from '../../common/fields/newBcField/newBcField';

import '../modalsComponent.scss';

const styles = {};

export const NewCompanyModal = () => {
  const dispatch = useDispatch();

  const { modalData } = useSelector(getModalDataSelector);
  const { action } = modalData;

  const code = useContextSelector(FranchiseContext, franchiseData => franchiseData.code);
  const isFranchiseOwner = useSelector(isFranchiseOwnerSelector);

  const [inputs, setInputs] = useState({
    promocode: '',
  });

  const [errorInput, setErrorInput] = useState('');
  const { formatMessage } = useInternationalization();

  const handleChange = e => {
    const { name, value } = e.target;

    if (e.target.value !== '') {
      setErrorInput('');
    }

    setInputs(inputs => ({ ...inputs, [name]: value }));
  };

  const handleSubmit = event => {
    event.preventDefault();

    if (promocode === '') {
      setErrorInput('This field can`t be blank');
    } else {
      const newPromoObj = { promo_code: promocode };
      handleCreatePromocode(newPromoObj);
    }
  };

  const handleCreatePromocode = newPromoObj => {
    companiesApi.createPromocode(newPromoObj).then(res => {
      if (res.data.success) {
        if (modalData.setUpdateCompanies) {
          modalData.setUpdateCompanies(newPromoObj.promo_code);
        }

        dispatch(modalActions.closeModal());
        dispatch(
          companiesActions.getCompaniesRequest({ frCode: code, isFranchiseOwner }),
        );
      } else {
        setErrorInput('This promocode is incorrect.');
      }
    });
  };

  const { promocode } = inputs;

  return (
    <ModalContainer
      modal={MODALS.newCompany}
      onClose={modalData.onClose}
      customStyles={styles}
    >
      <div className="campaigns-modal">
        <div className="campaigns-modal__header">{formatMessage(action)}</div>
        <div className="campaigns-modal__description">
          {formatMessage(
            'Are you sure you want to add the selected tags to 2,473 contacts?',
          )}
        </div>
        <div className="campaigns-modal__form-group">
          <NewBcField
            error={Boolean(errorInput)}
            name="promocode"
            placeholder={formatMessage('Enter promocode')}
            value={promocode}
            helperText={errorInput && formatMessage(errorInput)}
            onChange={handleChange}
            variant="outlined"
          />
        </div>
        <div className="campaigns-modal__form-group">
          <Button onClick={handleSubmit} height="56px" color="green" width="100%">
            <span className="campaigns-modal__form-group__submit-button__text">
              {formatMessage('Submit')}
            </span>
          </Button>
        </div>
      </div>
    </ModalContainer>
  );
};
