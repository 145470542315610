import isEqual from 'lodash/isEqual';
import {
  GET_BROADCAST_USERS_SUCCESS,
  GET_BROADCAST_USERS_NEXT_SUCCESS,
  ADD_FILTER_TO_BROADCAST,
  DELETE_FILTER_FROM_BROADCAST,
  DELETE_ALL_FILTERS_FROM_BROADCAST,
  SET_FILTER_TYPE_TO_BROADCAST,
  UPDATE_BROADCAST_USERS_SORT_TYPE,
  UPDATE_BROADCAST_USERS_SUBSCRIBED_SORT_TYPE,
  SET_BROADCAST_QUERY_STRING,
  SET_BROADCAST_USERS_SEARCH_NAME,
  SET_SHOW_USERS_IN_BROADCAST_CREATE_MODAL,
  GET_BROADCAST_USERS_REQUEST,
  CLEAR_REDUCER,
  SET_SAVED_FILTER,
  CLEAR_NEXT,
} from './broadcastActionTypes';

const initialState = {
  users: [],
  usersCount: null,
  loader: false,
  next: null,
  filters: [],
  filterType: 1,
  usersSortType: 0,
  subscribedSortType: 0,
  searchName: '',
  queryString: '',
  queryStringLoader: true,
  showUsers: false,
  savedFilterState: null,
};

export const broadCastReducer = (state = initialState, action) => {
  const data = action.payload;

  switch (action.type) {
    case GET_BROADCAST_USERS_REQUEST:
      return {
        ...state,
        loader: true,
      };
    case GET_BROADCAST_USERS_SUCCESS:
      return {
        ...state,
        usersCount: data.count,
        users: data.results.pipelineUsers,
        next: data.next,
        loader: false,
        savedFilterState: null,
      };
    case GET_BROADCAST_USERS_NEXT_SUCCESS:
      return {
        ...state,
        users: [...state.users, ...data.results.pipelineUsers],
        next: data.next,
      };
    case ADD_FILTER_TO_BROADCAST:
      return {
        ...state,
        filters: addFilter(state.filters, data),
      };
    case DELETE_FILTER_FROM_BROADCAST:
      return {
        ...state,
        filters: state.filters.filter(
          item =>
            !(
              item.filterName === data.filterName &&
              item.condition === item.condition &&
              (data.value.value
                ? item.value.value === data.value.value
                : item.value === data.value)
            ),
        ),
      };
    case DELETE_ALL_FILTERS_FROM_BROADCAST:
      return {
        ...state,
        filters: [],
      };
    case SET_FILTER_TYPE_TO_BROADCAST:
      return {
        ...state,
        filterType: data,
      };
    case UPDATE_BROADCAST_USERS_SORT_TYPE:
      return {
        ...state,
        usersSortType: state.usersSortType === 2 ? 0 : state.usersSortType + 1,
      };
    case UPDATE_BROADCAST_USERS_SUBSCRIBED_SORT_TYPE:
      return {
        ...state,
        subscribedSortType:
          state.subscribedSortType === 2 ? 0 : state.subscribedSortType + 1,
      };
    case SET_BROADCAST_QUERY_STRING:
      return {
        ...state,
        queryString: action.payload,
        queryStringLoader: false,
      };
    case SET_BROADCAST_USERS_SEARCH_NAME:
      return {
        ...state,
        searchName: action.payload,
      };
    case SET_SHOW_USERS_IN_BROADCAST_CREATE_MODAL:
      return {
        ...state,
        showUsers: !state.showUsers,
      };
    case SET_SAVED_FILTER:
      return {
        ...state,
        queryString: action.payload.filters,
        savedFilterState: action.payload.id,
      };
    case CLEAR_REDUCER:
      return initialState;
    case CLEAR_NEXT:
      return {
        ...state,
        next: null,
      };
    default:
      return state;
  }

};

const addFilter = (filters, newFilter) => {
  for (const filter of filters) {
    if (isEqual(filter, newFilter)) return filters;
  }

  return [...filters, newFilter];
};
