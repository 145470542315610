import React, { useState } from 'react';

import { useInternationalization } from '@hooks/useTranslationHook';
import { CheckIcon } from '@icons/audience/check-icon';
import ClipLoader from 'react-spinners/ClipLoader';
import { constructorApi } from '@api/constructorApi';
import { BcTooltip } from '@components/common/BcTooltip/BcTooltip';
import Avatar from '../../../wabb-ui-kit/Avatar/Avatar';
import RedCross from '../../../wabb-assets/svg/FormField/red-cross.svg';

import './SaveCompanyItem.scss';

const saveStateOptions = {
  INITIAL: 'initial',
  LOADING: 'loading',
  SUCCESS: 'success',
  FAILURE: 'failure',
};

const SaveCompanyItem = ({ company }) => {
  const { formatMessage } = useInternationalization();

  const [saveState, setSaveState] = useState(saveStateOptions.INITIAL);
  const [showTooltip, setShowTooltip] = useState(false);

  const handleSaveClick = () => {
    const queryString = window.location.search;
    const lastItem = queryString.substring(queryString.lastIndexOf('=') + 1);
    setSaveState(saveStateOptions.LOADING);
    constructorApi
      .saveSharedFlowToSelectedCompany(lastItem, company.id)
      .then(() => {
        setSaveState(saveStateOptions.SUCCESS);
      })
      .catch(() => {
        setSaveState(saveStateOptions.FAILURE);
      });
  };

  const makrupForSaveState = () => {
    if (saveState === saveStateOptions.LOADING) {
      return <ClipLoader size={20} color="#5925dc" />;
    }

    if (saveState === saveStateOptions.SUCCESS) {
      return <CheckIcon />;
    }

    if (saveState === saveStateOptions.FAILURE) {
      return (
        <div className="save-company-item__error-container">
          <img src={RedCross} />
          <p className="save-company-item__error-container__text">something went wrong</p>
        </div>
      );
    }
  };

  return (
    <div
      className="save-company-item"
      onClick={saveState !== saveStateOptions.SUCCESS ? () => handleSaveClick() : ''}
    >
      <div className="save-company-item__container">
        <Avatar size="s" shape="square" type="company" img={company.avatar} />
        <div className="save-company-item__container_description">
          <div className="save-company-item__container_name">
            <div className="save-company-item__container_name_name">
              {company.name ? company.name : ''}
            </div>
            {/* {company.is_bot_connected ? (
              <BcTooltip title={formatMessage('WhatsApp Connected')} placement="right">
                <div className="save-company-item__container_name_connected" />
              </BcTooltip>
            ) : (
              <BcTooltip title={formatMessage('WhatsApp Disconnected')} placement="right">
                <div className="save-company-item__container_name_disconnected" />
              </BcTooltip>
            )} */}
          </div>
          <div className="save-company-item__container_id">
            ID:
            {company?.id}
          </div>
        </div>
      </div>
      <BcTooltip
        title={formatMessage('Save')}
        open={saveState === 'initial' && showTooltip}
        onMouseEnter={() => setShowTooltip(true)}
        onMouseOut={() => setShowTooltip(false)}
        placement="top"
        arrow
      >
        <div className="save-company-item__checkbox">{makrupForSaveState()}</div>
      </BcTooltip>
    </div>
  );
};

export default SaveCompanyItem;
