import { AudienceTypes } from './types';

export const audienceActions = {
  getUsersRequest(data) {
    return {
      type: AudienceTypes.GET_USERS_REQUEST,
      payload: data,
    };
  },
  getNextPageOfUsersRequest(data) {
    return {
      type: AudienceTypes.GET_USERS_NEXT_REQUEST,
      payload: data,
    };
  },
  getUsersSuccess(data) {
    return {
      type: AudienceTypes.GET_USERS_LOADED,
      payload: data,
    };
  },
  getNextPageOfUsersSuccess(data) {
    return {
      type: AudienceTypes.GET_USERS_NEXT_LOADED,
      payload: data,
    };
  },
  deleteUserRequest(data) {
    return {
      type: AudienceTypes.DELETE_USER_REQUEST,
      payload: data,
    };
  },
  deleteUser(data) {
    return {
      type: AudienceTypes.DELETE_USER,
      payload: data,
    };
  },
  createUser(data) {
    return {
      type: AudienceTypes.CREATE_USER,
      payload: data,
    };
  },
  addFilter(data) {
    return {
      type: AudienceTypes.ADD_FILTER,
      payload: data,
    };
  },
  deleteFilter(data) {
    return {
      type: AudienceTypes.DELETE_FILTER,
      payload: data,
    };
  },
  deleteAllFilters() {
    return {
      type: AudienceTypes.DELETE_ALL_FILTERS,
    };
  },

  setFilterType(data) {
    return {
      type: AudienceTypes.SET_FILTER_TYPE,
      payload: data,
    };
  },
  updateUsersSortType() {
    return {
      type: AudienceTypes.UPDATE_USERS_SORT_TYPE,
    };
  },

  updateSubscribedSortType() {
    return {
      type: AudienceTypes.UPDATE_SUBSCRIBED_SORT_TYPE,
    };
  },
  getStatsRequest(botId) {
    return {
      type: AudienceTypes.GET_STATS,
      payload: { botId },
    };
  },
  getAdminsRequest(botId) {
    return {
      type: AudienceTypes.GET_ADMINS,
      payload: { botId },
    };
  },
  getStatsRequestLoaded(data) {
    return {
      type: AudienceTypes.GET_STATS_LOADED,
      payload: data,
    };
  },
  getAdminsRequestLoaded(data) {
    return {
      type: AudienceTypes.GET_ADMINS_LOADED,
      payload: data,
    };
  },
  switchSelectedAllMode(data) {
    return {
      type: AudienceTypes.SWITCH_SELECTED_ALL_MODE,
      payload: data,
    };
  },
  changeUserSelectedMode(data) {
    return {
      type: AudienceTypes.CHANGE_USER_SELECT_MODE,
      payload: data,
    };
  },

  updateBulkActionsStatus(data) {
    return {
      type: AudienceTypes.UPDATE_BULK_ACTIONS_STATUS,
      payload: data,
    };
  },
  getBulkActionsStatus(data) {
    return {
      type: AudienceTypes.GET_BULK_ACTIONS_STATUS,
      payload: data,
    };
  },
  updateUserFullName(data) {
    return {
      type: AudienceTypes.UPDATE_USER_FULL_NAME,
      payload: data,
    };
  },
  resetUsers() {
    return {
      type: AudienceTypes.RESET_USERS,
    };
  },
  setQueryString(data) {
    return {
      type: AudienceTypes.SET_QUERY_STRING,
      payload: data,
    };
  },
  setSearchName(data) {
    return {
      type: AudienceTypes.SET_SEARCH_NAME,
      payload: data,
    };
  },
  setSavedFilter(data) {
    return {
      type: AudienceTypes.SET_SAVED_FILTER,
      payload: data,
    };
  },
  clearNext() {
    return {
      type: AudienceTypes.CLEAR_NEXT,
    };
  },
};
