import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
// import classNames from 'classnames';

import { ModalContainer } from '../../containers/Modal/ModalContainer';
import { MODALS } from '../../containers/Modal/modalConstants';
import { getModalDataSelector } from '../../containers/Modal/modalSelectors';
import { useInternationalization } from '../../../hooks/useTranslationHook';
import { Button } from '../../common/Button';
import { GreenCheckbox } from '../../common/GreenCheckbox/GreenCheckbox';
import { notificationWindowActions } from '../../../redux/notificationWindow/notificationWindowActions';

import '../modalsComponent.scss';
import { FranchiseCompanyApi } from '../../../api/franchiseCompanyApi';

// const StyledDiv = styled('div')(({ theme }) => ({
//   display: 'flex',
//   marginLeft: 0,
//   marginRight: 0,
// }));

const styles = {};

export const FranchisePermissionsModal = () => {
  const { formatMessage } = useInternationalization();
  const { modalData } = useSelector(getModalDataSelector);

  const {
    companyId,
    enable_billing_page,
    enable_managers_page,
    enable_integrations,
    changeFranchisePermissions,
  } = modalData;

  const [integrationsState, setIntegrationsState] = useState(enable_integrations);
  const [billingPageState, setBillingPageState] = useState(enable_billing_page);
  const [managersPageState, setManagersPageState] = useState(enable_managers_page);

  const handleSubmit = event => {
    event.preventDefault();
    const data = {
      enable_billing_page: billingPageState,
      enable_integrations: integrationsState,
      enable_managers_page: managersPageState,
    };
    FranchiseCompanyApi.updateCompany(companyId, data)
      .then(res => {
        notificationWindowActions.openWindow(true, {
          label: formatMessage('Successful'),
          type: 'success',
          text: formatMessage('Successful update'),
        });
        modalData.onClose();
        changeFranchisePermissions(res.data);
      })
      .catch(() => {
        dispatch(
          notificationWindowActions.openWindow(true, {
            label: formatMessage('Error'),
            type: 'error',
            text: formatMessage('Error'),
          }),
        );
      });
  };

  return (
    <ModalContainer
      modal={MODALS.franchisePermissions}
      onClose={modalData.onClose}
      customStyles={styles}
    >
      <div className="campaigns-modal">
        <div className="campaigns-modal__header">{formatMessage('Update')}</div>
        <div
          className="campaigns-modal__form-group"
          style={{ flexDirection: 'column', alignItems: 'flex-start', marginLeft: '20%' }}
        >
          <FormControl component="fieldset">
            <FormLabel component="legend">
              {formatMessage('Whitelabel permissions to settings page functionality')}
            </FormLabel>
            <div style={{ display: 'flex', alignItems: 'center', marginTop: '20px' }}>
              <FormControlLabel
                // className={classNames(classes.root, classes.body)}
                control={
                  <GreenCheckbox
                    checked={integrationsState}
                    onChange={event => {
                      setIntegrationsState(event.target.checked);
                    }}
                    inputProps={{ 'aria-label': 'checkbox with default color' }}
                    name="checkedBox"
                  />
                }
              />
              <span style={{ display: 'inline-block', fontSize: '14px' }}>
                {formatMessage('Integrations')}
              </span>
            </div>

            <div style={{ display: 'flex', alignItems: 'center' }}>
              <FormControlLabel
                // className={classNames(classes.root, classes.body)}
                control={
                  <GreenCheckbox
                    checked={billingPageState}
                    onChange={event => {
                      setBillingPageState(event.target.checked);
                    }}
                    inputProps={{ 'aria-label': 'checkbox with default color' }}
                    name="checkedBox"
                  />
                }
              />
              <span style={{ display: 'inline-block', fontSize: '14px' }}>
                {formatMessage('Billing')}
              </span>
            </div>

            <div style={{ display: 'flex', alignItems: 'center', marginBottom: '30px' }}>
              <FormControlLabel
                // className={classNames(classes.root, classes.body)}
                control={
                  <GreenCheckbox
                    checked={managersPageState}
                    onChange={event => {
                      setManagersPageState(event.target.checked);
                    }}
                    inputProps={{ 'aria-label': 'checkbox with default color' }}
                    name="checkedBox"
                  />
                }
              />
              <span style={{ display: 'inline-block', fontSize: '14px' }}>
                {formatMessage('Managers')}
              </span>
            </div>
          </FormControl>
        </div>

        <div className="campaigns-modal__form-group">
          <Button
            onClick={handleSubmit}
            color="green"
            width={200}
            customClass="bots-modal__submit-button"
          >
            {formatMessage('Submit')}
          </Button>
        </div>
      </div>
    </ModalContainer>
  );
};
