import React, { useState, FC, useCallback } from 'react';
import CheckBox from '@wabb-ui-kit/CheckBox/CheckBox';
import styles from './TagsDisplay.module.scss';

interface Tag {
  [key: string]: any;
}

interface TagsDisplayProps {
  tag: Tag;
  isCheckbox: boolean;
  selectedTags: { [key: number]: boolean };
  onCheckboxChange: (tagId: number, checked: boolean) => void;
  singleWithGroups?: boolean;
  single?: boolean;
  onSingleSelect?: (tagId: number, label: string) => void;
  withGroups?: boolean;
  onAddTag: (newTagLabel: string) => void;
  handleChangeItem: (tag: Tag) => void;
  setIsOpen: (isOpen: boolean) => void;
  labelName?: string;
  idName?: string;
}

const TagsDisplay: FC<TagsDisplayProps> = ({
  tag,
  isCheckbox,
  selectedTags,
  onCheckboxChange,
  singleWithGroups = false,
  withGroups = false,
  single = false,
  onSingleSelect,
  onAddTag,
  handleChangeItem,
  setIsOpen,
  labelName = 'name',
  idName = 'id',
}) => {
  const [newTagLabel, setNewTagLabel] = useState('');

  const handleInputChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    setNewTagLabel(event.target.value);
  }, []);

  const handleAddTag = useCallback(() => {
    if (newTagLabel.trim()) {
      onAddTag(newTagLabel);
      setNewTagLabel('');
    }
  }, [newTagLabel, onAddTag]);

  const handleTagClick = useCallback(
    (tagId: number, label: string) => {
      if ((single || singleWithGroups) && onSingleSelect) {
        onSingleSelect(tagId, label);
      }
    },
    [single, singleWithGroups, onSingleSelect],
  );

  const handleCheckboxChange = (tagId: number) => {
    return () => {
      const isChecked = !selectedTags[tagId];
      onCheckboxChange(tagId, isChecked);
    };
  };

  return (
    <div
      className={styles.tagItem}
      onClick={() => {
        handleChangeItem(tag);
        setIsOpen(false);
      }}
    >
      {isCheckbox ? (
        <CheckBox
          type="checkbox"
          title={tag[labelName]}
          isChecked={!!selectedTags[tag[idName]]}
          onChange={handleCheckboxChange(tag[idName])}
        />
      ) : (
        <>
          {tag[labelName]}
          {(single || singleWithGroups) && selectedTags[tag[idName]] && <div>+</div>}
        </>
      )}
    </div>
  );
};

export default TagsDisplay;
