import * as React from 'react';

export const WarningIcon = ({ color }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.29 4.85999L1.82002 19C1.46466 19.6154 1.46254 20.3731 1.81445 20.9905C2.16635 21.6079 2.81943 21.9922 3.53002 22H20.47C21.1806 21.9922 21.8337 21.6079 22.1856 20.9905C22.5375 20.3731 22.5354 19.6154 22.18 19L13.71 4.85999C13.3475 4.26227 12.6991 3.89725 12 3.89725C11.3009 3.89725 10.6526 4.26227 10.29 4.85999Z"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M12 10V14" stroke={color} strokeLinecap="round" strokeLinejoin="round" />
    <circle cx="12" cy="18" r="1" fill={color} />
  </svg>
);
