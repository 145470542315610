import {
  AUTH,
  LOGOUT_SUCCESS,
  REG_FAILURE,
  REG_SUCCESS,
  SET_AUTH_DATA,
  SET_TWO_FA_DATA,
  SET_USER_ID,
  IS_EMAIL_REGISTRATION_IN_PROGRESS,
  REG,
  REDIRECT_SUCCESS,
  REDIRECT_TO_NEXT_PAGE,
  ACTIVATE_ACCOUNT,
  ADD_ERROR_MESSAGE,
  CLEAN_ERROR_MESSAGE,
  SEND_USER_INFORMATION,
  HIDE_RESEND_EMAIL_CONFIRMATION,
  SHOW_RESEND_EMAIL_CONFIRMATION,
  SET_RESET_PASSWORD_DATA,
  SET_FRANCHISE_PERMISSIONS_DATA,
} from './authConstants';

const initialState = {
  userId: null,
  token: null,
  signUpData: null,
  isNeedToRedirect: false,
  errorMessage: undefined,
  redirectRoute: undefined,
  fullName: JSON.parse(localStorage.getItem('fullName')),
  isAuthorized: !!localStorage.getItem('authToken'),
  userCreatedSuccess: false,
  isResendEmailConfirmation: false,
  // isFranchiseOwner: JSON.parse(localStorage.getItem('isFranchiseOwner') ?? ''),
  isEmailRegistrationInProgress: JSON.parse(
    localStorage.getItem('isEmailRegistrationInProgress'),
  ),
  userType: JSON.parse(localStorage.getItem('userType')),
  resetPasswordData: null,
  franchisePermissionsData: null,
};

export const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case AUTH:
      return {
        ...state,
      };
    case REG:
      return {
        ...state,
        userEmail: action.payload.email,
        franchiseCode: action.payload.code ? action.payload.code : null,
      };
    case ACTIVATE_ACCOUNT:
      return {
        ...state,
        confirmationCode: action.payload.confirmationCode,
      };

    case SEND_USER_INFORMATION:
      return {
        ...state,
        userPassword: action.payload.password,
      };

    case REDIRECT_TO_NEXT_PAGE:
      const { route } = action.payload;
      return {
        ...state,
        isNeedToRedirect: true,
        redirectRoute: route,
      };

    case REDIRECT_SUCCESS:
      return {
        ...state,
        isNeedToRedirect: false,
        redirectRoute: undefined,
      };

    case ADD_ERROR_MESSAGE: {
      const { errorMessage } = action.payload;
      return {
        ...state,
        errorMessage,
      };
    }

    case CLEAN_ERROR_MESSAGE:
      return {
        ...state,
        errorMessage: undefined,
      };

    case SHOW_RESEND_EMAIL_CONFIRMATION:
      return {
        ...state,
        isResendEmailConfirmation: true,
      };
    case HIDE_RESEND_EMAIL_CONFIRMATION:
      return {
        ...state,
        isResendEmailConfirmation: false,
      };
    case SET_AUTH_DATA:
      const {
        token,
        userId,
        is_franchise_owner,
        is_email_in_progress,
        userType,
        fullName,
      } = action.payload;
      return {
        ...state,
        token,
        userId,
        fullName,
        isAuthorized: !!token,
        // isFranchiseOwner: is_franchise_owner,
        isEmailRegistrationInProgress: is_email_in_progress,
        userType,
      };

    case SET_TWO_FA_DATA:
      const { twoFaUserId, sessionCode } = action.payload;
      return {
        ...state,
        twoFaUserId,
        sessionCode,
      };

    case LOGOUT_SUCCESS:
      return {
        ...state,
        token: null,
        userId: null,
        isAuthorized: null,
        isEmailRegistrationInProgress: null,
        isFranchiseOwner: null,
      };
    case SET_USER_ID:
      return { ...state, userId: action.payload };
    case REG_SUCCESS:
      return { ...state, userCreatedSuccess: true };
    case REG_FAILURE:
      return { ...state, userCreatedSuccess: false };
    case IS_EMAIL_REGISTRATION_IN_PROGRESS:
      return { ...state, isEmailRegistrationInProgress: action.payload };
    case SET_RESET_PASSWORD_DATA:
      return { ...state, resetPasswordData: action.payload };
    case SET_FRANCHISE_PERMISSIONS_DATA:
      return { ...state, franchisePermissionsData: action.payload };
    default:
      return state;
  }
};
