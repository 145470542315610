import api from './baseApi';

export const FranchiseCompanyApi = {
  getFranchiseInfo: franchiseCode => api.get(`/franchise/code/${franchiseCode}/`),
  getFranchiseSettings: franchiseId => api.get(`/franchise/${franchiseId}/`),
  setFranchiseSettings: (franchiseId, updateObj) =>
    api.patch(`/franchise/${franchiseId}/`, updateObj),
  createFranchiseUser: (userObj, companies, type) =>
    api.post('/franchise/create_user/', {
      user: userObj,
      companies,
      user_creation_type: type,
    }),
  getFranchiseUsers: (franchiseId, searchName, page) => {
    let url = `/franchise/${franchiseId}/users/?page=${page}`;

    if (searchName) {
      url += `&search_name=${searchName}`;
    }

    return api.get(url);
  },

  getNextFranchiseUsers: (franchiseId, page) =>
    api.get(`/franchise/${franchiseId}/users/?page=${page + 1}`),

  getFranchiseUsersShort: franchiseId =>
    api.get(`/franchise/${franchiseId}/users/short/`),
  modifyFranchiseUser: (userId, action) =>
    api.post(`/franchise/modify_user/${userId}/`, {
      action,
    }),
  modifyFranchiseCompany: (companyId, action) =>
    api.post(`/franchise/modify_company/${companyId}/`, {
      action,
    }),
  changeFranchiseUserPassword: (userId, password) =>
    api.post('/franchise/change_user_password/', {
      user_id: userId,
      password,
    }),
  getFranchisePayments: franchiseId => api.get(`/franchise/${franchiseId}/payment/`),
  getFranchiseCompanies: (franchiseId, searchName, page) => {
    let url = `/franchise/${franchiseId}/companies/?page=${page}`;

    if (searchName) {
      url += `&search_name=${searchName}`;
    }

    return api.get(url);
    // return api.get(`/franchise/${franchiseId}/companies/`);
  },
  getNextFranchiseCompanies: (franchiseId, page) =>
    api.get(`/franchise/${franchiseId}/companies/?page=${page + 1}`),
  getFranchiseCompaniesShort: franchiseId =>
    api.get(`/franchise/${franchiseId}/companies/short/`),
  modifyCompany: (franchiseId, data) =>
    api.post(`/franchise/modify_company/${franchiseId}/`, data),
  updateCompany: (companyId, data) =>
    api.patch(`/franchise/update_company/${companyId}/`, data),
  createCompany: data => api.post('/franchise/create_company/', data),
  changeRegistrationData: data => api.patch('/franchise/post_registration/', data),

  getManagersListPermissions: franchiseId =>
    api.get(`franchise/${franchiseId}/get_managers_list/`),

  updateManagerPermissions: (franchiseId, userId, data) =>
    api.patch(`franchise/${franchiseId}/update_manager_permissions/${userId}/`, data),

  deleteManager: (franchiseId, userId) =>
    api.post(`franchise/${franchiseId}/remove_manager/${userId}/`),

  addManager: (franchiseId, userId) =>
    api.post(`franchise/${franchiseId}/add_manager_to_franchise/`, { user_id: userId }),

  getFranchiseManagers: franchiseCode =>
    api.get(`franchise/get_user_premissions/?franchise_code=${franchiseCode}`),
  getPaymentLink: franchiseOrderId =>
    api.post(`/franchise/franchise_orders/${franchiseOrderId}/update_payment_link/`),
};
