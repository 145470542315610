import { createSelector } from 'reselect';

export const getBcSplitTableItemsSelector = createSelector(
  [state => state.bcSplitTable.items],
  items => items,
);

export const getBcSplitTableItemsNameSelector = createSelector(
  [state => state.bcSplitTable.itemsName],
  itemsName => itemsName,
);

export const getBcSplitTableLoadedSelector = createSelector(
  [state => state.bcSplitTable.loaded],
  loaded => loaded,
);

export const getBcSplitTableCountSelector = createSelector(
  [state => state.bcSplitTable.items.length],
  itemsCount => itemsCount,
);

export const getBcSplitTablePageSelector = createSelector(
  [state => state.bcSplitTable.page],
  page => page,
);

export const getBcSplitTableNextSelector = createSelector(
  [state => state.bcSplitTable.next],
  next => next,
);

export const getSelectedAllModeSelector = createSelector(
  [state => state.bcSplitTable.selectedAll],
  selectedAll => selectedAll,
);

export const getSelectedItemsListSelector = createSelector(
  [state => state.bcSplitTable.items],
  items =>
    items.reduce((arr, item) => {
      if (item.checked) {
        arr.push(item.id);
      }

      return arr;
    }, []),
);
export const getUnselectedItemsListSelector = createSelector(
  [state => state.bcSplitTable.items],
  items =>
    items.reduce((arr, item) => {
      if (!item.checked) {
        arr.push(item.id);
      }

      return arr;
    }, []),
);
