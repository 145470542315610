import React from 'react';
import { useSelector } from 'react-redux';

import { useInternationalization } from '@hooks/useTranslationHook';
import { ModalContainer } from '../../containers/Modal/ModalContainer';
import { MODALS } from '../../containers/Modal/modalConstants';
import { getModalDataSelector } from '../../containers/Modal/modalSelectors';
import { DynamicRequest } from './components/dynamicRequest/dynamicRequest';

import './dynamicRequestModal.scss'

export const DynamicRequestModal = () => {
  const { formatMessage } = useInternationalization();

  const { modalData } = useSelector(getModalDataSelector);

  return (
    <ModalContainer
      modal={MODALS.dynamicRequest}
      onClose={modalData.onClose}
    >
      <div className="dynamic-request-modal">
        <div className="dynamic-request-modal__header">{formatMessage('Edit Request')}</div>
        <DynamicRequest
          onClose={modalData.onClose}
          blockId={modalData.blockId}
          block={modalData.block}
        />
      </div>
    </ModalContainer>
  );
};
