import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { bcSplitTableActions } from '@redux/bcSplitTable/bcSplitTableActions.js';
import { getSelectedCompanySelector } from '@redux/companies/companiesSelectors.js';
import { Code, ConnectError } from '@connectrpc/connect';

import { useInternationalization } from '@hooks/useTranslationHook.js';

import { tagTypesApi } from '@api/tagTypesApi.js';
import { EComponentType } from '@wabb-ui-kit/FormField/types/types';
import { EButtonSize } from '@wabb-ui-kit/Button/types/types';

import Button from '@wabb-ui-kit/Button/Button';
import FormField from '@wabb-ui-kit/FormField/FormField';

import { MODALS } from '@components/containers/Modal/modalConstants.jsx';
import { ModalContainer } from '@components/containers/Modal/ModalContainer.jsx';
import { getModalDataSelector } from '@components/containers/Modal/modalSelectors.jsx';

import './createEditTagModal.scss';

export const CreateEditTagModal = () => {
  const dispatch = useDispatch();
  const { formatMessage } = useInternationalization();

  const { modalData } = useSelector(getModalDataSelector);
  const { id, type, onClose } = modalData;
  const botId = useSelector(getSelectedCompanySelector).bot;

  const [error, setError] = useState('');
  const [tagName, setTagName] = useState(type === 'Edit' ? modalData.name : '');
  const [tagDescription, setDescription] = useState(type === 'Edit' ? modalData.description : '');

  const createTag = async () => {
    try {
      const  data  = await tagTypesApi.createTagTypes({
        name: tagName, description: tagDescription, botId,
      });
      dispatch(bcSplitTableActions.createNewItemSuccess(data));
      onClose();
    }  catch (e) {
      const connectErr = ConnectError.from(e);

      if (connectErr.code === Code.InvalidArgument) {
        setError('Tag with this name is already exists');
      }
    }
  };

  const editTag = () => {
    tagTypesApi
      .updateTag( {
        pk:id,
        name: tagName,
        description: tagDescription,
      })
      .then((res) => {
        dispatch(bcSplitTableActions.updateItemInSplitTable(res));
        onClose();
      });
  };

  return (
    <ModalContainer modal={MODALS.createEditTagModal} onClose={onClose}>
      <div className="tag-modal">
        <div className="tag-modal__content">
          <span className="tag-modal__content__title">
            { type === 'Add' ? 'Create new tag' : 'Edit tag' }
          </span>
          <div className="tag-modal__content__container">
            <div>
              <FormField
                Component={EComponentType.Input}
                isRequired
                label="Name"
                placeholder="Type here..."
                value={tagName}
                errorText={error}
                onChange={(e) => setTagName(e.target.value)}
                onCrossClick={() => setTagName('')}
              />
            </div>
            <div>
              <FormField
                Component={EComponentType.TextArea}
                label="Description"
                placeholder="Type here..."
                maxLength={500}
                height="144px"
                value={tagDescription}
                onChange={(e) => setDescription(e.target.value)}
                onCrossClick={() => setDescription('')}
              />
            </div>
          </div>
        </div>
        <div className="tag-modal__buttons">
          <Button
            type="primary"
            size={EButtonSize.L}
            disabled={!tagName}
            onClick={type === 'Add' ? createTag : editTag}
          >
            {type === 'Add' ? 'Create' : 'Save'}
          </Button>
          <Button
            type="secondary"
            style="tint"
            size={EButtonSize.L}
            onClick={onClose}
          >
            Cancel
          </Button>
        </div>
      </div>
    </ModalContainer>
  );
};
