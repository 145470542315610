import * as React from 'react';

export const CopyIcon = ({ color }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
  >
    <path
      d="M17 0H5C3.9 0 3 0.9 3 2V16H5V2H17V0ZM20 4H9C7.9 4 7 4.9 7 6V20C7 21.1 7.9 22 9 22H20C21.1 22 22 21.1 22 20V6C22 4.9 21.1 4 20 4ZM20 20H9V6H20V20Z"
      fill={color || '#9E9E9E'}
    />
  </svg>
);
