import { modalActions } from '@components/containers/Modal/modalActions';

export const checkCompanyStatus = (companyType, isOwner, dispatch) => {
  dispatch(modalActions.closeModal());

  if (companyType === 0) {
    dispatch(modalActions.openNotPaidModal({ isOwner }));
  } else if (companyType === 2) {
    dispatch(modalActions.openFrozenModal({ isOwner }));
  }
};
