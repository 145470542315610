import React, { useEffect, useState, useRef } from 'react';
import { bcSplitTableActions } from '@redux/bcSplitTable/bcSplitTableActions';
import { useDispatch } from 'react-redux';
import FadeLoader from 'react-spinners/FadeLoader';

import './BCInfinityScroll.scss';

export const BCInfiniteScroll = props => {
  const dispatch = useDispatch();
  const containerRef = useRef(null);
  const [isLoading, setIsLoading] = useState(false);
  const { isPendingGetItems, next, root, loadMore, threshold, rootMargin } = props;

  const callbackFunction = async entries => {
    const [entry] = entries;

    if (entry.isIntersecting && next && !isLoading) {
      setIsLoading(true);
      await props.loadMore();
      dispatch(bcSplitTableActions.clearNext());
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (props.backToTop && containerRef.current && props.root) {
      props.root.scrollTop = 0;
    }
  }, [props.backToTop]);

  useEffect(() => {
    const observer = new IntersectionObserver(callbackFunction, props);

    if (containerRef.current) {
      observer.observe(containerRef.current);
    }

    return () => {
      if (containerRef.current) observer.unobserve(containerRef.current);
    };
  }, [containerRef.current, root, next, rootMargin, threshold, loadMore]);

  return (
    <>
      {props.children}
      <div ref={containerRef} className="scroll-trigger" />
     {isPendingGetItems && <div className="scroll-loader">
        <FadeLoader size={100} color="#5925dc" loading />
      </div>}
    </>
  );
};
