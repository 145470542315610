import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Code, ConnectError } from '@connectrpc/connect';
import { bcSplitTableActions } from '@redux/bcSplitTable/bcSplitTableActions.js';
import { getBotIdSelectedCompanySelector } from '@redux/companies/companiesSelectors.js';
import { fastRepliesApi } from '@api/fastReplieApi.js';

import { EComponentType } from '@wabb-ui-kit/FormField/types/types';
import { EButtonSize } from '@wabb-ui-kit/Button/types/types';

import Button from '@wabb-ui-kit/Button/Button';
import FormField from '@wabb-ui-kit/FormField/FormField';

import { MODALS } from '@components/containers/Modal/modalConstants.jsx';
import { ModalContainer } from '@components/containers/Modal/ModalContainer.jsx';
import { getModalDataSelector } from '@components/containers/Modal/modalSelectors.jsx';

import './createEditFastRepliesModal.scss';

export const CreateEditFastRepliesModal = () => {
  const dispatch = useDispatch();

  const [error, setError] = useState('');

  const { modalData } = useSelector(getModalDataSelector);
  const { id, type, onClose } = modalData;
  const companyBot = useSelector(getBotIdSelectedCompanySelector);

  const [replyName, setReplyName] = useState(type === 'Edit' ? modalData.name : '');
  const [replyDescription, setReplyDescription] = useState(type === 'Edit' ? modalData.description : '');

  const createReply = async () => {
    try {
      const res = await fastRepliesApi.createFastReply({
        replyName,
        botId: companyBot,
        replyContent: replyDescription,
      });
      dispatch(bcSplitTableActions.createNewItemSuccess(res));
      onClose();
    } catch (err) {
      const connectErr = ConnectError.from(err);

      if (connectErr && connectErr.code === Code.InvalidArgument) {
        setError('A reply with this name or phrase already exists.');
      }
    }
  };

  const editReply = async () => {
    try {
      const res = await fastRepliesApi.updateFastReply({
        replyName,
        botId: companyBot,
        replyContent: replyDescription,
        replyId: id,
      });
      dispatch(bcSplitTableActions.updateItemInSplitTable(res));
      onClose();
    } catch (err) {
      const connectErr = ConnectError.from(err);

      if (connectErr && connectErr.code === Code.InvalidArgument) {
        setError('A reply with this name or phrase already exists.');
      }
    }
  };

  return (
    <ModalContainer modal={MODALS.createEditFastRepliesModal} onClose={onClose}>
      <div className="fast-replies-modal">
        <div className="fast-replies-modal__content">
          <span className="fast-replies-modal__content__title">
            { type === 'Add' ? 'Create new reply' : 'Edit reply' }
          </span>
          <div className="fast-replies-modal__content__container">
            <div>
              <FormField
                Component={EComponentType.Input}
                isRequired
                label="Name"
                placeholder="Type here..."
                value={replyName}
                errorText={error}
                onChange={(e) => setReplyName(e.target.value)}
                onCrossClick={() => setReplyName('')}
              />
            </div>
            <div>
              <FormField
                Component={EComponentType.TextArea}
                isRequired
                label="Description"
                placeholder="Type here..."
                maxLength={500}
                height="144px"
                value={replyDescription}
                onChange={(e) => setReplyDescription(e.target.value)}
                onCrossClick={() => setReplyDescription('')}
              />
            </div>
          </div>
        </div>
        <div className="fast-replies-modal__buttons">
          <Button
            type="primary"
            size={EButtonSize.L}
            disabled={!replyName || !replyDescription}
            onClick={type === 'Add' ? createReply : editReply}
          >
            { type === 'Add' ? 'Create' : 'Save' }
          </Button>
          <Button
            type="secondary"
            style="tint"
            size={EButtonSize.L}
            onClick={onClose}
          >
            Cancel
          </Button>
        </div>
      </div>
    </ModalContainer>
  );
};
