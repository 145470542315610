import React from 'react';

import { Popover } from '@ui-kit';
import { inlineStyles } from '@components/common/TextEditor/constants';
import { DecoratorItem, FieldSelect } from '@components/common/TextEditor/elements';
import { BracesIcon } from '../../../../../icons/text-editor/braces-icon';

export const TextDecoratorsPanel = ({
  isDecorators,
  toggleInlineStyle,
  hasInlineStyle,
  requestFieldsList,
  onChange,
}) => (
  <div className="tool-panel">
    {isDecorators && inlineStyles.map((style) => (
      <DecoratorItem
        key={style.textStyle}
        style={style}
        toggleInlineStyle={toggleInlineStyle}
        hasInlineStyle={hasInlineStyle}
      />
    ))}
    {requestFieldsList ? (
      <Popover
        size={{
          width: 'auto',
        }}
        renderTrigger={() => (
          <button className="btn-icon" type="button">
            <BracesIcon />
          </button>
        )}
      >
        <FieldSelect
          onChange={onChange}
          requestFieldsList={requestFieldsList}
        />
      </Popover>
    ) : null}
  </div>
);
