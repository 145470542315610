import React from 'react';

export const HelpkitInfoIcon = () => (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M16 5.5C21.7903 5.5 26.5 10.2097 26.5 16C26.5 21.7903 21.7903 26.5 16 26.5C10.2097 26.5 5.5 21.7903 5.5 16C5.5 10.2109 10.2097 5.5 16 5.5Z"
      fill="white"
      stroke="#EFF2F3"
      strokeWidth="1.5"
    />
    <path
      d="M14.8617 20.391C14.8617 21.036 15.3925 21.5298 16.0005 21.5298C16.6455 21.5298 17.1393 21.036 17.1393 20.391V15.5827C17.1393 14.9377 16.6455 14.4439 16.0005 14.4439C15.3925 14.4439 14.8617 14.9377 14.8617 15.5827V20.391Z"
      fill="#848B91"
    />
    <path
      d="M17.3291 11.729C17.3291 12.4628 16.7343 13.0576 16.0005 13.0576C15.2667 13.0576 14.6719 12.4628 14.6719 11.729C14.6719 10.9952 15.2667 10.4004 16.0005 10.4004C16.7343 10.4004 17.3291 10.9952 17.3291 11.729Z"
      fill="#848B91"
    />
  </svg>
);
