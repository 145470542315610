import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment/moment';

import { modalActions } from '@components/containers/Modal/modalActions';
import { useInternationalization } from '@hooks/useTranslationHook';
import { audienceActions } from '@redux/audience/audienceActions';
import { AudienceUserTableOptionsIcon } from '@icons/audience/audience-user-table-options-icon';
import { getSelectedCompanySelector } from '@redux/companies/companiesSelectors';
import CheckBox from '@wabb-ui-kit/CheckBox/CheckBox';
import Avatar from '@wabb-ui-kit/Avatar/Avatar';
import ConfirmModal from '@wabb-ui-kit/ConfirmModal/ConfirmModal';
import { EConfirmModalType } from '@wabb-ui-kit/ConfirmModal/types/types';
import { Popover } from '@ui-kit/components/Popover';
import { DeleteModalContent } from '../../DeleteModalContent/DeleteModalContent';
import './usersTableComponent.scss';

export const UsersTableComponent = ({ user, broadcasts }) => {
  const { formatMessage } = useInternationalization();
  const dispatch = useDispatch();
  const botId = useSelector(getSelectedCompanySelector)?.bot;


  const dateFormat = (data) => moment(data).format('DD/MM/YYYY HH:mm');

  const openUserDetailsModal = (idUser) => {
    if (!broadcasts) {
      dispatch(modalActions.closeModal());
      dispatch(
        modalActions.openAudienceDetailsModal({
          idUser,
          onClose: () => {
            dispatch(modalActions.closeModal());
          },
        }),
      );
    }
  };

  const handleChangeUserSelect = (id) => {
    dispatch(audienceActions.changeUserSelectedMode(id));
  };

  const handleDeleteItem = (e, id) => {
    e.stopPropagation();
    dispatch(
      modalActions.openConfirmModal({
        title: `${formatMessage('Delete tag')}`,
        text: <span>
          Are you sure you want to delete tag&nbsp;
          <span style={{ color: '#101828', fontWeight: 500 }}>
            ”
            { user.fullName }
            ”
          </span>
          ?
        </span>,
        confirmButtonText: formatMessage('Delete'),
        type: 'delete',
        handleConfirm: async () => {
          dispatch(audienceActions.deleteUserRequest({ id, botId }));
          dispatch(modalActions.closeModal());
        },
        onClose: () => {
          dispatch(modalActions.closeModal());
        },
      }),
    );
  };

  return (
    <div
      className="audience-user-table-component"
      onClick={() => openUserDetailsModal(user.id)}
    >
      <div className="audience-user-table-component__users">
        {!broadcasts && (
          <div
            onClick={(event) => event.stopPropagation()}
            className="audience-user-table-component__users_checkbox"
          >
            <CheckBox
              type="checkbox"
              isChecked={user.checked}
              onChange={() => handleChangeUserSelect(user.id)}
            />
          </div>
        )}
        <div className="audience-user-table-component__users_avatar-container">
          <Avatar type="person" size="s" img={user.avatar} />
        </div>
        <div className="audience-user-table-component__users_name">
          <div>{user.fullName}</div>
        </div>
      </div>
      <div className="audience-user-table-component__whatsapp">{user.phone}</div>
      <div className="audience-user-table-component__subscribed">
        <span>{dateFormat(user.createdAt)}</span>
      </div>
      {!broadcasts && (
        <Popover
          size={{ width: 'auto' }}
          renderTrigger={() => (
            <div className="audience-user-table-component__options">
              <AudienceUserTableOptionsIcon />
            </div>
          )}
        >
          <div className="audience-user-table-component__options_popover">
            <div
              onClick={() => openUserDetailsModal(user.id)}
              className="audience-user-table-component__options_popover_info"
            >
              {formatMessage('More info')}
            </div>
            <div
              className="audience-user-table-component__options_popover_delete"
              onClick={(e) => handleDeleteItem(e, user.id)}
            >
              {formatMessage('Delete')}
            </div>
          </div>
        </Popover>
      )}
    </div>
  );
};
