import React, { useEffect, useState } from 'react';
import Button from '@wabb-ui-kit/Button/Button';
import { EButtonSize } from '@wabb-ui-kit/Button/types/types';
import FormField from '@wabb-ui-kit/FormField/FormField.tsx';
import { EComponentType } from '@wabb-ui-kit/FormField/types/types';
import { Popover } from '@ui-kit';
import { BcPhoneInput } from '@components/common/fields/BcPhoneInput';
import { useInternationalization } from '@hooks/useTranslationHook';
import { regExp } from '@components/common/fields/newBcField/newBcFieldHelper';
import NoteField from './NoteField/NoteField';

import './СontactDetailItem.scss';

export const ContactDetailItem = ({ name, field, updateUserCard }) => {
  const { formatMessage } = useInternationalization();
  const [value, setValue] = useState('');
  const [tempValue, setTempValue] = useState('');
  const [errorText, setErrorText] = useState('');
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    setValue(field || '');
    setTempValue(field || '');
    setErrorText('');
  }, [field]);

  const handleSave = () => {
    if (name === 'Email') {
      if (regExp.email && regExp.email.expression.test(String(tempValue).toLowerCase())) {
        updateUserCard({ [name.toLowerCase()]: tempValue });
        setValue(tempValue);
        setErrorText('');
        setIsOpen(false);
      } else {
        setErrorText('Invalid email address');
      }
    } else if (name === 'Note') {
      updateUserCard({ [name.toLowerCase()]: tempValue });
      setValue(tempValue);
      setIsOpen(false);
    }
  };

  const handleInputChange = (event) => {
    setTempValue(event.target.value);

    if (errorText) {
      setErrorText('');
    }
  };

  const componentsMapper = {
    Email: (
      <FormField
        Component={EComponentType.Input}
        width={256}
        onCrossClick={() => setTempValue('')}
        onChange={handleInputChange}
        value={tempValue}
        errorText={errorText}
      />
    ),
    Phone: (
      <BcPhoneInput
        specialLabel=""
        name="phone"
        onCrossClick={() => setTempValue('')}
        onChange={handleInputChange}
        value={tempValue}
      />
    ),
    Note: (
      <FormField
        Component={EComponentType.TextArea}
        width={256}
        onCrossClick={() => setTempValue('')}
        onChange={handleInputChange}
        value={tempValue}
        height="144px"
        maxLength="1000"
      />
    ),
  };

  return (
    <div className="contact-detail-item">
      <div className="contact-detail-item__header">
        <div className="contact-detail-item__field-name">{formatMessage(name)}</div>
        {name !== 'Phone' && (
          <Popover
            size={{ width: 'auto' }}
            controllableOpenState={{ isOpen, setIsOpen }}
            renderTrigger={() => (
              <div>
                <Button size={EButtonSize.S} type="primary" style="link" width="25px">
                  Edit
                </Button>
              </div>
            )}
          >
            <div className="contact-detail-item__header-popover">
              {componentsMapper[name]}
              <div className="contact-detail-item__header-popover-buttons">
                <Button
                  size={EButtonSize.S}
                  type="secondary"
                  style="tint"
                  onClick={() => setIsOpen(false)}
                >
                  Cancel
                </Button>
                <Button
                  size={EButtonSize.S}
                  type="primary"
                  style="filled"
                  onClick={handleSave}
                >
                  Save
                </Button>
              </div>
            </div>
          </Popover>
        )}
      </div>
      {name === 'Email' ? (
        <div className="contact-detail-item__field">
          <input
            type="text"
            autoComplete="off"
            value={value}
            readOnly
            className="contact-detail-item__field"
            style={{ display: value ? 'block' : 'none' }}
          />
        </div>
      ) : name === 'Note' ? (
        <div className="contact-detail-item__field">
          <NoteField note={value} />
        </div>
      ) : (
        <div className="contact-detail-item__field">{value}</div>
      )}
    </div>
  );
};
