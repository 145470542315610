import React from 'react';
import { useSelector, useDispatch } from 'react-redux';

import '../modalsComponent.scss';
import './deleteItemModal.scss';

import { ModalContainer } from '../../containers/Modal/ModalContainer';
import { MODALS } from '../../containers/Modal/modalConstants';
import { getModalDataSelector } from '../../containers/Modal/modalSelectors';
import { modalActions } from '../../containers/Modal/modalActions';
import { Button } from '../../common/Button';
import { useInternationalization } from '../../../hooks/useTranslationHook';

const styles = {
  top: '15%',
  width: '330px',
};

export const DeleteItemModal = () => {
  const { formatMessage } = useInternationalization();

  const dispatch = useDispatch();

  const { modalData } = useSelector(getModalDataSelector);

  const handleDelete = () => {
    modalData.onDeleteFunction();
    dispatch(modalActions.closeModal());
  };

  return (
    <ModalContainer
      modal={MODALS.deleteItem}
      onClose={modalData.onClose}
      customStyles={styles}
    >
      <div className="campaigns-modal">
        <div className="campaigns-modal__header">{formatMessage('Warning')}</div>
        <div className="delete-item-modal__content">
          <div className="delete-item-modal__description-container">
            <div className="delete-item-modal__description">
              {formatMessage('Do you really want to delete this item ?')}
            </div>
          </div>
          <div className="delete-item-modal__footer-group">
            <Button
              onClick={() => dispatch(modalActions.closeModal())}
              color="transparent"
              customClass="delete-item-modal__button"
            >
              {formatMessage('Cancel')}
            </Button>
            <Button
              onClick={handleDelete}
              color="green"
              customClass="delete-item-modal__button"
            >
              {formatMessage('Delete')}
            </Button>
          </div>
        </div>
      </div>
    </ModalContainer>
  );
};
