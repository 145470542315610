import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getIdCompanySelector } from '@redux/companies/companiesSelectors';

import { usersApi } from '@api/usersApi';
import { getManagersPermissionsSelector } from '@redux/managers/managersSelector';
import { useInternationalization } from '@hooks/useTranslationHook';
import { BcSplitTable as Table } from '@components/common/SplitTable/SplitTable';
import { modalActions } from '@components/containers/Modal/modalActions';
import { LocalSearch } from '@components/common/LocalSearch/LocalSearch';
import { getBcSplitTableCountSelector } from '@redux/bcSplitTable/bcSplitTableSelectors';
import EmptyPageWrapper from '@wabb-ui-kit/EmptyPageWrapper/EmptyPageWrapper';

import { ManagerItem } from './ManagerItem';

import './ManagersSettings.scss';

const gridTableColumns = '20% 9% 9% 9% 9% 9% 9% 9% 9% 8%';

export const columns = [
  { name: 'Name', field: 'name' },
  // { name: 'Dashboard', field: 'dashboard' }, //NOTE PAGE NOT READY
  { name: 'Audience', field: 'audience' },
  { name: 'Campaigns', field: 'campaigns' },
  { name: 'Broadcasts', field: 'broadcasts' },
  { name: 'Automation', field: 'automation' },
  { name: 'Flows', field: 'flows' },
  { name: 'Settings', field: 'settings' },
  { name: 'Live chat', field: 'liveChat' },
];

export const ManagersSettings = () => {
  const { formatMessage } = useInternationalization();
  const managersLiveChatPermissions = useSelector(
    getManagersPermissionsSelector,
  ).last_live_chat_permissions;

  const [managersActiveList, setManagersActiveList] = useState([]);
  const [firstUpdate, setFirstUpdate] = useState(true);
  const dispatch = useDispatch();
  const companyId = useSelector(getIdCompanySelector);
  const itemsCount = useSelector(getBcSplitTableCountSelector);

  useEffect(() => {
    if (firstUpdate) {
      setFirstUpdate(false);
    } else {
      updateItem(managersLiveChatPermissions);
    }
  }, [managersLiveChatPermissions]);

  const handleGetActiveManagersList = async ({ botId, searchName }) => {
    try {
      const response = await usersApi.getActiveUsers({ botId, searchName: searchName ?? '' });
      return response.managers;
    } catch (error) {
      console.error('Error fetching flows:', error);
      return null;
    }
  };

  const updateItem = (data) => {
    const { activeManagerId, updateActiveUserObj } = data;
    const managersActiveListCopy = [...managersActiveList];
    const index = managersActiveListCopy.findIndex(
      (manager) => manager.id === activeManagerId,
    );
    managersActiveListCopy[index] = {
      ...managersActiveListCopy[index],
      ...updateActiveUserObj,
    };
    setManagersActiveList(managersActiveListCopy);
  };

  const handleCreateMember = () => {
    dispatch(
      modalActions.openAddManagerModal({
        action: 'Create',
        companyId,
        onClose: () => {
          dispatch(modalActions.closeModal());
        },
      }),
    );
  };

  return (
    <div className="managers-setting-container">
      <div className="managers-setting-container__second-header">
        <div className="managers-setting-container__second-header_path">
          <span className="managers-setting-container__second-header_main-text">
            { formatMessage('All members') }
          </span>
          <span className="managers-setting-container__second-header_main-text_secondary">
            { itemsCount }
          </span>
        </div>
        <div>
          <LocalSearch handler={() => {
          }}
          />
        </div>
      </div>
      <Table
        EmptyComponent={() => (
          <EmptyPageWrapper
            title="team member"
            nameToCreate="member"
            handleCreateNewItem={handleCreateMember}
          />
        )}
        withoutCheckbox
        gridColumns={gridTableColumns}
        columns={columns}
        Component={ManagerItem}
        getItemsApi={handleGetActiveManagersList}
        sourceItemsName={formatMessage('Managers')}
      />
    </div>
  );
};
