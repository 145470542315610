import React from 'react';

export const ChatIcon = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.5027 3.97869C10.5488 3.85624 8.61731 4.4512 7.07095 5.65189C5.52459 6.85258 4.46967 8.57641 4.10422 10.4998C3.73878 12.4231 4.08795 14.4138 5.08618 16.0979C5.1884 16.2704 5.21536 16.4773 5.16074 16.6702L4.30403 19.696L7.32984 18.8393L7.52911 19.5431L7.90208 18.9138C9.58624 19.9121 11.5769 20.2612 13.5002 19.8958C15.4236 19.5303 17.1474 18.4754 18.3481 16.929C19.5488 15.3827 20.1438 13.4512 20.0213 11.4973C19.8989 9.54336 19.0674 7.7013 17.683 6.31695C16.2987 4.93259 14.4566 4.10114 12.5027 3.97869ZM7.43337 20.3304C9.36337 21.3885 11.6045 21.7451 13.7733 21.333C16.0468 20.901 18.0844 19.6541 19.5036 17.8263C20.9229 15.9984 21.6261 13.7154 21.4814 11.4058C21.3366 9.09619 20.3538 6.91883 18.7175 5.28249C17.0812 3.64615 14.9038 2.66336 12.5942 2.51861C10.2846 2.37387 8.00158 3.07713 6.17374 4.49637C4.34591 5.91562 3.09896 7.95323 2.66699 10.2267C2.25492 12.3955 2.61154 14.6366 3.6696 16.5666L2.89436 19.3047C2.82214 19.554 2.81796 19.8181 2.88229 20.0695C2.94692 20.3222 3.07833 20.5528 3.26275 20.7373C3.44717 20.9217 3.6778 21.0531 3.93046 21.1177C4.18194 21.182 4.44603 21.1779 4.69532 21.1056L7.43337 20.3304Z"
      fill="white"
    />
    <path
      d="M12.0093 13.0878C12.6153 13.0878 13.1065 12.5966 13.1065 11.9906C13.1065 11.3847 12.6153 10.8934 12.0093 10.8934C11.4033 10.8934 10.9121 11.3847 10.9121 11.9906C10.9121 12.5966 11.4033 13.0878 12.0093 13.0878Z"
      fill="white"
    />
    <path
      d="M7.62048 13.0878C8.22645 13.0878 8.71768 12.5966 8.71768 11.9906C8.71768 11.3847 8.22645 10.8934 7.62048 10.8934C7.01451 10.8934 6.52327 11.3847 6.52327 11.9906C6.52327 12.5966 7.01451 13.0878 7.62048 13.0878Z"
      fill="white"
    />
    <path
      d="M16.3981 13.0878C17.0041 13.0878 17.4953 12.5966 17.4953 11.9906C17.4953 11.3847 17.0041 10.8934 16.3981 10.8934C15.7922 10.8934 15.3009 11.3847 15.3009 11.9906C15.3009 12.5966 15.7922 13.0878 16.3981 13.0878Z"
      fill="white"
    />
  </svg>
);
