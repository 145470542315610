import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { NewBcField as Field } from '@components/common/fields/newBcField/newBcField';

import Button from '@wabb-ui-kit/Button/Button';
import FormField from '@wabb-ui-kit/FormField/FormField';
import { EComponentType } from '@wabb-ui-kit/FormField/types/types';
import { EButtonSize } from '@wabb-ui-kit/Button/types/types';
import { ModalContainer } from '../../containers/Modal/ModalContainer';
import { MODALS } from '../../containers/Modal/modalConstants';
import { getModalDataSelector } from '../../containers/Modal/modalSelectors';
import { getIntegrationEventsSelector } from '../../../redux/integrationEvents/integrationEventsSelector';
import { integrationEventsActions } from '../../../redux/integrationEvents/integrationEventsActions';

import { useInternationalization } from '../../../hooks/useTranslationHook';

import './IntegrationEventModal.scss';

const styles = {
  width: '400px',
  overflow: 'visible',
};

export const IntegrationEventModal = () => {
  const { formatMessage } = useInternationalization();

  const dispatch = useDispatch();

  const { modalData } = useSelector(getModalDataSelector);

  const integrationEventsError = useSelector(
    getIntegrationEventsSelector,
  ).integrationEventsErrors;

  const [inputs, setInputs] = useState({
    integrationEventName: '',
  });

  const [errorInput, setErrorInput] = useState('');
  const [checkError, setCheckError] = useState(0);

  useEffect(() => {
    checkError && setErrorInput(formatMessage(integrationEventsError));
    setCheckError(checkError + 1);
  }, [integrationEventsError]);

  const handleChange = e => {
    const { name, value } = e.target;
    setInputs(inputs => ({ ...inputs, [name]: value }));
  };

  const handleCheckName = e => {
    if (e.target.value.indexOf(' ') !== -1) {
      setErrorInput(formatMessage('Event name must be a one word'));
    } else if (e.target.value.length > 20) {
      setErrorInput('Name must be no more than 20 symbols');
    } else if (e.target.value.indexOf(' ') === -1 && e.target.value.length <= 20) {
      setErrorInput('');
    }

    handleChange(e);
  };

  const handleSubmit = event => {
    event.preventDefault();

    if (integrationEventName === '') {
      setErrorInput(formatMessage('This field can`t be blank'));
    } else if (integrationEventName !== '' && errorInput === '') {
      dispatch(
        integrationEventsActions.createIntegrationEventRequest({
          integrationEventName,
          companyBot,
          handleSelectIntegrationEvent,
        }),
      );
    }
  };

  const { integrationEventName } = inputs;
  const { companyBot, handleSelectIntegrationEvent } = modalData;

  return (
    <ModalContainer
      modal={MODALS.integrationEvent}
      onClose={modalData.onClose}
      customStyles={styles}
    >
      <div className="zapier-events-modal">
        <span className="zapier-events-modal__header">
          {formatMessage(modalData.action)} {formatMessage('event')}
        </span>
        <div className="zapier-events-modal__content">
          <div className="form">
            <div>
              <FormField
                Component={EComponentType.Input}
                name="integrationEventName"
                label={formatMessage('New integration event')}
                value={integrationEventName}
                errorText={errorInput}
                onChange={handleCheckName}
              />
            </div>
            <Button type="primary" onClick={handleSubmit} size={EButtonSize.L}>
              {formatMessage(modalData.action)} {formatMessage('event')}
            </Button>
          </div>
        </div>
      </div>
    </ModalContainer>
  );
};
