import { navigationMenuConstants } from './navigationMenuConstants';

export const navigationMenuActions = {
  closeMenu() {
    return {
      type: navigationMenuConstants.CLOSE_MENU,
    };
  },
  openMenu() {
    return {
      type: navigationMenuConstants.OPEN_MENU,
    };
  },

  hideHeader() {
    return {
      type: navigationMenuConstants.HIDE_HEADER,
    };
  },

  showHeader() {
    return {
      type: navigationMenuConstants.SHOW_HEADER,
    };
  },
};
