import React from 'react';
import Select from 'react-select';

import { fieldSelectStyles as styles } from '@components/common/TextEditor/constants';

import './FieldSelect.scss';

export const FieldSelect = ({
  requestFieldsList, onChange,
}) => (
  <div className="field-select-container">
    <Select
      components={{ IndicatorSeparator: null }}
      styles={styles}
      menuIsOpen
      placeholder="Search field"
      options={requestFieldsList}
      onChange={onChange}
    />
  </div>
);
