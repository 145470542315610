import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Button from '@wabb-ui-kit/Button/Button';
import { EButtonSize } from '@wabb-ui-kit/Button/types/types';
import { useInternationalization } from '@hooks/useTranslationHook';
import { audienceApi } from '@api/audience';
import { audienceActions } from '@redux/audience/audienceActions';
import FormField from '@wabb-ui-kit/FormField/FormField.tsx';
import { EComponentType } from '@wabb-ui-kit/FormField/types/types';
import { Code, ConnectError } from '@connectrpc/connect';
import { MODALS } from '../../containers/Modal/modalConstants';
import { getModalDataSelector } from '../../containers/Modal/modalSelectors';
import { ModalContainer } from '../../containers/Modal/ModalContainer';
import { BcPhoneInput } from '../../common/fields/BcPhoneInput';

import './addNewContactInAudience.scss';

const style = { width: '30%' };

const isObjectWithoutError = object => Object.values(object).every(value => value === '');

export const AddNewContactInAudience = () => {
  const { modalData } = useSelector(getModalDataSelector);
  const { formatMessage } = useInternationalization();
  const dispatch = useDispatch();

  const [userData, setUserData] = useState({
    first_name: '',
    last_name: '',
    phone: '',
    bot: modalData.companyBot,
  });

  const [userDataErrors, setUserDataErrors] = useState({
    first_name: '',
    last_name: '',
    phone: '',
  });

  const handleChangeValue = e => {
    const { name, value } = e.target;
    setUserData({ ...userData, [name]: value });
    setUserDataErrors({ ...userDataErrors, [name]: '' });
  };

  const handleChangePhone = value => {
    setUserData({ ...userData, phone: value });
    setUserDataErrors({ ...userDataErrors, phone: '' });
  };

  const handleCrossClick = fieldName => {
    setUserData({ ...userData, [fieldName]: '' });
    setUserDataErrors({ ...userDataErrors, [fieldName]: '' });
  };

  const valueFieldsValidation = () => {
    const errorObject = {};

    for (const [key, value] of Object.entries(userData)) {
      if (value === '' && key !== 'last_name') {
        errorObject[key] = 'This field must not be empty';
      } else {
        errorObject[key] = '';
      }
    }

    return errorObject;
  };

  const validatePhone = value => value.length > 9 && value.length < 19;

  const handleSubmit = () => {
    const errorObject = valueFieldsValidation();

    if (!validatePhone(userData.phone)) {
      errorObject.phone = 'Phone is not valid';
    }

    if (isObjectWithoutError(errorObject)) {
      audienceApi
        .createAudienceUser({ ...userData, phone: `+${userData.phone}` })
        .then(res => {
          dispatch(audienceActions.createUser(res));
          modalData.onClose();
        })
        .catch(e => {
          const connectErr = ConnectError.from(e);

          if (connectErr.code === Code.InvalidArgument) {
            setUserDataErrors({
              ...userDataErrors,
              phone: 'User with this phone already exist',
            });
          }
        });
    } else {
      setUserDataErrors(errorObject);
    }
  };

  return (
    <ModalContainer
      modal={MODALS.addNewContact}
      onClose={modalData.onClose}
      customStyles={style}
    >
      <div className="create-audience-user">
        <div className="create-audience-user__container">
          <div className="create-audience-user__header">
            {formatMessage('Add new Contact')}
          </div>
          <div className="create-audience-user__container__label">
            <FormField
              Component={EComponentType.Input}
              name="first_name"
              label={formatMessage('First name')}
              onChange={handleChangeValue}
              value={userData.first_name}
              helperText={
                userDataErrors.first_name && formatMessage(userDataErrors.first_name)
              }
              onCrossClick={() => handleCrossClick('first_name')}
              width={336}
              isRequired
              errorText={userDataErrors.first_name}
            />
          </div>
          <div className="create-audience-user__container__label">
            <FormField
              Component={EComponentType.Input}
              name="last_name"
              label={formatMessage('Second name')}
              onChange={handleChangeValue}
              value={userData.last_name}
              onCrossClick={() => handleCrossClick('last_name')}
              width={336}
            />
          </div>
          <div className="create-audience-user__container__phone">
            <BcPhoneInput
              name="phone"
              title={formatMessage('Phone')}
              specialLabel=""
              onChange={handleChangePhone}
              value={userData.phone}
              isRequired
              helperText={userDataErrors.phone}
              onCrossClick={() => handleCrossClick('phone')}
              width={336}
            />
          </div>
          <div className="create-audience-user__container__buttons">
            <div className="create-audience-user__container__buttons_left">
              <Button
                onClick={modalData.onClose}
                type="secondary"
                style="tint"
                size={EButtonSize.L}
              >
                {formatMessage('Cancel')}
              </Button>
            </div>
            <div className="create-audience-user__container__buttons_right">
              <Button
                type="primary"
                style="filled"
                size={EButtonSize.L}
                onClick={handleSubmit}
              >
                {formatMessage('Add contact')}
              </Button>
            </div>
          </div>
        </div>
      </div>
    </ModalContainer>
  );
};
