export type TModalSizeType = 'small' | 'big' | 'large';

export enum EModalSize {
  SMALL = 'small',
  BIG = 'big',
  LARGE = 'large',
}

export enum EOverflowTypes {
  HIDDEN = 'hidden',
  AUTO = 'auto',
}
