import { CompositeDecorator } from 'draft-js';

import { EntityTypes } from '@components/common/TextEditor/constants';
import { ValueField } from '@components/common/TextEditor/elements';

function findValueFieldEntities(contentBlock, callback, contentState) {
  contentBlock.findEntityRanges((character) => {
    const entityKey = character.getEntity();
    return (
      entityKey !== null && contentState.getEntity(entityKey).getType() === EntityTypes.valueField
    );
  }, callback);
}

const valueFieldDecorator = {
  strategy: findValueFieldEntities,
  component: ValueField,
};

export const decorators = new CompositeDecorator([valueFieldDecorator]);
