import React, { useEffect, useRef, useState } from 'react';
import classNames from 'classnames';
import './UploadImage.scss';
import { useInternationalization } from '@hooks/useTranslationHook';

export const UploadImage = ({
  defaultImage,
  onUpdate,
  classNameImageContainer,
  children,
  helperText,
}) => {
  const { formatMessage } = useInternationalization();
  const [previewImg, setPreviewImg] = useState('');
  const fileRef = useRef(null);

  useEffect(() => {
    setPreviewImg(defaultImage);
  }, [defaultImage]);

  const handleImageClick = () => {
    fileRef.current.click();
  };

  const handleInputChange = e => {
    const uploadedFile = e.target.files[0];

    if (uploadedFile) {
      if (uploadedFile.size <= 4194304) { // 4 MB size limit
        setPreviewImg(URL.createObjectURL(uploadedFile));
        onUpdate(uploadedFile);
      }
    }
  };

  const handleDeleteImg = () => {
    setPreviewImg('');
    onUpdate('');
  };

  return (
    <div className="upload-image">
      <input
        accept=".jpg, .jpeg, .png"
        type="file"
        className="upload-image__input"
        ref={fileRef}
        onChange={handleInputChange}
      />
      <div
        className={classNameImageContainer}
        style={{ backgroundImage: `url(${previewImg})`, border: previewImg ? 'none' : '' }}
        onClick={handleImageClick}
      >
        {!previewImg && (
          <>
            {children}
          </>
        )}
      </div>
      {!previewImg && <div className="upload-image__description">{helperText}</div>}
      {previewImg && (
        <div className="upload-image__btn-group">
          <div
            className={classNames(
              'upload-image__btn-group-text',
              'upload-image__btn-group-text_red',
            )}
            onClick={handleDeleteImg}
          >
            {formatMessage('Delete')}
          </div>
          <div className="upload-image__btn-group-text" onClick={handleImageClick}>
            {formatMessage('Change')}
          </div>
        </div>
      )}
    </div>
  );
};
