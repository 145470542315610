import React from 'react';

export const NegativeAlertIcon = () => (
  <svg
    width="56"
    height="56"
    viewBox="0 0 56 56"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="56" height="56" rx="28" fill="#FEE4E2" />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M28 18.375C22.6843 18.375 18.375 22.6843 18.375 28C18.375 33.3157 22.6843 37.625 28 37.625C33.3157 37.625 37.625 33.3157 37.625 28C37.625 22.6843 33.3157 18.375 28 18.375ZM16.625 28C16.625 21.7178 21.7178 16.625 28 16.625C34.2822 16.625 39.375 21.7178 39.375 28C39.375 34.2822 34.2822 39.375 28 39.375C21.7178 39.375 16.625 34.2822 16.625 28Z"
      fill="#D92D20"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M28 21.875C28.4832 21.875 28.875 22.2668 28.875 22.75V28.875C28.875 29.3582 28.4832 29.75 28 29.75C27.5168 29.75 27.125 29.3582 27.125 28.875V22.75C27.125 22.2668 27.5168 21.875 28 21.875Z"
      fill="#D92D20"
    />
    <path
      d="M28 34.125C28.7249 34.125 29.3125 33.5374 29.3125 32.8125C29.3125 32.0876 28.7249 31.5 28 31.5C27.2751 31.5 26.6875 32.0876 26.6875 32.8125C26.6875 33.5374 27.2751 34.125 28 34.125Z"
      fill="#D92D20"
    />
  </svg>
);
