import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment/moment';

import { useInternationalization } from '@hooks/useTranslationHook';
import { Button, CheckBox } from '@components/common';
import { FranchiseCompanyApi } from '@api/franchiseCompanyApi';
import { UploadImage } from '@components/common/UploadImage/UploadImage';
import { NewBcField } from '@components/common/fields/newBcField/newBcField';
import { RadioButtons } from '@components/common/RadioButtons/RadioButtons';
import { regExp } from '@components/common/fields/newBcField/newBcFieldHelper';
import { useContextSelector } from 'use-context-selector';
import { BcSelect } from '../../common/Select';
import { getModalDataSelector } from '../../containers/Modal/modalSelectors';
import { ModalContainer } from '../../containers/Modal/ModalContainer';
import { MODALS } from '../../containers/Modal/modalConstants';
import { FranchiseContext } from '../../../contexts/franchiseContext';

import './createCompanyFranchise.scss';

const styles = {
  width: '40%',
  minWidth: '500px',
};

const customStyles = () => ({
  control: styles => ({
    ...styles,
    width: '100%',
    height: '52px',
    backgroundColor: '#FFFF',
    border: '1px solid #D9DFE3',
    borderRadius: '10px',
    cursor: 'pointer',
  }),
});

const isObjectWithoutError = object => Object.values(object).every(value => value === '');

export const CreateCompanyFranchise = () => {
  const { formatMessage } = useInternationalization();
  const { modalData } = useSelector(getModalDataSelector);
  const data = useContextSelector(FranchiseContext, data => data);
  const [companies, setCompanies] = useState([]);
  const [selectedCompany, setSelectedCompany] = useState(null);
  const [companyData, setCompanyData] = useState({
    name: '',
    plan_type: null,
    enable_billing_page: false,
    enable_integration: false,
    enable_managers_page: false,
    owner: null,
  });
  const [currentAvatar, setCurrentAvatar] = useState('');
  const [isError, setIsError] = useState(false);
  const [users, setUsers] = useState();
  const [companyDataErrors, setCompanyDataErrors] = useState({
    name: '',
    plan_type: '',
    enable_billing_page: '',
    enable_integration: '',
    owner: '',
    enable_managers_page: '',
  });
  const { id } = data;

  const permission = [
    {
      id: 0,
      label: formatMessage('IntegrationsFr'),
      checked: companyData.enable_integration,
      field: 'enable_integrations',
    },
    {
      id: 1,
      label: formatMessage('BillingFr'),
      checked: companyData.enable_billing_page,
      field: 'enable_billing_page',
    },
    {
      id: 2,
      label: formatMessage('Team'),
      checked: companyData.enable_managers_page,
      field: 'enable_managers_page',
    },
  ];

  const options = [
    {
      name: 'plan_type',
      value: formatMessage('Trial'),
      id: 2,
      checked: true,
    },
    {
      name: 'plan_type',
      value: formatMessage('middle'),
      id: 0,
      checked: false,
    },
    {
      name: 'plan_type',
      value: formatMessage('PRO'),
      id: 1,
      checked: false,
    },
  ];

  const handleUpdateCheck = el => {
    switch (el.id) {
      case 0:
        setCompanyData({ ...companyData, enable_integration: !el.checked });
        break;
      case 1:
        setCompanyData({ ...companyData, enable_billing_page: !el.checked });
        break;
      case 2:
        setCompanyData({ ...companyData, enable_managers_page: !el.checked });
        break;
    }
  };

  const handleUpdateAvatar = image => {
    setCurrentAvatar(image);
  };

  const valueFieldsValidation = () => {
    const errorObject = {};

    for (const [key, value] of Object.entries(companyData)) {
      if (value === '') {
        errorObject[key] = 'This field must not be empty';
      } else if (
        regExp[key] &&
        !regExp[key].expression.test(String(value).toLowerCase())
      ) {
        errorObject[key] = regExp[key].errorMessage;
      } else {
        errorObject[key] = '';
      }
    }

    return errorObject;
  };

  const handleChangeValue = (e, name) => {
    if (e.target && e.target.value) {
      setCompanyData({ ...companyData, [name]: e.target.value });
      setCompanyDataErrors({ ...companyDataErrors, [name]: '' });
    } else {
      setCompanyData({ ...companyData, [name]: '' });
    }
  };

  const selectCompany = value => {
    setSelectedCompany({ label: value.label, value: value.value });
  };

  const handleChangeOwner = value => {
    if (value) {
      setCompanyData({ ...companyData, owner: value.value });
      setIsError(false);
    } else {
      setCompanyData({ ...companyData, owner: null });
    }
  };

  const handleCreate = () => {
    const errorObject = valueFieldsValidation();

    if (
      isObjectWithoutError(errorObject) &&
      companyData.plan_type !== null &&
      companyData.owner !== null
    ) {
      setIsError(false);
      const data = new FormData();
      companyData.name && data.append('name', companyData.name);
      data.append('avatar', currentAvatar);
      selectedCompany && data.append('company_clone_from', selectedCompany.value);
      data.append('owner', companyData.owner);
      data.append('plan_type', companyData.plan_type);
      data.append('type', 1);
      data.append('franchise', modalData.franchise);
      data.append('enable_billing_page', companyData.enable_billing_page);
      data.append('enable_integrations', companyData.enable_integration);
      data.append('enable_managers_page', companyData.enable_managers_page);
      data.append('end_subscription', moment().format());
      modalData.handleCreate(data);
      modalData.onClose();
    } else {
      setCompanyDataErrors({ ...companyDataErrors, ...errorObject });
      companyData.owner === null ? setIsError(true) : setIsError(false);
    }
  };

  useEffect(() => {
    FranchiseCompanyApi.getFranchiseUsersShort(modalData.franchise).then(res => {
      setUsers(res.data.map(el => ({ value: el.id, label: el.email })));
    });
  }, []);

  useEffect(() => {
    setCompanyData({ ...companyData, plan_type: options[0].id.toString() });
  }, []);

  useEffect(() => {
    FranchiseCompanyApi.getFranchiseCompaniesShort(id).then(res => {
      const dataCompanies = res.data.map(item => ({
        value: item.id,
        label: item.name,
      }));
      setCompanies(dataCompanies);
    });
  }, []);

  return (
    <ModalContainer
      modal={MODALS.createFranchiseCompany}
      onClose={modalData.onClose}
      customStyles={styles}
    >
      <div className="create-company-franchise">
        <div className="create-company-franchise__header">
          {formatMessage('Create Company')}
        </div>
        <div className="create-company-franchise__content">
          <div className="create-company-franchise__content_container">
            <div className="create-company-franchise__content_container__edit-form">
              <UploadImage
                children={modalData.adIcon}
                classNameImageContainer="create-company-franchise__content_container__edit-form_upload_avatar"
                helperText={formatMessage(
                  'The uploaded image must be in JPG, PNG format and should not exceed 5MB in size',
                )}
                onUpdate={handleUpdateAvatar}
              />
            </div>
            <div className="create-company-franchise__content_container__field">
              <NewBcField
                name="name"
                label={formatMessage('Company name')}
                value={companyData.name}
                onChange={handleChangeValue}
                placeholder={formatMessage('Company Name')}
                helperText={
                  Boolean(companyDataErrors.name) && formatMessage(companyDataErrors.name)
                }
              />
            </div>
            <div className="create-company-franchise__content_container__field">
              <span className="create-company-franchise__content_container__field_label">
                {formatMessage('Owner')}
              </span>
              <BcSelect
                outlined="none"
                color="white"
                placeholder={formatMessage('Set user as owner')}
                customStyle={customStyles()}
                options={users}
                onChange={value => handleChangeOwner(value)}
              />
              {isError ? (
                <span className="create-company-franchise__content_container__field_error-value">
                  {formatMessage('This Field must not be empty')}
                </span>
              ) : (
                ''
              )}
            </div>
            <div className="create-company-franchise__content_container__field">
              <span className="create-company-franchise__content_container__field_label">
                {formatMessage('(Optional) Clone another company’s data:')}
              </span>
              <BcSelect
                outlined="none"
                color="white"
                placeholder={formatMessage('Choose company')}
                customStyle={customStyles()}
                options={companies}
                value={selectedCompany}
                onChange={value => selectCompany(value)}
              />
            </div>
            <div className="create-company-franchise__content_container__field">
              <span className="create-company-franchise__content_container__field_label">
                {formatMessage('Plan')}
              </span>
              <div className="create-company-franchise__content_container__field_radio-buttons">
                <RadioButtons
                  options={options}
                  onChange={e =>
                    setCompanyData({ ...companyData, plan_type: e.target.id })
                  }
                  errorText={formatMessage(companyDataErrors.plan_type)}
                />
              </div>
            </div>
            <div className="create-company-franchise__content_container__field">
              <span className="create-company-franchise__content_container__field_label">
                {formatMessage('Settings page functionality')}
              </span>
              <div className="create-company-franchise__content_container__field_checkbox">
                {permission.map(el => (
                  <div className="create-company-franchise__content_container__field_checkbox_item">
                    <CheckBox
                      checked={el.checked}
                      onChange={() => handleUpdateCheck(el)}
                    />
                    <span className="create-company-franchise__content_container__field_checkbox_item_text">
                      {formatMessage(el.label)}
                    </span>
                  </div>
                ))}
              </div>
            </div>
            <div className="create-company-franchise__content_container__button">
              <Button
                color="green"
                width="100%"
                height="56px"
                customClass="create-company-franchise__content_container__button_text"
                onClick={handleCreate}
              >
                {formatMessage('Create company')}
              </Button>
            </div>
          </div>
        </div>
      </div>
    </ModalContainer>
  );
};
