import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { NewBcField as Field } from '../../common/fields/newBcField/newBcField';
import FormField from '@wabb-ui-kit/FormField/FormField';
import { EComponentType } from '@wabb-ui-kit/FormField/types/types';
import Button from '@wabb-ui-kit/Button/Button';
import { EButtonSize } from '@wabb-ui-kit/Button/types/types';

import { ModalContainer } from '../../containers/Modal/ModalContainer';
import { MODALS } from '../../containers/Modal/modalConstants';
import { getModalDataSelector } from '../../containers/Modal/modalSelectors';
import { tagTypesActions } from '../../../redux/tagTypes/tagTypesActions';
import { getTagTypesSelector } from '../../../redux/tagTypes/tagTypesSelector';

import { useInternationalization } from '../../../hooks/useTranslationHook';

import './tagTypesModal.scss';

const styles = {
  width: '400px',
  overflow: 'visible',
};

export const TagTypesModal = () => {
  const { formatMessage } = useInternationalization();

  const dispatch = useDispatch();

  const { modalData } = useSelector(getModalDataSelector);
  const { tagTypesErrors } = useSelector(getTagTypesSelector);

  const { companyBot, handleSelectTagEvent } = modalData;

  const [inputs, setInputs] = useState({
    tagTypesName: '',
  });

  const { tagTypesName } = inputs;

  const [errorInput, setErrorInput] = useState('');

  useEffect(() => {
    if (tagTypesErrors) {
      setErrorInput(formatMessage(tagTypesErrors));
    }
  }, [tagTypesErrors]);

  const handleChange = e => {
    const { name, value } = e.target;
    setInputs(inputs => ({ ...inputs, [name]: value }));
  };

  const handleCheckName = e => {
    if (e.target.value.indexOf(' ') !== -1) {
      setErrorInput(formatMessage('Tag name must be a one word'));
    } else if (e.target.value.length > 20) {
      setErrorInput('Name must be no more than 20 symbols');
    } else if (e.target.value.indexOf(' ') === -1 && e.target.value.length <= 20) {
      setErrorInput('');
    }

    handleChange(e);
  };

  const handleSubmit = event => {
    event.preventDefault();

    if (tagTypesName === '') {
      setErrorInput(formatMessage('This field can`t be blank'));
    } else if (tagTypesName !== '' && errorInput === '') {
      dispatch(
        tagTypesActions.createTagTypesRequest({
          tagTypesName,
          companyBot,
          handleSelectTagEvent,
        }),
      );
    }
  };

  return (
    <ModalContainer
      modal={MODALS.tagTypes}
      onClose={modalData.onClose}
      customStyles={styles}
    >
      <div className="tag-types-modal">
        <div className="tag-types-modal__form">
        <h2 className="tag-types-modal__header">
          {formatMessage(modalData.action)} {formatMessage('tag')}
        </h2>
        <div className="tag-types-modal__input">
          <FormField
            name="tagTypesName"
            Component={EComponentType.Input}
            onChange={handleCheckName}
            value={tagTypesName}
            // onCrossClick={onCrossClick}
            label={formatMessage('New integration event')}
          />
          </div>
          <div className="tag-types-modal__buttons">
            <Button
              type="secondary"
              style="tint"
              onClick={modalData.onClose}
              size={EButtonSize.L}
            >
              Cancel
            </Button>
            <Button type="primary" onClick={handleSubmit} size={EButtonSize.L}>
              Save
            </Button>
          </div>
        </div>
      </div>
    </ModalContainer>
  );
};
