export const requestTypes = [
  {
    id: 0,
    bot: 0,
    name: 'POST',
  },
  {
    id: 1,
    bot: 1,
    name: 'GET',
  },
  {
    id: 2,
    bot: 2,
    name: 'PUT',
  },
  {
    id: 3,
    bot: 3,
    name: 'PATCH',
  },
  {
    id: 4,
    bot: 4,
    name: 'DELETE',
  },
];
