import axios from 'axios';
import { store } from '@redux/store';
import { authActions } from '@redux/auth/authActions';
import { VITE_API_URL } from '../app/config';

// old api implementation

// prev api
export const API_URL_V1 = `${VITE_API_URL}api/v1`;

const api = axios.create({
  baseURL: API_URL_V1,
});

export default api;

api.interceptors.response.use(
  response => response,
  error => {
    const originalRequest = error.config;
    let code = '';

    if (isProperty(error.response?.data, 'code')) {
      code = error.response.data.code;
    }

    if (
      error.response?.status === 401 &&
      !originalRequest._retry &&
      code === 'token_not_valid'
    ) {
      originalRequest._retry = true;
      const refresh = window.localStorage.getItem('refreshToken');

      if (!refresh) {
        store.dispatch(authActions.logOut());
      }

      return axios
        .post(`${API_URL_V1}/auth/jwt/refresh/`, { refresh })
        .then(({ data }) => {
          localStorage.setItem('authToken', data.access);
          api.defaults.headers.common.Authorization = `Bearer ${data.access}`;
          originalRequest.headers.Authorization = `Bearer ${data.access}`;
          return axios(originalRequest);
        })
        .catch(error => {
          const code = isProperty(error.response.data, 'code')
            ? error.response.data.code
            : '';

          if (error.response.status === 401 && code === 'token_not_valid') {
            store.dispatch(authActions.logOut());
          }
        });
    }

    return Promise.reject(error);
  },
);

const isProperty = (data, property) => data?.hasOwnProperty(property);
