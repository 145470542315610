import {
  OPEN_NOTIFICATIONS_CONNECTION_SUCCESS,
  CLOSE_NOTIFICATIONS_CONNECTION_SUCCESS,
  SET_START_NOTIFICATION_VALUES,
  CHANGE_CONNECTION_STATUS,
  CHANGE_COUNT_OF_TRIES,
  CHANGE_QR_SCAN,
  CHANGE_BATTERY_LEVEL,
  CHANGE_DEVICE_INFO,
  CHANGE_UNREAD_CHATS,
  CHANGE_CONNECTED_PHONE,
  CHANGE_SYNC_PROCESS_STATUS,
  GET_NOTIFICATION_CONNECTION_STATUS_SUCCESS,
} from './notificationsConstants';

const initialState = {
  chats: [],
  notificationsSocket: null,
  batteryLevel: 0,
  connectionStatus: 0,
  notificationStatus: 0,
  countOfTries: 0,
  countOfUnreadChats: 0,
  logoutInNextIter: false,
  qr: null,
  connectedPhone: '',
  connectedDevice: '',
  isSyncProcess: null,
};

export const notificationsReducer = (state = initialState, action) => {
  switch (action.type) {
    case OPEN_NOTIFICATIONS_CONNECTION_SUCCESS:
      return {
        ...state,
        notificationsSocket: action.payload,
      };

    //Close notificationsSocket connection
    case CLOSE_NOTIFICATIONS_CONNECTION_SUCCESS:
      return {
        ...state,
        notificationsSocket: null,
        batteryLevel: 0,
        connectionStatus: 0,
        notificationStatus: 0,
        countOfTries: 0,
        countOfUnreadChats: 0,
        logoutInNextIter: false,
        qr: null,
        connectedPhone: '',
        connectedDevice: '',
      };

    case CHANGE_CONNECTED_PHONE:
      return {
        ...state,
        connectedPhone: action.payload.connectedPhone,
      };

    case SET_START_NOTIFICATION_VALUES:
      return {
        ...state,
        batteryLevel: action.payload.batteryLevel,
        connectionStatus: action.payload.connectionStatus,
        countOfTries: action.payload.countOfTries,
        countOfUnreadChats: action.payload.countOfUnreadChats,
        logoutInNextIter: action.payload.logoutInNextIter,
        qr: action.payload.qr,
        connectedPhone: action.payload.connectedPhone,
        connectedDevice: action.payload.connectedDevice,
        isSyncProcess: action.payload.isSyncProcess,
      };

    case GET_NOTIFICATION_CONNECTION_STATUS_SUCCESS:
      return {
        ...state,
        notificationStatus: action.payload.status,
      };

    case CHANGE_SYNC_PROCESS_STATUS:
      return {
        ...state,
        isSyncProcess: action.payload.isSyncProcess,
      };
    case CHANGE_CONNECTION_STATUS:
      return {
        ...state,
        connectionStatus: action.payload.connectionStatus,
      };

    case CHANGE_COUNT_OF_TRIES:
      return {
        ...state,
        countOfTries: action.payload.countOfTries,
      };

    case CHANGE_QR_SCAN: {
      return {
        ...state,
        qr: action.payload.qr,
      };
    }

    case CHANGE_BATTERY_LEVEL: {
      return {
        ...state,
        batteryLevel: action.payload.batteryLevel,
      };
    }

    //Change scaned qr device info
    case CHANGE_DEVICE_INFO: {
      return {
        ...state,
        connectedPhone: action.payload.connectedPhone,
        connectedDevice: action.payload.connectedDevice,
      };
    }

    case CHANGE_UNREAD_CHATS: {
      return {
        ...state,
        countOfUnreadChats: action.payload.countOfUnreadChats,
      };
    }

    default:
      return state;
  }
};
