import React, { useEffect, useRef } from 'react';
import { useContextSelector } from 'use-context-selector';
import { useInternationalization } from '@hooks/useTranslationHook';

import {
  DoneToastIcon,
  ErrorToastIcon,
  WarningToastIcon,
  InfoToastIcon,
  CloseToastIcon,
} from '@icons/toasts';
import { ToastContext } from '../../../contexts/toastsContext';
// import { ProgressComponent } from '@components/common/ProgressComponent/ProgressComponent';

import './BcToast.scss';

export const BcToast = props => {
  const { formatMessage } = useInternationalization();
  const icons = {
    success: <DoneToastIcon />,
    error: <ErrorToastIcon />,
    warning: <WarningToastIcon />,
    info: <InfoToastIcon />,
  };

  const {
    type,
    title,
    message,
    // functionToGetProgress, intervalToUpdateProgress = 3000,
    id,
  } = props;

  // const [toastProgress, setToastProgress] = useState(0);

  const wrapperRef = useRef(null);
  const remove = useContextSelector(ToastContext, ({ remove }) => remove);

  const dismissRef = useRef();

  // let progressInterval;

  // useEffect(() => {
  //   if (toastProgress === 100) {
  //     remove(id, wrapperRef);
  //     clearInterval(progressInterval);
  //   }
  // }, [toastProgress]);

  useEffect(() => {
    if (type === 'progress') {
      // progressInterval = setInterval(() => {
      //   setToastProgress(functionToGetProgress());
      // }, intervalToUpdateProgress);
    } else {
      dismissRef.current = setTimeout(() => {
        remove(id, wrapperRef);
      }, 6000);

      return () => {
        clearTimeout(dismissRef.current);
      };
    }
  }, []);

  return (
    <div className="bc-toast animate-toastIn" id={id} ref={wrapperRef} role="alert">
      <div className="bc-toast__body">
        {/*{type === 'progress' ? (*/}
        {/*  <ProgressComponent diameter={30} progress={toastProgress} />*/}
        {/*) : (*/}
        <div className="bc-toast__body_icon">{icons[type]}</div>
        {/*)}*/}
        <div className="bc-toast__body_text">
          <div className="bc-toast__body_text_title">{formatMessage(title)}</div>
          {message && (
            <div className="bc-toast__body_text_message">
              {typeof message === 'string'
                ? formatMessage(message)
                : Array.isArray(message)
                ? formatMessage(...message)
                : message}
            </div>
          )}
        </div>
      </div>
      <div
        className="bc-toast__close"
        aria-label="Close"
        onClick={() => {
          remove(id, wrapperRef);
        }}
      >
        <CloseToastIcon />
      </div>
    </div>
  );
};
