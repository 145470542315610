import React, { forwardRef, memo } from 'react';
import { FloatingFocusManager, FloatingPortal } from '@floating-ui/react';

import './Floating.styles.scss';

const Floating = (
  {
    isOpen,
    position,
    zIndex,
    getFloatingProps,
    isMulti,
    children,
    size,
    disabled = false,
    visible,
    style,
    ...floatingFocusManagerProps
  },
  ref
) => {
  const handleBackgroundClick = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  return (isOpen ? (
    <>
      <div onClick={handleBackgroundClick} className="dropdown-background" />
      <FloatingPortal>
        <FloatingFocusManager {...floatingFocusManagerProps} disabled={disabled}>
          <div
            ref={ref}
            style={{
              ...position,
              ...{ zIndex },
              ...(size
                ? {
                  width: size.width,
                  maxWidth: size.maxWidth,
                  maxHeight: size.maxHeight,
                  height: size.height,
                }
                : {}),
              overflow: visible,
              ...(style || {}),
            }}
            {...getFloatingProps({
              className: 'floating-root',
              ...(isMulti && {
                'aria-multiselectable': true,
              }),
            })}
          >
            {children}
          </div>
        </FloatingFocusManager>
      </FloatingPortal>
    </>
  ) : null);
};

const memoized = memo(forwardRef(Floating));

export { memoized as Floating };
