import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useFormik } from 'formik';
import {  ConnectError, Code } from '@connectrpc/connect';

import * as Yup from 'yup';
import { useInternationalization } from '@hooks/useTranslationHook';
import { DateTimePopover } from '@pages/audience/components/audienceFilters/filtersPopover/components/dateTimePopover/dateTimePopover';
import { EComponentType } from '@wabb-ui-kit/FormField/types/types';
import FormField from '@wabb-ui-kit/FormField/FormField.tsx';
import Button from '@wabb-ui-kit/Button/Button';
import { EButtonSize } from '@wabb-ui-kit/Button/types/types';
import Dropdown from '@wabb-ui-kit/Dropdown/Dropdown';
import { getBotIdSelectedCompanySelector } from '@redux/companies/companiesSelectors';
import { bcSplitTableActions } from '@redux/bcSplitTable/bcSplitTableActions';
import { variablesApi } from '@api/variablesApi';
import { AudienceFiltersMenuArrowIcon } from '@icons/audience/audience-filters-menu-arrow-icon';
import { getModalDataSelector } from '../../containers/Modal/modalSelectors';
import { ModalContainer } from '../../containers/Modal/ModalContainer';
import { MODALS } from '../../containers/Modal/modalConstants';
import { CustomFieldsTypes, systemFields } from './const';
import { transformFieldsData } from './utils';

import './CustomFieldsModal.scss';

const styles = {
  minHeight: '420px',
};

export const CustomFieldsModal = () => {
  const { formatMessage } = useInternationalization();
  const dispatch = useDispatch();
  const { modalData } = useSelector(getModalDataSelector);
  const { typeField, onClose } = modalData;
  const companyBot = useSelector(getBotIdSelectedCompanySelector);
  const [isLoading, setLoading] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const customFieldsTypes = CustomFieldsTypes.map(({ id, bot, name }) => ({
    id,
    name: formatMessage(name),
    valueLabel: name,
  }));
  const history = useHistory();

  const folderId = history.location.pathname.split('/')[5]
    ? history.location.pathname.split('/')[5]
    : '';

  //TODO add validation for custom field value
  const validation = Yup.object().shape({
    name: Yup.string()
      .max(30, formatMessage('name must be less than 30 characters'))
      .required(formatMessage('This field can`t be blank'))
      .test(
        'forbiddenNames',
        formatMessage('Variable with this name cannot be created'),
        value => !systemFields.includes(value),
      ),
  });

  const createNewField = async formData => {
    try {
      const  data  = await variablesApi.createVariable(formData);
      return data;
    } catch (e) {
      const connectErr = ConnectError.from(e);

      switch (connectErr.code === Code.InvalidArgument) {
        case 400:
          form.setFieldError(
            'name',
            formatMessage(
              typeField === 'bot'
                ? 'This bot field already exists'
                : 'This user field already exists',
            ),
          );
          break;
        default:
          form.setFieldError('name', '');
      }

      return null;
    }
  };

  const form = useFormik({
    initialValues: {
      name: '',
      fieldType: customFieldsTypes[0],
      customFieldValue: '',
      description: '',
    },
    validationSchema: validation,
    onSubmit: async values => {
      try {
        setLoading(true);
        const data = await createNewField({
          customFieldName: values.name,
          companyBot,
          fieldType: typeField,
          description: values.description,
          customFieldValue: values.customFieldValue,
          activeType: values.fieldType.id,
          folder: folderId ? +folderId : null,
        });
        dispatch(
          bcSplitTableActions.createNewItemSuccess(
            transformFieldsData(data, formatMessage),
          ),
        );

        setLoading(false);
      } finally {
        onClose();
      }
    },
  });

  const handleChangeNumberField = e => {
    // old one if (/^\d+$/.test(e.target.value) || e.target.value === '') {
    if (/^([0-9]{1,})?(\.)?([0-9]{1,})?$/.test(e.target.value) || e.target.value === '') {
      form.handleChange(e);
    }
  };

  const getValueField = fieldType => {
    switch (fieldType) {
      case 0:
        return (
          <div>
            <FormField
              label={formatMessage('Value')}
              autoComplete="off"
              value={form.values.value}
              onChange={form.handleChange}
              onCrossClick={() => form.setFieldValue('value', '')}
              name="value"
              placeholder="Type here..."
            />
          </div>
        );
      case 1:
        return (
          <div>
            <FormField
              label={formatMessage('Value')}
              value={form.values.customFieldValue}
              autoComplete="off"
              errorText={form.errors?.customFieldValue}
              onCrossClick={() => form.setFieldValue('customFieldValue', '')}
              onChange={handleChangeNumberField}
              name="customFieldValue"
              placeholder="Type here..."
            />
          </div>
        );
      case 2:
        return (
          <div className="fields">
            <div className="fields__title">{formatMessage('Value')}</div>
            <div
              className={`${
                anchorEl ? 'fields__filled-input__open' : 'fields__filled-input'
              }`}
              onClick={event => setAnchorEl(event.currentTarget)}
            >
              {form.values.customFieldValue || (
                <span className="fields__filled-input__placeholder">Select value</span>
              )}
              <div className={`fields-arrow ${anchorEl ? 'open-item' : ''}`}>
                <AudienceFiltersMenuArrowIcon />
              </div>
            </div>
            <DateTimePopover
              id="bot_fields"
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
              }}
              open={Boolean(anchorEl)}
              onClose={() => {
                setAnchorEl(null);
              }}
              dateTime={false}
              format="YYYY-MM-DD"
              changeDate={value => form.setFieldValue('customFieldValue', value)}
            />
          </div>
        );
      case 3:
        return (
          <div className="fields">
            <div className="fields__title">{formatMessage('Value')}</div>
            <div
              className={`${
                anchorEl ? 'fields__filled-input__open' : 'fields__filled-input'
              }`}
              onClick={event => setAnchorEl(event.currentTarget)}
            >
              {form.values.customFieldValue || (
                <span className="fields__filled-input__placeholder">Select value</span>
              )}
              <div className={`fields-arrow ${anchorEl ? 'open-item' : ''}`}>
                <AudienceFiltersMenuArrowIcon />
              </div>
            </div>
            <DateTimePopover
              id="bot_fields"
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
              }}
              open={Boolean(anchorEl)}
              onClose={() => {
                setAnchorEl(null);
              }}
              dateTime
              format="YYYY-MM-DD HH:mm"
              changeDate={value => form.setFieldValue('customFieldValue', value)}
            />
          </div>
        );
      default:
        return (
          <FormField
            label={formatMessage('Value')}
            value={form.values.customFieldValue}
            onChange={form.handleChange}
            name="customFieldValue"
            placeholder="Type here..."
            errorText={form.errors?.customFieldValue}
          />
        );
    }
  };

  const handleSelectItem = value => {
    form.setFieldValue('fieldType', value, false);
    form.setFieldValue('customFieldValue', '', false);
  };

  return (
    <ModalContainer
      modal={MODALS.customFields}
      onClose={modalData.onClose}
      customStyles={styles}
    >
      <div className="custom-fields-modal">
        <span className="custom-fields-modal__header">
          {formatMessage(`Create ${typeField} field`)}
        </span>
        <div className="custom-fields-modal__content">
          <div className="form">
            <div>
              <FormField
                label={formatMessage('Name')}
                value={form.values.name}
                name="name"
                isRequired
                autoComplete="off"
                placeholder="Type here..."
                errorText={form.touched.name && form.errors.name}
                onCrossClick={() => form.setFieldValue('name', '')}
                onBlur={() => form.setFieldTouched('name', true)}
                onChange={form.handleChange}
              />
            </div>
            <div>
              <label className="custom-fields-modal__select__label">
                Type
                <span className="custom-fields-modal__select__label-required">*</span>
              </label>
              <Dropdown
                options={CustomFieldsTypes}
                isRequired
                label="label"
                idName="value"
                singleSelectedOption={form.values.fieldType}
                setSingleSelectedOption={handleSelectItem}
              />
            </div>

            {typeField === 'bot' && getValueField(form?.values?.fieldType.id)}
            <div className="form__description">
              <div className="block">
                <FormField
                  Component={EComponentType.TextArea}
                  label={formatMessage('Description')}
                  maxLength={500}
                  height={124}
                  value={form.values.description}
                  placeholder="Type here..."
                  onCrossClick={() => form.setFieldValue('description', '')}
                  onChange={e => form.setFieldValue('description', e.target.value)}
                />
              </div>
            </div>
            <div className="custom-fields-modal__buttons-wrapper">
              <Button
                size={EButtonSize.L}
                isDisabled={
                  !form.values.name || !form.values.fieldType
                }
                isLoading={isLoading}
                onClick={form.handleSubmit}
              >
                {formatMessage('Create field')}
              </Button>
              <Button
                type="secondary"
                style="tint"
                size={EButtonSize.L}
                onClick={modalData.onClose}
              >
                {formatMessage('Cancel')}
              </Button>
            </div>
          </div>
        </div>
      </div>
    </ModalContainer>
  );
};
