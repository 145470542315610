import React, { useState } from 'react';
import FormField from '@wabb-ui-kit/FormField/FormField.tsx';
import { useDispatch, useSelector } from 'react-redux';
import Button from '@wabb-ui-kit/Button/Button';
import { EButtonSize } from '@wabb-ui-kit/Button/types/types';
import { getModalDataSelector } from '@components/containers/Modal/modalSelectors';
import { useInternationalization } from '@hooks/useTranslationHook';
import { foldersApi } from '@api';
import { ModalContainer } from '../../containers/Modal/ModalContainer';
import { MODALS } from '../../containers/Modal/modalConstants';
import { flowsApi } from '@api/flowsApi';
import { bcSplitTableActions } from '../../../redux/bcSplitTable/bcSplitTableActions.js';

import './editFlowAndFolderModal.scss';

export const EditFlowAndFolderModal = () => {
  const dispatch = useDispatch();
  const { modalData } = useSelector(getModalDataSelector);
  const [name, setName] = useState(modalData.item.name);
  const { formatMessage } = useInternationalization();

  const handleUpdateFolderName = name => {
    foldersApi
      .renameFolder(modalData.item.id, name)
      .then(res => {
        setName('');
        modalData.setFlowName(res.name);
        modalData.onClose();
        modalData.actionWithFolders(modalData.item.id, 'Update', {
          ...modalData.item,
          ...res,
        });
      })
      .catch(error => {
        console.error('API call failed:', error);
      });
  };

  const handleUpdateFlowName = name => {
    flowsApi
      .renameFlowsSource(modalData.item.id, name)
      .then(res => {
        setName('');
        dispatch(bcSplitTableActions.updateItemInSplitTable(res));
        modalData.onClose();
      })
      .catch(error => {
        console.error('API call failed:', error);
      });
  };

  return (
    <ModalContainer modal={MODALS.editFlowAndFolderModal} onClose={modalData.onClose}>
      <div className="edit-folder-modal__container">
        <div className="edit-folder-modal__title"> Edit</div>
        <div className="edit-folder-modal__input">
          <FormField
            onChange={e => setName(e.target.value)}
            label={'Name'}
            isRequired
            value={name}
            onCrossClick={() => setName('')}
            placeholder="Type here..."
          />
        </div>
        <div className="edit-folder-modal__btns">
          <Button
            size={EButtonSize.L}
            isDisabled={name.length === 0}
            onClick={() =>
              modalData.type === 'folder'
                ? handleUpdateFolderName(name)
                : handleUpdateFlowName(name)
            }
          >
            {formatMessage('Save')}
          </Button>
          <Button
            type="secondary"
            style="tint"
            size={EButtonSize.L}
            onClick={modalData.onClose}
          >
            {formatMessage('Cancel')}
          </Button>
        </div>
      </div>
    </ModalContainer>
  );
};
