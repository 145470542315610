import { customUserApiRPC } from '../connectApi/baseApi.js';
import { UserIdRequest } from 'wabb-backend-api/build/es/custom_user/custom_user_pb.js';

export const twoFactorAuthenticationApi = {
  sendSMS: data => {
    return customUserApiRPC.sendMFACode(UserIdRequest.fromJson(data));
  },

  sendConfirmCode: data => customUserApiRPC.confirmMFAView(UserIdRequest.fromJson(data)),
};
