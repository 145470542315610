import moment from 'moment';
import { CustomFieldsTypes } from './const';

export const transformFieldsData = customField => {
  const {
    id,
    key,
    description,
    type,
    value_string,
    value_float,
    date_value,
    date_time_value,
  } = customField;
  let value = '';

  switch (type) {
    case 0:
      value = value_string;
      break;
    case 1:
      value = value_float;
      break;
    case 2:
      value = date_value ? moment(date_value).format('YYYY-MM-DD') : '';
      break;
    case 3:
      value = date_time_value ? moment(date_time_value).format('YYYY-MM-DD HH:mm') : '';
      break;
    default:
      value = '';
  }

  return {
    id,
    name: key,
    description,
    value,
    type: CustomFieldsTypes[type].label,
  };
};
