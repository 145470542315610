import React from 'react';

export const DateTimeVariableIcon = ({ color = '#1187F4' }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="currentColor"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M2 3.8335C2 3.28121 2.44772 2.8335 3 2.8335H13C13.5523 2.8335 14 3.28121 14 3.8335V10.6668C14 11.2191 14 14.1668 10.3333 14.1668H3C2.44772 14.1668 2 13.7191 2 13.1668V3.8335ZM13 3.8335H3V13.1668H8.66667L10.6667 14.1583L14 13.0002V10.0002L13 9.00016V3.8335Z"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M11 1.8335C11.2761 1.8335 11.5 2.05735 11.5 2.3335V4.3335C11.5 4.60964 11.2761 4.8335 11 4.8335C10.7239 4.8335 10.5 4.60964 10.5 4.3335V2.3335C10.5 2.05735 10.7239 1.8335 11 1.8335Z"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M5 1.8335C5.27614 1.8335 5.5 2.05735 5.5 2.3335V4.3335C5.5 4.60964 5.27614 4.8335 5 4.8335C4.72386 4.8335 4.5 4.60964 4.5 4.3335V2.3335C4.5 2.05735 4.72386 1.8335 5 1.8335Z"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M2 6.3335C2 6.05735 2.22386 5.8335 2.5 5.8335H13.5C13.7761 5.8335 14 6.05735 14 6.3335C14 6.60964 13.7761 6.8335 13.5 6.8335H2.5C2.22386 6.8335 2 6.60964 2 6.3335Z"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M11 8.54778C9.55354 8.54778 8.38095 9.72037 8.38095 11.1668C8.38095 12.6133 9.55354 13.7859 11 13.7859C12.4465 13.7859 13.619 12.6133 13.619 11.1668C13.619 9.72037 12.4465 8.54778 11 8.54778ZM7.33333 11.1668C7.33333 9.14179 8.97496 7.50016 11 7.50016C13.025 7.50016 14.6667 9.14179 14.6667 11.1668C14.6667 13.1919 13.025 14.8335 11 14.8335C8.97496 14.8335 7.33333 13.1919 7.33333 11.1668Z"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M10.6508 9.5954C10.9401 9.5954 11.1746 9.82992 11.1746 10.1192V10.9922H12.0476C12.3369 10.9922 12.5714 11.2267 12.5714 11.516C12.5714 11.8053 12.3369 12.0398 12.0476 12.0398H10.6508C10.3615 12.0398 10.127 11.8053 10.127 11.516V10.1192C10.127 9.82992 10.3615 9.5954 10.6508 9.5954Z"
    />
  </svg>
);
