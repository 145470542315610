import React from 'react';
import { Link } from 'react-router-dom';

import { useInternationalization } from '@hooks/useTranslationHook';

import './NotFound.scss';

export const NotFoundPage = () => {
  const { formatMessage } = useInternationalization();
  return (
    <div className="not-found">
      <h1>404</h1>
      <h2>{formatMessage('Page not found')}</h2>
      <h3>
        {formatMessage(
          "The Page you are looking for doesn't exist or an other error occured",
        )}
        .{' '}
        <Link className="not-found-link" to="/">
          {formatMessage('Go to Home Page')}
        </Link>
        .
      </h3>
    </div>
  );
};
