import React from 'react';
import moment from 'moment/moment';

import './FlowItem.scss';

import { CheckBox } from '@components/common';

export const FlowItem = ({
  item,
  deleteItemFunction,
  folders,
  children,
  itemData,
  ...props
}) => {
  const { name, runs, ctr, last_update } = item;
  const { selectedItem, setSelectedItem } = itemData;

  const dateFormat = data => moment(data).format('DD/MM/YYYY');

  return (
    <div {...props}>
      <div className="choice-flow-item__name">{name}</div>
      <div className="choice-flow-item__cell">{runs || '-'}</div>
      <div className="choice-flow-item__cell">{ctr || '-'}</div>
      <div className="choice-flow-item__date">{dateFormat(last_update)}</div>
      <div className="choice-flow-item__cell">
        <CheckBox
          customClass="choice-checkbox"
          checked={item.id === selectedItem?.id}
          onChange={() => setSelectedItem(item)}
        />
      </div>
    </div>
  );
};
