import React from 'react';

import { MODALS } from '@components/containers/Modal/modalConstants';
import { ModalContainer } from '@components/containers/Modal/ModalContainer';
import { useSelector } from 'react-redux';
import { getModalDataSelector } from '@components/containers/Modal/modalSelectors';
import { useInternationalization } from '@hooks/useTranslationHook';

import './deleteSupportEmailModal.scss';
import { Button } from '@components/common/Button';
import { FranchiseCompanyApi } from '@api/franchiseCompanyApi';

export const DeleteSupportEmailModal = () => {
  const { modalData } = useSelector(getModalDataSelector);
  const { formatMessage } = useInternationalization();

  const updateObj = new FormData();

  const onClose = () => {
    modalData.onClose();
  };

  const handleDeleteSupportEmail = () => {
    updateObj.append('support_email', '');
    FranchiseCompanyApi.setFranchiseSettings(modalData.id, updateObj).then(res =>
      modalData.setSupEmail(res.data.support_email),
    );
    onClose();
  };

  return (
    <ModalContainer modal={MODALS.deleteSupportEmail} onClose={onClose}>
      <div className="delete-email-support-container">
        <div className="delete-email-support-container__title">
          {formatMessage('Delete Support email')}
        </div>
        <div className="delete-email-support-container__subtitle">
          {formatMessage('Are you sure you want to delete Support email?')}
        </div>
        <div className="delete-email-support-container__button-block">
          <Button
            color="red"
            width="100%"
            customClass="delete-email-support-container__button-block_button"
            onClick={handleDeleteSupportEmail}
          >
            {formatMessage('Yes, Delete')}
          </Button>
          <Button
            color="gray"
            width="100%"
            customClass="delete-email-support-container__button-block_button-cancel"
            onClick={onClose}
          >
            {formatMessage('Cancel')}
          </Button>
        </div>
      </div>
    </ModalContainer>
  );
};
