import React from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { useInternationalization } from '@hooks/useTranslationHook';
import { ModalContainer } from '../../containers/Modal/ModalContainer';
import { MODALS } from '../../containers/Modal/modalConstants';
import { getModalDataSelector } from '../../containers/Modal/modalSelectors';
import { modalActions } from '../../containers/Modal/modalActions';

import '../leaveManagerModal/leaveManagerModal.scss';
import { Button } from '@components/common/Button';

export const DeleteCompanyModal = () => {
  const { formatMessage } = useInternationalization();

  const dispatch = useDispatch();

  const { modalData } = useSelector(getModalDataSelector);

  return (
    <ModalContainer modal={MODALS.deleteCompany} onClose={modalData.onClose}>
      <div className="leave-manager-container">
        <div className="leave-manager-container__header">
          <span className="leave-manager-container__header__text">
            {formatMessage('Delete Company')}
          </span>
        </div>
        <div className="leave-manager-container__text">
          {formatMessage('All user data will be deleted. Do you really want delete ')}
          <a className="leave-manager-container__text__company-name">
            {modalData.companiesName}
          </a>
          {formatMessage(' company?')}
        </div>
        <div className="leave-manager-container__red-button">
          <Button color="red" height="56px">
            <div className="leave-manager-container__red-button__text">
              {formatMessage('Yes, Delete')}
            </div>
          </Button>
        </div>
        <Button
          className="leave-manager-container__button"
          onClick={() => dispatch(modalActions.closeModal())}
          height="56px"
        >
          <span className="leave-manager-container__button__text">
            {formatMessage('Cancel')}
          </span>
        </Button>
      </div>
    </ModalContainer>
  );
};
