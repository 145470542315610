export type TButtonType =
  | 'primary'
  | 'secondary'
  | 'negative'
  | 'google'
  | 'facebook'
  | 'apple'
  | 'twitter';

export type TButtonStyle =
  | 'filled'
  | 'outlined'
  | 'dashed'
  | 'tint'
  | 'ghost'
  | 'link'
  | 'google-dark'
  | 'facebook-light'
  | 'facebook-dark'
  | 'apple-dark';

export enum EIconPosition {
  LEFT = 'left',
  RIGHT = 'right',
}

export enum EButtonSize {
  S = 'size_s',
  M = 'size_m',
  L = 'size_l',
  XL = 'size_xl',
}
