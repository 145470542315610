import { all, put, takeLatest } from 'redux-saga/effects';

import { companiesApi } from '@api/companiesApi';
import { FranchiseCompanyApi } from '@api/franchiseCompanyApi';
import { companiesActions } from './companiesActions';
import { GET_COMPANIES_REQUEST } from './companiesConstants';
import { loaderActions } from '../loader/loaderActions';
import { authActions } from '../auth/authActions';
import { Routes } from '../../const/routes';

function* getCompaniesRequestSaga(data) {
  const { onlyActiveCompanies, frCode, isFranchiseOwner, isEmailRegistrationInProgress } =
    data.payload;

  try {
    if (frCode && isFranchiseOwner) {
      const { data } = yield FranchiseCompanyApi.getFranchiseManagers(frCode);
      yield put(authActions.setFranchisePermissionsData(data));
    }

    const response = yield companiesApi.getCompanies(onlyActiveCompanies, frCode);

    if (response?.companies.length) {
      yield put(companiesActions.getCompaniesSuccess(response.companies));
      yield put(loaderActions.loaderOff());
    } else if (
      (!isFranchiseOwner && !frCode && !isEmailRegistrationInProgress) ||
      (!isFranchiseOwner && frCode && !isEmailRegistrationInProgress)
    ) {
      yield put(authActions.redirectToNextPage(Routes.AddYourCompanyRoute));
      yield put(loaderActions.loaderOff());
    } else if (isFranchiseOwner) {
      yield put(authActions.redirectToNextPage(Routes.FranchiseRoute));
      yield put(loaderActions.loaderOff());
    }
  } catch (err) {
    yield put(loaderActions.loaderOff());
  }
}

function* sagas() {
  yield all([takeLatest(GET_COMPANIES_REQUEST, getCompaniesRequestSaga)]);
}

export const companiesSagas = sagas;
