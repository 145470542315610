import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import Modal from 'react-modal';
import { CloseIcon } from '@icons/header/close-icon'; // Импортируем CloseIcon

import './modal.scss';
import { isModalOpenSelector } from './modalSelectors';

const baseContentStyles = {
  top: '50%',
  left: '50%',
  right: 'auto',
  bottom: 'auto',
  padding: '20px 16px',
  transform: 'translate(-50%, -50%)',
  border: 'none',
  background: 'none',
  width: 'auto',
  display: 'flex',
  maxHeight: '90%',
  height: 'auto',
  overflow: 'visible',
};

export const ModalContainer = (props) => {
  const {
    children, onClose, customStyles, modal, overlayStyles,
  } = props;

  const closeModalCallback = useCallback(() => {
    if (onClose) onClose();
  }, [onClose]);

  const styles = useMemo(
    () => ({
      content: {
        ...baseContentStyles,
        ...customStyles,
      },
      overlay: {
        ...overlayStyles,
      },
    }),
    [customStyles, overlayStyles],
  );

  const isOpen = useSelector((state) => isModalOpenSelector(state, modal));

  if (!isOpen) return null;

  const applyStylesToChildren = (children) => React.Children.map(children, (child) => {
    if (React.isValidElement(child)) {
      return React.cloneElement(child);
    }

    return child;
  });

  return (
    <Modal
      isOpen
      onRequestClose={closeModalCallback}
      style={styles}
      contentLabel="Example ModalContainer"
    >
      <div className="modal-children-wrapper">
        <button className="modal-close-button" onClick={closeModalCallback}>
          <CloseIcon width="16px" height="16px" fill="#000" />
        </button>
        {applyStylesToChildren(children)}
      </div>
    </Modal>
  );
};

ModalContainer.propTypes = {
  modal: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  onClose: PropTypes.func,
};
ModalContainer.defaultProps = {
  customStyles: {},
};
