import {
  PostCompanyManagerRequest,
  GetCompanyManagersRequest,
  UpdateCompanyDetailRequest,
  GetCompanyDetailRequest,
} from 'wabb-backend-api/build/es/company/company_pb';
import {
  UsersMeSetPasswordRequest,
  UsersResetPasswordConfirmationRequest,
  UsersResetPasswordRequest,
} from 'wabb-backend-api/build/es/user/user_pb.js';
import { ManagerDetailRequest } from 'wabb-backend-api/build/es/manager/manager_pb';
import api from './baseApi';
import {
  companyApi,
  managerApi,
  botApiRPC,
  userApiRPC,
  customUserApiRPC,
} from '../connectApi/baseApi.js';
import { VITE_API_URL } from '../app/config';

export const usersApi = {
  getActiveUsers: ({ botId, searchName }) =>
    companyApi.getCompanyManagers(
      GetCompanyManagersRequest.fromJson({ pk: botId, searchName }),
    ),

  getUsersMe: () => userApiRPC.usersMeGet(),

  updateUserPassword: updatePasswordObj =>
    userApiRPC.usersMeSetPassword(UsersMeSetPasswordRequest.fromJson(updatePasswordObj)),

  updateActiveUsers: updateObjs =>
    companyApi.postCompanyManagers(PostCompanyManagerRequest.fromJson(updateObjs)),

  updateActiveUser: updateObjs =>
    managerApi.managerView(ManagerDetailRequest.fromJson(updateObjs)),

  getLiveChatCheckBoxPermission: botId => botApiRPC.getBot({ pk: botId }),

  deleteActiveUser: manager_id => managerApi.managerViewDelete({ pk: manager_id }),

  updateUsers: (data, pk) => {
    return api.patch(`${VITE_API_URL}uploads/companies/${pk}/`, data, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  },

  getUser: pk =>
    companyApi.getCompanyDetail(GetCompanyDetailRequest.fromJson({ pk: pk })),

  activateUsers: (uId, token) => api.post('/users/activation/', { uid: uId, token }),

  resetUserPassword: email =>
    userApiRPC.usersResetPassword(UsersResetPasswordRequest.fromJson({ email })),

  resetUserPasswordConfirm: data =>
    userApiRPC.usersResetPasswordConfirmation(
      UsersResetPasswordConfirmationRequest.fromJson(data),
    ),

  updateUserInfo: data => {
    return api.patch(`${VITE_API_URL}uploads/users/me/`, data, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  },

  addMfa: () => customUserApiRPC.addMFAToUserView(),

  removeMfa: () => customUserApiRPC.removeMFAFromUserView(),
};
