import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import en from './en.json';
import { VITE_APP_LANGUAGE_TYPE } from '../app/config';

const languageByAppType = {
  ENGLAND: 'en',
};

const languageByLocalStorage = {
  English: 'en',
};

const defaultLanguage = languageByAppType[VITE_APP_LANGUAGE_TYPE];

i18n.use(initReactI18next).init({
  debug: false,
  fallbackLng:
    localStorage.getItem('language') !== null
      ? languageByLocalStorage[localStorage.getItem('language')]
      : 'en',
  interpolation: {
    escapeValue: false,
  },
  resources: {
    en: {
      translation: {
        ...en,
      },
    },
  },
});

export default i18n;
