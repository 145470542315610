import React from 'react';

import { useInternationalization } from '@hooks/useTranslationHook';
import PhoneInput from 'react-phone-input-2';

import './BcPhoneInput.scss';

export const BcPhoneInput = (props) => {
  const { formatMessage } = useInternationalization();
  const inputClassName = props.helperText ? 'phone-input-error' : 'phone-input';

  return (
    <>
      <div className="phone-input-container">
        <div className="title">
          {props.title}
          {props.isRequired && <span className="required">*</span>}
        </div>
        <PhoneInput
          country="in"
          {...props}
          enableLongNumbers={11}
          containerClass={inputClassName}
        />
      </div>
      {props.helperText && (
        <span className="error-value">{formatMessage(props.helperText)}</span>
      )}
    </>
  );
};

//***please check react-phone-input-2 doc for props
