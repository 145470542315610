import React, { ReactNode } from 'react';
import { clsx } from 'clsx';
import { renderChildren } from '@wabb-ui-kit/Button/utils';
import {
  TButtonType,
  TButtonStyle,
  EIconPosition,
  EButtonSize,
} from '@wabb-ui-kit/Button/types/types';
import styles from './Button.module.scss';

export interface IButtonProps {
  className?: string;
  type: TButtonType;
  style?: TButtonStyle;
  iconPosition?: EIconPosition;
  size?: EButtonSize;
  isDisabled?: boolean;
  children?: ReactNode;
  icon?: ReactNode;
  onClick?: () => void;
  isLoading?: boolean;
  width?: string;
  height?: string;
}

const Button = ({
  className,
  children,
  iconPosition,
  type = 'primary',
  style = 'filled',
  onClick,
  icon,
  size = EButtonSize.M,
  isLoading = false,
  isDisabled,
  width,
  height,
  ...otherProps
}: IButtonProps) => {
  const buttonClasses = clsx(
    styles.button,
    styles[type],
    styles[style],
    styles[size],
    className,
    {
      [styles.isDisabled]: isDisabled,
      [styles.isLoading]: isLoading,
      [styles['icon-left']]: iconPosition === EIconPosition.LEFT,
      [styles['icon-right']]: iconPosition === EIconPosition.RIGHT,
    },
  );

  return (
    <button
      type="button"
      onClick={onClick}
      className={buttonClasses}
      disabled={isDisabled}
      style={{ width, height }}
      {...otherProps}
    >
      {iconPosition === EIconPosition.LEFT && icon}
      {renderChildren(children, isLoading)}
      {iconPosition === EIconPosition.RIGHT && icon}
    </button>
  );
};

export default Button;
