import React from 'react';

export const FolderIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
  >
    <path
      d="M2.27626 3.84615C2.27626 3.42132 2.64026 3.07692 3.08927 3.07692H7.96732L9.99984 5.38462H16.9104C17.3594 5.38462 17.7234 5.729 17.7234 6.15385V16.1538C17.7234 16.5787 17.3594 16.9231 16.9104 16.9231H3.08927C2.64026 16.9231 2.27626 16.5787 2.27626 16.1538V3.84615Z"
      fill="#667085"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M3.08927 3.65385C2.97702 3.65385 2.88602 3.73995 2.88602 3.84615V16.1538C2.88602 16.2601 2.97701 16.3462 3.08927 16.3462H16.9104C17.0227 16.3462 17.1137 16.2601 17.1137 16.1538V6.15385C17.1137 6.04763 17.0227 5.96154 16.9104 5.96154H9.99984C9.81887 5.96154 9.64726 5.88549 9.53141 5.75395L7.68172 3.65385H3.08927ZM1.6665 3.84615C1.6665 3.10269 2.3035 2.5 3.08927 2.5H7.96732C8.14828 2.5 8.3199 2.57605 8.43575 2.70759L10.2854 4.80769H16.9104C17.6962 4.80769 18.3332 5.41037 18.3332 6.15385V16.1538C18.3332 16.8973 17.6962 17.5 16.9104 17.5H3.08927C2.3035 17.5 1.6665 16.8973 1.6665 16.1538V3.84615Z"
      fill="#667085"
    />
  </svg>
);
