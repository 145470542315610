const statuses = {
  connected: 'connected',
  scanning: 'scanning',
  loading: 'loading',
  close: 'close',
};

const getStatus = (status, setServerStatus, isScanned) => {
  switch (status) {
    case 'close':
      setServerStatus(statuses.close);
      break;
    case 'connecting':
      isScanned ? setServerStatus(statuses.loading) : setServerStatus(statuses.scanning);
      break;
    case 'open':
      setServerStatus(statuses.connected);
      break;
  }
};

export const settingsHelper = {
  statuses,
  getStatus,
};
