import React from 'react';

export const PositiveAlertIcon = () => (
  <svg
    width="66"
    height="66"
    viewBox="0 0 66 66"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect x="5" y="5" width="56" height="56" rx="28" fill="#EBE9FE" />
    <rect x="5" y="5" width="56" height="56" rx="28" stroke="#F4F3FF" stroke-width="10" />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M33 23.375C27.6843 23.375 23.375 27.6843 23.375 33C23.375 38.3157 27.6843 42.625 33 42.625C38.3157 42.625 42.625 38.3157 42.625 33C42.625 27.6843 38.3157 23.375 33 23.375ZM21.625 33C21.625 26.7178 26.7178 21.625 33 21.625C39.2822 21.625 44.375 26.7178 44.375 33C44.375 39.2822 39.2822 44.375 33 44.375C26.7178 44.375 21.625 39.2822 21.625 33Z"
      fill="#5925DC"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M31.25 32.125C31.25 31.6418 31.6418 31.25 32.125 31.25H33C33.4832 31.25 33.875 31.6418 33.875 32.125V37.375C34.3582 37.375 34.75 37.7668 34.75 38.25C34.75 38.7332 34.3582 39.125 33.875 39.125H33C32.5168 39.125 32.125 38.7332 32.125 38.25V33C31.6418 33 31.25 32.6082 31.25 32.125Z"
      fill="#5925DC"
    />
    <path
      d="M32.7812 29.5C33.5061 29.5 34.0938 28.9124 34.0938 28.1875C34.0938 27.4626 33.5061 26.875 32.7812 26.875C32.0564 26.875 31.4688 27.4626 31.4688 28.1875C31.4688 28.9124 32.0564 29.5 32.7812 29.5Z"
      fill="#5925DC"
    />
  </svg>
);
