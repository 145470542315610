import { all, takeLatest, put, fork } from 'redux-saga/effects';

import { modalActions } from '../../components/containers/Modal/modalActions';
import { integrationEventsActions } from './integrationEventsActions';
import { integrationApi } from '../../api/integrationApi';
import { constructorActions } from '../constructor/constructorActions';
import {
  CREATE_INTEGRATION_EVENT_REQUEST,
  GET_INTEGRATION_EVENT_REQUEST,
} from './integrationEventsConstants';

function* getZapierEventsRequestSaga(action, eventId) {
  try {
    const { companyBot } = action.payload;
    const response = yield integrationApi.getZapierEvents(companyBot);

    if (response.data) {
      yield fork(handleSelectedZapierEventsSaga, action, eventId);
    }
  } catch (err) {}
}

function* createIntegrationEventRequestSaga(action) {
  try {
    const response = yield integrationApi.addNewZapierEvent(action.payload);

    if (response) {
      yield put(modalActions.closeModal());
      yield fork(getZapierEventsRequestSaga, action, response.data.id);
    }
  } catch (err) {
    if (err.response.status === 400) {
      yield put(
        integrationEventsActions.createIntegrationEventError(
          'Tag with this name already exist',
        ),
      );
    }
  }
}

function* handleSelectedZapierEventsSaga(action, eventId) {
  try {
    yield fork(getIntegrationEventRequestSaga, action.payload.companyBot);
    yield put(constructorActions.loadInProgress());
    const { handleSelectIntegrationEvent } = action.payload;
    const response = yield integrationApi.setZapierIntegration(
      handleSelectIntegrationEvent,
      eventId,
    );

    if (response.data) {
      yield put(constructorActions.updateIntegrationBlock(response.data, 0));
      yield put(constructorActions.loadSuccess());
    }
  } catch (err) {
    console.log('err', err);
    yield put(constructorActions.loadFailure());
  }
}

function* getIntegrationEventRequestSaga(action) {
  try {
    const response = yield integrationApi.getZapierEvents(
      action && action.payload ? action.payload : action,
    );

    if (response.data) {
      yield put(integrationEventsActions.getIntegrationEventSuccess(response.data));
    }
  } catch (err) {}
}

function* sagas() {
  yield all([
    takeLatest(CREATE_INTEGRATION_EVENT_REQUEST, createIntegrationEventRequestSaga),
  ]);
  yield all([takeLatest(GET_INTEGRATION_EVENT_REQUEST, getIntegrationEventRequestSaga)]);
}

export const integrationEventsSagas = sagas;
