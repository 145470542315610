import {
  GET_GROUPCAMPAIGNS_SUCCESS,
  GET_GROUPCAMPAIGNS_REQUEST,
} from './groupCampaignsConstants';

const initialState = {
  groupCampaigns: [],
  groupsCampaignsErrors: '',
};

export const groupCampaignsReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_GROUPCAMPAIGNS_REQUEST:
      return {
        ...state,
        groupsCampaignsErrors: '',
      };
    case GET_GROUPCAMPAIGNS_SUCCESS:
      const { data } = action.payload;
      return {
        ...state,
        groupCampaigns: [...data],
        groupsCampaignsErrors: '',
      };
    default:
      return state;
  }
};
