import React, { useEffect, useState } from 'react';
import cn from 'classnames';
import { useInternationalization } from '@hooks/useTranslationHook';
import Button from '@wabb-ui-kit/Button/Button';
import { Label } from '@wabb-ui-kit/Label/Label';
import { ELableSize, ELabelIconPosition } from '@wabb-ui-kit/Label/types/types';
import { EButtonSize, EIconPosition } from '@wabb-ui-kit/Button/types/types';
import './ChatAction.scss';
import { CheckIcon } from '@wabb-assets/svg/Label/check-icon';
import { CheckSmallIcon } from '@wabb-assets/svg/Button/check-icon';

export const ChatAction = ({ data, updateUserCard, isContacts }) => {
  const { formatMessage } = useInternationalization();
  const [openCaseState, setOpenCaseState] = useState(false);

  const changeOpenState = () => {
    updateUserCard({ isCaseOpened: !openCaseState });
    setOpenCaseState(!openCaseState);
  };

  useEffect(() => {
    setOpenCaseState(data?.isCaseOpened);
  }, [data?.isCaseOpened]);
  return (
    <div>
      <div
        className={cn('chat-action_state', { 'chat-action_state--contacts': isContacts })}
      >
        <div className="chat-action_left">
          <span>{formatMessage('Chat is')} </span>
          <span
            className={
              openCaseState ? 'chat-action_state_open' : 'chat-action_state_done'
            }
          >
            {openCaseState ? (
              formatMessage('Open')
            ) : (
              <Label
                type="green"
                style="tinted"
                iconPosition={ELabelIconPosition.LEFT}
                icon={<CheckIcon />}
                size={ELableSize.M}
              >
                Closed
              </Label>
            )}
          </span>
        </div>
        <div>
          <Button
            onClick={changeOpenState}
            size={EButtonSize.S}
            style="secondary"
            type="outlined"
            icon={openCaseState ? <CheckSmallIcon /> : false}
            iconPosition={EIconPosition.RIGHT}
          >
            <span>
              {openCaseState ? formatMessage('Mark as Done') : formatMessage('Re-open')}
            </span>
          </Button>
        </div>
      </div>
    </div>
  );
};
