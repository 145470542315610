import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import classNames from 'classnames';
import { styled } from '@mui/system';

import { managersActions } from '../../../redux/managers/managersActions';
import { usersApi } from '../../../api/usersApi';
import { ModalContainer } from '../../containers/Modal/ModalContainer';
import { MODALS } from '../../containers/Modal/modalConstants';
import { getModalDataSelector } from '../../containers/Modal/modalSelectors';
import { useInternationalization } from '../../../hooks/useTranslationHook';
import { Button } from '../../common/Button';
import { GreenCheckbox } from '../../common/GreenCheckbox/GreenCheckbox';

import '../modalsComponent.scss';
import './ChatPermissionsModal.scss';

const GreenRadio = styled(Radio)(({ theme }) => ({
  color: theme.palette.green[400],
  '&.Mui-checked': {
    color: theme.palette.green[600],
  },
}));

const styles = {};

export const ChatPermissionsModal = () => {
  const dispatch = useDispatch();
  const { formatMessage } = useInternationalization();
  const { modalData } = useSelector(getModalDataSelector);
  const {
    activeManagerId,
    live_chat_all,
    live_chat_all_busy,
    live_chat_my_busy,
    assign_chat,
  } = modalData;

  const [assignOption, setAssignOption] = useState(assign_chat);

  const [allGroupState, setAllGroupState] = useState(live_chat_all);
  const [allBusyGroupState, setAllBusyGroupState] = useState(live_chat_all_busy);
  const [allMyGroupState, setAllMyGroupState] = useState(live_chat_my_busy);
  const [error, setError] = useState(false);

  const checkEmptyGroups = () => {
    const groupArr = [allGroupState, allBusyGroupState, allMyGroupState];
    return groupArr.every(grouptItem => !grouptItem);
  };

  const handleSubmit = event => {
    event.preventDefault();

    if (checkEmptyGroups()) {
      setError(true);
    } else {
      setError(false);
      const updateActiveUserObj = {
        live_chat_all: allGroupState,
        live_chat_my_busy: allMyGroupState,
        live_chat_all_busy: allBusyGroupState,
        assign_chat: assignOption,
      };
      usersApi.updateActiveUser(activeManagerId, updateActiveUserObj).then(res => {
        dispatch(
          managersActions.updateLiveChatPermissionsSuccess({
            activeManagerId,
            updateActiveUserObj,
          }),
        );
        modalData.onClose();
      });
    }
  };

  const handleChangeAssignOption = event => {
    setAssignOption(+event.target.value);
  };

  return (
    <ModalContainer
      modal={MODALS.chatPermissions}
      onClose={modalData.onClose}
      customStyles={styles}
    >
      <div className="campaigns-modal">
        <div className="campaigns-modal__header">{formatMessage('Update')}</div>
        <div
          className="campaigns-modal__form-group"
          style={{ flexDirection: 'column', alignItems: 'flex-start', marginLeft: '20%' }}
        >
          <FormControl component="fieldset">
            <FormLabel component="legend">
              {formatMessage('Live chat permisssions')}
            </FormLabel>
            <div style={{ display: 'flex', alignItems: 'center', marginTop: '20px' }}>
              <FormControlLabel
                className={classNames(classes.root, classes.body)}
                control={
                  <GreenCheckbox
                    checked={allGroupState}
                    onChange={event => {
                      setError(false);
                      setAllGroupState(event.target.checked);
                    }}
                    inputProps={{ 'aria-label': 'checkbox with default color' }}
                    name="checkedBox"
                  />
                }
              />
              <span style={{ display: 'inline-block', fontSize: '14px' }}>
                {formatMessage('All chats')}
              </span>
            </div>

            <div style={{ display: 'flex', alignItems: 'center' }}>
              <FormControlLabel
                className={classNames(classes.root, classes.body)}
                control={
                  <GreenCheckbox
                    checked={allMyGroupState}
                    onChange={event => {
                      setError(false);
                      setAllMyGroupState(event.target.checked);
                    }}
                    inputProps={{ 'aria-label': 'checkbox with default color' }}
                    name="checkedBox"
                  />
                }
              />
              <span style={{ display: 'inline-block', fontSize: '14px' }}>
                {formatMessage('My chats')}
              </span>
            </div>

            <div style={{ display: 'flex', alignItems: 'center', marginBottom: '30px' }}>
              <FormControlLabel
                className={classNames(classes.root, classes.body)}
                control={
                  <GreenCheckbox
                    checked={allBusyGroupState}
                    onChange={event => {
                      setError(false);
                      setAllBusyGroupState(event.target.checked);
                    }}
                    inputProps={{ 'aria-label': 'checkbox with default color' }}
                    name="checkedBox"
                  />
                }
              />
              <span style={{ display: 'inline-block', fontSize: '14px' }}>
                {formatMessage('Opened chats')}
              </span>
            </div>
          </FormControl>
          <span
            style={{
              color: 'red',
              marginBottom: '35px',
              marginTop: '-30px',
              visibility: error ? 'visible' : 'hidden',
            }}
          >
            {formatMessage('At least one group must be selected')}
          </span>

          <FormControl component="fieldset">
            <FormLabel component="legend">
              {formatMessage('Assign chat permissions')}
            </FormLabel>
            <RadioGroup
              aria-label="assign-option"
              name="assign-option"
              value={assignOption}
              onChange={handleChangeAssignOption}
            >
              <FormControlLabel
                value={0}
                control={<GreenRadio />}
                label={formatMessage('Can assign chats to himself and other people')}
              />
              <FormControlLabel
                value={1}
                control={<GreenRadio />}
                label={formatMessage('Can assign chats to himself')}
              />
              <FormControlLabel
                value={2}
                control={<GreenRadio />}
                label={formatMessage('Can`t assign but can make free')}
              />
            </RadioGroup>
          </FormControl>
        </div>

        <div className="campaigns-modal__form-group">
          <Button
            onClick={handleSubmit}
            color="green"
            width={200}
            customClass="bots-modal__submit-button"
          >
            {formatMessage('Submit')}
          </Button>
        </div>
      </div>
    </ModalContainer>
  );
};
