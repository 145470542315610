import React from 'react';

import { useInternationalization } from '@hooks/useTranslationHook';

import './RadioButtons.scss';

export const RadioButtons = ({
  options,
  onChange,
  errorText,
  customStyles,
  customStylesRadioText,
}) => {
  const { formatMessage } = useInternationalization();

  return (
    <>
      <div className="radio-buttons" style={customStyles}>
        {options.map(el => (
          <label className="radio-buttons__radio">
            <input
              className="radio-buttons__radio-input"
              type="radio"
              name={el.name}
              value={el.value}
              id={el.id}
              onClick={e => onChange(e, el.name)}
              defaultChecked={el.checked}
            />
            <span
              className="radio-buttons__radio-span"
              style={{ borderColor: errorText ? '#F54E19' : '#D9DFE3' }}
            />
            <div className="radio-buttons__radio-text" style={customStylesRadioText}>
              {el.valueComponent ? el.valueComponent : el.value}
            </div>
          </label>
        ))}
      </div>
      <div>
        {errorText && <div className="radio-buttons__error-text">{errorText}</div>}
      </div>
    </>
  );
};
