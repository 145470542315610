import React, { useEffect, useRef, useState } from 'react';
import set from 'lodash/set';
import { useSelector } from 'react-redux';

import { CheckIcon } from '@icons/check-icon';
import { getVariablesSelector } from '@redux/variables/variablesSelector';
import { useInternationalization } from '@hooks/useTranslationHook';
import { NewBcField } from '@components/common/fields/newBcField/newBcField';
import { Button } from '@components/common';
import { TextEditor } from '@components/common/TextEditor/TextEditor';

const types = {
  array: 'array',
  obj: 'object',
  string: 'string',
  number: 'number',
  null: 'null',
};

export const systemFields = [
  {
    label: 'full_name',
    value: 'full_name',
  },
  {
    label: 'first_name',
    value: 'first_name',
  },
  {
    label: 'last_name',
    value: 'last_name',
  },
  {
    label: 'phone(variable)',
    value: 'phone(variable)',
  },
  {
    label: 'ddd',
    value: 'ddd',
  },
  {
    label: 'referral_name',
    value: 'referral_name',
  },
  {
    label: 'referrals_count',
    value: 'referrals_count',
  },
  {
    label: 'referral_code',
    value: 'referral_code',
  },
  {
    label: 'referral_phone',
    value: 'referral_phone',
  },
  {
    label: 'joined_at',
    value: 'joined_at',
  },
  {
    label: 'email',
    value: 'email',
  },
];

export const FieldEditor = ({
  jsonConfigField,
  setJsonConfigField,
  jsonConfig,
  setJsonConfig,
  isOpenEditMenu,
  setIsOpenEditMenu,
  setForm,
  form,
  handleClose,
}) => {
  const [type, setType] = useState(types.string);
  const [isOpenSelect, setIsOpenSelect] = useState(false);
  const [initValue, setInitValue] = useState({ text: '', styledText: null });
  const { formatMessage } = useInternationalization();
  const selectRef = useRef(null);

  const listenClick = ev => {
    if (selectRef.current && !selectRef.current.contains(ev.target)) {
      setIsOpenSelect(false);
    }
  };

  useEffect(() => {
    window.addEventListener('click', listenClick);

    return () => {
      window.removeEventListener('click', listenClick);
    };
  });

  const systemFieldsData = systemFields.map(element => ({
    label: formatMessage(element.label),
    value: formatMessage(element.value),
  }));

  const botVariablesData = useSelector(getVariablesSelector).botVariables.map(
    element => ({
      label: element.key,
      value: element.key,
    }),
  );
  const userVariablesData = useSelector(getVariablesSelector).variables.map(element => ({
    label: element.key,
    value: element.key,
  }));

  const groupedOptions = [
    {
      label: formatMessage('System fields'),
      options: systemFieldsData,
    },
    {
      label: formatMessage('User variables'),
      options: userVariablesData,
    },
    {
      label: formatMessage('Bot variables'),
      options: botVariablesData,
    },
  ];

  const updateJsonConfig = typeOfValue => {
    if (jsonConfigField.key) {
      let obj = { ...jsonConfig };
      const path = [...jsonConfigField.nameSpace];
      path.push(jsonConfigField.key);

      switch (typeOfValue) {
        case 'object':
          obj = set(obj, path, {});
          break;
        case 'array':
          obj = set(obj, path, []);
          break;
        case 'null':
          obj = set(obj, path, null);
          break;
        case 'number':
          obj = set(obj, path, Number(jsonConfigField.value));
          break;
        default:
          obj = set(obj, path, jsonConfigField.value);
      }

      setJsonConfig(obj);
      setForm({ ...form, body: obj });
    }

    // cancelUpdate();
    setIsOpenEditMenu(false);
    handleClose();
  };

  const handleChangeFieldValue = (text, styledText) => {
    switch (text) {
      case '{}':
        setType(types.obj);
        break;
      case '[]':
        setType(types.array);
        break;
      case 'null':
        setType(types.null);
        break;
      default:
        const valueToNumber = Number(text);
        setType(!isNaN(valueToNumber) ? types.number : types.string);
    }

    setJsonConfigField({ ...jsonConfigField, value: text, styledText });
  };

  const cancelUpdate = () => {
    setJsonConfigField({
      key: null,
      value: '',
      namespace: [],
      styledText: null,
    });
    setType(types.string);
    setIsOpenEditMenu(false);
  };

  useEffect(() => {
    if (
      jsonConfigField.styledText &&
      jsonConfigField.styledText === initValue.styledText
    ) {
      setInitValue({ text: '', styledText: jsonConfigField.styledText });
    } else {
      setInitValue({
        text: jsonConfigField.value?.toString(),
        styledText: null,
      });
    }
  }, []);

  return (
    <div className="editor">
      <NewBcField
        value={formatMessage('key :') && isOpenEditMenu && jsonConfigField.key}
        disabled
        label="Key"
      />
      <div className="editor__text-area-block">
        <div className="editor__text-area-block_label">{formatMessage('Value')}</div>
        <div className="editor__text-area-block_text">
          <TextEditor
            label="Value"
            initValue={initValue}
            listOfRequestFields={groupedOptions}
            onChange={handleChangeFieldValue}
            // onBlur={handleChangeFieldValue}
            isShowDecoratorsPanel
            // textLimit={isEditMode ? 1000 : 0}
            // customClass={!isEditMode ? 'read-only' : ''}
          />
        </div>
      </div>
      {type !== 'string' && (
        <div className="editor__check-button-block">
          <span>{`${formatMessage('Is ')} ${formatMessage(type)}  ?`}</span>
          <div className="icon-check-type" onClick={() => updateJsonConfig(type)}>
            <CheckIcon width={15} height={15} color="#76C351" />
          </div>
        </div>
      )}
      {jsonConfigField.key && (
        <div className="bottom-btn-group">
          <Button
            color="blue"
            onClick={() => updateJsonConfig(types.string)}
            width="100%"
            height="50px"
            disabled={!jsonConfigField.value}
            customClass="editor__save-button"
          >
            {formatMessage('Create Key')}
          </Button>
        </div>
      )}
    </div>
  );
};
