import isEqual from 'lodash/isEqual';
import { AudienceTypes } from './types';

const initialState = {
  allUsersCount: null,
  filterUsersCount: null,
  users: [],
  filters: [],
  next: null,
  filterType: 1,
  searchName: '',
  stats: null,
  usersSortType: 0,
  subscribedSortType: 0,
  selectedAll: false,
  loader: true,
  loaderAdmins: true,
  queryString: '',
  queryStringLoader: true,
  savedFilterState: null,
};

export const audienceReducer = (state = initialState, action) => {
  const data = action.payload;

  switch (action.type) {
    case AudienceTypes.GET_USERS_REQUEST:
      return {
        ...state,
      };
    case AudienceTypes.GET_USERS_LOADED:
      return {
        ...state,
        users: addCheckedField(data.results.pipelineUsers, state.selectedAll),
        next: data.next,
        allUsersCount:
          data.results.countPipelineUsers === 0
            ? data.results.pipelineUsers.length
            : data.results.countPipelineUsers,
        filterUsersCount: data.results.count,
        savedFilterState: null,
      };
    case AudienceTypes.GET_USERS_NEXT_REQUEST:
      return {
        ...state,
      };
    case AudienceTypes.GET_USERS_NEXT_LOADED:
      return {
        ...state,
        users: data.hasOwnProperty('results')
          ? [
              ...state.users,
              ...addCheckedField(data.results.pipelineUsers, state.selectedAll),
            ]
          : [...state.users],
        next: data.next,
      };
    case AudienceTypes.DELETE_USER:
      return {
        ...state,
        users: state.users.filter(user => user.id !== data.id),
        allUsersCount: state.allUsersCount - 1,
      };
    case AudienceTypes.CREATE_USER:
      return {
        ...state,
        users: [data, ...state.users],
        allUsersCount: state.allUsersCount + 1,
      };
    case AudienceTypes.ADD_FILTER:
      if (
        data.condition === 'is' &&
        ['Tag', 'Sequence', 'Campaign'].includes(data.filterName)
      ) {
        return {
          ...state,
          filters: addFilter(state.filters, data),
          stats: checkedHelperStats(state.stats, data.value.value, data.filterName, true),
        };
      }

      return {
        ...state,
        filters: addFilter(state.filters, data),
      };

    case AudienceTypes.DELETE_FILTER:
      return {
        ...state,
        filters: state.filters.filter(
          item =>
            !(
              item.filterName === data.filterName &&
              item.condition === item.condition &&
              (data.value.value
                ? item.value.value === data.value.value
                : item.value === data.value)
            ),
        ),
        stats: ['Tag', 'Sequence', 'Campaign'].includes(data.filterName)
          ? checkedHelperStats(state.stats, data.value.value, data.filterName, false)
          : state.stats,
      };
    case AudienceTypes.DELETE_ALL_FILTERS:
      return {
        ...state,
        filters: [],
        stats: checkedHelperAllStats(state.stats),
      };
    case AudienceTypes.SET_FILTER_TYPE:
      return {
        ...state,
        filterType: data,
      };
    case AudienceTypes.SET_SEARCH_NAME:
      return {
        ...state,
        searchName: action.payload,
      };
    case AudienceTypes.GET_STATS:
      return {
        ...state,
        loader: true,
      };
    case AudienceTypes.GET_ADMINS:
      return {
        ...state,
        loaderAdmins: true,
      };
    case AudienceTypes.GET_STATS_LOADED:
      return {
        ...state,
        stats: {
          tags: data.tagTypes,
          sequences: data.sequenceTypes,
          campaigns: data.adsSources,
        },
        loader: false,
      };
    case AudienceTypes.GET_ADMINS_LOADED:
      return {
        ...state,
        admins: data,
        loaderAdmins: false,
      };
    case AudienceTypes.UPDATE_USERS_SORT_TYPE:
      return {
        ...state,
        usersSortType: state.usersSortType === 2 ? 0 : state.usersSortType + 1,
      };
    case AudienceTypes.UPDATE_SUBSCRIBED_SORT_TYPE:
      return {
        ...state,
        subscribedSortType:
          state.subscribedSortType === 2 ? 0 : state.subscribedSortType + 1,
      };

    case AudienceTypes.SWITCH_SELECTED_ALL_MODE:
      return {
        ...state,
        selectedAll: data !== undefined ? data : !state.selectedAll,
        users: addCheckedField(
          state.users,
          data !== undefined ? data : !state.selectedAll,
        ),
      };

    case AudienceTypes.CHANGE_USER_SELECT_MODE:
      return {
        ...state,
        users: changeUserSelectMode(state.users, data),
      };

    case AudienceTypes.UPDATE_USER_FULL_NAME:
      const index = state.users.findIndex(user => user.id === data.id);
      const usersArr = state.users;
      usersArr[index].fullName = data.fullName;
      return { ...state, users: [...usersArr] };

    case AudienceTypes.RESET_USERS:
      return {
        ...initialState,
      };
    case AudienceTypes.SET_QUERY_STRING:
      return {
        ...state,
        queryString: action.payload,
        queryStringLoader: false,
      };
    case AudienceTypes.SET_SAVED_FILTER:
      return {
        ...state,
        queryString: action.payload.filters,
        savedFilterState: action.payload.id,
      };
    case AudienceTypes.CLEAR_NEXT:
      return {
        ...state,
        next: null,
      };
    default:
      return state;
  }
};

const addCheckedField = (data, selectedAll) =>
  data.map(item => ({
    ...item,
    checked: selectedAll,
  }));

const changeUserSelectMode = (users, id) =>
  users.map(item => {
    if (item.id === id) {
      return {
        ...item,
        checked: !item.checked,
      };
    }

    return item;
  });

const checkedHelperStats = (stats, id, filterName, checked) => {
  const key = `${filterName.toLowerCase()}s`;
  return {
    ...stats,
    [key]: stats[key].map(item =>
      item.id === id
        ? {
            ...item,
            checked,
          }
        : item,
    ),
  };
};

const addFilter = (filters, newFilter) => {
  for (const filter of filters) {
    if (isEqual(filter, newFilter)) return filters;
  }

  return [...filters, newFilter];
};

const checkedHelperAllStats = stats => ({
  ...stats,
  tags: stats.tags.map(el => ({ ...el, checked: false })),
  sequences: stats.sequences.map(el => ({ ...el, checked: false })),
  campaigns: stats.campaigns.map(el => ({ ...el, checked: false })),
});
