import React from 'react';
import cn from 'classnames';

export const DecoratorItem = ({ style, toggleInlineStyle, hasInlineStyle }) => {
  const onClick = (e) => {
    e.preventDefault();
    toggleInlineStyle(style.textStyle);
  };

  return (
    <button
      type="button"
      onMouseDown={onClick}
      className={cn('btn-icon', {
        active: hasInlineStyle(style.textStyle),
      })}
    >
      {style.icon}
    </button>
  );
};
