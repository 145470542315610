import React, { useEffect, useRef, useState } from 'react';
import Popover from '@mui/material/Popover';
import classNames from 'classnames';
import moment from 'moment';
import 'moment/locale/ru';
import Dropdown from '@wabb-ui-kit/Dropdown/Dropdown.tsx';
import { useInternationalization } from '@hooks/useTranslationHook';
import { ArrowIcon } from '@wabb-assets/svg/DatePicker/arrow-icon';
import Button from '@wabb-ui-kit/Button/Button';
import { EButtonSize } from '@wabb-ui-kit/Button/types/types';
import { ListItemDateTime } from './ListItemDateTime';
import { TimePopover } from './TimePopover';

import './dateTimePopover.scss';

const months = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

const weekdays = ['Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa', 'Su'];

const monthOptions = months.map((month, index) => ({
  id: index,
  name: month,
}));

export const DateTimePopover = (props) => {
  const { formatMessage } = useInternationalization();

  useEffect(() => {
    moment.locale('en', {
      week: {
        dow: 1,
      },
    });
  }, []);

  const refMonths = useRef();
  const refYears = useRef();

  const currentDate = {
    day: moment().get('date'),
    month: moment().get('month'),
    year: moment().get('year'),
    hour: moment().get('hour'),
    minute: moment().get('minute'),
  };

  const [selectedDate, setSelectedDate] = useState(currentDate);
  const [initialDate, setInitialDate] = useState(currentDate);
  const [step, setStep] = useState(2);
  const [days, setDays] = useState([]);

  const years = Array.from({ length: 200 }, (v, k) => k + moment().year() - 99);
  const yearOptions = years.map((year) => ({
    id: year,
    name: year.toString(),
  }));

  useEffect(() => {
    const dayOfWeek = moment(
      `1-${selectedDate.month + 1}-${selectedDate.year}`,
      'DD-MM-YYYY',
    ).weekday();
    setDays(
      Array(dayOfWeek)
        .fill('', 0, dayOfWeek)
        .concat(
          Array.from(
            {
              length: moment().month(selectedDate.month).daysInMonth(),
            },
            (v, k) => k + 1,
          ),
        ),
    );
  }, [selectedDate.month, selectedDate.year]);

  useEffect(() => {
    if (refMonths.current) {
      refMonths.current.scrollTop = (selectedDate.month - 4 || 0) * 30;
    }

    if (refYears.current) {
      refYears.current.scrollTop = (Math.abs(moment().year() - 99 - selectedDate.year) - 4) * 30;
    }
  }, [step]);

  const changeMonthInDate = (n) => {
    let newDate;

    if (n > 0) {
      newDate = moment([selectedDate.year, selectedDate.month, selectedDate.day]).add(
        1,
        'months',
      );
    } else {
      newDate = moment([
        selectedDate.year,
        selectedDate.month,
        selectedDate.day,
      ]).subtract(1, 'months');
    }

    setSelectedDate({
      ...selectedDate,
      day: newDate.date(),
      month: newDate.month(),
      year: newDate.year(),
    });
  };

  const handleMonthChange = (option) => {
    setSelectedDate((prevDate) => ({
      ...prevDate,
      month: option.id,
    }));
  };

  const handleYearChange = (option) => {
    setSelectedDate((prevDate) => ({
      ...prevDate,
      year: option.id,
    }));
  };

  const handleConfirm = () => {
    props.changeDate(moment(selectedDate).format(props.format));
    setInitialDate(selectedDate);
    props.onClose();
  };

  const handleCancel = () => {
    props.onClose();
    setTimeout(() => {
      setSelectedDate(initialDate);
    }, 300);
  };

  return (
    <Popover
      {...props}
      onClose={handleCancel}
      PaperProps={{
        style: {
          marginTop: '10px',
          border: '1px solid #D9DFE3',
          filter: 'drop-shadow(0px 5px 15px rgba(0, 0, 0, 0.05))',
          borderRadius: '10px',
        },
      }}
      onEntered={() => setInitialDate(selectedDate)}
    >
      <div className="date-time-popover">
        <div className="date-time-popover__date">
          <div className="date-time-popover__header">
            <div
              onClick={() => changeMonthInDate(-1)}
              className="date-time-popover__header_arrow"
            >
              <ArrowIcon rotate={180} />
            </div>
            <div className="date-time-popover__header_dropdown-wrapper">
              <div>
                <Dropdown
                  options={monthOptions}
                  singleSelectedOption={monthOptions[selectedDate.month]}
                  setSingleSelectedOption={handleMonthChange}
                  className="date-time-popover__header_dropdown"
                  isBoldTitle
                />
              </div>
              <div>
                <Dropdown
                  options={yearOptions}
                  singleSelectedOption={yearOptions.find(
                    (option) => option.id === selectedDate.year,
                  )}
                  setSingleSelectedOption={handleYearChange}
                  className="date-time-popover__header_dropdown-month"
                  isBoldTitle
                />
              </div>
            </div>
            <div
              onClick={() => changeMonthInDate(1)}
              className="date-time-popover__header_arrow"
            >
              <ArrowIcon rotate={0} />
            </div>
          </div>
          <div className="date-time-popover__body">
            <div
              id="months"
              ref={refMonths}
              className="date-time-popover__body_container"
            >
              {months.map((el, index) => (
                <ListItemDateTime
                  key={el}
                  item={el}
                  active={index === selectedDate.month}
                  onClick={() => setSelectedDate({
                    ...selectedDate,
                    month: index,
                  })}
                />
              ))}
            </div>
            <div id="years" ref={refYears} className="date-time-popover__body_container">
              {years.map((el) => (
                <ListItemDateTime
                  key={el}
                  item={el}
                  active={el === selectedDate.year}
                  onClick={() => {
                    setSelectedDate({
                      ...selectedDate,
                      year: el,
                    });
                  }}
                />
              ))}
            </div>
            <div
              className={classNames({
                'date-time-popover__body_weekdays': step !== 2,
                'date-time-popover__body_weekdays_active': step === 2,
              })}
            >
              <div className="date-time-popover__body_weekdays_header">
                {weekdays.map((el) => (
                  <div
                    key={el}
                    className="date-time-popover__body_weekdays_header_days"
                  >
                    <span>{formatMessage(el)}</span>
                  </div>
                ))}
              </div>
              <div className="date-time-popover__body_weekdays_body">
                {days.map((el, index) => (
                  <div
                    key={`${el}-${index}`}
                    className={classNames(
                      'date-time-popover__body_weekdays_body_element',
                      {
                        'date-time-popover__body_weekdays_body_element_active':
                          selectedDate.day === el,
                        'date-time-popover__body_weekdays_body_block-element': el === '',
                      },
                    )}
                    onClick={() => {
                      if (el) {
                        setSelectedDate({
                          ...selectedDate,
                          day: el,
                        });
                      }
                    }}
                  >
                    <span>{el}</span>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
        {Boolean(props.dateTime) && (
          <div className="date-time-popover__datetime">
            <div className="date-time-popover__header">
              <div className="date-time-popover__header_time">
                {selectedDate.hour}
                :
                {selectedDate.minute}
              </div>
            </div>
            <TimePopover selectedDate={selectedDate} setSelectedDate={setSelectedDate} />
          </div>
        )}
      </div>
      <div className="date-time-popover__buttons">
        <Button
          type="secondary"
          style="tint"
          size={EButtonSize.L}
          width="80px"
          onClick={handleCancel}
        >
          {formatMessage('Cancel')}
        </Button>
        <Button size={EButtonSize.L} width="88px" onClick={handleConfirm}>
          {formatMessage('Confirm')}
        </Button>
      </div>
    </Popover>
  );
};
