import React from 'react';

import { useInternationalization } from '@hooks/useTranslationHook';
import Switcher from '../../../wabb-ui-kit/Switcher/Switcher';
import './ManagerModal.scss';

export const LiveChatAccess = ({ form }) => {
  const { formatMessage } = useInternationalization();

  const handleChangeAssignHimself = (isChecked) => {
    form.setFieldValue('assignHimself', isChecked);

    if (!isChecked) {
      form.setFieldValue('assignOther', false);
    }
  };

  return (
    <>
      <div className="manager-modal__content__container__settings__content__hidden-item">
        <span>{formatMessage('Access to assigned to me chats')}</span>
        <Switcher
          isChecked={form.values.live_chat_my_busy}
          onChange={() => form.setFieldValue('live_chat_my_busy', !form.values.live_chat_my_busy)}
        />
      </div>
      <div className="manager-modal__content__container__settings__content__hidden-item">
        <span>{formatMessage('Access to assigned to others chats')}</span>
        <Switcher
          isChecked={form.values.live_chat_all}
          onChange={() => form.setFieldValue('live_chat_all', !form.values.live_chat_all)}
        />
      </div>
      <div className="manager-modal__content__container__settings__content__hidden-item">
        <span>{formatMessage('Access to unassigned chats')}</span>
        <Switcher
          isChecked={form.values.live_chat_all_busy}
          onChange={() => form.setFieldValue('live_chat_all_busy', !form.values.live_chat_all_busy)}
        />
      </div>
      <div className="manager-modal__content__container__settings__content__hidden-item">
        <span>{formatMessage('Can assign chats himself')}</span>
        <Switcher
          isChecked={form.values.assignHimself}
          onChange={handleChangeAssignHimself}
        />
      </div>
      <div className="manager-modal__content__container__settings__content__hidden-item">
        <span>{formatMessage('Can assign chats to other users')}</span>
        <Switcher
          isDisabled={!form.values.assignHimself}
          isChecked={form.values.assignOther}
          onChange={(isChecked) => form.setFieldValue('assignOther', isChecked)}
        />
      </div>
    </>
  );
};
