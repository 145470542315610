import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { useInternationalization } from '@hooks/useTranslationHook';
import { broadcastActions } from '@redux/broadcast/broadcastActions';
import {
  getBroadcastUsersCountSelector,
  getBroadcastUsersFiltersSelector,
} from '@redux/broadcast/broadcastSelector';
import { AudienceFilters } from '@pages/audience/components/audienceFilters/audienceFilters';
import Button from '@wabb-ui-kit/Button/Button';
import { EIconPosition } from '@wabb-ui-kit/Button/types/types';
import { ArrowIcon } from '@icons/broadcast/arrow-icon';
import { FiltersPopover } from './filtersPopover/filtersPopover';

import '../BroadcastCreateModal.scss';

export const BroadcastCreateModalRightPanel = () => {
  const { formatMessage } = useInternationalization();
  const dispatch = useDispatch();

  const [anchorEl, setAnchorEl] = useState(null);

  const filters = useSelector(getBroadcastUsersFiltersSelector);
  const broadcastUsersCount = useSelector(getBroadcastUsersCountSelector);

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div className="broadcast-modal__body_right-panel">
      <div className="broadcast-modal__body_right-panel_container">
        <div className="broadcast-modal__body_right-panel_header">
          <div className="broadcast-modal__body_right-panel_header_info">
            <span
              className="broadcast-modal__body_right-panel_header_info_title"
            >
              { formatMessage('Targeting') }
            </span>
            <FiltersPopover
              id="broadcast-filters"
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              open={Boolean(anchorEl)}
              onClose={handleClose}
              broadcasts
            />
          </div>
        </div>
        <div className="broadcast-modal__body_right-panel_body">
          { filters.length > 0 && (
          <AudienceFilters broadcasts />
          ) }
        </div>
        <div className="broadcast-modal__body_right-panel_footer">
          <div className="broadcast-modal__body_right-panel_footer_info">
            <span className="broadcast-modal__body_right-panel_footer_info_users">{ broadcastUsersCount }</span>
            <span
              className="broadcast-modal__body_right-panel_footer_info_text"
            >
              Users who will get this broadcast
            </span>
          </div>
          <div>
            <Button
              type="secondary"
              style="tint"
              icon={<ArrowIcon />}
              iconPosition={EIconPosition.RIGHT}
              onClick={() => dispatch(broadcastActions.setShowUsersInBroadcastCreateModal(true))}
            >
              Show users
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};
