import React from 'react';
import { useDispatch } from 'react-redux';

import { useInternationalization } from '@hooks/useTranslationHook';
import { audienceActions } from '@redux/audience/audienceActions';
import { broadcastActions } from '@redux/broadcast/broadcastActions';
import { CloseSmallIcon } from '@wabb-assets/svg/Tags/close-small-icon';
import './FilterItem.scss';

export const FilterItem = ({ filter, broadcasts }) => {
  const { formatMessage } = useInternationalization();
  const dispatch = useDispatch();

  const deleteFilter = () => {
    broadcasts
      ? dispatch(broadcastActions.deleteFilterFromBroadcast(filter))
      : dispatch(audienceActions.deleteFilter(filter));
  };

  return (
    <div className="filter-item__container">
      <div className="filter-item__container_item">
        <div className="filter-item__container_item_header">
          <span className="filter-item__container_item_header_filter-name">
            {formatMessage(filter.filterName)}
          </span>
          <span className="filter-item__container_item_header_condition">
            {formatMessage(filter.condition)}
          </span>
        </div>
        <div className="filter-item__container_item_body">
          <span>{filter.value.label ? filter.value.label : filter.value}</span>
        </div>
      </div>
      <div className="filter-item__container_item_option" onClick={deleteFilter}>
        <CloseSmallIcon />
      </div>
    </div>
  );
};
