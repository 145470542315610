import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import './newOwnerCompanyFranchiseModal.scss';

import { ModalContainer } from '../../containers/Modal/ModalContainer';
import { MODALS } from '../../containers/Modal/modalConstants';
import { useInternationalization } from '../../../hooks/useTranslationHook';
import { getModalDataSelector } from '../../containers/Modal/modalSelectors';
import { FranchiseCompanyApi } from '../../../api/franchiseCompanyApi';
import { BcTextField } from '../../common/fields/TextField';

const styles = { width: '45%' };

export const NewOwnerCompanyFranchiseModal = () => {
  const { formatMessage } = useInternationalization();
  const { modalData } = useSelector(getModalDataSelector);

  const [users, setUsers] = useState([]);
  const [displayUsers, setDisplayUsers] = useState([]);

  useEffect(() => {
    FranchiseCompanyApi.getFranchiseUsersShort(modalData.franchiseId).then(res => {
      setUsers(res.data);
      setDisplayUsers(res.data);
    });
  }, []);

  const filterUsers = string => {
    if (!string) {
      setDisplayUsers(users);
    } else {
      setDisplayUsers(
        users.filter(
          el =>
            el.email.toLowerCase().includes(string) ||
            el.full_name.toLowerCase().includes(string),
        ),
      );
    }
  };

  const handleRequest = userId => {
    FranchiseCompanyApi.modifyCompany(modalData.companyId, {
      action: modalData.action,
      new_owner: userId,
    }).then(() => {
      modalData.notification();
    });
    modalData.onClose();
  };

  return (
    <ModalContainer
      modal={MODALS.newOwnerCompanyFranchise}
      onClose={modalData.onClose}
      customStyles={styles}
    >
      <div className="campaigns-modal">
        <div className="campaigns-modal__header">{formatMessage('Select new owner')}</div>
        <div className="new_owner">
          <div className="new_owner__search">
            <BcTextField
              onChange={e => filterUsers(e.target.value)}
              fullWidth
              color="green"
              size="small"
              placeholder={formatMessage('Input user name or email for search...')}
            />
          </div>
          <div className="new_owner__header">
            <div className="new_owner__header__name">
              <strong>{formatMessage('Name')}</strong>
            </div>
            <div className="new_owner__header__email">
              <strong>{formatMessage('Email')}</strong>
            </div>
          </div>
          <div className="new_owner__list">
            {displayUsers.map(user => (
              <div
                className="new_owner__list__item"
                onClick={() => handleRequest(user.id)}
              >
                <div className="new_owner__list__item__name">{user.full_name}</div>
                <div className="new_owner__list__item__email">{user.email}</div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </ModalContainer>
  );
};
