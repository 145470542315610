import React, { useEffect, useState } from 'react';

import { useSelector } from 'react-redux';
import { FranchiseCompanyApi } from '@api/franchiseCompanyApi';
import { useContextSelector } from 'use-context-selector';
import { useInternationalization } from '@hooks/useTranslationHook';
import { NewBcField } from '@components/common/fields/newBcField/newBcField';
import { Button } from '@components/common';
import { CompaniesSelector } from '@components/modals/createFranchiseUser/companiesSelector/companiesSelector';

import './inviteUseModal.scss';
import { CrossIcon } from '@icons/franchise/cross-icon';
import { regExp } from '@components/common/fields/newBcField/newBcFieldHelper';
import { ModalContainer } from '../../containers/Modal/ModalContainer';
import { MODALS } from '../../containers/Modal/modalConstants';
import { FranchiseContext } from '../../../contexts/franchiseContext';
import { getModalDataSelector } from '../../containers/Modal/modalSelectors';

const isObjectWithoutError = object => Object.values(object).every(value => value === '');

export const InviteUserModal = () => {
  const { formatMessage } = useInternationalization();
  const { modalData } = useSelector(getModalDataSelector);
  const [selectedCompanies, setSelectedCompanies] = useState([]);
  const [inputs, setInputs] = useState({
    email: '',
  });
  const [inputsError, setInputsError] = useState({
    email: '',
  });
  const data = useContextSelector(FranchiseContext, data => data);
  const { id } = data;
  const { email } = inputs;
  const [companiesData, setCompaniesData] = useState({
    companies: [],
    flowsErrors: '',
    page: 1,
    next: null,
  });
  const [status, setStatus] = useState(null);

  const valueFieldsValidation = () => {
    const errorObject = {};

    for (const [key, value] of Object.entries(inputs)) {
      if (value === '') {
        errorObject[key] = 'This field must not be empty';
      } else if (
        regExp[key] &&
        !regExp[key].expression.test(String(value).toLowerCase())
      ) {
        errorObject[key] = regExp[key].errorMessage;
      } else {
        errorObject[key] = '';
      }
    }

    return errorObject;
  };

  const handleChangeValue = (e, name) => {
    if (e.target && e.target.value) {
      setInputs({ ...inputs, [name]: e.target.value });
      setInputsError({ ...inputsError, [name]: '' });
    } else {
      setInputs({ ...inputs, [name]: '' });
    }
  };

  const handleChangeChecked = item => {
    if (selectedCompanies.find(el => el.id === item.id)) {
      setSelectedCompanies(selectedCompanies.filter(el => el.id !== item.id));
    } else {
      setSelectedCompanies([...selectedCompanies, { name: item.name, id: item.id }]);
    }
  };

  const getFranchiseCompanies = () => {
    FranchiseCompanyApi.getFranchiseCompaniesShort(id).then(res => {
      setCompaniesData({ ...res.data, companies: res.data });
    });
  };

  const handleSubmit = () => {
    const errorObject = valueFieldsValidation();

    if (isObjectWithoutError(errorObject)) {
      const userObj = {
        franchise: id,
        email,
      };
      const data = new FormData();
      data.append('email', inputs.email);
      data.append('franchise', id);
      const type = 'email';
      const companiesIds = selectedCompanies.map(company => company.id);
      modalData.createFranchiseUser(
        userObj,
        companiesIds,
        type,
        setInputsError,
        inputsError,
        setStatus,
      );
    } else {
      setInputsError({ ...inputsError, ...errorObject });
    }
  };

  useEffect(() => {
    getFranchiseCompanies();
  }, []);

  useEffect(() => {
    if (status === 200) {
      modalData.onClose();
    }
  }, [status]);

  return (
    <ModalContainer modal={MODALS.inviteUser} onClose={modalData.onClose}>
      <div className="invite-user-modal">
        <div className="invite-user-modal__header">{formatMessage('Invite User')}</div>
        <div className="invite-user-modal__container">
          <div className="invite-user-modal__container_field">
            <NewBcField
              name="email"
              label={formatMessage('Email')}
              placeholder={formatMessage('Email')}
              value={inputs.email}
              onChange={handleChangeValue}
              helperText={inputsError.email && formatMessage(inputsError.email)}
            />
          </div>
          <div className="invite-user-modal__container_field">
            <CompaniesSelector
              selectedCompanies={selectedCompanies}
              companies={companiesData.companies}
              selectCompany={handleChangeChecked}
            />
            <div className="create-franchise-user__container_field_companies-array">
              {selectedCompanies &&
                selectedCompanies.map(item => (
                  <div className="create-franchise-user__container_field_companies-array_item">
                    {item.name}
                    <div
                      className="create-franchise-user__container_field_companies-array_item_cross"
                      onClick={() => handleChangeChecked(item)}
                    >
                      <CrossIcon />
                    </div>
                  </div>
                ))}
            </div>
          </div>
          <div>
            <Button
              width="100%"
              color="green"
              onClick={() => handleSubmit()}
              disabled={!inputs.email}
              customClass="invite-user-modal__button-text"
            >
              {formatMessage('Invite')}
            </Button>
          </div>
        </div>
      </div>
    </ModalContainer>
  );
};
