import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import debounce from 'lodash/debounce';

import { useInternationalization } from '@hooks/useTranslationHook';
import { getSelectedCompanySelector } from '@redux/companies/companiesSelectors';
import { flowsApi } from '@api/flowsApi';
import { broadCastApi } from '@api/broadCastApi';
import { bcSplitTableActions } from '@redux/bcSplitTable/bcSplitTableActions';
import { broadcastActions } from '@redux/broadcast/broadcastActions';
import { audienceApi } from '@api/audience';
import { parseQueryString } from '@helpers/parseQueryString';
import { collectQueryString } from '@helpers/collectQueryString';
import { getBcSplitTableItemsNameSelector } from '@redux/bcSplitTable/bcSplitTableSelectors';
import {
  getBroadcastUsersCountSelector,
  getBroadcastShowUsersSelector,
  getBroadcastFilterTypeSelector,
  getBroadcastSubscribedUsersSortTypesSelector,
  getBroadcastUsersFiltersSelector,
  getBroadcastUsersLoadedSelector,
  getBroadcastUsersQueryStringLoaderSelector,
  getBroadcastUsersQueryStringSelector,
  getBroadcastUsersSearchNameSelector,
  getBroadcastUsersSortTypesSelector,
  getNewSavedFilterStateBroadcast,
  getNextBroadcastUsersSelector,
  getBroadcastUsersSelector,
} from '@redux/broadcast/broadcastSelector';
import { UsersTable } from '@pages/audience/components/usersTable/UsersTable';
import { BroadcastTypes } from '@pages/broadcast/consts';
import decamelizeKeys from 'decamelize-keys';
import Button from '@wabb-ui-kit/Button/Button';
import { EButtonSize } from '@wabb-ui-kit/Button/types/types';
import FormField from '@wabb-ui-kit/FormField/FormField';
import { LoopIcon } from '@wabb-assets/svg/FormField/loop-icon';
import { EComponentType, EIconDirection } from '@wabb-ui-kit/FormField/types/types';
import { ArrowBack } from '@icons/audience/arrow-back';
import { modalActions } from '../../containers/Modal/modalActions';
import { BroadcastCreateModalRightPanel } from './BroadcastCreateModalRightPanel/BroadcastCreateModalRightPanel';
import { BroadcastCreateModalLeftPanel } from './BroadcastCreateModalLeftPanel/BroadcastCreateModalLeftPanel';
import { MODALS } from '../../containers/Modal/modalConstants';
import { ModalContainer } from '../../containers/Modal/ModalContainer';
import { getModalDataSelector } from '../../containers/Modal/modalSelectors';

import './BroadcastCreateModal.scss';

export const BroadcastCreateModal = () => {
  const { formatMessage } = useInternationalization();
  const dispatch = useDispatch();
  const { modalData } = useSelector(getModalDataSelector);

  const { broadcast } = modalData;

  const company = useSelector(getSelectedCompanySelector);
  const queryString = useSelector(getBroadcastUsersQueryStringSelector);

  const filters = useSelector(getBroadcastUsersFiltersSelector);
  const filterType = useSelector(getBroadcastFilterTypeSelector);
  const usersSortTypes = useSelector(getBroadcastUsersSortTypesSelector);
  const subscribedSortTypes = useSelector(getBroadcastSubscribedUsersSortTypesSelector);
  const queryStringLoader = useSelector(getBroadcastUsersQueryStringLoaderSelector);
  const searchName = useSelector(getBroadcastUsersSearchNameSelector);
  const loader = useSelector(getBroadcastUsersLoadedSelector);
  const activeItemsName = useSelector(getBcSplitTableItemsNameSelector);
  const savedFilterState = useSelector(getNewSavedFilterStateBroadcast);
  const broadcastNext = useSelector(getNextBroadcastUsersSelector);
  const showUsers = useSelector(getBroadcastShowUsersSelector);
  const users = useSelector(getBroadcastUsersSelector);
  const broadcastUsersCount = useSelector(getBroadcastUsersCountSelector);

  const [page, setPage] = useState(2);
  const [stats, setStats] = useState([]);
  const [statsLoader, setStatsLoader] = useState(true);
  const [showDateStart, setShowDateStart] = useState(
    broadcast ? Boolean(broadcast.start_time) : false,
  );
  const [invalidDate, setInvalidDate] = useState(false);

  const [flowsData, setFlowData] = useState([]);
  const [broadcastData, setBroadcastData] = useState(broadcast || {});

  const queryStringURL = new URLSearchParams(
    broadcast ? broadcast.query_string : queryString,
  );

  const [cursor, setCursor] = useState(null);
  const [usersSearchName, setUsersSearchName] = useState('');

  const sendDebouncedReq = useCallback(
    debounce((value) => {
      dispatch(
        broadcastActions.getUsersRequest({
          botId: company.bot,
          cursor,
          queryString: `search_name=${value}`,
        })
      );
    }, 800),
    []
  );

  useEffect(() => {
    if (!broadcastNext) {
      return setCursor(null);
    }

    try {
      const urlObj = new URL(broadcastNext);
      const newCursor = urlObj.searchParams.get("cursor");
      setCursor(newCursor);
    } catch (error) {
      console.error("Invalid URL:", broadcastNext);
    }
  }, [broadcastNext]);

  useEffect(() => {
    if (company) {
      flowsApi.getFlowsShort(company.bot).then((res) => {
        setFlowData(res.flowList);
      });

      audienceApi.getStatistics(company.bot).then((res) => {
        setStats({
          tags: res.tagTypes,
          sequences: res.sequenceTypes,
          campaigns: res.adsSources,
        });
        setStatsLoader(false);
      });
    }

    dispatch(
      broadcastActions.setBroadcastQueryString(
        broadcast ? broadcast.query_string : queryString,
      ),
    );
  }, [company.bot]);

  useEffect(() => {
    if (!queryStringLoader) {
      dispatch(
        broadcastActions.getUsersRequest({
          botId: company.bot,
          cursor,
          queryString,
        }),
      );
      setPage(2);
    }
  }, [filters, queryString, queryStringLoader]);

  const handleUpdateBroadcastData = (data) => {
    setBroadcastData({ ...broadcastData, ...data });
  };

  useEffect(() => {
    if (!statsLoader) {
      parseQueryString('Broadcast', queryStringURL, dispatch, stats);
    }
  }, [statsLoader, savedFilterState]);

  useEffect(() => {
    if (!queryStringLoader && !statsLoader) {
      const query = collectQueryString(
        searchName,
        usersSortTypes,
        subscribedSortTypes,
        filterType,
        filters,
      );
      dispatch(broadcastActions.setBroadcastQueryString(query));
    }
  }, [filters, searchName, filterType, loader, usersSortTypes, subscribedSortTypes]);

  const getNextPageOfUsers = () => {
    dispatch(
      broadcastActions.getNextPageOfUsersRequest({
        botId: company.bot,
        cursor,
        queryString,
      }),
    );
    setPage(page + 1);
    dispatch(broadcastActions.clearNext());
  };

  const handleCreateBroadcast = (queryString, run, runNow) => {
    let stateParamApi = 0;

    if (run) {
      stateParamApi = runNow ? 2 : 1;
    }

    broadCastApi
      .createBroadcast(
        {
          ...broadcastData,
          state: stateParamApi,
          bot_id: company.bot,
        }
      )
      .then((response) => {
        if (
          (activeItemsName === 'Scheduled broadcasts' && response.state !== 0)
          || (activeItemsName === 'Draft broadcasts' && response.state === 0)
        ) {
          const responseSnakeCase = decamelizeKeys(response, { deep: true });
          dispatch(bcSplitTableActions.createNewItemSuccess(responseSnakeCase));
        }

        dispatch(modalActions.closeModal());
      });
  };

  const handleUpdateBroadcast = (queryString, run, runNow) => {
    let stateParamApi = 0;

    if (run) {
      stateParamApi = runNow ? 2 : 1;
    }

    broadCastApi
      .updateBroadcast(
        {
          pk: broadcastData.id,
          state: stateParamApi,
          flow: broadcastData.flow,
          name: broadcastData.name,
          delay: broadcastData.delay,
          delayType: broadcastData.delay_type,
          delaySelectionType: broadcastData.delay_selection_type,
          startTime: broadcastData.start_time,
        }
      )
      .then((res) => {
        const responseSnakeCase = decamelizeKeys(res, { deep: true });

        if (activeItemsName === BroadcastTypes.draft && run) {
          dispatch(bcSplitTableActions.deleteItemSuccess(responseSnakeCase.id));
        } else {
          dispatch(bcSplitTableActions.updateItemInSplitTable(responseSnakeCase));
        }

        dispatch(modalActions.closeModal());
      });
  };

  const handleConfirm = (clickedStart, startNow) => {
    if (broadcast) {
      handleUpdateBroadcast(queryString, clickedStart, startNow);
    } else if (clickedStart && broadcastData.name && broadcastData.flow) {
      handleCreateBroadcast(queryString, clickedStart, startNow);
    } else if (broadcastData.name && broadcastData.flow) {
      dispatch(
        modalActions.openConfirmModal({
          title: formatMessage('Cancel broadcast creation'),
          text: formatMessage('This broadcast contains unsaved changes. You can save it as a draft to work on later.'),
          confirmButtonText: formatMessage('Save as draft'),
          canselButtonText: formatMessage('Cancel'),
          type: 'delete',
          handleConfirm: () => handleCreateBroadcast(queryString),
          onClose: () => {
            dispatch(modalActions.closeModal());
          },
        }),
      );
    }

    dispatch(broadcastActions.clearReducer());
  };

  const onClose = () => {
    dispatch(modalActions.closeModal());
    handleConfirm(broadcastData.state === 1, false);
  };

  const handleChange = (e) => {
    setUsersSearchName(e.target.value);
    sendDebouncedReq(e.target.value);
  };

  const cleanUsersSearch = () => {
    dispatch(
      broadcastActions.getUsersRequest({
        botId: company.bot,
        cursor,
      })
    );
    setUsersSearchName('');
  };

  return (
    <ModalContainer modal={MODALS.broadcast} onClose={onClose}>
      <div className="broadcast-modal">
        { showUsers ? (
          <>
            <div className="broadcast-modal__users-table">
              <div className="broadcast-modal__users-table__header">
                <div onClick={() => dispatch(broadcastActions.setShowUsersInBroadcastCreateModal(false))}>
                  <ArrowBack />
                </div>
                <span>{ formatMessage('Back') }</span>
              </div>
              <div className="broadcast-modal__users-table__subheader">
                <div className="broadcast-modal__users-table__subheader_users">
                  <span>{ formatMessage('Users') }</span>
                  <span className="broadcast-modal__users-table__subheader_users_count">{ broadcastUsersCount }</span>
                </div>
                <FormField
                  Component={EComponentType.Input}
                  width={320}
                  placeholder="Search"
                  value={usersSearchName}
                  icon={<LoopIcon color="#667085" />}
                  iconDirection={EIconDirection.Left}
                  onChange={handleChange}
                  onCrossClick={cleanUsersSearch}
                />
              </div>
            </div>
            <UsersTable
              users={users}
              page={page}
              usersSortTypes={usersSortTypes}
              subscribedSortTypes={subscribedSortTypes}
              getNextPageOfUsers={getNextPageOfUsers}
              broadcastUsersCount={broadcastUsersCount}
              broadcastSearchName={searchName}
              broadcasts
            />
          </>
        ) : (
          <>
            <div className="broadcast-modal__header">
              <span>{ formatMessage('Create broadcast') }</span>
            </div>
            <div className="broadcast-modal__body">
              <BroadcastCreateModalLeftPanel
                flows={flowsData}
                broadcastData={broadcastData}
                handleUpdateBroadcastData={handleUpdateBroadcastData}
                setShowDateStart={setShowDateStart}
                showDateStart={showDateStart}
              />
              <BroadcastCreateModalRightPanel />
            </div>
            <div className="broadcast-modal__bottom">
              <div className="broadcast-modal__bottom_buttons">
                <Button
                  type="secondary"
                  style="tint"
                  size={EButtonSize.L}
                  width={111}
                  onClick={onClose}
                >
                  <span>{ formatMessage('Cancel') }</span>
                </Button>
                <Button
                  type="primary"
                  size={EButtonSize.L}
                  width={111}
                  isDisabled={showDateStart
                    ? invalidDate || !broadcastData.name || !broadcastData.flow
                    : !broadcastData.name || !broadcastData.flow}
                  onClick={() => handleConfirm(true, !showDateStart)}
                >
                  <span>{ formatMessage('Start') }</span>
                </Button>
              </div>
            </div>
          </>
        ) }
      </div>
    </ModalContainer>
  );
};
