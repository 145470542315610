import { createSelector } from 'reselect';

export const getChatSelector = createSelector(
  [state => state.chats],
  chats => chats,
  chatSocket => chatSocket,
);

export const getChatsCursor = createSelector(
  [state => state.chats],
  chats => chats.newChatsCursor,
);

export const getChatsCaseFilter = createSelector(
  [state => state.chats],
  chats => chats.caseFilter,
);

export const getChatRoomSelector = createSelector(
  [state => state.chats],
  chats => chats.currentRoom,
);

export const getChatsSelector = createSelector(
  [state => state.chats],
  chats => chats.chats,
);

export const getCurrentFilterSelector = createSelector(
  [state => state.chats],
  chats => chats.currentFilter,
);

export const getConnectionStatusSelector = createSelector(
  [state => state.chats],
  chats => chats.connectionStatus,
);

//TODO remove when will be add chat info to store
export const getCurrentChatIdSelector = createSelector(
  [state => state.chats],
  chats => chats.currentChatId,
);

export const getNewCursorMessageSelector = createSelector(
  [state => state.chats],
  chats => chats.newMessagesCursos,
);

export const getPrevCursorMessageSelector = createSelector(
  [state => state.chats],
  chats => chats.oldMessagesCursos,
);

export const getChatsLoadingSelector = createSelector(
  [state => state.chats],
  chats => chats.chatsLoading,
);

export const getSoundNotificationSelector = createSelector(
  [state => state.chats],
  chats => chats.isSoundNotification,
);

export const getSearchValueSelector = createSelector(
  [state => state.chats],
  chats => chats.searchValue,
);

export const getRepliedMessageSelector = createSelector(
  [state => state.chats],
  chats => chats.repliedMessage,
);

export const getUserCardDataSelector = createSelector(
  [state => state.chats],
  chats => chats.userCardData,
);

export const getFullNameSelector = createSelector(
  [state => state.chats],
  chats => chats.userCardData?.full_name,
);

export const getIsUserCardLoadingSelector = createSelector(
  [state => state.chats],
  chats => chats.isUserCardLoading,
);

export const getIsChatLoadingSelector = createSelector(
  [state => state.chats],
  chats => chats.isChatLoading,
);

export const getMessagesListSelector = createSelector(
  [state => state.chats],
  chats => chats.messagesList,
);

export const getIsLoadingMessageSelector = createSelector(
  [state => state.chats],
  chats => chats.messagesLoading,
);

export const getScrollToBottomSelector = createSelector(
  [state => state.chats],
  chats => chats.scrollBottom,
);
