import React from 'react';
import { useSelector } from 'react-redux';
// import ReactJson from 'react-json-view';
import { useContextSelector } from 'use-context-selector';

import { Button } from '@components/common';
import { useInternationalization } from '@hooks/useTranslationHook';
import { MODALS } from '../../containers/Modal/modalConstants';
import { ModalContainer } from '../../containers/Modal/ModalContainer';
import { getModalDataSelector } from '../../containers/Modal/modalSelectors';
import { ToastContext } from '../../../contexts/toastsContext';

import './checkHTTPBody.scss';

const styles = {};

export const checkHTTPBodyModal = () => {
  const { modalData } = useSelector(getModalDataSelector);
  const { formatMessage } = useInternationalization();
  const { data } = modalData;
  const addToast = useContextSelector(ToastContext, ({ add }) => add);

  const handleCopyData = data => {
    if (navigator.clipboard) {
      navigator.clipboard
        .writeText(JSON.stringify(data))
        .then(() => {})
        .catch(err => console.error('error ', err));
    }

    addToast({
      title: 'Copied',
      type: 'success',
      message: 'Copied successfully',
    });
  };

  return (
    <ModalContainer
      modal={MODALS.checkHTTPBody}
      onClose={modalData.onClose}
      customStyles={styles}
    >
      <div className="check_http_modal">
        <div className="check_http_modal__header">{formatMessage('View Data')}</div>
        <div className="check_http_modal__response-container">
          <div className="check_http_modal__json-view">
            {/* <ReactJson
              src={data}
              displayObjectSize={false}
              displayDataTypes={false}
              enableClipboard={false}
            /> */}
          </div>
          <div className="check_http_modal__container">
            <Button
              onClick={() => handleCopyData(data)}
              customClass="check_http_modal__copy-button"
            >
              {formatMessage('Copy')}
            </Button>
          </div>
        </div>
      </div>
    </ModalContainer>
  );
};
