import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getSelectedCompanySelector } from '@redux/companies/companiesSelectors';
import { audienceActions } from '@redux/audience/audienceActions';
import { broadcastActions } from '@redux/broadcast/broadcastActions';
import { PlusIcon } from '@icons/broadcast/plus-icon';
import { Popover } from '../../../../../ui-kit/components/Popover/Popover';

import { OptionsList } from './components/optionsList';
import { FilterModal } from './components/filterModal';
import { CustomFieldsFilterModal } from './components/customFieldsFilterModal';
import { SavedFilters } from './components/savedFilters';

import './filtersPopover.scss';

const filtersMenu = [
  { id: 0, label: 'Tag' },
  { id: 1, label: 'Sequence' },
  { id: 2, label: 'Phone' },
  { id: 3, label: 'First name' },
  { id: 4, label: 'Last name' },
  { id: 5, label: 'Full name' },
  { id: 6, label: 'Campaign' },
  { id: 7, label: 'Subscribed' },
  { id: 9, label: 'Phone code' },
  { id: 10, label: 'User ID' },
  { id: 11, label: 'Custom user field' },
  { id: 12, label: 'Assigned Manager' },
  { id: 13, label: 'Case' },
  { id: 14, label: 'Email' },
  { id: 15, label: 'CPF' },
];

export const FiltersPopover = (props) => {
  const [step, setStep] = useState('options');
  const [selectedFilter, setSelectedFilter] = useState({
    filterName: {},
    condition: {},
    value: {},
  });
  const [isOpen, setIsOpen] = useState(false);

  const company = useSelector(getSelectedCompanySelector);
  const dispatch = useDispatch();

  const onClose = () => {
    props.onClose();
    setStep('options');
  };

  const returnToStart = () => {
    setStep('options');
  };

  const updateSelectedFilter = (data, close = false) => {
    if (close) {
      props.broadcasts
        ? dispatch(broadcastActions.addFilterToBroadcast({ ...selectedFilter, ...data }))
        : dispatch(audienceActions.addFilter({ ...selectedFilter, ...data }));
      onClose();
    } else {
      setSelectedFilter({ ...selectedFilter, ...data });
    }
  };

  const handleToggle = () => {
    setIsOpen((prev) => !prev);
  };

  const planType = 1;
  return (
    <Popover
      renderTrigger={() => (
        <div
          className="broadcast-modal__body_right-panel_body_no-filters_button"
          onClick={(e) => handleToggle(e.currentTarget)}
        >
          <PlusIcon />
          <span>Add filter</span>
        </div>
      )}
      size={{
        width: (step !== 'options' && step !== 'savedFilters') ? step === 'Custom user field' ? 600 : 400 : 240,
        maxHeight: 510,
        height: step !== 'options' ? 400 : 'auto',
      }}
      controllableOpenState={{ isOpen, setIsOpen }}
    >
      {step === 'options' ? (
        <OptionsList
          planType={planType}
          options={filtersMenu}
          setStep={setStep}
          broadcasts={props.broadcasts}
        />
      ) : step !== 'Custom user field' ? (
        <FilterModal
          selectedFilter={selectedFilter}
          step={step}
          companyBot={company.bot}
          returnToStart={returnToStart}
          updateSelectedFilter={updateSelectedFilter}
        />
      ) : (
        <CustomFieldsFilterModal
          selectedFilter={selectedFilter}
          step={step}
          companyBot={company.bot}
          returnToStart={returnToStart}
          updateSelectedFilter={updateSelectedFilter}
        />
      )}
    </Popover>
  );
};
