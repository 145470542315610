import React from 'react';
import { useSelector } from 'react-redux';
import { saveAs } from 'file-saver';

import './CampaignQRModal.scss';

import { useInternationalization } from '@hooks/useTranslationHook';
import { ModalContainer } from '../../containers/Modal/ModalContainer';
import { MODALS } from '../../containers/Modal/modalConstants';
import { getModalDataSelector } from '../../containers/Modal/modalSelectors';
import Button from '@wabb-ui-kit/Button/Button.tsx';
import { EButtonSize } from '@wabb-ui-kit/Button/types/types.ts';

export const CampaignQRModal = () => {
  const { formatMessage } = useInternationalization();
  const { modalData } = useSelector(getModalDataSelector);

  const handleDownloadQRCode = () => {
    saveAs(modalData.qr, 'image.png');
    modalData.onClose();
  };

  const handleCloseQRCode = () => {
    modalData.onClose();
  };

  return (
    <ModalContainer modal={MODALS.campaignQR} onClose={modalData.onClose}>
      <div className="campaign-qr-code-modal">
        <div className="campaign-qr-code-modal__header">
          {formatMessage('Campaign QR-Code')}
        </div>
        <div className="campaign-qr-code-modal__hint">
          {formatMessage('Use this QR Code to launch your campaign ')}
        </div>
        <div>
          <img src={modalData.qr} width={250} height={250} alt="QR-Code" />
        </div>
        <div className="campaign-qr-code-modal__button">
          <Button
            onClick={handleCloseQRCode}
            type="secondary"
            style="tint"
            size={EButtonSize.L}
          >
            <span>{formatMessage('Cancel')}</span>
          </Button>
          <Button type="primary" size={EButtonSize.L} onClick={handleDownloadQRCode}>
            <span>{formatMessage('Download QR-Code')}</span>
          </Button>
        </div>
      </div>
    </ModalContainer>
  );
};
