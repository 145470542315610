import React, { useState, useRef, useEffect } from 'react';
import Button from '@wabb-ui-kit/Button/Button';
import { EButtonSize } from '@wabb-ui-kit/Button/types/types';
import useFilteredOptions from '@wabb-ui-kit/TagsDropdown/hooks/useFilteredOptions';
import TagsDisplay from '@wabb-ui-kit/TagsDropdown/TagsDisplay/TagsDisplay';
import SearchSelect from '@wabb-ui-kit/TagsDropdown/SearchSelect/SearchSelect';
import styles from './TagsDropdown.module.scss';

interface ITag {
  [key: string]: any;
}

interface TagsDropdownProps {
  options: ITag[];
  setOptions: React.Dispatch<React.SetStateAction<ITag[]>>;
  isCheckbox?: boolean;
  singleWithGroups?: boolean;
  single?: boolean;
  onAddClick?: (selectedTags: { [key: number]: boolean }) => void;
  withGroups?: boolean;
  isHeader?: boolean;
  headerTitle?: string;
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  handleChangeItem: () => void;
  labelName?: string;
  idName?: string;
}

const TagsDropdown: React.FC<TagsDropdownProps> = ({
  options,
  setOptions,
  isCheckbox = false,
  singleWithGroups = false,
  single = false,
  withGroups = false,
  onAddClick,
  isHeader = false,
  headerTitle,
  isOpen,
  setIsOpen,
  handleChangeItem,
  labelName = 'name',
  idName = 'id',
}) => {
  const [selectedTags, setSelectedTags] = useState<{ [key: number]: boolean }>({});
  const [searchTerm, setSearchTerm] = useState('');
  const ref = useRef<HTMLDivElement>(null);

  const handleCheckboxChange = (tagId: number, checked: boolean) => {
    setSelectedTags(prev => ({
      ...prev,
      [tagId]: checked,
    }));
  };

  const handleSingleSelect = (tagId: number) => {
    setSelectedTags({ [tagId]: true });
  };

  const handleCancelClick = () => {
    setSelectedTags({});
    setSearchTerm('');
    setIsOpen(false);
  };

  const handleAddTag = (newTagLabel: string) => {
    const newTag: ITag = {
      [idName]: Math.max(...options.map(tag => tag[idName])) + 1,
      [labelName]: newTagLabel,
    };

    setOptions(prev => [...prev, newTag]);
  };

  const filteredOptions = useFilteredOptions(options, searchTerm, labelName);

  const hasSelectedTags = Object.values(selectedTags).some(selected => selected);

  useEffect(() => {
    const handleClickOutside = event => {
      if (ref.current && !ref.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    if (isOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isOpen]);

  return (
    <div className={styles.dropdown} ref={ref}>
      {isOpen && (
        <>
          {isHeader && (
            <div className={styles.header}>
              <div className={styles.arrowIcon} />
              <div className={styles.headerTitle}>{headerTitle}</div>
            </div>
          )}
          <div className={styles.dropdownHeader}>
            <SearchSelect
              searchTerm={searchTerm}
              setSearchTerm={setSearchTerm}
              hasSelectedOptions={hasSelectedTags}
              isHeader={isHeader}
            />
          </div>
          <div className={styles.dropdownListContainer}>
            {filteredOptions.length === 0 ? (
              <div className={styles.noValues}>No values yet</div>
            ) : (
              filteredOptions.map(tag => (
                <TagsDisplay
                  key={tag[idName]}
                  tag={tag}
                  isCheckbox={isCheckbox}
                  selectedTags={selectedTags}
                  onCheckboxChange={handleCheckboxChange}
                  singleWithGroups={singleWithGroups}
                  single={single}
                  onSingleSelect={handleSingleSelect}
                  withGroups={withGroups}
                  onAddTag={handleAddTag}
                  handleChangeItem={handleChangeItem}
                  setIsOpen={setIsOpen}
                  labelName={labelName}
                  idName={idName}
                />
              ))
            )}
            {hasSelectedTags && (
              <div className={styles.buttonsWrapper}>
                <Button
                  onClick={handleCancelClick}
                  type="secondary"
                  style="tint"
                  size={EButtonSize.S}
                >
                  Cancel
                </Button>
                <Button
                  type="primary"
                  style="filled"
                  size={EButtonSize.S}
                  onClick={() => onAddClick}
                >
                  Add
                </Button>
              </div>
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default TagsDropdown;
