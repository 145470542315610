import React, { useMemo } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import classNames from 'classnames';
import styles from './NavigationMenuLink.module.scss';
import { IPageItem } from '@wabb-ui-kit/NavigationMenu/types';

interface INavigationMenuLinkProps {
  isOpen: boolean;
  pathname: string;
  permission?: string | string[];
  item: IPageItem;
}

const NavigationMenuLink = ({ isOpen, pathname, item }: INavigationMenuLinkProps) => {
  const history = useHistory();
  const location = useLocation();

  const handleClick = () => {
    history.push(pathname);
  };

  const isActive = useMemo(() => {
    return location.pathname.startsWith(pathname);
  }, [location.pathname, pathname]);

  const classes = classNames(styles.listItem, {
    [styles.open]: isOpen,
    [styles.active]: isActive,
  });

  return (
    <li className={classes} onClick={handleClick}>
      {!!item?.icon && (
        <span className={styles.icon}>
          <img src={item.icon} />
        </span>
      )}
      <span className={styles.label}>{item.label}</span>
    </li>
  );
};

export default NavigationMenuLink;
