import React from 'react';

export const RubbishIcon = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g id="Delete-one">
      <g id="Vector">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M3.5 6.30769C3.5 5.91472 3.81713 5.59615 4.20833 5.59615H19.7917C20.1829 5.59615 20.5 5.91472 20.5 6.30769C20.5 6.70066 20.1829 7.01923 19.7917 7.01923H4.20833C3.81713 7.01923 3.5 6.70066 3.5 6.30769Z"
          fill="#D92D20"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M9.875 9.86538C10.2662 9.86538 10.5833 10.184 10.5833 10.5769V16.2692C10.5833 16.6622 10.2662 16.9808 9.875 16.9808C9.4838 16.9808 9.16667 16.6622 9.16667 16.2692V10.5769C9.16667 10.184 9.4838 9.86538 9.875 9.86538Z"
          fill="#D92D20"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M14.125 9.86538C14.5162 9.86538 14.8333 10.184 14.8333 10.5769V16.2692C14.8333 16.6622 14.5162 16.9808 14.125 16.9808C13.7338 16.9808 13.4167 16.6622 13.4167 16.2692V10.5769C13.4167 10.184 13.7338 9.86538 14.125 9.86538Z"
          fill="#D92D20"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M5.625 5.59615C6.0162 5.59615 6.33333 5.91472 6.33333 6.30769V19.8269H17.6667V6.30769C17.6667 5.91472 17.9838 5.59615 18.375 5.59615C18.7662 5.59615 19.0833 5.91472 19.0833 6.30769V19.8269C19.0833 20.2043 18.9341 20.5663 18.6684 20.8332C18.4027 21.1001 18.0424 21.25 17.6667 21.25H6.33333C5.95761 21.25 5.59728 21.1001 5.3316 20.8332C5.06592 20.5663 4.91667 20.2043 4.91667 19.8269V6.30769C4.91667 5.91472 5.2338 5.59615 5.625 5.59615Z"
          fill="#D92D20"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M8.3724 3.37521C8.77091 2.9749 9.31141 2.75 9.875 2.75H14.125C14.6886 2.75 15.2291 2.9749 15.6276 3.37521C16.0261 3.77553 16.25 4.31848 16.25 4.88462V6.30769C16.25 6.70066 15.9329 7.01923 15.5417 7.01923C15.1505 7.01923 14.8333 6.70066 14.8333 6.30769V4.88462C14.8333 4.6959 14.7587 4.51492 14.6259 4.38148C14.493 4.24804 14.3129 4.17308 14.125 4.17308H9.875C9.68714 4.17308 9.50697 4.24804 9.37413 4.38148C9.24129 4.51492 9.16667 4.6959 9.16667 4.88462V6.30769C9.16667 6.70066 8.84953 7.01923 8.45833 7.01923C8.06713 7.01923 7.75 6.70066 7.75 6.30769V4.88462C7.75 4.31848 7.97388 3.77553 8.3724 3.37521Z"
          fill="#D92D20"
        />
      </g>
    </g>
  </svg>
);
