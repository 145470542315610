import React from 'react';
import ClipLoader from 'react-spinners/ClipLoader';
import { useInternationalization } from '../../hooks/useTranslationHook';

import './LoadingScreen.scss';

const styles = {
  top: '-60px',
  right: '-27px',
  position: 'absolute',
};

export const LoadingScreen = logo => {
  const { formatMessage } = useInternationalization();
  return (
    <div className="loading-screen__container">
      <div className="loading-screen__loader-container">
        <div className="loading-screen__loader-content">
          <div style={{ position: 'relative' }}>
            <ClipLoader size={60} color="#5925dc" loading css={styles} />
          </div>
          <div className="loading-screen__text">
            {formatMessage('Loading content')}
            ...
          </div>
        </div>
      </div>
      <div className="loading-screen__image-container">
        {/* <div
          className="loading-screen__image-container__image"
          style={{ backgroundImage: `url(${logo ? logo : BotConversaImage}`}}
        ></div> */}
      </div>
    </div>
  );
};
