import api from './baseApi';

export const dashboardSettings = {
  getDashboardTimePeriod: (id, startDate, endDate, graphType, tagId) =>
    api.get(
      `/bot/${id}/graph/?start_time_date=${startDate}&end_time_date=${endDate}&graph_type=${graphType}&tag_id=${tagId}`,
    ), //new_contacts - 0
  // total_unique_chats - 1
  // tags - 2
  // closed_conversations - 3
  // opened_conversations - 4

  getDashboardStatus: id => api.get(`/bot/${id}/statistic/`),
};
