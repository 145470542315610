import * as FileSaver from 'file-saver';
import { all, delay, put, takeLatest } from 'redux-saga/effects';

import { settingsApi } from '../../api/settingsApi';
import { customFieldsActions } from './customFieldsActions';
import { filesApi } from '../../api/filesApi';
import { notificationWindowActions } from '../notificationWindow/notificationWindowActions';
import {
  DOWNLOAD_REPORT_CUSTOM_FIELDS_REQUEST,
  DOWNLOAD_PREPARED_FILE_CUSTOM_FIELDS,
} from './customFieldsConstants';

function* downloadBotFieldsSaga(data) {
  try {
    const botId = data.payload;
    const response = yield settingsApi.getBotVariablesExport(botId);

    if (response) {
      yield put(customFieldsActions.downloadPreparedBotFields(response));
    }
  } catch (err) {
    yield put(customFieldsActions.addOrUpdateReportBotFields(true));
  }
}

function* downloadPreparedFileSaga(data) {
  try {
    const response = yield filesApi.checkStatusFile(data.payload.file_id);

    if (response.data.status === 'pending') {
      yield put(
        customFieldsActions.updateDownloadStatusReportBotFields({
          rowsProcessed: response.data.rows_processed,
          maxLines: response.data.max_lines,
        }),
      );
      yield delay(2000);
      yield put(customFieldsActions.downloadPreparedBotFields(response.data));
    } else if (response.data.status === 'long-running') {
      yield put(
        notificationWindowActions.openWindow(true, {
          label: 'Warning!',
          type: 'error',
          text: 'Something went wrong with your file , please contact support or try again',
        }),
      );
      yield put(
        customFieldsActions.updateDownloadStatusReportBotFields({
          rowsProcessed: 0,
          maxLines: 1,
        }),
      );
      yield put(customFieldsActions.addOrUpdateReportBotFields(true));
    } else {
      const fileBits = yield filesApi.downloadPreparedFile(data.payload.file_id);
      const file = new File([fileBits.data], 'bot_variables.xlsx', {
        type: 'application/ms-excel',
        header: 'Content-Disposition: attachment; filename=campaigns.xlsx',
      });
      FileSaver.saveAs(file);
      yield put(customFieldsActions.addOrUpdateReportBotFields(true));
      yield put(
        customFieldsActions.updateDownloadStatusReportBotFields({
          rowsProcessed: 0,
          maxLines: 1,
        }),
      );
    }
  } catch (err) {
    yield put(customFieldsActions.addOrUpdateReportBotFields(true));
  }
}

function* sagas() {
  yield all([
    takeLatest(DOWNLOAD_REPORT_CUSTOM_FIELDS_REQUEST, downloadBotFieldsSaga),
    takeLatest(DOWNLOAD_PREPARED_FILE_CUSTOM_FIELDS, downloadPreparedFileSaga),
  ]);
}

export const customFieldsSagas = sagas;
