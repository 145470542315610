import {
  ACTIVATE_ACCOUNT,
  ADD_ERROR_MESSAGE,
  ADD_YOUR_COMPANY,
  AUTH,
  CLEAN_ERROR_MESSAGE,
  IS_EMAIL_REGISTRATION_IN_PROGRESS,
  LOGOUT,
  LOGOUT_SUCCESS,
  REDIRECT_SUCCESS,
  REDIRECT_TO_NEXT_PAGE,
  REG,
  REG_FAILURE,
  REG_SUCCESS,
  RESET_PASSWORD,
  SEND_USER_INFORMATION,
  SET_AUTH_DATA,
  SET_USER_ID,
  SET_TWO_FA_DATA,
  HIDE_RESEND_EMAIL_CONFIRMATION,
  SHOW_RESEND_EMAIL_CONFIRMATION,
  SET_RESET_PASSWORD_DATA,
  SET_FRANCHISE_PERMISSIONS_DATA,
  UPDATE_TOKEN,
} from './authConstants';

export const authActions = {
  auth(
    email,
    password,
    franchise_code,
    history,
    location,
    supportEmail,
    addToast,
    SMSResponse,
  ) {
    return {
      type: AUTH,
      payload: {
        email,
        password,
        franchise_code,
        history,
        location,
        supportEmail,
        addToast,
        SMSResponse,
      },
    };
  },

  reg(last_name, first_name, phone, password, email, invite_code) {
    return {
      type: REG,
      payload: {
        last_name,
        first_name,
        phone,
        email,
        password,
        invite_code,
      },
    };
  },

  redirectToNextPage(route) {
    return {
      type: REDIRECT_TO_NEXT_PAGE,
      payload: {
        route,
      },
    };
  },

  redirectSuccess() {
    return {
      type: REDIRECT_SUCCESS,
    };
  },

  newReg(email, code, addToast) {
    return {
      type: REG,
      payload: {
        email,
        code,
        addToast,
      },
    };
  },

  addConfirmationCode(email, confirmationCode) {
    return {
      type: ACTIVATE_ACCOUNT,
      payload: {
        email,
        confirmationCode,
      },
    };
  },

  sendUserInformation({
    userEmail,
    confirmationCode,
    firstName,
    lastName,
    password,
    phone,
  }) {
    return {
      type: SEND_USER_INFORMATION,
      payload: {
        email: userEmail,
        confirmationCode,
        firstName,
        lastName,
        password,
        phone,
      },
    };
  },

  callErrorMessage(errorMessage) {
    return {
      type: ADD_ERROR_MESSAGE,
      payload: {
        errorMessage,
      },
    };
  },

  cleanErrorMessage() {
    return {
      type: CLEAN_ERROR_MESSAGE,
    };
  },
  showResendEmailConfirmation() {
    return {
      type: SHOW_RESEND_EMAIL_CONFIRMATION,
    };
  },

  hideResendEmailConfirmation() {
    return {
      type: HIDE_RESEND_EMAIL_CONFIRMATION,
    };
  },

  setAuthData(
    token,
    userId,
    is_franchise_owner,
    is_email_in_progress,
    userType,
    fullName,
  ) {
    return {
      type: SET_AUTH_DATA,
      payload: {
        token,
        userId,
        is_franchise_owner,
        is_email_in_progress,
        userType,
        fullName,
      },
    };
  },

  updateToken(token) {
    return {
      type: UPDATE_TOKEN,
      payload: { token },
    };
  },

  setTwoFaData(twoFaUserId, sessionCode) {
    return {
      type: SET_TWO_FA_DATA,
      payload: {
        twoFaUserId,
        sessionCode,
      },
    };
  },

  addYourCompany(name, addServerToBot) {
    return {
      type: ADD_YOUR_COMPANY,
      payload: {
        name,
        addServerToBot,
      },
    };
  },

  logOut() {
    return {
      type: LOGOUT,
    };
  },

  logOutSuccess() {
    return {
      type: LOGOUT_SUCCESS,
    };
  },

  setUserId(userId) {
    return {
      type: SET_USER_ID,
      payload: userId,
    };
  },

  isEmailRegistrationInProgress(data) {
    return {
      type: IS_EMAIL_REGISTRATION_IN_PROGRESS,
      payload: data,
    };
  },

  resetPassword(email) {
    return {
      type: RESET_PASSWORD,
      payload: { email },
    };
  },

  regSuccess() {
    return {
      type: REG_SUCCESS,
    };
  },

  regFailure() {
    return {
      type: REG_FAILURE,
    };
  },

  setResetPasswordData(data) {
    return {
      type: SET_RESET_PASSWORD_DATA,
      payload: data,
    };
  },

  setFranchisePermissionsData(franchisePermissionsData) {
    return {
      type: SET_FRANCHISE_PERMISSIONS_DATA,
      payload: franchisePermissionsData,
    };
  },
};
