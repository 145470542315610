import React, { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import cn from 'classnames';
import PropTypes from 'prop-types';
import FadeLoader from 'react-spinners/FadeLoader';

import { bcSplitTableActions } from '@redux/bcSplitTable/bcSplitTableActions';
import { getSelectedCompanySelector } from '@redux/companies/companiesSelectors';
import {
  getBcSplitTableItemsNameSelector,
  getBcSplitTableItemsSelector,
  getBcSplitTableLoadedSelector,
  getBcSplitTableNextSelector,
  getBcSplitTablePageSelector,
  getSelectedAllModeSelector,
} from '@redux/bcSplitTable/bcSplitTableSelectors';
import { useInternationalization } from '@hooks/useTranslationHook';
import { BCInfiniteScroll } from '@components/common/BCInfiniteScroll/BCInfiniteScroll';
import { modalActions } from '@components/containers/Modal/modalActions';
import CheckBox from '../../../wabb-ui-kit/CheckBox/CheckBox';
import { SortIcon } from '../../../icons/sort-icon';
import { WarningWidget } from '@components/common/WarningWidget/WarningWidget';

import './SplitTable.scss';

export const BcSplitTable = ({
  gridColumns,
  columns,
  Component,
  getItemsApi,
  createItemApi,
  deleteItemApi,
  customStyle,
  sourceItemsName,
  EmptyComponent,
  withoutCheckbox,
  sourceItems,
  inverse,
  deleteItemCatchFunction,
  sourceSearchName,
  sequence,
  isPendingGetItems,
  ...props
}) => {
  const style = { gridTemplateColumns: gridColumns };

  const { formatMessage } = useInternationalization();

  const history = useHistory();
  const dispatch = useDispatch();

  const botId = useSelector(getSelectedCompanySelector)?.bot;
  const items = useSelector(getBcSplitTableItemsSelector);
  const loaded = useSelector(getBcSplitTableLoadedSelector);
  const selectedAllMode = useSelector(getSelectedAllModeSelector);
  const itemsName = useSelector(getBcSplitTableItemsNameSelector);
  const page = useSelector(getBcSplitTablePageSelector);
  const next = useSelector(getBcSplitTableNextSelector);

  const searchName =
    sourceSearchName || new URLSearchParams(history.location.search).get('search_name');

  useEffect(() => {
    dispatch(bcSplitTableActions.setItemsPage(1));

    if (searchName && getItemsApi) {
      dispatch(
        bcSplitTableActions.getItemsRequest({
          botId,
          searchName,
          getItemsApi,
          page: 1,
        }),
      );
    } else if (getItemsApi) {
      dispatch(bcSplitTableActions.getItemsRequest({ botId, getItemsApi, page: 1 }));
    } else {
      dispatch(bcSplitTableActions.getItemsSuccess({ results: sourceItems }));
    }

    dispatch(bcSplitTableActions.setItemsName(sourceItemsName));
  }, [botId, searchName, sourceItems, sourceItemsName]);

  useEffect(() => {
    dispatch(bcSplitTableActions.clearCheckBoxes());
  }, [sourceItemsName]);

  const getNextPageOfItems = () => {
    if (searchName) {
      dispatch(
        bcSplitTableActions.getItemsNextRequest({
          botId,
          searchName,
          getItemsApi,
          page,
        }),
      );
    } else {
      dispatch(bcSplitTableActions.getItemsNextRequest({ botId, getItemsApi, page }));
    }
  };

  const handleSelectAllItems = () => {
    dispatch(bcSplitTableActions.toggleSelectAll(!selectedAllMode));
  };

  const handleChangeSelectModeItem = id => {
    dispatch(bcSplitTableActions.changeItemSelectMode(id));
  };

  const deleteItemFunction = useCallback(
    (id, deleteName) => {
      const name = sourceItemsName.toLowerCase().slice(0, -1);
      dispatch(
        modalActions.openConfirmModal({
          title: formatMessage(`Delete ${name}`),
          text: formatMessage(`Are you sure you want to delete ${name} "${deleteName}"?`),
          confirmButtonText: formatMessage(`Delete`),
          type: 'delete',
          handleConfirm: () => {
            dispatch(
              bcSplitTableActions.deleteItemRequest({
                deleteItemApi,
                id,
                deleteItemCatchFunction,
              }),
            );
            dispatch(modalActions.closeModal());
          },
          onClose: () => {
            dispatch(modalActions.closeModal());
          },
        }),
      );
    },
    [deleteItemApi, sourceItemsName],
  );

  const confirmCreateFunction = value => {
    dispatch(
      bcSplitTableActions.createNewItemRequest({
        createItemApi,
        data: {
          name: value,
          botId,
        },
      }),
    );
  };
  

  return (
    sourceItemsName === itemsName && (
      <div className="bc-table" id="#BcSplitTable">
        {loaded && items.length > 0 ? (
          <>
            <div
              className={cn('bc-table__header', inverse && 'bc-table__header_inverse')}
              style={style}
            >
              {!withoutCheckbox && !sequence  && (
                <div className="bc-table__header__checkbox">
                  <CheckBox
                    type="checkbox"
                    isChecked={selectedAllMode}
                    onChange={handleSelectAllItems}
                  />
                </div>
              )}
              {columns.map((column, index) => (
                <div className="bc-table__header__name" {...column} key={index}>
                  <span>{column.name}</span>
                  {/* {column.name && (
                    <div className="bc-table__header__sort">
                      <SortIcon />
                    </div>
                  )} */}
                </div>
              ))}
            </div>
            <div className="bc-table__body">
              <BCInfiniteScroll
                loadMore={getNextPageOfItems}
                next={next}
                root={document.getElementById('#BcSplitTable')}
                rootMargin="0px"
                threshold={0.5}
                backToTop={page === 2}
                isPendingGetItems={isPendingGetItems}
              >
                {items.map(item => (
                  <Component
                    item={item}
                    style={{ ...style, ...customStyle }}
                    className={cn('bc-table__item', inverse && 'bc-table__item_inverse')}
                    {...props}
                    key={item.id}
                    deleteItemFunction={deleteItemFunction}
                  >
                    {!withoutCheckbox && (
                      <div className="bc-table__checkbox item__cell">
                        <CheckBox
                          type="checkbox"
                          isChecked={item.checked}
                          onChange={() => handleChangeSelectModeItem(item.id)}
                        />
                      </div>
                    )}
                  </Component>
                ))}
              </BCInfiniteScroll>
            </div>
          </>
        ) : !loaded ? (
          <div className="bc-table__loading">
            <FadeLoader size={150} color="#5925dc" loading />
          </div>
        ) : (
          <div className="bc-table__no_data">
            {!!searchName && items.length < 1 ? (
              <WarningWidget
                title="No results found"
                description={<>You searched for "{searchName}"</>}
              />
            ) : (
              <EmptyComponent />
            )}
          </div>
        )}
      </div>
    )
  );
};

BcSplitTable.propTypes = {
  inverse: PropTypes.bool,
};