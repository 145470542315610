import * as React from 'react';

export const InfoIcon = props => (
  <svg
    width={props.width ? props.width : 18}
    height={props.height ? props.height : 18}
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9 16.5C13.1421 16.5 16.5 13.1421 16.5 9C16.5 4.85786 13.1421 1.5 9 1.5C4.85786 1.5 1.5 4.85786 1.5 9C1.5 13.1421 4.85786 16.5 9 16.5Z"
      stroke={props.color ? props.color : '#29292F'}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M9 6V9"
      stroke={props.color ? props.color : '#29292F'}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <circle cx="9" cy="12" r="0.75" fill={props.color ? props.color : '#29292F'} />
  </svg>
);
