import React, { useEffect, useState } from 'react';
import cn from 'classnames';
import { useContextSelector } from 'use-context-selector';
import { Popover } from '@ui-kit';
import { useSelector } from 'react-redux';
import Button from '@wabb-ui-kit/Button/Button';
import { DotsIcon } from '@wabb-assets/svg/Button/dots-icon';
import { ChatIcon } from '@wabb-assets/svg/Button/chat-icon';
import { EIconPosition } from '@wabb-ui-kit/Button/types/types';
import { ContactDetailItem } from '@components/common/UserCardComponents/UserCardInfo/ContactDetailItem/ContactDetailItem';
import { useInternationalization } from '@hooks/useTranslationHook';
import { UserCardInfoHeader } from '@components/common/UserCardComponents/UserCardInfo/UserCardInfoHeader/UserCardInfoHeader';
import TagsDropdown from '@wabb-ui-kit/TagsDropdown/TagsDropdown';
import { flowsApi } from '@api/flowsApi';
import { audienceApi } from '@api/audience';
import { getSelectedCompanySelector } from '@redux/companies/companiesSelectors';
import { ContactEmailIcon, ContactMobilePhoneIcon } from '@icons/audience';
import { ToastContext } from '../../../../contexts/toastsContext';
import './UserCardInfo.scss';

export const UserCardInfo = ({
  data,
  updateUserCard,
  onClickChat,
  isLiveChat = true,
  onDelete,
  id,
  isContacts,
}) => {
  const { formatMessage } = useInternationalization();
  const addToast = useContextSelector(ToastContext, ({ add }) => add);
  const botId = useSelector(getSelectedCompanySelector).bot;
  const [flows, setFlows] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [isOpenFirstPopover, setIsOpenFirstPopover] = useState(false);
  const [popoverTop, setPopoverTop] = useState(0);

  const invalidEmailNotification = () => {
    addToast({ type: 'error', title: 'Invalid email', message: 'Invalid email' });
  };

  useEffect(() => {
    flowsApi.getFlowsShort(botId).then(res => {
      setFlows(res.flowList);
    });

    const updatePopoverPosition = () => {
      const windowHeight = window.innerHeight;
      const calculatedTop = windowHeight * 0.6;
      setPopoverTop(calculatedTop);
    };

    updatePopoverPosition();
    window.addEventListener('resize', updatePopoverPosition);

    return () => {
      window.removeEventListener('resize', updatePopoverPosition);
    };
  }, []);

  const handleSendFlow = flow => {
    audienceApi
      .sendFlow(flow.id, id, botId)
      .then(() =>
        addToast({
          type: 'success',
          title: 'Flow sent',
          message: 'Flow sent',
        }),
      )
      .catch(() =>
        addToast({
          type: 'error',
          title: "Flow didn't send",
          message: "Flow didn't send",
        }),
      );
    setIsOpenFirstPopover(false);
  };

  return (
    <div
      className={cn('user-card-info', {
        'user-card-info--contacts': isContacts,
      })}
    >
      <div className="user-card-info__container">
        {isContacts && <UserCardInfoHeader data={data} updateUserCard={updateUserCard} />}
        {isLiveChat && (
          <div>
            <div className="user-card-info__buttons">
              <Button
                onClick={onClickChat}
                icon={<ChatIcon />}
                iconPosition={EIconPosition.LEFT}
              >
                <div>{formatMessage('Go to chat')} </div>
              </Button>
              <Popover
                controllableOpenState={{
                  isOpen: isOpenFirstPopover,
                  setIsOpen: setIsOpenFirstPopover,
                }}
                size={{ width: 'auto' }}
                renderTrigger={() => (
                  <div>
                    <Button
                      type="secondary"
                      style="outlined"
                      className="user-card-info__right-icon"
                      onClick={() => setIsOpenFirstPopover(true)}
                    >
                      <DotsIcon />
                    </Button>
                  </div>
                )}
              >
                <div className="user-card-info__popover">
                  <div
                    onClick={() => setIsOpen(!isOpen)}
                    className="user-card-info__popover-item"
                  >
                    {formatMessage('Send Flow')}
                  </div>
                  {onDelete ? (
                    <div
                      onClick={() => onDelete(id)}
                      id="audience-options-popover"
                      className="user-card-info__popover-delete"
                    >
                      {formatMessage('Delete')}
                    </div>
                  ) : null}
                  {isOpen && (
                    <div className="user-card-info__inside-popover" role="presentation">
                      <div
                        className="user-card-info__inside-popover-wrapper"
                        style={{ top: `${popoverTop}px` }}
                      >
                        <TagsDropdown
                          options={flows}
                          setOptions={setFlows}
                          isOpen={isOpen}
                          setIsOpen={setIsOpen}
                          handleChangeItem={handleSendFlow}
                          labelName="name"
                          idName="id"
                        />
                      </div>
                    </div>
                  )}
                </div>
              </Popover>
            </div>
          </div>
        )}
        <div
          className={cn('user-card-info__data', {
            'user-card-info__data--contacts': isContacts,
          })}
        >
          <div className="user-card-info__data-info">Information</div>
          <div className="user-card-info__data__container">
            <ContactDetailItem
              icon={<ContactMobilePhoneIcon />}
              name="Note"
              field={data.note}
              updateUserCard={updateUserCard}
            />
            <ContactDetailItem
              icon={<ContactMobilePhoneIcon />}
              name="Phone"
              field={data.phone}
            />
            <ContactDetailItem
              icon={<ContactEmailIcon />}
              name="Email"
              field={data.email}
              updateUserCard={updateUserCard}
              invalidEmailNotification={invalidEmailNotification}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
