import React from 'react';

export const ProgressComponent = ({ diameter, progress, color = '#1187F4' }) => (
  <svg width={diameter} height={diameter} id="svg" viewBox="0 0 200 200">
    <circle
      r="80"
      cx="100"
      cy="100"
      fill="none"
      strokeWidth="12"
      stroke="#ccc"
      strokeDasharray="500 500"
      strokeLinecap="round"
    />
    <circle
      r="80"
      cx="100"
      cy="100"
      fill="none"
      strokeWidth="12"
      stroke={color}
      strokeDashoffset="0"
      strokeDasharray={`${progress * 5} 500`}
      strokeLinecap="round"
    />
    <text
      x="100"
      y="125"
      fontFamily="Arial"
      fontSize="70"
      textAnchor="middle"
      fill="#444"
    >
      {progress}
    </text>
  </svg>
);
