import { all, takeLatest, put, delay } from 'redux-saga/effects';

import { audienceApi } from '@api/audience';
import { botAdminsApi } from '@api/botAdminsApi';
import { audienceActions } from './audienceActions';
import { AudienceTypes } from './types';


function* getUsersRequestSaga(action) {
  try {
    const { payload } = action;
    const response = yield audienceApi.getAudienceUsers(
      payload.botId,
      payload.cursor,
      payload.queryString,
    );  
    if (response) {
      yield put(audienceActions.getUsersSuccess(response));
    }
  } catch (e) {}
}
function* getNextPageOfUsersRequestSaga(action) {
  try {
    const { payload } = action;
    const response = yield audienceApi.getAudienceUsers(
      payload.botId,
      payload.cursor,
      payload.queryString,
    );
    if (response) {
      yield put(audienceActions.getNextPageOfUsersSuccess(response));
    }
  } catch (err) {}
}

function* getStatsRequestSaga(action) {
  try {
    const { payload } = action;
    const response = yield audienceApi.getStatistics(payload.botId);

    if (response) {
      yield put(audienceActions.getStatsRequestLoaded(response));
    }
  } catch (err) {}
}

function* getAdminsRequestSaga(action) {
  try {
    const { payload } = action;

    const response = yield botAdminsApi.getBotAdmins(payload.botId, false);
    if (response) {
      yield put(audienceActions.getAdminsRequestLoaded(response.updatedUser));
    }
  } catch (err) {}
}

function* getBulkActionsOperationsStatus(action) {
  try {
    yield delay(2000);
    const response = yield audienceApi.getBulkActionsStatus(action.payload.data.id);
    yield put(audienceActions.updateBulkActionsStatus(response.data.progress));

    if (response.data.progress < 100) {
      yield put(
        audienceActions.getBulkActionsStatus({
          data: response.data,
          endBulkActionOperation: action.payload.endBulkActionOperation,
        }),
      );
    } else {
      action.payload.endBulkActionOperation(true);
      yield put(audienceActions.updateBulkActionsStatus(0));
    }
  } catch (err) {
    action.payload.endBulkActionOperation(false);
  }
}

function* deleteUserRequestSaga(action) {
  try {
    const { payload } = action;
    const response = yield audienceApi.deleteUser(payload.id, payload.botId);

    if (response) {
      yield put(audienceActions.deleteUser({ id: payload.id }));
    }
  } catch (e) {}
}

function* sagas() {
  yield all([
    takeLatest(AudienceTypes.GET_USERS_REQUEST, getUsersRequestSaga),
    takeLatest(AudienceTypes.GET_USERS_NEXT_REQUEST, getNextPageOfUsersRequestSaga),
    takeLatest(AudienceTypes.GET_STATS, getStatsRequestSaga),
    takeLatest(AudienceTypes.GET_ADMINS, getAdminsRequestSaga),
    takeLatest(AudienceTypes.GET_BULK_ACTIONS_STATUS, getBulkActionsOperationsStatus),
    takeLatest(AudienceTypes.DELETE_USER_REQUEST, deleteUserRequestSaga),
  ]);
}

export const audienceSagas = sagas;
