import { botAdminsApiRPC } from '@connectApi/baseApi';


export const botAdminsApi = {
  getBotAdmins: (botId, fromUserCard) =>
    botAdminsApiRPC.botAdminsView({
      botId,
      from_user_card: fromUserCard ? true : '',
    }),
};
