import React from 'react';
import { useSelector } from 'react-redux';

import { useInternationalization } from '@hooks/useTranslationHook';
import { ModalContainer } from '../../containers/Modal/ModalContainer';
import { MODALS } from '../../containers/Modal/modalConstants';
import { getModalDataSelector } from '../../containers/Modal/modalSelectors';
import { Button as BcButton } from '../../common/Button';

import '../modalsComponent.scss';
import './contactNotExistModal.scss';

export const ContactNotExistModal = () => {
  const { formatMessage } = useInternationalization();
  const { modalData } = useSelector(getModalDataSelector);

  return (
    <ModalContainer modal={MODALS.contactNotExist} onClose={modalData.onClose}>
      <div className="contact-not-exist-modal">
        <div className="contact-not-exist-modal__content">
          <div className="campaigns-modal__form-group">
            {formatMessage('Contact doesn`t exist in WhatsApp')}
          </div>
        </div>
        <div className="contact-not-exist-modal__button-group">
          {' '}
          <div>
            <BcButton color="green" onClick={modalData.onClose}>
              {formatMessage('Okay')}
            </BcButton>
          </div>
        </div>
      </div>
    </ModalContainer>
  );
};
