import React from 'react';

import { JsonPreview } from '../jsonPreview/jsonPreview';

import './requestBody.scss';

export const RequestBody = props => (
  <div className="request-body">
    <div className="request-body__preview">
      <JsonPreview fields={props.fields} form={props.form} setForm={props.setForm} />
    </div>
  </div>
);
