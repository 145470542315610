import React from 'react';

export const LongArrowIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="18"
    height="13"
    viewBox="0 0 18 13"
    fill="none"
  >
    <path
      d="M0.298247 5.75172C0.0994543 5.95318 -2.50974e-07 6.21227 -2.62897e-07 6.48561C-2.74826e-07 6.75905 0.0994467 7.01803 0.298247 7.2195L5.21504 12.1978C5.61263 12.6007 6.2516 12.6007 6.6492 12.1978C7.04679 11.7949 7.04679 11.1473 6.6492 10.7444L3.47899 7.53611L16.9776 7.53611C17.5456 7.53611 18 7.07557 18 6.5C18 5.92443 17.5456 5.46389 16.9776 5.46389L3.47899 5.46389L6.6492 2.25561C7.04679 1.85268 7.04679 1.20513 6.6492 0.8022C6.2516 0.399267 5.61263 0.399267 5.21504 0.8022L0.298247 5.75172Z"
      fill="#464F55"
    />
  </svg>
);
