import { all, takeLatest, put, fork } from 'redux-saga/effects';
import { modalActions } from '@components/containers/Modal/modalActions';
import { tagTypesApi } from '@api/tagTypesApi';
import { constructorApi } from '@api/constructorApi';
import { tagTypesActions } from './tagTypesActions';
import { constructorActions } from '../constructor/constructorActions';
import { GET_TAG_TYPES_REQUEST, CREATE_TAG_TYPES_REQUEST } from './tagTypesConstants';

function* getTagTypesRequestSaga(action, tagId) {
  try {
    const { companyBot, handleSelectTagEvent } = action.payload;
    const response = yield tagTypesApi.getTagTypesShort(companyBot);

    if (response.tagTypes) {
      yield put(tagTypesActions.getTagTypesSuccess(response.tagTypes));

      if (handleSelectTagEvent) {
        yield fork(handleSelectedTagSaga, action, tagId);
      }
    }
  } catch (err) {}
}

function* createTagTypesRequestSaga(action) {
  try {
    const { companyBot, tagTypesName } = action.payload;
    const response = yield tagTypesApi.createTagTypes({
      name: tagTypesName,
      botId: companyBot,
    });

    if (response) {
      yield put(modalActions.closeModal());
      yield fork(getTagTypesRequestSaga, action, response.id);
    }
  } catch (err) {
    if (err.response?.status === 400) {
      yield put(tagTypesActions.createTagTypesError('Tag with this name already exist'));
    }
  }
}

function* handleSelectedTagSaga(action, tagId) {
  try {
    yield put(constructorActions.loadInProgress());
    const { handleSelectTagEvent } = action.payload;
    const response = yield constructorApi.setTagVariable(handleSelectTagEvent, tagId);

    if (response.data) {
      yield put(
        constructorActions.updateActionBlock({
          ...response.data,
          type: 'tag',
        }),
      );
      yield put(constructorActions.loadSuccess());
    }
  } catch (err) {
    console.log('err', err);
    yield put(constructorActions.loadFailure());
  }
}

function* sagas() {
  yield all([takeLatest(GET_TAG_TYPES_REQUEST, getTagTypesRequestSaga)]);
  yield all([takeLatest(CREATE_TAG_TYPES_REQUEST, createTagTypesRequestSaga)]);
}

export const tagTypesSagas = sagas;
