import api from './baseApi';
import { adsSourceApiRPC } from '@connectApi/baseApi';
import {
  AdsSourceListRequest,
  AdsSourceByBotId,
  AdsSourceCreateRequest,
  AdsSourceDetailPatchRequest,
} from 'wabb-backend-api/build/es/ads_sources/ads_sources_pb';

export const adsSourceApi = {
  getAdsSources: ({ botId, searchName }) => {
    return adsSourceApiRPC.adsSourceList(
      AdsSourceListRequest.fromJson({
        bot_id: botId,
        search_name: searchName ? searchName : '',
      }),
    );
  },

  getAdsSourcesShort: botId => adsSourceApiRPC.adsSourceShortList({ botId }),

  createAdsSource: data => {
    const { name, text, bot, share_type, toFlow } = data;

    return adsSourceApiRPC.adsSourceCreate(
      AdsSourceCreateRequest.fromJson({ bot, name, share_type, text, toFlow }),
    );
  },

  deleteAdsSource: campaignId =>
    adsSourceApiRPC.adsSourceDetailDelete({ pk: campaignId }),

  updateAdsSource: data => {
    const { name, text, shareType, campaignId, toFlow } = data;
    return adsSourceApiRPC.adsSourceDetailPatch(
      AdsSourceDetailPatchRequest.fromJson({
        name,
        pk: campaignId,
        shareType,
        text,
        toFlow,
      }),
    );
  },

  downloadAdsSources: bot_id =>
    adsSourceApiRPC.adsSourceExport(AdsSourceByBotId.fromJson({ bot_id: bot_id })),
};
