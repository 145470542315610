import React from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import { chatActions } from '@redux/liveChat/chatActions';
import { notificationsActions } from '@redux/notifications/notificationsActions';
import { getChatSelector } from '@redux/liveChat/chatSelector';
import { getNotificationsSelector } from '@redux/notifications/notificationsSelector';
import { getSelectedCompanySelector } from '@redux/companies/companiesSelectors';
import { companiesActions } from '@redux/companies/companiesActions';
import { audienceActions } from '@redux/audience/audienceActions';
import { useInternationalization } from '@hooks/useTranslationHook';
import companyAvatar from '../../../../../wabb-assets/svg/Avatar/company.svg';

import { BcTooltip } from '@components/common/BcTooltip/BcTooltip';

const CompanyItem = ({ company }) => {
  const { formatMessage } = useInternationalization();

  const history = useHistory();
  const dispatch = useDispatch();
  const { chatId } = useParams();

  const selectedCompany = useSelector(getSelectedCompanySelector);
  const { notificationsSocket } = useSelector(getNotificationsSelector);
  const { chatSocket } = useSelector(getChatSelector);

  const handleSelectCompany = company => {
    if (selectedCompany.id === company.id) {
      history.push('/')
    } else {
      const { companyBot, botUrl, instanceId, serverNumber } = selectedCompany;

      dispatch(companiesActions.setSelectedCompany({ company, reload: false }));
      dispatch(audienceActions.resetUsers());
      localStorage.setItem('selectedCompany', company.id);

      const pathNameCopyArr = history.location.pathname.split('/');
      const urlId = company.id;

      pathNameCopyArr.splice(1, 1, urlId);

      history.push(pathNameCopyArr.join('/'));

      if (history.location.pathname.includes('/live-chat')) {
        if (chatSocket) {
          chatSocket.notReconnect = true;
          chatSocket.close();
          dispatch(chatActions.closeConnectionSuccess());
        }

        chatActions.openChatsConnection({
          companyBot,
          botUrl,
          serverNumber,
          instanceId,
        });
      }

      if (history.location.pathname.includes('/payment')) {
        history.push(history.location.pathname.replace('/payment', ''));
      }

      if (history.location.pathname.includes('/companies')) {
        history.push(history.location.pathname.replace('/companies', ''));
      }

      if (history.location.pathname.includes('/users')) {
        history.push(history.location.pathname.replace('/users', ''));
      }

      if (notificationsSocket) {
        notificationsSocket.notReconnect = true;
        notificationsSocket.close();
        dispatch(notificationsActions.closeConnectionSuccess());
      }

      notificationsActions.openNotifiactionsConnection({
        companyBot,
        botUrl,
        serverNumber,
        instanceId,
      });

      if (chatId) {
        const pathNameArr = history.location.pathname.split('/');
        pathNameArr.pop();
        history.push(pathNameArr.join('/'));
        dispatch(chatActions.chooseCurrentChat({ id: null, isChatGroup: false }));
      }
    }
  };

  return (
    <div
      className="companies-dropup__company-option"
      onClick={() => handleSelectCompany(company)}
    >
      <div className="companies-dropup__company_avatar_container">
        <div
          className="companies-dropup__company-option-avatar"
          style={{
            backgroundImage: `url(${company.avatar || companyAvatar})`,
          }}
        />
        {/* companies-dropup__company-option_indicator */}
        <div className="indicator">
          {company.isBotConnected ? (
            <BcTooltip title={formatMessage('WhatsApp Connected')} placement="right">
              <div className="companies-dropup__company_name_connected" />
            </BcTooltip>
          ) : (
            <BcTooltip title={formatMessage('WhatsApp Disconnected')} placement="right">
              <div className="companies-dropup__company_name_disconnected" />
            </BcTooltip>
          )}
        </div>
      </div>
      <div className="companies-dropup__company_description bottom-open">
        <div className="companies-dropup__company_name">
          <div className="companies-dropup__company_name_name">{company?.name}</div>
        </div>
        <div className="companies-dropup__company_id">
          ID {company?.id}
        </div>
      </div>
    </div>
  );
};

export default CompanyItem;
