import React, { useEffect, useState } from 'react';
import { useInternationalization } from '@hooks/useTranslationHook';

import { CheckBox } from '@components/common';
import { DropdownIcon } from '@icons/automation/dropdown-icon';
import Popover from '@mui/material/Popover';
import { DefaultCompaniesIcon } from '@icons/default-companie-icon';

import './companiesSelector.scss';

export const CompaniesSelector = ({ selectedCompanies, companies, selectCompany }) => {
  const { formatMessage } = useInternationalization();
  const [anchorEl, setAnchorEl] = useState(null);
  const [searchValue, setSearchValue] = useState('');
  const [filteredItems, setFilteredItems] = useState(companies);

  const handleClick = event => {
    event.preventDefault();
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleClose = event => {
    event.stopPropagation();
    setAnchorEl(null);
  };

  useEffect(() => {
    setFilteredItems(
      companies.filter(item =>
        item.name.toLowerCase().includes(searchValue.toLowerCase()),
      ),
    );
  }, [companies, searchValue]);

  return (
    <div className="companies-selector" onClick={handleClose}>
      <div className="companies-selector__label">{formatMessage('Company')}</div>
      <div className="companies-selector__container" onClick={handleClick}>
        {selectedCompanies.length !== 0 ? (
          <div className="companies-selector__container_text">
            {`${selectedCompanies.length} ${formatMessage(' selected')}`}
          </div>
        ) : (
          <div className="companies-selector__container_text_placeholder">
            {formatMessage('Select')}
          </div>
        )}
        <div style={anchorEl ? { transform: 'rotate(180deg)' } : {}}>
          <DropdownIcon />
        </div>
        <Popover
          open={Boolean(anchorEl)}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          PaperProps={{
            style: {
              width: '21%',
              border: '1px solid #D9DFE3',
              boxShadow: '0px 5px 15px rgba(0, 0, 0, 0.05)',
              borderRadius: '10px',
              maxHeight: '235px',
              scrollbar: 'red !important',
            },
          }}
          elevation={2}
        >
          <div className="companies-selector__container__popover-item">
            <div className="companies-selector__container__popover-item_search">
              <input
                type="text"
                placeholder={formatMessage('Search value')}
                value={searchValue}
                onChange={event => setSearchValue(event.target.value)}
              />
            </div>
            {filteredItems.length > 0 ? (
              filteredItems.map(company => (
                <div
                  key={company.id}
                  className="companies-selector__container__popover-item_block"
                >
                  <div className="companies-selector__container__popover-item_block_information">
                    {company.avatar ? (
                      <img
                        className="companies-selector__container__popover-item_block_information_img"
                        src={company.avatar}
                      />
                    ) : (
                      <div className="companies-selector__container__popover-item_block_information_img">
                        <DefaultCompaniesIcon />
                      </div>
                    )}
                    <div className="companies-selector__container__popover-item_block_information_text">
                      {company.name}
                    </div>
                  </div>
                  <CheckBox
                    onChange={() => selectCompany(company)}
                    checked={selectedCompanies.find(el => el.id === company.id)}
                  />
                </div>
              ))
            ) : (
              <div className="companies-selector__container__popover-item_empty">
                {formatMessage('No companies yet')}
              </div>
            )}
          </div>
        </Popover>
      </div>
    </div>
  );
};
