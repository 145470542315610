import { managersActionsTypes } from './managersActionsTypes';

const initialState = {
  last_live_chat_permissions: {},
};

export const managersReducer = (state = initialState, action) => {
  switch (action.type) {
    case managersActionsTypes.UPDATE_LIVE_CHAT_PERMISSIONS_REQUEST:
      return {
        ...state,
      };

    case managersActionsTypes.UPDATE_LIVE_CHAT_PERMISSIONS_SUCCESS:
      return {
        ...state,
        last_live_chat_permissions: action.payload,
      };

    case managersActionsTypes.UPDATE_LIVE_CHAT_PERMISSIONS_FAILURE:
      return {
        ...state,
      };

    default:
      return state;
  }
};
