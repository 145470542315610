import React, { useState } from 'react';

import { useInternationalization } from '@hooks/useTranslationHook';
import { ArrowIcon } from '@icons/automation/arrow-icon';
import { WabbSelectPopover } from '../../../wabb-ui-kit/WabbSelectPopover/WabbSelectPopover';

import './BcSelect.scss';

export const BcSelect = ({
  values,
  selectedItem,
  handleChangeItem,
  labelName = 'name',
  idName = 'id',
  height = 50,
  disabled,
  widthSelect,
  width
}) => {
  const { formatMessage } = useInternationalization();
  
  const [isOpenFlows, setIsOpenFlows] = useState(false);

  const handleToggleFlows = () => {
    setIsOpenFlows(prev => !prev);
  };

  return (
    <WabbSelectPopover
      renderTrigger={() => (
        <div
          id="#BcSelect"
          className={`bc-select ${isOpenFlows ? 'bc-select__active' : ''}`}
          onClick={handleToggleFlows}
          style={{ height: height - 30, width: widthSelect}}
        >
          <div className="bc-select__selected-item">
            {selectedItem ? (
              <div className="bc-select__selected-item_selected">
                <div>{selectedItem[labelName]}</div>
              </div>
            ) : (
              <div>{formatMessage('Choose here')}</div>
            )}
            <div
              className="bc-select__arrow-icon"
              style={{ transform: `rotate(${isOpenFlows ? '0deg' : '180deg'})` }}
            >
              <ArrowIcon />
            </div>
          </div>
        </div>
      )}
      items={values}
      selectedItemId={selectedItem ? selectedItem[idName] : null}
      labelName={labelName}
      idName={idName}
      handleChangeItem={handleChangeItem}
      isOpen={isOpenFlows}
      setIsOpen={setIsOpenFlows}
      onClose={handleToggleFlows}
      width={width}
    />
  );
};
