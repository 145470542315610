import { createSelector } from 'reselect';

export const getSelectedCompanySelector = createSelector(
  [state => state.companies.selectedCompany],
  selectedCompany => selectedCompany,
);

export const getReloadCompanySelector = createSelector(
  [state => state.companies.reloadPage],
  reloadPage => reloadPage,
);

export const getCompaniesSelector = createSelector(
  [state => state.companies.companies],
  companies => companies,
);

export const getCompaniesPlans = createSelector(
  [state => state.companies.companyPlans],
  plans => plans,
);

export const getAvatarCompanySelector = createSelector(
  [state => state.companies.selectedCompany.avatar],
  avatar => avatar,
);

export const getNameCompanySelector = createSelector(
  [state => state.companies.selectedCompany.name],
  name => name,
);

export const getIdCompanySelector = createSelector(
  [state => state.companies.selectedCompany.id],
  id => id,
);

export const getBotIdSelectedCompanySelector = createSelector(
  [state => state.companies.selectedCompany.bot],
  botId => botId,
);

export const getPermissionsSelectedCompanySelector = createSelector(
  [state => state.companies.selectedCompany?.permissions],
  permissions => permissions,
);

export const getServerUrlSelectedCompanySelector = createSelector(
  [state => state.companies.selectedCompany.serverUrl],
  serverUrl => serverUrl,
);

export const getServerNumberSelectedCompanySelector = createSelector(
  [state => state.companies.selectedCompany.server_number],
  server_number => server_number,
);

export const getInstanceIdSelectedCompanySelector = createSelector(
  [state => state.companies.selectedCompany.instance_id],
  instance_id => instance_id,
);
