import FormField from '@wabb-ui-kit/FormField/FormField.tsx';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { variablesApi } from '@api/variablesApi';
import { Code, ConnectError } from '@connectrpc/connect';

import Button from '@wabb-ui-kit/Button/Button';
import { EButtonSize } from '@wabb-ui-kit/Button/types/types';
import { useInternationalization } from '@hooks/useTranslationHook';
import { getSelectedCompanySelector } from '@redux/companies/companiesSelectors';
import Dropdown from '@wabb-ui-kit/Dropdown/Dropdown';
import { EComponentType } from '@wabb-ui-kit/FormField/types/types';
import { bcSplitTableActions } from '@redux/bcSplitTable/bcSplitTableActions';

import './CreateUserFieldModal.scss';

export const CustomFieldsTypes = [
  {
    id: 0,
    bot: 0,
    name: 'Text',
  },
  {
    id: 1,
    bot: 1,
    name: 'Number',
  },
  {
    id: 2,
    bot: 2,
    name: 'Date',
  },
  {
    id: 3,
    bot: 3,
    name: 'Date & Time',
  },
];

export const CreateUserFieldModal = ({ handleCloseModal, isModalOpen }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [type, setType] = useState(null);
  const { formatMessage } = useInternationalization();
  const [error, setError] = useState('');

  const selectedCompany = useSelector(getSelectedCompanySelector);
  const companyBot = selectedCompany.bot;
  const currentFolderId = history.location.pathname.split('/')[4]
    ? history.location.pathname.split('/')[4]
    : '';

  useEffect(() => {
    if (!isModalOpen) {
      setName('');
      setDescription('');
      setType(null);
      setError('');
    }
  }, [isModalOpen]);

  const handleCreateUserField = async () => {
    try {
      const res = await variablesApi.createVariable({
        customFieldName: name,
        companyBot,
        folder: currentFolderId,
        description,
        activeType: type.id,
        fieldType: 'user',
      });
      dispatch(bcSplitTableActions.createNewItemSuccess(res));
      setName('');
      setDescription('');
      setType(null);
      handleCloseModal();
      return res;
    } catch (e) {
      const connectErr = ConnectError.from(e);

      if (connectErr.code === Code.InvalidArgument) {
        setError('Not more than 30 characters or the name already exists');
      }
    }
  };

  const handleClose = () => {
    setName('');
    setDescription('');
    setType(null);
    handleCloseModal();
  };

  return (
    <div className="create-user-fields-modal">
      <div className="create-user-fields-modal__title">Create new user field</div>
      <div className="create-user-fields-modal__form-fields">
        <div className="create-user-fields-modal__form-fields__input">
          <FormField
            onChange={(e) => setName(e.target.value)}
            label="Name"
            errorText={error}
            isRequired
            value={name}
            onCrossClick={() => setName('')}
            placeholder="Type here..."
          />
        </div>
        <div className="create-user-fields-modal__form-fields__select">
          <label className="create-user-fields-modal__form-fields__select__label">
            Type
            <span className="create-user-fields-modal__form-fields__select__label-required">*</span>
          </label>
          <Dropdown
            options={CustomFieldsTypes}
            singleSelectedOption={type}
            setSingleSelectedOption={setType}
            placeholder="Select type"
          />
        </div>
        <div className="create-user-fields-modal__form-fields__textarea">
          <FormField
            Component={EComponentType.TextArea}
            onChange={(e) => setDescription(e.target.value)}
            label="Description"
            value={description}
            maxLength={500}
            height={124}
            onCrossClick={() => setDescription('')}
            placeholder="Type here..."
          />
        </div>
      </div>
      <div className="create-user-fields-modal__btns">
        <Button
          size={EButtonSize.L}
          isDisabled={!(name.length !== 0 && !!type)}
          onClick={handleCreateUserField}
        >
          { formatMessage('Сreate') }
        </Button>
        <Button
          type="secondary"
          style="tint"
          size={EButtonSize.L}
          onClick={handleClose}
        >
          { formatMessage('Cancel') }
        </Button>
      </div>
    </div>
  );
};
