import {
  CHANGE_ITEM_SELECT_MODE,
  CREATE_NEW_ITEM_REQUEST,
  CREATE_NEW_ITEM_SUCCESS,
  DELETE_ITEM_REQUEST,
  DELETE_ITEM_REQUEST_SUCCESS,
  GET_ITEMS_REQUEST,
  GET_ITEMS_SUCCESS,
  GET_ITEMS_NEXT_REQUEST,
  GET_ITEMS_NEXT_SUCCESS,
  TOGGLE_SELECT_ALL,
  SET_ITEMS_NAME,
  BULK_ACTION_DELETE_ITEMS,
  SET_ITEMS_PAGE,
  UPDATE_ITEM_SPLIT_TABLE,
  CLEAR_CHECKBOXES,
  CLEAR_NEXT,
} from './bcSplitTableActionTypes';

export const bcSplitTableActions = {
  getItemsRequest(data) {
    return {
      type: GET_ITEMS_REQUEST,
      payload: data,
    };
  },
  getItemsSuccess(data) {
    return {
      type: GET_ITEMS_SUCCESS,
      payload: data,
    };
  },
  getItemsNextRequest(data) {
    return {
      type: GET_ITEMS_NEXT_REQUEST,
      payload: data,
    };
  },
  getItemsNextSuccess(data) {
    return {
      type: GET_ITEMS_NEXT_SUCCESS,
      payload: data,
    };
  },
  setItemsPage(data) {
    return {
      type: SET_ITEMS_PAGE,
      payload: data,
    };
  },
  setItemsName(data) {
    return {
      type: SET_ITEMS_NAME,
      payload: data,
    };
  },
  createNewItemRequest(data) {
    return {
      type: CREATE_NEW_ITEM_REQUEST,
      payload: data,
    };
  },

  createNewItemSuccess(data) {
    return {
      type: CREATE_NEW_ITEM_SUCCESS,
      payload: data,
    };
  },
  deleteItemRequest(data) {
    return {
      type: DELETE_ITEM_REQUEST,
      payload: data,
    };
  },
  deleteItemSuccess(data) {
    return {
      type: DELETE_ITEM_REQUEST_SUCCESS,
      payload: data,
    };
  },
  updateItemInSplitTable(data) {
    return {
      type: UPDATE_ITEM_SPLIT_TABLE,
      payload: data,
    };
  },
  bulkActionDeleteItems(data) {
    return {
      type: BULK_ACTION_DELETE_ITEMS,
      payload: data,
    };
  },
  toggleSelectAll(checked) {
    return {
      type: TOGGLE_SELECT_ALL,
      payload: checked,
    };
  },
  changeItemSelectMode(data) {
    return {
      type: CHANGE_ITEM_SELECT_MODE,
      payload: data,
    };
  },
  clearCheckBoxes() {
    return {
      type: CLEAR_CHECKBOXES,
    };
  },
  clearNext() {
    return {
      type: CLEAR_NEXT,
    };
  },
};
