import React from 'react';

export const CheckSmallIcon = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="Check-small">
      <path
        id="Vector"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.63 6.04531C15.9012 6.32792 15.9012 6.78613 15.63 7.06875L9.05588 13.9547C8.78469 14.2373 8.34499 14.2373 8.07379 13.9547L4.37009 10.0951C4.09889 9.81245 4.09889 9.35424 4.37009 9.07162C4.64128 8.78901 5.08098 8.78901 5.35218 9.07162L8.56484 12.4195L14.6479 6.04531C14.9191 5.76269 15.3588 5.76269 15.63 6.04531Z"
        fill="#202F45"
      />
    </g>
  </svg>
);
