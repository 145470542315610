import api from './baseApi';

import { variablesApiRPC } from '@connectApi/baseApi';
import {
  VariableTypeFolderListRequest,
  VariableTypeListRequest,
  VariableShortListRequest,
  VariableByBotId,
  BotVariableDetailPatchRequest,
  VariableTypeDetailPatchRequest,
  BotVariableCreateRequest,
  VariableByPk,
  AnyVariableBulkDeleteRequest,
  VariableTypeMovePatchRequest,
  BotVariableFolderListRequest,
} from 'wabb-backend-api/build/es/variables/variables_pb';

export const variablesApi = {
  getVariables: (botId, page, searchName) => {
    return variablesApiRPC.variableTypeList(
      VariableTypeListRequest.fromJson({
        bot_id: botId,
        search_name: searchName ? searchName : '',
        without_folder: searchName ? false : true,
        page: page ? page : '',
      }),
    );
  },

  getVariablesByFolder: folderId => {
    return variablesApiRPC.variableTypeFolderList(
      VariableTypeFolderListRequest.fromJson({ folder_id: folderId }),
    );
  },

  getBotVariables: (botId, searchName, page) => {
    const params = {
      bot_id: botId,
      search_name: searchName || '',
      page: page || '',
    };
  
    if (!searchName) {
      params.withoutFolder = true;
    }
  
    return variablesApiRPC.botVariableList(VariableTypeListRequest.fromJson(params));
  },

  getBotVariablesByFolder: (folderId, page) =>
    variablesApiRPC.botVariableFolderList(
      BotVariableFolderListRequest.fromJson({ folder_id: folderId, page }),
    ),

  getPaginatedAllVariables: (botId, queryArg, page) => {
    return variablesApiRPC.variableTypeShortPaginationList(
      VariableShortListRequest.fromJson({
        bot_id: botId,
        search_string: queryArg ? queryArg : '',
        page,
      }),
    );
  },

  getAllUserVariables: botId =>
    variablesApiRPC.variableTypeShortList(
      VariableShortListRequest.fromJson({ bot_id: botId }),
    ),

  getAllBotVariables: botId =>
    variablesApiRPC.botVariableShortList(
      VariableShortListRequest.fromJson({ bot_id: botId }),
    ),

  getBotDateVariables: botId =>
    variablesApiRPC.botVariableDatetimeList(VariableByBotId.fromJson({ bot_id: botId })),
  getUserDateVariables: botId =>
    variablesApiRPC.variableTypeDatetimeList(VariableByBotId.fromJson({ bot_id: botId })),

  getBotDateTimeVariables: botId =>
    variablesApiRPC.botVariableDatetimeList(VariableByBotId.fromJson({ bot_id: botId })),

  getUserDateTimeVariables: botId =>
    variablesApiRPC.variableTypeDatetimeList(VariableByBotId.fromJson({ bot_id: botId })),

  getBotVariablesByType: (botId, type) => {
    if (type === 'string') {
      return variablesApiRPC.botVariableStringList(
        VariableByBotId.fromJson({ bot_id: botId }),
      );
    } else if (type === 'date') {
      return variablesApiRPC.botVariableDateList(
        VariableByBotId.fromJson({ bot_id: botId }),
      );
    } else if (type === 'datetime') {
      return variablesApiRPC.botVariableDatetimeList(
        VariableByBotId.fromJson({ bot_id: botId }),
      );
    } else if (type === 'int') {
      return variablesApiRPC.botVariableIntList(
        VariableByBotId.fromJson({ bot_id: botId }),
      );
    }
  },

  getUserVariablesByType: (botId, type) => {
    if (type === 'string') {
      return variablesApiRPC.variableTypeStringList(
        VariableByBotId.fromJson({ bot_id: botId }),
      );
    } else if (type === 'date') {
      return variablesApiRPC.variableTypeDateList(
        VariableByBotId.fromJson({ bot_id: botId }),
      );
    } else if (type === 'datetime') {
      return variablesApiRPC.variableTypeDatetimeList(
        VariableByBotId.fromJson({ bot_id: botId }),
      );
    } else if (type === 'int') {
      return variablesApiRPC.variableTypeIntList(
        VariableByBotId.fromJson({ bot_id: botId }),
      );
    }
  },

  createVariable: data => {
    const {
      customFieldName,
      fieldType,
      activeType,
      companyBot,
      customFieldValue,
      description,
      folder,
    } = data;
    const objSend = {
      key: customFieldName,
      bot: companyBot,
      type: activeType,
      description,
      folder,
    };

    if (activeType === 0) {
      objSend.value_string = customFieldValue;
      objSend.value_float = 0;
    } else if (activeType === 1) {
      objSend.value_string = '';
      objSend.value_float = parseFloat(customFieldValue) ? customFieldValue : '0';
    } else if (activeType === 2) {
      objSend.date_value = customFieldValue || null;
    } else if (activeType === 3) {
      objSend.date_time_value = customFieldValue || null;
    }

    if (fieldType === 'user') {
      return variablesApiRPC.variableTypeCreate(objSend);
    } else {
      return variablesApiRPC.botVariableCreate(
        BotVariableCreateRequest.fromJson(objSend),
      );
    }
  },

  updateVariableBot: data => {
    return variablesApiRPC.botVariableDetailPatch(
      BotVariableDetailPatchRequest.fromJson(data),
    );
  },

  updateVariableTypes: data =>{
   return variablesApiRPC.variableTypeDetailPatch(
      VariableTypeDetailPatchRequest.fromJson(data),
    )},

  removeVariable: (id, fieldType) => {
    if (fieldType === 'user') {
      return variablesApiRPC.variableTypeDetailDelete(VariableByPk.fromJson({ pk: id }));
    } else {
      return variablesApiRPC.botVariableDetailDelete(VariableByPk.fromJson({ pk: id }));
    }
  },

  deleteBulkVariables: (
    botId,
    selectedOrUnselectedItemIds,
    selectedAllMode,
    typeField,
    folderId,
    searchName,
  ) => {
    const data = {
      bot_id: botId,
      selected_or_unselected_items: selectedOrUnselectedItemIds,
      selected_all: selectedAllMode,
      folder_id: folderId,
      search_name: searchName,
    };

    if (typeField === 'user') {
      return variablesApiRPC.variableTypeBulkDelete(
        AnyVariableBulkDeleteRequest.fromJson(data),
      );
    } else {
      return variablesApiRPC.botVariableBulkDelete(
        AnyVariableBulkDeleteRequest.fromJson(data),
      );
    }
  },

  pluralMoveVariables: (
    botId,
    currentFolder,
    newFolderId,
    selected_or_unselected_variables,
    selectedAll,
    searchName,
    typeField,
  ) => {
    const data = {
      bot_id: botId,
      new_folder_id: newFolderId,
      current_folder_id: currentFolder,
      selected_or_unselected_variables,
      selected_all: selectedAll,
      // search_name: searchName,
    };

    if (typeField === 'user') {
      return variablesApiRPC.variableTypeMovePatch(
        VariableTypeMovePatchRequest.fromJson(data),
      );
    } else {
      return variablesApiRPC.botVariableMovePatch(
        VariableTypeMovePatchRequest.fromJson(data),
      );
    }
  },
};
