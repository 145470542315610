import {
  GET_TAG_TYPES_REQUEST,
  GET_TAG_TYPES_SUCCESS,
  CREATE_TAG_TYPES_ERROR,
  CREATE_TAG_TYPES_REQUEST,
} from './tagTypesConstants';

export const tagTypesActions = {
  getTagTypesRequest(data) {
    return {
      type: GET_TAG_TYPES_REQUEST,
      payload: data,
    };
  },

  getTagTypesSuccess(data) {
    return {
      type: GET_TAG_TYPES_SUCCESS,
      payload: {
        data,
      },
    };
  },

  createTagTypesRequest(data) {
    return {
      type: CREATE_TAG_TYPES_REQUEST,
      payload: data,
    };
  },

  createTagTypesError(data) {
    return {
      type: CREATE_TAG_TYPES_ERROR,
      payload: data,
    };
  },
};
