import { all, takeLatest, put } from 'redux-saga/effects';
import { groupsActions } from './groupsActions';
import { groupCampaignsApi } from '../../api/groupCampaigns';
import { GET_GROUPS_REQUEST } from './groupsConstants';

function* getGroupsRequestSaga(action) {
  try {
    const response = yield groupCampaignsApi.getGroupCampaignsWithGroups(
      action.payload.companyBot,
    );

    if (response.data) {
      yield put(groupsActions.getGroupsSuccess(response.data));
    }
  } catch (err) {}
}

function* sagas() {
  yield all([takeLatest(GET_GROUPS_REQUEST, getGroupsRequestSaga)]);
}

export const groupsSagas = sagas;
