import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useContextSelector } from 'use-context-selector';
import { useLocation } from 'react-router-dom';

import { Button } from '@components/common';
import { useInternationalization } from '@hooks/useTranslationHook';
import { getSelectedCompanySelector } from '@redux/companies/companiesSelectors';
import { getModalDataSelector } from '@components/containers/Modal/modalSelectors';
import { companiesApi } from '@api/companiesApi';
import { ModalContainer } from '../../containers/Modal/ModalContainer';
import { MODALS } from '../../containers/Modal/modalConstants';
import { modalActions } from '../../containers/Modal/modalActions';
import { FranchiseContext } from '../../../contexts/franchiseContext';
import { PaymentLinks } from '../../../const/PaymentLinks';

import './notPaidModal.scss';

const styles = {
  width: '70%',
  left: '70%',
};

const overlayStyles = {
  zIndex: 10,
};

export const NotPaidModal = () => {
  const { formatMessage } = useInternationalization();
  const selectedCompany = useSelector(getSelectedCompanySelector);
  const { modalData } = useSelector(getModalDataSelector);
  const url = useLocation();
  const dispatch = useDispatch();

  const { isOwner } = modalData;

  const franchiseData = useContextSelector(
    FranchiseContext,
    franchiseData => franchiseData,
  );
  const companyId = useSelector(getSelectedCompanySelector).id;
  const { code } = franchiseData;
  const renewLink = `${PaymentLinks.billing.renew}?company_id=${companyId}`;

  const starterPlanText =
    'Your plan has expired. You can renew your account clicking on the button below. You also have the option to upgrade your company to PRO and enjoy unlimited users, unlimited broadcasts, zapier integration, webhook integration and API integration.';
  const proPlanText =
    'Your plan has expired. You can renew your account clicking on the button below. You also have the option to downgrade your company to Starter, but you will be able to have only 5 users, send 1000 broadcasts monthly and lose access to integrations.';

  // useEffect(() => {
  //   if (franchiseData.code) {
  //     setLinkToPayStarterAccount(franchiseData.linkToPayStarterAccount);
  //     setLinkToPayProAccount(franchiseData.linkToPayProAccount);
  //   } else {
  //     linkToPayStarterAccount = PaymentLinks.notPaid.userStarter + selectedCompany.id;
  //     linkToPayProAccount = PaymentLinks.notPaid.userPro + selectedCompany.id;
  //   }
  // }, []);

  const openLink = () => {
    window.open(renewLink);
  };

  const handleClickStarter = () => {
    if (franchiseData.code) {
      window.location.href = franchiseData.linkToPayStarterAccount;
    } else {
      companiesApi.getNotPaidLinks('starter', selectedCompany.id).then(res => {
        window.location.href = res.data.link;
      });
    }
  };

  const handleClickPro = () => {
    if (franchiseData.code) {
      window.location.href = franchiseData.linkToPayProAccount;
    } else {
      companiesApi.getNotPaidLinks('pro', selectedCompany.id).then(res => {
        window.location.href = res.data.link;
      });
    }
  };

  useEffect(() => {
    if (
      url.pathname.includes('/profile') ||
      url.pathname.includes('/franchise') ||
      url.pathname.includes('/login')
    ) {
      dispatch(modalActions.closeModal());
    }
  }, [url]);

  return (
    <ModalContainer
      modal={MODALS.notPaid}
      hideCross
      customStyles={styles}
      overlayStyles={overlayStyles}
    >
      <div className="not-paid-modal">
        <h2 className="not-paid-modal__description">
          {formatMessage(
            franchiseData.code
              ? franchiseData.notPaidMessage
              : selectedCompany.plan_type === 0
              ? starterPlanText
              : proPlanText,
          )}
        </h2>
        {/*{isOwner &&*/}
        {/*  (franchiseData.code &&*/}
        {/*  franchiseData.linkToPayStarterAccount &&*/}
        {/*  selectedCompany.permissions.settings_billing ? (*/}
        {/*    <Button width={180} onClick={handleClickStarter} color="green">*/}
        {/*      {selectedCompany.plan_type === 0*/}
        {/*        ? formatMessage('Renew Starter')*/}
        {/*        : formatMessage('Downgrade to Starter')}*/}
        {/*    </Button>*/}
        {/*  ) : franchiseData.code &&*/}
        {/*    franchiseData.linkToPayProAccount &&*/}
        {/*    selectedCompany.permissions.settings_billing ? (*/}
        {/*    <Button width={180} onClick={handleClickPro} color="green">*/}
        {/*      {selectedCompany.plan_type === 0*/}
        {/*        ? formatMessage('Upgrade to PRO')*/}
        {/*        : formatMessage('Renew PRO')}*/}
        {/*    </Button>*/}
        {/*  ) : (*/}
        {/*    <div className="not-paid-modal__buttons">*/}
        {/*      <Button color="green" transparent onClick={openLink}>*/}
        {/*        <span>{formatMessage('Renew')}</span>*/}
        {/*      </Button>*/}
        {/*    </div>*/}
        {/*  ))}*/}
        {franchiseData.code &&
          franchiseData.linkToPayStarterAccount &&
          selectedCompany.permissions.settings_billing && (
            <Button width={180} onClick={handleClickStarter} color="green">
              {selectedCompany.plan_type === 0
                ? formatMessage('Renew Starter')
                : formatMessage('Downgrade to Starter')}
            </Button>
          )}
        {franchiseData.code &&
          franchiseData.linkToPayProAccount &&
          selectedCompany.permissions.settings_billing && (
            <Button width={180} onClick={handleClickPro} color="green">
              {selectedCompany.plan_type === 0
                ? formatMessage('Upgrade to PRO')
                : formatMessage('Renew PRO')}
            </Button>
          )}
        {!franchiseData.code && isOwner && (
          <div className="not-paid-modal__buttons">
            <Button color="green" transparent onClick={openLink}>
              <span>{formatMessage('Renew')}</span>
            </Button>
          </div>
        )}
      </div>
    </ModalContainer>
  );
};
