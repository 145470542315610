export const regExp = {
  first_name: {
    expression: /^[\p{L}\s]+$/u,
    errorMessage: 'Incorrect First Name',
  },
  last_name: {
    expression: /^[\p{L}\s]+$/u,
    errorMessage: 'Incorrect Last Name',
  },
  email: {
    expression: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
    errorMessage: 'Incorrect E-mail',
  },
  company_name: {
    expression: /^(?=.{1,50}$)[a-z]+(?:['_.\s][a-z]+)*$/i,
    errorMessage: 'Incorrect Company Name',
  },
  password: {
    expression: /^(?=.*[A-Z])(?=.*[^A-Za-z0-9]).{8,}$/,
    errorMessage:
      'The password must have at least: 8 characters, one uppercase, one lowercase, one number and one special character',
  },
  confirmPassword: {
    expression: /^(?=.*[A-Z])(?=.*[^A-Za-z0-9]).{8,}$/,
    errorMessage:
      'The password must have at least: 8 characters, one uppercase, one lowercase, one number and one special character',
  },
  link_starter: {
    expression: /^(ftp|http|https):\/\/[^ "]+$/,
    errorMessage: 'This link is not valid',
  },
  link_pro: {
    expression: /^(ftp|http|https):\/\/[^ "]+$/,
    errorMessage: 'This link is not valid',
  },
};

export const checkRegExp = (name, value, setErrorValue) => {
  if (value === '') {
    setErrorValue('This field must not be empty');
  } else if (!regExp[name].expression.test(String(value).toLowerCase())) {
    setErrorValue(regExp[name].errorMessage);
  } else {
    setErrorValue('');
  }
};
