import {
  ADD_OR_UPDATE_REPORT_CUSTOM_FIELDS_SUCCESS,
  DOWNLOAD_PREPARED_FILE_CUSTOM_FIELDS,
  DOWNLOAD_REPORT_CUSTOM_FIELDS_REQUEST,
  UPDATE_DOWNLOAD_STATUS_REPORT_BOT_FIELDS,
} from './customFieldsConstants';

export const customFieldsActions = {
  downloadBotFieldsRequest(data) {
    return {
      type: DOWNLOAD_REPORT_CUSTOM_FIELDS_REQUEST,
      payload: data,
    };
  },

  downloadPreparedBotFields(data) {
    return {
      type: DOWNLOAD_PREPARED_FILE_CUSTOM_FIELDS,
      payload: data,
    };
  },

  addOrUpdateReportBotFields(data) {
    return {
      type: ADD_OR_UPDATE_REPORT_CUSTOM_FIELDS_SUCCESS,
      payload: data,
    };
  },
  updateDownloadStatusReportBotFields(data) {
    return {
      type: UPDATE_DOWNLOAD_STATUS_REPORT_BOT_FIELDS,
      payload: data,
    };
  },
};
