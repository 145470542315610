import {
  convertToRaw,
} from 'draft-js';

export const transformEditorState = (editorState) => {  
  const currentContent = editorState.getCurrentContent();
  const text = currentContent.getPlainText();
  const styledText = convertToRaw(currentContent);

  return [text, styledText];
};
