import { dashboardActionTypes as types } from './actionTypes';

export const dashboardActions = {
  getDashboardStatistics(id, startDate, endDate, graphType, tagId) {
    return {
      type: types.GET_DASHBOARD_STAT,
      payload: {
        id,
        startDate,
        endDate,
        graphType,
        tagId,
      },
    };
  },

  setDashboardStatistics(data) {
    return {
      type: types.SET_DASH_STAT,
      payload: {
        data,
      },
    };
  },
};
