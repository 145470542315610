import React from 'react';

export const ConfirmPasswordConditionIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M11.7388 4.71817C12.0273 4.42728 12.4981 4.42728 12.7866 4.71817C13.0711 5.00513 13.0711 5.4676 12.7867 5.75459L7.33369 11.2815C7.04521 11.5724 6.57412 11.5727 6.28564 11.2818L3.21345 8.18382C2.92885 7.89683 2.92885 7.43428 3.21345 7.1473C3.50192 6.8564 3.97273 6.8564 4.26121 7.1473L6.8092 9.7167L11.7388 4.71817Z"
      fill="#039855"
    />
  </svg>
);
