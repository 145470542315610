export enum EComponentType {
  Input = 'input',
  TextArea = 'textarea',
}

export enum EInputType {
  Password = 'password',
  Search = 'inputSearch',
  Text = 'text',
  Number = 'number',
}

export enum EIconDirection {
  Left = 'left',
  Right = 'right',
}
