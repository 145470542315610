import { settingsAPI, serverApiRPC, variablesApiRPC } from '@connectApi/baseApi';
import { ServerBotIdRequest } from 'wabb-backend-api/build/es/servers/servers_pb';
import {
  RequestByBotId,
  GeneralSettingsPatchRequest,
} from 'wabb-backend-api/build/es/settings/settings_pb';
import { VariableByBotId } from 'wabb-backend-api/build/es/variables/variables_pb';

export const settingsApi = {
  getSettingsInfo: bot_id =>
    settingsAPI.generalSettingsGet(RequestByBotId.fromJson({ bot_id })),

  updateSettingsInfo: updateObj =>
    settingsAPI.generalSettingsPatch(GeneralSettingsPatchRequest.fromJson(updateObj)),

  getBotVariablesExport: bot_id => variablesApiRPC.botVariablesExport(VariableByBotId.fromJson({ bot_id })),

  generateQrCode: bot_id =>
    serverApiRPC.addServerToBot(ServerBotIdRequest.fromJson({ bot_id: bot_id })),
};
