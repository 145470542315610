import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';

import './ChoiceFlowsModal.scss';

import { useInternationalization } from '@hooks/useTranslationHook';
import { getBotIdSelectedCompanySelector } from '@redux/companies/companiesSelectors';
import { BcSplitTable } from '@components/common/SplitTable/SplitTable';
import { flowsApi } from '@api/flowsApi';
import { FlowItem } from '@components/modals/choiceFlowsModal/FlowItem';
import { foldersApi } from '@api';
import { Folders } from '@components/common/Folders';
import { Button } from '@components/common';
import { getBcSplitTableItemsSelector } from '@redux/bcSplitTable/bcSplitTableSelectors';
import { MODALS } from '../../containers/Modal/modalConstants';
import { ModalContainer } from '../../containers/Modal/ModalContainer';
import { getModalDataSelector } from '../../containers/Modal/modalSelectors';

const customStyles = {
  width: '1000px',
  minHeight: '550px',
};

const TABLE_HEIGHT_DIFF = 48;

export const ChoiceFlowsModal = () => {
  const { formatMessage } = useInternationalization();

  const { modalData } = useSelector(getModalDataSelector);
  const companyBot = useSelector(getBotIdSelectedCompanySelector);
  const [folders, setFolders] = useState(null);
  const [currentFolder, setCurrentFolder] = useState(null);
  const [selectedItem, setSelectedItem] = useState(null);
  const items = useSelector(getBcSplitTableItemsSelector);
  const [isLoading, setLoading] = useState(false);
  const [searchName, setSearchName] = useState('');
  const tableRef = useRef(null);
  const folderRef = useRef(null);
  const contentRef = useRef(null);

  const getFolders = async folderId => {
    if (companyBot) {
      const { folders } = await foldersApi.getFolders(companyBot, 0, folderId);
      setFolders(folders);
    }
  };

  useEffect(() => {
    if (items.length && modalData?.selectedFlowId) {
      setSelectedItem(items.find(({ id }) => id === modalData?.selectedFlowId));
    }
  }, [modalData?.selectedFlowId, items]);

  useEffect(() => {
    getFolders();
  }, [companyBot, currentFolder]);

  useEffect(() => {
    if (tableRef?.current && folderRef?.current && contentRef?.current && items.length) {
      const table = tableRef.current.querySelector('.bc-table__body');

      if (table) {
        table.style.maxHeight =
          contentRef.current.clientHeight -
          folderRef.current.clientHeight -
          TABLE_HEIGHT_DIFF;
      }
    }
  }, [items, folderRef?.current, contentRef?.current, tableRef?.current]);

  const getModalTitle = () => {
    switch (modalData?.flowType) {
      case 'welcome_flow':
        return 'Welcome Message Flow';
      case 'default_reply':
        return 'Default Message Flow';
      case 'default_reply_for_media':
        return 'Default message flow for media';
      default:
        return 'Welcome Message Flow';
    }
  };

  const getHelperText = () => {
    switch (modalData?.flowType) {
      case 'welcome_flow':
        return 'Welcome is sent only to new contacts (who have never sent a message to the robot) and only 1x.';
      case 'default_reply':
        return 'Default Response is sent with any character other than a keyword, except when the flow is waiting for user input to save to a field. NOTE AUTO OFF (in content block) temporarily turns off the Default Answer.';
      case 'default_reply_for_media':
        return 'Standard Response for Media is sent with any attachment (image, video, audio, file) except when the stream is waiting for user input to save to a field.';
      default:
        return 'Welcome is sent only to new contacts (who have never sent a message to the robot) and only 1x.';
    }
  };

  const getFlows = async ({ botId, searchName, page }) =>
    flowsApi.getFlows(botId, currentFolder?.id, searchName, page);

  const handleFolderClick = async folderId => {
    if (folderId) {
      const { data } = await foldersApi.getFolderInfo(folderId);
      setCurrentFolder(data);
    } else {
      setCurrentFolder(null);
    }
  };

  const updateDefaultFlow = async () => {
    setLoading(true);

    try {
      const { data } = await flowsApi.updateFlowsSettings(
        { [modalData.flowType]: selectedItem?.id },
        companyBot,
      );
      modalData.setFlowsData(data);
      modalData.onClose();
    } finally {
      setLoading(false);
    }
  };

  return (
    <ModalContainer
      modal={MODALS.choiceFlows}
      customStyles={customStyles}
      onClose={modalData.onClose}
    >
      <div className="choice-flows-modal">
        <h4 className="choice-flows-modal__title">{formatMessage(getModalTitle())}</h4>
        <div className="choice-flows-modal__content" ref={contentRef}>
          <div className="choice-modal-folders" ref={folderRef}>
            <Folders
              currentFolder={currentFolder}
              folders={folders}
              getFolders={getFolders}
              title={formatMessage('All Flows')}
              onFolderClick={handleFolderClick}
              isModalComponent
              searchName={searchName}
              setSearchName={setSearchName}
            />
          </div>
          <div className="table" ref={tableRef}>
            <BcSplitTable
              inverse
              withoutCheckbox
              gridColumns="40% 15% 15% 20% 10%"
              columns={[
                { name: formatMessage('Name') },
                {
                  name: formatMessage('Runs'),
                  style: { display: 'flex', justifyContent: 'center' },
                },
                {
                  name: formatMessage('CTR, %'),
                  style: { display: 'flex', justifyContent: 'center' },
                },
                { name: formatMessage('Last changed') },
              ]}
              Component={FlowItem}
              getItemsApi={getFlows}
              createItemApi={flowsApi.createFlowsSource}
              deleteItemApi={flowsApi.deleteFlowsSource}
              sourceItemsName={`Flows ${
                currentFolder?.id ? `in folder "${currentFolder?.name}" ` : ''
              }`}
              itemData={{
                selectedItem,
                setSelectedItem,
              }}
              sourceSearchName={searchName}
            />
          </div>
        </div>
        <div className="bottom-content">
          <div className="bottom-content__text">{formatMessage(getHelperText())}</div>
          <Button
            disabled={!selectedItem || selectedItem?.id === modalData?.selectedFlowId}
            width={236}
            onClick={updateDefaultFlow}
            isLoading={isLoading}
          >
            {formatMessage('Save')}
          </Button>
        </div>
      </div>
    </ModalContainer>
  );
};
