import api from './baseApi';
import { companyApi } from '@connectApi/baseApi';

export const companiesApi = {
  createPromocode: newPromoObj => api.post('/promo_package/', newPromoObj),

  activateCompany: company_id => api.post(`/companies/activate_frozen/${company_id}/`),

  getCompanies: (onlyActiveCompanies, frCode) => {
    const url = '/companies/';
    const params = {};

    if (onlyActiveCompanies) {
      params.only_active_companies = true;
    }

    if (frCode) {
      params.fr_code = frCode;
    }

    return companyApi.getCompanyList(params);
  },

  getCompaniesById: company_id => api.get(`/companies/${company_id}/`),

  getNotPaidLinks: (plan, companyId) =>
    api.get(
      `/guru_payment/get_link_for_not_paid_company/?plan=${plan}&company_id=${companyId}`,
    ),
  getNewCompanyPaymentLinks: plan =>
    api.get(`/guru_payment/get_link_for_new_company/?plan=${plan}`),

  passwordUpdateCheck: () => api.get('custom_users/get_pass_info/'),

  confirmPasswordChange: (userId, data) =>
    api.post(`custom_users/change_password/${userId}/`, data),
};
