import React from 'react';
import { useSelector } from 'react-redux';

import '../modalsComponent.scss';
import './SaveFlowModal.scss';

import { getCompaniesSelector } from '@redux/companies/companiesSelectors';
import { useInternationalization } from '@hooks/useTranslationHook';
import { ModalContainer } from '../../containers/Modal/ModalContainer';
import { MODALS } from '../../containers/Modal/modalConstants';
import { getModalDataSelector } from '../../containers/Modal/modalSelectors';
import SaveCompanyItem from './SaveCompanyItem';

export const SaveFlowModal = () => {
  const { formatMessage } = useInternationalization();

  const { modalData } = useSelector(getModalDataSelector);

  const companies = useSelector(getCompaniesSelector);

  const AllCompanies = companies
    ? companies.map(company => {
        if (modalData.details.isFlowProtected) {
          if (modalData.details.allowedCompaniesForCloning.includes(company.id)) {
            return <SaveCompanyItem company={company} />;
          }
        } else {
          return <SaveCompanyItem company={company} />;
        }
      })
    : null;

  return (
    <ModalContainer modal={MODALS.saveFlow} onClose={modalData.onClose}>
      <div className="save-flow-modal">
        <div className="save-flow-modal__header">
          <div className="save-flow-modal__header__text">
            {formatMessage('My Companies')}
          </div>
        </div>
        <div className="save-flow-modal__content">
          <div className="save-flow-modal__subtitle-block">
            <div className="save-flow-modal__subtitle-block_text">
              {formatMessage(
                'Please select the company where you would like to save a copy of the flow',
              )}
              <span className="save-flow-modal__subtitle-block_text_name">
                {' '}
                {modalData.details.flowName}
              </span>
            </div>
          </div>
          {AllCompanies}
        </div>
      </div>
    </ModalContainer>
  );
};
