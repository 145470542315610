import React from 'react';

export const PlusIcon = ({ color = '#5925dc' }) => (
  <svg
    width="12"
    height="12"
    viewBox="0 0 12 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6 0C6.59173 0 7.07143 0.479695 7.07143 1.07143V4.92857H10.9286C11.5203 4.92857 12 5.40827 12 6C12 6.59173 11.5203 7.07143 10.9286 7.07143H7.07143V10.9286C7.07143 11.5203 6.59173 12 6 12C5.40827 12 4.92857 11.5203 4.92857 10.9286V7.07143H1.07143C0.479695 7.07143 0 6.59173 0 6C0 5.40827 0.479695 4.92857 1.07143 4.92857H4.92857V1.07143C4.92857 0.479695 5.40827 0 6 0Z"
      fill={color}
    />
  </svg>
);
