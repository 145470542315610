import React from 'react';

export const ArrowIcon = ({ rotate }) => (
  <svg
    width="20"
    height="19"
    viewBox="0 0 22 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={{ transform: `rotate(${rotate}deg)` }}
  >
    <g id="Arrows/down">
      <path
        id="Vector"
        d="M4.97468 7.05806C5.21876 6.81398 5.61449 6.81398 5.85857 7.05806L10.4166 11.6161L14.9747 7.05806C15.2188 6.81398 15.6145 6.81398 15.8586 7.05806C16.1026 7.30214 16.1026 7.69786 15.8586 7.94194L10.8586 12.9419C10.6145 13.186 10.2188 13.186 9.97468 12.9419L4.97468 7.94194C4.73061 7.69786 4.73061 7.30214 4.97468 7.05806Z"
        fill="#202F45"
      />
    </g>
  </svg>
);
