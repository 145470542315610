
export const fieldSelectStyles = {
  control: (baseStyles, state) => ({
    ...baseStyles,
    fontWeight: '400',
    fontSize: '15px',
    lineHeight: '20px',
    boxShadow: 'none',
    border: 'none',
    borderRadius: '0',
    borderBottom: `1.5px solid ${state.isFocused ? '#1187F4' : '#E5EBEF'}`,
    '&:hover': {
      borderColor: state.isFocused ? '#1187F4' : '#1187F44D',
    },
  }),
  dropdownIndicator: () => ({
    display: 'none',
  }),
  menu: (baseStyles) => ({
    ...baseStyles,
    border: 'none',
    boxShadow: 'transparent',
  }),
  menuList: (baseStyles) => ({
    ...baseStyles,
    maxHeight: '34vh',
    '&::-webkit-scrollbar': {
      background: 'transparent',
    },
  }),
  option: (baseStyles) => ({
    ...baseStyles,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    fontWeight: '600',
    fontSize: '14px',
    lineHeight: '19px',
    background: 'transparent',
    maxWidth: '100%',
    color: '#3C4449',
    '&:hover': {
      background: '#1187F41A',
    },
  }),
  singleValue: () => ({
    display: 'none',
  }),
  placeholder: (baseStyles) => ({
    ...baseStyles,
    fontSize: '15px',
  }),
  groupHeading: (baseStyles) => ({
    ...baseStyles,
    fontWeight: '600',
    fontSize: '9px',
    lineHeight: '12px',
  }),
};
