import React, { FC } from 'react';
import { clsx } from 'clsx';
import PersonIcon from '../../wabb-assets/svg/Avatar/person.svg';
import CompanyIcon from '../../wabb-assets/svg/Avatar/company.svg';
import styles from './Avatar.module.scss';

interface IAvatarProps {
  type: 'company' | 'person';
  size: 'xs' | 's' | 'm' | 'l' | 'xl' | '2xl';
  status?: 'green' | 'red' | 'orange' | 'gray';
  img?: string;
  shape?: 'circle' | 'square'
}

const defaultAvatar = {
  person: PersonIcon,
  company: CompanyIcon,
};

const Avatar: FC<IAvatarProps> = ({
  type, size, status, img, shape = 'circle',
}) => {
  const imgClasses = clsx(styles.avatar, styles[`avatar-${size}`], styles[`avatar-${shape}`]);
  const StatusClasses = clsx(
    styles.status,
    styles[`status-${size}`],
    styles[`color-${status}`],
  );

  return (
    <div className={styles.avatar__container}>
      <img className={imgClasses} src={img || defaultAvatar[type]} alt={type} />
      {!!status && <span className={StatusClasses} />}
    </div>
  );
};

export default Avatar;
