import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import Avatar from '@wabb-ui-kit/Avatar/Avatar';
import { Popover } from '@ui-kit';
import { modalActions } from '@components/containers/Modal/modalActions';
import { authActions } from '@redux/auth/authActions';
import { getNotificationsSelector } from '@redux/notifications/notificationsSelector';
import { notificationsActions } from '@redux/notifications/notificationsActions';
import { getChatSelector } from '@redux/liveChat/chatSelector';
import { stopChatSocketReconnect } from '@helpers/stopChatSocketReconnect';
import { IUser } from '@wabb-ui-kit/NavigationMenu/types';
import { Routes } from '../../../../const/routes';
import { AccountSettingsIcon, LogoutIcon } from '@icons';

import styles from './NavigationFooter.module.scss';

interface INavigationFooterProps {
  open?: boolean;
  userInfo: IUser;
}

const NavigationFooter = ({ open, userInfo }: INavigationFooterProps) => {
  const dispatch = useDispatch();
  const { chatSocket }: any = useSelector(getChatSelector);
  const { notificationsSocket }: any = useSelector(getNotificationsSelector);

  const handleSignOut = () => {
    dispatch(modalActions.closeModal());
    dispatch(
      modalActions.openConfirmModal({
        title: 'Sign out',
        text: 'Are you sure you want to sign out?',
        confirmButtonText: 'Yes, sign out',
        type: 'delete',
        handleConfirm: () => {
          dispatch(authActions.logOut());
          chatSocket && stopChatSocketReconnect(chatSocket, dispatch);
          notificationsSocket && notificationsSocket.close();
          notificationsSocket && dispatch(notificationsActions.closeConnectionSuccess());
        },
        onClose: () => {
          dispatch(modalActions.closeModal());
        },
      }),
    );
  };

  const emailFromLocalStorage = localStorage.getItem('currentUserEmail');
  const userInfoFromLocalStorage = localStorage.getItem('fullName');

  const currentUserEmail = !!emailFromLocalStorage ? JSON.parse(emailFromLocalStorage) : null;
  const currentUserInfo = !!userInfoFromLocalStorage ? JSON.parse(userInfoFromLocalStorage) : null;

  return (
    <>
      <Popover
        size={{ width: '240px' }}
        style={{ backgroundColor: '#101828', border: "1px solid #202F45", borderRadius: '4px' }}
        renderTrigger={() => (
          <div className={styles.footer__container}>
            <Avatar
              img={userInfo?.avatar as string | undefined}
              type="person"
              size="m"
              status="green"
            />
            {open && (
              <div className={styles.footer__nameContainer}>
                <p className={styles.footer__name}>
                  {currentUserInfo ?? `${userInfo?.first_name ?? ''} ${userInfo?.last_name ?? ''}`}
                </p>
                <p className={styles.footer__email}>
                  {currentUserEmail ?? (userInfo?.email ?? '')}
                </p>
              </div>
            )}
          </div>
        )}
      >
        <div style={{ marginTop: '8px', width: '100%' }}>
          <Link to={Routes.ProfileRoute} className={styles.footer__item}>
            <div style={{ marginRight: 5 }}>
              <AccountSettingsIcon />
            </div>
            <span>Account settings</span>
          </Link>
        </div>
        <div onClick={handleSignOut} className={styles.footer__item}>
          <div style={{ marginRight: 5 }}>
            <LogoutIcon />
          </div>
          <span>Log out</span>
        </div>
      </Popover>
    </>
  );
};

export default NavigationFooter;
