import React, { useEffect, useRef, useState } from 'react';

import { useInternationalization } from '@hooks/useTranslationHook';
import { ShowPasswordIcon } from '@icons/show-password-icon';
import { OpenPassword } from '@icons/open-password';

import './PasswordField.scss';

export const PasswordField = ({
  label,
  value,
  onChange,
  name,
  type,
  helperText,
  placeholder,
  onClick,
}) => {
  const { formatMessage } = useInternationalization();

  const [showHiddenText, setShowHiddenText] = useState(false);
  const htmlElRef = useRef(null);

  useEffect(() => {
    if (value && value.length > 0 && document.activeElement === htmlElRef.current)
      setShowHiddenText(true);
    if (value && (value.length === 0 || value === '')) setShowHiddenText(false);
    if (!value) setShowHiddenText(false);
  }, [value, setShowHiddenText, htmlElRef]);

  return (
    <div className="password-field">
      <div className="password-field__title">{label}</div>
      <div className="password-field__container">
        <input
          key={type}
          className={
            value && !helperText
              ? 'password-field__filled-input'
              : helperText && value
              ? 'password-field__error-input'
              : value.length === 0 && helperText
              ? 'password-field__empty-input'
              : 'password-field__input'
          }
          name={name}
          type={type}
          ref={htmlElRef}
          onChange={e => onChange(e, name)}
          value={value}
          onBlur={() => setShowHiddenText(false)}
          onMouseDown={() => value && value.length > 0 && setShowHiddenText(true)}
          placeholder={placeholder}
        />
        <div className="password-field__button-container">
          <button
            className="password-field__button"
            onMouseDown={() => {
              if (onClick) onClick();
            }}
          >
            {showHiddenText && (
              <span>{type === 'password' ? <ShowPasswordIcon /> : <OpenPassword />}</span>
            )}
          </button>
        </div>
      </div>
      {helperText && (
        <div className="password-field__error-value">{formatMessage(helperText)}</div>
      )}
    </div>
  );
};
