import {
  ADD_ITEM,
  ADD_ALL_ITEMS,
  DELETE_ITEM,
  DELETE_ALL_ITEMS,
  ADD_AVATAR,
  ADD_DESCRIPTION,
  ADD_FEATURES,
  ADD_INSTRUCTIONS,
  ADD_CATEGORY,
  ADD_NAME,
  FOR_PUBLISHER,
  ADD_VARIABLE_TYPES,
  ADD_BOT_VARIABLES,
  ADD_TAGS,
  ADD_SCREENSHOTS,
  ADD_ZAPIER_EVENTS,
} from './addTemplateConstants';

const initialState = {
  general: [],
  keywords: [],
  flows: [],
  disabled_flows: [],
  campaigns: [],
  sequences: [],

  variable_types: [],
  bot_variables: [],
  tags: [],
  zapier_events: [],

  forPublisher: false,
  name: '',
  avatar: '',
  avatarPath: '',
  category: [],
  description: '',
  features: '',
  instructions: '',
  screenShot: [],
  screenShotsPath: [],
};

export const addTemplateReducer = (state = initialState, action) => {
  const check = (arr, item) => arr.some(el => el.id === item.id);

  const type = () => action.payload.type;
  const item = () => action.payload.item;
  const items = () => action.payload.items;
  const name_field = () => type().toLowerCase();

  switch (action.type) {
    case ADD_ITEM:
      if (!check(state[name_field()], item())) {
        return {
          ...state,
          [name_field()]: [...state[name_field()], item()],
        };
      }

      return { ...state };
    case DELETE_ITEM:
      const newItems = state[name_field()].filter(el => el.id !== item().id);
      return {
        ...state,
        [name_field()]: [...newItems],
      };
    case ADD_ALL_ITEMS:
      return {
        ...state,
        [name_field()]: [...items()],
      };
    case DELETE_ALL_ITEMS:
      return {
        ...state,
        [name_field()]: [],
      };
    case ADD_VARIABLE_TYPES:
      return {
        ...state,
        variable_types: action.payload,
      };
    case ADD_BOT_VARIABLES:
      return {
        ...state,
        bot_variables: action.payload,
      };
    case ADD_TAGS:
      return {
        ...state,
        tags: action.payload,
      };
    case ADD_ZAPIER_EVENTS:
      return {
        ...state,
        zapier_events: action.payload,
      };
    case FOR_PUBLISHER:
      return {
        ...state,
        forPublisher: action.payload,
      };
    case ADD_NAME:
      return {
        ...state,
        name: action.payload,
      };
    case ADD_AVATAR:
      return {
        ...state,
        avatar: action.payload.avatar,
        avatarPath: action.payload.avatarPath,
      };
    case ADD_CATEGORY:
      return {
        ...state,
        category: action.payload,
      };
    case ADD_DESCRIPTION:
      return {
        ...state,
        description: action.payload,
      };
    case ADD_FEATURES:
      return {
        ...state,
        features: action.payload,
      };
    case ADD_INSTRUCTIONS:
      return {
        ...state,
        instructions: action.payload,
      };
    case ADD_SCREENSHOTS:
      return {
        ...state,
        screenShot: action.payload.images,
        screenShotsPath: action.payload.imagesPath,
      };
    default:
      return { ...state };
  }
};
