import React, { useState } from 'react';
// import ReactJson from 'react-json-view';

import { Button as BcButton } from '../../../../common/Button';
import { useInternationalization } from '../../../../../hooks/useTranslationHook';
import './response.scss';

export const Response = ({ handleSendForm, response }) => {
  const { formatMessage } = useInternationalization();
  return (
    <div className="json-editor">
      {/* <BcButton color={'green'} onClick={handleSendForm}>
        {formatMessage('Click to test your request')}{' '}
      </BcButton> */}
      <div>
        {/* <ReactJson
          src={response}
          displayObjectSize={false}
          displayDataTypes={false}
          enableClipboard={false}
          style={{ maxHeight: '300px', display: 'flex', overflow: 'scroll' }}
        /> */}
      </div>
    </div>
  );
};
