import { usersApi } from '@api/usersApi';
import { companiesActions } from '@redux/companies/companiesActions';
import { settingsApi } from '@api/settingsApi';
import { clearDataApi } from '@api/clearDataApi';
import { compareTime } from '@helpers';
import countriesList from '../static/countries.json';
import { updateIncorrectTimePeriod } from '@pages/settings/components/defaultFlows/utils';

const transformSettingInfoData = (data, prevData) => {
  const endTimeTemplate = 'EndBusinessHours';
  const startTimeTemplate = 'StartBusinessHours';
  const isOnTemplate = 'BusinessHoursIsOn';

  const daysOfWeek = [
    'monday',
    'tuesday',
    'wednesday',
    'thursday',
    'friday',
    'saturday',
    'sunday',
  ];

  return daysOfWeek.map(day => {
    const [hourStart, minStart] = data[day + startTimeTemplate].split(':');
    const [hourEnd, minEnd] = data[day + endTimeTemplate].split(':');
    const isOn = data[day + isOnTemplate];

    return {
      label: day.charAt(0).toUpperCase() + day.slice(1),
      startTime: `${hourStart}:${minStart}`,
      endTime: `${hourEnd}:${minEnd}`,
      isOn,
      isWarning: prevData?.length
        ? prevData.find(({ label }) => label.toLowerCase() === day)?.isWarning
        : false,
    };
  });
};

export const settingService = {
  updateCompanyData: async (companyId, companyData, dispatch, isDeleteLogo) => {
    try {
      const companyNewData = new FormData();
      (companyData.logo || isDeleteLogo) &&
        companyNewData.append("avatar", companyData.logo);
      companyNewData.append("name", companyData.name);
      const { status, data } = await usersApi.updateUsers(
        companyNewData,
        companyId,
      );

      if (status === 200) {
        dispatch(companiesActions.updateSelectedCompany({ company: data }));
      }
    } catch (e) {}
  },

  getCompanyTimeZone: async companyBot => {
    try {
      const { timezone } = await settingsApi.getSettingsInfo(companyBot);

      return countriesList.findIndex(el => timezone.includes(el.name));
    } catch (e) {}
  },

  updateTimeZone: async (timeZoneIndex, companyBot, addToast) => {
    try {
      await settingsApi.updateSettingsInfo({
        bot_id: companyBot,
        timezone: timeZoneIndex,
      });
      addToast({
        title: 'Successful',
        type: 'success',
        message: 'Bot time updated',
      });
    } catch (e) {
      console.log(e, 'e');
      addToast({
        title: 'Error',
        type: 'error',
        message: '',
      });
    }
  },

  clearData: async (clearType, botId, checkStatus) => {
    try {
      let apiName = '';

      switch (clearType) {
        case 'contacts':
          apiName = 'clearUsers';
          break;
        case 'company':
          apiName = 'clearCompany';
          break;
      }

      // const { data } = await clearDataApi[apiName](botId, checkStatus);
      return clearDataApi[apiName](botId, checkStatus);

      // if (data.status) {
      //   return data;
      // } else {
      //   await settingService.clearData(botId, true);
      // }
    } catch (e) {
      throw e;
    }
  },

  getWorkingTime: async companyBot => {
    try {
      const data = await settingsApi.getSettingsInfo(companyBot);

      return transformSettingInfoData(data);
    } catch (e) {
      throw e;
    }
  },

  turnWorkingHours: async (companyBot, dayOfWeeks, isOn) => {
    const isOnTemplate = 'BusinessHoursIsOn';

    try {
      const data = await settingsApi.updateSettingsInfo({
        bot_id: companyBot,
        [dayOfWeeks.toLowerCase() + isOnTemplate]: isOn,
      });

      return transformSettingInfoData(data);
    } catch (e) {
      throw e;
    }
  },

  updateWorkingTime: async (companyBot, time, days, fieldName) => {
    const isStartTime = fieldName === 'startTime';
    const startTimeTemplate = '_start_business_hours';
    const endTimeTemplate = '_end_business_hours';
    const updDays = days.map(day => {
      const { startTime, endTime } = day;
      const timeCheck = isStartTime
        ? compareTime(time, endTime)
        : compareTime(startTime, time);
      const isValidTime =
        timeCheck || (isStartTime ? time === endTime : time === startTime);
      const updDay = { ...day, [fieldName]: time, isWarning: !isValidTime };

      if (!isValidTime) {
        updDay[isStartTime ? 'endTime' : 'startTime'] = updateIncorrectTimePeriod(
          time,
          isStartTime,
        );
      }

      return updDay;
    });
    const newDataObj = updDays.reduce(
      (object, day) => ({
        ...object,
        bot_id: companyBot,
        [`${day.label.toLowerCase()}${startTimeTemplate}`]: day?.startTime,
        [`${day.label.toLowerCase()}${endTimeTemplate}`]: day?.endTime,
      }),
      {},
    );

    try {
      const data = await settingsApi.updateSettingsInfo(newDataObj);

      return transformSettingInfoData(data, updDays);
    } catch (e) {
      throw e;
    }
  },
};
