import React, { ReactNode } from 'react';
// import { EIconNames } from '@/@types/IconNames';
// import IconComponent from '@/components/IconComponent/index';
import { EIconNames } from '../../@types/IconNames';
import IconComponent from '../IconComponent';

const arrDots = [0, 1, 2];

const containerDotsStyles = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: '100%',
  height: '8px',
  gap: '4px',
};

const itemDotsStyles = {
  width: '4px',
  height: '4px',
  borderRadius: '50%',
  backgroundColor: 'white',
};

export const renderChildren = (children: ReactNode, isLoading: boolean): ReactNode => {
  if (isLoading) {
    // return <IconComponent iconName={EIconNames.DOTS} width="60" height="8" />;
    return (
      <div style={containerDotsStyles}>
        {arrDots.map(() => {
          return <div style={itemDotsStyles}></div>;
        })}
      </div>
    );
  }

  return children;
};
