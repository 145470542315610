import { EConfirmModalType } from './types/types';

export const modalConfigMap = {
  [EConfirmModalType.DELETE]: {
    buttonTitle: 'Delete',
    buttonType: 'negative',
    modalTitle: 'Delete?',
    // TODO: need replace to normal icons after merging Snackbars
    icon: '',
  },
  [EConfirmModalType.CONFIRM]: {
    buttonTitle: 'Confirm',
    buttonType: 'primary',
    modalTitle: 'Info message',
    // TODO: need replace to normal icons after merging Snackbars
    icon: '',
  },
};
