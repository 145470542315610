import React, { useEffect, useState } from 'react';
import Button from '@wabb-ui-kit/Button/Button';
import { EButtonSize } from '@wabb-ui-kit/Button/types/types';
import FormField from '@wabb-ui-kit/FormField/FormField.tsx';
import { EComponentType } from '@wabb-ui-kit/FormField/types/types';
import { useInternationalization } from '@hooks/useTranslationHook';

import './EditingField.scss';

export const EditingField = ({ onClick, value, onClose, maxLength }) => {
  const [fieldValue, setFieldValue] = useState(value);
  const { formatMessage } = useInternationalization();

  const handleChangeValue = e => {
    if (e.target && e.target.value) {
      setFieldValue(e.target.value);
    } else {
      setFieldValue('');
    }
  };

  useEffect(() => {
    setFieldValue(value);
  }, [value]);

  return (
    <div className="editing-field">
      <div className="editing-field__container">
        <FormField
          Component={EComponentType.Input}
          onChange={e => handleChangeValue(e)}
          value={fieldValue}
          customClass="editing-field__container_field"
          maxLength={maxLength}
          width={256}
          onCrossClick={() => setFieldValue('')}
        />
      </div>
      <div className="editing-field__button-block">
        <Button type="secondary" style="tint" onClick={onClose} size={EButtonSize.S}>
          {formatMessage('Cancel')}
        </Button>
        <Button onClick={() => onClick(fieldValue)} size={EButtonSize.S}>
          {formatMessage('Save')}
        </Button>
      </div>
    </div>
  );
};
