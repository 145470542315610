export const ArrowNavigationClose = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
    >
      <path
        d="M9.35196 9.99969L11.9414 13.144C12.1609 13.4105 12.1227 13.8044 11.8563 14.0238C11.5898 14.2432 11.1959 14.2051 10.9765 13.9387L8.05984 10.397C7.86978 10.1662 7.86978 9.83316 8.05984 9.60238L10.9765 6.06071C11.1959 5.79426 11.5898 5.75614 11.8563 5.97557C12.1227 6.195 12.1609 6.58889 11.9414 6.85534L9.35196 9.99969Z"
        fill="#F9FAFB"
      />
    </svg>
  );
};
