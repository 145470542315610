import React, { useEffect } from 'react';
import { IOption } from '@wabb-ui-kit/Dropdown/Dropdown';
import SearchIcon from '../../../../wabb-assets/svg/Dropdown/search.svg';
import CloseIcon from '../../../../wabb-assets/svg/Dropdown/close.svg';

import styles from '../../Dropdown.module.scss';

interface SearchSelectProps {
  searchTerm: string;
  setSearchTerm: (term: string) => void;
  setIsOpen: (open: boolean) => void;
  isOpen: boolean;
  hasSelectedOptions: boolean;
  singleSelectedOption: IOption | null;
}

const SearchSelect: React.FC<SearchSelectProps> = ({
  searchTerm,
  setSearchTerm,
  setIsOpen,
  isOpen,
  hasSelectedOptions,
  singleSelectedOption,
}) => {
  useEffect(() => {
    if (!isOpen && singleSelectedOption) {
      setSearchTerm(singleSelectedOption.name || '');
    }
  }, [isOpen, setSearchTerm, singleSelectedOption]);

  return (
    <div className={styles.inputContainer}>
      <div className={styles.iconLeft}>
        {/* <IconComponent iconName={EIconNames.LOOP} /> */}
        <img src={SearchIcon} />
      </div>
      <input
        type="text"
        placeholder="Search"
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
        className={styles.dropdownSearch}
        onClick={() => setIsOpen(true)}
      />
      {isOpen && hasSelectedOptions && (
        <div onClick={() => setSearchTerm('')} className={styles.iconRight}>
          {/* <IconComponent iconName={EIconNames.CLOSE} /> */}
          <img src={CloseIcon} />
        </div>
      )}
    </div>
  );
};

export default SearchSelect;
