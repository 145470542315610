import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { Button } from '@components/common';
import { useInternationalization } from '@hooks/useTranslationHook';
import { getSelectedCompanySelector } from '@redux/companies/companiesSelectors';
import { companiesApi } from '@api/companiesApi';
import { companiesActions } from '@redux/companies/companiesActions';
import { checkCompanyStatus } from '@helpers';
import { modalActions } from '../../containers/Modal/modalActions';
import { MODALS } from '../../containers/Modal/modalConstants';
import { getModalDataSelector } from '../../containers/Modal/modalSelectors';
import { ModalContainer } from '../../containers/Modal/ModalContainer';

import './frozenModal.scss';

const styles = {
  width: '70%',
  left: '70%',
};

const overlayStyles = {
  zIndex: 10,
};

export const FrozenModal = () => {
  const { formatMessage } = useInternationalization();
  const dispatch = useDispatch();
  const url = useLocation();

  const { modalData } = useSelector(getModalDataSelector);
  const { isOwner } = modalData;
  const selectedCompany = useSelector(getSelectedCompanySelector);

  useEffect(() => {
    if (
      url.pathname.includes('/profile') ||
      url.pathname.includes('/franchise') ||
      url.pathname.includes('/login')
    ) {
      dispatch(modalActions.closeModal());
    }
  }, [url]);

  const handleSubmit = () => {
    if (selectedCompany.type === 2) {
      dispatch(modalActions.closeModal());
      dispatch(
        modalActions.openConfirmModal({
          title: formatMessage('Activate company'),
          text: formatMessage('Are you sure you want to activate this company?'),
          confirmButtonText: formatMessage('Yes, activate'),
          type: 'delete',
          handleConfirm: () => {
            companiesApi.activateCompany(selectedCompany.id).then(res => {
              dispatch(companiesActions.setUpdatedCompany(res.data));
            });
          },
          onClose: () => {
            dispatch(modalActions.closeModal());
            checkCompanyStatus(selectedCompany.type, selectedCompany.is_owner, dispatch);
          },
        }),
      );
    }
  };

  return (
    <ModalContainer
      modal={MODALS.frozen}
      hideCross
      customStyles={styles}
      overlayStyles={overlayStyles}
    >
      <div className="frozen-modal">
        <h2 className="frozen-modal__description">
          {formatMessage(
            'This license is frozen. After activating it, the contracted time starts to count. To activate it, please press the button.',
          )}
        </h2>
        {isOwner && (
          <div className="frozen-modal__button">
            <Button onClick={handleSubmit}>{formatMessage('Activate license')}</Button>
          </div>
        )}
      </div>
    </ModalContainer>
  );
};
