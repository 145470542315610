import { all, takeLatest, put } from 'redux-saga/effects';
import jwtDecode from 'jwt-decode';
import { authApi, resetAuthorization } from '@api/authApi';
import { modalActions } from '@components/containers/Modal/modalActions';
import { authActions } from './authActions';
import { loaderActions } from '@redux/loader/loaderActions';
import {
  ACTIVATE_ACCOUNT,
  AUTH,
  LOGOUT,
  RESET_PASSWORD,
  SEND_USER_INFORMATION,
  REG,
} from './authConstants';
import { Routes } from '../../const/routes';

import { ConnectError, Code } from '@connectrpc/connect';

function* authSaga({
  payload: {
    email,
    password,
    franchise_code,
    history,
    location,
    supportEmail,
    addToast,
    SMSResponse,
  },
}) {
  try {
    let response;

    if (SMSResponse) {
      response = SMSResponse;
    } else {
      response = yield authApi.auth(email, password, franchise_code);
    }

    const token = response.access;
    const sessionCode = response.session_code;
    const userId = response.user_id;
    const refresh = response.refresh;
    const isFranchiseOwner = response.is_franchise_owner ?? false;
    const userType = response.user_type ?? '';
    const isEmailRegistrationInProgress =
      response.is_email_registration_in_processing ?? false;

    if (!!response?.access) {
      localStorage.setItem('authToken', token);
      localStorage.setItem('refreshToken', refresh);
      localStorage.setItem('isFranchiseOwner', JSON.stringify(isFranchiseOwner));
      localStorage.setItem('userType', JSON.stringify(userType));
      localStorage.setItem(
        'isEmailRegistrationInProgress',
        JSON.stringify(isEmailRegistrationInProgress),
      );
      const { user_id, first_name, last_name } = jwtDecode(token);
      const fullName = `${first_name} ${last_name}`;
      localStorage.setItem('fullName', JSON.stringify(fullName));
      localStorage.setItem('currentUserEmail', JSON.stringify(email));
      yield put(
        authActions.setAuthData(
          token,
          user_id,
          isFranchiseOwner,
          isEmailRegistrationInProgress,
          userType,
          fullName,
        ),
      );
      const { from } = location.state || { from: { pathname: '/' } };
      history.replace(from);
    } else if (response.status === 208) {
      // history.push(Routes.TwoFactorAuthRoute);
      yield put(
        modalActions.openTwoFactorAuthenticationModal({
          userId,
          sessionCode,
          phone: data.phone,
        }),
      );
      yield put(authActions.setTwoFaData(userId, sessionCode));
    }
  } catch (err) {
    const connectErr = ConnectError.from(err);
    if (connectErr && connectErr.code === Code.Unauthenticated) {
      addToast({
        title: 'Error',
        type: 'error',
        message: 'Password does not match or account non-existent.',
      });
    }

    if (connectErr && connectErr.code === Code.PermissionDenied) {
      addToast({
        title: 'Error',
        type: 'error',
        message: [
          'You have entered too many incorrect passwords. You can try again in minutes',
          { minutes_remaining: err.response.data.minutes_remaining },
        ],
      });
    }

    // if (err.response && err.response.status === 405) {
    //   addToast({
    //     title: 'Error',
    //     type: 'error',
    //     message: [
    //       // 'This account is registered at. Ask to delete this account before registering in BotConversa or register a different email in BotConversa',
    //       'Error',
    //       {
    //         franchise_name: err.response.data.detail ? err.response.data.detail : 'wabb',
    //       },
    //     ],
    //   });
    // }

    // if (err.response && err.response.status === 406) {
    //   addToast({
    //     title: 'Error',
    //     type: 'error',
    //     message: [
    //       'You have entered too many incorrect passwords. You have been blocked from logging in, please contact support to unblock',
    //       { support_email: supportEmail || 'suporte@wabb.com.br' },
    //     ],
    //   });
    // }

    // if (err.response && err.response.status === 415) {
    //   addToast({
    //     title: 'Error',
    //     type: 'error',
    //     message: 'Unconfirmed account. You can resend email for confirmation.',
    //   });
    //   yield put(authActions.showResendEmailConfirmation());
    // }
  }
}

function* sendUserInformationSaga(action) {
  try {
    const { payload } = action;
    const { email, confirmationCode, firstName, lastName, password, phone } = payload;
    const response = yield authApi.sendUserInformation({
      email,
      confirmationCode,
      firstName,
      lastName,
      password,
      phone,
    });
    const data = response;
    const token = data.access;
    const { refresh } = data;
    const isFranchiseOwner = false;
    const userType = 0;
    const isEmailRegistrationInProgress = false;
    // if (response.status === 200) {
    yield put(authActions.redirectToNextPage(Routes.AddYourCompanyRoute));
    localStorage.setItem('authToken', token);
    localStorage.setItem('refreshToken', refresh);
    localStorage.setItem('isFranchiseOwner', JSON.stringify(isFranchiseOwner));
    localStorage.setItem('userType', JSON.stringify(userType));
    localStorage.setItem(
      'isEmailRegistrationInProgress',
      JSON.stringify(isEmailRegistrationInProgress),
    );
    const { user_id, first_name, last_name } = jwtDecode(token);
    const data123 = jwtDecode(token);
    const fullName = `${first_name} ${last_name}`;
    localStorage.setItem('fullName', JSON.stringify(fullName));
    localStorage.setItem('currentUserEmail', JSON.stringify(email));
    yield put(
      authActions.setAuthData(
        token,
        user_id,
        isFranchiseOwner,
        isEmailRegistrationInProgress,
        userType,
        fullName,
      ),
    );
    const { from } = location.state || { from: { pathname: '/' } };
    history.replace(from);
    // }
  } catch (err) {
    if (err.response && err.response.status === 404) {
      yield put(authActions.callErrorMessage('Something went wrong'));
    }
  }
}

function* activateAccountSaga(action) {
  try {
    const { payload } = action;
    const { email, confirmationCode } = payload;
    const response = yield authApi.confirmationCode(email, confirmationCode);

    // if (response.status === 200) {
    yield put(authActions.redirectToNextPage(Routes.RegisterAccountRoute));
    // }
  } catch (err) {
    const connectErr = ConnectError.from(err);
    if (connectErr && connectErr.code === Code.InvalidArgument) {
      yield put(authActions.callErrorMessage('Wrong confirmation code'));
    } else if (connectErr && connectErr.code === Code.NotFound) {
      yield put(authActions.callErrorMessage('Something went wrong'));
    }
  }
}

function* regSaga(action) {
  try {
    const { payload } = action;
    const { email, code } = payload;
    const response = yield authApi.newReg(email, code);

    yield put(authActions.redirectToNextPage(Routes.PasswordRoute));
  } catch (err) {
    // yield put(authActions.redirectToNextPage(Routes.ActivateAccountRoute));
    yield put(authActions.callErrorMessage('Incorrect email'));
  }
}

// function* addYourCompanySaga(action) {
//   try {
//     const { payload } = action;
//     const { name, addServerToBot } = payload;
//     const response = yield authApi.addYourCompany(name, addServerToBot);
//     if (response.status >= 200 && response.status <= 300) {
//       yield put(companiesActions.getCompaniesSuccess(response.data));
//       yield put(authActions.redirectToNextPage(Routes.DashboardRoute));
//     }
//   } catch (err) {
//     yield put(authActions.redirectToNextPage(Routes.LoginRoute));
//   }
// }

function* resetPasswordSaga(action) {
  const { payload } = action;
  const { email } = payload;
  yield authApi.resetPassword(email);
}

function* logOutSaga() {
  try {
    resetAuthorization();
    localStorage.removeItem('authToken');
    localStorage.removeItem('refreshToken');
    localStorage.removeItem('selectedCompany');
    localStorage.removeItem('isFranchiseOwner');
    localStorage.removeItem('isEmailRegistrationInProgress');
    localStorage.removeItem('userType');
    localStorage.removeItem('fullName');
    localStorage.removeItem('currentUserEmail');
    yield put(loaderActions.loaderOff());
    yield put(authActions.logOutSuccess());
  } catch (err) {
    console.log('err', err);
  }
}

function* sagas() {
  yield all([
    takeLatest(AUTH, authSaga),
    takeLatest(REG, regSaga),
    takeLatest(LOGOUT, logOutSaga),
    takeLatest(ACTIVATE_ACCOUNT, activateAccountSaga),
    takeLatest(SEND_USER_INFORMATION, sendUserInformationSaga),
    // takeLatest(ADD_YOUR_COMPANY, addYourCompanySaga),
    takeLatest(RESET_PASSWORD, resetPasswordSaga),
  ]);
}

export const authSagas = sagas;
