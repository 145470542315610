import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import FadeLoader from 'react-spinners/FadeLoader';

import { MODALS } from '../../containers/Modal/modalConstants';
import { getModalDataSelector } from '../../containers/Modal/modalSelectors';
import { groupCampaignsApi } from '../../../api/groupCampaigns';
import { notificationWindowActions } from '../../../redux/notificationWindow/notificationWindowActions';
import { useInternationalization } from '../../../hooks/useTranslationHook';
import { Button } from '../../common/Button';
import { ModalContainer } from '../../containers/Modal/ModalContainer';
import { CreateGroupCampaignsPart1 } from './part1/CreateGroupCampaignsPart1';
import { CreateGroupCampaignsPart2 } from './part2/CreateGroupCampaignsPart2';

import './createGroupCampaignModal.scss';

const styles = {
  width: '45%',
};

export const CreateGroupCampaignModal = () => {
  const { modalData } = useSelector(getModalDataSelector);
  const [form, setForm] = useState({
    bot: modalData.companyBot,
    name: '',
    groups_name: '',
    description: '',
    start_number: '',
    spam_keywords: '',
    only_admins_can_send_messages: false,
    admins: '',
    payment_webhooks: [],
  });
  const [avatar, setAvatar] = useState(null);
  let [createStep, setCreateStep] = useState(1);
  const [warning, setWarning] = useState(null);

  const dispatch = useDispatch();
  const { formatMessage } = useInternationalization();

  const validations = () => {
    if (
      form.name.length > 0 &&
      form.start_number.length > 0 &&
      form.groups_name.length > 0
    ) {
      setCreateStep(++createStep);
      setWarning(false);
    } else {
      setWarning(true);
    }
  };

  useEffect(() => {
    if (createStep === 3) {
      groupCampaignsApi
        .createGroupCampaign(form)
        .then(res => {
          if (avatar) {
            const data = new FormData();
            data.append('avatar', avatar, avatar.name);
            groupCampaignsApi
              .updateGroupCampaigns(res.data.id, data)
              .then(res => {
                modalData.setUpdateGroups(res.data.id);
              })
              .catch(e => {
                console.log('ERROR', e);
              });
          } else {
            modalData.setUpdateGroups(res.data.id);
            dispatch(
              notificationWindowActions.openWindow(true, {
                label: formatMessage('Success'),
                type: 'success',
                text: formatMessage(
                  `Group Campaigns "${res.data.name}" successful created`,
                ),
              }),
            );
          }
        })
        .catch(e => {
          console.log('error', e);
        });
      modalData.onClose();
    }
  }, [createStep]);

  const steps = [
    {
      id: 1,
      title: 'Create Your Group Campaign - Part 1',
      component: (
        <CreateGroupCampaignsPart1
          form={form}
          setForm={setForm}
          avatar={avatar}
          setAvatar={setAvatar}
          warning={warning}
        />
      ),
      button_text: 'Go To Part 2',
    },
    {
      id: 2,
      title: 'Create Your Group Campaign - Part 2',
      component: (
        <CreateGroupCampaignsPart2
          form={form}
          setForm={setForm}
          botId={modalData.companyBot}
        />
      ),
      button_text: 'Create Group Campaigns',
    },
    {
      id: 3,
      title: '',
      component: (
        <div className="loader">
          <FadeLoader size={150} color="#5925dc" loading />
        </div>
      ),
    },
  ];

  return (
    <ModalContainer
      modal={MODALS.groupCampaigns}
      onClose={modalData.onClose}
      customStyles={styles}
    >
      {steps.map(item => {
        if (item.id === createStep) {
          return (
            <div className="group-campaigns-created-modal">
              <div className="group-campaigns-created-modal__header">
                <span>{item.title}</span>
              </div>
              {item.component}
              <div
                className="group-campaigns-created-modal__form-group"
                style={{ paddingBottom: '16px' }}
              >
                <div
                  style={{
                    color: 'red',
                    paddingBottom: warning ? '16px' : '32px',
                    // fontWeight: '100',
                    fontSize: '12px',
                  }}
                >
                  {warning
                    ? 'Name, Group Name and Start Number Group it`s required fields'
                    : ''}
                </div>
                <Button onClick={() => validations()}>
                  <span>{item.button_text}</span>
                </Button>
              </div>
            </div>
          );
        }
      })}
    </ModalContainer>
  );
};
