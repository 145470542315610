import React from 'react';
import { audienceActions } from '@redux/audience/audienceActions';
import { broadcastActions } from '@redux/broadcast/broadcastActions';

export const parseQueryString = (source, queryStringURL, dispatch, stats, admins) => {
  const setFilterType = value => {
    const action =
      source === 'Audience'
        ? audienceActions.setFilterType
        : broadcastActions.setFilterTypeToBroadcast;
    dispatch(action(value));
  };

  const setSearchName = value => {
    const action =
      source === 'Audience'
        ? audienceActions.setSearchName
        : broadcastActions.setBroadcastSearchName;

    dispatch(action(value));
  };

  const addEntityFilter = filterItem => {
    const action =
      source === 'Audience'
        ? audienceActions.addFilter
        : broadcastActions.addFilterToBroadcast;
    const value = Number(filterItem[1]);
    const [filterName, condition] = filterItem[0].split('__');
    const labelEntity = stats[`${filterName.toLowerCase()}s`].find(
      el => el.id === Number(filterItem[1]),
    );

    labelEntity
      ? dispatch(
          action({
            filterName: filterName.replaceAll('_', ' '),
            condition: condition.replaceAll('_', ' '),
            value: {
              value,
              label: labelEntity.name,
            },
          }),
        )
      : null;
  };

  const addAdminFilter = filterItem => {
    const action =
      source === 'Audience'
        ? audienceActions.addFilter
        : broadcastActions.addFilterToBroadcast;
    const value = Number(filterItem[1]);
    const [filterName, condition] = filterItem[0].split('__');
    const labelEntity = admins.find(el => el.id === Number(filterItem[1]));
    labelEntity
      ? dispatch(
          action({
            filterName: filterName.replaceAll('_', ' '),
            condition: condition.replaceAll('_', ' '),
            value: {
              value,
              label: labelEntity.full_name,
            },
          }),
        )
      : null;
  };

  const addSimpleFilter = filterItem => {
    const action =
      source === 'Audience'
        ? audienceActions.addFilter
        : broadcastActions.addFilterToBroadcast;
    const [filterName, condition] = filterItem[0].split('__');

    dispatch(
      action({
        filterName: filterName.replaceAll('_', ' '),
        condition: condition.replaceAll('_', ' '),
        value: filterItem[1],
      }),
    );
  };

  const addVariablesFilter = filterItem => {
    const action =
      source === 'Audience'
        ? audienceActions.addFilter
        : broadcastActions.addFilterToBroadcast;
    const [variableName, variableCondition] = filterItem[0].split('__');
    const [variableId, variableValue] = filterItem[1].split('::');

    dispatch(
      action({
        filterName: variableName.replaceAll('_', ' '),
        condition: variableCondition.replaceAll('_', ' '),
        value: variableValue,
        variableId,
      }),
    );
  };

  for (const queryItem of queryStringURL.entries()) {
    switch (queryItem[0].toLowerCase()) {
      case 'filter_type':
        setFilterType(Number(queryItem[1]));
        break;
      case 'search_name':
        setSearchName(queryItem[1]);
        break;
      case 'tag__is':
      case 'tag__is_not':
      case 'sequence__is':
      case 'sequence__is_not':
      case 'campaign__is':
      case 'campaign__is_not':
        addEntityFilter(queryItem);
        break;
      case 'phone__is':
      case 'phone__is_not':
      case 'first_name__is':
      case 'first_name__is_not':
      case 'last_name__is':
      case 'last_name__is_not':
      case 'full_name__is':
      case 'full_name__is_not':
      case 'subscribed__after':
      case 'subscribed__before':
      case 'subscribed__on':
      case 'gender__is':
      case 'gender__is_not':
      case 'phone_code__is':
      case 'phone_code__is_not':
      case 'user_id__is':
      case 'user_id__is_not':
      case 'case__is':
      case 'case__is_not':
      case 'email__is':
      case 'email__is_not':
      case 'assigned_manager__is':
      case 'assigned_manager__is_not':
        addAdminFilter(queryItem);
        break;
      default:
        addVariablesFilter(queryItem);
    }
  }
};
