import { createPromiseClient, ConnectError, Code } from '@connectrpc/connect';
import { createConnectTransport } from '@connectrpc/connect-web';
import { authActions } from '@redux/auth/authActions';
import { store } from '@redux/store';
import { Mutex } from 'async-mutex';

// constructor APIs
import { StartFlowBlocksAPI } from 'wabb-backend-api/build/es/start_flow_blocks/start_flow_blocks_connect';
import { SmartDelayBlocksAPI } from 'wabb-backend-api/build/es/smart_delay_blocks/smart_delay_blocks_connect';
import { ConditionBlocksAPI } from 'wabb-backend-api/build/es/condition_blocks/condition_blocks_connect';
import { ContentBlocksAPI } from 'wabb-backend-api/build/es/content_blocks/content_blocks_connect';
import { ActionBlocksAPI } from 'wabb-backend-api/build/es/action_blocks/action_blocks_connect';
import { IntegrationBlocksAPI } from 'wabb-backend-api/build/es/integration_blocks/integration_blocks_connect';
import { RandomizerBlocksAPI } from 'wabb-backend-api/build/es/randomizer_blocks/randomizer_blocks_connect';
import { MenuBlocksAPI } from 'wabb-backend-api/build/es/menu_blocks/menu_blocks_connect';
import { ConstructorPreviewAPI } from 'wabb-backend-api/build/es/constructor_preview/constructor_preview_connect';
import { BlocksAPI } from 'wabb-backend-api/build/es/blocks/blocks_connect';

import { JWTService } from 'wabb-backend-api/build/es/auth/jwt/jwt_connect';
import { CompanyAPI } from 'wabb-backend-api/build/es/company/company_connect';
import { FlowsAPI } from 'wabb-backend-api/build/es/flows/flows_connect';
import { WebHooksAPI } from 'wabb-backend-api/build/es/webhooks/webhooks_connect';
import { WebhookAutomationAPI } from 'wabb-backend-api/build/es/webhook-automation/webhook-automation_connect';
import { ZapierAPI } from 'wabb-backend-api/build/es/zapier/zapier_connect';
import { ManagerAPI } from 'wabb-backend-api/build/es/manager/manager_connect';
import { VariablesAPI } from 'wabb-backend-api/build/es/variables/variables_connect';
import { SequenceAPI } from 'wabb-backend-api/build/es/sequence/sequence_connect';
import { TagAPI } from 'wabb-backend-api/build/es/tag/tag_connect';
import { SettingsAPI } from 'wabb-backend-api/build/es/settings/settings_connect';
import { ServersAPI } from 'wabb-backend-api/build/es/servers/servers_connect';
import { AdsSourcesAPI } from 'wabb-backend-api/build/es/ads_sources/ads_sources_connect';
import { BotAPI } from 'wabb-backend-api/build/es/bot/bot_connect';
import { BotAdminsAPI } from 'wabb-backend-api/build/es/bot_admins/bot_admins_connect';
import { BotAnswerAPI } from 'wabb-backend-api/build/es/bot_answer/bot_answer_connect';
import { BroadcastAPI } from 'wabb-backend-api/build/es/broadcast/broadcast_connect';
import { CustomPaymentAPI } from 'wabb-backend-api/build/es/custom_payment/custom_payment_connect';
import { CustomUserAPI } from 'wabb-backend-api/build/es/custom_user/custom_user_connect';
import { FoldersAPI } from 'wabb-backend-api/build/es/folders/folders_connect';
import { KeywordsAPI } from 'wabb-backend-api/build/es/keywords/keywords_connect';
import { LiveChatAPI } from 'wabb-backend-api/build/es/live_chat/live_chat_connect';
import { UserAPI } from 'wabb-backend-api/build/es/user/user_connect';
import { ProfileAPI } from 'wabb-backend-api/build/es/profile/profile_connect';
import { PipelinesUserAPI } from 'wabb-backend-api/build/es/pipelines_user/pipelines_user_connect';
// import { UTMServiceAPI } from 'wabb-backend-api/build/es/utm/utm_connect';

import { VITE_IS_DEV, VITE_API_URL } from '../app/config';

const getErrorMessage = (code) => {
  switch (code) {
    case Code.PermissionDenied:
      return 'You do not have permission to access this resource. Please contact your administrator.';
    case Code.ResourceExhausted:
      return 'The resource is currently unavailable. Please try again later.';
    case Code.Internal:
      return 'An internal error occurred. Please try again later.';
    default:
      return 'An unknown error occurred. Please contact your administrator.';
  }
};

const tokenRefreshMutex = new Mutex();

async function handleTokenRefresh(req) {
  const release = await tokenRefreshMutex.acquire();

  try {
    const refreshToken = window.localStorage.getItem('refreshToken');

    if (!refreshToken) {
      store.dispatch(authActions.logOut());
      return;
    }

    const response = await jwtApi.tokenRefresh({ refresh: refreshToken });
    const newToken = response.access;

    window.localStorage.setItem('authToken', newToken);
    req.header.set('Authorization', `Bearer ${newToken}`);
  } catch (error) {
    const connectErr = ConnectError.from(error);

    if (connectErr) {
      // TODO: can be improved, for example, if network error then recall handleTokenRefresh and so on
      // if (connectErr.code === Code.Unauthenticated || connectErr.code === Code.Internal)
      store.dispatch(authActions.logOut());
    }
  } finally {
    release();
  }
}

const jwtOptions = {
  baseUrl: VITE_API_URL,
  useBinaryFormat: !VITE_IS_DEV,
};

export const jwtApi = createPromiseClient(JWTService, createConnectTransport(jwtOptions));

const options = {
  baseUrl: VITE_API_URL,
  useBinaryFormat: !VITE_IS_DEV,
  interceptors: [
    next => async req => {
      const token = window.localStorage.getItem('authToken');
      if (token) {
        req.header.set('Authorization', `Bearer ${token}`);
      }
      return next(req);
    },
    next => async req => {
      try {
        return await next(req);
      } catch (err) {
        const connectErr = ConnectError.from(err);

        if (connectErr && connectErr.code === Code.Unauthenticated) {
          await handleTokenRefresh(req);
          return next(req);
        }

        // TODO: will be uncommented after implementation custom toast
        // const errorMessage = getErrorMessage(connectErr.code);
        // alert(errorMessage);
        // store.dispatch(authActions.logOut());

        return Promise.reject(err);
      }
    },
  ],
};

// TODO: will be implemented later
// export const UTMServiceApiRPC = createPromiseClient(UTMServiceAPI, createConnectTransport(options),);

export const companyApi = createPromiseClient(
  CompanyAPI,
  createConnectTransport(options),
);

export const flowBlockApiRPC = createPromiseClient(
  StartFlowBlocksAPI,
  createConnectTransport(options),
);

export const audienceApiRpc = createPromiseClient(
  PipelinesUserAPI,
  createConnectTransport(options),
);

export const profileApiRPC = createPromiseClient(
  ProfileAPI,
  createConnectTransport(options),
);

export const tagsApiRPC = createPromiseClient(TagAPI, createConnectTransport(options));

export const adsSourceApiRPC = createPromiseClient(
  AdsSourcesAPI,
  createConnectTransport(options),
);

export const foldersApiRPC = createPromiseClient(
  FoldersAPI,
  createConnectTransport(options),
);

export const userApiRPC = createPromiseClient(UserAPI, createConnectTransport(options));

export const liveChatApiRPC = createPromiseClient(
  LiveChatAPI,
  createConnectTransport(options),
);

export const customUserApiRPC = createPromiseClient(
  CustomUserAPI,
  createConnectTransport(options),
);

export const sequencesApiRPC = createPromiseClient(
  SequenceAPI,
  createConnectTransport(options),
);

export const blocksApiRPC = createPromiseClient(
  BlocksAPI,
  createConnectTransport(options),
);

export const keywordsApiRPC = createPromiseClient(
  KeywordsAPI,
  createConnectTransport(options),
);

export const broadcastApiRPC = createPromiseClient(
  BroadcastAPI,
  createConnectTransport(options),
);

export const flowsApiRPC = createPromiseClient(FlowsAPI, createConnectTransport(options));

export const botApiRPC = createPromiseClient(BotAPI, createConnectTransport(options));

export const botAdminsApiRPC = createPromiseClient(
  BotAdminsAPI,
  createConnectTransport(options),
);

export const webhooksApi = createPromiseClient(
  WebHooksAPI,
  createConnectTransport(options),
);

export const webhookAutomationApi = createPromiseClient(
  WebhookAutomationAPI,
  createConnectTransport(options),
);

export const zapierApi = createPromiseClient(ZapierAPI, createConnectTransport(options));

export const managerApi = createPromiseClient(
  ManagerAPI,
  createConnectTransport(options),
);

export const variablesApiRPC = createPromiseClient(
  VariablesAPI,
  createConnectTransport(options),
);

export const settingsAPI = createPromiseClient(
  SettingsAPI,
  createConnectTransport(options),
);

export const serverApiRPC = createPromiseClient(
  ServersAPI,
  createConnectTransport(options),
);

export const ConditionBlocksApiRPC = createPromiseClient(
  ConditionBlocksAPI,
  createConnectTransport(options),
);

export const smartDelayBlocksApiRPC = createPromiseClient(
  SmartDelayBlocksAPI,
  createConnectTransport(options),
);

export const randomizerBlocksApiRPC = createPromiseClient(
  RandomizerBlocksAPI,
  createConnectTransport(options),
);

export const actionBlockApiRPC = createPromiseClient(
  ActionBlocksAPI,
  createConnectTransport(options),
);

export const menuBlockApiRPC = createPromiseClient(
  MenuBlocksAPI,
  createConnectTransport(options),
);

export const contentBlockApiRPC = createPromiseClient(
  ContentBlocksAPI,
  createConnectTransport(options),
);