import axios from 'axios';

import api from './baseApi';
import { jwtApi, customUserApiRPC, userApiRPC } from '@connectApi/baseApi';
import {
  EmailRequest,
  ConfirmationCodeRequest,
  UserRequest,
} from 'wabb-backend-api/build/es/custom_user/custom_user_pb';
import { UsersResetPasswordRequest } from 'wabb-backend-api/build/es/user/user_pb.js';

export const authApi = {
  auth: (email, password, franchise_code) =>
    jwtApi.tokenObtainPair({
      email,
      password,
      franchise_code,
    }),

  newReg: (email, code) =>
    customUserApiRPC.checkEmailExistOrNo(
      EmailRequest.fromJson({
        email,
        code,
      }),
    ),

  confirmationCode: (email, confirmationCode) =>
    customUserApiRPC.checkConfirmationCode(
      ConfirmationCodeRequest.fromJson({
        email,
        confirmation_code: confirmationCode,
      }),
    ),

  resetPassword: email =>
    userApiRPC.usersResetPassword(UsersResetPasswordRequest.fromJson({ email })),

  sendUserInformation: ({ email, confirmationCode, firstName, password, phone }) =>
    customUserApiRPC.finishRegistration(
      UserRequest.fromJson({
        email,
        confirmation_code: confirmationCode,
        password,
        full_name: firstName,
        phone,
      }),
    ),
};

export const initAuthorization = () => {
  const token = localStorage.getItem('authToken');

  if (token) {
    api.defaults.headers.common.Authorization = `Bearer ${token}`;
    axios.defaults.headers.common.Authorization = `Bearer ${token}`;
  }
};

export const resetAuthorization = () => {
  delete api.defaults.headers.common.Authorization;
  delete axios.defaults.headers.common.Authorization;
};
