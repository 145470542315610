import React from 'react';

export const FailedPasswordConditionIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M4.20503 4.20503C4.47839 3.93166 4.92161 3.93166 5.19498 4.20503L11.795 10.805C12.0683 11.0784 12.0683 11.5216 11.795 11.795C11.5216 12.0683 11.0784 12.0683 10.805 11.795L4.20503 5.19498C3.93166 4.92161 3.93166 4.47839 4.20503 4.20503Z"
      fill="#98A2B3"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M11.795 4.20503C12.0683 4.47839 12.0683 4.92161 11.795 5.19498L5.19498 11.795C4.92161 12.0683 4.47839 12.0683 4.20503 11.795C3.93166 11.5216 3.93166 11.0784 4.20503 10.805L10.805 4.20503C11.0784 3.93166 11.5216 3.93166 11.795 4.20503Z"
      fill="#98A2B3"
    />
  </svg>
);
