import React, { useState } from 'react';
import { createContext } from 'use-context-selector';

const ToastContext = createContext(undefined);

const ToastProvider = ({ children }) => {
  const [toasts, setToasts] = useState([]);

  const add = toast => {
    setToasts(toasts => [...toasts, { ...toast, id: Math.random() * 10000 }]);
  };

  const remove = (toastId, ref) => {
    ref?.current?.classList.add('animate-toastOut');
    ref?.current?.addEventListener('animationend', () => {
      setToasts(toasts => toasts.filter(toast => toast.id !== toastId));
    });
  };

  return (
    <ToastContext.Provider value={{ add, remove, toasts }}>
      {children}
    </ToastContext.Provider>
  );
};

export { ToastProvider, ToastContext };
