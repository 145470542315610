import React from 'react';

import './audienceButton.scss';

export const AudienceButton = ({
  children,
  name,
  color,
  onClick,
  customClass,
  width,
  height,
  disabled,
}) => (
  <button
    name={name}
    onClick={onClick}
    className={`audience-button ${color}-color ${customClass}`}
    style={{ minWidth: width, height }}
    disabled={disabled}
  >
    {children}
  </button>
);
