import { useSelector } from 'react-redux';
import React, { useState } from 'react';
import { useContextSelector } from 'use-context-selector';

import { InputPasswordComponent } from '@components/common/InputPasswordComponent/InputPasswordComponent';
import { useInternationalization } from '@hooks/useTranslationHook';
import { ModalContainer } from '../../containers/Modal/ModalContainer';
import { Button } from '@components/common';
import { getModalDataSelector } from '../../containers/Modal/modalSelectors';
import { ToastContext } from '../../../contexts/toastsContext';
import { MODALS } from '../../containers/Modal/modalConstants';

export const PasswordModal = () => {
  const { modalData } = useSelector(getModalDataSelector);

  const [newPassword, setNewPassword] = useState('');
  const [isPasswordAccept, setIsPasswordAccept] = useState(false);

  const addToast = useContextSelector(ToastContext, ({ add }) => add);
  const { formatMessage } = useInternationalization();

  const handleSubmit = () => {
    if (isPasswordAccept) {
      modalData
        .apiFunction(newPassword)
        .then(() => {
          addToast({
            title: 'Success',
            type: 'success',
            message: 'Password changed successfully',
          });

          if (modalData.history) {
            modalData.history.push('/login');
          }
        })
        .catch(err => {
          switch (err.response.status) {
            case 400:
              addToast({
                title: 'Error',
                type: 'error',
                message: 'link is not valid',
              });
              break;
            default:
              addToast({
                title: 'Error',
                type: 'error',
                message: 'Something go wrong',
              });
              break;
          }
        });

      modalData.onClose();
    }
  };

  return (
    <ModalContainer modal={MODALS.password} onClose={modalData.onClose}>
      <div className="change_password">
        <div className="change_password__header">{formatMessage('Reset Password')}</div>
        <div className="change_password__content">
          <div className="change_password__content__field">
            <InputPasswordComponent
              setNewPassword={setNewPassword}
              setIsPasswordAccept={setIsPasswordAccept}
            />
          </div>
        </div>
        <div className="change_password__button">
          <Button
            onClick={handleSubmit}
            width="89%"
            color="green"
            disabled={!isPasswordAccept}
          >
            <span className="change_password__button__container__text">
              {formatMessage('Confirm')}
            </span>
          </Button>
        </div>
      </div>
    </ModalContainer>
  );
};
