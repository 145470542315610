import React from 'react';

export const DateVariableIcon = ({ color = '#1187F4' }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="currentColor"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M2 3.8335C2 3.28121 2.44772 2.8335 3 2.8335H13C13.5523 2.8335 14 3.28121 14 3.8335V13.1668C14 13.7191 13.5523 14.1668 13 14.1668H3C2.44772 14.1668 2 13.7191 2 13.1668V3.8335ZM13 3.8335H3V13.1668H13V3.8335Z"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M11 1.8335C11.2761 1.8335 11.5 2.05735 11.5 2.3335V4.3335C11.5 4.60964 11.2761 4.8335 11 4.8335C10.7239 4.8335 10.5 4.60964 10.5 4.3335V2.3335C10.5 2.05735 10.7239 1.8335 11 1.8335Z"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M5 1.8335C5.27614 1.8335 5.5 2.05735 5.5 2.3335V4.3335C5.5 4.60964 5.27614 4.8335 5 4.8335C4.72386 4.8335 4.5 4.60964 4.5 4.3335V2.3335C4.5 2.05735 4.72386 1.8335 5 1.8335Z"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M2 6.3335C2 6.05735 2.22386 5.8335 2.5 5.8335H13.5C13.7761 5.8335 14 6.05735 14 6.3335C14 6.60964 13.7761 6.8335 13.5 6.8335H2.5C2.22386 6.8335 2 6.60964 2 6.3335Z"
    />
  </svg>
);
