import React, { useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
import debounce from 'lodash/debounce';
import { settingService } from '@services';

import { useInternationalization } from '@hooks/useTranslationHook';
import { getBotIdSelectedCompanySelector } from '@redux/companies/companiesSelectors';
import { useContextSelector } from 'use-context-selector';
import { NegativeAlertIcon } from '@wabb-assets/svg/Modal/negative-alert-icon';
import FormField from '@wabb-ui-kit/FormField/FormField';
import { EComponentType } from '@wabb-ui-kit/FormField/types/types';
import Button from '@wabb-ui-kit/Button/Button';
import { EButtonSize } from '@wabb-ui-kit/Button/types/types';
import { ToastContext } from '../../../contexts/toastsContext';
import { getModalDataSelector } from '../../containers/Modal/modalSelectors';
import { MODALS } from '../../containers/Modal/modalConstants';
import { ModalContainer } from '../../containers/Modal/ModalContainer';

import './ClearDataModal.scss';

export const ClearDataModal = () => {
  const { formatMessage } = useInternationalization();

  const { modalData } = useSelector(getModalDataSelector);
  const { type, onClose } = modalData;
  const botId = useSelector(getBotIdSelectedCompanySelector);
  const addToasts = useContextSelector(ToastContext, ({ add }) => add);
  const [progress, setProgress] = useState(null);
  const [value, setValue] = useState('');


  const sendDebouncedReq = useCallback(
    debounce((callback) => callback(), 3000),
    [],
  );

  const handleClick = () => {
    settingService.clearData(type, botId, false).then(() => {
      setProgress(1);
      sendDebouncedReq(checkClearProcessProgress);
    });
  };

  const checkClearProcessProgress = () => {
    settingService.clearData(type, botId, true).then((res) => {
      setProgress(res.data.progress);

      if (res.data.progress !== 100) {
        sendDebouncedReq(checkClearProcessProgress);
      } else {
        onClose();
        addToasts({
          type: 'success',
          title: formatMessage(
            `${
              type[0].toUpperCase() + type.slice(1)
            } cleared`,
          ),
          message: formatMessage(
            `${
              type[0].toUpperCase() + type.slice(1)
            } was successfully cleared`,
          ),
        });
      }
    });
  };

  return (
    <ModalContainer modal={MODALS.clearData} onClose={!progress && onClose}>
      <div className="clear-data-modal">
        <div className="clear-data-modal__header">
          <NegativeAlertIcon />
          <div className="clear-data-modal__header__content">
            <span
              className="clear-data-modal__header__content-title"
            >
              { type === 'contacts' ? 'Delete database' : 'Delete company' }
            </span>
            <div className="clear-data-modal__header__content-info">
              <span>
                { type === 'contacts'
                  ? 'Are you going to delete all your contacts from your database?'
                  : 'Are you about to clear your Company? All settings and contacts and information will be permanently deleted.' }
              </span>
              <span>
                &nbsp;If you are sure, write&nbsp;
                <span className="clear-data-modal__header__content-info-agree">Agree</span>
                &nbsp;and confirm  the action.
              </span>
            </div>
          </div>
        </div>
        <div>
          <FormField
            Component={EComponentType.Input}
            value={value}
            placeholder="Type here..."
            onCrossClick={() => setValue('')}
            onChange={(e) => setValue(e.target.value)}
          />
        </div>
        <div className="clear-data-modal__buttons">
          <Button
            type="negative"
            size={EButtonSize.L}
            disabled={value !== 'Agree' || progress}
            onClick={handleClick}
          >
            Delete
          </Button>
          <Button
            type="secondary"
            style="tint"
            size={EButtonSize.L}
            onClick={onClose}
            disabled={progress}
          >
            Cancel
          </Button>
        </div>
      </div>
    </ModalContainer>
    // <ModalContainer
    //   modal={MODALS.clearData}
    //   onClose={() => !progress && modalData.onClose()}
    //   customStyles={styles}
    // >
    //   <div className="clear-data-modal">
    //     <h3 className="clear-data-modal__label">{`${formatMessage(data.label)}?`}</h3>
    //     <p className="clear-data-modal__desc">
    //       {formatMessage(data.description)}
    //       <span className="clear-data-modal__desc_bold">{` ${formatMessage(
    //         'irreversible',
    //       )} `}</span>
    //       {getClearText()}
    //     </p>
    //     <form onSubmit={formik.handleSubmit} className="clear-data-modal__confirm-form">
    //       <NewBcField
    //         name="confirm"
    //         value={formik.values.confirm}
    //         onChange={formik.handleChange}
    //         placeholder={formatMessage("Please type 'Agree'")}
    //         onBlur={formik.handleBlur}
    //         helperText={formik.touched.confirm ? (formik.isValid ? '' : ' ') : ''}
    //         disabled={progress}
    //       />
    //       <Button
    //         type="submit"
    //         color="red"
    //         customClass="clear-data-modal__btn"
    //         width="100%"
    //         disabled={!formik.values.confirm || progress}
    //       >
    //         {progress ? (
    //           <ProgressComponent progress={progress} diameter={30} color="#ffffff" />
    //         ) : (
    //           formatMessage(data.textBtn)
    //         )}
    //       </Button>
    //     </form>
    //   </div>
    // </ModalContainer>
  );
};
