import React from 'react';

import avatar from '@wabb-assets/svg/Avatar/default-big-avatar.svg'

import './UserCardInfoHeader.scss';

export const UserCardInfoHeader = ({ data }) => {


  return (
    <div className="user-card-info-header">
      <div
        className="user-card-info-header__avatar"
        style={{
          backgroundImage: `url(${data && data.avatar ? data.avatar : avatar})`,
        }}
      />
    </div>
  );
};
