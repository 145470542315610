import api from './baseApi';

export const clearDataApi = {
  clearUsers: (botId, checkStatus) =>
    checkStatus
      ? api.get(`/clear_data/users/${botId}/`)
      : api.post(`/clear_data/users/${botId}/`),

  clearCompany: (botId, checkStatus) =>
    checkStatus
      ? api.get(`/clear_data/company/${botId}/`)
      : api.post(`/clear_data/company/${botId}/`),
};
