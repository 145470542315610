import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Button from '@wabb-ui-kit/Button/Button';
import { EButtonSize } from '@wabb-ui-kit/Button/types/types';
import FormField from '@wabb-ui-kit/FormField/FormField.tsx';
import { InputPasswordComponent } from '@components/common/InputPasswordComponent/InputPasswordComponent';
import { useInternationalization } from '@hooks/useTranslationHook';
import { ModalContainer } from '../../containers/Modal/ModalContainer';
import { getModalDataSelector } from '../../containers/Modal/modalSelectors';
import { modalActions } from '@components/containers/Modal/modalActions';
import { usersApi } from '@api/usersApi';
import { MODALS } from '../../containers/Modal/modalConstants';

import './changePasswordModal.scss';

export const ChangePasswordModal = () => {
  const { formatMessage } = useInternationalization();
  const { modalData } = useSelector(getModalDataSelector);

  const dispatch = useDispatch();

  const [currentPassword, setCurrentPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [isPasswordAccept, setIsPasswordAccept] = useState(false);
  const [passType, setPassType] = useState('password');
  const [passwordError, setPasswordError] = useState('');
  const [newPasswordError, setNewPasswordError] = useState('');

  const handleChangePassword = () => {
    if (newPassword !== currentPassword) {
      if (isPasswordAccept) {
        usersApi
          .updateUserPassword({
            current_password: currentPassword,
            new_password: newPassword,
          })
          .then(res => {
            if (res) {
              modalData.successfulChangePassword();
              modalData.onClose();
            } 
             else if (res.status === 208) {
              dispatch(modalActions.closeModal());
              modalData.fetchTwoFa(res);
            }
          })
          .catch(err => {
            if (err.response.status === 400) {
              setPasswordError('Your password is invalid. Please try again.');
            }
          });
      }
    } else {
      setPasswordError("Your new password can't be the same as the old one.");
    }
  };
  const handleChangeCurrentPasswordField = event => {
    setCurrentPassword(event.target.value);
    setPasswordError('');
  };

  return (
    <ModalContainer modal={MODALS.changePassword} onClose={modalData.onClose}>
      <div className="change_password">
        <div className="change_password__header">{formatMessage('Change Password')}</div>
        <div className="change_password__content">
          <div className="change_password__content__field">
            <FormField
              id="password"
              name="password"
              label="Old password"
              fullWidth
              required
              onChange={handleChangeCurrentPasswordField}
              value={currentPassword}
              onClick={() => setPassType(passType === 'password' ? 'text' : 'password')}
              type={passType}
              placeholder={formatMessage('Old Password')}
              errorText={passwordError ? passwordError : ''}
            />
          </div>
          <InputPasswordComponent
            setNewPassword={setNewPassword}
            setIsPasswordAccept={setIsPasswordAccept}
            setConfirmPassword={setConfirmPassword}
            passwordError={passwordError}
            newPasswordError={newPasswordError}
            setNewPasswordError={setNewPasswordError}
            additionalConditions={[
              {
                text: 'mismatch between new and current passwords',
                condition: password => password !== currentPassword,
              },
            ]}
          />
        </div>
        <div className="change_password__button">
          <Button
            onClick={handleChangePassword}
            size={EButtonSize.L}
            disabled={
              !(isPasswordAccept && currentPassword && currentPassword !== newPassword)
            }
          >
            {formatMessage('Confirm')}
          </Button>
          <Button type="secondary" style="tint" size={EButtonSize.L} onClick={modalData.onClose}>
            {formatMessage('Cancel')}
          </Button>
        </div>
      </div>
    </ModalContainer>
  );
};
