import { useEffect, useMemo, useState } from 'react';
import {
  EditorState, Modifier,
  RichUtils,
} from 'draft-js';

import {
  EntityTypes,
} from '@components/common/TextEditor/constants';
import {
  decorators,
  setInitEditorState,
  transformEditorState,
} from '@components/common/TextEditor/helpers';

export const useTextEditor = (initValue, textLimit, onChange, autoFocus) => {
  const [isFocused, setFocused] = useState(false);

  const initEditorStateState = useMemo(
    () => EditorState.moveFocusToEnd(setInitEditorState(initValue, decorators)),
    [initValue]
  );

  const [editorState, setEditorState] = useState(initEditorStateState);
  const [isStylesChanged, setIsStylesChanged] = useState(false);

  const currentText = editorState?.getCurrentContent().getPlainText();
  const isSaveDisabled = (!currentText?.length
  || currentText === initValue || currentText === initValue?.text)
  && !isStylesChanged;

  const onFocus = () => {
    setFocused(true);
  };

  const onBlur = () => {
    setFocused(false);
  };

  useEffect(() => {
    if (autoFocus) {
      onFocus();
    }
  }, [autoFocus]);

  const onResetTextEditor = () => {
    setEditorState(initEditorStateState);
  };

  const onChangeTextEditor = (newEditorState) => {
    setEditorState(newEditorState);

    if (onChange) {
      onChange(...transformEditorState(newEditorState));
    }
  };

  const onChangeFieldSelect = (data) => {
    const newDataLength = data.value.length + editorState.getCurrentContent().getPlainText().length;

    if (newDataLength < textLimit || !textLimit) {
      const newContentState = Modifier.insertText(
        editorState.getCurrentContent(),
        editorState.getSelection(),
        `{${data.value}}`,
        null,
        editorState
          .getCurrentContent()
          .createEntity(EntityTypes.valueField, 'IMMUTABLE')
          .getLastCreatedEntityKey()
      );

      const updatedEditorState = EditorState.push(editorState, newContentState, 'insert-fragment');

      onChangeTextEditor(updatedEditorState);
    }
  };

  const onToggleInlineStyles = (inlineStyle) => {
    if (!isStylesChanged) {
      setIsStylesChanged(true);
    }

    return onChangeTextEditor(
      (currentState) => RichUtils.toggleInlineStyle(currentState, inlineStyle)
    );
  };

  const onCheckInlineStyles = (inlineStyle) => editorState.getCurrentInlineStyle().has(inlineStyle);

  const handleBeforeInput = (chars) => {
    if (textLimit && chars.length
        + editorState.getCurrentContent().getPlainText().length > textLimit) {
      return 'handled';
    }

    return 'not-handled';
  };

  const handlePastedText = (text) => {
    const overflowChars = text.length
      + editorState.getCurrentContent().getPlainText().length - textLimit;

    if (textLimit && overflowChars) {
      const insertText = text.substring(0, text.length - overflowChars);
      const newContent = Modifier.insertText(
        editorState.getCurrentContent(),
        editorState.getSelection(),
        insertText
      );
      onChangeTextEditor(EditorState.push(editorState, newContent, 'insert-characters'));
      return 'handled';
    }

    return 'not-handled';
  };

  return {
    editorState,
    isFocused,
    isSaveDisabled,
    onFocus,
    onBlur,
    onResetTextEditor,
    onChangeTextEditor,
    onChangeFieldSelect,
    onToggleInlineStyles,
    onCheckInlineStyles,
    handleBeforeInput,
    handlePastedText,
  };
};