import { all, takeLatest, put } from 'redux-saga/effects';
import { constructorApi } from '../../api/constructorApi';
import { constructorActions } from './constructorActions';
import decamelizeKeys from 'decamelize-keys';
import {
  GET_FLOW_DETAILS_REQUEST,
  UPDATE_BLOCK_COORDINATES_REQUEST,
  ADD_NEW_BLOCK_REQUEST,
  DELETE_BLOCK_REQUEST,
  UPDATE_RANDOMIZER_SUB_BLOCK_PERCENTAGE_REQUEST,
  GET_SHARE_FLOW_DETAILS_REQUEST,
} from './constructorConstants';

function* getFlowDetailsRequestSaga() {
  try {
    const path = window.location.href;
    const lastItem = path.substring(path.lastIndexOf('/') + 1);
    const response = yield constructorApi.getFlowDetails(lastItem);
    const parsedRes = decamelizeKeys(response, { deep: true });

    if (!!parsedRes) {
      yield put(constructorActions.getFlowDetailsSuccess(parsedRes));
    }
  } catch (err) { }
}

function* getShareFlowDetailsRequestSaga() {
  try {
    const queryString = window.location.search;
    let lastItem = queryString.substring(queryString.lastIndexOf('=') + 1);
    if (!lastItem) lastItem = localStorage.getItem('shareCode') || '';
    const response = yield constructorApi.getShareFlowDetails(lastItem);

    if (response.data) {
      yield put(constructorActions.getFlowDetailsSuccess(response.data));
    }
  } catch (err) {
    yield put(constructorActions.getShareFlowDetailsFailure(err.message));
  }
}

function* updateBlockCoordinatesRequestSaga(action) {
  const { data } = action.payload;

  try {
    const response = yield constructorApi.updateBlockCoordinates(
      data.props.id,
      data.position.x,
      data.position.y,
    );
    // if (response.data) {
    //   yield put(constructorActions.updateBlockCoordinatesSuccess());
    // }
  } catch (err) {
    yield put(constructorActions.updateBlockCoordinatesFailure());
  }
}

function* addNewBlockRequestSaga(action) {
  const { data } = action.payload;

  try {
    const response = yield constructorApi.addNewBlock(data);

    if (response.data) {
      yield put(constructorActions.addNewBlockSuccess(response.data));
    }
  } catch (err) { }
}

function* deleteBlockRequestSaga(action) {
  const { data } = action.payload;

  try {
    const response = yield constructorApi.deleteBlock(data);

    if ((response.status = 204)) {
      yield put(constructorActions.deleteBlockSuccess(data));
    }
  } catch (err) { }
}

function* updateRandomizerSubBlockPercentageSaga(action) {
  const { data } = action.payload;

  try {
    const response = yield constructorApi.updateRandomizerSubBlockPercentage(data);

    if ((response.status = 204)) {
      yield put(constructorActions.deleteBlockSuccess(data));
    }
  } catch (err) { }
}

function* sagas() {
  yield all([
    takeLatest(GET_SHARE_FLOW_DETAILS_REQUEST, getShareFlowDetailsRequestSaga),
    takeLatest(GET_FLOW_DETAILS_REQUEST, getFlowDetailsRequestSaga),
    takeLatest(UPDATE_BLOCK_COORDINATES_REQUEST, updateBlockCoordinatesRequestSaga),
    takeLatest(ADD_NEW_BLOCK_REQUEST, addNewBlockRequestSaga),
    takeLatest(DELETE_BLOCK_REQUEST, deleteBlockRequestSaga),
    takeLatest(
      UPDATE_RANDOMIZER_SUB_BLOCK_PERCENTAGE_REQUEST,
      updateRandomizerSubBlockPercentageSaga,
    ),
  ]);
}

export const constructorSagas = sagas;
