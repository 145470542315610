import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { useInternationalization } from '@hooks/useTranslationHook';
import { getSelectedCompanySelector } from '@redux/companies/companiesSelectors';
import { constructorActions } from '@redux/constructor/constructorActions';
import { integrationApi } from '@api/integrationApi';
import FormField from '@wabb-ui-kit/FormField/FormField';
import Dropdown from '@wabb-ui-kit/Dropdown/Dropdown';
import Button from '@wabb-ui-kit/Button/Button';
import { EComponentType } from '@wabb-ui-kit/FormField/types/types';
import { EButtonSize } from '@wabb-ui-kit/Button/types/types';
import { ResponseMapping } from '../responseMapping/responseMapping';
import { RequestBody } from '../requestBody/requestBody';
import { JsonEditor } from '../jsonEditor/jsonEditor';
import { Response } from '../response/response';
import { requestTypes } from '../const';

import './dynamicRequest.scss';

export const DynamicRequest = ({ onClose, blockId, block }) => {
  const { formatMessage } = useInternationalization();
  const dispatch = useDispatch();

  const [tabValue, setTabValue] = useState(0);

  const selectedCompany = useSelector(getSelectedCompanySelector);

  const [form, setForm] = useState({
    requestType: requestTypes[block.request_type],
    url: block.url ? block.url : '',
    headers: block.headers ? block.headers : [],
    body: block.body ? block.body : [],
    keys_variables: block.keys_variables ? block.keys_variables : [],
    response: {},
  });

  const [error, setError] = useState('');

  const handleChangeTab = (newValue) => {
    setTabValue(newValue);
  };

  const handleAddRequestHeader = () => {
    setForm({
      ...form,
      headers: [...form.headers, { key: '', value: '', id: form.headers.length }],
    });
  };

  const handleSaveDynamicRequest = () => {
    if (form.url === '') {
      setError(formatMessage('Url cannot be blank'));
    } else if (!form.url.startsWith('https://')) {
      setError(formatMessage('Sorry, only https links are allowed'));
    } else {
      integrationApi.updateDynamicRequestBlock(blockId, form).then((res) => {
        dispatch(constructorActions.updateIntegrationBlock(res.data, 1));
      });
      onClose();
    }
  };

  const handleAddKeysVariables = () => {
    setForm({
      ...form,
      keys_variables: [
        ...form.keys_variables,
        {
          type: '',
          value: undefined,
          id: '',
          key_variable_id: form.keys_variables.length
            ? form.keys_variables[form.keys_variables.length - 1].key_variable_id + 1
            : 0,
        },
      ],
    });
  };

  const handleAddBodyField = () => {
    setForm({
      ...form,
      bodyFields: [
        ...form.bodyFields,
        { key: '', value: '', id: form.bodyFields.length },
      ],
    });
  };

  const handleDeleteField = (type, id) => {
    const newArray = form[type].filter((item) => item[type === 'keys_variables' ? 'key_variable_id' : 'id'] !== id);
    setForm({
      ...form,
      [type]: newArray,
    });
  };

  const handleChangeSelect = (value) => {
    setForm({
      ...form,
      requestType: value,
    });
  };

  const handleChangeUrl = (event) => {
    setForm({
      ...form,
      url: event.currentTarget.value,
    });
  };

  const handleSendForm = () => {
    setError('');
    integrationApi
      .getResponse(form)
      .then((res) => {
        setForm({
          ...form,
          response: res.data,
        });
      })
      .catch(() => {
        setError(formatMessage('Sorry, server error'));
      });
  };

  const handleTestRequest = (event) => {
    event.preventDefault();
    handleChangeTab(2);

    if (form.url === '' || !form.url.startsWith('https://')) {
      if (form.url === '') {
        setError(formatMessage('Url cannot be blank'));
      } else {
        setError(formatMessage('Sorry, only https links are allowed'));
      }
    } else {
      setError('');
      integrationApi
        .getResponse(form)
        .then((res) => {
          setForm({
            ...form,
            response: res.data,
          });
        })
        .catch(() => {
          setError(formatMessage('Sorry, server error'));
        });
    }
  };

  const handleChangeField = (type, id, event) => {
    if (type === 'bodyFieldsKey') {
      const newArray = form.body.map((item) => {
        if (item.id === id) {
          return {
            key: event.currentTarget.value,
            value: item.value,
            id: item.id,
          };
        }

        return item;
      });
      setForm({
        ...form,
        body: newArray,
      });
    }

    if (type === 'bodyFieldsValue') {
      const newArray = form.body.map((item) => {
        if (item.id === id) {
          return {
            key: item.key,
            value: event.currentTarget.value,
            id: item.id,
          };
        }

        return item;
      });
      setForm({
        ...form,
        body: newArray,
      });
    }

    if (type === 'headersFieldsKey') {
      const newArray = form.headers.map((item) => {
        if (item.id === id) {
          return {
            key: event.currentTarget.value,
            value: item.value,
            id: item.id,
          };
        }

        return item;
      });
      setForm({
        ...form,
        headers: newArray,
      });
    }

    if (type === 'headersFieldsValue') {
      const newArray = form.headers.map((item) => {
        if (item.id === id) {
          return {
            key: item.key,
            value: event.currentTarget.value,
            id: item.id,
          };
        }

        return item;
      });
      setForm({
        ...form,
        headers: newArray,
      });
    }
  };

  const handleChangeResponseMapping = (key_variable_id, type, event) => {
    if (type === 'field') {
      const newArray = form.keys_variables.map((item) => {
        if (item.key_variable_id === key_variable_id) {
          return { ...item, value: event.target.value };
        }

        return item;
      });
      setForm({
        ...form,
        keys_variables: newArray,
      });
    } else {
      const newArray = form.keys_variables.map((item) => {
        if (item.key_variable_id === key_variable_id) {
          if (event.name === 'variable_type_from') {
            return {
              ...item,
              id: event.value,
              type: 'variable_type',
              label: event.label,
            };
          }

          return {
            ...item,
            id: event.value,
            type: 'bot_variable',
            label: event.label,
          };
        }

        return item;
      });
      setForm({
        ...form,
        keys_variables: newArray,
      });
    }
  };

  return (
    <div className="dynamic-request-block">
      <div className="dynamic-request-block__dynamic-request">
        <div>
          <div className="dynamic-request-block__dynamic-request__header">
            <div className="dynamic-request-block__dynamic-request__header-fields">
              <div className="dynamic-request-block__dynamic-request__header-fields_left-side">
                <div className="dynamic-request-block__dynamic-request__header-fields_left-side_title">
                  { formatMessage('Request type') }
                </div>
                <div className="dynamic-request-block__dynamic-request__form-group">
                  <Dropdown
                    options={requestTypes}
                    singleSelectedOption={form.requestType}
                    setSingleSelectedOption={handleChangeSelect}
                  />
                </div>
              </div>
              <div className="dynamic-request-block__dynamic-request__header-fields_right-side">
                <div className="dynamic-request-block__dynamic-request__form-group-url">
                  <FormField
                    Component={EComponentType.Input}
                    label={formatMessage('Request URL')}
                    placeholder="Type here..."
                    value={form.url}
                    name="customFieldName"
                    onChange={handleChangeUrl}
                    onCrossClick={() => setForm({ ...form, url: '' })}
                    infoText={!error && 'Only https links are allowed'}
                    errorText={error}
                  />
                </div>
              </div>
            </div>
            <div className="dynamic-request-block__dynamic-request__header_button">
              <Button
                type="primary"
                style="outlined"
                size={EButtonSize.L}
                width={145}
                onClick={handleTestRequest}
              >
                { formatMessage('Get test request') }
              </Button>
            </div>
          </div>
          <div className="dynamic-request-block__dynamic-request__tabs">
            <button
              className={tabValue === 0 ? 'active' : ''}
              onClick={() => handleChangeTab(0)}
            >
              Headers
            </button>
            <button
              className={tabValue === 1 ? 'active' : ''}
              onClick={() => handleChangeTab(1)}
            >
              Body
            </button>
            <button
              className={tabValue === 2 ? 'active' : ''}
              onClick={() => handleChangeTab(2)}
            >
              Response
            </button>
            <button
              className={tabValue === 3 ? 'active' : ''}
              onClick={() => handleChangeTab(3)}
            >
              Response mapping
            </button>
          </div>
          <div className="dynamic-request-block__dynamic-request__tabs-content">
            {tabValue === 0 && (
            <JsonEditor
              fields={form.headers}
              setForm={setForm}
              handleAddButton={handleAddRequestHeader}
              handleDeleteField={handleDeleteField}
              addButtonTitle="+ New Header Request"
              handleChangeField={handleChangeField}
            />
            )}
            {tabValue === 1 && (
            <RequestBody
              fields={form.bodyFields}
              setForm={setForm}
              form={form}
              handleAddButton={handleAddBodyField}
              handleDeleteField={handleDeleteField}
              addButtonTitle="+ new Body Fields"
              handleChangeField={handleChangeField}
              body={form.body}
            />
            )}
            {tabValue === 2 && <Response handleSendForm={handleSendForm} response={form.response} />}
            {tabValue === 3 && (
            <ResponseMapping
              setForm={setForm}
              fields={form.keys_variables}
              handleDeleteField={handleDeleteField}
              handleAddKeysVariables={handleAddKeysVariables}
              handleChange={handleChangeResponseMapping}
            />
            )}
          </div>
        </div>
      </div>
      <div className="dynamic-request-block__dynamic-request__footer">
        {/*<a*/}
        {/*  className="dynamic-request-block__dynamic-request__footer__link"*/}
        {/*  href={`${window.location.origin}/${selectedCompany.id}/settings/logs`}*/}
        {/*>*/}
        {/*  { formatMessage('See all Logs') }*/}
        {/*</a>*/}
        <div className="dynamic-request-block__dynamic-request__footer__buttons">
          <Button
            type="secondary"
            style="tint"
            size={EButtonSize.L}
            width={111}
            onClick={onClose}
          >
            { formatMessage('Cancel') }
          </Button>
          <Button
            type="primary"
            size={EButtonSize.L}
            width={111}
            onClick={handleSaveDynamicRequest}
          >
            { formatMessage('Save') }
          </Button>
        </div>
      </div>
    </div>
  );
};
