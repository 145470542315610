import React, { useState, useRef, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';

import './Folders.scss';

import { modalActions } from '@components/containers/Modal/modalActions';
import { useInternationalization } from '@hooks/useTranslationHook';
import { useHistory } from 'react-router-dom';
import { LocalSearchIcon } from '@icons/local-search-icon';
import { FolderItem } from './FolderItem/FolderItem';

export const Folders = ({
  folders,
  getFolders,
  onMove,
  currentFolder,
  onDelete,
  onRename,
  onFolderClick,
  title,
  isModalComponent,
  searchName,
  setSearchName,
}) => {
  const dragEl = useRef(null);
  const { formatMessage } = useInternationalization();
  const dispatch = useDispatch();
  const history = useHistory();
  const [isShowMore, setShowMore] = useState(false);
  // const searchName = new URLSearchParams(history.location.search).get('search_name');

  const handleOnDragEnter = () => {
    dragEl.current.style = 'border: 1.5px dashed #6445e0';
  };

  const handleOnDragLeave = () => {
    dragEl.current.style = 'border: 4px solid rgba(0, 0, 0, 0)';
  };

  const handleChangeSearchName = e => {
    setSearchName(e.target.value);

    if (!isModalComponent) {
      history.push(
        e.target.value ? `?search_name=${e.target.value}` : history.location.pathname,
      );
    }
  };

  const handleOnDrop = e => {
    const data = JSON.parse(e.dataTransfer.getData('transferData'));

    if (data?.type === 'botField') {
      data.toFolder = null;
      onMove(data);
    } else if (data?.type === 'userField') {
      data.toFolder = null;
      onMove(data);
    } else if (data?.folderId !== null) {
      data.toFolder = null;
      onMove(data);
      // dragEl.current.style = 'border: 1px solid #D9DFE3';
    }

    dragEl.current.style = 'border: 4px solid rgba(0, 0, 0, 0)';
  };

  const openMoveToModal = async folderId => {
    const rootFolders = await getFolders();

    dispatch(
      modalActions.openMoveToModal({
        type: 'folder',
        id: folderId,
        folders: rootFolders,
        onClose: () => {
          getFolders();
          dispatch(modalActions.closeModal());
        },
      }),
    );
  };

  const handleFolderClick = useCallback(
    folderId => {
      onFolderClick(folderId);
    },
    [onFolderClick],
  );

  return (
    <div className="folders">
      <div
        ref={dragEl}
        onDrop={e => currentFolder !== null && handleOnDrop(e)}
        onDragOver={event => {
          event.preventDefault();
        }}
        onDragEnter={e => {
          currentFolder !== null && handleOnDragEnter(e);
        }}
        onDragLeave={e => {
          currentFolder !== null && handleOnDragLeave(e);
        }}
        className="folders__title"
      >
        <div className="folders__title_path">
          <span className="main-text" onClick={() => onFolderClick(null)}>
            {title}
          </span>
          {!searchName && currentFolder?.folder_path ? (
            currentFolder.folder_path.map(({ id, name }) => (
              <span className="main-text" onClick={() => getFolders(id)} key={id}>
                <span className="main-text main-text_secondary">/</span>
                {name}
              </span>
            ))
          ) : (
            <span className="main-text main-text_secondary">{/*{folders?.length}*/}</span>
          )}
        </div>
        <div className="local-search">
          <input
            type="text"
            placeholder={formatMessage('Search')}
            onChange={handleChangeSearchName}
            value={String(searchName)}
          />
          <div>
            <LocalSearchIcon />
          </div>
        </div>
      </div>
      {folders?.length && !searchName ? (
        <div className="folders__body">
          {folders.length < 11 ? (
            folders.map(({ id, name, reference_count, daughterFolders }) => (
              <FolderItem
                key={id}
                folderId={id}
                name={name}
                referenceCount={daughterFolders?.length}
                onClick={() => handleFolderClick(id)}
                onMove={onMove}
                onRename={onRename}
                onDelete={onDelete}
                openMoveToModal={openMoveToModal}
              />
            ))
          ) : (
            <>
              {!isShowMore
                ? folders
                    .slice(0, 9)
                    .map(({ id, name, reference_count, daughterFolders }) => (
                      <FolderItem
                        key={id}
                        folderId={id}
                        name={name}
                        referenceCount={daughterFolders?.length}
                        onMove={onMove}
                        onDelete={onDelete}
                        onClick={() => handleFolderClick(id)}
                        openMoveToModal={openMoveToModal}
                      />
                    ))
                : folders.map(({ id, name, reference_count, daughterFolders }) => (
                    <FolderItem
                      key={id}
                      folderId={id}
                      name={name}
                      referenceCount={daughterFolders?.length}
                      onMove={onMove}
                      onDelete={onDelete}
                      onClick={() => handleFolderClick(id)}
                      openMoveToModal={openMoveToModal}
                    />
                  ))}
              <button
                className="flow-folders__body_show-more"
                onClick={() => setShowMore(prevState => !prevState)}
              >
                {isShowMore ? (
                  <span>{formatMessage('Show less')}</span>
                ) : (
                  <span>{formatMessage('Show more')}</span>
                )}
              </button>
            </>
          )}
        </div>
      ) : null}
    </div>
  );
};

Folders.propTypes = {
  title: PropTypes.string,
  currentFolder: PropTypes.shape({
    id: PropTypes.number,
    folder_path: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
        name: PropTypes.string,
      }),
    ),
  }),
  folders: PropTypes.array,
};
