export const Routes = {
  // DashboardRoute: '/dashboard',
  AudienceRoute: '/audience',
  CampaignsRoute: '/campaigns',
  GroupCampaignsRoute: '/campaigns/group_campaigns',
  GroupCampaignDetailRoute: '/campaigns/group_campaigns/detail',
  LiveChatRoute: '/live-chat',
  AutomationRoute: '/automation',
  KeywordsRoute: '/keywords',
  SequenceRoute: '/sequences',
  // WebhookRoute: '/webhooks',
  FlowsRoute: '/flows',
  SettingsRoute: '/settings',
  LoginRoute: '/login',
  ComponentsRoute: '/components',
  TwoFactorAuthRoute: '/login/two_factor_auth',
  RegisterAccountRoute: '/login/register-account',
  ScanningQrCodeRoute: '/login/scan-qr',
  ActivateAccountRoute: '/login/activate-account',
  ExistenceErrorRoute: '/login/existence-error',
  SetNewPassword: '/login/set-new-password',
  PasswordRoute: '/login/password',
  ResetPasswordRoute: '/login/reset-password',
  AddYourCompanyRoute: '/login/add-your-company',
  ConfirmEmail: '/login/confirm-email',
  SignIn: '/login/sign-in',
  ConstructorRoute: '/constructor',
  ShareFlowRoute: '/share-flow',
  // TemplatesRoute: '/templates',
  TemplateInstallationRoute: '/installation',
  CustomUserTemplate: '/custom_templates',
  TemplateCreateRoute: '/create',
  TemplateInfoRoute: '/template-info',
  StatisticRoute: '/statistics',
  FranchiseRoute: '/franchise',
  BroadcastRoute: '/broadcast',
  BroadcastsScheduledRoute: '/scheduled',
  BroadcastsDraftsRoute: '/drafts',
  BroadcastsHistoryRoute: '/history',
  ProfileRoute: '/profile',
  FranchiseSettingsRoute: '/settings',
  FranchisePaymentRoute: '/payment',
  FranchiseCompaniesRoute: '/companies',
  FranchiseUsersRoute: '/users',
  FranchisePermissionsRoute: '/permissions',
};
