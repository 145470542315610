import {
  GET_VARIABLES_REQUEST,
  GET_VARIABLES_SUCCESS,
  GET_BOT_VARIABLES_REQUEST,
  GET_BOT_VARIABLES_SUCCESS,
  CREATE_VARIABLE_REQUEST,
  CREATE_VARIABLE_ERROR,
  REMOVE_FIELD_REQUEST,
  UPDATE_VARIABLES_REQUEST,
  UPDATE_VARIABLES_SUCCESS,
  UPDATE_BOT_VARIABLES_REQUEST,
  UPDATE_BOT_VARIABLES_SUCCESS,
} from './variablesConstants';

export const variablesActions = {
  getVariablesRequest(data) {
    return {
      type: GET_VARIABLES_REQUEST,
      payload: data,
    };
  },

  getBotVariablesRequest(data) {
    return {
      type: GET_BOT_VARIABLES_REQUEST,
      payload: data,
    };
  },

  createVariableRequest(data) {
    return {
      type: CREATE_VARIABLE_REQUEST,
      payload: data,
    };
  },

  createVariableError(data) {
    return {
      type: CREATE_VARIABLE_ERROR,
      payload: data,
    };
  },

  removeVariableRequest(data) {
    return {
      type: REMOVE_FIELD_REQUEST,
      payload: data,
    };
  },

  getVariablesSuccess(data) {
    return {
      type: GET_VARIABLES_SUCCESS,
      payload: {
        data,
      },
    };
  },

  getBotVariablesSuccess(data) {
    return {
      type: GET_BOT_VARIABLES_SUCCESS,
      payload: data,
    };
  },

  updateVariableRequest(data) {
    return {
      type: UPDATE_VARIABLES_REQUEST,
      payload: data,
    };
  },

  updateVariableSuccess(data) {
    return {
      type: UPDATE_VARIABLES_SUCCESS,
      payload: data,
    };
  },

  updateBotVariableSuccess(data) {
    return {
      type: UPDATE_BOT_VARIABLES_SUCCESS,
      payload: data,
    };
  },

  updateBotVariableRequest(data) {
    return {
      type: UPDATE_BOT_VARIABLES_REQUEST,
      payload: data,
    };
  },
};
