import React from 'react';
import Button from '@wabb-ui-kit/Button/Button';
import FormField from '@wabb-ui-kit/FormField/FormField';
import { EComponentType } from '@wabb-ui-kit/FormField/types/types';
import { EButtonSize, EIconPosition } from '@wabb-ui-kit/Button/types/types';
import { PlusConstEditPannel } from '@icons/constructor/index';
import { useInternationalization } from '@hooks/useTranslationHook';
import { RubbishIcon } from '@icons/constructor/rubbish-icon';
import './jsonEditor.scss';

export const JsonEditor = ({
  fields,
  handleAddButton,
  body,
  handleDeleteField,
  handleChangeField,
  setForm,
}) => {
  const { formatMessage } = useInternationalization();

  return (
    <div className="json-editor">
      {fields.length === 0 ? (
        <div className="json-editor__empty-block">
          <span className="json-editor__empty-block_title">
            { formatMessage('Add header requests to get data') }
          </span>
          <Button
            type="primary"
            style="link"
            size={EButtonSize.L}
            width={210}
            onClick={handleAddButton}
            icon={<PlusConstEditPannel color="#5026D9" />}
            iconPosition={EIconPosition.LEFT}
          >
            { formatMessage('Add header request') }
          </Button>
        </div>
      ) : (
        <>
          <div className="json-editor__bottom-button">
            <Button
              type="primary"
              style="link"
              size={EButtonSize.L}
              width={210}
              onClick={handleAddButton}
              icon={<PlusConstEditPannel color="#5026D9" />}
              iconPosition={EIconPosition.LEFT}
            >
              { formatMessage('Add header request') }
            </Button>
          </div>
          <div className="json-editor__fields">
            {fields
              && fields.length > 0
              && fields.map((item, index) => (
                <div key={item.id} className="json-editor__fields__row">
                  <div style={{ width: '100%' }}>
                    <FormField
                      Component={EComponentType.Input}
                      placeholder="Enter key"
                      value={item.key}
                      onChange={(event) => handleChangeField(body ? 'bodyFieldsKey' : 'headersFieldsKey', item.id, event)}
                      onCrossClick={() => setForm(((prev) => {
                        const headers = prev.headers.map((item, ind) => {
                          if (index === ind) {
                            return {
                              ...item,
                              key: '',
                            };
                          }

                          return item;
                        });
                        return {
                          ...prev,
                          headers,
                        };
                      }))}
                    />
                  </div>
                  <div style={{ width: '100%' }}>
                    <FormField
                      Component={EComponentType.Input}
                      placeholder="Enter value"
                      value={item.value}
                      onChange={(event) => handleChangeField(body ? 'bodyFieldsValue' : 'headersFieldsValue', item.id, event)}
                      onCrossClick={() => setForm(((prev) => {
                        const headers = prev.headers.map((item, ind) => {
                          if (index === ind) {
                            return {
                              ...item,
                              value: '',
                            };
                          }

                          return item;
                        });
                        return {
                          ...prev,
                          headers,
                        };
                      }))}
                    />
                  </div>
                  <button
                    type="button"
                    onClick={() => handleDeleteField(body ? 'bodyFields' : 'headers', item.id)}
                  >
                    <RubbishIcon />
                  </button>
                </div>
              ))}
          </div>
        </>
      )}
    </div>
  );
};
