import { useEffect, useRef } from 'react';

export const useKey = (key, callback) => {
  const cbRef = useRef(callback);

  useEffect(() => {
    cbRef.current = callback;
  });

  useEffect(() => {
    const handle = event => {
      if (event.code === key) {
        cbRef.current(event);
      }
    };

    document.addEventListener('keypress', handle);
    return () => document.removeEventListener('keypress', handle);
  }, [key]);
};
