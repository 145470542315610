export const MODAL_ACTION_TYPES = {
  OPEN_MODAL: 'OPEN_MODAL',
  CLOSE_MODAL: 'CLOSE_MODAL',
  CLOSE_PERMISSION_MODAL: 'CLOSE_PERMISSION_MODAL',
};

export const MODALS = {
  integrationEvent: 'integrationEvent',
  firstPromocode: 'firstPromocode',
  newCompany: 'newCompany',
  tagTypes: 'tagTypes',
  customFields: 'customFields',
  bots: 'Bots',
  shareFlow: 'shareFlow',
  moveTo: 'moveTo',
  saveFlow: 'saveFlow',
  folders: 'Folders',
  campaign: 'campaigns',
  campaignQR: 'campaignCreated',
  editProfile: 'editProfile',
  sequence: 'sequence',
  seqSub: 'seqSub',
  deleteItem: 'deleteItem',
  email: 'email',
  password: 'password',
  manager: 'manager',
  userDetails: 'userDetails',
  chat: 'chat',
  chatPermissions: 'chatPermissions',
  uploadUsers: 'uploadUsers',
  shareTemplate: 'shareTemplate',
  dynamicRequest: 'dynamicRequest',
  broadcast: 'broadcast',
  broadcastUsers: 'broadcastUsers',
  checkHTTPBody: 'checkHTTPBody',
  clearData: 'clearData',
  groupCampaigns: 'groupCampaigns',
  payment: 'payment',
  changePassword: 'changePassword',
  transferCompany: 'transferCompany',
  leaveManager: 'leaveManager',
  deleteCompany: 'deleteCompany',
  createTemplateModal: 'createTemplateModal',
  createFranchiseUser: 'createFranchiseUser',
  createFranchiseCompany: 'createFranchiseCompany',
  editCompanyModal: 'editCompanyModal',
  newOwnerCompanyFranchise: 'newOwnerCompanyFranchise',
  finishFranchiseRegistration: 'finishFranchiseRegistration',
  showTemporaryCreds: 'showTemporaryCreds',
  franchisePermissions: 'franchisePermissions',
  mdChat: 'mdChat',
  addNewContact: 'addNewContact',
  confirmModal: 'confirmModal',
  downloadReport: 'downloadReport',
  contactNotExist: 'contactNotExist',
  createWebhookModal: 'createWebhookModal',
  createKeywordModal: 'createKeywordModal',
  createSequenceModal: 'createSequenceModal',
  createEditFastRepliesModal: 'createEditFastRepliesModal',
  createEditTagModal: 'createEditTagModal',
  qrScanning: 'qrScanning',
  disconnection: 'disconnection',
  editingCompany: 'editingCompany:',
  setUpSupportEmail: 'setUpSupportEmail',
  deleteSupportEmail: 'deleteSupportEmail',
  editCustomEmail: 'editCustomEmail',
  deleteEmailDomainModal: 'deleteEmailDomainModal',
  openEditFranchiseAvatarModal: 'openEditFranchiseAvatarModal',
  choiceFlows: 'choiceFlows',
  inviteUser: 'inviteUser',
  notPaid: 'notPaid',
  frozen: 'frozen',
  announce: 'announce',
  twoFactorAuthentication: 'twoFactorAuthentication',
  passwordUpdateCheck: 'passwordUpdateCheck',
  setUpHelpLink: 'setUpHelpLink',
  editFlowAndFolderModal: 'editFlowAndFolderModal',
  editFieldAndFolderModal: 'editFieldAndFolderModal',
};
