import React, { useEffect, useState } from 'react';

import { ModalContainer } from '@components/containers/Modal/ModalContainer';
import { useSelector } from 'react-redux';
import { getModalDataSelector } from '@components/containers/Modal/modalSelectors';
import { useInternationalization } from '@hooks/useTranslationHook';
import { MODALS } from '@components/containers/Modal/modalConstants';
import { NewBcField } from '@components/common/fields/newBcField/newBcField';
import { Button } from '@components/common/Button';

import { regExp } from '@components/common/fields/newBcField/newBcFieldHelper';
import { FranchiseCompanyApi } from '@api/franchiseCompanyApi';
import { ClearingFieldIcon } from '@icons/clearing-field-icon';

import './setUpSupportEmailModal.scss';

const isObjectWithoutError = object => Object.values(object).every(value => value === '');

export const SetUpSupportEmailModal = () => {
  const { modalData } = useSelector(getModalDataSelector);
  const { formatMessage } = useInternationalization();

  const updateObj = new FormData();

  const [supportEmail, setSupportEmail] = useState({
    email: modalData.supEmail,
  });

  const [supportEmailError, setSupportEmailError] = useState({
    email: '',
  });

  const onClose = () => {
    modalData.onClose();
  };

  const handleChangeSupportEmail = (e, name) => {
    if (e.target && e.target.value) {
      setSupportEmail({ ...supportEmail, [name]: e.target.value });
      setSupportEmailError({ ...supportEmailError, [name]: '' });
    } else {
      setSupportEmail({ ...supportEmail, [name]: '' });
    }
  };

  const valueFieldsValidation = () => {
    const errorObject = {};

    for (const [key, value] of Object.entries(supportEmail)) {
      if (value === '') {
        errorObject[key] = 'This field must not be empty';
      } else if (
        regExp[key] &&
        !regExp[key].expression.test(String(value).toLowerCase())
      ) {
        errorObject[key] = regExp[key].errorMessage;
      } else {
        errorObject[key] = '';
      }
    }

    return errorObject;
  };

  const handleUpdateSupportEmail = () => {
    const errorObject = valueFieldsValidation();

    if (isObjectWithoutError(errorObject)) {
      updateObj.append('support_email', supportEmail.email);
      FranchiseCompanyApi.setFranchiseSettings(modalData.id, updateObj).then(res =>
        modalData.setSupEmail(res.data.support_email),
      );
      onClose();
    } else {
      setSupportEmailError({ ...supportEmail, ...errorObject });
    }
  };

  useEffect(() => {
    modalData.setSupEmail(supportEmail.email);
  }, [supportEmail.email]);

  return (
    <ModalContainer modal={MODALS.setUpSupportEmail} onClose={onClose}>
      <div className="email-support-container">
        <div className="email-support-container_title">
          {formatMessage('Support Email')}
        </div>
        <div className="email-support-container_title_border" />
        <div className="email-support-container_field">
          <NewBcField
            name="email"
            value={supportEmail.email}
            onChange={handleChangeSupportEmail}
            Icon={<ClearingFieldIcon />}
            helperText={
              Boolean(supportEmailError.email) && formatMessage(supportEmailError.email)
            }
          />
        </div>
        <div
          className="email-support-container_button-block"
          onClick={handleUpdateSupportEmail}
        >
          <Button
            color="green"
            width="100%"
            height="56px"
            customClass="email-support-container_button-block_button"
            disabled={supportEmail.email === ''}
          >
            {formatMessage('Save')}
          </Button>
        </div>
      </div>
    </ModalContainer>
  );
};
