import { notificationWindowActionTypes } from './notificationWindowConstants';

const initialState = {
  open: false,
  data: { type: '', label: 'Notification', text: 'Notification' },
};

export const notificationReducer = (state = initialState, action) => {
  switch (action.type) {
    case notificationWindowActionTypes.OPEN_WINDOW:
      const { open, data } = action.payload;
      return { ...state, open, data };
    case notificationWindowActionTypes.CLOSE_WINDOW:
      return { ...state, open: false };
    default:
      return state;
  }
};
