import React, { useState } from 'react';
import './NoteField.scss';
import Button from '@wabb-ui-kit/Button/Button';
import { EButtonSize } from '@wabb-ui-kit/Button/types/types';

const NoteField = ({ note }) => {
  const [showMore, setShowMore] = useState(false);

  const handleToggleShowMore = () => {
    setShowMore(!showMore);
  };

  const renderNote = () => {
    if (note.length <= 140) {
      return note;
    }

    if (showMore) {
      return note;
    }

    return `${note.slice(0, 70)}...`;
  };

  return (
    <div className="note-field">
      {renderNote()}
      {note.length > 70 && (
      <Button
        onClick={handleToggleShowMore}
        type="primary"
        style="link"
        width={showMore ? '64px' : '68px'}
        className="note-field-btn"
      >
        {showMore ? 'Show Less' : 'Show More'}
      </Button>
      )}
    </div>
  );
};

export default NoteField;
