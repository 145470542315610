import * as React from 'react';

export const OpenPassword = () => (
  <svg
    width="19"
    height="14"
    viewBox="0 0 19 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.395 2.30291C12.632 2.30291 15.5188 4.12212 16.9281 7.00041C15.5188 9.87869 12.632 11.6979 9.395 11.6979C6.158 11.6979 3.27117 9.87869 1.86192 7.00041C3.27117 4.12212 6.158 2.30291 9.395 2.30291ZM9.395 0.594727C5.12455 0.594727 1.47758 3.25095 0 7.00041C1.47758 10.7499 5.12455 13.4061 9.395 13.4061C13.6655 13.4061 17.3124 10.7499 18.79 7.00041C17.3124 3.25095 13.6655 0.594727 9.395 0.594727ZM9.395 4.86518C10.5736 4.86518 11.5302 5.82176 11.5302 7.00041C11.5302 8.17905 10.5736 9.13564 9.395 9.13564C8.21636 9.13564 7.25977 8.17905 7.25977 7.00041C7.25977 5.82176 8.21636 4.86518 9.395 4.86518ZM9.395 3.157C7.27685 3.157 5.55159 4.88226 5.55159 7.00041C5.55159 9.11855 7.27685 10.8438 9.395 10.8438C11.5131 10.8438 13.2384 9.11855 13.2384 7.00041C13.2384 4.88226 11.5131 3.157 9.395 3.157Z"
      fill="#A4A8B8"
    />
  </svg>
);
