import { all, takeLatest, put } from 'redux-saga/effects';
import io from 'socket.io-client';
import { notificationsActions } from './notificationsActions';
import { OPEN_NOTIFICATIONS_CONNECTION_REQUEST } from './notificationsConstants';

function* openNotificationsConnectionRequestSaga(action) {
  try {
    const { companyBot, botUrl, serverNumber, instanceId } = action.payload;

    const token = localStorage.getItem('authToken');
    const socketConnection = io(`${botUrl}/notifications`, {
      query: {
        bot: companyBot,
        jwt: token,
        connection_id: serverNumber,
        instance_id: instanceId,
      },
      upgrade: false,
      transports: ['websocket'],
    });

    if (socketConnection) {
      yield put(notificationsActions.openConnectionSuccess(socketConnection));
    }
  } catch (err) {}
}

function* sagas() {
  yield all([
    takeLatest(
      OPEN_NOTIFICATIONS_CONNECTION_REQUEST,
      openNotificationsConnectionRequestSaga,
    ),
  ]);
}

export const notificationsSagas = sagas;
