import React, { useCallback, useEffect, useState } from 'react';
import debounce from 'lodash/debounce';
import moment from 'moment/moment';
import FadeLoader from 'react-spinners/FadeLoader';

import { audienceApi } from '@api/audience';
import { BCInfiniteScroll } from '@components/common/BCInfiniteScroll/BCInfiniteScroll';
import { AudienceFiltersMenuArrowIcon } from '@icons/audience/audience-filters-menu-arrow-icon';
import { CheckIcon } from '@icons/audience/check-icon';
import { variablesApi } from '@api/variablesApi';
import { DateTimeVariableIcon } from '@icons/audience/date-time-variable-icon';
import { DateVariableIcon } from '@icons/audience/date-variable-icon';
import { NumberVariableIcon } from '@icons/audience/number-variable-icon';
import { StringVariableIcon } from '@icons/audience/string-variable-icon';
import { useInternationalization } from '@hooks/useTranslationHook';
import { EComponentType } from '@wabb-ui-kit/FormField/types/types';
import FormField from '@wabb-ui-kit/FormField/FormField';
import { LoopIcon } from '@wabb-assets/svg/FormField/loop-icon';
import { DateTimePopover } from './dateTimePopover/dateTimePopover';

import '../filtersPopover.scss';

const conditions = {
  0: ['is', 'is not', 'contains', 'does not contain', 'begins with'],
  1: [
    'is',
    'is not',
    'greater than',
    'greater than or equal',
    'less than',
    'less than or equal',
  ],
  2: ['after', 'before', 'on'],
  3: ['after', 'before', 'on'],
  4: ['is true', 'is false'],
};

const defaultCondition = ['has any value', 'is unknown'];

export const CustomFieldsFilterModal = ({
  selectedFilter,
  step,
  companyBot,
  returnToStart,
  updateSelectedFilter,
}) => {
  const { formatMessage } = useInternationalization();

  const [customVariables, setCustomVariables] = useState([]);
  const [mostPopularValues, setMostPopularValues] = useState([]);
  const [page, setPage] = useState(1);
  const [customVariablesPage, setCustomVariablesPage] = useState(1);
  const [customVariablesNext, setCustomVariablesNext] = useState();

  const [searchName, setSearchName] = useState('');
  const [queryArg, setQueryArg] = useState('');
  const [anchorEl, setAnchorEl] = useState(null);

  const [suggestedValues, setSuggestedValues] = useState([]);
  const [selectedVariable, setSelectedVariable] = useState({});
  const [loaded, setLoaded] = useState(false);

  const [next, setNext] = useState();

  const filterName = selectedVariable
    ? `custom_field${
        selectedVariable?.type === 0
          ? '_string'
          : selectedVariable?.type === 1
          ? '_float'
          : selectedVariable?.type === 2
          ? '_date'
          : '_datetime'
      }`
    : 0;

  useEffect(() => {
    if (customVariables.length > 0) {
      page > 1 ? sendDebouncedReq(1, queryArg, getValues) : getValues(1, queryArg);
    }
  }, [queryArg, selectedVariable, customVariables]);

  useEffect(() => {
    if (searchName) {
      sendDebouncedReq(1, null, getCustomVariables);
    } else {
      setLoaded(false);
      getCustomVariables(1);
    }
  }, [searchName]);

  useEffect(() => {
    selectedVariable &&
      selectedVariable?.id &&
      updateSelectedFilter({
        filterName: selectedVariable?.key,
        condition: conditions[selectedVariable?.type][0],
      });
    setQueryArg('');
  }, [selectedVariable]);

  const handleClose = () => {
    setAnchorEl(null);
  };

  const dateFormat = data => moment(data, 'DD-MM-YYYY').format('DD.MM.YYYY');

  const dateTimeFormat = data =>
    moment(data, 'DD-MM-YYYY hh:mm').format('DD.MM.YYYY hh:mm:ss');

  const changeFormatValues = elements => {
    if (selectedVariable?.type === 2) {
      return elements.map((el, index) => ({
        value: index,
        label: dateFormat(el),
      }));
    }

    if (selectedVariable?.type === 3) {
      return elements.map((el, index) => ({
        value: index,
        label: dateTimeFormat(el),
      }));
    }

    return elements.map((el, index) => ({ value: index, label: el }));
  };

  const getValues = (currentPage, queryArg = '') => {
    audienceApi
      .getSuggestedValues(companyBot, filterName, queryArg, currentPage)
      .then(res => {
        if (currentPage === 1) {
          setSuggestedValues(changeFormatValues(res.values));
          setMostPopularValues(changeFormatValues(res.mostPopular));
        } else {
          setSuggestedValues([
            ...suggestedValues,
            ...changeFormatValues(res.values),
          ]);
        }

        setNext(Boolean(res.next));
        setPage(1 + currentPage);
      });
  };

  const getCustomVariables = currentPage => {
    variablesApi
      .getPaginatedAllVariables(companyBot, searchName, currentPage)
      .then(res => {
        if (currentPage === 1) {
          setCustomVariables(res.results);
          setSelectedVariable(res.results[0]);
        } else {
          setCustomVariables([...customVariables, ...res.results]);
        }

        setCustomVariablesNext(res.next);
        setCustomVariablesPage(1 + currentPage);
        setLoaded(true);

        if (res.results.length === 0 && currentPage === 1) {
          setSuggestedValues([]);
          setMostPopularValues([]);
        }
      });
  };

  const sendDebouncedReq = useCallback(
    debounce((value1, value2, callback) => callback(value1, value2), 600),
    [],
  );

  return loaded &&
    (customVariables.length > 0 || (customVariables.length === 0 && searchName)) ? (
    <div>
      <div className='option-choose__column_back-header'>
        <button type='button' className='option-choose__column_back-header_button' onClick={ returnToStart }>
          <AudienceFiltersMenuArrowIcon rotate={ 90 }/>
        </button>
        { formatMessage(step) }
      </div>
      <div className='option-filters-choose'>
        <div className='option-filters-choose__column'>
          <div className='option-filters-choose__column_header'>
            <div>
              <FormField
                Component={ EComponentType.Input }
                placeholder={ formatMessage('Search variable') }
                value={ searchName }
                onChange={ event => setSearchName(event.target.value) }
                onCrossClick={ setSearchName }
                className='option-filters-choose__column_header_search'
              />
            </div>
          </div>
          <div className='option-filters-choose__column_custom-fields'>
            <div
              className='option-filters-choose__column_body'
              id='#BCScrollingElementVariables'
            >
              <BCInfiniteScroll
                loadMore={ () => getCustomVariables(customVariablesPage) }
                next={ customVariablesNext }
                root={ document.getElementById('#BCScrollingElementVariables') }
                threshold={ 0.5 }
                rootMargin='0px'
              >
                { customVariables.length > 0 ? (
                  customVariables.map(item => (
                    <div
                      key={ item?.id }
                      className={ `option-filters-choose__column_body_element${
                        selectedVariable?.key === item.key ? '_active' : ''
                      }` }
                      onClick={ () => {
                        setSelectedVariable(item);
                      } }
                    >
                      <div className='option-filters-choose__column_body_element_variable'>
                        { item.type === 0 && <StringVariableIcon/> }
                        { item.type === 1 && <NumberVariableIcon/> }
                        { item.type === 2 && <DateVariableIcon/> }
                        { item.type === 3 && <DateTimeVariableIcon/> }
                        <span>{ item.key }</span>
                      </div>
                      { item.id === selectedVariable?.id && <CheckIcon/> }
                    </div>
                  ))
                ) : (
                  <div className='option-filters-choose__column_body_element_static'>
                    <span>{ formatMessage('No values yet') }</span>
                  </div>
                ) }
              </BCInfiniteScroll>
            </div>
          </div>
        </div>
        <div className='option-filters-choose__column'>
          <div className='option-filters-choose__column_body'>
            <div className='option-filters-choose__column_header'/>

            { customVariables.length > 0 && selectedVariable ? (
              <>
                { conditions[selectedVariable?.type].map(item => (
                  <div
                    key={ item }
                    className={ `option-filters-choose__column_body_element${
                      selectedFilter.condition === item ? '_active' : ''
                    }` }
                    onClick={ () => {
                      updateSelectedFilter({ condition: item });
                    } }
                  >
                    <span>{ formatMessage(item) }</span>
                    { selectedFilter.condition === item && <CheckIcon/> }
                  </div>
                )) }
                { defaultCondition.map(item => (
                  <div
                    key={ item }
                    className={ `option-filters-choose__column_body_element${
                      selectedFilter.condition === item ? '_active' : ''
                    }` }
                    onClick={ () => {
                      updateSelectedFilter(
                        {
                          condition: item,
                          value: '',
                          variableId: String(selectedVariable?.id),
                        },
                        true,
                      );
                    } }
                  >
                    <span>{ formatMessage(item) }</span>
                    { selectedFilter.condition === item && <CheckIcon/> }
                  </div>
                )) }
              </>
            ) : (
              <div className='option-filters-choose__column_body_element_static'>
                <span>{ formatMessage('No values yet') }</span>
              </div>
            ) }
          </div>
        </div>
        <div className='option-filters-choose__column'>
          <div className='option-filters-choose__column_header'>
            <div
              onClick={ event => {
                (selectedVariable?.type === 2 || selectedVariable?.type === 3) &&
                setAnchorEl(event.currentTarget);
              } }
            >
              <FormField
                type='text'
                placeholder={ formatMessage('Search or input value') }
                value={ queryArg }
                onChange={ event => setQueryArg(event.target.value) }
                icon={
                  selectedVariable?.type === 2 ? (
                    <DateVariableIcon/>
                  ) : (
                    <DateTimeVariableIcon/>
                  )
                }
                disabled={ selectedVariable?.type === 3 || selectedVariable?.type === 4 }
                className='option-filters-choose__column_header_search'
              />
            </div>
            <DateTimePopover
              id='custom_fields_filter'
              anchorEl={ anchorEl }
              anchorOrigin={ {
                vertical: 'bottom',
                horizontal: 'centre',
              } }
              open={ Boolean(anchorEl) }
              onClose={ handleClose }
              dateTime={ selectedVariable?.type === 3 }
              format={ selectedVariable?.type === 3 ? 'DD.MM.YYYY HH:mm' : 'DD.MM.YYYY' }
              changeDate={ setQueryArg }
            />
          </div>
          <div id='#BCScrollingElement' className='option-filters-choose__column_body'>
            <BCInfiniteScroll
              loadMore={ () => getValues(page, queryArg) }
              next={ next }
              root={ document.getElementById('#BCScrollingElement') }
              threshold={ 1.0 }
              rootMargin='0px'
              backToTop={ page === 2 }
            >
              { queryArg && (
                <div
                  className='option-filters-choose__column_body_element_add'
                  onClick={ () => {
                    updateSelectedFilter(
                      {
                        value: queryArg,
                        variableId: String(selectedVariable?.id),
                      },
                      true,
                    );
                  } }
                >
                <span>
                  { formatMessage('select') } "{ queryArg }"
                </span>
                </div>
              ) }
              { !queryArg && mostPopularValues.length > 0 && (
                <>
                  <div className='option-filters-choose__column_body_element_label'>
                    { formatMessage('Most popular') }
                  </div>
                  { mostPopularValues.map(item => (
                    <div
                      key={ item?.value ? item.value : item }
                      className='option-filters-choose__column_body_element'
                      onClick={ () => {
                        updateSelectedFilter(
                          {
                            value: item.label,
                            variableId: String(selectedVariable?.id),
                          },
                          true,
                        );
                      } }
                    >
                      <span>{ item.label }</span>
                    </div>
                  )) }
                </>
              ) }
              { suggestedValues.length ? (
                <>
                  { !queryArg && (
                    <div className='option-filters-choose__column_body_element_label'>
                      { formatMessage('All') }
                    </div>
                  ) }
                  { suggestedValues.map(item => (
                    <div
                      key={ item?.value ? item.value : item }
                      className='option-filters-choose__column_body_element'
                      onClick={ () => {
                        updateSelectedFilter(
                          {
                            value: item.label,
                            variableId: String(selectedVariable?.id),
                          },
                          true,
                        );
                      } }
                    >
                      <span>{ item.label }</span>
                    </div>
                  )) }
                </>
              ) : (
                <div className='option-filters-choose__column_body_element_static'>
                  <span>{ formatMessage('No values yet') }</span>
                </div>
              ) }
            </BCInfiniteScroll>
          </div>
        </div>
      </div>
    </div>
  ) : !loaded ? (
    <div className='option-filters-choose__loading'>
      <span>
        { formatMessage('Loading') }
        ...
      </span>
      <FadeLoader size={ 150 } color='#5925dc' loading/>
    </div>
  ) : (
    <div className='option-filters-choose__no-values'>
      <div className="option-filters-choose__no-values_header" onClick={ returnToStart }>
        <AudienceFiltersMenuArrowIcon rotate={ 180 }/>
        { step }
      </div>
      <div className="option-filters-choose__no-values_body">
        <span>{ formatMessage('No values yet') }</span>
      </div>
    </div>
  );
};
