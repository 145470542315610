import React, { useCallback, useEffect, useState } from 'react';
import debounce from 'lodash/debounce';

import { Tooltip } from '@ui-kit';

import { CloseSmallIcon } from '@wabb-assets/svg/Tags/close-small-icon';
import { DateTimeVariableIcon } from '@icons/audience/date-time-variable-icon';
import { DateVariableIcon } from '@icons/audience/date-variable-icon';
import { NumberVariableIcon } from '@icons/audience/number-variable-icon';
import { StringVariableIcon } from '@icons/audience/string-variable-icon';
import { useInternationalization } from '@hooks/useTranslationHook';
import { NewDateTimePopover } from '@components/common/DateTimePopover/DateTimePopover';

import './MarkerItemVariable.scss';

const ICONS = {
  0: <StringVariableIcon color="#644EEF" />,
  1: <NumberVariableIcon color="#644EEF" />,
  2: <DateVariableIcon color="#644EEF" />,
  3: <DateTimeVariableIcon color="#644EEF" />,
};

const VARIABLES_TYPE = {
  STRING: 0,
  NUMBER: 1,
  DATE: 2,
  DATE_TIME: 3,
};

const FORMATS = {
  2: 'DD.MM.YYYY',
  3: 'DD.MM.YYYY HH:mm',
};

const VARIABLES_VALUE_MAX_LENGTH = 2000;

export const MarkerItemVariable = ({ marker, removeMarker, updateVariableMarker }) => {
  const { formatMessage } = useInternationalization();
  const [variableValue, setVariableValue] = useState(
    marker.value ? String(marker.value) : '',
  );
  useEffect(() => {
    if (variableValue !== marker.value && variableValue !== undefined) {
      if (
        marker.type.type === VARIABLES_TYPE.STRING ||
        marker.type.type === VARIABLES_TYPE.NUMBER
      ) {
        sendDebouncedReq(
          {
            ...marker,
            value: variableValue,
          },
          updateVariableMarker,
        );
      }
    }
  }, [variableValue]);

  const sendDebouncedReq = useCallback(
    debounce((value, callback) => callback(value), 2000),
    [],
  );

  const handleChangeValue = event => {
    if (marker.type.type === 1) {
      if (/^\d*\.?\d*$/.test(event.target.value)) {
        setVariableValue(event.target.value);
      }
    } else {
      setVariableValue(event.target.value);
    }
  };

  return (
    <div
      className="marker-item-variable"
      style={marker.id || variableValue ? {} : { border: '1px solid #e24b41' }}
    >
      <div className="marker-item-variable__container">
        <div className="marker-item-variable__icon">{ICONS[marker.type.type]}</div>
        <div className="marker-item-variable__name-variable">
          {marker.type.key}
          {': '}
        </div>
        <Tooltip
          renderTrigger={() => (
            <div className="marker-item-variable__value">
              {marker.type.type === VARIABLES_TYPE.STRING ||
              marker.type.type === VARIABLES_TYPE.NUMBER ? (
                <input
                  className="marker-item-variable__value_input"
                  type="text"
                  placeholder={
                    marker.type.type === VARIABLES_TYPE.STRING
                      ? formatMessage('Text value')
                      : formatMessage('Number value')
                  }
                  value={variableValue}
                  onChange={handleChangeValue}
                  maxLength={VARIABLES_VALUE_MAX_LENGTH}
                />
              ) : (
                <NewDateTimePopover
                  id="user-card-variable-marker"
                  dateTime={marker.type.type === VARIABLES_TYPE.DATE_TIME}
                  format={FORMATS[marker.type.type]}
                  changeDate={value => {
                    setVariableValue(value);
                    updateVariableMarker({
                      ...marker,
                      value,
                    });
                  }}
                  renderTrigger={() => (
                    <div>
                      {variableValue
                        ? String(variableValue)
                        : formatMessage('Input date')}
                    </div>
                  )}
                />
              )}
            </div>
          )}
        >
          <div>{variableValue}</div>
        </Tooltip>
        <div
          className="marker-item-variable__delete-icon"
          onClick={() => removeMarker(marker.id)}
        >
          <CloseSmallIcon />
        </div>
      </div>
    </div>
  );
};
