import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputAdornment from '@mui/material/InputAdornment';
import FormControl from '@mui/material/FormControl';
import IconButton from '@mui/material/IconButton';

import { BcPhoneInput } from '../../common/fields/BcPhoneInput';
import { ModalContainer } from '../../containers/Modal/ModalContainer';
import { MODALS } from '../../containers/Modal/modalConstants';
import { modalActions } from '../../containers/Modal/modalActions';
import { Button } from '../../common';
import { BcTextField } from '../../common/fields/TextField';
import { FranchiseCompanyApi } from '../../../api/franchiseCompanyApi';

import { useInternationalization } from '../../../hooks/useTranslationHook';
import { authActions } from '../../../redux/auth/authActions';
import { notificationsActions } from '../../../redux/notifications/notificationsActions';
import { getNotificationsSelector } from '../../../redux/notifications/notificationsSelector';
import { notificationWindowActions } from '../../../redux/notificationWindow/notificationWindowActions';

import './finishFranchiseRegistration.scss';

const styles = {};

// const useStyles = makeStyles({
//   borderClass: {
//     '&.react-tel-input .form-control:focus': {
//       border: '1px solid #8061FF',
//     },
//     '&.react-tel-input:focus-within .special-label': {
//       color: '#8061FF',
//     },
//   },
//   root: {
//     margin: '15px 0',
//     width: '100%',
//     '& label': {
//       fontWeight: 'normal',
//       fontSize: '15px',
//       justifyContent: 'center',
//       alignItems: 'center',
//       color: (props) => (props.error ? '#f44336' : 'gray'),
//     },
//     '& label.Mui-focused': {
//       color: '#8061FF',
//     },
//     '& input': {
//       width: '100%',
//       backgroundColor: 'white',
//       borderRadius: '6px',
//       padding: '16px',
//     },
//     '& .MuiOutlinedInput-root': {
//       '& fieldset': {
//         borderColor: '#E5E5E5',
//       },
//       '&.Mui-focused fieldset': {
//         border: '1px solid #8061FF',
//       },
//     },
//   },
// });

export const finishFranchiseRegistration = () => {
  const { formatMessage } = useInternationalization();

  const dispatch = useDispatch();

  const [inputs, setInputs] = useState({
    firstName: '',
    lastName: '',
    phone: '',
    password: '',
  });
  const [errorInput, setErrorInput] = useState({
    firstName: '',
    lastName: '',
    password: '',
  });
  const [passwordError, setPasswordError] = useState(false);
  const [showPassword, setShowPassword] = useState({
    password: false,
  });

  const { notificationsSocket } = useSelector(getNotificationsSelector);
  // const classes = useStyles({ error: passwordError });

  const { firstName, lastName, phone, password } = inputs;
  const passwordReg = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})/;

  const removeSpace = value => {
    const arr = value.split(' ');
    let str = '+';
    arr.map(item => (str += item));
    return str;
  };

  const handleChange = e => {
    const { name, value } = e.target;
    setInputs(inputs => ({ ...inputs, [name]: value }));
  };

  const handleChangePhone = (fieldName, value) => {
    const formatedValue = removeSpace(value);
    setInputs(inputs => ({ ...inputs, [fieldName]: formatedValue }));
  };

  const handleLogOut = () => {
    notificationsSocket && notificationsSocket.close();
    notificationsSocket && dispatch(notificationsActions.closeConnectionSuccess());

    localStorage.removeItem('selectedCompany');
    localStorage.removeItem('authToken');
    localStorage.removeItem('refreshToken');
    localStorage.removeItem('isFranchiseOwner');
    localStorage.removeItem('isEmailRegistrationInProgress');
    localStorage.removeItem('userType');
    localStorage.removeItem('fullName');
    dispatch(modalActions.closeModal());
    dispatch(authActions.logOut());
  };

  const handleSubmit = () => {
    if (firstName === '' || lastName === '' || validate(password) === false) {
      const errorInputCopy = { ...errorInput };

      if (firstName === '') {
        errorInputCopy.firstName = 'This field is required';
      }

      if (lastName === '') {
        errorInputCopy.lastName = 'This field is required';
      }

      if (validate(password) === false) {
        setPasswordError(true);
        errorInputCopy.password =
          'The password must be at least 8 characters long, contain more than just letters or numbers, and have at least one uppercase character';
      }

      setErrorInput(errorInputCopy);
    } else {
      FranchiseCompanyApi.changeRegistrationData({
        first_name: firstName,
        last_name: lastName,
        phone,
        password,
      })
        .then(res => {
          if (res.status === 200) {
            localStorage.setItem('isEmailRegistrationInProgress', JSON.stringify(false));
            dispatch(authActions.isEmailRegistrationInProgress(false));
            dispatch(modalActions.closeModal());
            dispatch(
              notificationWindowActions.openWindow(true, {
                label: formatMessage('Successful'),
                type: 'success',
                text: formatMessage('User successful create'),
              }),
            );
          }
        })
        .catch(() => {
          setPasswordError(true);
          setErrorInput({
            firstName: '',
            lastName: '',
            password: 'Error, please contact administrator',
          });
        });
    }
  };

  const validate = password =>
    passwordReg.test(String(password)) && isNaN(password) && password.length > 0;

  return (
    <ModalContainer
      modal={MODALS.finishFranchiseRegistration}
      hideCross
      customStyles={styles}
    >
      <div className="campaigns-modal">
        <div className="custom-fields-modal__header">{formatMessage('Update Info')}</div>
        <h2 className="custom-fields-modal__description">
          {formatMessage('First Name')}
        </h2>
        <div className="custom-fields-modal__form-group">
          <BcTextField
            name="firstName"
            error={Boolean(errorInput.firstName)}
            value={firstName}
            onChange={handleChange}
            variant="outlined"
            helperText={errorInput.firstName && formatMessage(errorInput.firstName)}
            fullWidth
          />
        </div>
        <h2 className="custom-fields-modal__description">{formatMessage('Last Name')}</h2>
        <div className="custom-fields-modal__form-group">
          <BcTextField
            name="lastName"
            value={lastName}
            error={Boolean(errorInput.lastName)}
            onChange={handleChange}
            variant="outlined"
            helperText={errorInput.lastName && formatMessage(errorInput.lastName)}
            fullWidth
          />
        </div>
        <h2 className="custom-fields-modal__description">{formatMessage('Phone')}</h2>
        <div className="custom-fields-modal__form-group">
          <BcPhoneInput
            // containerClass={classes.borderClass}
            onChange={event => {
              handleChangePhone('phone', event);
            }}
          />
        </div>
        <h2 className="custom-fields-modal__description">{formatMessage('Password')}</h2>
        <div className="custom-fields-modal__form-group">
          <div className="create-franchise-user__password-field">
            <FormControl variant="outlined">
              <OutlinedInput
                id="Password"
                name="Password"
                fullWidth
                required
                type={showPassword.password ? 'text' : 'password'}
                onChange={e => setInputs({ ...inputs, password: e.target.value })}
                error={passwordError}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() =>
                        setShowPassword({
                          ...showPassword,
                          password: !showPassword.password,
                        })
                      }
                      onMouseDown={event => event.preventDefault()}
                      edge="end"
                    >
                      {showPassword.password ? 'VisibilityOff' : 'Visibility'}
                    </IconButton>
                  </InputAdornment>
                }
              />
            </FormControl>
            <div className="create-franchise-user__password-err">
              {passwordError && formatMessage(errorInput.password)}
            </div>
          </div>
        </div>

        <div className="finish-franchise-registration__footer">
          <Button
            onClick={handleLogOut}
            color="transparent"
            customClass="finish-franchise-registration__submit-button"
          >
            {formatMessage('Sign Out')}
          </Button>
          <Button
            onClick={handleSubmit}
            customClass="finish-franchise-registration__submit-button"
          >
            {formatMessage('Update')}
          </Button>
        </div>
      </div>
    </ModalContainer>
  );
};
