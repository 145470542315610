import { createSelector } from 'reselect';

export const getNotificationDataSelector = createSelector(
  [state => state.notification.data],
  data => data,
);

export const isNotificationOpenSelector = createSelector(
  [state => state.notification.open],
  open => open,
);
