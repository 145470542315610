import { useEffect } from 'react';

export const useClickOutsideHandler = (
  action: (e?: Event) => void,
  ...refs: React.RefObject<HTMLElement>[]
) => {
  const handleClickOutside = (event: Event) => {
    if (!refs.some(ref => ref?.current?.contains(event.target as Node))) {
      action(event);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [action, refs]);
};
