export const updateIncorrectTimePeriod = (time, isStartTime) => {
  const [hour, min] = time.split(':');
  const [first, second] = min.split('');

  if (isStartTime && time === '23:59') {
    return '23:59';
  }

  if (!isStartTime && time === '00:00') {
    return '00:00';
  }

  return `${hour}:${first}${isStartTime ? Number(second) + 1 : Number(second - 1)}`;
};
