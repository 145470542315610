import { useState } from 'react';
import {
  autoUpdate, flip, offset, shift, size, useFloating,
} from '@floating-ui/react';

function useFloatingCustom(controllableOpenState, params = {}) {
  const [isOpen, setIsOpen] = useState(false);

  const openState = controllableOpenState ? controllableOpenState.isOpen : isOpen;
  const setOpenState = controllableOpenState
    ? controllableOpenState.setIsOpen
    : setIsOpen;

  const {
    x, y, refs, strategy, context,
  } = useFloating({
    open: openState,
    onOpenChange: setOpenState,
    middleware: [
      offset(params.offset || 1),
      flip({ fallbackAxisSideDirection: 'end', padding: 10 }),
      shift(),
      size({
        apply({ rects, elements }) {
          Object.assign(elements.floating.style, {
            width: elements.floating.style.width || `${rects.reference.width}px`,
          });
        },
      }),
    ],
    whileElementsMounted: autoUpdate,
    ...params,
    placement: params.placement || 'bottom-start',
  });

  const position = {
    position: strategy,
    top: y || 0,
    left: x || 0,
  };

  return {
    isOpen: openState,
    setIsOpen: setOpenState,
    refs,
    context,
    position,
  };
}

export { useFloatingCustom as useFloating };
