import React from 'react';

export const ArrowIcon = ({ color = '#3C4449', rotate = 0 }) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={{ transform: `rotate(${rotate}deg)` }}
  >
    <path
      d="M17.715 14.5533C17.335 14.9143 16.7211 14.9143 16.3411 14.5533L11.9951 10.3997L7.65891 14.5625C7.2789 14.9235 6.66503 14.9235 6.28501 14.5625C5.905 14.2016 5.905 13.6184 6.28501 13.2574L11.3131 8.43737C11.6931 8.07638 12.3069 8.07638 12.687 8.43737L17.715 13.2482C18.095 13.6092 18.095 14.1923 17.715 14.5533Z"
      fill={color}
    />
  </svg>
);
