export const GET_ITEMS_REQUEST = 'GET_ITEMS_REQUEST';
export const GET_ITEMS_SUCCESS = 'GET_ITEMS_SUCCESS';
export const GET_ITEMS_NEXT_REQUEST = 'GET_ITEMS_NEXT_REQUEST';
export const GET_ITEMS_NEXT_SUCCESS = 'GET_ITEMS_NEXT_SUCCESS';
export const TOGGLE_SELECT_ALL = 'TOGGLE_SELECT_ALL';
export const CHANGE_ITEM_SELECT_MODE = 'CHANGE_ITEM_SELECT_MODE';
export const CREATE_NEW_ITEM_REQUEST = 'CREATE_NEW_ITEM_REQUEST';
export const CREATE_NEW_ITEM_SUCCESS = 'CREATE_NEW_ITEM_SUCCESS';
export const DELETE_ITEM_REQUEST = 'DELETE_ITEM_REQUEST';
export const DELETE_ITEM_REQUEST_SUCCESS = 'DELETE_ITEM_REQUEST_SUCCESS';
export const UPDATE_ITEM_SPLIT_TABLE = 'UPDATE_ITEM_SPLIT_TABLE';
export const BULK_ACTION_DELETE_ITEMS = 'BULK_ACTION_DELETE_ITEMS';
export const SET_ITEMS_NAME = 'SET_ITEMS_NAME';
export const SET_ITEMS_PAGE = 'SET_ITEMS_PAGE';
export const CLEAR_CHECKBOXES = 'CLEAR_CHECKBOXES';
export const CLEAR_NEXT = 'CLEAR_NEXT';
