import api from './baseApi';

export const filesApi = {
  checkStatusFile: fileId => api.get(`/files/get_status/file/${fileId}/`),

  downloadPreparedFile: fileId =>
    api.get(`/files/get_file/file/${fileId}/`, { responseType: 'arraybuffer' }),

  downloadMediaFile: (file, messageId, messageType) => {
    return api.post(
      'https://downloader-dev.wabb.in/api/v1/get_media_from_message', //https://media-downloader-new.botconversa.com.br/api/v1/get_media_from_message?test=true
      {
        message: file,
        message_type: messageType,
      },
      { responseType: 'blob' },
    );
  },
};
