import { broadcastApiRPC } from '@connectApi/baseApi';
import {
  BroadCastListRequest, BroadCastDetailPk, BroadCastCreateRequest, BroadCastDetailPatchRequest,
} from 'wabb-backend-api/build/es/broadcast/broadcast_pb';
import api from './baseApi';

export const broadCastApi = {
  getBroadcasts: (botId, type, searchName) => (type
    ? broadcastApiRPC.broadCastList(BroadCastListRequest.fromJson({ botId, type, searchName: searchName ?? '' }))
    : broadcastApiRPC.broadCastList(BroadCastListRequest.fromJson({ botId, searchName: searchName ?? '' }))),
  getScheduleBroadcasts: ({ botId, page }) => api.get(`/broadcast/${botId}/?page=${page}`),
  getDraftsBroadcasts: ({ botId, page }) => api.get(`/broadcast/${botId}/?type=0&page=${page}`),
  getHistoryBroadcasts: ({ botId, page }) => api.get(`/broadcast/${botId}/?type=4&page=${page}`),
  // updateBroadcast: (broadcastId, data, queryString = '') => api.patch(
  //   `/broadcast/detail/${broadcastId}/${
  //     queryString ? `?${queryString.toLowerCase()}` : ''
  //   }`,
  //   data,
  // ),
  updateBroadcast: (data) => broadcastApiRPC.broadCastDetailPatch(BroadCastDetailPatchRequest.fromJson(data)),
  deleteBroadcast: (id) => broadcastApiRPC.broadCastDetailDelete(BroadCastDetailPk.fromJson({ pk: id })),
  createBroadcast: (data) => broadcastApiRPC.broadCastCreate(BroadCastCreateRequest.fromJson(data)),
  // createBroadcast: (data, queryString) => api.post(`/broadcast/?${queryString.toLowerCase()}`, data),
  duplicateBroadcast: (id) => broadcastApiRPC.broadcastDuplicate(BroadCastDetailPk.fromJson({ pk: id })),
  // duplicateBroadcast: (broadcastId, query_string) => api.post(`broadcast/duplicate/${broadcastId}/?${query_string}`),
};
