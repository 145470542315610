import { notificationWindowActionTypes } from './notificationWindowConstants';

export const notificationWindowActions = {
  closeModal() {
    return {
      type: notificationWindowActionTypes.CLOSE_WINDOW,
    };
  },

  openWindow(open, data) {
    return {
      type: notificationWindowActionTypes.OPEN_WINDOW,
      payload: {
        open,
        data,
      },
    };
  },
};
