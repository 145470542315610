import React from 'react';

export const ContactEmailIcon = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M15.6 4H4.4C3.76348 4 3.15303 4.23705 2.70294 4.65901C2.25286 5.08097 2 5.65326 2 6.25V13.75C2 14.3467 2.25286 14.919 2.70294 15.341C3.15303 15.7629 3.76348 16 4.4 16H15.6C16.2365 16 16.847 15.7629 17.2971 15.341C17.7471 14.919 18 14.3467 18 13.75V6.25C18 5.65326 17.7471 5.08097 17.2971 4.65901C16.847 4.23705 16.2365 4 15.6 4ZM15.064 5.5L10 9.0625L4.936 5.5H15.064ZM15.6 14.5H4.4C4.18783 14.5 3.98434 14.421 3.83431 14.2803C3.68429 14.1397 3.6 13.9489 3.6 13.75V6.4375L9.52 10.6C9.65848 10.6974 9.8269 10.75 10 10.75C10.1731 10.75 10.3415 10.6974 10.48 10.6L16.4 6.4375V13.75C16.4 13.9489 16.3157 14.1397 16.1657 14.2803C16.0157 14.421 15.8122 14.5 15.6 14.5Z"
      fill="#A4A8B8"
    />
  </svg>
);
