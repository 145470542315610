export const OPEN_CHATS_CONNECTION_REQUEST = 'OPEN_CHATS_CONNECTION_REQUEST';
export const OPEN_CHATS_CONNECTION_SUCCESS = 'OPEN_CHATS_CONNECTION_SUCCESS';
export const CLOSE_CHATS_CONNECTION_SUCCESS = 'CLOSE_CHATS_CONNECTION_SUCCESS';
export const GET_CONNECTION_STATUS_SUCCESS = 'GET_CONNECTION_STATUS_SUCCESS';

export const CHOOSE_CHAT = 'CHOOSE_CHAT';
export const CHECK_CHAT_READ = 'CHECK_CHAT_READ';
export const GET_CHATS_REQUEST = 'GET_CHATS_REQUEST';
export const GET_CHATS_SUCCESS = 'GET_CHATS_SUCCESS';
export const GET_CHATS_GROUP_REQUEST = 'GET_CHATS_GROUP_REQUEST';
export const GET_CHATS_GROUP_SUCCESS = 'GET_CHATS_GROUP_SUCCESS';

export const GET_MESSAGES_REQUEST = 'GET_MESSAGES_REQUEST';
export const GET_MESSAGES_SUCCESS = 'GET_MESSAGES_SUCCESS';
export const DISABLE_LOADING = 'DISABLE_LOADING';
export const GET_SEND_MESSAGE = 'GET_SEND_MESSAGE';
export const UPDATE_CHAT_MESSAGE = 'UPDATE_CHAT_MESSAGE';
export const DOWNLOAD_FILE_MESSAGE_SUCCESS = 'DOWNLOAD_FILE_MESSAGE_SUCCESS';
export const CHANGE_BUSY_STATUS = 'CHANGE_BUSY_STATUS';
export const CHANGE_CHAT_AVATAR = 'CHANGE_CHAT_AVATAR';
export const CHANGE_CHAT_NAME = 'CHANGE_CHAT_NAME';
export const CHANGE_CHAT_COUNT = 'CHANGE_CHAT_COUNT';

export const CHANGE_CASE_STATUS = 'CHANGE_CASE_STATUS';
export const SET_CHAT_BUSY = 'SET_CHAT_BUSY';

export const FILTER_CHAT_BY_ID = 'FILTER_CHAT_BY_ID';

export const SET_CHAT_INFO_FROM_AUDIENCE = 'SET_CHAT_INFO_FROM_AUDIENCE';

export const SET_CASE_FILTER = 'SET_CASE_FILTER';

export const HANDLE_CHATS_CASE_UPDATING = 'HANDLE_CHATS_CASE_UPDATING';

export const HANDLE_NEW_MESSAGE = 'HANDLE_NEW_MESSAGE';

export const SET_CHAT_ROOM = 'SET_CHAT_ROOM';
export const ADD_NEW_CHAT = 'ADD_NEW_CHAT';

export const HANDLE_CHATS_BUSY_UPDATING = 'HANDLE_CHATS_BUSY_UPDATING';

export const SET_CURRENT_FILTER = 'SET_CURRENT_FILTER';

export const CHECK_CHAT_READ_STATUS = 'CHECK_CHAT_READ_STATUS';

export const UPDATE_CHAT_READ_STATUS = 'UPDATE_CHAT_READ_STATUS';

export const SET_CHATS_LOADING = 'SET_CHAT_LOADING';

export const SET_SOUND_NOTIFICATION = 'SET_SOUND_NOTIFICATION';

export const SET_PAUSE_AUTOMATION = 'SET_PAUSE_AUTOMATION';

export const SET_SEARCH_VALUE = 'SET_SEARCH_VALUE';

export const SET_REPLIED_MESSAGE = 'SET_REPLIED_MESSAGE';

export const SET_USER_CARD = 'SET_USER_CARD';

export const GET_USER_CARD_REQUEST = 'GET_USER_CARD_REQUEST';

export const GET_CHAT_REQUEST = 'GET_CHAT_REQUEST';

export const ADD_SCROLL_BOTTOM = 'ADD_SCROLL_BOTTOM';

export const UPDATE_CHATS = 'UPDATE_CHATS';
