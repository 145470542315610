import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Code, ConnectError } from '@connectrpc/connect';

import { getSelectedCompanySelector } from '@redux/companies/companiesSelectors';
import { useInternationalization } from '@hooks/useTranslationHook';
import { adsSourceApi } from '@api/adsSourceApi';
import { bcSplitTableActions } from '@redux/bcSplitTable/bcSplitTableActions';
import FormField from '@wabb-ui-kit/FormField/FormField.tsx';
import Button from '@wabb-ui-kit/Button/Button.tsx';
import Dropdown from '@wabb-ui-kit/Dropdown/Dropdown.tsx';
import { EButtonSize } from '@wabb-ui-kit/Button/types/types.ts';
import { getModalDataSelector } from '../../containers/Modal/modalSelectors';
import { MODALS } from '../../containers/Modal/modalConstants';
import { ModalContainer } from '../../containers/Modal/ModalContainer';

import './CampaignModal.scss';

export const CampaignModal = () => {
  const { formatMessage } = useInternationalization();
  const dispatch = useDispatch();

  const { modalData } = useSelector(getModalDataSelector);
  const companyBot = useSelector(getSelectedCompanySelector).bot;

  const { flows } = modalData;

  const [campaignName, setCampaignName] = useState(modalData.campaignName);
  const [triggerPhrase, setTriggerPhrase] = useState(modalData.triggerPhrase);
  const [toFlow, setToFlow] = useState(() =>
    modalData.campaignId ? flows.find(item => item.id === modalData.toFlow) : null,
  );
  const [error, setError] = useState('');

  const handleCleanCampaignName = () => {
    setCampaignName('');
  };

  const handleCleanTriggerPhrase = () => {
    setTriggerPhrase('');
  };

  const handleSubmit = () => {
    if (!campaignName.trim()) {
      setError('This field may not be empty');
      return;
    }

    if (modalData.campaignId) {
      adsSourceApi
        .updateAdsSource({
          campaignId: modalData.campaignId,
          name: campaignName,
          text: triggerPhrase,
          bot: companyBot,
          shareType: 0,
          toFlow: toFlow.id,
        })
        .then(res => {
          dispatch(bcSplitTableActions.updateItemInSplitTable(res));
          modalData.onClose();
        })
        .catch(err => {
          const connectErr = ConnectError.from(err);

          if (connectErr && connectErr.code === Code.InvalidArgument) {
            setError('A campaign with this name or phrase already exists.');
          }
        });
    } else {
      adsSourceApi
        .createAdsSource({
          name: campaignName,
          text: triggerPhrase,
          bot: companyBot,
          share_type: 1,
          toFlow: toFlow.id,
        })
        .then(res => {
          dispatch(bcSplitTableActions.createNewItemSuccess(res));
          modalData.onClose();
        })
        .catch(err => {
          const connectErr = ConnectError.from(err);

          if (connectErr && connectErr.code === Code.InvalidArgument) {
            setError('A campaign with this name or phrase already exists.');
          }
        });
    }
  };

  return (
    <ModalContainer modal={MODALS.campaign} onClose={modalData.onClose}>
      <div className="campaign-modal">
        <div className="campaign-modal__body">
          <div className="campaign-modal__header">
            {modalData.campaignId
              ? formatMessage('Edit Campaign')
              : formatMessage('Create new campaign')}
          </div>
          <div className="campaign-modal__vertical-block">
            <FormField
              label="Campaign name"
              value={campaignName}
              placeholder="Type here..."
              isRequired
              width="100%"
              errorText={error}
              onChange={event => {
                setCampaignName(event.target.value);
              }}
              onCrossClick={handleCleanCampaignName}
            />
          </div>
          <div className="campaign-modal__vertical-block">
            <label className="campaign-modal__vertical-block-label">Flow</label>
            <Dropdown
              options={flows}
              singleSelectedOption={toFlow}
              setSingleSelectedOption={setToFlow}
            />
          </div>
          <div className="campaign-modal__vertical-block">
            <FormField
              placeholder="Type here the phrase..."
              label="What phrase should trigger this campaign?"
              value={triggerPhrase}
              infoText={
                "Please, don't end this phrase with period (.), exclamation (!) or question mark (?)"
              }
              width="100%"
              onChange={(event) => {
                setTriggerPhrase(event.target.value);
              }}
              onCrossClick={handleCleanTriggerPhrase}
            />
          </div>
        </div>
        <div className="campaign-modal__body_button">
          <Button
            onClick={modalData.onClose}
            type="secondary"
            style="tint"
            size={EButtonSize.L}
          >
            <span>{formatMessage('Cancel')}</span>
          </Button>
          <Button
            type="primary"
            size={EButtonSize.L}
            onClick={handleSubmit}
            isDisabled={
              !(campaignName && triggerPhrase && toFlow) ||
              ['.', ',', '?', '!'].includes(triggerPhrase[triggerPhrase - 1])
            }
          >
            <span className="campaign-modal__text_white">
              {modalData.campaignId ? formatMessage('Save') : formatMessage('Create')}
            </span>
          </Button>
        </div>
      </div>
    </ModalContainer>
  );
};
