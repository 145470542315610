import sbjs from 'sourcebuster';

export const setTitle = () => {
  document.title = 'Wabb';
};

export const setFavicon = () => {
  // document.querySelector('#shortcut-icon').setAttribute('href', BotConversaFavicon);
};

const getUtmParams = () => {
  const utmParams = sbjs.get.first;
  localStorage.setItem('utmParams', JSON.stringify(utmParams))
}

export const setAppSettings = () => {
  setTitle();
  setFavicon();

  sbjs.init({
    domain: false,
    utm_params: {
      source: true,
      medium: true,
      campaign: true,
      keyword: true,
      content: true,
    },
    callback: getUtmParams
  });
};
