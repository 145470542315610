import React, { cloneElement, memo } from 'react';
import {
  safePolygon,
  useDismiss,
  useFocus,
  useHover,
  useInteractions,
  useRole,
} from '@floating-ui/react';
import cn from 'classnames';
import { Floating } from '@ui-kit/components/Floating';
import { useFloating } from '@ui-kit/hooks/useFloating';

import './Tooltip.styles.scss';

const Tooltip = ({
  renderTrigger,
  children,
  placement,
  isError,
  controllableOpenState,
  isClickableContent = true,
  zIndex = 9999,
}) => {
  const { context, refs, position, isOpen } = useFloating(controllableOpenState, {
    placement,
  });
  const hover = useHover(context, {
    move: false,
    handleClose: isClickableContent
      ? safePolygon({
          blockPointerEvents: true,
        })
      : undefined,
  });
  const focus = useFocus(context);
  const dismiss = useDismiss(context);
  const role = useRole(context, { role: 'tooltip' });
  const { getReferenceProps, getFloatingProps: getFloatingPropsBase } = useInteractions([
    hover,
    focus,
    dismiss,
    role,
  ]);
  const getFloatingProps = props =>
    getFloatingPropsBase({
      ...props,
      className: cn('tooltip-root', { 'tooltip-error': isError }),
    });
  return (
    <>
      {cloneElement(
        renderTrigger(isOpen),
        getReferenceProps({
          ref: refs.setReference,
        }),
      )}
      <Floating
        ref={refs.setFloating}
        isOpen={isOpen}
        context={context}
        initialFocus={-1}
        position={position}
        zIndex={zIndex}
        getFloatingProps={getFloatingProps}
        size={{
          width: 'auto',
          maxWidth: '320px',
        }}
      >
        <div className="tooltip-content">{children}</div>
      </Floating>
    </>
  );
};

const memoized = memo(Tooltip);
export { memoized as Tooltip };
