import React, { useState } from 'react';
import { useSelector } from 'react-redux';

import './createTemplateModal.scss';
import default_avatar from '../../../images/default-avatar.png';

import { MODALS } from '../../containers/Modal/modalConstants';
import { ModalContainer } from '../../containers/Modal/ModalContainer';
import { getModalDataSelector } from '../../containers/Modal/modalSelectors';
import { BcTextField } from '../../common/fields/TextField';
import { Button } from '../../common/Button';
import { companiesActions } from '../../../redux/companies/companiesActions';
import { getCompaniesSelector } from '../../../redux/companies/companiesSelectors';
import { getUserIdSelector } from '../../../redux/auth/authSelectors';
import { useInternationalization } from '../../../hooks/useTranslationHook';

const styles = {
  width: '40%',
};

export const CreateTemplateModal = () => {
  const { modalData } = useSelector(getModalDataSelector);
  const { formatMessage } = useInternationalization();
  const userId = useSelector(getUserIdSelector);

  const companies = useSelector(getCompaniesSelector).filter(
    item => item.owner === userId,
  );

  const [displayCompanies, setDisplayCompanies] = useState(companies);

  const handleRedirect = item => {
    companiesActions.setSelectedCompany(item);
    window.open(`${window.location.origin}/templates/create`);
    modalData.onClose();
  };

  const handleSearch = e => {
    const { value } = e.target;

    if (!value) {
      setDisplayCompanies(companies);
    } else {
      setDisplayCompanies(
        companies.filter(item => item.name.toLowerCase().includes(value)),
      );
    }
  };

  return (
    <ModalContainer
      modal={MODALS.createTemplateModal}
      onClose={modalData.onClose}
      customStyles={styles}
    >
      <div className="create-template-modal">
        <div className="create-template-modal__header">
          <h2>{formatMessage('Select source company')}</h2>
          <span>
            {formatMessage('For new template will be used content from this company')}
          </span>
        </div>
        <div className="create-template-modal__content">
          <div className="create-template-modal__content__search">
            <BcTextField
              placeholder={formatMessage('Enter company name')}
              onChange={handleSearch}
            />
          </div>
          <div className="create-template-modal__content__table">
            {displayCompanies.map(item => (
              <div className="create-template-modal__content__table__item">
                <div className="create-template-modal__content__table__item__data">
                  <img
                    width={48}
                    height={48}
                    src={item.avatar ? item.avatar : default_avatar}
                  />
                  {item.name}
                </div>
                <div className="create-template-modal__content__table__item__button">
                  <Button
                    onClick={() => {
                      handleRedirect(item);
                    }}
                  >
                    Use company
                  </Button>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </ModalContainer>
  );
};
