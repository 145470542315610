import React from 'react';

export const CheckIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M15.63 6.04521C15.9012 6.32783 15.9012 6.78604 15.63 7.06866L9.05594 13.9546C8.78475 14.2372 8.34505 14.2372 8.07385 13.9546L4.37015 10.095C4.09895 9.81236 4.09895 9.35415 4.37015 9.07153C4.64134 8.78891 5.08104 8.78891 5.35224 9.07153L8.5649 12.4195L14.6479 6.04521C14.9191 5.7626 15.3588 5.7626 15.63 6.04521Z"
      fill="#5925DC"
    />
  </svg>
);
