import React from 'react';
import classNames from 'classnames';

interface IDividerProps {
  className?: string;
}

const Divider = ({ className }: IDividerProps) => {
  return (
    <hr
      className={classNames('divider', className)}
      style={{
        border: 'none',
        borderTop: '1px solid #101828',
        margin: '10px 0',
      }}
    />
  );
};

export default Divider;
