import React, { FC } from 'react';
import Button from '@wabb-ui-kit/Button/Button';
import { EButtonSize } from '@wabb-ui-kit/Button/types/types';
import Switcher from '@wabb-ui-kit/Switcher/Switcher';
import { Popover } from '../../ui-kit/components/Popover/Popover';

import './WabbSwitcherPopover.scss';

interface Item {
  name: string;
  field: string;
  isChecked: boolean;
  isDisabled: boolean;
}

interface Props {
  items: Item[];
  isOpen: boolean;
  placement?: string;
  width?: number;
  focusPopoverDisabled?: boolean;
  renderTrigger: () => Node;
  onChange: (value: { isChecked: boolean, field: string, index: number }) => void;
  setIsOpen: (value: boolean) => void;
  onClose: () => void;
  onClick?: () => void;
}

export const WabbSwitcherPopover: FC<Props> = (props) => {
  const {
    placement, width, isOpen,
    onChange, setIsOpen, onClick, onClose, renderTrigger, items, focusPopoverDisabled = true,
  } = props;

  return (
    <Popover
      renderTrigger={renderTrigger}
      placement={placement}
      size={{ width }}
      controllableOpenState={{ isOpen, setIsOpen }}
      disabled={focusPopoverDisabled}
    >
      <div className="wabb-switcher-popover">
        {
          items.map(({
            name, field, isChecked, isDisabled,
          }, index) => (
            <div key={`${index + Math.random() * 1000}`} className="wabb-switcher-popover__item">
              <Switcher
                isDisabled={isDisabled}
                onChange={(value) => onChange({ isChecked: value, field, index })}
                isChecked={isChecked}
              />
              <span>{name}</span>
            </div>
          ))
        }
        {items.length > 1 && (
        <div className="wabb-switcher-popover__buttons">
          <Button type="secondary" style="tint" onClick={onClose} size={EButtonSize.S}>
            Cancel
          </Button>
          <Button type="primary" onClick={onClick} size={EButtonSize.S}>
            Save
          </Button>
        </div>
        )}
      </div>
    </Popover>
  );
};
