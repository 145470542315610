import { createSelector } from 'reselect';

export const getAllUsersSelector = createSelector(
  [state => state.audience.users],
  users => users,
);
export const getAllUsersCount = createSelector(
  [state => state.audience.allUsersCount],
  allUsersCount => allUsersCount,
);
export const getLoaderSelector = createSelector(
  [state => state.audience.loader],
  loader => loader,
);
export const getLoaderAdminsSelector = createSelector(
  [state => state.audience.loaderAdmins],
  loader => loader,
);
export const queryStringLoaderSelector = createSelector(
  [state => state.audience.queryStringLoader],
  queryStringLoader => queryStringLoader,
);
export const getFilterUsersCountSelector = createSelector(
  [state => state.audience.filterUsersCount],
  filterUsersCount => filterUsersCount,
);
export const getFilters = createSelector(
  [state => state.audience.filters],
  filters => filters,
);
export const getNext = createSelector([state => state.audience.next], next => next);
export const getFilterType = createSelector(
  [state => state.audience.filterType],
  filterType => filterType,
);

export const getStatSelector = createSelector(
  [state => state.audience.stats],
  stats => stats,
);

export const getAdminsSelector = createSelector(
  [state => state.audience.admins],
  stats => stats,
);

export const getUsersSortTypes = createSelector(
  [state => state.audience.usersSortType],
  usersSortType => usersSortType,
);
export const getSubscribedSortTypes = createSelector(
  [state => state.audience.subscribedSortType],
  subscribedSortType => subscribedSortType,
);
export const getSelectedUsersList = createSelector(
  [state => state.audience.users],
  users =>
    users.reduce((arr, user) => {
      if (user.checked) {
        arr.push(user.id);
      }

      return arr;
    }, []),
);
export const getUnselectedUsersList = createSelector(
  [state => state.audience.users],
  users =>
    users.reduce((arr, user) => {
      if (!user.checked) {
        arr.push(user.id);
      }

      return arr;
    }, []),
);
export const getSearchNameSelector = createSelector(
  [state => state.audience.searchName],
  searchName => searchName,
);
export const getSelectedAllMode = createSelector(
  [state => state.audience.selectedAll],
  selectedAll => selectedAll,
);
export const getQueryString = createSelector(
  [state => state.audience.queryString],
  queryString => queryString,
);

export const getNewSavedFilterState = createSelector(
  [state => state.audience.savedFilterState],
  savedFilterState => savedFilterState,
);
