/* eslint-disable import/no-unresolved */
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import Button from '@wabb-ui-kit/Button/Button';
import { Popover } from '@ui-kit';
import CheckBox from '@wabb-ui-kit/CheckBox/CheckBox';
import { EButtonSize, EIconPosition } from '@wabb-ui-kit/Button/types/types';
import { getSelectedCompanySelector } from '@redux/companies/companiesSelectors';
import { getFilterType } from '@redux/audience/audienceSelectors';
import { getBroadcastFilterTypeSelector } from '@redux/broadcast/broadcastSelector';
import { useInternationalization } from '@hooks/useTranslationHook';
import { broadcastActions } from '@redux/broadcast/broadcastActions';
import { audienceActions } from '@redux/audience/audienceActions';
import { PlusIcon } from '@wabb-assets/svg/Button/plus-icon';
import { DeleteIcon } from '@wabb-assets/svg/Button/delete-icon';
import { CustomFieldsFilterModal } from '../../filtersPopover/components/customFieldsFilterModal';
import './HeaderFilters.scss';

export const HeaderFilters = ({ broadcasts, filters }) => {
  const { formatMessage } = useInternationalization();
  const dispatch = useDispatch();
  const company = useSelector(getSelectedCompanySelector);
  const filterType = broadcasts
    ? useSelector(getBroadcastFilterTypeSelector)
    : useSelector(getFilterType);
  const history = useHistory();

  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  const [selectedFilter, setSelectedFilter] = useState({
    filterName: {},
    condition: {},
    value: {},
  });

  const deleteAllFilters = () => {
    if (broadcasts) {
      dispatch(broadcastActions.deleteAllFiltersFromBroadcast());
    } else {
      dispatch(audienceActions.deleteAllFilters());
      history.push(history.location.pathname);
    }
  };

  const changeFilterType = () => {
    broadcasts
      ? dispatch(broadcastActions.setFilterTypeToBroadcast(filterType ? 0 : 1))
      : dispatch(audienceActions.setFilterType(filterType ? 0 : 1));
  };

  const updateSelectedFilter = (data, close = false) => {
    if (close) {
      broadcasts
        ? dispatch(
            broadcastActions.addFilterToBroadcast({
              ...selectedFilter,
              ...data,
            }),
          )
        : dispatch(audienceActions.addFilter({ ...selectedFilter, ...data }));
      setIsPopoverOpen(false);
    } else {
      setSelectedFilter({ ...selectedFilter, ...data });
    }
  };

  return (
    <div className="header-filters__container">

      {/* NOTE: ON HOLD */}
      {/* <Button
        type="primary"
        style="tint"
        size={EButtonSize.S}
        icon={<PlusIcon />}
        iconPosition={EIconPosition.RIGHT}
      >
        {formatMessage('General filter')}
      </Button>
      <Button
        type="primary"
        style="tint"
        size={EButtonSize.S}
        icon={<PlusIcon />}
        iconPosition={EIconPosition.RIGHT}
      >
        {formatMessage('System filter')}
      </Button> */}
      {
        !broadcasts && <Popover
        size={{ width: '600px', height: '372px' }}
        controllableOpenState={{ isOpen: isPopoverOpen, setIsOpen: setIsPopoverOpen }}
        renderTrigger={() => (
          <div>
            <Button
              type="primary"
              style="tint"
              size={EButtonSize.S}
              icon={<PlusIcon />}
              iconPosition={EIconPosition.RIGHT}
            >
              {formatMessage('Custom filter')}
            </Button>
          </div>
        )}
      >
        <CustomFieldsFilterModal
          step="Custom user field"
          companyBot={company.bot}
          selectedFilter={selectedFilter}
          updateSelectedFilter={updateSelectedFilter}
          closePopover={() => setIsPopoverOpen(false)}
        />
      </Popover>
      }
      {filters.length > 0 && (
        <>
          <div className="header-filters__filters">
            <div className="header-filters__filters-checkbox">
              <CheckBox
                type="checkbox"
                isChecked={filterType}
                onChange={changeFilterType}
              />
            </div>
            <div className="header-filters__filters-title">
              {formatMessage('Apply filters together')}
            </div>
          </div>
          <Button
            type="negative"
            style="link"
            size={EButtonSize.S}
            onClick={deleteAllFilters}
            icon={<DeleteIcon />}
            iconPosition={EIconPosition.LEFT}
          >
            Clear All
          </Button>
        </>
      )}
    </div>
  );
};
