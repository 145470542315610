import React from 'react';

export const UploadFileIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="12"
    height="20"
    viewBox="0 0 12 20"
    fill="none"
  >
    <path
      d="M6.82311 0.812519C6.6015 0.593799 6.31651 0.484375 6.01583 0.484375C5.71504 0.484375 5.43017 0.59379 5.20855 0.812519L0.83242 5.12193C0.389194 5.55938 0.389194 6.26239 0.83242 6.69985C1.27565 7.1373 1.98795 7.1373 2.43117 6.69985L4.86028 4.3121L4.86028 13.8631C4.86028 14.488 5.36688 14.988 6 14.988C6.63312 14.988 7.13972 14.488 7.13972 13.8631L7.13972 4.3121L9.56883 6.69985C10.0121 7.1373 10.7244 7.1373 11.1676 6.69985C11.6108 6.2624 11.6108 5.55938 11.1676 5.12193L6.82311 0.812519Z"
      fill="#1187F4"
    />
    <path
      d="M10.3444 17.2346H1.63972C1.0066 17.2346 0.5 17.7346 0.5 18.3595C0.5 18.9844 1.0066 19.4844 1.63972 19.4844H10.3603C10.9934 19.4844 11.5 18.9844 11.5 18.3595C11.5 17.7345 10.9777 17.2346 10.3444 17.2346Z"
      fill="#1187F4"
    />
  </svg>
);
