import React from 'react';

export const DonwloadIcon = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="Arrows/to-bottom">
      <g id="Vector">
        <path
          d="M10.0035 1.875C10.3486 1.875 10.6285 2.15482 10.6285 2.5V14.1248C10.6285 14.47 10.3486 14.7498 10.0035 14.7498C9.65828 14.7498 9.37846 14.47 9.37846 14.1248V2.5C9.37846 2.15482 9.65828 1.875 10.0035 1.875Z"
          fill="#5925DC"
        />
        <path
          d="M4.55806 8.72473C4.80214 8.48065 5.19786 8.48065 5.44194 8.72473L10 13.2828L14.5581 8.72473C14.8021 8.48065 15.1979 8.48065 15.4419 8.72473C15.686 8.9688 15.686 9.36453 15.4419 9.60861L10.4419 14.6086C10.1979 14.8527 9.80214 14.8527 9.55806 14.6086L4.55806 9.60861C4.31398 9.36453 4.31398 8.9688 4.55806 8.72473Z"
          fill="#5925DC"
        />
        <path
          d="M4.375 17.5C4.375 17.1548 4.65482 16.875 5 16.875H15C15.3452 16.875 15.625 17.1548 15.625 17.5C15.625 17.8452 15.3452 18.125 15 18.125H5C4.65482 18.125 4.375 17.8452 4.375 17.5Z"
          fill="#5925DC"
        />
      </g>
    </g>
  </svg>
);
