import { clsx } from 'clsx';
import {
  EComponentType,
  EInputType,
  EIconDirection,
} from '@wabb-ui-kit/FormField/types/types';
import styles from './FormField.module.scss';

export const useFormFieldStyles = (
  className: string,
  component: EComponentType,
  type: EInputType,
  errorText: string,
  isDisabled: boolean,
  iconDirection: EIconDirection,
  value: string,
) => ({
  fieldClassNames: clsx(styles.field, className, {
    [styles.search]: type === EInputType.Search,
    [styles.isValue]: value.length > 0 && iconDirection === EIconDirection.Left,
    [styles.input]: component === EComponentType.Input,
    [styles.textArea]: component === EComponentType.TextArea,
    [styles.inputError]: errorText.length > 0,
    [styles.searchError]: errorText.length > 0 && type === EInputType.Search,
    [styles.disabled]: isDisabled,
    [styles.leftIcon]: iconDirection === EIconDirection.Left,
    [styles.rightIcon]: iconDirection === EIconDirection.Right,
  }),
  buttonClassName: clsx({
    [styles.iconButton]: component === EComponentType.Input,
    [styles.iconTextArea]: component === EComponentType.TextArea,
  }),
});
