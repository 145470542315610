import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Code, ConnectError } from '@connectrpc/connect';
import { getSelectedCompanySelector } from '@redux/companies/companiesSelectors';
import { useInternationalization } from '@hooks/useTranslationHook';
import { sequencesApi } from '@api/sequencesApi';
import { bcSplitTableActions } from '@redux/bcSplitTable/bcSplitTableActions';
import { ModalContainer } from '../../containers/Modal/ModalContainer';
import { MODALS } from '../../containers/Modal/modalConstants';
import { getModalDataSelector } from '../../containers/Modal/modalSelectors';
import FormField from '@wabb-ui-kit/FormField/FormField.tsx';
import Button from '@wabb-ui-kit/Button/Button.tsx';
import { EButtonSize } from '@wabb-ui-kit/Button/types/types.ts';

import './createSequenceModal.scss';

export const CreateSequenceModal = () => {
  const dispatch = useDispatch();
  const { formatMessage } = useInternationalization();

  const modalData = useSelector(getModalDataSelector).modalData;
  const botId = useSelector(getSelectedCompanySelector).bot;

  const [sequenceName, setSequenceName] = useState(modalData.sequenceName);
  const [error, setError] = useState('');

  const handleCleanSequenceName = () => {
    setSequenceName('');
  };

  const handleConfirm = () => {
    const { sequenceId, onClose } = modalData;

    const apiCall = sequenceId
      ? sequencesApi.updateSequence(sequenceName, sequenceId)
      : sequencesApi.createSequence({ name: sequenceName, botId });

    apiCall
      .then(res => {
        const action = sequenceId
          ? bcSplitTableActions.updateItemInSplitTable(res)
          : bcSplitTableActions.createNewItemSuccess(res);

        dispatch(action);
        onClose();
      })
      .catch(err => {
        const connectErr = ConnectError.from(err);

        if (connectErr && connectErr.code === Code.InvalidArgument) {
          setError('A sequence with this name or phrase already exists.');
        }
      });
  };

  return (
    <ModalContainer modal={MODALS.createSequenceModal} onClose={modalData.onClose}>
      <div className="sequence-modal">
        <div className="sequence-modal__body">
          <div className="sequence-modal__header">
            {modalData.sequenceId
              ? formatMessage('Edit sequence')
              : formatMessage('Create new sequence')}
          </div>
          <div className="sequence-modal__vertical-block">
            <FormField
              label="Name"
              value={sequenceName}
              placeholder="Type here..."
              isRequired
              width="100%"
              errorText={error}
              onChange={event => setSequenceName(event.target.value)}
              onCrossClick={handleCleanSequenceName}
            />
          </div>
        </div>
        <div className="sequence-modal__body_button">
          <Button
            type="primary"
            size={EButtonSize.L}
            isDisabled={!sequenceName}
            onClick={handleConfirm}
          >
            <span className="sequence-modal__text_white">
              {modalData.sequenceId ? formatMessage('Save') : formatMessage('Create')}
            </span>
          </Button>
          <Button
            type="secondary"
            style="tint"
            size={EButtonSize.L}
            onClick={modalData.onClose}
          >
            <span>{formatMessage('Cancel')}</span>
          </Button>
        </div>
      </div>
    </ModalContainer>
  );
};
