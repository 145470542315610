import React from 'react';

import { MODALS } from '@components/containers/Modal/modalConstants';
import { ModalContainer } from '@components/containers/Modal/ModalContainer';
import { useSelector } from 'react-redux';
import { getModalDataSelector } from '@components/containers/Modal/modalSelectors';
import { useInternationalization } from '@hooks/useTranslationHook';
import { Button } from '@components/common/Button';
import { FranchiseCompanyApi } from '@api/franchiseCompanyApi';

import './deleteEmailDomainModal.scss';

export const DeleteEmailDomainModal = () => {
  const { modalData } = useSelector(getModalDataSelector);
  const { formatMessage } = useInternationalization();

  const updateObj = new FormData();

  const onClose = () => {
    modalData.onClose();
  };

  const handleDeleteEmailDomain = () => {
    updateObj.append('email_from', '');
    updateObj.append('email_host', '');
    updateObj.append('email_password', '');
    updateObj.append('email_port', '');
    updateObj.append('email_username', '');
    updateObj.append('email_type', 0);
    updateObj.append('use_custom_email_domain', false);

    FranchiseCompanyApi.setFranchiseSettings(modalData.id, updateObj).then(res =>
      modalData.setEmailDomain(prevState => ({
        ...prevState,
        email_from: res.data.email_from,
        email_host: res.data.email_host,
        email_type: res.data.email_type,
        email_port: res.data.email_port,
        email_username: res.data.email_username,
        email_password: res.data.email_password,
      })),
    );
    onClose();
  };

  return (
    <ModalContainer modal={MODALS.deleteEmailDomainModal} onClose={onClose}>
      <div className="delete-email-support-container">
        <div className="delete-email-support-container__title">
          {formatMessage('Delete Custom Email Domain Settings')}
        </div>
        <div className="delete-email-support-container__subtitle">
          {formatMessage(
            'All of the data from your custom domain will be erased with this action. Are you sure you want to continue?',
          )}
        </div>
        <div className="delete-email-support-container__button-block">
          <Button
            color="red"
            width="100%"
            customClass="delete-email-support-container__button-block_button"
            onClick={handleDeleteEmailDomain}
          >
            {formatMessage('Yes, delete the data')}
          </Button>
          <Button
            color="gray"
            width="100%"
            customClass="delete-email-support-container__button-block_button-cancel"
            onClick={onClose}
          >
            {formatMessage('Cancel')}
          </Button>
        </div>
      </div>
    </ModalContainer>
  );
};
