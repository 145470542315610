import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { useInternationalization } from '@hooks/useTranslationHook';
import { webhooksApi } from '@api/webhooksApi';
import { bcSplitTableActions } from '@redux/bcSplitTable/bcSplitTableActions';
import { getSelectedCompanySelector } from '@redux/companies/companiesSelectors';
import { NewBcField } from '@components/common/fields/newBcField/newBcField';
import { ClearingFieldIcon } from '@icons/clearing-field-icon';
import { AudienceButton } from '@pages/audience/components/audienceButton/audienceButton';
import { ModalContainer } from '../../containers/Modal/ModalContainer';
import { MODALS } from '../../containers/Modal/modalConstants';
import { getModalDataSelector } from '../../containers/Modal/modalSelectors';

import './createWebhookModal.scss';

export const CreateWebhookModal = () => {
  const { formatMessage } = useInternationalization();
  const { modalData } = useSelector(getModalDataSelector);
  const botId = useSelector(getSelectedCompanySelector).bot;
  const dispatch = useDispatch();

  const [hotmartIntegrations, setHotmartIntegrations] = useState(
    modalData.is_webhook_from_hotmart || false,
  );
  const [name, setName] = useState(modalData.name || '');
  const [clientId, setClientId] = useState(modalData.client_id || '');
  const [clientSecret, setClientSecret] = useState(modalData.client_secret || '');
  const [basic, setBasic] = useState(modalData.basic || '');

  const handleConfirm = () => {
    if (modalData.name) {
      webhooksApi
        .updateWebhook(modalData.webhookId, {
          name,
          client_id: clientId,
          client_secret: clientSecret,
          basic,
          is_webhook_from_hotmart: Boolean(clientSecret && clientId && basic),
        })
        .then(res => {
          dispatch(bcSplitTableActions.updateItemInSplitTable(res.data));
          modalData.onClose();
        });
    } else {
      webhooksApi
        .createWebhook({
          name,
          botId,
          clientId,
          clientSecret,
          basic,
        })
        .then(res => {
          dispatch(bcSplitTableActions.createNewItemSuccess(res.data));
          modalData.onClose();
        });
    }
  };

  return (
    <ModalContainer
      modal={MODALS.createWebhookModal}
      onClose={() => {
        modalData.onClose();
      }}
      customStyles={{ width: 'auto' }}
    >
      <div className="create-webhook-modal">
        <div className="create-webhook-modal__header">
          {formatMessage('Create webhook')}
        </div>
        <div className="create-webhook-modal__body">
          <div className="create-webhook-modal__body_hotmart_field">
            <NewBcField
              name="webhook_name"
              label={formatMessage('Webhook name')}
              onChange={event => setName(event.target.value)}
              value={name}
              Icon={<ClearingFieldIcon />}
            />
          </div>
          {/*<div className="create-webhook-modal__body_hotmart_description">*/}
          {/*  <span>{formatMessage('Hotmart settings')}</span>*/}
          {/*  <div className="create-webhook-modal__body_hotmart_description_container">*/}
          {/*    <div>*/}
          {/*      {formatMessage('If you use')} {formatMessage('Hotmart') + ' '}*/}
          {/*      <HotmartIcon />*/}
          {/*      {' ' + formatMessage('a webhook please allow us to use your data')}*/}
          {/*    </div>*/}
          {/*    <div>*/}
          {/*      <BcToggleButton*/}
          {/*        checked={hotmartIntegrations}*/}
          {/*        onClick={() => {*/}
          {/*          setHotmartIntegrations(!hotmartIntegrations);*/}
          {/*        }}*/}
          {/*      />*/}
          {/*    </div>*/}
          {/*  </div>*/}
          {/*</div>*/}
          {/*{hotmartIntegrations && (*/}
          {/*  <div className="create-webhook-modal__body_hotmart">*/}
          {/*    <div className="create-webhook-modal__body_hotmart_field">*/}
          {/*      <input*/}
          {/*        placeholder={formatMessage('Client Id')}*/}
          {/*        value={clientId}*/}
          {/*        onChange={(event) => {*/}
          {/*          setClientId(event.target.value);*/}
          {/*        }}*/}
          {/*      />*/}
          {/*    </div>*/}
          {/*    <div className="create-webhook-modal__body_hotmart_field">*/}
          {/*      <input*/}
          {/*        placeholder={formatMessage('Client Secret')}*/}
          {/*        value={clientSecret}*/}
          {/*        onChange={(event) => {*/}
          {/*          setClientSecret(event.target.value);*/}
          {/*        }}*/}
          {/*      />*/}
          {/*    </div>*/}
          {/*    <div className="create-webhook-modal__body_hotmart_field">*/}
          {/*      <input*/}
          {/*        placeholder={formatMessage('Basic')}*/}
          {/*        value={basic}*/}
          {/*        onChange={(event) => {*/}
          {/*          setBasic(event.target.value);*/}
          {/*        }}*/}
          {/*      />*/}
          {/*    </div>*/}
          {/*  </div>*/}
          {/*)}*/}
          <div className="create-webhook-modal__body_button">
            <AudienceButton
              width={320}
              height={56}
              color="green"
              disabled={
                !(hotmartIntegrations
                  ? Boolean(name && clientId && clientSecret && basic)
                  : Boolean(name))
              }
              onClick={handleConfirm}
            >
              <span>
                {modalData.name ? formatMessage('Update') : formatMessage('Create')}
              </span>
            </AudienceButton>
          </div>
        </div>
      </div>
    </ModalContainer>
  );
};
