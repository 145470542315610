import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import ReactHtmlParser from 'html-react-parser';

import '../modalsComponent.scss';

import { useInternationalization } from '@hooks/useTranslationHook';
import { ModalContainer } from '../../containers/Modal/ModalContainer';
import { MODALS } from '../../containers/Modal/modalConstants';
import { getModalDataSelector } from '../../containers/Modal/modalSelectors';
import { modalActions } from '../../containers/Modal/modalActions';
import { AudienceButton } from '@pages/audience/components/audienceButton/audienceButton';

const styles = {
  width: '60%',
};

function NewlineText(props) {
  const { text } = props;
  return text.split('\n').map(str => <p>{ReactHtmlParser(str)}</p>);
}

export const AnnounceModal = () => {
  const { formatMessage } = useInternationalization();

  const dispatch = useDispatch();

  const { modalData } = useSelector(getModalDataSelector);

  const handleClickLinkButton = () => {
    window.open(modalData.link);
  };

  return (
    <ModalContainer
      modal={MODALS.announce}
      onClose={modalData.onClose}
      customStyles={styles}
    >
      {modalData.isSmallScreen ? (
        <div className="campaigns-modal">
          <div className="campaigns-modal__header">{formatMessage('Warning')}</div>
          <div className="delete-item-modal__content">
            <div className="delete-item-modal__description">
              {formatMessage(
                'Nossa versão para celular não é totalmente compatível, por favor, acesse por um computador.',
              )}
            </div>
            <div className="delete-item-modal__footer-group-launch">
              <AudienceButton
                onClick={() => dispatch(modalActions.closeModal())}
                color="transparent"
                customClass="delete-item-modal__button"
              >
                {formatMessage('Fechar modal')}
              </AudienceButton>
            </div>
          </div>
        </div>
      ) : (
        <div className="campaigns-modal">
          <div className="campaigns-modal__header2">{modalData.title}</div>
          <div className="delete-item-modal__content">
            <div className="delete-item-modal__description-container">
              <div className="delete-item-modal__description">
                {' '}
                {modalData.body && <NewlineText text={modalData.body} />}
              </div>
            </div>
            {modalData.videoLink && (
              <div style={{ alignSelf: 'center', borderRadius: '20px', width: '500px' }}>
                <div style={{ padding: '56.25% 0 0 0', position: 'relative' }}>
                  <iframe
                    src={modalData.videoLink}
                    allow="autoplay; fullscreen; picture-in-picture allowFullScreen"
                    style={{
                      position: 'absolute',
                      top: '0',
                      left: '0',
                      width: '100%',
                      height: '100%',
                      borderRadius: '20px',
                      border: '1px solid #D5D8DC',
                    }}
                    title="O Que &amp;Eacute; Um Rob&amp;ocirc;.mp4"
                  />
                </div>
              </div>
            )}
            <div className="delete-item-modal__footer-group">
              <AudienceButton
                onClick={modalData.onClose}
                color="transparent"
                customClass="delete-item-modal__button"
              >
                {formatMessage('Cancel')}
              </AudienceButton>
              {modalData.buttonName && modalData.link && (
                <AudienceButton
                  color="green"
                  customClass="delete-item-modal__button"
                  onClick={handleClickLinkButton}
                >
                  {modalData.buttonName}
                </AudienceButton>
              )}
            </div>
          </div>
        </div>
      )}
    </ModalContainer>
  );
};
