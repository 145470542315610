import api from './baseApi';
import { audienceApiRpc } from '@connectApi/baseApi';
import {
  PiplineListBotWithCursorPaginationRequest,
  PipelineUserCreateRequest,
  PipelineUserCardGetRequest,
  PipelineUserCardPatchRequest,
  PipelineUserCardDeleteRequest,
  PipelineUserCardPostRequest,
  PipelineUserListActionBotRequest,
  PipelineUserAddDeleteTagToCardPostRequest,
  PipelineUserAddDeleteSequenceToCardPostRequest,
  PipelineUserAddDeleteVariableToCardPostRequest,
  PipelineUserAddDeleteAdsSourceToCardRequest,
  PipelineUserAddDeleteVariableToCardPatchRequest,
  PipelineUserCardSendFlowRequest,
  PiplineUserRequest,
  FiltersSuggestedValuesRequest,
} from 'wabb-backend-api/build/es/pipelines_user/pipelines_user_pb';

export const audienceApi = {
  getAudienceUsers: (botId, cursor, queryString) => { 
    const params = new URLSearchParams(queryString);
    const queryObject = {};
    for (const [key, value] of params.entries()) {
      queryObject[key] = value;
    }

    const paramsObj = {
      bot_id: botId,
      filters: queryObject,
    }

    if(!queryString){ 
      paramsObj.cursor = cursor;
    }

    return audienceApiRpc.pipelineUserListBotWithCursorPagination(
      PiplineListBotWithCursorPaginationRequest.fromJson(
        paramsObj
      ),
    );
  },


  getAudienceCount: (botId, queryString) => {
    const params = new URLSearchParams(queryString);

    const search_string = params.get('search_name');

    return audienceApiRpc.pipelineUserListCountBot(
      PiplineUserRequest.fromJson({
        bot_id: botId,
        search_string: search_string ? search_string : '',
      }),
    );
  },

  createAudienceUser: data =>
    audienceApiRpc.pipelineUserCreate(PipelineUserCreateRequest.fromJson(data)),


  getSuggestedValues: (botId, filterName, queryArg, page) =>{
    
    return audienceApiRpc.filtersSuggestedValues({
        botId,
        filterName,
        q: queryArg ? queryArg : '',
        page,
      },
    )},

  getStatistics: botId =>
    audienceApiRpc.commonInfoAudiencePage(PiplineUserRequest.fromJson({ bot_id: botId })),

  // previous version download full report
  downloadAudeinceSources: botId => api.get(`/pipelines/export/bot/${botId}/`),

  getUserDetails: (userId, jid, botId) =>
    audienceApiRpc.pipelineUserCardGet(
      PipelineUserCardGetRequest.fromJson({
        bot_id: botId,
        jid: jid ? jid : '',
        user_id: userId,
      }),
    ),

  // assignChat: (userId, isBusy, busyById, botId) =>
  //   api.post(`/pipelines/user_card/${userId}/assign_chat/?bot_id=${botId}`, {
  //     is_busy: isBusy,
  //     busy_by_id: busyById,
  //   }),

  addMarker: (userId, botId, idMarker, type, value) => {
    const idKey = type === 'ads_source' ? `${type}_id` : `${type}_type_id`;
    const data =
      type !== 'variable'
        ? { bot_id: botId, user_id: userId, [idKey]: idMarker }
        : { bot_id: botId, user_id: userId, value, [idKey]: idMarker };
    let request;
    if (type === 'ads_source') {
      request = audienceApiRpc.pipelineUserAddAdsSourceToCard(
        PipelineUserAddDeleteAdsSourceToCardRequest.fromJson({
          botId: data.bot_id,
          user_id: data.user_id,
          ads_source_id: data.ads_source_id,
        }),
      );
    } else if (type === 'tag') {
      request = audienceApiRpc.pipelineUserAddDeleteTagToCardPost(
        PipelineUserAddDeleteTagToCardPostRequest.fromJson(data),
      );
    } else if (type === 'sequence') {
      request = audienceApiRpc.pipelineUserAddDeleteSequenceToCardPost(
        PipelineUserAddDeleteSequenceToCardPostRequest.fromJson(data),
      );
    } else {
      request = audienceApiRpc.pipelineUserAddDeleteVariableToCardPost(
        PipelineUserAddDeleteVariableToCardPostRequest.fromJson(data),
      );
    }
    return request;
  },

  removeMarker: (userId, botId, idMarker, type) => {
    const typeChanger = type === 'ads_source' ? 'adsSource' : type;
    const idKey = `${typeChanger}Id`;
    const data = { botId, userId, [idKey]: idMarker };
    let request;
    if (type === 'ads_source') {
      request = audienceApiRpc.pipelineUserAddDeleteAdsSourceToCardDelete(data);
    } else if (type === 'tag') {
      request = audienceApiRpc.pipelineUserAddDeleteTagToCardDelete(data);
    } else if (type === 'sequence') {
      request = audienceApiRpc.pipelineUserAddDeleteSequenceToCardDelete(data);
    } else {
      request = audienceApiRpc.pipelineUserAddDeleteVariableToCardDelete(data);
    }
    return request;
  },

  editVariable: (userId, botId, idMarker, value) => {    

    const idKey = 'variable_id';

    const data = { bot_id: botId, user_id: userId, [idKey]: idMarker, value };
    return audienceApiRpc.pipelineUserAddDeleteVariableToCardPatch(
      PipelineUserAddDeleteVariableToCardPatchRequest.fromJson(data),
    );
  },

  updateUserCard: (userId, botId, data) => {
    const requestData = {
      user_id: userId ? userId : '',
      bot_id: botId ? botId : '',
      ...data,
    };

    return audienceApiRpc.pipelineUserCardPatch(
      PipelineUserCardPatchRequest.fromJson(requestData),
    );
  },

  deleteUser: (userId, botId) =>
    audienceApiRpc.pipelineUserCardDelete(
      PipelineUserCardDeleteRequest.fromJson({
        bot_id: botId,
        user_id: userId,
      }),
    ),

  sendFlow: (flowId, userId, botId) =>
    audienceApiRpc.pipelineUserCardSendFlow(
      PipelineUserCardSendFlowRequest.fromJson({
        user_id: userId,
        bot_id: botId,
        flow_id: flowId,
      }),
    ),

  subscribeUser: (avatar, phone, full_name, bot, userId) =>
    audienceApiRpc.pipelineUserCardPost(
      PipelineUserCardPostRequest.fromJson({
        avatar_url: avatar || null,
        phone: phone || null,
        full_name,
        bot_id: bot,
        user_id: userId,
      }),
    ),


  getUploadUsersStatus: id => api.get(`/pipelines/upload_users/status/${id}/`),

  setBulkAction: data =>{
    return audienceApiRpc.pipelineUserListActionBot(
      PipelineUserListActionBotRequest.fromJson(data),
    )},

  getBulkActionsStatus(id) {
    return audienceApiRpc.bulkActionsOperationsStatus({ botId: id });
  },

  saveFilters(botId, data) {
    return api.post('pipelines/saved_filter/create/', data);
  },

  getSavedFiltersList: botId => api.get(`pipelines/saved_filters/${botId}/`),
  deleteSavedFilter: savedFilterId =>
    api.delete(`pipelines/saved_filter/${savedFilterId}/`),
};
