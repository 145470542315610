import React, { useEffect, useState } from 'react';
import classNames from 'classnames';

import { ConfirmPasswordConditionIcon } from '@icons/confirm-password-condition-icon';
import { useInternationalization } from '@hooks/useTranslationHook';
import { PasswordField } from '@components/common/fields/PasswordField/PasswordField';
import { FailedPasswordConditionIcon } from '@icons/failedPasswordConditionIcon';
import FormField from '@wabb-ui-kit/FormField/FormField.tsx';

import './InputPasswordComponent.scss';

const PASSWORD_VALIDATION_STRING =
  /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*(),.?":{}|<>]).{8,}$/;

const CONDITIONS = [
  {
    text: 'at least 8 characters',
    condition: password => password.length >= 8,
  },
  {
    text: 'lower case and upper case letters',
    condition: password => /(?=.*[a-z])/.test(password) && /(?=.*[A-Z])/.test(password),
  },
  {
    text: 'at least 1 digit',
    condition: password => /(?=.*[0-9])/.test(password),
  },
  {
    text: 'at least 1 special character (@#-_$%^&+=!?) ',
    condition: password => /(?=.*[!@#$%^&*(),.?":{}|<>])/.test(password),
  },
  {
    text: 'passwords match',
    condition: (password, confirmNewPassword) =>
      password === confirmNewPassword && password && confirmNewPassword,
  },
];

export const InputPasswordComponent = ({
  setNewPassword,
  setIsPasswordAccept,
  setConfirmPassword,
  additionalConditions = [],
}) => {
  const { formatMessage } = useInternationalization();

  const [password, setPassword] = useState('');
  const [confirmNewPassword, setConfirmNewPassword] = useState('');
  const [passwordInputType, setPasswordInputType] = useState('password');
  const [confirmPasswordInputType, setConfirmPasswordInputType] = useState('password');

  const onFieldChange = (e)=>{
  setConfirmNewPassword(e.target.value)
  }

  useEffect(() => {
    if (PASSWORD_VALIDATION_STRING.test(password)) {
      if (password === confirmNewPassword) {
        if (
          additionalConditions.reduce(
            (accept, curValue) =>
              accept && curValue.condition(password, confirmNewPassword),
            true,
          )
        ) {
          setIsPasswordAccept(true);
        } else {
          setIsPasswordAccept(false);
        }
      } else {
        setIsPasswordAccept(false);
      }
    } else {
      setIsPasswordAccept(false);
    }

    setNewPassword(password);

    if (setConfirmPassword) {
      setConfirmPassword(confirmNewPassword);
    }
  }, [password, confirmNewPassword]);

  return (
    <div className="input-password-component">
      <div className="input-password-component__content">
        <div className="input-password-component__content_field">
          <FormField
            label="New password"
            id="new_password"
            name="new_password"
            value={password}
            type={passwordInputType}
            onChange={e => setPassword(e.target.value)}
            onClick={() =>
              setPasswordInputType(passwordInputType === 'password' ? 'text' : 'password')
            }
            placeholder={formatMessage('New Password')}
          />
        </div>
        <div className="input-password-component__content_field">
          <FormField
            label="Repeat password"
            id="confirm_password"
            name="confirm_password"
            value={confirmNewPassword}
            type={confirmPasswordInputType}
            onChange={e => onFieldChange(e)}
            onClick={() =>
              setConfirmPasswordInputType(
                confirmPasswordInputType === 'password' ? 'text' : 'password',
              )
            }
            placeholder={formatMessage('Confirm password')}
          />
        </div>
      </div>
      <div className="input-password-component__conditions">
        {[...CONDITIONS, ...additionalConditions].map(item => (
          <CheckPasswordCondition
            text={formatMessage(item.text)}
            condition={item.condition(password, confirmNewPassword)}
          />
        ))}
      </div>
    </div>
  );
};

const CheckPasswordCondition = ({ condition, text }) => (
  <div className="input-password-component__conditions_item">
    <div>
      {condition ? <ConfirmPasswordConditionIcon /> : <FailedPasswordConditionIcon />}
    </div>
    <div
      className={classNames('input-password-component__conditions_item_text', {
        'input-password-component__conditions_item_text': condition,
        'input-password-component__conditions_item_text_active': condition,
      })}
    >
      <span>{text}</span>
    </div>
  </div>
);
