import { useEffect } from 'react';

const useOutsideClick = (
  action: (e?: Event) => void,
  ...refs: React.RefObject<HTMLElement>[]
) => {
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (!refs.some(ref => ref?.current?.contains(event.target as Node))) {
        action(event);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [action, refs]);
};

export default useOutsideClick;
