import React, { useState } from 'react';
import classnames from 'classnames';
import {
  Editor,
} from 'draft-js';

import Button from '@wabb-ui-kit/Button/Button';
import { TextDecoratorsPanel } from '@components/common/TextEditor/elements';
import { transformEditorState } from '@components/common/TextEditor/helpers';
import { useTextEditor } from '@components/common/TextEditor/hooks';

import { useInternationalization } from '@hooks/useTranslationHook';

import 'draft-js/dist/Draft.css';
import './TextEditor.scss';

export const TextEditor = ({
  initValue,
  autoFocus = false,
  disabled = false,
  isDecorators = false,
  isControls = false,
  shouldCancelBeDisabled = false,
  isEditMode = true,
  requestFieldsList = false,
  theme = {},
  error = {},
  placeholder,
  textLimit = 0,
  customClass = '',
  setIsEditMode,
  onChange,
  onClose,
  onSave,
  children,
}) => {
  const { formatMessage } = useInternationalization();
  const {
    editorState,
    isFocused,
    isSaveDisabled,
    onFocus,
    onBlur,
    onResetTextEditor,
    onChangeTextEditor,
    onChangeFieldSelect,
    onToggleInlineStyles,
    onCheckInlineStyles,
    handleBeforeInput,
    handlePastedText,
  } = useTextEditor(initValue || { text: '', styledText: { blocks: [], entityMap: {} } }, textLimit, autoFocus);

  const [isCancelDisabled, setIsCancelDisabled] = useState(
    !initValue?.text && shouldCancelBeDisabled
  );

  const handleSave = () => {
    setIsCancelDisabled(false);

    if (onSave) {
      onSave(...transformEditorState(editorState));
    }
  };

  const handleCancel = () => {
    onResetTextEditor();

    if (onClose) {
      onClose(...transformEditorState(editorState));
    }
  };


  return (
    <>
      <div
        className={classnames(
          'text-editor',
          {
            disabled,
            'input-error': error.isError,
            focused: isFocused && !error.isError,
            'focused-error': isFocused && error.isError,
          },
          customClass
        )}
        style={theme}
      >
        <Editor
          readOnly={disabled}
          onFocus={onFocus}
          onBlur={onBlur}
          placeholder={placeholder ? formatMessage(placeholder) : ''}
          editorState={editorState}
          onChange={onChangeTextEditor}
          handleBeforeInput={handleBeforeInput}
          handlePastedText={handlePastedText}
        />
        {isEditMode && (isDecorators || requestFieldsList) ? (
          <TextDecoratorsPanel
            isDecorators={isDecorators}
            onChange={onChangeFieldSelect}
            requestFieldsList={requestFieldsList}
            hasInlineStyle={onCheckInlineStyles}
            toggleInlineStyle={onToggleInlineStyles}
          />
        ) : null}
        {textLimit && isEditMode ? (
          <div className="text-limit">
            {`${editorState.getCurrentContent().getPlainText().length}/${textLimit}`}
          </div>
        ) : null}
        {!isEditMode && setIsEditMode && (
          <div className="text-editor-constructor__message-container_edit-button">
            <div onClick={() => setIsEditMode(true)} style={{ color: '#5925DC' }} className="btn">
              {formatMessage('Edit')}
            </div>
          </div>
        )}
      </div>
      {isEditMode && isControls && !children ? (
        <div className="button-block">
          <Button
            disabled={isCancelDisabled}
            height="38px"
            width="49%"
            customClass="button-block__cancel-button"
            onClick={handleCancel}
          >
            {formatMessage('Cancel')}
          </Button>
          <Button
            disabled={isSaveDisabled}
            height="38px"
            width="49%"
            color="blue"
            onClick={handleSave}
          >
            {formatMessage('Save')}
          </Button>
        </div>
      ) : null}
      {isEditMode && !isControls && children ? children(
        transformEditorState(editorState),
        isSaveDisabled,
        isCancelDisabled
      ) : null}
    </>
  );
};

