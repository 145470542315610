import React, { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import debounce from 'lodash/debounce';

import { useInternationalization } from '@hooks/useTranslationHook';
import { LongArrowIcon } from '@icons/audience/long-arrow-icon';
import { UploadFileIcon } from '@icons/audience/upload-file-icon';
import { audienceApi } from '@api/audience';
import { getSelectedCompanySelector } from '@redux/companies/companiesSelectors';
import { AudienceSuccessfulUploadFileIcon } from '@icons/audience/audience-succesfull-upload-file-icon';
import { audienceActions } from '@redux/audience/audienceActions';
import { getQueryString } from '@redux/audience/audienceSelectors';
import { ProgressComponent } from '@components/common/ProgressComponent/ProgressComponent';
import { HelpkitInfo } from '@components/common/HelpkitInfo/HelpkitInfo';
import { useContextSelector } from 'use-context-selector';
import { ToastContext } from '../../../contexts/toastsContext';
import { AudienceButton } from '@pages/audience/components/audienceButton/audienceButton';
import { MODALS } from '../../containers/Modal/modalConstants';
import { getModalDataSelector } from '../../containers/Modal/modalSelectors';
import { ModalContainer } from '../../containers/Modal/ModalContainer';

import './uploadFile.scss';

export const UploadModal = () => {
  const { formatMessage } = useInternationalization();
  const { modalData } = useSelector(getModalDataSelector);

  const dispatch = useDispatch();
  const botId = useSelector(getSelectedCompanySelector).bot;
  const queryString = useSelector(getQueryString);

  const [showInfo, setShowInfo] = useState(false);
  const [uploadStatus, setUploadStatus] = useState(null);
  const [sendFileLoader, setSendFileLoader] = useState(false);
  const [stopUploading, setStopUploading] = useState(false);
  const [file, setFile] = useState(null);
  const [fileName, setFileName] = useState('');
  const [responseData, setResponseData] = useState({});
  const [error, setError] = useState('');

  const addToast = useContextSelector(ToastContext, ({ add }) => add);

  const handleSubmit = () => {
    const data = new FormData();
    data.append('file', file);
    data.append('botId', botId);
    setSendFileLoader(true);
    audienceApi
      .uploadUsers( data)
      .then(res => {
        setUploadStatus(res.data.progress);
        sendDebouncedReq(res.data.id, handleUploadStatus);
        setSendFileLoader(false);
      })
      .catch(err => {
        setError('Something went wrong');
        setUploadStatus(null);
        setSendFileLoader(false);
        console.log(err);
      });
  };

  const sendDebouncedReq = useCallback(
    debounce((value, callback) => callback(value), 3000),
    [],
  );

  const handleUploadStatus = id => {
    audienceApi.getUploadUsersStatus(id).then(res => {
      if (!stopUploading) {
        setUploadStatus(res.data.progress);

        if (res.data.progress === 100) {
          dispatch(audienceActions.getUsersRequest({ botId, page: 1, queryString }));
          setResponseData(res.data);
          addToast({
            type: 'success',
            title: 'Import finished',
            message: 'Your contacts file was successfully imported',
          });
        } else {
          sendDebouncedReq(res.data.id, handleUploadStatus);
        }
      } else setStopUploading(false);
    });
  };

  const handleSelectFile = event => {
    const format = event.target.files[0].name.split('.').slice(-1)[0];
    setFileName(event.target.files[0].name);

    if (format.toLowerCase() === 'xlsx') {
      setFile(event.target.files[0]);
      setError('');
    } else {
      setError('Invalid format, please change it to excel extension!');
    }
  };

  const handleClose = () => {
    if (uploadStatus && uploadStatus !== 100) {
      addToast({
        type: 'info',
        title: 'Import in progress',
        message: 'Your contact list is being imported. Check back in few minutes.',
      });
    }

    modalData.onClose();
  };

  return (
    <ModalContainer modal={MODALS.uploadUsers} onClose={handleClose}>
      <div className="upload-users-modal">
        <div className="upload-users-modal__header">
          <div
            className="upload-users-modal__header_arrow"
            onClick={() => setShowInfo(!showInfo)}
          >
            {showInfo && <LongArrowIcon />}
          </div>
          <div className="upload-users-modal__header_label">
            <div>{formatMessage('Import Contacts')}</div>
            <HelpkitInfo helpkitCode="qcKfjrGjZ9mC1uckKjAiFi" />
          </div>
          <div />
        </div>
        {!showInfo ? (
          <div className="upload-users-modal__body">
            <div>
              <span>
                {formatMessage(
                  'Please download the .xlsx document with contacts base. You can download a',
                )}{' '}
                <a
                  target="_blank"
                  href="https://docs.google.com/spreadsheets/d/11EB-_XIbZ9dO1QQcnR5qis26NMKxQYbrXZYpsmIYaJs/edit?usp=sharing"
                  rel="noreferrer"
                >
                  <font color="#1187F4">{formatMessage('Template')}</font>{' '}
                </a>
                {formatMessage(
                  'for such a document and see the rules for filling out this',
                )}{' '}
                <font color="#1187F4" onClick={() => setShowInfo(!showInfo)}>
                  {formatMessage('here')}
                </font>
              </span>
            </div>
            {uploadStatus === null ? (
              <div className="upload-users-modal__body_upload">
                {error ? (
                  <div className="upload-users-modal__body_upload_error">
                    <span>{fileName}</span>
                    <u>
                      <font color="#1187F4">
                        {formatMessage('Try again or upload new document')}
                      </font>
                    </u>
                  </div>
                ) : fileName ? (
                  <div className="upload-users-modal__body_upload_select">
                    <span>{fileName}</span>
                  </div>
                ) : (
                  <div className="upload-users-modal__body_upload_select">
                    <span>{formatMessage('Upload File')}</span>
                    <UploadFileIcon />
                  </div>
                )}
                <div className="upload-users-modal__body_upload_info">
                  <span>
                    {formatMessage('Click to select or drag and drop the file')}
                  </span>
                </div>
                <input accept=".xlsx" type="file" id="file" onChange={handleSelectFile} />
              </div>
            ) : uploadStatus === 100 ? (
              <div className="upload-users-modal__body_loading">
                <AudienceSuccessfulUploadFileIcon />
                <span>
                  {formatMessage('We have successfully create contact(s) and update.', {
                    created_users: responseData.created_users,
                    updated_users: responseData.updated_users,
                  })}{' '}
                </span>
              </div>
            ) : (
              <div className="upload-users-modal__body_loading">
                <ProgressComponent diameter={40} progress={uploadStatus} />
                <div className="upload-users-modal__body_loading_container">
                  <span>
                    {formatMessage(
                      'We are analyzing your file. It may take a little time',
                    )}
                  </span>
                  {/*<span onClick={() => setStopUploading(true)}>*/}
                  {/*  <font color={'#E75E4B'}>{formatMessage('Stop Uploading')}</font>*/}
                  {/*</span>*/}
                </div>
              </div>
            )}
            {uploadStatus === null && (
              <div className="upload-users-modal__body_button">
                <AudienceButton
                  width={420}
                  height={56}
                  color="blue"
                  onClick={handleSubmit}
                  disabled={error || !file}
                >
                  {sendFileLoader ? (
                    <div className="upload-users-modal__body_loading_loader" />
                  ) : (
                    <span>{formatMessage('Import contacts')}</span>
                  )}
                </AudienceButton>
              </div>
            )}
          </div>
        ) : (
          <div className="upload-users-modal__body_info">
            <span>
              {formatMessage(
                'Your Excel spreadsheet must have the following rules in order for you to successfully import contacts:',
              )}{' '}
              {/* <font color="#1187F4">{formatMessage('phone')}</font>,{' '}
              <font color="#1187F4">{formatMessage('surname')}</font>,{' '}
              <font color="#1187F4">{formatMessage('first name')}</font>,{' '}
              <font color="#1187F4">{formatMessage('tags')}</font>. */}
            </span>
            <br />
            <span>
              <b>
                1.
                {formatMessage('Important about worksheet columns')}:
              </b>
            </span>
            <ul>
              <li>{formatMessage('Your Excel spreadsheet must have only one sheet')}.</li>
              <li>
                {formatMessage(
                  "This sheet must have the text 'telefone' in cell A1, 'nome' in cell B1, and 'etiquetas' in cell C1. These will be the headers of your spreadsheet",
                )}
                .
              </li>
            </ul>
            <span>
              <b>
                2.
                {formatMessage('Important about the phone column')}:
              </b>
            </span>
            <ul>
              <li>
                {formatMessage(
                  'All phone numbers must include the country code (DDI) before the area code (DDD). The DDI for Brazil is 55. (Example: 5511988776655)',
                )}
                .
              </li>
              <li>
                {formatMessage(
                  'You don`t need to format the phone numbers by removing dots, spaces, commas, dashes, or any other special characters. We will do this automatically',
                )}
                .
              </li>
              <li>
                {formatMessage(
                  'You don`t need to worry about the ninth digit rule in the phone number. We will take care of that automatically',
                )}
                .
              </li>
            </ul>
            <span>
              <b>
                3.
                {formatMessage('Important about the labels column')}:
              </b>
            </span>
            <ul>
              <li>
                {formatMessage(
                  'The use of labels is optional. You can import numbers and names only',
                )}
                .
              </li>
              <li>{formatMessage('Labels should not exceed 20 characters')}.</li>
              <li>
                {formatMessage(
                  'To add more than 1 label for clients, separate the labels with commas (e.g., customer, purchased, productX)',
                )}
                .
              </li>
              <li>
                {formatMessage(
                  "The column title must be 'etiquetas,' it cannot be 'etiqueta'",
                )}
                .
              </li>
            </ul>

            <span>
              <b>
                4.
                {formatMessage('Summary')}:
              </b>
            </span>
            <ul>
              <li>
                {formatMessage(
                  "Column names should be 'telefone,' 'nome,' and 'etiquetas'",
                )}
                .
              </li>
              <li>
                {formatMessage(
                  'Phones must have the DDI before the DDD (e.g., 5511999999999)',
                )}
                .
              </li>
              <li>
                {formatMessage('Labels should be separated by commas and are optional')}.
              </li>
              <li>{formatMessage('Review everything before importing')}.</li>
            </ul>
            <div className="upload-users-modal__body_bottom">
              <span>
                <a
                  target="_blank"
                  href="https://docs.google.com/spreadsheets/d/11EB-_XIbZ9dO1QQcnR5qis26NMKxQYbrXZYpsmIYaJs/edit?usp=sharing"
                  rel="noreferrer"
                >
                  <u>
                    <font color="#1187F4">{formatMessage('Access the Template')}</font>{' '}
                  </u>
                </a>
              </span>
              <span>
                <font color="#A4A8B8">
                  {formatMessage(
                    'and simply edit it with your information. Remember to delete the instructional texts and the image',
                  )}
                  .
                </font>
              </span>
            </div>
          </div>
        )}
      </div>
    </ModalContainer>
  );
};
