import React, { useState } from 'react';

import { MODALS } from '@components/containers/Modal/modalConstants';
import { ModalContainer } from '@components/containers/Modal/ModalContainer';
import { useDispatch, useSelector } from 'react-redux';
import { modalActions } from '@components/containers/Modal/modalActions';
import { PasswordField } from '@components/common/fields/PasswordField/PasswordField';
import { Button } from '@components/common';
import { useInternationalization } from '@hooks/useTranslationHook';
import { companiesApi } from '@api/companiesApi';
import { getUserIdSelector } from '@redux/auth/authSelectors';

import './passwordUpdateCheckModal.scss';
import { authActions } from '@redux/auth/authActions';
import { stopChatSocketReconnect } from '@helpers/stopChatSocketReconnect';
import { notificationsActions } from '@redux/notifications/notificationsActions';
import { getChatSelector } from '@redux/liveChat/chatSelector';
import { getNotificationsSelector } from '@redux/notifications/notificationsSelector';
import useMediaQuery from '@mui/material/useMediaQuery';

const passwordReg = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})/;

export const PasswordUpdateCheckModal = () => {
  const dispatch = useDispatch();
  const { formatMessage } = useInternationalization();

  const userId = useSelector(getUserIdSelector);
  const { chatSocket } = useSelector(getChatSelector);
  const { notificationsSocket } = useSelector(getNotificationsSelector);
  const isSmallScreen = useMediaQuery('(max-width:1000px)');

  const [passwordData, setPasswordData] = useState({
    old_password: '',
    new_password: '',
    new_password_confirm: '',
  });

  const [fieldType, setFieldType] = useState({
    old_password: 'password',
    new_password: 'password',
    new_password_confirm: 'password',
  });

  const [error, setError] = useState('');

  const changeFieldType = type => {
    setFieldType({
      ...fieldType,
      [type]: fieldType[type] === 'password' ? 'text' : 'password',
    });
  };

  const onClose = () => {
    dispatch(modalActions.closeModal());
  };

  const validation = () => {
    if (passwordData.new_password !== passwordData.new_password_confirm) {
      setError('The passwords are not the same.');
      return false;
    }

    if (
      !passwordReg.test(passwordData.new_password) &&
      !isNaN(passwordData.new_password)
    ) {
      setError(
        'The password must be at least 8 characters long, contain more than just letters or numbers, and have at least one uppercase character',
      );
      return false;
    }

    return true;
  };

  const handleSubmit = () => {
    if (validation()) {
      companiesApi
        .confirmPasswordChange(userId, passwordData)
        .then(res => {
          dispatch(authActions.updateToken(res.data.access));
          localStorage.setItem('authToken', res.data.access);
          localStorage.setItem('refreshToken', res.data.refresh);
          localStorage.setItem('PasswordChangeIsNotRequired', true);
          onClose();
        })
        .catch(err => {
          switch (err.response.data.detail) {
            case 'something_went_wrong':
              setError('Something went wrong');
              break;
            case 'current_password_is_wrong':
              setError(
                'This is not your current password. Enter the password you use to login.',
              );
              break;
            case 'passwords_not_the_same':
              setError('The passwords are not the same.');
              break;
            case 'password_validation_is_failed':
              setError(
                'Your new password must have at least 8 characters and contain at least one uppercase letter, one lowercase letter, one number, and one symbol.',
              );
              break;
            case 'new_password_should_not_be_the_same_as_old_one':
              setError("Your new password can't be the same as the old one.");
              break;
            default:
              setError('Something went wrong');
          }
        });
    } else {
      console.log('error', error);
    }
  };

  const handleLogOut = () => {
    dispatch(authActions.logOut());
    chatSocket && stopChatSocketReconnect(chatSocket, dispatch);
    notificationsSocket && notificationsSocket.close();
    notificationsSocket && dispatch(notificationsActions.closeConnectionSuccess());
  };

  return (
    <ModalContainer
      modal={MODALS.passwordUpdateCheck}
      customStyles={{ width: isSmallScreen ? '300px' : '550px' }}
      hideCross
    >
      <div className="password-update-check">
        <div className="password-update-check__header_log-out" onClick={handleLogOut}>
          {formatMessage('Log out')}
        </div>
        <div className="password-update-check__header">
          <div>{formatMessage('UPDATE YOUR ACCOUNT')}</div>
        </div>
        <div className="password-update-check__container">
          <div className="password-update-check__container_content">
            {formatMessage('Your Account Security is Important!')}{' '}
            {formatMessage(
              'To enhance your protection, we kindly ask you to update your password in response to our new layout. Thank you for prioritizing the safety of your account.',
            )}
          </div>
          <div className="password-update-check__container__field">
            <PasswordField
              id="old-password"
              name="old-password"
              fullWidth
              required
              onChange={e =>
                setPasswordData({ ...passwordData, old_password: e.target.value })
              }
              onClick={() => changeFieldType('old_password')}
              value={passwordData.old_password}
              type={fieldType.old_password}
              placeholder={formatMessage('Current Password')}
            />
          </div>
          <div className="password-update-check__container__field">
            <PasswordField
              id="new-password"
              name="new-password"
              fullWidth
              required
              onChange={e =>
                setPasswordData({ ...passwordData, new_password: e.target.value })
              }
              onClick={() => changeFieldType('new_password')}
              value={passwordData.new_password}
              type={fieldType.new_password}
              placeholder={formatMessage('Set a new password here')}
            />
          </div>
          <div className="password-update-check__container__field">
            <PasswordField
              id="confirm-new-password"
              name="confirm-new-password"
              fullWidth
              required
              onChange={e =>
                setPasswordData({ ...passwordData, new_password_confirm: e.target.value })
              }
              onClick={() => changeFieldType('new_password_confirm')}
              value={passwordData.new_password_confirm}
              type={fieldType.new_password_confirm}
              placeholder={formatMessage('Repeat new password here')}
            />
          </div>
          {error && (
            <div className="password-update-check__container_error">
              {formatMessage(error)}
            </div>
          )}
          <div className="password-update-check__button">
            <Button
              onClick={handleSubmit}
              width="100%"
              color="green"
              disabled={
                !(
                  Boolean(passwordData.old_password) &&
                  Boolean(passwordData.new_password) &&
                  Boolean(passwordData.new_password_confirm)
                )
              }
            >
              <span className="password-update-check__button__container__text">
                {formatMessage('Confirm')}
              </span>
            </Button>
          </div>
        </div>
      </div>
    </ModalContainer>
  );
};
