import { useSelector } from 'react-redux';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import React, { useEffect, useState } from 'react';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import { green } from '@mui/material/colors';
import moment from 'moment';
import NumericInput from 'react-numeric-input';
import { styled } from '@mui/system';

import { useInternationalization } from '@hooks/useTranslationHook';
import { getModalDataSelector } from '../../containers/Modal/modalSelectors';
import { ModalContainer } from '../../containers/Modal/ModalContainer';
import { MODALS } from '../../containers/Modal/modalConstants';
import { Button } from '../../common/Button';

import './seqSubModal.scss';

const GreenRadio = styled(Radio)(() => ({
  color: green[400],
  '&.Mui-checked': {
    color: green[600],
  },
}));

export const SeqSubModal = () => {
  const { modalData } = useSelector(getModalDataSelector);

  const [timeOption, setTimeOption] = useState('delay');

  const [startTime, setStartTime] = useState(moment().set('hour', moment().hour() + 1));
  const [endTime, setEndTime] = useState(moment().set('hour', moment().hour() + 2));

  const [error, setError] = useState('');
  const [delayDay, setDelayDay] = useState(0);
  const [delayHour, setDelayHour] = useState(0);
  const [delayMin, setDelayMin] = useState(0);

  const { formatMessage } = useInternationalization();

  const checkDay = time => {
    const arr = time.split(' ');
    return arr.length > 1 ? arr[0] : 0;
  };

  const splitTime = time => {
    let arr = time.split(' ');
    arr = arr.length > 1 ? arr[1] : arr[0];
    arr = arr.split(':');
    return arr.length > 1 ? arr : [0, 0];
  };

  useEffect(() => {
    const { seqSub } = modalData;

    if (seqSub.is_run_immediately === true && seqSub.any_time === false) {
      setTimeOption('is_run');
    } else if (seqSub.is_run_immediately === false && seqSub.any_time === true) {
      setTimeOption('any_time');
      const startTimeSplit = seqSub.start_time.split(':');
      const endTimeSplit = seqSub.end_time.split(':');
      setStartTime(
        moment().hour(Number(startTimeSplit[0])).minute(Number(startTimeSplit[1])),
      );
      setEndTime(moment().hour(Number(endTimeSplit[0])).minute(Number(endTimeSplit[1])));
    } else {
      setTimeOption('delay');
    }

    setDelayDay(checkDay(modalData.seqSub.time ? modalData.seqSub.time : '0'));
    setDelayHour(splitTime(modalData.seqSub.time ? modalData.seqSub.time : '0')[0]);
    setDelayMin(splitTime(modalData.seqSub.time ? modalData.seqSub.time : '0')[1]);
  }, []);

  const handleSubmit = event => {
    const { seqSub } = modalData;
    event.preventDefault();
    let seconds;

    switch (timeOption) {
      case 'delay':
        seconds = delayDay * 24 * 60 * 60 + delayHour * 60 * 60 + delayMin * 60;

        if (seconds > 600) {
          seqSub.is_run_immediately = false;
          seqSub.any_time = false;
          seqSub.time = seconds;
        }

        break;
      case 'is_run':
        seqSub.is_run_immediately = true;
        seqSub.any_time = false;
        seqSub.time = '0';
        break;
      case 'any_time':
        seqSub.is_run_immediately = false;
        seqSub.any_time = true;
        seqSub.time = '0';
        seqSub.start_time = startTime.format('HH:mm');
        seqSub.end_time = endTime.format('HH:mm');
    }

    if (timeOption === 'delay' && seconds < 600) {
      setError(formatMessage('Delay should be minimum 10 min'));
    } else {
      modalData.handleChangeTimeOption(modalData.seqSub.id, modalData.seqSub);
      modalData.onClose();
    }
  };

  const handleChange = event => {
    setTimeOption(event.target.value);
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <ModalContainer modal={MODALS.seqSub} onClose={modalData.onClose}>
        <div className="campaigns-modal">
          <div className="campaigns-modal__header">{formatMessage('Update')}</div>
          <div className="campaigns-modal__form-group">
            <FormControl component="fieldset">
              <FormLabel component="legend">{formatMessage('Time option')}</FormLabel>
              <RadioGroup
                aria-label="time"
                name="time"
                value={timeOption}
                onChange={handleChange}
              >
                <FormControlLabel
                  value="delay"
                  control={<GreenRadio />}
                  label={formatMessage('Delay (Minimum: 10min)')}
                />
                <FormControlLabel
                  value="any_time"
                  control={<GreenRadio />}
                  label={formatMessage('Send between')}
                />
                <FormControlLabel
                  value="is_run"
                  control={<GreenRadio />}
                  label={formatMessage('Is Run Immediately')}
                />
              </RadioGroup>
            </FormControl>
          </div>
          <div className="campaigns-modal__form-group">
            {timeOption === 'delay' && (
              <div className="time-block">
                <div className="number-block">
                  <span>{formatMessage('Days')}</span>
                  <NumericInput
                    min={0}
                    className="form-number"
                    max={7}
                    mobile={false}
                    defaultValue={delayDay}
                    value={delayDay}
                    onChange={valueAsNumber =>
                      setDelayDay(valueAsNumber > 7 ? 7 : valueAsNumber)
                    }
                  />
                </div>
                <div className="number-block">
                  <span>{formatMessage('Hours')}</span>
                  <NumericInput
                    max={23}
                    min={0}
                    mobile={false}
                    defaultValue={delayHour}
                    value={delayHour}
                    onChange={valueAsNumber =>
                      setDelayHour(valueAsNumber > 23 ? 23 : valueAsNumber)
                    }
                    className="form-number"
                  />
                </div>
                <div className="number-block">
                  <div>{formatMessage('Minutes')}</div>
                  <NumericInput
                    max={59}
                    min={0}
                    mobile={false}
                    defaultValue={delayMin}
                    value={delayMin}
                    onChange={valueAsNumber =>
                      setDelayMin(valueAsNumber > 59 ? 59 : valueAsNumber)
                    }
                    className="form-number"
                  />
                </div>
                {error && <span className="email_modal__text-input__err">{error}</span>}
              </div>
            )}
            {timeOption === 'any_time' && (
              <span className="time-block">
                <TimePicker
                  inputVariant="outlined"
                  showTodayButton
                  todayLabel="now"
                  label={formatMessage('start')}
                  minutesStep={1}
                  value={startTime}
                  onChange={value => setStartTime(value)}
                />
                <TimePicker
                  inputVariant="outlined"
                  showTodayButton
                  todayLabel="now"
                  label={formatMessage('end')}
                  minutesStep={1}
                  value={endTime}
                  onChange={value => setEndTime(value)}
                />
              </span>
            )}
          </div>
          <div className="margin-block">
            <Button onClick={handleSubmit} color="green" width={180}>
              {formatMessage('Set time option')}
            </Button>
          </div>
        </div>
      </ModalContainer>
    </LocalizationProvider>
  );
};
