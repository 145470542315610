import api from './baseApi';
import { flowsApiRPC, botApiRPC } from '@connectApi/baseApi';
import {
  BotIdRequest,
  FlowListRequest,
  FlowIdRequest,
  FlowCreateRequest,
  FlowPluralMoveRequest,
  FlowDetailPatchRequest,
  FlowBulkDeleteRequest,
} from 'wabb-backend-api/build/es/flows/flows_pb';
import { BotRequest, UpdateBotRequest } from 'wabb-backend-api/build/es/bot/bot_pb';

export const flowsApi = {
  getFlows: (botId, folderId, searchName, page) => {
    return flowsApiRPC.flowList(
      FlowListRequest.fromJson({
        bot_id: botId,
        folder: folderId ? folderId : 0,
        page,
        search_name: searchName ? searchName : '',
        without_folders: false,
      }),
    );
  },

  getFlowsShort: botId =>
    flowsApiRPC.flowShortList(BotIdRequest.fromJson({ bot_id: botId })),

  getFlowsSources: (botId, folderId, searchName) => {
    let url = `/flows/bot/${botId}/`;

    if (folderId) {
      url += `${folderId}/?`;
    } else {
      url += '?without_folders=true&';
    }

    if (searchName) {
      url += `search_name=${searchName}`;
    }

    return api.get(url);
  },

  getNextFlowsSources: (page, botId, folderId) => {
    if (folderId === '') {
      return api.get(`/flows/bot/${botId}?without_folders=true&page=${page}`);
    }

    return api.get(`/flows/folder/${folderId}?page=${page}`);
  },

  createFlowsSource: data => {
    const { name, folderId, companyBot } = data;
    const folder = folderId || null;
    return flowsApiRPC.flowCreate(
      FlowCreateRequest.fromJson({ bot: companyBot, folder, name }),
    );
  },

  getActiveFlowsSettings: bot_id => {
    return botApiRPC.getBot(BotRequest.fromJson({ pk: bot_id }));
  },

  updateFlowsSettings: updateFlowObj => {
    return botApiRPC.updateBot(UpdateBotRequest.fromJson(updateFlowObj));
  },

  renameFlowsSource: (flowId, name) => flowsApiRPC.flowDetailPatch({ id: flowId, name }),

  updateFlowsSource: data => {
    const { flowId, cloneAllow, shareEnabled, isFlowProtected, allowedCompanies } = data;

    const allowedCompaniesArray = allowedCompanies.split(',').map(Number);
    return flowsApiRPC.flowDetailPatch(
      FlowDetailPatchRequest.fromJson({
        id: flowId,
        is_cloning_available: Boolean(cloneAllow),
        is_share_enabled: Boolean(shareEnabled),
        is_flow_protected: Boolean(isFlowProtected),
        allowed_companies_for_cloning: allowedCompaniesArray,
      }),
    );
  },

  moveFlowSource: data => {
    const { flowId, folder } = data;
    return flowsApiRPC.flowDetailPatch({
      id: flowId,
      folder,
    });
  },

  pluralMoveFlows: (
    botId,
    currentFolder,
    newFolderId,
    selected_or_unselected_flows,
    selectedAll,
    searchName,
  ) =>
    flowsApiRPC.flowPluralMove(
      FlowPluralMoveRequest.fromJson({
        bot_id: botId,
        current_folder_id: currentFolder,
        new_folder_id: newFolderId,
        selected_all: selectedAll,
        selected_or_unselected_flows,
        // search_name: searchName,
      }),
    ),

  duplicateFlowsSource: flowId => flowsApiRPC.flowDuplicate({ flow: flowId }),

  deleteFlowsSource: flow_id => {
    flowsApiRPC.flowDelete(FlowIdRequest.fromJson({ flow_id }));
  },

  bulkActionDelete: (
    botId,
    selectedOrUnselectedItemIds,
    selectedAllMode,
    folderId,
    searchName,
  ) =>
    flowsApiRPC.flowBulkDelete(
      FlowBulkDeleteRequest.fromJson({
        bot_id: botId,
        folder_id: folderId,
        search_name: searchName,
        selected_all: selectedAllMode,
        selected_or_unselected_items: selectedOrUnselectedItemIds,
      }),
    ),
};
