import React from 'react';
import { styled } from '@mui/system';
import TextField from '@mui/material/TextField';
import PropTypes from 'prop-types';

const BcTextFieldRoot = styled('div')(props => ({
  '& label': {
    fontWeight: 'normal',
    fontSize: '15px',
  },
  '& label.Mui-focused': {
    color: props.color,
  },
  '& input': {
    width: '100%',
    backgroundColor: 'white',
    borderRadius: '12px',
    padding: props.size,
  },
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: '#D9DFE3',
      borderRadius: '12px',
    },
    '&.Mui-focused fieldset': {
      border: `1px solid ${props.color}`,
    },
  },
}));

export const BcTextField = ({ color = 'purple', size = 'medium', ...otherProps }) => {
  const setColor = () => {
    switch (color) {
      case 'purple':
        return 'rgba(128, 97, 255, 1)';
      case 'green':
        return 'rgba(0, 198, 75, 1)';
      case 'blue':
        return 'rgba(17,135,244, 1 )';
    }
  };

  const setSize = () => {
    switch (size) {
      case 'small':
        return '8px 12px';
      case 'medium':
        return '13px 15px';
      case 'large':
        return '15px 18px';
    }
  };

  const props = { color: setColor(), size: setSize() };

  return (
    <BcTextFieldRoot {...props}>
      <TextField variant="outlined" {...otherProps} />
    </BcTextFieldRoot>
  );
};

BcTextField.propTypes = {
  color: PropTypes.oneOf(['green', 'purple', 'blue']),
  size: PropTypes.oneOf(['medium', 'small', 'large']),
};
