import { all, takeLatest, put } from 'redux-saga/effects';
import { bcSplitTableActions } from './bcSplitTableActions';
import {
  GET_ITEMS_REQUEST,
  GET_ITEMS_NEXT_REQUEST,
  CREATE_NEW_ITEM_REQUEST,
  DELETE_ITEM_REQUEST,
} from './bcSplitTableActionTypes';

function* getItemsSaga(action) {
  try {
    const {
      botId, searchName, getItemsApi, page,
    } = action.payload;

    const response = yield getItemsApi({ botId, searchName, page });

    if (response) {
      if (Array.isArray(response)) {
        yield put(bcSplitTableActions.getItemsSuccess({ results: response }));
      } else {
        yield put(bcSplitTableActions.getItemsSuccess(response));
      }
    }
  } catch (err) {
    console.log('err', err);
  }
}

function* getItemsNextSaga(action) {
  try {
    const {
      botId, searchName, getItemsApi, page,
    } = action.payload;
    const response = yield getItemsApi({ botId, searchName, page });

    if (response) {
      yield put(bcSplitTableActions.getItemsNextSuccess(response));
    }
  } catch (err) {
    console.log('err', err);
  }
}

function* createNewItemSaga(action) {
  try {
    const { createItemApi, data } = action.payload;
    const response = yield createItemApi(data);

    if (response) {
      yield put(bcSplitTableActions.createNewItemSuccess(response));
    }
  } catch (err) {
    console.log('createNewItemSaga err', err);
  }
}

function* deleteItemSaga(action) {
  const { deleteItemApi, id, deleteItemCatchFunction } = action.payload;

  try {
    const response = yield deleteItemApi(id);

    if (response) {
      yield put(bcSplitTableActions.deleteItemSuccess(id));
    }
  } catch (err) {
    console.log('deleteItemSaga err', err);

    if (deleteItemCatchFunction) {
      deleteItemCatchFunction(err);
    }
  }
}

function* sagas() {
  yield all([
    takeLatest(GET_ITEMS_REQUEST, getItemsSaga),
    takeLatest(GET_ITEMS_NEXT_REQUEST, getItemsNextSaga),
    takeLatest(CREATE_NEW_ITEM_REQUEST, createNewItemSaga),
    takeLatest(DELETE_ITEM_REQUEST, deleteItemSaga),
  ]);
}

export const bcSplitTableSagas = sagas;
