import { dashboardActionTypes as types } from './actionTypes';

const initialState = {
  info: [],
  stat: {},
};

export const dashboardReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.SET_DASH_STAT:
      return {
        ...state,
        stat: action.payload.data.stat,
        info: action.payload.data.info,
      };
    default:
      return state;
  }
};
