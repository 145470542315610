import api from './baseApi';
import {
  blocksApiRPC,
  actionBlockApiRPC,
  randomizerBlocksApiRPC,
} from '@connectApi/baseApi';
import {
  TagActionSubBlockCreateRequest,
  SequenceActionSubBlockRequest,
  CaseActionSubBlockCreateRequest,
  ConversationActionSubBlockRequest,
  NotifyAdminActionSubBlockCreateRequest,
  VariableActionSubBlockCreateRequest,
  ReferralActionSubBlockCreateRequest,
  PauseAutomationSubBlockCreateRequest,
  TagActionSubBlockDetailRequest,
  NotifyAdminActionSubBlockDetailRequest,
  VariableActionSubBlockDetailRequest,
  ReferralActionSubBlockRequest,
  PauseAutomationSubBlockDetailRequest,
  ActionBlocksById,
  // VariableSetActionSubBlock,
} from 'wabb-backend-api/build/es/action_blocks/action_blocks_pb';
import { VariableSetActionSubBlock } from 'wabb-backend-api/build/es/flows/flows_pb';
import { BlocksByFlowId } from 'wabb-backend-api/build/es/blocks/blocks_pb';
import { RandomizerSubBlockDetailPatchRequest } from 'wabb-backend-api/build/es/randomizer_blocks/randomizer_blocks_pb';
import {
  ModelsByType,
  nodesTypes,
} from '../pages/constructor/constructorWidget/helpers/constants';

export const constructorApi = {
  getFlowDetails: flowId => api.get(`/blocks/flow/${flowId}/`),

  // Updated GRPC code
  // getFlowDetails: flowId => blocksApiRPC.blockList(BlocksByFlowId.fromJson({ flow_id: flowId })),

  updateBlockCoordinates: (id, x, y) =>
    blocksApiRPC.blockDetailPatch({
      id,
      name: '',
      x,
      y,
    }),
  updateRandomizerSubBlockPercentage: ({ id, percentage }) =>
    randomizerBlocksApiRPC.randomizerSubBlockDetailPatch(
      RandomizerSubBlockDetailPatchRequest.fromJson({ pk: id, value: percentage }),
    ),
  getShareFlowDetails: shareFlow => api.get(`/flows/share_flow/${shareFlow}/`),
  saveSharedFlowToSelectedCompany: (shareFlow, toCompany) =>
    api.post(`/flows/share_flow/${shareFlow}/`, {
      company_copy_to: toCompany,
    }),
  setUserInputVariable: (data, variableId) => {
    const { subBlockConentTypeId } = data;
    return api.patch(`/blocks_content/user_input/${subBlockConentTypeId}/`, {
      variable_type: variableId,
    });
  },
  setTagVariable: (data, tagId) => {
    const { contentId } = data;
    return api.patch(`/blocks_action/tag/${contentId}/`, {
      tag_type: tagId,
    });
  },
  updateUserInput: (data, id) =>
    api.patch(`/blocks_content/user_input/${id}/`, {
      ...data,
    }),

  chooseItem: (url, action_block, type) => {
    if (url === 'tag') {
      return actionBlockApiRPC.tagActionSubBlockCreate(
        TagActionSubBlockCreateRequest.fromJson({ type, action_block }),
      );
    } else if (url === 'sequence') {
      return actionBlockApiRPC.sequenceActionSubBlockCreate(
        SequenceActionSubBlockRequest.fromJson({ type, action_block }),
      );
    } else if (url === 'open_case') {
      return actionBlockApiRPC.openCaseActionSubBlockCreate(
        CaseActionSubBlockCreateRequest.fromJson({ type, action_block }),
      );
    } else if (url === 'conversation') {
      return actionBlockApiRPC.conversationActionSubBlockCreate(
        ConversationActionSubBlockRequest.fromJson({ type, action_block }),
      );
    } else if (url === 'notify_admin') {
      return actionBlockApiRPC.notifyAdminActionSubBlockCreate(
        NotifyAdminActionSubBlockCreateRequest.fromJson({ type, action_block }),
      );
    } else if (url === 'close_case') {
      return actionBlockApiRPC.closeCaseActionSubBlockCreate(
        CaseActionSubBlockCreateRequest.fromJson({ type, action_block }),
      );
    } else if (url === 'variable') {
      return actionBlockApiRPC.variableActionSubBlockCreate(
        VariableActionSubBlockCreateRequest.fromJson({ type, action_block }),
      );
    } else if (url === 'referral') {
      return actionBlockApiRPC.referralActionSubBlockCreate(
        ReferralActionSubBlockCreateRequest.fromJson({ type, action_block }),
      );
    } else if (url === 'pause_automation') {
      return actionBlockApiRPC.pauseAutomationSubBlockCreate(
        PauseAutomationSubBlockCreateRequest.fromJson({ type, action_block }),
      );
    } else if (url === 'resume_automation') {
      return actionBlockApiRPC.resumeAutomationActionSubBlockCreate(
        CaseActionSubBlockCreateRequest.fromJson({ type, action_block }),
      );
    } else if (url === 'set_variable') {
      return actionBlockApiRPC.setCustomFieldActionSubBlock(
        VariableSetActionSubBlock.fromJson({ type, action_block }),
      );
    }
  },

  deleteItemInPopover: pk => {
    return actionBlockApiRPC.actionSubBlockDelete(ActionBlocksById.fromJson({ pk }));
  },

  chooseItemInPopover: (data, type) => {
    if (type === 'tag') {
      return actionBlockApiRPC.tagActionSubBlockDetail(
        TagActionSubBlockDetailRequest.fromJson(data),
      );
    } else if (type === 'sequence') {
      return actionBlockApiRPC.sequenceActionSubBlockDetail(
        SequenceActionSubBlockRequest.fromJson(data),
      );
    } else if (type === 'conversation') {
      return actionBlockApiRPC.conversationActionSubBlockDetail(
        ConversationActionSubBlockRequest.fromJson(data),
      );
    } else if (type === 'notify_admin') {
      return actionBlockApiRPC.notifyAdminActionSubBlockDetail(
        NotifyAdminActionSubBlockDetailRequest.fromJson(data),
      );
    } else if (type === 'variable') {
      return actionBlockApiRPC.variableActionSubBlockDetail(
        VariableActionSubBlockDetailRequest.fromJson(data),
      );
    } else if (type === 'referral') {
      return actionBlockApiRPC.referralActionSubBlockDetail(
        ReferralActionSubBlockRequest.fromJson(data),
      );
    } else if (type === 'pause_automation') {
      return actionBlockApiRPC.pauseAutomationSubBlockDetail(
        PauseAutomationSubBlockDetailRequest.fromJson(data),
      );
    } else if (type === 'set_variable') {
      return actionBlockApiRPC.setCustomFieldActionSubBlockDetail(
        VariableSetActionSubBlock.fromJson(data),
      );
    }
  },
  updateUserInput: (data, id) =>
    api.patch(`/blocks_content/user_input/${id}/`, {
      ...data,
    }),
};
