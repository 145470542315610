import { forwardRef, useState } from "react";
import './CodeInput.scss';

const CodeInput = forwardRef((props, ref) => {
  const { value, onKeyDown, index, onChange } = props;
  const [isFocused, setIsFocused] = useState(false);

  const handleChange = (e) => {
    const pattern = /^\d$/;
    const newValue = e.target.value;
    if (newValue.length !== 0 && !pattern.test(newValue)) {
      return;
    }
    onChange(newValue, index);
  };

  const handleKeyDown = (e) => {
    onKeyDown(e, index);
  };

  return (
    <div className="code-input-wrapper">
      <input
        ref={ref}
        value={value}
        className="code-input"
        type="text"
        inputMode="numeric"
        placeholder="0"
        onChange={handleChange}
        onKeyDown={handleKeyDown}
        maxLength={1}
        onFocus={() => setIsFocused(true)}
        onBlur={() => setIsFocused(false)}
      />
      {isFocused && <span className="caret"/>}
    </div>
  );
});

export default CodeInput;
