import { useDispatch, useSelector } from 'react-redux';
import React, { useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';

import { useInternationalization } from '@hooks/useTranslationHook';
import { usersApi } from '@api/usersApi';
import { LiveChatAccess } from '@components/modals/ManagerModal/LiveChatAccess';
import { bcSplitTableActions } from '@redux/bcSplitTable/bcSplitTableActions';
import { Code } from '@connectrpc/connect';
import { getModalDataSelector } from '../../containers/Modal/modalSelectors';
import { ModalContainer } from '../../containers/Modal/ModalContainer';
import { MODALS } from '../../containers/Modal/modalConstants';
import FormField from '../../../wabb-ui-kit/FormField/FormField';
import { EComponentType } from '../../../wabb-ui-kit/FormField/types/types';
import { EButtonSize } from '../../../wabb-ui-kit/Button/types/types';
import Button from '../../../wabb-ui-kit/Button/Button';
import CheckBox from '../../../wabb-ui-kit/CheckBox/CheckBox';
import { columns } from '../../../pages/settings/components/managersSettings/ManagersSettings';
import Switcher from '../../../wabb-ui-kit/Switcher/Switcher';

import './ManagerModal.scss';

const validationSchema = Yup.object().shape({
  email: Yup.string().email('Incorrect E-mail'),
});

export const ManagerModal = () => {
  const { modalData } = useSelector(getModalDataSelector);
  const { formatMessage } = useInternationalization();
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  const { companyId, onClose } = modalData;

  const checkAssignPermissions = (assignHimself, assignOther) => {
    if (assignHimself && assignOther) {
      return 0;
    }

    if (assignHimself) {
      return 1;
    }

    return 2;
  };

  const form = useFormik({
    initialValues: {
      email: '',
      dashboard: false,
      audience: false,
      campaigns: false,
      broadcasts: false,
      automation: false,
      flows: false,
      settings: false,
      adding_new_managers: false,
      live_chat: false,
      assignHimself: false,
      assignOther: false,
      live_chat_all: false,
      live_chat_my_busy: false,
      live_chat_all_busy: false,
    },
    validationSchema,
    validateOnChange: false,
    onSubmit: async (values) => {
      setLoading(true);
      usersApi
        .updateActiveUsers({
          adding_new_managers: values.adding_new_managers,
          assign_chat: checkAssignPermissions(values.assignHimself, values.assignOther),
          audience: values.audience,
          automation: values.automation,
          broadcasts: values.broadcasts,
          campaigns: values.campaigns,
          dashboard: values.dashboard,
          email: values.email,
          flows: values.flows,
          live_chat: values.live_chat,
          live_chat_all: values.live_chat_all,
          live_chat_all_busy: values.live_chat_all_busy,
          live_chat_my_busy: values.live_chat_my_busy,
          pk: companyId,
          settings: values.settings,
        })
        .then((res) => {
          dispatch(bcSplitTableActions.createNewItemSuccess(res));
          modalData.onClose();
        })
        .catch((err) => {
          switch (err.code) {
            case Code.NotFound:
              form.setFieldError(
                'email',
                `${formatMessage('User not registered in Wabb')}`,
              );
              break;
            default:
              form.setFieldError(
                'email',
                err.response.data ? formatMessage(err.response.data.error) : 'Error',
              );
              break;
          }
        })
        .finally(() => setLoading(false));
    },
  });

  return (
    <ModalContainer modal={MODALS.manager} onClose={onClose}>
      <div className="manager-modal">
        <div className="manager-modal__content">
          <span className="manager-modal__content__title">
            Add team member
          </span>
          <div className="manager-modal__content__container">
            <div>
              <FormField
                Component={EComponentType.Input}
                isRequired
                label="Invitation email"
                placeholder="Type here..."
                value={form.values.email}
                onChange={(e) => form.setFieldValue('email', e.target.value)}
                onCrossClick={() => form.setFieldValue('email', '')}
                errorText={form.errors?.email && formatMessage(form.errors?.email)}
              />
            </div>
            <div className="manager-modal__content__container__settings">
              <span className="manager-modal__content__container__settings__title">Access settings</span>
              <div className="manager-modal__content__container__settings__content">
                {
                  [...columns.slice(1)].map(({ name, field }, index) => (
                    <>
                      <div
                        key={`${index + Math.random() * 10000}`}
                        className="manager-modal__content__container__settings__content__item"
                      >
                        <CheckBox
                          isChecked={form.values[field]}
                          onChange={() => form.setFieldValue(field, !form.values[field])}
                          type="checkbox"
                        />
                        <span>{ name }</span>
                      </div>
                      {
                        form.values.settings && field === 'settings'
                        && (
                        <div className="manager-modal__content__container__settings__content__hidden-item">
                          <span>Can invite team members</span>
                          <Switcher
                            isChecked={form.values.adding_new_managers}
                            onChange={() => form.setFieldValue('adding_new_managers', !form.values.adding_new_managers)}
                          />
                        </div>
                        )
                      }
                      {form.values.live_chat && field === 'live_chat' && <LiveChatAccess form={form} />}
                    </>
                  ))
                }
              </div>
            </div>
          </div>
        </div>
        <div className="manager-modal__buttons">
          <Button
            type="primary"
            size={EButtonSize.L}
            isDisabled={!form.values.email}
            isLoading={loading}
            onClick={form.handleSubmit}
          >
            Send invite
          </Button>
          <Button
            type="secondary"
            style="tint"
            size={EButtonSize.L}
            onClick={onClose}
          >
            Cancel
          </Button>
        </div>
      </div>
    </ModalContainer>
  );
};
