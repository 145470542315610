import {
  GET_COMPANIES_REQUEST,
  GET_COMPANIES_SUCCESS,
  SET_SELECTED_COMPANY,
  SET_UPDATED_COMPANY,
  UNLINK_SERVER,
  RELOAD_COMPANY,
  UPDATE_SELECTED_COMPANY,
} from './companiesConstants';

const initialState = {
  companies: [],
  selectedCompany: {},
  reloadPage: true,
  companyPlans: {
    COMPANY_PLAN_TYPE_STARTER: 0,
    COMPANY_PLAN_TYPE_PRO: 1,
    COMPANY_PLAN_TYPE_FRANCHISE_TRIAL: 2,
  },
};

export const companiesReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_COMPANIES_REQUEST:
      return {
        ...state,
      };
    case GET_COMPANIES_SUCCESS:
      const { data } = action.payload;
      return {
        ...state,
        companies: Array.isArray(data) ? [...data] : [data],
        changeCompany: false,
      };
    case SET_SELECTED_COMPANY:
      const { company, reload } = action.payload;
      return {
        ...state,
        selectedCompany: company,
        reloadPage: reload,
      };
    case SET_UPDATED_COMPANY:
      const { updatedCompany } = action.payload;
      const modifCompanies = state.companies.map(element => {
        if (element.id === updatedCompany.id) {
          return updatedCompany;
        }

        return element;
      });
      return {
        ...state,
        companies: [...modifCompanies],
        selectedCompany: updatedCompany,
      };

    case UNLINK_SERVER:
      const { selectedCompanyId } = action.payload;
      const currentCompany = state.selectedCompany;
      currentCompany.serverNumber = null;
      currentCompany.serverUrl = null;
      const modifyCompanies = state.companies.map(element => {
        if (element.id === selectedCompanyId) {
          element.serverNumber = null;
          element.serverUrl = null;
        }

        return element;
      });
      return {
        ...state,
        companies: [...modifyCompanies],
        selectedCompany: currentCompany,
      };
    case UPDATE_SELECTED_COMPANY:
      return {
        ...state,
        companies: state.companies.map(company =>
          company.id === action.payload.company.id ? action.payload.company : company,
        ),
        selectedCompany: action.payload.company,
      };
    case RELOAD_COMPANY:
      return {
        ...state,
        reloadPage: action.payload,
      };
    default:
      return state;
  }
};
