import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useContextSelector } from 'use-context-selector';
import ClipLoader from 'react-spinners/ClipLoader';
import { UserCardInfo } from '@components/common/UserCardComponents/UserCardInfo/UserCardInfo';
import { UserCardEntities } from '@components/common/UserCardComponents/UserCardEntities/UserCardEntities';
import { useInternationalization } from '@hooks/useTranslationHook';
import {
  getIdCompanySelector,
  getPermissionsSelectedCompanySelector,
  getSelectedCompanySelector,
} from '@redux/companies/companiesSelectors';
import { getUserIdSelector } from '@redux/auth/authSelectors';
import { audienceActions } from '@redux/audience/audienceActions';
import { modalActions } from '@components/containers/Modal/modalActions';
import { audienceApi } from '@api/audience';
import { botAdminsApi } from '@api/botAdminsApi';
import { getModalDataSelector } from '../../containers/Modal/modalSelectors';
import { liveChatRooms } from '../../../const/liveChatRooms';
import { ToastContext } from '../../../contexts/toastsContext';
import { MODALS } from '../../containers/Modal/modalConstants';
import { Routes } from '../../../const/routes';
import { ModalContainer } from '../../containers/Modal/ModalContainer';

import './AudienceUserCardModal.scss';

const styles = {
  minWidth: '700px',
};

export const AudienceUserCardModal = () => {
  const { modalData } = useSelector(getModalDataSelector);
  const company = useSelector(getSelectedCompanySelector);
  const companyId = useSelector(getIdCompanySelector);
  const ownerCompanyId = useSelector(getUserIdSelector);
  const history = useHistory();
  const dispatch = useDispatch();
  const { formatMessage } = useInternationalization();
  const permissions = useSelector(getPermissionsSelectedCompanySelector);

  const [loaded, setLoaded] = useState(false);
  const [data, setData] = useState({});
  const [managers, setManagers] = useState([]);


  const addToast = useContextSelector(ToastContext, ({ add }) => add);

  useEffect(() => {
    getUserDetails(modalData.idUser || 0, modalData.jid || null, company.bot || null);
    // до этого так было usersApi.getActiveUsers(company.bot).then((res) => {
    botAdminsApi.getBotAdmins(company.bot, true).then((res) => {
      setManagers(res.updatedUser);
    });
  }, []);

  const updateUserCard = (data) => audienceApi
    .updateUserCard(modalData.idUser, company.bot, data)
    .then((res) => setData(res));

  const getChatRoom = () => {
    const { live_chat_all_busy, live_chat_all, live_chat_my_busy } = permissions;
    const getRoomRouteParam = (field) => liveChatRooms.find(({ fieldName }) => fieldName === field);

    switch (true) {
      case live_chat_all:
        return getRoomRouteParam('live_chat_all')?.routeParam;
      case data?.isBusy === true
        && live_chat_my_busy
        && ownerCompanyId === data?.assignedTo:
        return getRoomRouteParam('live_chat_my_busy')?.routeParam;
      case data?.isBusy === false && live_chat_all_busy:
        return getRoomRouteParam('live_chat_all_busy')?.routeParam;
      case data?.isBusy === true && !live_chat_my_busy:
        addToast({
          type: 'error',
          title: 'Warning',
          message:
            'You only have access to My chats in Live Chat. Assign this chat to you before clicking to go to chat',
        });
        return null;
      case data?.isBusy === false && !live_chat_all_busy:
        addToast({
          type: 'error',
          title: 'Warning',
          message:
            'You only have access to Unassigned chats in Live Chat and this chat is assigned to one agent. Unassign this chat before clicking to go to chat',
        });
        return null;
      default:
        return null;
    }
  };

  const onClickChat = () => {
    modalData.onClose();
    const caseFilter = 'all';
    const phone = data?.jid.split('@')[0];
    const chatRoom = getChatRoom();

    if (chatRoom) {
      const url = `/${companyId}${Routes.LiveChatRoute}/${chatRoom}/${phone}`;
      history.push(url, { caseFilter });
    }
  };

  const getUserDetails = (userId, jId, botId) => {
    audienceApi
      .getUserDetails(userId, jId, botId)
      .then((res) => {
        setData(res);
        setLoaded(true);
      })
      .catch((err) => {
        console.log('err', err);
      });
  };

  const handleDelete = (id) => {
    dispatch(modalActions.closeModal());
    dispatch(
      modalActions.openConfirmModal({
        title: formatMessage('Delete user?'),
        text: formatMessage('Are you sure you want to delete this user?'),
        confirmButtonText: formatMessage('Yes, delete this user'),
        type: 'delete',
        handleConfirm: () => {
          dispatch(audienceActions.deleteUserRequest({ id, botId: company.bot }));
          setData(null);
          dispatch(modalActions.closeModal());
        },
        onClose: () => {
          dispatch(modalActions.closeModal());
        },
      }),
    );
  };

  return (
    <ModalContainer
      modal={MODALS.userDetails}
      onClose={modalData.onClose}
      customStyles={styles}
    >
      <div className="audience-user-card">
        {loaded ? (
          <div className="audience-user-card__content">
            <UserCardInfo
              data={data}
              id={data.id}
              updateUserCard={updateUserCard}
              onClickChat={onClickChat}
              ownerCompanyId={ownerCompanyId}
              managers={managers}
              onDelete={handleDelete}
              isContacts
            />
            <div className="audience-user-card__line" />
            <UserCardEntities
              data={data}
              setData={setData}
              id={data.id}
              fullName={data.fullName}
              ownerCompanyId={ownerCompanyId}
              managers={managers}
              updateUserCard={updateUserCard}
              isContacts
            />
          </div>
        ) : (
          <div className="audience-user-card__loader">
            <ClipLoader size={20} color="blue" />
          </div>
        )}
      </div>
    </ModalContainer>
  );
};
