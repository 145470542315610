import React, { useState } from 'react';

import { MODALS } from '@components/containers/Modal/modalConstants';
import { getModalDataSelector } from '@components/containers/Modal/modalSelectors';
import { ModalContainer } from '@components/containers/Modal/ModalContainer';
import { useSelector } from 'react-redux';
import { useInternationalization } from '@hooks/useTranslationHook';
import { NewBcField } from '@components/common/fields/newBcField/newBcField';
import { PasswordField } from '@components/common/fields/PasswordField/PasswordField';
import { Button } from '@components/common/Button';
import { regExp } from '@components/common/fields/newBcField/newBcFieldHelper';
import { BcSelect } from '@components/common/Select';

import './editCustomEmailModal.scss';
import { ClearingFieldIcon } from '@icons/clearing-field-icon';
import { FranchiseCompanyApi } from '@api/franchiseCompanyApi';

const isObjectWithoutError = object => Object.values(object).every(value => value === '');

const customStyles = () => ({
  control: styles => ({
    ...styles,
    width: '100%',
    height: '46px',
    backgroundColor: '#FFFF',
    border: '1px solid #D9DFE3',
    borderRadius: '10px',
  }),
});

export const EditCustomEmailModal = () => {
  const { modalData } = useSelector(getModalDataSelector);
  const { formatMessage } = useInternationalization();

  const updateObj = new FormData();

  const [passType, setPassType] = useState('password');
  const [emailDomainInfo, setEmailDomainInfo] = useState({
    email: modalData.emailDomain.email_from,
    email_host: modalData.emailDomain.email_host,
    email_type: modalData.emailDomain.email_type,
    email_port: modalData.emailDomain.email_port,
    email_username: modalData.emailDomain.email_username,
    email_password: modalData.emailDomain.email_password,
  });

  const [emailDomainError, setEmailDomainError] = useState({
    email: '',
    email_host: '',
    email_type: '',
    email_port: '',
    email_username: '',
    email_password: '',
  });

  const onClose = () => {
    modalData.onClose();
  };

  const togglePassType = () => {
    setPassType(passType === 'password' ? 'text' : 'password');
  };

  const valueFieldsValidation = () => {
    const errorObject = {};

    for (const [key, value] of Object.entries(emailDomainInfo)) {
      if (value === '') {
        errorObject[key] = 'This field must not be empty';
      } else if (
        regExp[key] &&
        !regExp[key].expression.test(String(value).toLowerCase())
      ) {
        errorObject[key] = regExp[key].errorMessage;
      } else {
        errorObject[key] = '';
      }
    }

    return errorObject;
  };

  const handleChangeDomainInfo = (e, name) => {
    if (e.target && e.target.value) {
      setEmailDomainInfo({ ...emailDomainInfo, [name]: e.target.value });
      setEmailDomainError({ ...emailDomainError, [name]: '' });
    } else {
      setEmailDomainInfo({ ...emailDomainInfo, [name]: '' });
    }
  };

  const handleUpdateEmailDomain = () => {
    const errorObject = valueFieldsValidation();
    updateObj.append('email_from', emailDomainInfo.email);
    updateObj.append('email_host', emailDomainInfo.email_host);
    updateObj.append('email_password', emailDomainInfo.email_password);
    updateObj.append('email_port', emailDomainInfo.email_port);
    updateObj.append('email_username', emailDomainInfo.email_username);
    updateObj.append('email_type', emailDomainInfo.email_type.value);
    updateObj.append('use_custom_email_domain', true);

    if (isObjectWithoutError(errorObject)) {
      FranchiseCompanyApi.setFranchiseSettings(modalData.id, updateObj).then(res =>
        modalData.setEmailDomain(prevState => ({
          ...prevState,
          email_from: res.data.email_from,
          email_host: res.data.email_host,
          email_type: res.data.email_type,
          email_port: res.data.email_port,
          email_username: res.data.email_username,
          email_password: res.data.email_password,
        })),
      );
      onClose();
    } else {
      setEmailDomainError({ ...emailDomainInfo, ...errorObject });
    }
  };

  return (
    <ModalContainer modal={MODALS.editCustomEmail} onClose={onClose}>
      <div className="email-custom-email-container">
        <div className="email-custom-email-container_title">
          {formatMessage('Custom Email Domain')}
        </div>
        <div className="email-custom-email-container_border" />
        <div className="email-custom-email-container_field">
          <NewBcField
            name="email"
            value={emailDomainInfo.email}
            placeholder="Sender email"
            label={formatMessage('Sender email')}
            onChange={handleChangeDomainInfo}
            Icon={<ClearingFieldIcon />}
            helperText={
              Boolean(emailDomainError.email) && formatMessage(emailDomainError.email)
            }
          />
        </div>
        <div className="email-custom-email-container_field">
          <div className="email-custom-email-container_field_name">
            {formatMessage('Certificate')}
          </div>
          <BcSelect
            customStyle={customStyles()}
            value={emailDomainInfo.email_type}
            placeholder={formatMessage('Choose an option')}
            label="Email type"
            outlined
            options={[
              { value: 0, label: 'TLS' },
              { value: 1, label: 'SSL' },
            ]}
            onChange={value => {
              setEmailDomainInfo({ ...emailDomainInfo, email_type: value });
            }}
          />
        </div>
        <div className="email-custom-email-container_field">
          <NewBcField
            name="email_username"
            value={emailDomainInfo.email_username}
            placeholder="Your name"
            label={formatMessage('Username')}
            Icon={<ClearingFieldIcon />}
            onChange={handleChangeDomainInfo}
          />
        </div>
        <div className="email-custom-email-container_field">
          <NewBcField
            name="email_host"
            value={emailDomainInfo.email_host}
            placeholder="Enter valid host"
            label={formatMessage('Host')}
            Icon={<ClearingFieldIcon />}
            onChange={handleChangeDomainInfo}
          />
        </div>
        <div className="email-custom-email-container_field">
          <NewBcField
            name="email_port"
            value={emailDomainInfo.email_port}
            placeholder={formatMessage('Enter valid port')}
            label={formatMessage('Port')}
            Icon={<ClearingFieldIcon />}
            onChange={handleChangeDomainInfo}
          />
        </div>
        <div className="email-custom-email-container_field">
          <PasswordField
            name="email_password"
            value={emailDomainInfo.email_password}
            placeholder={formatMessage('Enter your password')}
            label={formatMessage('Password')}
            onClick={togglePassType}
            type={passType}
            onChange={handleChangeDomainInfo}
          />
        </div>
        <div
          className="email-custom-email-container_button"
          onClick={handleUpdateEmailDomain}
        >
          <Button
            color="green"
            width="100%"
            height="56px"
            customClass="email-custom-email-container_button_text"
            disabled={emailDomainInfo.email === ''}
          >
            {formatMessage('Save')}
          </Button>
        </div>
      </div>
    </ModalContainer>
  );
};
