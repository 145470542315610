import React from 'react';
import Checkbox from '@mui/material/Checkbox';
import { styled } from '@mui/system';
import { green } from '@mui/material/colors';

const GreenCheckboxRoot = styled(Checkbox)(() => ({
  color: green[400],
  '&.Mui-checked': {
    color: green[600],
  },
}));

export const GreenCheckbox = props => <GreenCheckboxRoot color="default" {...props} />;
