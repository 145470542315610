import React from 'react';

export const CopyIcon = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="Copy">
      <g id="Vector">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M7.5 3.75C7.5 3.33579 7.83579 3 8.25 3H20.25C20.6642 3 21 3.33579 21 3.75V15.75C21 16.1642 20.6642 16.5 20.25 16.5H15.75C15.3358 16.5 15 16.1642 15 15.75C15 15.3358 15.3358 15 15.75 15H19.5V4.5H9V8.25C9 8.66421 8.66421 9 8.25 9C7.83579 9 7.5 8.66421 7.5 8.25V3.75Z"
          fill="#5925DC"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M3 8.25C3 7.83579 3.33579 7.5 3.75 7.5H15.75C16.1642 7.5 16.5 7.83579 16.5 8.25V20.25C16.5 20.6642 16.1642 21 15.75 21H3.75C3.33579 21 3 20.6642 3 20.25V8.25ZM4.5 9V19.5H15V9H4.5Z"
          fill="#5925DC"
        />
      </g>
    </g>
  </svg>
);
