import {
  GET_TAG_TYPES_REQUEST,
  GET_TAG_TYPES_SUCCESS,
  CREATE_TAG_TYPES_REQUEST,
  CREATE_TAG_TYPES_ERROR,
} from './tagTypesConstants';

const initialState = {
  tagTypes: [],
  tagTypesErrors: '',
};

export const tagTypesReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_TAG_TYPES_REQUEST:
      return {
        ...state,
        tagTypesErrors: '',
      };

    case GET_TAG_TYPES_SUCCESS:
      const { data } = action.payload;
      return {
        ...state,
        tagTypes: [...data],
        tagTypesErrors: '',
      };

    case CREATE_TAG_TYPES_REQUEST:
      return {
        ...state,
        tagTypesErrors: '',
      };

    case CREATE_TAG_TYPES_ERROR:
      return {
        ...state,
        tagTypesErrors: action.payload,
      };
      break;

    default:
      return state;
  }
};
