import Button from '@wabb-ui-kit/Button/Button';
import { EButtonSize, EIconPosition } from '@wabb-ui-kit/Button/types/types';
import './BackupCode.scss';
import { DonwloadIcon } from '@wabb-assets/svg/Button/donwload-icon';
import { CopyIcon } from '@wabb-assets/svg/FormField/copy-icon';

export const BackupCode = () => {
  const data = [
    '123 456',
    '123 456',
    '123 456',
    '123 456',
    '123 456',
    '123 456',
    '123 456',
    '123 456',
    '123 456',
    '123 456',
  ];
  return (
    <div className="back-up__container">
      <div className="back-up__list">
        {data.map(elem => (
          <div className="back-up__elem">{elem}</div>
        ))}
      </div>
      <div className="back-up__buttons">
        <Button
          type="primary"
          style="link"
          size={EButtonSize.S}
          icon={<CopyIcon />}
          className='back-up__buttons-btn-first'
          iconPosition={EIconPosition.RIGHT}
        >
          Copy
        </Button>
        <Button
          type="primary"
          style="link"
          className='back-up__buttons-btn-second'
          size={EButtonSize.S}
          icon={<DonwloadIcon />}
          iconPosition={EIconPosition.RIGHT}
        >
          Download
        </Button>
      </div>
    </div>
  );
};
