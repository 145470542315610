import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import './TwoFactorAuthenticationModal.scss';
import { useInternationalization } from '@hooks/useTranslationHook';
import { twoFactorAuthenticationApi } from '@api/twoFactorAuthenticationApi';
import { modalActions } from '@components/containers/Modal/modalActions';
import Button from '@wabb-ui-kit/Button/Button';
import { EButtonSize } from '@wabb-ui-kit/Button/types/types';
import { getModalDataSelector } from '@components/containers/Modal/modalSelectors';
import { ModalContainer } from '@components/containers/Modal/ModalContainer';
import { MODALS } from '@components/containers/Modal/modalConstants';
import { authActions } from '@redux/auth/authActions';
import { useContextSelector } from 'use-context-selector';
import { useHistory, useLocation } from 'react-router-dom';
import { FranchiseContext } from '../../../contexts/franchiseContext';
import { ToastContext } from '../../../contexts/toastsContext';
import { CopyIcon } from '@wabb-assets/svg/FormField/copy-icon';
import TwoFactorCodeInput from './2fa/2fa';
import qrCode from '@wabb-assets/img/qr.png';
import { AlertIcon } from '@wabb-assets/svg/Modal/alert-icon';
import { BackupCode } from './BackupCode/BackupCode';

export const TwoFactorAuthenticationModal = () => {
  const { formatMessage } = useInternationalization();
  const { modalData } = useSelector(getModalDataSelector);
  const initialCode = new Array(6).fill('');
  const [codes, setCodes] = useState(initialCode);
  const [step, setStep] = useState(1);

  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();

  const supportEmail = useContextSelector(
    FranchiseContext,
    ({ supportEmail }) => supportEmail,
  );
  const addToast = useContextSelector(ToastContext, ({ add }) => add);

  const [time, setTime] = useState(0);

  const CODE_LENGTH = new Array(6).fill(0);
  const [values, setValues] = useState([]);
  const [focused, setFocus] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [value, setValue] = useState('');
  const [hideInput, setHideInput] = useState(false);
  const language = localStorage.getItem('language');

  const input = React.createRef();


  useEffect(() => {
    time > 0 && setTimeout(() => setTime(time - 1), 1000);
  }, [time]);

  useEffect(() => {
    if (value.length === 6) {
      handleSubmit();
    }
  }, [value]);

  useEffect(() => {
    setValues(value.split(''));
  }, [value, setValues]);

  useEffect(() => {
    setHideInput(!(values.length < CODE_LENGTH.length));
    setSelectedIndex(
      values.length < CODE_LENGTH.length ? values.length : CODE_LENGTH.length - 1,
    );
  }, [values, setSelectedIndex]);

  const sendSMS = () => {
    setValue('');

    if (
      errorMessage !==
      'You have reached the maximum number of attempts. Please try again later'
    ) {
      setErrorMessage('');
    }

    twoFactorAuthenticationApi
      .sendSMS({
        language:
          language === 'English'
            ? 'en'
            : language === 'Português'
            ? 'pt'
            : language === 'Spanish'
            ? 'es'
            : '',
        user_id: modalData.userId,
        session_code: modalData.sessionCode,
      })
      .then(() => {
        setTime(59);
      })
      .catch(err => {
        switch (err.response.data.error) {
          case 'attempts_end':
            setErrorMessage(
              'You have reached the maximum number of attempts. Please try again later',
            );
            break;
          default:
            setErrorMessage('Something went wrong.');
        }
      });
  };

  const handleClick = () => {
    input.current.focus();
  };

  const onCLose = () => {
    dispatch(modalActions.closeModal());
  };

  const handleSubmit = () => {
    sendSMS().then(()=>{
      
    });
    setStep(2);
    // twoFactorAuthenticationApi
    //   .sendConfirmCode({
    //     user_id: modalData.userId,
    //     session_code: modalData.sessionCode,
    //     code: value,
    //   })
    //   .then(SMSResponse => {
    //     if (SMSResponse.data.type === 0) {
    //       dispatch(
    //         authActions.auth(
    //           '',
    //           '',
    //           null,
    //           history,
    //           location,
    //           supportEmail,
    //           addToast,
    //           SMSResponse,
    //         ),
    //       );
    //     } else if (SMSResponse.data.type === 1) {
    //     } else if (SMSResponse.data.type === 2) {
    //       modalData.setUserInfo(prevState => ({
    //         ...prevState,
    //         avatar: SMSResponse.data.avatar,
    //         first_name: SMSResponse.data.first_name,
    //         last_name: SMSResponse.data.last_name,
    //         phone: SMSResponse.data.phone,
    //         email: SMSResponse.data.email,
    //         id: SMSResponse.data.id,
    //       }));
    //       onCLose();
    //     } else if (SMSResponse.data.type === 3) {
    //       modalData.setIsValidation(true);
    //     } else if (SMSResponse.data.type === 4) {
    //       modalData.setIsValidation(false);
    //       onCLose();
    //     }
    // setStep(2); // Move to step 2
    //   })
    //   .catch(err => {
    //     switch (err.response.data.error) {
    //       case 'attempts_end':
    //         setErrorMessage(
    //           'You have reached the maximum number of attempts. Please try again later',
    //         );
    //         break;
    //       case 'incorrect_code':
    //         setErrorMessage(
    //           'The entered code is incorrect. Please double-check and try again',
    //         );
    //         break;
    //       case 'code_is_expired':
    //         setErrorMessage(
    //           'The code you entered has expired. Please request a new code',
    //         );
    //         break;
    //       default:
    //         setErrorMessage('Something went wrong.');
    //     }
    //   });
  };

  const handleCopyClick = () => {
    const textToCopy = 'NJ1& JKHUI LD21 NF42';
    navigator.clipboard
      .writeText(textToCopy)
      .then(() => {
        addToast({ title: '', message: 'Copied to clipboard', type: 'success' });
      })
      .catch(err => {
        addToast({ message: 'Failed to copy', type: 'error' });
      });
  };

  return (
    <ModalContainer modal={MODALS.twoFactorAuthentication} onClose={onCLose}>
      <div className="two-factor-authentication">
        {step === 1 && (
          <>
            <div className="two-factor-authentication__header">
              {formatMessage('Mobile phone verification')}
            </div>
            <div className="two-factor-authentication__container">
              <div className="two-factor-authentication__container_title">
                <span>
                  {formatMessage('To enable, scan the QR code using of ')}{' '}
                  <a
                    className="two-factor-authentication__container_title_number"
                    href="https://support.google.com/accounts/answer/1066447?hl=en"
                    target="_blank"
                  >
                    {formatMessage('Google Authenticator')}
                  </a>{' '}
                </span>
                <div className="two-factor-authentication__img">
                  <img src={qrCode} />
                </div>
                <span>
                  {formatMessage(`Can't scan the QR Code? Enter this code manually`)}
                </span>
              </div>
              <div className="two-factor-authentication__container_info">
                <div className="two-factor-authentication__container_info_title">
                  {formatMessage(' for Two-factor authentication code')}
                </div>
                <div className="two-factor-authentication__container_info_code">
                  <div>NJ1& JKHUI LD21 NF42</div>
                  <div
                    className="two-factor-authentication__container_info_code_icon"
                    onClick={handleCopyClick}
                  >
                    <CopyIcon />
                  </div>
                </div>
              </div>
              <div className="two-factor-authentication__container_footer">
                {formatMessage(
                  '  Once the authentication app generates a conformation code, enter it below',
                )}
              </div>
              <div
                className="two-factor-authentication__container_code"
                // onClick={handleClick}
              >
                <div className="two-factor-authentication__container_code_input">
                  <TwoFactorCodeInput codes={codes} setCodes={setCodes} />
                </div>
                <div className="two-factor-authentication__buttons">
                  <Button size={EButtonSize.L} onClick={handleSubmit}>
                    {formatMessage('Verify')}
                  </Button>
                  <Button
                    size={EButtonSize.L}
                    type="secondary"
                    style="tint"
                    onClick={onCLose}
                  >
                    {formatMessage('Cancel')}
                  </Button>
                </div>
              </div>
            </div>
          </>
        )}
        {step === 2 && (
          <>
            <div className="two-factor-authentication__icon">
              <AlertIcon />
            </div>
            <div className="two-factor-authentication__title">
              {formatMessage('Successfully enabled')}
            </div>
            <div className="two-factor-authentication__description">
              {formatMessage(
                'Save this emergency backup code and store it somewhere safe. If you lose your phone, you can use backup codes to sign in.',
              )}
            </div>
            <div className="two-factor-authentication__container">
              <BackupCode />
              <div className="two-factor-authentication__buttons">
                <Button size={EButtonSize.L} onClick={onCLose}>
                  {formatMessage('Done')}
                </Button>
              </div>
            </div>
          </>
        )}
      </div>
    </ModalContainer>
  );
};
