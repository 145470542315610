import React, { forwardRef } from 'react';
import cn from 'classnames';

import './Layout.styles.scss';

// types of props
// will remove after merge to typescript
// const Align = [
//   'top-left',
//   'top-center',
//   'top-right',
//   'bottom-left',
//   'bottom-center',
//   'bottom-right',
//   'middle-left',
//   'middle-right',
//   'middle-center',
// ];
// const PaddingStep = ['0', '4', '8', '12', '16', '20', '24', '28', '32', '64'];
// const Padding = `${PaddingStep} ${PaddingStep} ${PaddingStep} ${PaddingStep}`;
// const Gap = ['0', '2', '4', '8', '12', '16', '20', '24', '28', '32', '64'];

const Layout = (
  {
    children,
    align,
    gap,
    hasEllipsis,
    hasNoShrink,
    hasWrap,
    justify,
    id,
    isColumn,
    isWide,
    padding,
    title,
  },
  ref,
) => {
  const paddingArray = padding?.split(' ') || [];
  const TOP = 0;
  const RIGHT = 1;
  const BOTTOM = 2;
  const LEFT = 3;

  const classes = cn(
    'root',
    `gap${gap}`,
    `paddingTop${paddingArray[TOP]}`,
    `paddingRight${paddingArray[RIGHT]}`,
    `paddingBottom${paddingArray[BOTTOM]}`,
    `paddingLeft${paddingArray[LEFT]}`,
    {
      hasEllipsis,
      hasNoShrink,
      hasWrap,
      isColumn,
      isWide,
      spaceBetween: justify === 'space-between',
      topLeft: align === 'top-left',
      middleLeft: align === 'middle-left',
      bottomLeft: align === 'bottom-left',
      topCenter: align === 'top-center',
      middleCenter: align === 'middle-center',
      bottomCenter: align === 'bottom-center',
      topRight: align === 'top-right',
      middleRight: align === 'middle-right',
      bottomRight: align === 'bottom-right',
    },
  );

  return (
    <div ref={ref} className={classes} id={id} title={title}>
      {children}
    </div>
  );
};

const refForwarded = forwardRef(Layout);

export { refForwarded as Layout };
