import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import { useContextSelector } from 'use-context-selector';

import {
  getNotificationDataSelector,
  isNotificationOpenSelector,
} from '@redux/notificationWindow/notificationWindowSelectors';
import { notificationWindowActions } from '@redux/notificationWindow/notificationWindowActions';
import { useInternationalization } from '@hooks/useTranslationHook';
import { ToastContext } from '../../../contexts/toastsContext';

export default function NotificationWindow() {
  const dispatch = useDispatch();
  const data = useSelector(getNotificationDataSelector);
  const open = useSelector(isNotificationOpenSelector);
  const { formatMessage } = useInternationalization();
  const addToast = useContextSelector(ToastContext, ({ add }) => add);
  const { type, label, text } = data;

  const closeWindow = () => {
    dispatch(notificationWindowActions.closeModal());
  };

  useEffect(() => {
    open &&
      type &&
      addToast({
        title: label,
        type,
        message:
          typeof text === 'string'
            ? formatMessage(text)
            : Array.isArray(text)
            ? formatMessage(...text)
            : text,
      });
  }, [data]);

  const STOP_SHOW_NOTIFICATION_WINDOW = false;

  return (
    STOP_SHOW_NOTIFICATION_WINDOW && (
      <Snackbar
        open={open}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        onClose={() => closeWindow()}
      >
        <Alert severity={type} variant="filled">
          <AlertTitle>{formatMessage(label)}</AlertTitle>
          {typeof text === 'string'
            ? formatMessage(text)
            : Array.isArray(text)
            ? formatMessage(...text)
            : text}
        </Alert>
      </Snackbar>
    )
  );
}
