import api from './baseApi';

import { profileApiRPC } from '@connectApi/baseApi';
import { DeleteManagerFromCompanyRequest } from 'wabb-backend-api/build/es/profile/profile_pb';

export const profileApi = {
  getCompanies: () => profileApiRPC.getProfileCompany(),
  deleteManagerFromCompany: companyId =>
  profileApiRPC.deleteManagerFromCompany(DeleteManagerFromCompanyRequest.fromJson({ company_id: companyId })),

  transferCompany: data => api.post('/profile/transfer_company/', data),
  transferCompanyConfirm: data => api.post('/profile/transfer_company/confirm/', data),
};
