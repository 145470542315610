import { createSelector } from 'reselect';

export const getNotificationsSelector = createSelector(
  [state => state.notifications],
  notifications => notifications,
  notificationsSocket => notificationsSocket,
);

export const getQrSelector = createSelector([state => state.notifications.qr], qr => qr);

export const getConnectedPhoneSelector = createSelector(
  [state => state.notifications.connectedPhone],
  phone => phone,
);

export const getNotificationStatusSelector = createSelector(
  [state => state.notifications.notificationStatus],
  notificationStatus => notificationStatus,
);

export const getConnectionStatusSelector = createSelector(
  [state => state.notifications.connectionStatus],
  connectionStatus => connectionStatus,
);

export const getIsSyncProcessSelector = createSelector(
  [state => state.notifications.isSyncProcess],
  isSyncProcess => isSyncProcess,
);
