import React, { useState } from 'react';
import { useSelector } from 'react-redux';

import { Button } from '@components/common/Button';
import { useInternationalization } from '@hooks/useTranslationHook';
import { usersApi } from '@api/usersApi';
import { useContextSelector } from 'use-context-selector';
import { getModalDataSelector } from '../../containers/Modal/modalSelectors';
import { ModalContainer } from '../../containers/Modal/ModalContainer';
import { MODALS } from '../../containers/Modal/modalConstants';
import { BcTextField } from '../../common/fields/TextField';

import './emailModal.scss';
import { ToastContext } from '../../../contexts/toastsContext';

export const EmailModal = () => {
  const addToast = useContextSelector(ToastContext, ({ add }) => add);

  const { modalData } = useSelector(getModalDataSelector);
  const [email, setEmail] = useState('');
  const [error, setError] = useState('');

  const { formatMessage } = useInternationalization();

  const handleSubmit = () => {
    usersApi
      .resetUserPassword(email)
      .then(() => {
        addToast({
          title: 'Success',
          type: 'success',
          message: 'If you have a valid account',
        });
      })
      .catch(err => {
        switch (err.response.status) {
          case 400:
            addToast({
              title: 'Error',
              type: 'error',
              message: 'e-mail invalid',
            });
            break;
          default:
            addToast({
              title: 'Error',
              type: 'error',
              message: '',
            });
            break;
        }
      });
    modalData.onClose();
  };

  const validation = email => {
    const emailValidation =
      /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;

    if (emailValidation.test(String(email).toLowerCase())) {
      setError('');
      return true;
    }

    setError('Invalid email');
    return false;
  };

  return (
    <ModalContainer modal={MODALS.email} onClose={modalData.onClose}>
      <div className="email_modal">
        <div className="email_modal__header">{formatMessage('Reset Password')}</div>
        <div className="email_modal__content">
          <span className="email_modal__text">{formatMessage('Enter your email')}:</span>
          <div className="email_modal__text-input">
            <BcTextField
              onChange={e => setEmail(e.target.value)}
              placeholder="Enter Email"
              fullWidth
              onBlur={() => validation(email)}
              error={error}
            />
            {error && <span className="email_modal__text-input__err">{error}</span>}
            <div className="email_modal__text-input__button">
              <Button onClick={handleSubmit} width={180}>
                Reset Password
              </Button>
            </div>
          </div>
        </div>
      </div>
    </ModalContainer>
  );
};
