import React from 'react';
import { HelpkitInfoIcon } from '@icons/helpkit-info-icon';
import { useContextSelector } from 'use-context-selector';
import { FranchiseContext } from '../../../contexts/franchiseContext';

export const HelpkitInfo = ({ helpkitCode }) => {
  const frCode = useContextSelector(FranchiseContext, ({ code }) => code);

  if (!frCode) {
    return (
      <div
        style={{ cursor: 'pointer' }}
        //TODO: need to implement correct onclick logic
        onClick={() => {}}
      >
        <HelpkitInfoIcon />
      </div>
    );
  } else return <></>;
};
