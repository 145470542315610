import React, { useState } from 'react';
import { useSelector } from 'react-redux';

import { useInternationalization } from '@hooks/useTranslationHook';
import { getModalDataSelector } from '../../containers/Modal/modalSelectors';
import { MODALS } from '../../containers/Modal/modalConstants';
import { ModalContainer } from '../../containers/Modal/ModalContainer';
import Button from '@wabb-ui-kit/Button/Button';
import { EButtonSize } from '@wabb-ui-kit/Button/types/types';

import { PositiveAlertIcon } from '@wabb-assets/svg/Modal/positive-alert-icon';
import { NegativeAlertIcon } from '@wabb-assets/svg/Modal/negative-alert-icon';

import './confirmModal.scss';

export const ConfirmModal = () => {
  const { formatMessage } = useInternationalization();
  const { modalData } = useSelector(getModalDataSelector);

  const [loader, setLoader] = useState(false);

  const {
    title,
    text,
    confirmButtonText,
    canselButtonText = 'Cancel',
    type = 'confirm',
    handleConfirm,
    onClose,
  } = modalData;

  const handleSubmit = (event) => {
    if (!loader) {
      setLoader(true);
      handleConfirm(event);
      onClose();
    }
  };

  return (
    <ModalContainer modal={MODALS.confirmModal} onClose={onClose}>
      <div className="confirm-modal">
        <div>{type === 'confirm' ? <PositiveAlertIcon /> : <NegativeAlertIcon />}</div>
        <div className="confirm-modal__title">
          <span>{formatMessage(title)}</span>
        </div>
        <div className="confirm-modal__text">
          { typeof text === 'object'
            ? text
            : (
              <span>
                { Array.isArray(text) ? formatMessage(...text) : formatMessage(text) }
              </span>
            )}
        </div>
        <div className="confirm-modal__buttons">
          <Button
            width="100%"
            type={type === 'confirm' ? 'primary' : 'negative'}
            onClick={handleSubmit}
            style="tint"
            size={EButtonSize.L}
          >
            {loader ? (
              <div
                className={`confirm-modal__loader-${type === 'confirm' ? 'blue' : 'red'}`}
              />
            ) : (
              <span>{formatMessage(confirmButtonText)}</span>
            )}
          </Button>
          <Button type="secondary" style="tint" onClick={onClose} size={EButtonSize.L}>
            <span>{formatMessage(canselButtonText)}</span>
          </Button>
        </div>
      </div>
    </ModalContainer>
  );
};
