import React, { useState } from 'react';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { useSelector } from 'react-redux';
import { styled } from '@mui/system';

import '../modalsComponent.scss';
import './ShareTemplateModal.scss';

import { ModalContainer } from '../../containers/Modal/ModalContainer';
import { MODALS } from '../../containers/Modal/modalConstants';
import { getModalDataSelector } from '../../containers/Modal/modalSelectors';
import { getSelectedCompanySelector } from '../../../redux/companies/companiesSelectors';
import { useInternationalization } from '../../../hooks/useTranslationHook';
import { templatesApi } from '../../../api/templatesApi';

const StyledRoot = styled('div')(({ theme }) => ({
  display: 'flex',
  marginLeft: 0,
  marginRight: 0,
}));

const StyledBody = styled('div')(({ theme }) => ({
  fontFamily: 'Open Sans',
  fontStyle: 'normal',
  fontWeight: 'normal',
  fontSize: 15,
  lineHeight: '18px',
  color: '#8492A6',
}));

const PurpleCheckbox = styled(Checkbox)(({ theme }) => ({
  color: '#6445E0',
  '&.Mui-checked': {
    color: '#6445E0',
  },
}));

const styles = {};

export const ShareTemplateModal = () => {
  const { formatMessage } = useInternationalization();

  const company = useSelector(getSelectedCompanySelector);

  const { modalData } = useSelector(getModalDataSelector);

  const { template, is_share_enabled, shareCode } = modalData;

  const [state, setState] = useState({
    shareTemplateCheckedBox: is_share_enabled,
  });

  const handleChange = event => {
    company &&
      templatesApi
        .updateTemplate(template.id, { is_share_enabled: event.target.checked })
        .then(res => (template.is_share_enabled = res.data.is_share_enabled));
    setState({ ...state, [event.target.name]: event.target.checked });
  };

  return (
    <ModalContainer
      modal={MODALS.shareTemplate}
      onClose={modalData.onClose}
      customStyles={styles}
    >
      <div className="campaigns-modal">
        <div className="campaigns-modal__header">
          {formatMessage('Share this template')}
        </div>
        <div className="share-template-modal__link-sharing">
          <div>{formatMessage('Link Sharing and Embedding')}</div>
          <div className="share-template-modal__form-group">
            <div>
              <FormControlLabel
                // className={classNames(classes.root, classes.body)}
                control={
                  <PurpleCheckbox
                    title="checkbox"
                    label={formatMessage('Enable typing')}
                    checked={state.shareTemplateCheckedBox}
                    onChange={handleChange}
                    color="default"
                    inputProps={{ 'aria-label': 'checkbox with default color' }}
                    name="shareTemplateCheckedBox"
                  />
                }
                label={formatMessage('Enable Sharing')}
              />
            </div>
          </div>
        </div>
        {state.shareTemplateCheckedBox && (
          <div
            className="share-template-modal__link-sharing-dropdown"
            style={{ marginBottom: '16px' }}
          >
            <div>{formatMessage('Link')}</div>
            <a
              href={`${window.location.origin}/templates/installation?share_code=${shareCode}`}
              target="_blank"
              style={{ color: '#6445E0', cursor: 'pointer', textAlign: 'center' }}
              rel="noreferrer"
            >
              {`${window.location.origin}/templates/installation?share_code=${shareCode}`}
            </a>
          </div>
        )}
      </div>
    </ModalContainer>
  );
};
