import React from 'react';

export const ShowPasswordIcon = () => (
  <svg
    width="24"
    height="25"
    viewBox="0 0 24 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12.3974 7.30423C15.6352 7.30423 18.5228 9.12391 19.9324 12.0029C19.4283 13.0452 18.7193 13.9422 17.8735 14.6684L19.0781 15.8729C20.2656 14.8221 21.2053 13.5065 21.7948 12.0029C20.3168 8.25252 16.6689 5.59562 12.3974 5.59562C11.3124 5.59562 10.2702 5.76648 9.28771 6.08257L10.6973 7.49218C11.2526 7.38112 11.8165 7.30423 12.3974 7.30423ZM11.4833 8.27815L13.2517 10.0466C13.7387 10.2601 14.1316 10.6531 14.3452 11.1401L16.1136 12.9085C16.182 12.618 16.2332 12.3105 16.2332 11.9944C16.2418 9.8757 14.5161 8.15854 12.3974 8.15854C12.0813 8.15854 11.7823 8.20126 11.4833 8.27815ZM3.86285 5.48456L6.1524 7.7741C4.75987 8.86762 3.65782 10.3199 3 12.0029C4.47795 15.7533 8.12585 18.4102 12.3974 18.4102C13.6959 18.4102 14.9432 18.1625 16.088 17.7097L19.0097 20.6314L20.2143 19.4269L5.06743 4.27144L3.86285 5.48456ZM10.2702 11.8919L12.4999 14.1216C12.4657 14.1302 12.4316 14.1387 12.3974 14.1387C11.2184 14.1387 10.2616 13.1819 10.2616 12.0029C10.2616 11.9602 10.2702 11.9346 10.2702 11.8919ZM7.36551 8.98722L8.86055 10.4823C8.66406 10.9521 8.553 11.4647 8.553 12.0029C8.553 14.1216 10.2787 15.8473 12.3974 15.8473C12.9356 15.8473 13.4482 15.7363 13.9095 15.5398L14.7467 16.377C13.9949 16.582 13.209 16.7016 12.3974 16.7016C9.15956 16.7016 6.272 14.8819 4.86239 12.0029C5.46041 10.7813 6.3318 9.77318 7.36551 8.98722Z"
      fill="#A4A8B8"
    />
  </svg>
);
