import {
  GET_GROUPCAMPAIGNS_SUCCESS,
  GET_GROUPCAMPAIGNS_REQUEST,
} from './groupCampaignsConstants';

export const groupCampaignsActions = {
  getGroupCampaignsRequest(data) {
    return {
      type: GET_GROUPCAMPAIGNS_REQUEST,
      payload: data,
    };
  },

  getGroupCampaignsSuccess(data) {
    return {
      type: GET_GROUPCAMPAIGNS_SUCCESS,
      payload: {
        data,
      },
    };
  },
};
