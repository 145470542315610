import React, {
  useState, useRef, useCallback, useMemo,
} from 'react';
import Avatar from '@wabb-ui-kit/Avatar/Avatar';
import CheckBox from '@wabb-ui-kit/CheckBox/CheckBox';
import useOutsideClick from '@wabb-ui-kit/TagsDropdown/hooks/useOutsideClick';
import SingleSelectDisplay from '@wabb-ui-kit/Dropdown/Selects/SingleSelect/SingleSelect';
import SearchSelect from '@wabb-ui-kit/Dropdown/Selects/SearchSelect/SearchSelect';
import MultiSelectDisplay from '@wabb-ui-kit/Dropdown/Selects/MultiSelect/MultiSelect';
import { clsx } from 'clsx';
import CheckIcon from '../../wabb-assets/svg/Dropdown/check.svg';
import ArrowDown from '../../wabb-assets/svg/Dropdown/arrow-down.svg';

import styles from './Dropdown.module.scss';

export interface IOption {
  id: number;
  bot: number;
  name: string;
  subText?: string;
  img?: string;
}

interface IDropdownProps {
  options: IOption[];
  setOptions?: React.Dispatch<React.SetStateAction<IOption[]>>;
  multiSelect?: boolean;
  isCheckbox?: boolean;
  isAvatar?: boolean;
  search?: boolean;
  selectedOptions: IOption[];
  setSelectedOptions: React.Dispatch<React.SetStateAction<IOption[]>>;
  singleSelectedOption: IOption | null;
  setSingleSelectedOption: React.Dispatch<React.SetStateAction<IOption | null>>;
  className?: string;
  isBoldTitle?: boolean;
  placeholder?: string;
  isDisabled?: boolean
}

const Dropdown: React.FC<IDropdownProps> = ({
  options,
  setOptions,
  multiSelect = false,
  isCheckbox = false,
  isAvatar = false,
  search = false,
  selectedOptions = [],
  setSelectedOptions,
  singleSelectedOption,
  setSingleSelectedOption,
  className,
  isBoldTitle,
  placeholder,
  isDisabled = false,
}) => {
  const [searchTerm, setSearchTerm] = useState('');

  const [isOpen, setIsOpen] = useState(false);

  const ref = useRef<HTMLDivElement>(null);

  useOutsideClick(ref, () => setIsOpen(false), isOpen);

  const isOptionSelected = useCallback(
    (option: IOption) => selectedOptions.some((selectedOption) => selectedOption.id === option.id)
      || (singleSelectedOption && singleSelectedOption.id === option.id),
    [selectedOptions, singleSelectedOption],
  );

  const onOptionClicked = useCallback(
    (option: IOption) => {
      if (multiSelect) {
        const isSelected = isOptionSelected(option);
        const newSelectedOptions = isSelected
          ? selectedOptions.filter((selectedOption) => selectedOption.id !== option.id)
          : [...selectedOptions, option];

        setSelectedOptions(newSelectedOptions);

        if (setOptions) {
          setOptions((prevOptions) => prevOptions.map((opt) => (opt.id === option.id ? { ...opt, selected: !isSelected } : opt),),);
        }
      } else {
        setSingleSelectedOption(option);
        setIsOpen(false);
        if (search) setSearchTerm(option.name);
      }
    },
    [
      isOptionSelected,
      multiSelect,
      search,
      selectedOptions,
      setOptions,
      setSingleSelectedOption,
    ],
  );

  const handleCheckboxChange = useCallback(
    (option: IOption) => (checked: boolean) => {
      const newSelectedOptions = checked
        ? [...selectedOptions, option]
        : selectedOptions.filter((selectedOption) => selectedOption.id !== option.id);

      setSelectedOptions(newSelectedOptions);

      if (setOptions) {
        setOptions((prevOptions) => prevOptions.map((opt) => (opt.id === option.id ? { ...opt, selected: checked } : opt),),);
      }
    },
    [selectedOptions, setOptions],
  );

  const filteredOptions = useMemo(
    () => options.filter((option) => option.name.toLowerCase().includes(searchTerm.toLowerCase()),),
    [options, searchTerm],
  );

  const renderDisplay = useMemo(() => {
    if (search) {
      return (
        <SearchSelect
          searchTerm={searchTerm}
          setSearchTerm={setSearchTerm}
          setIsOpen={setIsOpen}
          isOpen={isOpen}
          singleSelectedOption={singleSelectedOption}
          hasSelectedOptions={selectedOptions.length > 0}
        />
      );
    }

    if (multiSelect) {
      return (
        <MultiSelectDisplay
          selectedOptions={selectedOptions}
          isOpen={isOpen}
          setSelectedOptions={setSelectedOptions}
        />
      );
    }

    return (
      <SingleSelectDisplay
        selectedOption={singleSelectedOption}
        isAvatar={isAvatar}
        isOpen={isOpen}
        setSelectedOptions={setSingleSelectedOption}
        isBoldTitle={isBoldTitle}
        isDisabled={isDisabled}
        placeholder={placeholder}
      />
    );
  }, [
    search,
    multiSelect,
    isOpen,
    searchTerm,
    selectedOptions,
    singleSelectedOption,
    isAvatar,
    setSingleSelectedOption,
  ]);

  const activeClass = clsx(className, styles.dropdownHeader, {
    [styles.dropdownHeader__active]: isOpen,
    [styles.dropdownHeader__disabled]: isDisabled,
  });

  const arrowClass = clsx(styles.selectArrow, {
    [styles.expanded]: isOpen,
    [styles.disabled]: isDisabled,
  });

  return (
    <div className={styles.dropdown} ref={ref}>
      <div className={activeClass} onClick={() => !isDisabled && setIsOpen(!isOpen)}>
        {renderDisplay}
        <div className={arrowClass}>
          <img src={ArrowDown} />
        </div>
      </div>
      {isOpen && (
        <div className={styles.dropdownListContainer}>
          <ul className={styles.dropdownList}>
            {filteredOptions.length > 0 ? (
              filteredOptions.map((option) => (
                <li
                  className={`${styles.dropdownListItem} ${isOptionSelected(option) ? styles.selected : ''
                  }`}
                  key={option.id}
                  onClick={() => !isCheckbox && onOptionClicked(option)}
                >
                  <div className={styles.listContainer}>
                    {isCheckbox && (
                      <CheckBox
                        type="checkbox"
                        title={option.name}
                        isChecked={isOptionSelected(option) || undefined}
                        onChange={() => handleCheckboxChange(option)}
                      />
                    )}
                    <div className={styles.titleWrapper}>
                      <div className={styles.title}>
                        {isAvatar && option.img && (
                          <Avatar type="company" size="xs" img={option.img} />
                        )}
                        {!isCheckbox && (
                          <div className={styles.optionLabel}>
                            {option.name}
                            {option.subText && (
                              <span className={styles.subText}>
                                {' '}
                                {option.subText}
                              </span>
                            )}
                          </div>
                        )}
                      </div>
                      <div className={styles.checkIcon}>
                        {!isCheckbox && isOptionSelected(option) && (
                          <img src={CheckIcon} />
                        )}
                      </div>
                    </div>
                  </div>
                </li>
              ))
            ) : (
              <div className={styles.notFound}>
                <div>Flows not found</div>
              </div>
            )}
          </ul>
        </div>
      )}
    </div>
  );
};

export default Dropdown;
