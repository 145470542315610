import React, { Suspense } from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';
import { IntercomProvider } from 'react-use-intercom';

import './index.scss';
import './translations/i18n';

import { store } from '@redux/store';
import ErrorBoundary from '@components/common/ErrorBoundary';
import { setAppSettings } from '@helpers/setAppSettings';
import NotificationWindow from '@components/common/NotificationWindow/NotificationWindow';
import { ToastProvider } from './contexts/toastsContext';
import { FranchiseProvider } from './contexts/franchiseContext';
import App from './App.jsx';
import { LoadingScreen } from '@components/loadingScreen/LoadingScreen';

setAppSettings();

const container = document.getElementById('root');
const root = createRoot(container);

const INTERCOM_APP_ID = 'yzkm4ffb'

root.render(
  <Suspense fallback={<LoadingScreen logo={false} />}>
    <Provider store={store}>
      <ErrorBoundary>
        <FranchiseProvider>
          <ToastProvider>
            <IntercomProvider appId={INTERCOM_APP_ID}>
              <Router>
                <App />
                <NotificationWindow />
              </Router>
            </IntercomProvider>
          </ToastProvider>
        </FranchiseProvider>
      </ErrorBoundary>
    </Provider>
  </Suspense>,
);

if ('serviceWorker' in navigator) {
  navigator.serviceWorker
    .getRegistrations()
    .then(registrations => {
      for (const registration of registrations) {
        registration.unregister();
      }
    })
    .catch(err => {
      console.log('Service Worker registration failed: ', err);
    });
}
