export const GET_VARIABLES_REQUEST = 'GET_VARIABLES_REQUEST';
export const GET_VARIABLES_SUCCESS = 'GET_VARIABLES_SUCCESS';
export const GET_BOT_VARIABLES_REQUEST = 'GET_BOT_VARIABLES_REQUEST';
export const GET_BOT_VARIABLES_SUCCESS = 'GET_BOT_VARIABLES_SUCCESS';
export const CREATE_VARIABLE_REQUEST = 'CREATE_VARIABLE_REQUEST';
export const CREATE_VARIABLE_ERROR = 'CREATE_VARIABLE_ERROR';
export const REMOVE_FIELD_REQUEST = 'REMOVE_FIELD_REQUEST';
export const UPDATE_VARIABLES_REQUEST = 'UPDATE_VARIABLES_REQUEST';
export const UPDATE_VARIABLES_SUCCESS = 'UPDATE_VARIABLES_SUCCESS';
export const UPDATE_BOT_VARIABLES_REQUEST = 'UPDATE_BOT_VARIABLES_REQUEST';
export const UPDATE_BOT_VARIABLES_SUCCESS = 'UPDATE_BOT_VARIABLES_SUCCESS';
