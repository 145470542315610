import React from 'react';

import { CloseSmallIcon } from '@wabb-assets/svg/Tags/close-small-icon';

import './MarkerItem.scss';

export const MarkerItem = ({ marker, removeMarker }) => {
  const getMarkerName = item => {
    if (item.name) {
      return item.name;
    }

    if (item.tagType) {
      return item.tagType.name;
    }

    return marker.sequenceType.name;
  };

  return (
    <div className="marker-item">
      <div className="marker-item__container">
        <div className="marker-item__name">{getMarkerName(marker)}</div>
        <div className="marker-item__delete-icon" onClick={() => removeMarker(marker.id)}>
          <CloseSmallIcon />
        </div>
      </div>
    </div>
  );
};
