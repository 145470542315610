import React, { useState, useRef, useEffect } from 'react';
import Popover from '@mui/material/Popover';
import { useDispatch } from 'react-redux';
import cn from 'classnames';
import PropTypes from 'prop-types';
import { useFormik } from 'formik';
import * as Yup from 'yup';

import './FolderItem.scss';

import { MoreIcon } from '@icons';
import { useHover } from '@hooks/useHover';
import { useKey } from '@hooks';
import { FolderIcon } from '@icons/flow/folder-icon';
import { useInternationalization } from '@hooks/useTranslationHook';
import { modalActions } from '@components/containers/Modal/modalActions';

const validationSchema = Yup.object().shape({
  folderName: Yup.string().required('Required'),
});

export const FolderItem = React.memo(
  ({
    onClick,
    onMove,
    onDelete,
    onRename,
    openMoveToModal,
    name,
    folderId,
    referenceCount,
  }) => {
    const { formatMessage } = useInternationalization();
    const [hoverRef, isHovered] = useHover();
    const inputRef = useRef(null);
    const folderRef = useRef(null);

    const [anchorEl, setAnchorEl] = useState(null);
    const [isRenaming, setRenaming] = useState(false);

    const dragEl = useRef(null);
    const dispatch = useDispatch();

    const handleClick = event => {
      event.preventDefault();
      event.stopPropagation();
      setAnchorEl(event.currentTarget);
    };

    useEffect(() => {
      isRenaming && inputRef.current.focus();
    }, [inputRef, isRenaming]);

    const form = useFormik({
      initialValues: {
        folderName: name,
      },
      validationSchema,
      validateOnChange: false,
      onSubmit: async values => {
        await onRename(folderId, values.folderName);
        closeRenameAction();
      },
    });

    const handleClose = () => {
      setAnchorEl(null);
    };

    const handleOnDragEnter = () => {
      dragEl.current.style = 'border: 1.5px dashed #6445e0';
    };

    const handleOnDragLeave = () => {
      dragEl.current.style = 'border: 1px solid #D9DFE3';
    };

    const handleOnDrop = e => {
      const data = JSON.parse(e.dataTransfer.getData('transferData'));

      if (data?.folderId !== folderId) {
        data.toFolder = folderId;
        onMove(data);
        dragEl.current.style = 'border: 1px solid #D9DFE3';
      }
    };

    const handleDeleteFolder = () => {
      dispatch(
        modalActions.openConfirmModal({
          title: formatMessage('Delete Folder'),
          text: `${formatMessage('Are you sure you want to delete folder?', {
            folderName: name,
          })} ${
            referenceCount
              ? formatMessage(
                  'If you do this all the flows inside this folder will also be deleted permanently. Are you sure you want to continue?',
                  { referenceCount },
                )
              : ''
          }`,
          confirmButtonText: 'Yes, Delete',
          type: 'delete',
          handleConfirm: async () => {
            await onDelete(folderId);
            dispatch(modalActions.closeModal());
          },
          onClose: () => {
            dispatch(modalActions.closeModal());
          },
        }),
      );
    };

    const closeRenameAction = () => {
      setRenaming(false);
    };

    const openRenameAction = () => {
      setRenaming(true);
    };

    useKey('Enter', form.submitForm);

    useKey('Escape', () => {
      closeRenameAction();
    });

    return (
      <button
        ref={dragEl}
        onDrop={e => handleOnDrop(e)}
        onDragOver={event => {
          event.preventDefault();
        }}
        onDragEnter={e => {
          handleOnDragEnter(e);
        }}
        onDragLeave={e => {
          handleOnDragLeave(e);
        }}
        onClick={() => {
          !anchorEl && !isRenaming && onClick();
        }}
        draggable={Boolean(onMove)}
        onDragStart={event => {
          const items = document.querySelectorAll('.folder-item__container');
          items.forEach(element => {
            element.style = 'pointer-events: none';
          });
          folderRef.current.style.opacity = '0.4';
          event.dataTransfer.setData(
            'transferData',
            JSON.stringify({ type: 'folder', folderId }),
          );
        }}
        onDragEnd={() => {
          const items = document.querySelectorAll('.folder-item__container');
          items.forEach(element => {
            element.style = 'pointer-events: all';
            folderRef.current.style.opacity = '1';
            dragEl.current.style = 'border: 1px solid #D9DFE3';
          });
        }}
        className={cn(
          'folder-item',
          isRenaming && 'folder-item_rename',
          isRenaming && form.errors.folderName && 'folder-item_error',
        )}
      >
        <div className="folder-item__container" ref={folderRef}>
          <div className="folder-item__left">
            <div>
              <FolderIcon />
            </div>
            {isRenaming ? (
              <input
                name="folderName"
                id="folderName"
                ref={inputRef}
                onBlur={form.submitForm}
                placeholder="Name"
                onChange={form.handleChange}
                value={form.values.folderName}
              />
            ) : (
              <span className="folder-item__left_name">{name}</span>
            )}
          </div>
          <div className="folder-item__right">
            <span className="folder-item__right_count">{referenceCount}</span>
            {(onRename || onDelete || onMove) && (
              <div onClick={handleClick} className="more-icn" ref={hoverRef}>
                <MoreIcon fill={isHovered ? '#797A86FF' : '#A4A8B8'} />
              </div>
            )}
            <Popover
              id={folderId}
              open={Boolean(anchorEl)}
              anchorEl={anchorEl}
              onClose={handleClose}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              PaperProps={{
                style: {
                  marginTop: '5px',
                  border: '1px solid #D9DFE3',
                  boxShadow: '0 5px 15px rgba(0, 0, 0, 0.05)',
                  borderRadius: '10px',
                },
              }}
            >
              <div className="folder-item__popover" onClick={handleClose}>
                <div onClick={openRenameAction}>{formatMessage('Rename')}</div>
                <div onClick={() => openMoveToModal(folderId)}>
                  {formatMessage('Move to')}
                </div>
                <div onClick={handleDeleteFolder}>{formatMessage('Delete')}</div>
              </div>
            </Popover>
          </div>
        </div>
      </button>
    );
  },
);

FolderItem.propsTypes = {
  folderId: PropTypes.string,
  name: PropTypes.string,
  referenceCount: PropTypes.number,
};
