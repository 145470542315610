import * as React from 'react';

export const ClearingFieldIcon = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect x="0.5" y="0.5" width="23" height="23" rx="11.5" fill="#EFF2F3" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.7926 8.20738C16.0691 8.48388 16.0691 8.93219 15.7926 9.20869L13.0013 12L15.7926 14.7913C16.0691 15.0678 16.0691 15.5161 15.7926 15.7926C15.5161 16.0691 15.0678 16.0691 14.7913 15.7926L12 13.0013L9.20869 15.7926C8.93219 16.0691 8.48388 16.0691 8.20738 15.7926C7.93087 15.5161 7.93087 15.0678 8.20738 14.7913L10.9987 12L8.20738 9.20869C7.93087 8.93219 7.93087 8.48388 8.20738 8.20738C8.48388 7.93087 8.93219 7.93087 9.20869 8.20738L12 10.9987L14.7913 8.20738C15.0678 7.93087 15.5161 7.93087 15.7926 8.20738Z"
      fill="#464F55"
    />
    <rect x="0.5" y="0.5" width="23" height="23" rx="11.5" stroke="white" />
  </svg>
);
