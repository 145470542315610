import React from 'react';

import { BoldIcon } from '@icons/text-editor/bold-icon';
import { ItalicIcon } from '@icons/text-editor/italic-icon';
import { StrikethroughIcon } from '@icons/text-editor/strikethrough-icon';

export const inlineStyles = [
  { textStyle: 'BOLD', icon: <BoldIcon /> },
  { textStyle: 'ITALIC', icon: <ItalicIcon /> },
  { textStyle: 'STRIKETHROUGH', icon: <StrikethroughIcon /> },
];
