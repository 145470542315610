import React, { useEffect, useState } from 'react';
import { MODALS } from '@components/containers/Modal/modalConstants';
import { ModalContainer } from '@components/containers/Modal/ModalContainer';
import { UploadImage } from '@components/common/UploadImage/UploadImage';
import { NewBcField } from '@components/common/fields/newBcField/newBcField';
import { BcSelect } from '@components/common/Select';
import { Button } from '@components/common/Button';
import { useDispatch, useSelector } from 'react-redux';
import { useInternationalization } from '@hooks/useTranslationHook';
import { FranchiseCompanyApi } from '@api/franchiseCompanyApi';
import moment from 'moment';
import { CheckBox } from '@components/common';

import './editCompanyModal.scss';
import { bcSplitTableActions } from '@redux/bcSplitTable/bcSplitTableActions';
import { getModalDataSelector } from '../../containers/Modal/modalSelectors';

const customStyles = () => ({
  control: styles => ({
    ...styles,
    width: '100%',
    height: '46px',
    backgroundColor: '#FFFF',
    border: '1px solid #D9DFE3',
    borderRadius: '10px',
    placeholderColor: 'black',
    cursor: 'pointer',
  }),
  menu: styles => ({
    ...styles,
    placeholderColor: 'black',
  }),
});

export const EditCompanyModal = () => {
  const dispatch = useDispatch();
  const { modalData } = useSelector(getModalDataSelector);
  const { formatMessage } = useInternationalization();

  const [users, setUsers] = useState();
  const [avatar, setAvatar] = useState(modalData.item.avatar);

  const [companyData, setCompanyData] = useState({
    name: modalData.item.name,
    plan_type: modalData.item.plan_type,
    enable_billing_page: modalData.item.enable_billing_page,
    enable_integrations: modalData.item.enable_integrations,
    enable_managers_page: modalData.item.enable_managers_page,
    owner: modalData.item.owner,
  });

  const [companyDataErrors, setCompanyDataErrors] = useState({
    name: '',
    plan_type: '',
    enable_billing_page: '',
    enable_integrations: '',
    owner: '',
    enable_managers_page: '',
  });

  const permission = [
    {
      id: 0,
      label: formatMessage('IntegrationsFr'),
      checked: companyData.enable_integrations,
      field: 'enable_integrations',
    },
    {
      id: 1,
      label: formatMessage('BillingFr'),
      checked: companyData.enable_billing_page,
      field: 'enable_billing_page',
    },
    {
      id: 2,
      label: formatMessage('Team'),
      checked: companyData.enable_managers_page,
      field: 'enable_managers_page',
    },
  ];

  const handleChangeValue = (e, name) => {
    if (e.target && e.target.value) {
      setCompanyData({ ...companyData, [name]: e.target.value });
      setCompanyDataErrors({ ...companyDataErrors, [name]: '' });
    } else {
      setCompanyData({ ...companyData, [name]: '' });
    }
  };

  const handleUpdateCheck = el => {
    switch (el.id) {
      case 0:
        setCompanyData({ ...companyData, enable_integrations: !el.checked });
        break;
      case 1:
        setCompanyData({ ...companyData, enable_billing_page: !el.checked });
        break;
      case 2:
        setCompanyData({ ...companyData, enable_managers_page: !el.checked });
        break;
    }
  };

  const handleEdit = data => {
    FranchiseCompanyApi.updateCompany(modalData.item.id, data).then(res => {
      dispatch(bcSplitTableActions.updateItemInSplitTable(res.data));
    });
  };

  const handleCreate = () => {
    if (companyData.owner !== modalData.item.owner) {
      FranchiseCompanyApi.modifyCompany(modalData.item.id, {
        action: 'change_owner',
        new_owner: companyData.owner,
      }).then(() => {
        modalData.notification();
      });
    }

    const data = new FormData();
    companyData.name && data.append('name', companyData.name);
    avatar !== modalData.item.avatar && data.append('avatar', avatar);
    // data.append('plan_type', companyData.plan_type);
    // data.append('type', 1);
    // data.append('franchise', modalData.franchise);
    data.append('enable_billing_page', companyData.enable_billing_page);
    data.append('enable_integrations', companyData.enable_integrations);
    data.append('enable_managers_page', companyData.enable_managers_page);
    // data.append('end_subscription', moment().format());
    handleEdit(data);
    modalData.onClose();
  };

  const handleUpdateAvatar = image => {
    setAvatar(image);
  };

  useEffect(() => {
    FranchiseCompanyApi.getFranchiseUsersShort(modalData.franchise).then(res => {
      setUsers(res.data.map(el => ({ value: el.id, label: el.email })));
    });
  }, []);

  return (
    <ModalContainer
      modal={MODALS.editCompanyModal}
      // customStyles={styles}
      onClose={modalData.onClose}
    >
      <div className="edit-company-franchise">
        <div className="edit-company-franchise__header">
          {formatMessage('Edit Company')}
        </div>
        <div className="edit-company-franchise__content">
          <div className="edit-company-franchise__content__edit-form">
            <UploadImage
              defaultImage={avatar}
              children={modalData.adIcon}
              classNameImageContainer="edit-company-franchise__content__edit-form_upload_avatar"
              helperText={formatMessage(
                'The uploaded image must be in JPG, PNG format and should not exceed 5MB in size',
              )}
              onUpdate={handleUpdateAvatar}
            />
          </div>
          <div className="edit-company-franchise__content__field">
            <NewBcField
              name="name"
              value={companyData.name}
              label={formatMessage('Company name')}
              placeholder={formatMessage('Company Name')}
              onChange={handleChangeValue}
            />
          </div>
          <div className="edit-company-franchise__content__field">
            <span className="edit-company-franchise__content__field_label">
              {formatMessage('Owner')}
            </span>
            <BcSelect
              value={companyData.owner.email}
              outlined="none"
              color="white"
              placeholder={
                <div className="select-placeholder-text">{companyData.owner.email}</div>
              }
              options={users}
              onChange={value => setCompanyData({ ...companyData, owner: value.value })}
              customStyle={customStyles()}
            />
          </div>
          <div className="edit-company-franchise__content__field">
            <span className="edit-company-franchise__content__field_label">
              {formatMessage('Settings page functionality')}
            </span>
            <div className="edit-company-franchise__content__field_checkbox">
              {permission.map(el => (
                <div className="edit-company-franchise__content__field_checkbox_item">
                  <CheckBox checked={el.checked} onChange={() => handleUpdateCheck(el)} />
                  <span className="edit-company-franchise__content__field_checkbox_item_text">
                    {el.label}
                  </span>
                </div>
              ))}
            </div>
          </div>
          <div className="create-company-franchise__content__button">
            <Button
              color="green"
              width="100%"
              height="56px"
              customClass="create-company-franchise__content__button_text"
              onClick={() => handleCreate(modalData.item.id)}
            >
              {formatMessage('Save')}
            </Button>
          </div>
        </div>
      </div>
    </ModalContainer>
  );
};
