import React, { useState } from 'react';

import { MODALS } from '@components/containers/Modal/modalConstants';
import { getModalDataSelector } from '@components/containers/Modal/modalSelectors';
import { ModalContainer } from '@components/containers/Modal/ModalContainer';
import { useSelector } from 'react-redux';
import { useInternationalization } from '@hooks/useTranslationHook';
import { NewBcField } from '@components/common/fields/newBcField/newBcField';
import { Button } from '@components/common/Button';
import { UploadImage } from '@components/common/UploadImage/UploadImage';
import { ClearingFieldIcon } from '@icons/clearing-field-icon';
import { FranchiseCompanyApi } from '@api/franchiseCompanyApi';

import './editFranchiseAvatarModal.scss';

export const EditFranchiseAvatarModal = () => {
  const { modalData } = useSelector(getModalDataSelector);
  const { formatMessage } = useInternationalization();
  const [avatarUpdated, setAvatarUpdated] = useState(false);
  const [companyName, setCompanyName] = useState({
    company_name: modalData.companyName,
  });
  const [companyNameError, setCompanyNameError] = useState({
    company_name: '',
  });
  const [companyAvatar, setCompanyAvatar] = useState(modalData.avatar);
  const [isLoading, setLoading] = useState(false);

  const onClose = () => {
    modalData.onClose();
  };

  const updateObj = new FormData();

  const handleChangeCompanyName = (e, name) => {
    if (e.target && e.target.value) {
      setCompanyName({ ...companyName, [name]: e.target.value });
      setCompanyNameError({ ...companyNameError, [name]: '' });
    } else {
      setCompanyName({ ...companyName, [name]: '' });
    }
  };

  const handleUpdateAvatar = image => {
    setCompanyAvatar(image);
    setAvatarUpdated(true);
  };

  const handleUpdateCompany = () => {
    setLoading(true);

    if (avatarUpdated === true) {
      updateObj.append('name', companyName.company_name);
      // updateObj.append('logo_navigation', companyAvatar);
      companyAvatar
        ? updateObj.append('logo_navigation', companyAvatar)
        : updateObj.append('logo_navigation', '');
      FranchiseCompanyApi.setFranchiseSettings(modalData.id, updateObj).then(res => {
        modalData.setCompanyName(res.data.name);
        modalData.setCompanyAvatar(res.data.logo_navigation);
      });
      setLoading(false);
      onClose();
    } else {
      updateObj.append('name', companyName.company_name);
      FranchiseCompanyApi.setFranchiseSettings(modalData.id, updateObj).then(res => {
        modalData.setCompanyName(res.data.name);
      });
      setLoading(false);
      onClose();
    }
  };

  return (
    <ModalContainer modal={MODALS.openEditFranchiseAvatarModal} onClose={onClose}>
      <div className="franchise-avatar-container">
        <div className="franchise-avatar-container_title-container">
          <span className="franchise-avatar-container_title-container_title">
            {formatMessage('Edit Whitelabel')}
          </span>
        </div>
        <div className="franchise-avatar-container__edit-form">
          <UploadImage
            defaultImage={companyAvatar}
            children={modalData.children}
            onUpdate={handleUpdateAvatar}
            classNameImageContainer="franchise-avatar-container__edit-form_upload_avatar"
            helperText={formatMessage(
              'The uploaded image must be in JPG, PNG format and should not exceed 5MB in size',
            )}
          />
        </div>
        <div className="franchise-avatar-container__name_field">
          <NewBcField
            name="company_name"
            label={formatMessage('Whitelabel Name')}
            value={companyName.company_name}
            onChange={handleChangeCompanyName}
            Icon={<ClearingFieldIcon />}
          />
        </div>
        <div className="franchise-avatar-container__button">
          <Button
            width="100%"
            height="56px"
            customClass="franchise-avatar-container__button_text"
            onClick={handleUpdateCompany}
            disabled={companyName.company_name === '' && !companyAvatar}
          >
            {formatMessage('Save')}
          </Button>
        </div>
      </div>
    </ModalContainer>
  );
};
