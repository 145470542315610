import React from 'react';
import { Routes } from '../../const/routes.js';
import {
  DashboardIcon,
  AudienceIcon,
  AutomationIcon,
  BroadcastIcon,
  CampaignIcon,
  ChatsIcon,
  FlowIcon,
  TemplateIcon,
  SettingIcon,
} from '../../wabb-assets/svg/NavigationMenu/index.ts';
import { IPageItem } from './types.ts';
import { VITE_IS_DEV } from '../../app/config/index.js'

export const pagesItems: IPageItem[] = [
  {
    id: '1',
    label: 'Dashboard',
    icon: DashboardIcon,
    path: Routes.DashboardRoute,
    permission: '',
    show: false,
  },
  {
    id: '2',
    label: 'Contacts',
    icon: AudienceIcon,
    path: Routes.AudienceRoute,
    permission: '',
    show: true,
  },
  {
    id: '3',
    label: 'Campaigns',
    icon: CampaignIcon,
    path: Routes.CampaignsRoute,
    permission: '',
    show: true,
  },
  {
    id: '4',
    label: 'Broadcasts',
    icon: BroadcastIcon,
    path: Routes.BroadcastRoute,
    permission: '',
    show: true,
  },
  {
    id: '5',
    label: 'Chats',
    icon: ChatsIcon,
    path: Routes.LiveChatRoute,
    permission: '',
    show: true,
  },
  {
    id: '6',
    label: 'Flows',
    icon: FlowIcon,
    path: Routes.FlowsRoute,
    permission: '',
    show: true,
  },
  {
    id: '7',
    label: 'Automation',
    icon: AutomationIcon,
    path: Routes.AutomationRoute,
    permission: '',
    show: true,
  },
  {
    id: '8',
    label: 'Templates',
    icon: TemplateIcon,
    path: Routes.TemplatesRoute,
    permission: '',
    show: false,
  },
  {
    id: '9',
    label: 'Settings',
    icon: SettingIcon,
    path: Routes.SettingsRoute,
    permission: '',
    show: true,
  },
];
