import moment from 'moment';

export const CustomFieldsTypes = [
  {
    value: 0,
    label: 'Text',
  },
  {
    value: 1,
    label: 'Number',
  },
  {
    value: 2,
    label: 'Date',
  },
  {
    value: 3,
    label: 'Date & Time',
  },
];

export const transformFieldsData = customFields =>
  customFields.map(
    ({
      id,
      key,
      description,
      type,
      valueString,
      valueFloat,
      dateValue,
      dateTimeValue,
    }) => {
      let value = '';

      switch (type) {
        case 0:
          value = valueString;
          break;
        case 1:
          value = valueFloat || 0;
          break;
        case 2:
          value = dateValue ? moment(dateValue).format('YYYY-MM-DD') : '';
          break;
        case 3:
          value = dateTimeValue
            ? moment(dateTimeValue).format('YYYY-MM-DD HH:mm')
            : '';
          break;
        default:
          value = '';
      }
      return {
        id,
        name: key,
        description,
        value,
        type: CustomFieldsTypes[type].label,
      };
    },
  );
