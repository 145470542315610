import React, { FC } from 'react';

import { useInternationalization } from '@hooks/useTranslationHook';
import Button from '@wabb-ui-kit/Button/Button.tsx';
import { PlusIcon } from '@icons/audience/plus-icon';
import { InfoBrandIcon } from '@icons/info-brand-icon.jsx';
import { EButtonSize, EIconPosition } from '@wabb-ui-kit/Button/types/types.ts';

import styles from './EmptyPageWrapper.module.scss';

interface IEmptyPageProps {
  title: string;
  nameToCreate: string;
  handleCreateNewItem: () => void;
}

const EmptyPageWrapper: FC<IEmptyPageProps> = ({ handleCreateNewItem, title , nameToCreate }) => {
  const { formatMessage } = useInternationalization();

  return (
    <div className={styles.emptyPageWrapper}>
      <div className={styles.emptyPageIcon}>
        <InfoBrandIcon />
      </div>
      <span className={styles.emptyPageTitle}>{formatMessage(`No ${title} added yet`)}</span>
      <span className={styles.emptyPageDescription}>
        {formatMessage(`Click 'create new ${nameToCreate}' to get started`)}
      </span>
      <Button
        onClick={handleCreateNewItem}
        type="primary"
        size={EButtonSize.L}
        icon={<PlusIcon color="#FFFFFF" />}
        iconPosition={EIconPosition.RIGHT}
      >
        Create new {nameToCreate}
      </Button>
    </div>
  );
};

export default EmptyPageWrapper;
