import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
// import ReactJson from 'react-json-view';
import { variablesActions } from '@redux/variables/variablesActions';
import { getSelectedCompanySelector } from '@redux/companies/companiesSelectors';
import Popover from '@mui/material/Popover';
import { FieldEditor } from './FieldEditor';

import './jsonPreview.scss';

export const JsonPreview = ({ form, setForm }) => {
  const dispatch = useDispatch();
  const jsonPreviewEl = useRef(null);
  const [currentField, setCurrentField] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const [isOpenPopover, setIsOpenPopover] = useState(false);
  const [jsonConfig, setJsonConfig] = useState({
    ...form.body,
  });
  const [jsonConfigField, setJsonConfigField] = useState({
    key: null,
    value: '',
    nameSpace: [],
    styledText: null,
  });
  const companyBot = useSelector(getSelectedCompanySelector).bot;
  const [isOpenEditMenu, setIsOpenEditMenu] = useState(false);

  useEffect(() => {
    companyBot && dispatch(variablesActions.getVariablesRequest({ companyBot }));
    companyBot && dispatch(variablesActions.getBotVariablesRequest({ companyBot }));
  }, [dispatch, companyBot]);

  const onUpdate = e => {
    setJsonConfig({ ...e.updated_src });
    setForm({ ...form, body: { ...e.updated_src } });
    setAnchorEl(null);
  };

  const onSelectJsonValue = item => {
    setJsonConfigField({
      ...jsonConfigField,
      key: item.name,
      value: item.value,
      nameSpace: item.namespace,
    });
    setIsOpenEditMenu(true);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setIsOpenEditMenu(false);
  };

  useEffect(() => {
    if (Object.keys(jsonConfig).length !== 0) {
      setIsOpenPopover(true);
    } else {
      setIsOpenPopover(false);
      setAnchorEl(null);
    }
  }, [jsonConfig]);

  useEffect(() => {
    setAnchorEl(null);
  }, [jsonConfig]);

  return (
    <div className="json-preview">
      <div className="view" onClick={event => isOpenPopover && setAnchorEl(event.target)}>
        {/* <ReactJson
          ref={jsonPreviewEl}
          src={jsonConfig}
          displayObjectSize={false}
          displayDataTypes={false}
          enableClipboard={false}
          onDelete={isOpenEditMenu ? false : onUpdate}
          onAdd={isOpenEditMenu ? false : onUpdate}
          onSelect={onSelectJsonValue}
        /> */}
      </div>
      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        PaperProps={{
          style: {
            width: '300px',
            marginTop: '10px',
            border: '1px solid #D9DFE3',
            borderRadius: '10px',
            padding: '5px',
            background: '#FFFFFF',
            boxShadow: '0 5px 15px rgba(0, 0, 0, 0.05)',
          },
        }}
      >
        <FieldEditor
          jsonConfig={jsonConfig}
          setJsonConfig={setJsonConfig}
          jsonConfigField={jsonConfigField}
          setJsonConfigField={setJsonConfigField}
          isOpenEditMenu={isOpenEditMenu}
          setIsOpenEditMenu={setIsOpenEditMenu}
          setForm={setForm}
          form={form}
          handleClose={handleClose}
        />
      </Popover>
    </div>
  );
};
