import React, { useEffect, useState, useRef } from 'react';
import { useSelector } from 'react-redux';
import cn from 'classnames';
import { useInternationalization } from '@hooks/useTranslationHook';
import { getPermissionsSelectedCompanySelector } from '@redux/companies/companiesSelectors';
import Button from '@wabb-ui-kit/Button/Button';
import { EButtonSize, EIconPosition } from '@wabb-ui-kit/Button/types/types';
import { ArrowIcon } from '@wabb-assets/svg/Button/arrow-icon';
import TagsDropdown from '@wabb-ui-kit/TagsDropdown/TagsDropdown';

import './UserCardInfoPanelForAssign.scss';

export const UserCardInfoPanelForAssign = ({
  data,
  updateUserCard,
  ownerCompanyId,
  managers,
  isContacts,
}) => {
  const { formatMessage } = useInternationalization();
  const [isOpen, setIsOpen] = useState(false);
  const [assignedToMe, setAssignedToMe] = useState(false);
  const [assignedToManager, setAssignedToManager] = useState(null);
  const { assignChat } = useSelector(getPermissionsSelectedCompanySelector);

  useEffect(() => {
    setAssignedToManager(managers.find(manager => manager.user === data.assignedTo));
  }, [managers, data]);

  useEffect(() => {
    setAssignedToMe(data.assignedTo === ownerCompanyId);
  }, [data?.assignedTo, ownerCompanyId]);

  const assignUserChatToMe = () => {
    if (data.assignedTo === ownerCompanyId) {
      updateUserCard({ assigned_to: null, assigned_to_type: 0 }, data?.id).then(() => {
        setAssignedToMe(false);
        setAssignedToManager(null);
      });
    } else {
      updateUserCard({ assigned_to: ownerCompanyId, assigned_to_type: 0 }, data?.id).then(
        () => {
          setAssignedToMe(true);
          setAssignedToManager(null);
        },
      );
    }
  };

  const assignUserChatToManager = manager => {
    updateUserCard({ assigned_to: manager.user, assigned_to_type: 0 }, data?.id).then(
      () => {
        if (data.assignedTo === ownerCompanyId) {
          return;
        }

        if (manager.id === null) {
          setAssignedToManager(null);
        } else {
          setAssignedToManager(manager);
        }

        setAssignedToMe(false);
        setIsOpen(false);
      },
    );
  };

  const handleDropdownToggle = event => {
    event.stopPropagation();
    setIsOpen(prevState => !prevState);
  };
  return (
    <div
      className={cn('user-card-info-for-assign__container', {
        'user-card-info-for-assign__container--contacts': isContacts,
      })}
    >
      <div className="user-card-info-for-assign__title">
        Assigned to{' '}
        {assignedToMe && <span className="user-card-info-for-assign__title-me">Me</span>}
        {assignedToManager && !assignedToMe ? (
          <span className="user-card-info-for-assign__button_admin-name">
            {assignedToManager.fullName}
          </span>
        ) : null}
      </div>
      <div className="user-card-info-for-assign__buttons">
        {managers.length > 0 && assignChat === 0 && (
          <>
            <Button
              type="secondary"
              style="outlined"
              transparent
              onClick={handleDropdownToggle}
              customClass="user-card-info-for-assign__button"
              icon={<ArrowIcon rotate={isOpen ? 180 : 0} color="#202F45" />}
              size={EButtonSize.S}
              iconPosition={EIconPosition.RIGHT}
            >
              {assignedToManager ? <div>Change</div> : <div>Assign to</div>}
            </Button>
            {isOpen && (
              <div
                className={cn('user-card-info-for-assign__dropdown', {
                  'user-card-info-for-assign__dropdown-contacts': isContacts,
                })}
                onClick={e => e.stopPropagation()}
              >
                <TagsDropdown
                  options={managers}
                  isOpen={isOpen}
                  setIsOpen={setIsOpen}
                  handleChangeItem={assignUserChatToManager}
                  labelName="fullName"
                  idName="id"
                />
              </div>
            )}
          </>
        )}
        {(assignChat === 0 || assignChat === 1) && (
          <Button
            type={assignedToMe ? 'negative' : 'primary'}
            // width={assignedToMe ? '95px' : '91px'}
            width={assignedToMe ? '100%' : '100%'}
            style="outlined"
            onClick={assignUserChatToMe}
            color="blue"
            transparent={assignedToMe}
            size={EButtonSize.S}
          >
            {assignedToMe ? (
              <span>{formatMessage('Unassign me')}</span>
            ) : (
              <span>{formatMessage('Assign me')}</span>
            )}
          </Button>
        )}
      </div>
    </div>
  );
};
