import { GET_BOT_VARIABLES_SUCCESS } from '../variables/variablesConstants';
import {
  CREATE_INTEGRATION_EVENT_ERROR,
  CREATE_INTEGRATION_EVENT_REQUEST,
  GET_INTEGRATION_EVENT_REQUEST,
  GET_INTEGRATION_EVENT_SUCCESS,
} from './integrationEventsConstants';

export const integrationEventsActions = {
  createIntegrationEventRequest(data) {
    return {
      type: CREATE_INTEGRATION_EVENT_REQUEST,
      payload: data,
    };
  },

  createIntegrationEventError(data) {
    return {
      type: CREATE_INTEGRATION_EVENT_ERROR,
      payload: data,
    };
  },

  getIntegrationEventRequest(data) {
    return {
      type: GET_INTEGRATION_EVENT_REQUEST,
      payload: data,
    };
  },

  getIntegrationEventSuccess(data) {
    return {
      type: GET_INTEGRATION_EVENT_SUCCESS,
      payload: data,
    };
  },
};
