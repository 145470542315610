import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import './ManagerItem.scss';

import { bcSplitTableActions } from '@redux/bcSplitTable/bcSplitTableActions';
import { modalActions } from '@components/containers/Modal/modalActions';
import { useInternationalization } from '@hooks/useTranslationHook';
import { usersApi } from '@api/usersApi';
import { WabbSwitcherPopover } from '@wabb-ui-kit/WabbSwitcherPopover/WabbSwitcherPopover';
import CheckBox from '@wabb-ui-kit/CheckBox/CheckBox';
import { columns } from '../ManagersSettings.jsx';
import { ArrowIcon } from '../../../../../wabb-assets/svg/DatePicker/arrow-icon';
import { Popover } from '../../../../../ui-kit/components/Popover/Popover';
import { AudienceUserTableOptionsIcon } from '../../../../../icons/audience/audience-user-table-options-icon';

export const ManagerItem = React.memo(
  ({
    item, deleteItemFunction, children, ...props
  }) => {
    const [isLiveChatPopoverOpen, setIsLiveChatPopoverOpen] = useState(false);
    const [isSettingsPopoverOpen, setIsSettingsPopoverOpen] = useState(false);
    const [isLiveChatOnChange, setIsLiveChatOnChange] = useState(false);

    const [liveChatItems, setLiveChatItems] = useState([]);
    const [settingsItems, setSettingsItems] = useState([]);
    const dispatch = useDispatch();
    const { formatMessage } = useInternationalization();

    useEffect(() => {
      setSettingsItems([
        {
          name: 'Can invite team members',
          field: 'addingNewManagers',
          isChecked: item.addingNewManagers,
          isDisabled: false,
        },
      ]);
      setLiveChatItems([
        {
          name: 'Access to assigned to me chats',
          field: 'liveChatMyBusy',
          isChecked: item.liveChatMyBusy,
          isDisabled: false,
        },
        {
          name: 'Access to assigned to others chats',
          field: 'liveChatAll',
          isChecked: item.liveChatAll,
          isDisabled: false,
        },
        {
          name: 'Access to unassigned chats',
          field: 'liveChatAllBusy',
          isChecked: item.liveChatAllBusy,
          isDisabled: false,
        },
        {
          name: 'Can assign chats himself',
          field: 'assignHimself',
          isChecked: item.assignChat === 0 ? true : item.assignChat === 1,
          isDisabled: false,
        },
        {
          name: 'Can assign chats to other users',
          field: 'assignOther',
          isChecked: item.assignChat === 0,
          isDisabled: !(item.assignChat === 0 || item.assignChat === 1),
        },
      ]);
    }, [item]);

    useEffect(() => {
      if (isLiveChatOnChange) {
        setLiveChatItems((prevItems) => prevItems.map((item) => {
          if (item.field === 'assignOther') {
            const isAssignHimselfChecked = prevItems.find(
              (i) => i.field === 'assignHimself'
            ).isChecked;
            return {
              ...item,
              isChecked: isAssignHimselfChecked ? item.isChecked : false,
              isDisabled: !isAssignHimselfChecked,
            };
          }

          return item;
        }));
        setIsLiveChatOnChange(false);
      }
    }, [isLiveChatOnChange]);

    const handleLiveChatPopoverClose = () => {
      setIsLiveChatPopoverOpen(false);
    };

    const handleSettingsPopoverClose = () => {
      setIsSettingsPopoverOpen(false);
    };

    const handleDeleteItem = () => {
      dispatch(
        modalActions.openConfirmModal({
          title: formatMessage('Delete team member'),
          text: <span>
            Are you sure you want to delete tag&nbsp;
            <span style={{ color: '#101828', fontWeight: 500 }}>
              ”
              { item.fullName }
              ”
            </span>
            ?
          </span>,
          confirmButtonText: formatMessage('Delete'),
          type: 'delete',
          handleConfirm: async () => {
            await usersApi.deleteActiveUser(item.id);
            dispatch(bcSplitTableActions.deleteItemSuccess(item.id));
            dispatch(modalActions.closeModal());
          },
          onClose: () => {
            dispatch(modalActions.closeModal());
          },
        }),
      );
    };

    const updateLiveChatPermissions = async () => {
      const {
        liveChatMyBusy, liveChatAll, liveChatAllBusy, assignHimself, assignOther,
      } = liveChatItems.reduce((acc, item) => {
        acc[item.field] = item.isChecked;

        return acc;
      }, {});

      let assignChat = 2;

      if (assignHimself && assignOther) {
        assignChat = 0;
      }

      if (assignHimself && !assignOther) {
        assignChat = 1;
      }

      const res = await usersApi.updateActiveUser({
        pk: item.id,
        assignChat,
        liveChatMyBusy,
        liveChatAll,
        liveChatAllBusy,
      });
      dispatch(bcSplitTableActions.updateItemInSplitTable(res));
      handleLiveChatPopoverClose();
    };

    const handleChange = async (value, field) => {
      const res = await usersApi.updateActiveUser({
        pk: item.id,
        [field]: value,
      });
      dispatch(bcSplitTableActions.updateItemInSplitTable(res));
    };

    const handleSettingsChange = async (isChecked) => {
      const res = await usersApi.updateActiveUser({
        pk: item.id,
        addingNewManagers: isChecked,
      });
      dispatch(bcSplitTableActions.updateItemInSplitTable(res));
      handleSettingsPopoverClose();
    };

    return (
      <div {...props}>
        { children }
        <div className="account-info">
          { item.fullName && <div className="account-info__name">{ item.fullName }</div> }
          <div className="account-info__email">{ item?.email }</div>
        </div>
        { [...columns.slice(1)].map(({ field }, index) => (
          <div
            key={`${index + Math.random() * 1000}`}
            className="managers-item-cell"
          >
            <CheckBox
              type="checkbox"
              isChecked={item[field]}
              onChange={() => handleChange(!item[field], field)}
            />
            { field === 'liveChat' && item[field] && (
              <WabbSwitcherPopover
                renderTrigger={() => (
                  <div className="managers-item-cell__button">
                    <ArrowIcon rotate={90} />
                  </div>
                )}
                width={305}
                isOpen={isLiveChatPopoverOpen}
                setIsOpen={setIsLiveChatPopoverOpen}
                onChange={({ field, isChecked }) => {
                  setIsLiveChatOnChange(true);
                  setLiveChatItems((prevItems) => prevItems.map((it) => (it.field === field
                    ? { ...it, isChecked } : it)));
                }}
                onClose={handleLiveChatPopoverClose}
                onClick={updateLiveChatPermissions}
                items={liveChatItems}
              />
            ) }
            { field === 'settings' && item[field] && (
              <WabbSwitcherPopover
                renderTrigger={() => (
                  <div className="managers-item-cell__button">
                    <ArrowIcon rotate={90} />
                  </div>
                )}
                width={237}
                isOpen={isSettingsPopoverOpen}
                setIsOpen={setIsSettingsPopoverOpen}
                onChange={({ isChecked }) => handleSettingsChange(isChecked)}
                onClose={handleSettingsPopoverClose}
                items={settingsItems}
              />
            ) }
          </div>
        )) }
        <div>
          <Popover
            renderTrigger={() => (
              <div className="managers-item-cell__button">
                <AudienceUserTableOptionsIcon />
              </div>
            )}
            size={{ width: 'auto' }}
            placement="bottom-start"
            offset={8}
          >
            <div className="managers-item-cell__popover">
              <div className="managers-item-cell__popover__delete" onClick={handleDeleteItem}>
                { formatMessage('Delete') }
              </div>
            </div>
          </Popover>
        </div>
      </div>
    );
  },
);
