import { all, takeLatest, put } from 'redux-saga/effects';
import { audienceApi } from '../../api/audience';
import { broadcastActions } from './broadcastActions';
import {
  GET_BROADCAST_USERS_REQUEST,
  GET_BROADCAST_USERS_NEXT_REQUEST,
} from './broadcastActionTypes';

function* getUsersSaga(action) {
  const {
    payload: { botId, cursor, queryString },
  } = action;
  const  data= yield audienceApi.getAudienceUsers(botId, cursor, queryString);
  const   countData  = yield audienceApi.getAudienceCount(botId, queryString);

  if (data) {
    yield put(broadcastActions.getUsersSuccess({ ...data, count: countData.count }));
  }
}

function* getNextPageOfUsersRequestSaga(action) {
  const { payload } = action;

  const response = yield audienceApi.getAudienceUsers(
    payload.botId,
    payload.cursor,
    payload.queryString,
  );

  if (response) {
    yield put(broadcastActions.getNextPageOfUsersSuccess(response));
  }
}

function* sagas() {
  yield all([
    takeLatest(GET_BROADCAST_USERS_REQUEST, getUsersSaga),
    takeLatest(GET_BROADCAST_USERS_NEXT_REQUEST, getNextPageOfUsersRequestSaga),
  ]);
}

export const broadcastSagas = sagas;
