import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getSelectedCompanySelector } from '@redux/companies/companiesSelectors';
import { getVariablesSelector } from '@redux/variables/variablesSelector';

import { useInternationalization } from '@hooks/useTranslationHook';
import Button from '@wabb-ui-kit/Button/Button';
import FormField from '@wabb-ui-kit/FormField/FormField';
import { EComponentType } from '@wabb-ui-kit/FormField/types/types';
import { EButtonSize, EIconPosition } from '@wabb-ui-kit/Button/types/types';
import { BcSelect } from '@components/common/Select';
import { RubbishIcon } from '@icons/constructor/rubbish-icon';
import { PlusConstEditPannel } from '@icons/constructor/index';
import { variablesApi } from '../../../../../api/variablesApi.js';
import './responseMapping.scss';

export const ResponseMapping = ({
  fields,
  handleDeleteField,
  handleAddKeysVariables,
  handleChange,
  setForm,
}) => {
  const dispatch = useDispatch();
  const { formatMessage } = useInternationalization();
  const [userData, setUserDatas] = useState([]);
  const [botData, setBotDatas] = useState([]);

  const companyBot = useSelector(getSelectedCompanySelector).bot;
  const userVariablesData = useSelector(getVariablesSelector).variables;
  const botVariablesData = useSelector(getVariablesSelector).botVariables;

  const fetchNextVariables = async () => {
    try {
      const res = companyBot && (await variablesApi.getAllUserVariables(companyBot));
      setUserDatas(res.variables);
    } catch (error) {
      console.log(error);
    }
  };

  const fetchBotVariables = async () => {
    try {
      const res = companyBot && (await variablesApi.getAllBotVariables(companyBot));
      setBotDatas(res.variables);
    } catch (error) {
      console.log(error);
    }
  };

  const userDataModified = userData.map((element) => ({
    label: element.key,
    value: element.id,
    name: 'variable_type_from',
    type: element.type,
  }));
  const botDataModified = botData.map((element) => ({
    label: element.key,
    value: element.id,
    name: 'bot_variable_from',
    type: element.type,
  }));

  const groupedOptions = [
    {
      label: formatMessage('User variables'),
      options: userDataModified,
    },
    {
      label: formatMessage('Bot variables'),
      options: botDataModified,
    },
  ];

  useEffect(() => {
    fetchNextVariables();
  }, [dispatch, companyBot, userVariablesData]);

  useEffect(() => {
    fetchBotVariables();
  }, [dispatch, companyBot, botVariablesData]);

  return (
    <div className="json-editor">
      {fields.length === 0 ? (
        <div className="json-editor__empty-block">
          <div className="json-editor__empty-block_title">
            {formatMessage('Define how you want to map your request response')}
          </div>
          <Button
            type="primary"
            style="link"
            size={EButtonSize.L}
            width={210}
            onClick={handleAddKeysVariables}
            icon={<PlusConstEditPannel color="#5026D9" />}
            iconPosition={EIconPosition.LEFT}
          >
            { formatMessage('Add mapping field') }
          </Button>
        </div>
      ) : (
        <>
          <div className="json-editor__bottom-button">
            <Button
              type="primary"
              style="link"
              size={EButtonSize.L}
              width={210}
              onClick={handleAddKeysVariables}
              icon={<PlusConstEditPannel color="#5026D9" />}
              iconPosition={EIconPosition.LEFT}
            >
              { formatMessage('Add mapping field') }
            </Button>
          </div>
          <div className="json-editor__fields">
            {fields
            && fields.length > 0
            && fields.map((item, index) => (
              <div key={index} className="json-editor__fields__row">
                <div style={{ width: '100%' }}>
                  <FormField
                    Component={EComponentType.Input}
                    placeholder="Enter JSONPath"
                    value={item.value || ''}
                    onChange={(event) => handleChange(item.key_variable_id, 'field', event)}
                    onCrossClick={() => setForm(((prev) => {
                      const keys_variables = prev.keys_variables.map((item, ind) => {
                        if (index === ind) {
                          return {
                            ...item,
                            value: '',
                          };
                        }

                        return item;
                      });
                      return {
                        ...prev,
                        keys_variables,
                      };
                    }))}
                  />
                </div>
                <div style={{ width: '100%' }}>
                  <BcSelect
                    value={item?.value ? item : undefined}
                    options={groupedOptions}
                    placeholder={(
                      <div className="selector-placeholder">
                        { formatMessage('Select custom field') }
                      </div>
                    )}
                    outlined="none"
                    onChange={(event) => handleChange(item.key_variable_id, 'select', event)}
                  />
                </div>
                <button
                  type="button"
                  onClick={() => handleDeleteField('keys_variables', item.key_variable_id)}
                >
                  <RubbishIcon />
                </button>
              </div>
            ))}
          </div>
        </>
      )}
    </div>
  );
};
