import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { useInternationalization } from '@hooks/useTranslationHook';
import {
  getAvatarCompanySelector,
  getIdCompanySelector,
  getNameCompanySelector,
} from '@redux/companies/companiesSelectors';
import { CompanyIcon } from '@icons';
import { Button } from '@components/common/Button';
import { MODALS } from '../../containers/Modal/modalConstants';
import { ModalContainer } from '../../containers/Modal/ModalContainer';
import { getModalDataSelector } from '../../containers/Modal/modalSelectors';
import { NewBcField } from '../../common/fields/newBcField/newBcField';
import { UploadImage } from '../../common/UploadImage/UploadImage';
import { settingService } from '../../../services';

import './EditingCompanyModal.scss';

const styles = {
  width: '28%',
  maxWidth: '450px',
  minWidth: '350px',
};

export const EditingCompanyModal = () => {
  const { formatMessage } = useInternationalization();
  const dispatch = useDispatch();
  const [companyNameState, setCompanyNameState] = useState({ value: '', error: '' });
  const [companyLogoState, setCompanyLogoState] = useState('');
  const [isLogoUpdated, seIsLogoUpdated] = useState(false);
  const [isLoading, setLoading] = useState(false);

  const { modalData } = useSelector(getModalDataSelector);
  const companyName = useSelector(getNameCompanySelector);
  const logoCompany = useSelector(getAvatarCompanySelector);
  const companyId = useSelector(getIdCompanySelector);

  useEffect(() => {
    setCompanyNameState({ ...companyNameState, value: companyName });
  }, [companyName]);

  const updateCompanyName = e => {
    setCompanyNameState({
      value: e.target.value,
      error: !e.target.value.trim() ? formatMessage('Invalid name format') : '',
    });
  };

  const disableBtn = () =>
    !!(
      !isLogoUpdated &&
      (companyName === companyNameState.value || companyNameState.error)
    );

  const onUpdateClick = () => {
    setLoading(true);
    settingService
      .updateCompanyData(
        companyId,
        {
          logo: companyLogoState,
          name: companyNameState.value,
        },
        dispatch,
        isLogoUpdated && !companyLogoState,
      )
      .then(() => modalData.onClose())
      .finally(() => setLoading(false));
  };

  const handleUpdateLogo = image => {
    setCompanyLogoState(image);
    seIsLogoUpdated(true);
  };

  return (
    <ModalContainer
      customStyles={styles}
      modal={MODALS.editingCompany}
      onClose={modalData.onClose}
    >
      <div className="editing-company-container">
        <h4 className="editing-company-container_label">
          {formatMessage('Edit Company')}
        </h4>
        <div className="editing-company-container__edit-info">
          <UploadImage
            classNameImageContainer="editing-company-container__edit-info_upload"
            defaultImage={logoCompany}
            onUpdate={handleUpdateLogo}
            helperText={formatMessage(
              'Image format must be JPG or PNG and shouldn’t exceed 5MB.',
            )}
          >
            <CompanyIcon color="black" width="40" height="40" />
          </UploadImage>
          <NewBcField
            value={companyNameState.value}
            label={formatMessage('Company name')}
            onChange={updateCompanyName}
            helperText={companyNameState.error}
          />
          <Button
            isLoading={isLoading}
            disabled={disableBtn()}
            onClick={onUpdateClick}
            color="green"
            width="100%"
            height="50"
            customClass="editing-company-container__edit-info_btn"
          >
            {formatMessage('Save')}
          </Button>
        </div>
      </div>
    </ModalContainer>
  );
};
