import api from './baseApi';

import { foldersApiRPC } from '@connectApi/baseApi';
// import { FolderDetailRequest } from 'wabb-backend-api/build/es/folders/folders_pb';

import {
  FolderDetailPatchRequest,
  FolderListRequest,
  FolderCreateRequest
} from 'wabb-backend-api/build/es/folders/folders_pb';

export const foldersApi = {
  getFolders: (bot_id, page, currentFolder) => {
    return foldersApiRPC.folderList(
      FolderListRequest.fromJson(currentFolder ? {
        bot_id: bot_id,
        page,
        folderId: currentFolder,
      } : {
        bot_id: bot_id,
        page,
      }),
    );
  },

  getFolderInfo: folderId => api.get(`/folders/${folderId}`),

  createFolder: (data) => foldersApiRPC.folderCreate(FolderCreateRequest.fromJson(data)),

  renameFolder: (folderId, name) =>
    foldersApiRPC.folderDetailPatch(
      FolderDetailPatchRequest.fromJson({
        name,
        pk: folderId,
      }),
    ),

  deleteFolder: folderId => foldersApiRPC.folderDetailDelete({ pk: folderId }),

  moveToFolder: (folderId, data) => foldersApiRPC.folderDetailPatch({ ...data, pk: folderId }),
};
