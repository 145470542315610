import api from './baseApi';
import { fromArrayToObject } from '../helpers/jsonHelper';

export const integrationApi = {
  createIntegrationBlock: (blockId, type, obj) => {
    const objSend = {
      integration_block: blockId,
      type,
      ...obj,
    };

    if (type === 1) {
      objSend.request_type = 0;
    }

    return api.post(
      `/blocks_integration/${type === 0 ? 'zapier_integration' : 'request'}/`,
      objSend,
    );
  },

  updateDynamicRequestBlock: (id, obj) => {
    const objSend = {
      request_type: obj.requestType.value,
      headers: obj.headers,
      ...obj,
    };
    return api.patch(`/blocks_integration/request/${id}/`, objSend);
  },

  setZapierIntegration: (data, eventId) => {
    const { contentId } = data;
    return api.patch(`/blocks_integration/zapier_integration/${contentId}/`, {
      zapier_event: eventId,
    });
  },

  getZapierEvents: bot_id => api.get(`/integrations/zapier/bot_id/events/${bot_id}/`),

  addNewZapierEvent: data => {
    const { integrationEventName, companyBot } = data;
    return api.post('/integrations/zapier/event/', {
      bot: companyBot,
      name: integrationEventName,
    });
  },

  getZapierIntegrationsSettings: bot_id =>
    api.get(`/integrations/zapier/settings/${bot_id}/`),

  setZapierIntegrationsSettings: (bot_id, sendMessageBoolean) =>
    api.patch(`/integrations/zapier/settings/${bot_id}/`, {
      send_message_to_non_contact_list: sendMessageBoolean,
    }),

  getWebhookIntegrationsSettings: bot_id => api.get(`/webhook/api_key/${bot_id}/`),

  getResponse: form => {
    const { requestType, url, headers, body } = form;
    return api.post('/blocks_integration/test_request/', {
      body,
      headers: fromArrayToObject(headers),
      url,
      request_type: requestType.id,
    });
  },
};
