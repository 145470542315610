import React from 'react';
import { useSelector } from 'react-redux';

import { ModalContainer } from '../../containers/Modal/ModalContainer';
import { MODALS } from '../../containers/Modal/modalConstants';
import { getModalDataSelector } from '../../containers/Modal/modalSelectors';
import { useInternationalization } from '../../../hooks/useTranslationHook';

import '../modalsComponent.scss';
import './ChatModal.scss';

const styles = {};

export const ChatModal = () => {
  const { formatMessage } = useInternationalization();

  const { modalData } = useSelector(getModalDataSelector);

  return (
    <ModalContainer modal={MODALS.chat} onClose={modalData.onClose} customStyles={styles}>
      <div className="campaigns-modal">
        <div className="campaigns-modal__header">{formatMessage('Warning')}</div>
        <div
          className="campaigns-modal__form-group"
          style={{ flexDirection: 'column', marginTop: '25px', marginBottom: '50px' }}
        >
          <h2 style={{ margin: '0', fontSize: '22px' }}>Ooooops!</h2>
          <h2 style={{ margin: '0', fontSize: '22px' }}>
            {formatMessage('Chat is not found')}
          </h2>
        </div>
        <div className="campaigns-modal__form-group" />
      </div>
    </ModalContainer>
  );
};
