import React, { FC } from 'react';

import styles from './Switcher.module.scss';

interface ISwitcherProps {
  isChecked: boolean;
  onChange: (value: boolean) => void;
  isDisabled?: boolean;
}

const Switcher: FC<ISwitcherProps> = ({ isChecked, onChange, isDisabled }) => {
  const handleSwitch = (event: React.ChangeEvent<HTMLInputElement>): void => {
    onChange(event.target.checked);
  };

  return (
    <label className={styles.switch}>
      <input
        type="checkbox"
        checked={isChecked}
        onChange={handleSwitch}
        className={styles.switch__input}
        disabled={isDisabled}
      />
      <span className={styles.switch__slider} />
    </label>
  );
};

export default Switcher;
