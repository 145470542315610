import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { useInternationalization } from '@hooks/useTranslationHook';
import { flowsApi } from '@api/flowsApi';
import { variablesApi } from '@api/variablesApi';
import { bcSplitTableActions } from '@redux/bcSplitTable/bcSplitTableActions';
import { getSelectedCompanySelector } from '@redux/companies/companiesSelectors';
import Button from '@wabb-ui-kit/Button/Button';
import { EButtonSize } from '@wabb-ui-kit/Button/types/types';
import { ModalContainer } from '../../containers/Modal/ModalContainer';
import { MODALS } from '../../containers/Modal/modalConstants';
import { getModalDataSelector } from '../../containers/Modal/modalSelectors';
import { FolderItem } from './FolderItem';
import { foldersApi } from '@api';

import './MoveToModal.scss';

export const MoveToModal = () => {
  const { formatMessage } = useInternationalization();
  const { modalData } = useSelector(getModalDataSelector);
  const companyBot = useSelector(getSelectedCompanySelector).bot;

  const dispatch = useDispatch();
  const history = useHistory();

  const currentFolderId = history.location.pathname.split('/')[3]
    ? Number(history.location.pathname.split('/')[3])
    : null;

  const { type, id, folders } = modalData;

  const [selectedFolder, setSelectedFolder] = useState();
  const [isDisabled, setIsDisabled] = useState(false);

  const handleMove = () => {
    if (type === 'flow') {
      flowsApi.moveFlowSource({ flowId: id, folder: selectedFolder.id }).then(() => {
        dispatch(bcSplitTableActions.deleteItemSuccess(id));
        modalData.onClose();
      });
    } else if (type === 'flowFolder') {
      foldersApi.moveToFolder(id, { parentFolder: selectedFolder.id ?? null }).then(() => {
        modalData.onClose();
      });
    } else if (type === 'userField') {
      variablesApi.updateVariableTypes({ folder: selectedFolder.id ,pk:id}).then(() => {
        dispatch(bcSplitTableActions.deleteItemSuccess(id));
        modalData.onClose();
      });
    } else if (type === 'botField') {
      variablesApi.updateVariableBot({ folder: selectedFolder.id ,pk:id}).then(() => {
        dispatch(bcSplitTableActions.deleteItemSuccess(id));
        modalData.onClose();
      });
    } else if (type === 'severalUserField') {
      variablesApi.pluralMoveVariables(
        companyBot,
        modalData.currentFolderId,
        selectedFolder.id,
        modalData.selected_or_unselected_variables,
        modalData.selectedAll,
        modalData.searchName,
        'user'
      ).then((res) => {
        dispatch(bcSplitTableActions.bulkActionDeleteItems(res));
        modalData.onClose();
      });
    } else if (type === 'severalBotField') {
      variablesApi.pluralMoveVariables(
        companyBot,
        modalData.currentFolderId,
        selectedFolder.id,
        modalData.selected_or_unselected_variables,
        modalData.selectedAll,
        modalData.searchName,
        'bot'
      ).then((res) => {
        dispatch(bcSplitTableActions.bulkActionDeleteItems(res));
        modalData.onClose();
      });
    } else {
      flowsApi
        .pluralMoveFlows(
          companyBot,
          modalData.currentFolderId,
          selectedFolder.id,
          modalData.selected_or_unselected_flows,
          modalData.selectedAll,
          modalData.searchName,
        )
        .then((res) => {
          dispatch(bcSplitTableActions.bulkActionDeleteItems(res));
          modalData.onClose();
        });
    }
  };

  const wrappedFolders = {
    id: null,
    name: formatMessage('All'),
    daughterFolders: folders,
  };

  useEffect(() => {
    if (selectedFolder) {
      const depth = selectedFolder.folder_path ? selectedFolder.folder_path.length : 0;
      setIsDisabled(depth >= 3);
    } else {
      setIsDisabled(false);
    }
  }, [selectedFolder]);

  return (
    <div>
      <ModalContainer modal={MODALS.moveTo} onClose={modalData.onClose}>
        <div className="move-to-modal">
          <div className="move-to-modal__body">
            <div className="move-to-modal__header">{formatMessage('Move to')}</div>
            <div className="move-to-modal__body_note">
              {formatMessage('Please select the location where you want to move')}
            </div>
            <div className="move-to-modal__body_folders">
              <ul className="move-to-modal__body_folders_list">
                <FolderItem
                  folder={wrappedFolders}
                  selectedFolder={selectedFolder}
                  setSelectedFolder={setSelectedFolder}
                  sourceFolderId={type === 'flow' ? 0 : id}
                />
              </ul>
            </div>
            <div className="move-to-modal__body_button">
              <Button
                onClick={handleMove}
                disabled={
                  !selectedFolder
                  || currentFolderId === (selectedFolder?.id || null)
                }
                size={EButtonSize.L}
              >
                {formatMessage('Move')}
              </Button>
              <Button
                onClick={modalData.onClose}
                size={EButtonSize.L}
                type="secondary"
                style="tint"
              >
                {formatMessage('Cancel')}
              </Button>
            </div>
          </div>
        </div>
      </ModalContainer>
    </div>
  );
};
