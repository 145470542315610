import { MODAL_ACTION_TYPES } from './modalConstants';

const initialState = {
  modal: null,
  modalData: {},
};

export const modalReducer = (state = initialState, action) => {
  switch (action.type) {
    case MODAL_ACTION_TYPES.OPEN_MODAL:
      const { modal, modalData } = action.payload;

      if (state.modal === null) {
        return { ...state, modal, modalData };
      }

      return state;

    case MODAL_ACTION_TYPES.CLOSE_MODAL:
      return initialState;
    case MODAL_ACTION_TYPES.CLOSE_PERMISSION_MODAL:
      if (state.modalData.text === 'You don`t have permission to this page') {
        return initialState;
      }

      return state;

    default:
      return state;
  }
};
