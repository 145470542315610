import { MODALS } from './modalConstants';

export const modalActions = {

  openContactNotExistModal(modalData) {
    return modalActions.openModal(MODALS.contactNotExist, modalData);
  },

  openDeleteCompany(modalDData) {
    return modalActions.openModal(MODALS.deleteCompany, modalDData);
  },

  openAddCompanyModal(modalData) {
    return modalActions.openModal(MODALS.newCompany, modalData);
  },

  openCustomFieldsModal(modalData) {
    return modalActions.openModal(MODALS.customFields, modalData);
  },

  openInviteUserModal(modalData) {
    return modalActions.openModal(MODALS.inviteUser, modalData);
  },

  openBotsModal(modalData) {
    return modalActions.openModal(MODALS.bots, modalData);
  },

  openTagTypesModal(modalData) {
    return modalActions.openModal(MODALS.tagTypes, modalData);
  },

  openBotsFloderModal(modalData) {
    return modalActions.openModal(MODALS.folders, modalData);
  },

  openCreateGroupCampaignsModal(modalData) {
    return modalActions.openModal(MODALS.groupCampaigns, modalData);
  },

  openPaymentModal(modalData) {
    return modalActions.openModal(MODALS.payment, modalData);
  },

  openAddManagerModal(modalData) {
    return modalActions.openModal(MODALS.manager, modalData);
  },

  openCampaignModal(modalData) {
    return modalActions.openModal(MODALS.campaign, modalData);
  },

  openCampaignQRModal(modalData) {
    return modalActions.openModal(MODALS.campaignQR, modalData);
  },

  openEditProfileModal(modalData) {
    return modalActions.openModal(MODALS.editProfile, modalData);
  },

  openSeqSubModal(modalData) {
    return modalActions.openModal(MODALS.seqSub, modalData);
  },

  openChatModal(modalData) {
    return modalActions.openModal(MODALS.chat, modalData);
  },

  openChatPermissionsModal(modalData) {
    return modalActions.openModal(MODALS.chatPermissions, modalData);
  },

  openSimpleModalError(modalData) {
    return modalActions.openModal(MODALS.simple, modalData);
  },

  openNotPaidModal(modalData) {
    return modalActions.openModal(MODALS.notPaid, modalData);
  },

  openFrozenModal(modalData) {
    return modalActions.openModal(MODALS.frozen, modalData);
  },

  openFirstPromocodeModal(modalData) {
    return modalActions.openModal(MODALS.firstPromocode, modalData);
  },

  openDeleteItemModal(modalData) {
    return modalActions.openModal(MODALS.deleteItem, modalData);
  },

  openShareFlowModal(modalData) {
    return modalActions.openModal(MODALS.shareFlow, modalData);
  },

  openMoveToModal(modalData) {
    return modalActions.openModal(MODALS.moveTo, modalData);
  },

  openShareTemplateModal(modalData) {
    return modalActions.openModal(MODALS.shareTemplate, modalData);
  },

  openSaveFlowModal(modalData) {
    return modalActions.openModal(MODALS.saveFlow, modalData);
  },

  openEmailModal(modalData) {
    return modalActions.openModal(MODALS.email, modalData);
  },

  openIntegrationEventModal(modalData) {
    return modalActions.openModal(MODALS.integrationEvent, modalData);
  },

  openPasswordModal(modalData) {
    return modalActions.openModal(MODALS.password, modalData);
  },

  openAudienceDetailsModal(modalData) {
    return modalActions.openModal(MODALS.userDetails, modalData);
  },

  openUploadUsersModal(modalData) {
    return modalActions.openModal(MODALS.uploadUsers, modalData);
  },

  openDynamicRequestModal(modalData) {
    return modalActions.openModal(MODALS.dynamicRequest, modalData);
  },

  openBroadcastModal(modalData) {
    return modalActions.openModal(MODALS.broadcast, modalData);
  },

  openClearDataModal(modalData) {
    return modalActions.openModal(MODALS.clearData, modalData);
  },

  openCheckHTTPBodyModal(modalData) {
    return modalActions.openModal(MODALS.checkHTTPBody, modalData);
  },

  openChangePasswordModal(modalData) {
    return modalActions.openModal(MODALS.changePassword, modalData);
  },

  openTransferCompanyModal(modalData) {
    return modalActions.openModal(MODALS.transferCompany, modalData);
  },

  openLeaveManagerModal(modalData) {
    return modalActions.openModal(MODALS.leaveManager, modalData);
  },

  openCreateTemplateModal(modalData) {
    return modalActions.openModal(MODALS.createTemplateModal, modalData);
  },

  openCreateFranchiseUserModal(modalData) {
    return modalActions.openModal(MODALS.createFranchiseUser, modalData);
  },

  openCreateFranchiseCompanyModal(modalData) {
    return modalActions.openModal(MODALS.createFranchiseCompany, modalData);
  },

  openEditCompanyModal(modalData) {
    return modalActions.openModal(MODALS.editCompanyModal, modalData);
  },

  openNewOwnerCompanyFranchiseModal(modalData) {
    return modalActions.openModal(MODALS.newOwnerCompanyFranchise, modalData);
  },

  openFinishFranchiseRegistration(modalData) {
    return modalActions.openModal(MODALS.finishFranchiseRegistration, modalData);
  },

  openCopyTemporaryCredsModal(modalData) {
    return modalActions.openModal(MODALS.showTemporaryCreds, modalData);
  },

  openFranchisePermissionsModal(modalData) {
    return modalActions.openModal(MODALS.franchisePermissions, modalData);
  },

  openMdChatModal(modalData) {
    return modalActions.openModal(MODALS.mdChat, modalData);
  },

  openAddNewContactModal(modalData) {
    return modalActions.openModal(MODALS.addNewContact, modalData);
  },

  openConfirmModal(modalData) {
    return modalActions.openModal(MODALS.confirmModal, modalData);
  },

  openAudienceDownloadReportModal(modalData) {
    return modalActions.openModal(MODALS.downloadReport, modalData);
  },

  openCreateWebhookModal(modalData) {
    return modalActions.openModal(MODALS.createWebhookModal, modalData);
  },

  openCreateKeywordModal(modalData) {
    return modalActions.openModal(MODALS.createKeywordModal, modalData);
  },

  openCreateSequenceModal(modalData) {
    return modalActions.openModal(MODALS.createSequenceModal, modalData);
  },

  openCreateEditTagModal(modalData) {
    return modalActions.openModal(MODALS.createEditTagModal, modalData);
  },

  openCreateEditFastReplyModal(modalData) {
    return modalActions.openModal(MODALS.createEditFastRepliesModal, modalData);
  },

  openQrScanningModal(modalData) {
    return modalActions.openModal(MODALS.qrScanning, modalData);
  },

  openDisconnectionModal(modalData) {
    return modalActions.openModal(MODALS.disconnection, modalData);
  },

  openEditingCompany(modalData) {
    return modalActions.openModal(MODALS.editingCompany, modalData);
  },

  openSetUpSupportEmailModal(modalData) {
    return modalActions.openModal(MODALS.setUpSupportEmail, modalData);
  },

  openDeleteSupportEmailModal(modalData) {
    return modalActions.openModal(MODALS.deleteSupportEmail, modalData);
  },

  opeEditCustomEmail(modalData) {
    return modalActions.openModal(MODALS.editCustomEmail, modalData);
  },

  openDeleteEMailDomainModal(modalData) {
    return modalActions.openModal(MODALS.deleteEmailDomainModal, modalData);
  },

  openEditFranchiseAvatarModal(modalData) {
    return modalActions.openModal(MODALS.openEditFranchiseAvatarModal, modalData);
  },

  openChoiceFlowsModal(modalData) {
    return modalActions.openModal(MODALS.choiceFlows, modalData);
  },

  openAnnounceModal(modalData) {
    return modalActions.openModal(MODALS.announce, modalData);
  },

  openTwoFactorAuthenticationModal(modalData) {
    return modalActions.openModal(MODALS.twoFactorAuthentication, modalData);
  },

  openPasswordUpdateCheck() {
    return modalActions.openModal(MODALS.passwordUpdateCheck);
  },

  openHelpLinkModal(modalData) {
    return modalActions.openModal(MODALS.setUpHelpLink, modalData);
  },

  openEditFlowAndFolderModal(modalData) {
    return modalActions.openModal(MODALS.editFlowAndFolderModal, modalData);
  },

  openEditFieldAndFolderModal(modalData) {
    return modalActions.openModal(MODALS.editFieldAndFolderModal, modalData);
  },

  closeModal() {
    return {
      type: 'CLOSE_MODAL',
    };
  },

  closePermissionModal() {
    return {
      type: 'CLOSE_PERMISSION_MODAL',
    };
  },

  openModal(modal, modalData) {
    return {
      type: 'OPEN_MODAL',
      payload: {
        modal,
        modalData,
      },
    };
  },
};
