import Switch from '@mui/material/Switch';
import { styled } from '@mui/system';

const GreenSwitchRoot = styled(Switch)(() => ({
  '&.MuiSwitch-switchBase': {
    color: '#FFFFFF',
    '&.Mui-checked': {
      color: '#FFFFFF',
    },
    '&.Mui-checked +.MuiSwitch-track': {
      opacity: 1,
      backgroundColor: 'rgba(0, 198, 75, 1)',
    },
  },
}));

export const GreenSwitch = GreenSwitchRoot;
