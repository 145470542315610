export const OPEN_NOTIFICATIONS_CONNECTION_REQUEST =
  'OPEN_NOTIFICATIONS_CONNECTION_REQUEST';
export const OPEN_NOTIFICATIONS_CONNECTION_SUCCESS =
  'OPEN_NOTIFICATIONS_CONNECTION_SUCCESS';
export const CLOSE_NOTIFICATIONS_CONNECTION_SUCCESS =
  'CLOSE_NOTIFICATIONS_CONNECTION_SUCCESS';
export const GET_NOTIFICATION_CONNECTION_STATUS_SUCCESS =
  'GET_NOTIFICATION_CONNECTION_STATUS_SUCCESS';

export const SET_START_NOTIFICATION_VALUES = 'SET_START_NOTIFICATION_VALUES';
export const CHANGE_CONNECTION_STATUS = 'CHANGE_CONNECTION_STATUS';
export const CHANGE_COUNT_OF_TRIES = 'CHANGE_COUNT_OF_TRIES';
export const CHANGE_QR_SCAN = 'CHANGE_QR_SCAN';
export const CHANGE_BATTERY_LEVEL = 'CHANGE_BATTERY_LEVEL';
export const CHANGE_DEVICE_INFO = 'CHANGE_DEVICE_INFO';
export const CHANGE_UNREAD_CHATS = 'CHANGE_UNREAD_CHATS';
export const CHANGE_CONNECTED_PHONE = 'CHANGE_CONNECTED_PHONE';
export const CHANGE_SYNC_PROCESS_STATUS = 'CHANGE_SYNC_PROCESS_STATUS';
