import React from 'react';

import { ListItemDateTime } from './ListItemDateTime';

import './dateTimePopover.scss';

export const TimePopover = ({ selectedDate, setSelectedDate }) => {
  const hours = Array.from({ length: 24 }, (v, k) => k++);
  const minutes = Array.from({ length: 60 }, (v, k) => k++);

  return (
    <div className="date-time-popover__body">
      <div>
        <div className="date-time-popover__body_container_title">hr</div>
        <div className="date-time-popover__body_container">
          {hours.map((el) => (
            <ListItemDateTime
              item={el}
              active={el === selectedDate.hour}
              onClick={() => {
                setSelectedDate({ ...selectedDate, hour: el });
              }}
              isTime={true}
            />
          ))}
        </div>
      </div>
      <div>
        <div className="date-time-popover__body_container_title">min</div>
        <div className="date-time-popover__body_container">
          {minutes.map((el) => (
            <ListItemDateTime
              item={el}
              active={el === selectedDate.minute}
              onClick={() => {
                setSelectedDate({ ...selectedDate, minute: el });
              }}
              isTime={true}
            />
          ))}
        </div>
      </div>
    </div>
  );
};
