import React from 'react';

export const AlertIcon = () => (
  <svg
    width="56"
    height="56"
    viewBox="0 0 56 56"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="56" height="56" rx="28" fill="#D1FADF" />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M33.4456 24.771C33.7792 25.1207 33.7661 25.6745 33.4165 26.0081L26.9962 32.1331C26.6579 32.4558 26.1257 32.4556 25.7877 32.1326L22.583 29.0701C22.2336 28.7362 22.221 28.1823 22.5549 27.833C22.8888 27.4836 23.4427 27.471 23.792 27.8049L26.3927 30.2902L32.2085 24.7419C32.5582 24.4083 33.112 24.4214 33.4456 24.771Z"
      fill="#039855"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M28 18.375C22.6843 18.375 18.375 22.6843 18.375 28C18.375 33.3157 22.6843 37.625 28 37.625C33.3157 37.625 37.625 33.3157 37.625 28C37.625 22.6843 33.3157 18.375 28 18.375ZM16.625 28C16.625 21.7178 21.7178 16.625 28 16.625C34.2822 16.625 39.375 21.7178 39.375 28C39.375 34.2822 34.2822 39.375 28 39.375C21.7178 39.375 16.625 34.2822 16.625 28Z"
      fill="#039855"
    />
  </svg>
);
