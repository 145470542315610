import { createSelector } from 'reselect';

export const getBroadcastUsersSelector = createSelector(
  [state => state.broadCast.users],
  users => users,
);

export const getBroadcastUsersCountSelector = createSelector(
  [state => state.broadCast.usersCount],
  usersCount => usersCount,
);

export const getBroadcastUsersFiltersSelector = createSelector(
  [state => state.broadCast.filters],
  filters => filters,
);

export const getBroadcastUsersQueryStringSelector = createSelector(
  [state => state.broadCast.queryString],
  queryString => queryString,
);

export const getBroadcastUsersQueryStringLoaderSelector = createSelector(
  [state => state.broadCast.queryStringLoader],
  queryStringLoader => queryStringLoader,
);

export const getBroadcastUsersSearchNameSelector = createSelector(
  [state => state.broadCast.searchName],
  searchName => searchName,
);

export const getBroadcastShowUsersSelector = createSelector(
  [state => state.broadCast.showUsers],
  showUsers => showUsers,
);

export const getBroadcastUsersSortTypesSelector = createSelector(
  [state => state.broadCast.usersSortType],
  usersSortType => usersSortType,
);
export const getBroadcastSubscribedUsersSortTypesSelector = createSelector(
  [state => state.broadCast.subscribedSortType],
  subscribedSortType => subscribedSortType,
);

export const getBroadcastFilterTypeSelector = createSelector(
  [state => state.broadCast.filterType],
  filterType => filterType,
);

export const getBroadcastUsersLoadedSelector = createSelector(
  [state => state.broadCast.loader],
  loader => loader,
);

export const getNextBroadcastUsersSelector = createSelector(
  [state => state.broadCast.next],
  next => next,
);

export const getNewSavedFilterStateBroadcast = createSelector(
  [state => state.broadCast.savedFilterState],
  savedFilterState => savedFilterState,
);
