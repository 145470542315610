import React from 'react';

import './NotPermissionPage.scss';
import { useInternationalization } from '../../hooks/useTranslationHook';

export const NotPermissionPage = () => {
  const { formatMessage } = useInternationalization();

  return (
    <div className="not-permission-container">
      <div className="not-permission-container__warning-block">
        <h2>{formatMessage('You don`t have permission to this page')}</h2>
      </div>
    </div>
  );
};
