import {
  CREATE_INTEGRATION_EVENT_ERROR,
  GET_INTEGRATION_EVENT_SUCCESS,
} from './integrationEventsConstants';

const initialState = {
  integrationEvents: [],
  integrationEventsErrors: '',
};

export const integrationEventsReducer = (state = initialState, action) => {
  switch (action.type) {
    case CREATE_INTEGRATION_EVENT_ERROR:
      return {
        ...state,
        integrationEventsErrors: action.payload,
      };

    case GET_INTEGRATION_EVENT_SUCCESS:
      return {
        ...state,
        integrationEvents: [...action.payload],
      };

    default:
      return state;
  }
};
