import { GET_GROUPS_SUCCESS, GET_GROUPS_REQUEST } from './groupsConstants';

const initialState = {
  groups: [],
  groupsErrors: '',
};

export const groupsReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_GROUPS_REQUEST:
      return {
        ...state,
        groupsErrors: '',
      };
    case GET_GROUPS_SUCCESS:
      const { data } = action.payload;
      return {
        ...state,
        groups: [...data],
        groupsErrors: '',
      };
    default:
      return state;
  }
};
