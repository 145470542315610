import {
  TOGGLE_SELECT_ALL,
  CHANGE_ITEM_SELECT_MODE,
  CREATE_NEW_ITEM_SUCCESS,
  DELETE_ITEM_REQUEST_SUCCESS,
  GET_ITEMS_REQUEST,
  GET_ITEMS_SUCCESS,
  GET_ITEMS_NEXT_SUCCESS,
  SET_ITEMS_NAME,
  BULK_ACTION_DELETE_ITEMS,
  SET_ITEMS_PAGE,
  UPDATE_ITEM_SPLIT_TABLE,
  CLEAR_CHECKBOXES,
  CLEAR_NEXT,
} from './bcSplitTableActionTypes';

const initialState = {
  items: [],
  selectedAll: false,
  loaded: false,
  itemsName: '',
  page: 1,
  next: null,
};

export const bcSplitTableReducer = (state = initialState, action) => {
  const data = action.payload;

  switch (action.type) {
    case GET_ITEMS_REQUEST:
      return {
        ...state,
        loaded: false,
      };
      //check this shit
      case GET_ITEMS_SUCCESS:
        const results = action.payload.results || action.payload.adsSources || [];
        return {
          ...state,
          items: addCheckedField(results, state.selectedAll),
          loaded: true,
          next: action.payload.next,
          page: action.payload.next ? state.page + 1 : 0,
        };
      
      case GET_ITEMS_NEXT_SUCCESS:
        const nextResults = action.payload.results || action.payload.adsSources || [];
        return {
          ...state,
          items: [
            ...state.items,
            ...addCheckedField(nextResults, state.selectedAll),
          ],
          loaded: true,
          next: action.payload.next === '' ? null : action.payload.next,
          page: state.page + 1,
        };
    
    case SET_ITEMS_PAGE:
      return {
        ...state,
        page: data,
      };
    case SET_ITEMS_NAME:
      return {
        ...state,
        itemsName: data,
      };
    case CREATE_NEW_ITEM_SUCCESS:
      return {
        ...state,
        items: [action.payload, ...state.items],
      };
    case DELETE_ITEM_REQUEST_SUCCESS:
      return {
        ...state,
        items: state.items.filter(item => item.id !== data),
      };
    case UPDATE_ITEM_SPLIT_TABLE:
      return {
        ...state,
        items: state.items.map(el => (el.id === data.id ? data : el)),
      };
    case BULK_ACTION_DELETE_ITEMS:
      return {
        ...state,
        items: state.items.filter(item => !data.ids.includes(item.id)),
      };
    case TOGGLE_SELECT_ALL:
      return {
        ...state,
        selectedAll: action.payload,
        items: addCheckedField(state.items, action.payload),
      };
    case CHANGE_ITEM_SELECT_MODE:
      return {
        ...state,
        items: changeItemSelectMode(state.items, action.payload),
      };
    case CLEAR_CHECKBOXES:
      return {
        ...state,
        items: clearCheckBoxes(state.items),
        selectedAll: false,
      };
      case CLEAR_NEXT:
        return {
          ...state,
          next: null,
        };
    default:
      return state;
  }
};

const addCheckedField = (data, selectedAll) =>
  data.map(item => ({
    ...item,
    checked: selectedAll,
  }));

const changeItemSelectMode = (data, id) =>
  data.map(item => {
    if (item.id === id) {
      return {
        ...item,
        checked: !item.checked,
      };
    }

    return item;
  });

const clearCheckBoxes = data => data.map(item => ({ ...item, checked: false }));
