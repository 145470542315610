import React from 'react';

export const ContactMobilePhoneIcon = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12.7778 2H7.22222C5.99492 2 5 2.95513 5 4.13333V15.8667C5 17.0449 5.99492 18 7.22222 18H12.7778C14.0051 18 15 17.0449 15 15.8667V4.13333C15 2.95513 14.0051 2 12.7778 2Z"
      stroke="#A4A8B8"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M5 15H15"
      stroke="#A4A8B8"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
