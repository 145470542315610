import React, { useEffect, useState } from 'react';

import FormField from '@wabb-ui-kit/FormField/FormField';
import Dropdown from '@wabb-ui-kit/Dropdown/Dropdown.tsx';
import RadioGroup from '@wabb-ui-kit/RadioGroup/RadioGroup';
import { EInputType } from '@wabb-ui-kit/FormField/types/types';
import CheckBox from '@wabb-ui-kit/CheckBox/CheckBox';
import { useInternationalization } from '@hooks/useTranslationHook';
import { DateVariableIcon } from '@icons/audience/date-variable-icon';
import { DateTimePopover } from '@pages/audience/components/audienceFilters/filtersPopover/components/dateTimePopover/dateTimePopover';
import { TextIcon } from '@icons/broadcast/text-icons';

import '../BroadcastCreateModal.scss';

const delayTypeOptions = [
  {
    id: 0,
    name: 'Very short',
    subText: '1-5s',
  },
  {
    id: 1,
    name: 'Short',
    subText: '5-20s',
  },
  {
    id: 2,
    name: 'Medium',
    subText: '20-50s',
  },
  {
    id: 3,
    name: 'Long',
    subText: '50-120s',
  },
  {
    id: 3,
    name: 'Very long',
    subText: '120-300s',
  },
];

export const BroadcastCreateModalLeftPanel = ({
  flows,
  broadcastData,
  handleUpdateBroadcastData,
  showDateStart,
  setShowDateStart,
}) => {
  const { formatMessage } = useInternationalization();

  const [datetime, setDateTime] = useState('');
  const [anchorEl, setAnchorEl] = useState(null);
  const [name, setName] = useState(broadcastData.name || '');
  const [flow, setFlow] = useState(
    broadcastData.flow ? { id: broadcastData.flow, name: broadcastData.flow_name } : {},
  );

  const [delay, setDelay] = useState(broadcastData.delay || 1);
  const [delayType, setDelayType] = useState({
    id: broadcastData.delay_type || 0,
    name: delayTypeOptions[broadcastData.delay_type || 0].name,
    subText: delayTypeOptions[broadcastData.delay_type || 0].subText,
  });
  const [delaySelectionType, setDelaySelectionType] = useState(
    broadcastData.delay_selection_type || 0,
  );

  useEffect(() => {
    handleUpdateBroadcastData({
      name,
      flow: flow.id,
      delay,
      delay_type: delayType.id,
      delay_selection_type: delaySelectionType,
      start_time: datetime,
    });
  }, [name, flow, delay, delayType, delaySelectionType, datetime]);

  const handleChangeDelaySelection = (e) => {
    setDelaySelectionType(e);
  };

  return (
    <div className="broadcast-modal__body_left-panel">
      <div className="broadcast-modal__body_left-panel_container">
        <FormField
          placeholder="Type here..."
          label="Broadcast name"
          isRequired
          value={name}
          maxLength={30}
          onCrossClick={() => setName('')}
          onChange={(e) => setName(e.target.value)}
        />
      </div>
      <div className="broadcast-modal__body_left-panel_container">
        <span className="broadcast-modal__body_left-panel_container__title">
          Flow&nbsp;
          <span className="broadcast-modal__body_left-panel_container__title_required">*</span>
        </span>
        <Dropdown
          options={flows}
          singleSelectedOption={flow}
          setSingleSelectedOption={setFlow}
          search
        />
      </div>
      <div className="broadcast-modal__body_left-panel_container">
        <span className="broadcast-modal__body_left-panel_container__title">{ formatMessage('Delay') }</span>
        <RadioGroup
          options={[
            {
              id: 0,
              groupName: 'DelaySelectionType',
              title: formatMessage('Smart'),
              isChecked: delaySelectionType === 0,
            },
            {
              id: 1,
              groupName: 'DelaySelectionType',
              title: formatMessage('Manually'),
              isChecked: delaySelectionType === 1,
            },
          ]}
          onChange={handleChangeDelaySelection}
        />
      </div>
      { delaySelectionType === 0 ? (
        <div>
          <Dropdown
            options={delayTypeOptions}
            singleSelectedOption={delayType}
            setSingleSelectedOption={setDelayType}
          />
        </div>
      ) : (
        <div style={{ width: delay ? '100%' : '225px' }}>
          <FormField
            placeholder="Delay"
            isRequired
            value={delay}
            width={255}
            type={EInputType.Number}
            icon={<TextIcon />}
            onCrossClick={() => setDelay(1)}
            onChange={(event) => setDelay(Number(
              event.target.value > 10000
                ? 9999
                : event.target.value < 1
                  ? 1
                  : event.target.value,
            ))}
          />
        </div>
      ) }
      <div className="broadcast-modal__body_left-panel_container__date">
        <CheckBox
          type="checkbox"
          title="Set time"
          isChecked={showDateStart}
          onChange={(e) => setShowDateStart(e.target.checked)}
        />
        { showDateStart && (
        <div className="broadcast-modal__body_left-panel_container__date__date-field">
          <div className="broadcast-modal__body_left-panel_container__date__date-field__title">{ formatMessage('Date & time') }</div>
          <div
            className={`broadcast-modal__body_left-panel_container__date__date-field__filled-input ${!datetime ? 'empty-state' : ''}`}
            onClick={(event) => setAnchorEl(event.currentTarget)}
          >
            { datetime || 'Choose here' }
            <div className="date-field-arrow">
              <DateVariableIcon />
            </div>
          </div>
          <DateTimePopover
            id="bot_fields"
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            open={Boolean(anchorEl)}
            onClose={() => {
              setAnchorEl(null);
            }}
            dateTime
            format="YYYY-MM-DDTHH:mm:ss"
            changeDate={(value) => setDateTime(value)}
          />
        </div>
        ) }
      </div>
    </div>
  );
};
