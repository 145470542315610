import React from 'react';
import { useSelector, useDispatch } from 'react-redux';

import '../modalsComponent.scss';
import './MdChatModal.scss';

import { ModalContainer } from '../../containers/Modal/ModalContainer';
import { MODALS } from '../../containers/Modal/modalConstants';
import { getModalDataSelector } from '../../containers/Modal/modalSelectors';
import { modalActions } from '../../containers/Modal/modalActions';
import { Button } from '../../common/Button';
import { useInternationalization } from '../../../hooks/useTranslationHook';

export const MdChatModal = () => {
  const { formatMessage } = useInternationalization();

  const dispatch = useDispatch();

  const { modalData } = useSelector(getModalDataSelector);

  return (
    <ModalContainer modal={MODALS.mdChat} onClose={modalData.onClose}>
      <div className="campaigns-modal">
        <div className="campaigns-modal__header">NOVA ATUALIZAÇÃO BC</div>
        <div className="delete-item-modal__content">
          <div className="md-chat-modal__description-container">
            <div>
              {/* <div>
            <div className="md-chat-modal__description-strong">Atualização prevista para às 15h.</div>
              <div className="md-chat-modal__description-strong-padding">
                  Sistema pode ficar inacessível entre 13h e 15h.
              </div>
              <div className="md-chat-modal__description">Olá, cliente BotConversa.</div>
              <div className="md-chat-modal__description">
                Domingo agora vamos fazer uma grande atualização no BotConversa.
              </div>
              <div className="md-chat-modal__description">
                A partir de domingo a ferramenta vai funcionar com o WhatsApp Beta ou definitiva da
                versão Múltiplos Aparelhos.
              </div>{' '}
              <div className="md-chat-modal__description">
                Isso significa que o seu celular não vai mais precisar estar conectado à internet
                para a automação continuar funcionando.
              </div>{' '}
              <div className="md-chat-modal__description">
                A atualização vai acontecer na parte da manhã às 15hrs.
              </div>{' '}
              <div className="md-chat-modal__description">
                Pedimos que todos vocês atualizem o WhatsApp apenas no domingo.
              </div>{' '}
              <div className="md-chat-modal__description">
                Caso seu WhatsApp não tenha atualizado automaticamente, você precisa seguir esses
                passos:
              </div>{' '}
              <div className="md-chat-modal__description">
                <strong>1.</strong> Abra o WhatsApp{' '}
              </div>
              <div>
                <strong>2.</strong> Clique em Configurações
              </div>{' '}
              <div>
                <strong>3.</strong> Clique em Aparelhos Conectados
              </div>{' '}
              <div>
                <strong>4.</strong> Clique na opção para atualizar o WhatsApp Pronto.
              </div>{' '}
              <div className="md-chat-modal__description">
                Seu WhatsApp agora estará atualizado e pronto para conectar ao BotConversa.
              </div>
              <div className="md-chat-modal__description">
                Lembre-se de fazer isso somente no Domingo.{' '}
              </div>
              <div>Ele não irá funcionar com o BotConversa se você fizer antes.</div>
              <div className="md-chat-modal__description">Obrigado!</div>
            </div> */}
              <div className="md-chat-modal__description">Olá! Tudo bem? </div>
              <div className="md-chat-modal__description">
                Sábado às 10:30h da manhã iremos fazer uma atualização de correções no
                BotConversa.
              </div>
              <div className="md-chat-modal__description">
                Estamos confiantes que estamos no caminho certo e que as coisas irão
                normalizar em um curto espaço de tempo.
              </div>
              <div className="md-chat-modal__description">
                Para continuar com as correções nesta versão atual, precisaremos
                desconectar sua companhia.
              </div>
              <div className="md-chat-modal__description">
                Portanto, você precisará escanear novamente com seu WhatsApp neste sábado
                às 10:30h.
              </div>
              <div className="md-chat-modal__description">
                Agradecemos a compreensão de todos e seguimos na luta para entregar para
                você a melhor ferramenta do mercado.
              </div>
              <div className="md-chat-modal__description">Muito obrigado!</div>
              <div className="md-chat-modal__description">Thiago Lopes</div>
              {/*<div className="md-chat-modal__description">*/}
              {/*  Após conectar com sucesso, seu celular até poderá ser desligado.*/}
              {/*</div>*/}
              {/*<div className="md-chat-modal__description">*/}
              {/*  Mas deverá ser ligado uma vez a cada 14 dias.*/}
              {/*</div>*/}
            </div>
          </div>
          <div className="md-chat-modal__footer-group">
            <Button
              onClick={() => dispatch(modalActions.closeModal())}
              color="transparent"
              customClass="delete-item-modal__button"
            >
              Entendido
            </Button>
          </div>
        </div>
      </div>
    </ModalContainer>
  );
};
