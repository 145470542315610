export const AUTH = 'AUTH';
export const REG = 'REG';
export const REDIRECT_TO_NEXT_PAGE = 'REDIRECT_TO_NEXT_PAGE';
export const ADD_ERROR_MESSAGE = 'ADD_ERROR_MESSAGE';
export const ACTIVATE_ACCOUNT = 'ACTIVATE_ACCOUNT';
export const CLEAN_ERROR_MESSAGE = 'CLEAN_ERROR_MESSAGE';
export const REDIRECT_SUCCESS = 'REDIRECT_SUCCESS';
export const REG_SUCCESS = 'REG_SUCCESS';
export const REG_FAILURE = 'REG_FAILURE';
export const SET_AUTH_DATA = 'SET_AUTH_DATA';
export const SET_TWO_FA_DATA = 'SET_TWOFA_DATA';
export const LOGOUT = 'LOGOUT';
export const SEND_USER_INFORMATION = 'SEND_USER_INFORMATION';
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';
export const SET_USER_ID = 'SET_USER_ID';
export const IS_EMAIL_REGISTRATION_IN_PROGRESS = 'IS_EMAIL_REGISTRATION_IN_PROGRESS';
export const ADD_YOUR_COMPANY = 'ADD_YOUR_COMPANY';
export const RESET_PASSWORD = 'RESET_PASSWORD';
export const HIDE_RESEND_EMAIL_CONFIRMATION = 'HIDE_RESEND_EMAIL_CONFIRMATION';
export const SHOW_RESEND_EMAIL_CONFIRMATION = 'SHOW_RESEND_EMAIL_CONFIRMATION';
export const SET_RESET_PASSWORD_DATA = 'SET_RESET_PASSWORD_DATA';
export const SET_FRANCHISE_PERMISSIONS_DATA = 'SET_FRANCHISE_PERMISSIONS_DATA';
export const UPDATE_TOKEN = 'UPDATE_TOKEN';
