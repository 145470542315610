import React, { useCallback, useEffect, useState } from 'react';
import debounce from 'lodash/debounce';
import moment from 'moment/moment';

import { AudienceFiltersMenuArrowIcon } from '@icons/audience/audience-filters-menu-arrow-icon';
import { CheckIcon } from '@icons/audience/check-icon';
import { audienceApi } from '@api/audience';
import { DateVariableIcon } from '@icons/audience/date-variable-icon';
import { BCInfiniteScroll } from '@components/common/BCInfiniteScroll/BCInfiniteScroll';
import { useInternationalization } from '@hooks/useTranslationHook';

import '../filtersPopover.scss';
import { botAdminsApi } from '@api/botAdminsApi';
import { DateTimePopover } from './dateTimePopover/dateTimePopover';

const conditions = ['is', 'is not'];

const subscribedCondition = ['after', 'before', 'on'];

export const FilterModal = ({
  selectedFilter,
  step,
  companyBot,
  returnToStart,
  updateSelectedFilter,
}) => {
  const { formatMessage } = useInternationalization();

  const [values, setValues] = useState([]);
  const [mostPopularValues, setMostPopularValues] = useState([]);
  const [page, setPage] = useState(1);
  const [queryArg, setQueryArg] = useState('');
  const [anchorEl, setAnchorEl] = useState(null);

  const [next, setNext] = useState();

  useEffect(() => {
    page > 1 ? sendDebouncedReq(1, queryArg, getValues) : getValues(1, queryArg);
  }, [queryArg]);

  useEffect(() => {
    updateSelectedFilter({ filterName: step, condition: getCondition()[0] });
  }, []);

  const handleClose = () => {
    setAnchorEl(null);
  };

  const dateFormat = data => moment(data).format('DD.MM.YYYY');

  const changeFormatValues = elements => {
    if (typeof elements[0] !== 'object') {
      return elements.map(el => (step === 'Subscribed' ? dateFormat(el) : el));
    }

    return elements.map(el => ({ value: el.id, label: el.name }));
  };

  const getValues = (currentPage, queryArg = '') => {
    const filterName = step.toLowerCase().replaceAll(' ', '_');

    if (filterName === 'assigned_manager') {
      botAdminsApi.getBotAdmins(companyBot).then(res => {
        setValues(resupdatedUser.map(el => ({ value: el.user, label: el.fullName })));
      });
    } else if (filterName === 'case') {
      setValues(['Open', 'Close']);
    } else {
      audienceApi
        .getSuggestedValues(companyBot, filterName, queryArg, currentPage)
        .then(res => {
          if (currentPage === 1) {
            setValues(changeFormatValues(res.results.values));
            setMostPopularValues(changeFormatValues(res.results.most_popular));
          } else {
            setValues([...values, ...changeFormatValues(res.results.values)]);
          }

          setNext(res.next);
          setPage(1 + currentPage);
        });
    }
  };

  const sendDebouncedReq = useCallback(
    debounce((value1, value2, callback) => callback(value1, value2), 500),
    [],
  );

  const getCondition = () => (step !== 'Subscribed' ? conditions : subscribedCondition);

  return (
    <div className="option-choose">
      <div className="option-choose__column">
        <div className="option-choose__column_header" onClick={returnToStart}>
          <AudienceFiltersMenuArrowIcon rotate={180} />
          {formatMessage('step')}
        </div>
        <div className="option-choose__column_body">
          <div className="option-choose__column_body_label">{formatMessage(step)}</div>
          {getCondition().map(item => (
            <div
              key={item}
              className={`option-choose__column_body_element${
                selectedFilter.condition === item ? '_active' : ''
              }`}
              onClick={() => {
                updateSelectedFilter({ condition: item });
              }}
            >
              <span>{formatMessage(item)}</span>
              {selectedFilter.condition === item && <CheckIcon />}
            </div>
          ))}
        </div>
      </div>
      <div className="option-choose__column">
        <div className="option-choose__column_header">
          <div
            className="option-choose__column_header_search"
            onClick={event => {
              step === 'Subscribed' && setAnchorEl(event.currentTarget);
            }}
          >
            <input
              type="text"
              value={queryArg}
              placeholder={formatMessage('Search or input value')}
              disabled={step === 'Subscribed'}
              onChange={event => setQueryArg(event.target.value)}
            />
            {step === 'Subscribed' && <DateVariableIcon />}
          </div>
          <DateTimePopover
            id="custom_fields_filter"
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'centre',
            }}
            open={Boolean(anchorEl)}
            onClose={handleClose}
            dateTime={false}
            format="DD.MM.YYYY"
            changeDate={setQueryArg}
          />
        </div>
        <div id="#BCScrollingElement" className="option-choose__column_body">
          <BCInfiniteScroll
            loadMore={() => getValues(page, queryArg)}
            next={next}
            root={document.getElementById('#BCScrollingElement')}
            rootMargin="0px"
            threshold={1.0}
            backToTop={page === 2}
          >
            {queryArg && !['Tag', 'Sequence', 'Campaign'].includes(step) && (
              <div
                className="option-choose__column_body_element_active"
                onClick={() => {
                  updateSelectedFilter({ value: queryArg }, true);
                }}
              >
                <span>
                  {formatMessage('select')} "{queryArg}"
                </span>
              </div>
            )}
            {!queryArg && mostPopularValues.length > 0 && (
              <>
                <div className="option-choose__column_body_element_label">
                  {formatMessage('Most popular')}
                </div>
                {mostPopularValues.map(item => (
                  <div
                    key={item?.value ? item.value : item}
                    className="option-choose__column_body_element"
                    onClick={() => {
                      updateSelectedFilter({ value: item }, true);
                    }}
                  >
                    <span>{item.label ? item.label : item}</span>
                  </div>
                ))}
              </>
            )}
            {values.length ? (
              <>
                {!queryArg && (
                  <div className="option-choose__column_body_element_label">
                    {formatMessage('All')}
                  </div>
                )}
                {values.map(item => (
                  <div
                    key={item?.value ? item.value : item}
                    className="option-choose__column_body_element"
                    onClick={() => {
                      updateSelectedFilter({ value: item }, true);
                    }}
                  >
                    <span>{item.label ? item.label : item}</span>
                  </div>
                ))}
              </>
            ) : (
              <div className="option-choose__column_body_element_static">
                <span>{formatMessage('No values yet')}</span>
              </div>
            )}
          </BCInfiniteScroll>
        </div>
      </div>
    </div>
  );
};
