import React, { useMemo, useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import classNames from 'classnames';
import { useMediaQuery } from '@mui/material';
import { useHistory, useLocation } from 'react-router-dom';
import { useContextSelector } from 'use-context-selector';

// components
import {
  getPermissionsSelectedCompanySelector,
  getSelectedCompanySelector,
} from '@redux/companies/companiesSelectors';
import { navigationMenuActions } from '@redux/navigationMenu/navigationMenuActions';
import { notificationsActions } from '@redux/notifications/notificationsActions';
import { getNotificationsSelector } from '@redux/notifications/notificationsSelector';
import { isEmailRegistrationInProgressSelector } from '@redux/auth/authSelectors';
import NavigationMenuLink from './components/NavigationMenuLink/NavigationMenuLink';
import Divider from './components/Divider/Divider';
import NavigationFooter from './components/NavigationFooter/NavigationFooter';
import Banner from './components/Banner/Banner';
import NavigationMenuDropdown from './components/Dropdown/NavigationMenuDropdown';
import { ToastContext } from '../../contexts/toastsContext';

// icons
import { ArrowNavigationClose } from '@icons/arrow-navigation-close.jsx';
import { LogoIconFull, LogoIconSmall } from '@icons';

// utils
import { IPageItem, IUser } from './types';
import styles from './NavigationMenu.module.scss';
import { Routes } from '../../const/routes';
import { checkCompanyStatus } from '@helpers';



interface INavigationProps {
  items: IPageItem[];
  userInfo: IUser;
  selectedCompanyId: string;
  open: boolean;
  onToggle?: (event: React.MouseEvent<HTMLDivElement>) => void;
}

const NavigationMenu: React.FC<INavigationProps> = ({
  items,
  userInfo,
  selectedCompanyId,
  open,
  onToggle,
}: INavigationProps) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const url = useLocation();

  const isSettingsPage = url.pathname.includes('/settings');

  const isSmallScreen = useMediaQuery('(max-width:1000px)');

  const [isCompanyMenuOpen, setIsOpenCompanyMenu] = useState(false);

  const { notificationsSocket } = useSelector(getNotificationsSelector) as any;

  const permissions: any = useSelector(getPermissionsSelectedCompanySelector);
  const selectedCompany: any = useSelector(getSelectedCompanySelector);
  const addToast = useContextSelector(ToastContext, ({ add }: any) => add);

  const isEmailRegistrationInProgress = useSelector(
    isEmailRegistrationInProgressSelector,
  );

  const companyBot = selectedCompany?.bot;
  const botUrl = selectedCompany?.serverUrl;
  const serverNumber = selectedCompany?.serverNumber;
  const instanceId = selectedCompany?.instanceId;

  const subSequence = /\d+\/automation\/sequences\/\d+/;
  const subWebhook = /\d+\/automation\/webhooks\/\d+/;
  const constructor = /\d+\/constructor\/\d+/;
  // const liveChat = /\d+\/live-chat\/\S+/;

  useEffect(() => {
    if (subSequence.test(history.location.pathname)) {
      history.push(history.location.pathname.replace(/sequences\/\d+/, 'sequences'));
    }

    if (subWebhook.test(history.location.pathname)) {
      history.push(history.location.pathname.replace(/webhooks\/\d+/, 'webhooks'));
    }

    if (constructor.test(history.location.pathname)) {
      history.push(history.location.pathname.replace(/constructor\/\d+/, 'flows'));
    }
  }, [selectedCompanyId]);

  useEffect(() => {
    if (!notificationsSocket && companyBot && botUrl) {
      dispatch(
        notificationsActions.openNotifiactionsConnection({
          companyBot,
          botUrl,
          serverNumber,
          instanceId,
        }),
      );
    }

    if (!notificationsSocket && companyBot && !botUrl) {
      dispatch(
        notificationsActions.changeConnectionStatus({ connectionStatus: 'close' }),
      );
    }
  }, [companyBot, botUrl]);

  const listenSocket = (notificationsSocket: any) => {
    notificationsSocket.on('init-values', (data: any) => {
      dispatch(notificationsActions.setInitialValues(data));
    });

    notificationsSocket.on('change-connection-status', (data: any) => {
      dispatch(notificationsActions.changeConnectionStatus(data));
    });

    notificationsSocket.on('change-connected-phone', (data: any) => {
      dispatch(notificationsActions.changeConnectedPhone(data));
    });

    notificationsSocket.on('qr', (data: any) => {
      dispatch(notificationsActions.changeQrScan(data));
    });

    notificationsSocket.on('change-is-sync-process', (data: any) => {
      dispatch(notificationsActions.changeSyncProcessStatus(data));
    });

    notificationsSocket.on('socket-open-status', (data: { status: number; }) => {
      if (!isSmallScreen) {
        if (data.status === 402) {
          handleConnectionWarning();
        }

        if (data.status === 401) {
          handleConnectionError();
        }
      }

      if (data.status === 200) {
        dispatch(notificationsActions.getNotificationConnectionStatusSuccess(data));
      }
    });
  };

  useEffect(() => {
    if (notificationsSocket) {
      listenSocket(notificationsSocket);
    }
  }, [notificationsSocket]);

  const handleConnectionError = () => {
    addToast({
      title: 'Error',
      type: 'error',
      message: 'Error occurred',
    });

    if (notificationsSocket) {
      notificationsSocket.notReconnect = true;
      notificationsSocket.close();
      dispatch(notificationsActions.closeConnectionSuccess());
    }
  };

  const handleConnectionWarning = () => {
    addToast({
      title: 'Warning',
      type: 'warning',
      message:
        'Our dev team has just made a quick update. Your access will normalize in less than 15 minutes.',
    });
  };

  // useEffect(() => {
  //   if (!!selectedCompanyId && !!selectedCompany?.type) {
  //     history.push(Routes.AddYourCompanyRoute);
  //   }
  // }, [selectedCompanyId, selectedCompany]);

  useEffect(() => {
    if (isEmailRegistrationInProgress === false && !!selectedCompany) {
      checkCompanyStatus(selectedCompany.type, selectedCompany.isOwner, dispatch);
    }
  }, [selectedCompany, isEmailRegistrationInProgress, url]);

  const drawerClassName = classNames(styles.drawer, {
    [styles.open]: open,
    [styles.closed]: !open,
  });

  const handleToggleNavMenu = (event: React.MouseEvent<HTMLDivElement>) => {
    if (isSettingsPage || isCompanyMenuOpen) {
      return;
    }

    onToggle?.(event);
  };

  const ToggleButton = useMemo(
    () => (
      <div
        onClick={handleToggleNavMenu}
        className={classNames(styles.closeButton, {
          [styles.closeButton__closed]: !open,
        })}
        style={{
          cursor: isCompanyMenuOpen || isSettingsPage ? 'not-allowed' : 'pointer',
          opacity: isCompanyMenuOpen || isSettingsPage ? '0.9' : '1',
        }}
      >
        <ArrowNavigationClose />
      </div>
    ),
    [open, isCompanyMenuOpen, isSettingsPage],
  );

  useEffect(() => {
    if (isSettingsPage) {
      dispatch(navigationMenuActions.closeMenu());
    } else {
      dispatch(navigationMenuActions.openMenu());
    }
  }, [url.pathname]);

  return (
    <>
      {!isSmallScreen && ToggleButton}
      <div className={drawerClassName}>
        <div className={styles.content}>
          <div className={styles.stack}>
            <div className={styles.box}>
              <p
                className={styles.title}
                style={{ textAlign: !open ? 'center' : undefined }}
              >
                {open ? <LogoIconFull /> : <LogoIconSmall />}
              </p>
              <Divider />
              <NavigationMenuDropdown
                isNavigationState={open}
                isCompanyMenuOpen={isCompanyMenuOpen}
                setIsCompanyMenuOpen={setIsOpenCompanyMenu}
              />
              <Divider />
              <ul className={styles.list}>
                {items.map((item: IPageItem) => (
                  // TODO: implement permission checking
                  // !!permissions?.[item.permission] ? (
                  <NavigationMenuLink
                    key={item.id}
                    isOpen={open}
                    item={item}
                    pathname={`/${selectedCompanyId}${item.path}`}
                  />
                ))}
              </ul>
            </div>
            <div>
              {/* <Banner isOpen={open} /> */}
              {/* <Divider /> */}
              <NavigationFooter open={open} userInfo={userInfo} />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default NavigationMenu;
