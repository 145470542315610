import React, { useState } from 'react';
import { useSelector } from 'react-redux';

import { Button } from '@components/common';
import { useInternationalization } from '@hooks/useTranslationHook';
import { profileApi } from '@api/profileApi';
import { useContextSelector } from 'use-context-selector';
import { ModalContainer } from '../../containers/Modal/ModalContainer';
import { MODALS } from '../../containers/Modal/modalConstants';
import { getModalDataSelector } from '../../containers/Modal/modalSelectors';
import { BcTextField } from '../../common/fields/TextField';

import './transferCompany.scss';
import { ToastContext } from '../../../contexts/toastsContext';

const styles = {};

export const TransferCompanyModal = () => {
  const { formatMessage } = useInternationalization();

  const [email, setEmail] = useState('');
  const [confirmationCode, setConfirmationCode] = useState('');
  const [error, setError] = useState(false);
  const [step, setStep] = useState(1);

  const emailError = formatMessage('Invalid email format');
  const confirmError = formatMessage('Incorrect code');

  const { modalData } = useSelector(getModalDataSelector);
  const addToast = useContextSelector(ToastContext, ({ add }) => add);

  const validateEmail = email => {
    const re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  };

  const handleSendNewOwnerEmail = () => {
    if (validateEmail(email)) {
      setError(false);
      profileApi
        .transferCompany({
          company_id: modalData.companyId,
          new_owner_email: email,
        })
        .then(() => {
          setStep(2);
        })
        .catch(() => {
          addToast({
            type: 'error',
            title: formatMessage('Error'),
            message: formatMessage('user with such email was not found'),
          });
        });
    } else {
      setError(true);
    }
  };

  const handleSendConfirmationCode = () => {
    profileApi
      .transferCompanyConfirm({
        confirmation_code: confirmationCode,
      })
      .then(() => {
        modalData.setUpdateCompanies(confirmationCode);
        modalData.successTransfer();
        modalData.onClose();
      })
      .catch(() => {
        setError(true);
      });
  };

  return (
    <ModalContainer
      modal={MODALS.transferCompany}
      onClose={modalData.onClose}
      customStyles={styles}
    >
      <div className="transfer-company-modal">
        <div className="transfer-company-modal__header">
          {formatMessage('Transfer company')}
        </div>
        <div className="transfer-company-modal__content">
          <span>
            {step === 1
              ? formatMessage('Input new owner email:')
              : formatMessage('Input the confirmation code sent to you by email')}
          </span>
          <BcTextField
            error={error}
            value={step === 1 ? email : confirmationCode}
            onChange={
              step === 1
                ? e => setEmail(e.target.value)
                : e => setConfirmationCode(e.target.value)
            }
            variant="outlined"
          />
          {error && (
            <span className="error">{step === 1 ? emailError : confirmError}</span>
          )}
        </div>
        <div className="transfer-company-modal__button">
          <Button
            onClick={step === 1 ? handleSendNewOwnerEmail : handleSendConfirmationCode}
          >
            Ok
          </Button>
        </div>
      </div>
    </ModalContainer>
  );
};
