import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import TextField from '@mui/material/TextField';

import { ModalContainer } from '../../containers/Modal/ModalContainer';
import { MODALS } from '../../containers/Modal/modalConstants';
import { getModalDataSelector } from '../../containers/Modal/modalSelectors';
import { Button } from '../../common/Button';

import { useInternationalization } from '../../../hooks/useTranslationHook';

import '../modalsComponent.scss';
import { flowsFoldersApi } from '../../../api/flowsFoldersApi';

const styles = {};

export const BotFoldersModal = () => {
  const { formatMessage } = useInternationalization();

  const { modalData } = useSelector(getModalDataSelector);

  const { companyBot, folderId, folderName, updateFlowsFoldersList, setFolderName } =
    modalData;

  const [newFolderName, setNewFolderName] = useState(folderName || '');

  const [error, setError] = useState(false);

  const handleSubmit = event => {
    event.preventDefault();

    if (newFolderName) {
      if (modalData.action === 'Create') {
        flowsFoldersApi
          .createFlowsFolderSource({
            name: newFolderName,
            bot: companyBot,
          })
          .then(res => {
            updateFlowsFoldersList(res.data, 'Create');
          });
      } else if (modalData.action === 'Rename') {
        flowsFoldersApi.renameFlowsFolderSource(folderId, newFolderName).then(res => {
          setFolderName(res.data.name);
        });
      }

      modalData.onClose();
    }
  };

  return (
    <ModalContainer
      modal={MODALS.folders}
      onClose={modalData.onClose}
      customStyles={styles}
    >
      <div className="campaigns-modal">
        <div className="campaigns-modal__header">
          {formatMessage(modalData.action)} {formatMessage('folder')}
        </div>
        <div className="campaigns-modal__form-group">
          <TextField
            error={error}
            name="botFolderName"
            label={formatMessage('Enter bot folder name')}
            value={newFolderName}
            onBlur={e => {
              setError(!e.target.value);
            }}
            helperText={error ? 'This field can`t be blank' : ''}
            onChange={e => setNewFolderName(e.target.value)}
            variant="outlined"
          />
        </div>
        {modalData.action === 'Create' ? (
          <h2 className="campaigns-modal__description">
            {formatMessage('Folder will be created indside')} "
            <strong>{formatMessage('Flows')}</strong>"{formatMessage('folder')}
          </h2>
        ) : null}
        <div className="campaigns-modal__form-group">
          <Button
            onClick={handleSubmit}
            customClass={`bots-modal__submit-button${
              modalData.action === 'Rename' ? '-rename' : ''
            }`}
          >
            {formatMessage(modalData.action)} {formatMessage('folder')}
          </Button>
        </div>
      </div>
    </ModalContainer>
  );
};
