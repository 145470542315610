import React, { useRef, useEffect, ComponentType } from 'react';
import { clsx } from 'clsx';
// types
import {
  TModalSizeType,
  EModalSize,
  EOverflowTypes,
} from '@wabb-ui-kit/Modal/types/types';
// components
import { useClickOutsideHandler } from '@wabb-ui-kit/Modal/hooks/useClickOutsideHandler';
import InputCross from '@wabb-assets/svg/FormField/input-cross.svg';
import IconComponent from '@wabb-ui-kit/IconComponent/index';

import styles from './Modal.module.scss';

export interface IModalProps {
  isOpen: boolean;
  onRequestClose: () => void;
  hideCloseBtn?: boolean;
  title?: string | React.ReactNode;
  children: React.ReactNode;
  size?: TModalSizeType;
  popover?: boolean;
  classNameModal?: string;
  classNameModalHead?: string;
  shouldCloseOnOverlayClick?: boolean;
  changeDocumentOverflowStyle?: boolean;
  modalStyle?: React.CSSProperties;
  modalRef?: any;
  footer?: React.ReactNode;
}

const Modal = ({
  isOpen,
  onRequestClose,
  hideCloseBtn,
  title = '',
  children,
  size = EModalSize.SMALL,
  popover = false,
  classNameModal = '',
  classNameModalHead = '',
  shouldCloseOnOverlayClick = false,
  changeDocumentOverflowStyle = true,
  modalStyle,
  modalRef,
  footer,
}: IModalProps) => {
  const modalBodyRef = useRef(null);

  useClickOutsideHandler(
    shouldCloseOnOverlayClick ? onRequestClose : () => {},
    modalBodyRef,
  );

  const modalClassName = clsx(styles.modal, styles[size], classNameModal, {
    [styles.modal__popover]: popover,
    [styles.open]: isOpen,
  });

  useEffect(() => {
    if (changeDocumentOverflowStyle) {
      const overflowStyle = isOpen ? EOverflowTypes.HIDDEN : EOverflowTypes.AUTO;
      document.body.style.overflow = overflowStyle;
    }

    return () => {
      if (changeDocumentOverflowStyle) {
        document.body.style.overflow = EOverflowTypes.AUTO;
      }
    };
  }, [isOpen]);

  return (
    <div className={modalClassName} ref={modalRef}>
      <div className={styles.modal__body} style={modalStyle} ref={modalBodyRef}>
        <div className={classNameModalHead}>
          {/* {!!title && <div className={styles.modal__title}>{title}</div>} */}
          {!hideCloseBtn && (
            <button
              className={clsx(styles.modal__btn, styles.modal__close)}
              type="button"
              onClick={onRequestClose}
            >
              {/* <IconComponent
								iconName={EIconNames.CROSS}
								className={styles.modal__icon}
							/> */}
              <img src={InputCross} />
            </button>
          )}
        </div>
        <div className={styles.modal__content}>{children}</div>
        {!!footer && footer}
      </div>
    </div>
  );
};

export default Modal as ComponentType<IModalProps>;
