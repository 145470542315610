import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { useInternationalization } from '@hooks/useTranslationHook';
import { CopyIcon } from '@icons/copy-icon';
import { ModalContainer } from '../../containers/Modal/ModalContainer';
import { MODALS } from '../../containers/Modal/modalConstants';
import { getModalDataSelector } from '../../containers/Modal/modalSelectors';

import './showTemporaryCreds.scss';
import { useContextSelector } from 'use-context-selector';
import { ToastContext } from '../../../contexts/toastsContext';

const styles = {
  width: '34%',
  maxWidth: '440px',
  minWidth: '350px',
};

export const ShowTemporaryCreds = () => {
  const { formatMessage } = useInternationalization();
  const addToast = useContextSelector(ToastContext, ({ add }) => add);
  const { modalData } = useSelector(getModalDataSelector);
  const [isCopiedEmail, setIsCopiedEmail] = useState(false);
  const [isCopiedPassword, setIsCopiedPassword] = useState(false);
  const [isCopiedEmailStyle, setIsCopiedEmailStyle] = useState(false);
  const [isCopiedPasswordStyle, setIsCopiedPasswordStyle] = useState(false);
  const [toastInfo, setToastInfo] = useState({
    title: 'Email copied',
    message: 'Email successfully copied',
  });

  const handleCopyLink = data => {
    if (navigator.clipboard) {
      navigator.clipboard
        .writeText(`${data}`)
        .then(() => {
          addToast({
            type: 'success',
            title: toastInfo.title,
            message: toastInfo.message,
          });
        })
        .catch(err =>
          addToast({
            type: 'error',
            title: 'Something go wrong',
            message: 'Something go wrong',
          }),
        );
    }
  };

  useEffect(() => {
    if (isCopiedPassword) {
      setToastInfo({
        ...toastInfo,
        title: 'Password copied',
        message: 'Password successfully copied',
      });
      setIsCopiedPassword(false);
    }

    if (isCopiedEmail) {
      setToastInfo({
        ...toastInfo,
        title: 'Email copied',
        message: 'Email successfully copied',
      });
      setIsCopiedEmail(false);
    }
  }, [isCopiedPassword, isCopiedEmail]);

  return (
    <ModalContainer
      modal={MODALS.showTemporaryCreds}
      onClose={modalData.onClose}
      customStyles={styles}
    >
      <div className="show-temporary-creds">
        <div className="show-temporary-creds__header">
          <span className="show-temporary-creds__header_text">
            {formatMessage('Copy your temporary credentials')}
          </span>
        </div>
        <div className="show-temporary-creds__block">
          <div className="show-temporary-creds__block_item-label">
            {formatMessage('Email')}
          </div>
          <div
            className="show-temporary-creds__container"
            style={
              isCopiedEmailStyle ? { borderColor: '#1187F4' } : { borderColor: '#D9DFE3' }
            }
          >
            <div className="show-temporary-creds__items">{modalData.email}</div>
            <div
              onClick={() => handleCopyLink(modalData.email)}
              className="show-temporary-creds__items__link"
              onMouseDown={() => setIsCopiedEmail(true)}
              onMouseUp={() => setIsCopiedEmailStyle(true)}
            >
              <div className="show-temporary-creds__items__link_copy-icon">
                <CopyIcon />
              </div>
            </div>
          </div>
          <div className="show-temporary-creds__block_item-label">
            {formatMessage('Password')}
          </div>
          <div
            className="show-temporary-creds__container"
            style={
              isCopiedPasswordStyle
                ? { borderColor: '#1187F4' }
                : { borderColor: '#D9DFE3' }
            }
          >
            <div className="show-temporary-creds__items">{modalData.password}</div>
            <div
              onClick={() => handleCopyLink(modalData.password)}
              className="show-temporary-creds__items__link"
              onMouseDown={() => setIsCopiedPassword(true)}
              onMouseUp={() => setIsCopiedPasswordStyle(true)}
            >
              <div className="show-temporary-creds__items__link_copy-icon">
                <CopyIcon />
              </div>
            </div>
          </div>
        </div>
      </div>
    </ModalContainer>
  );
};
