export enum ELabelIconPosition {
  LEFT = 'left',
  RIGHT = 'right',
}

export type TLabelType = 'accent' | 'green' | 'red';

export type TLabelStyle = 'tinted' | 'filled';

export enum ELableSize {
  S = 'size_s',
  M = 'size_m',
}
