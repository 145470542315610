import {
  GET_FLOW_DETAILS_REQUEST,
  GET_FLOW_DETAILS_SUCCESS,
  GET_FLOW_DETAILS_FAILURE,
  UPDATE_BLOCK_COORDINATES_REQUEST,
  UPDATE_BLOCK_COORDINATES_SUCCESS,
  ADD_NEW_BLOCK_REQUEST,
  ADD_NEW_BLOCK_SUCCESS,
  ADD_NEW_BLOCK_FAILURE,
  DELETE_BLOCK_REQUEST,
  DELETE_BLOCK_SUCCESS,
  DELETE_BLOCK_FAILURE,
  ADD_SELECTED_BLOCK,
  DELETE_SELECTED_BLOCK,
  UPDATE_RANDOMIZER_SUB_BLOCK_PERCENTAGE_REQUEST,
  UPDATE_SELECTED_BLOCK,
  DELETE_SUB_BLOCK,
  UPDATE_SELECTED_MENU_BLOCK,
  DELETE_MENU_SUB_BLOCK,
  DELETE_CONTENT_SUB_BLOCK,
  UPDATE_SELECTED_CONTENT_BLOCK,
  UPDATE_SELECTED_FILE_CONTENT_BLOCK,
  UPDATE_SELECTED_START_FLOW_BLOCK,
  UPDATE_SELECTED_ACTION_BLOCK,
  PUSH_TO_SELECTED_CONDITION_BLOCK,
  UPDATE_SELECTED_CONDITION_BLOCK,
  LOAD_IN_PROGRESS,
  LOAD_SUCCESS,
  LOAD_ERROR,
  UPDATE_BLOCK_COORDINATES_FAILURE,
  GET_SHARE_FLOW_DETAILS_REQUEST,
  GET_SHARE_FLOW_DETAILS_FAILURE,
  UPDATE_SELECTED_INTEGRATION_BLOCK,
  UPDATE_INTEGRATION_BLOCK,
  DELETE_INTEGRATION_SUB_BLOCK,
  UPDATE_SELECTED_SMART_DELAY_BLOCK_TYPE,
  UPDATE_SELECTED_MENU_BLOCK_TYPE,
  UPDATE_SELECTED_CONDITION_BLOCK_TYPE,
} from './constructorConstants';

export const constructorActions = {
  getFlowDetailsRequest() {
    return {
      type: GET_FLOW_DETAILS_REQUEST,
    };
  },
  getFlowDetailsSuccess(data) {
    return {
      type: GET_FLOW_DETAILS_SUCCESS,
      payload: {
        data,
      },
    };
  },
  getShareFlowDetailsRequest() {
    return {
      type: GET_SHARE_FLOW_DETAILS_REQUEST,
    };
  },
  getShareFlowDetailsFailure() {
    return {
      type: GET_SHARE_FLOW_DETAILS_FAILURE,
    };
  },
  getFlowDetailsFailure(error) {
    return {
      type: GET_FLOW_DETAILS_FAILURE,
      payload: {
        error,
      },
    };
  },
  updateBlockCoordinatesRequest(data) {
    return {
      type: UPDATE_BLOCK_COORDINATES_REQUEST,
      payload: {
        data,
      },
    };
  },
  updateBlockCoordinatesSuccess(data) {
    return {
      type: UPDATE_BLOCK_COORDINATES_SUCCESS,
      payload: {
        data,
      },
    };
  },
  updateSelectedConditionBlockType(data) {
    return {
      type: UPDATE_SELECTED_CONDITION_BLOCK_TYPE,
      payload: {
        data,
      },
    };
  },
  updateBlockCoordinatesFailure() {
    return {
      type: UPDATE_BLOCK_COORDINATES_FAILURE,
    };
  },
  addNewBlockRequest(data) {
    return {
      type: ADD_NEW_BLOCK_REQUEST,
      payload: {
        data,
      },
    };
  },
  addNewBlockSuccess(data) {
    return {
      type: ADD_NEW_BLOCK_SUCCESS,
      payload: {
        data,
      },
    };
  },
  addNewBlockFailure(error) {
    return {
      type: ADD_NEW_BLOCK_FAILURE,
      payload: {
        error,
      },
    };
  },
  deleteBlockRequest(data) {
    return {
      type: DELETE_BLOCK_REQUEST,
      payload: {
        data,
      },
    };
  },
  deleteBlockSuccess(data) {
    return {
      type: DELETE_BLOCK_SUCCESS,
      payload: {
        data,
      },
    };
  },
  deleteBlockFailure(error) {
    return {
      type: DELETE_BLOCK_FAILURE,
      payload: {
        error,
      },
    };
  },
  addSelectedBlock(data) {
    return {
      type: ADD_SELECTED_BLOCK,
      payload: {
        data,
      },
    };
  },
  deleteSelectedBlock() {
    return {
      type: DELETE_SELECTED_BLOCK,
      payload: {
        data: {},
      },
    };
  },
  updateRandomizerSubBlockPercentageRequest(data) {
    return {
      type: UPDATE_RANDOMIZER_SUB_BLOCK_PERCENTAGE_REQUEST,
      payload: {
        data,
      },
    };
  },
  updateSelectedBlock(data) {
    return {
      type: UPDATE_SELECTED_BLOCK,
      payload: {
        data,
      },
    };
  },
  updateSelectedMenuBlock(data) {
    return {
      type: UPDATE_SELECTED_MENU_BLOCK,
      payload: {
        data,
      },
    };
  },
  updateSelectedIntegrationBlock(data, type) {
    return {
      type: UPDATE_SELECTED_INTEGRATION_BLOCK,
      payload: {
        data,
        type,
      },
    };
  },
  deleteIntegrationSubBlock(data) {
    return {
      type: DELETE_INTEGRATION_SUB_BLOCK,
      payload: {
        data,
      },
    };
  },
  deleteMenuSubBlock(data) {
    return {
      type: DELETE_MENU_SUB_BLOCK,
      payload: {
        data,
      },
    };
  },
  deleteSubBlock(data) {
    return {
      type: DELETE_SUB_BLOCK,
      payload: {
        data,
      },
    };
  },
  deleteContentSubBlock(data) {
    return {
      type: DELETE_CONTENT_SUB_BLOCK,
      payload: {
        data,
      },
    };
  },
  updateSelectedContentBlock(data) {    
    return {
      type: UPDATE_SELECTED_CONTENT_BLOCK,
      payload: {
        data,
      },
    };
  },
  updateSelectedFileContentBlock(data) {
    return {
      type: UPDATE_SELECTED_FILE_CONTENT_BLOCK,
      payload: {
        data,
      },
    };
  },
  updateSelectedSmartDelayBlockBlockType(data) {
    return {
      type: UPDATE_SELECTED_SMART_DELAY_BLOCK_TYPE,
      payload: {
        data,
      },
    };
  },
  updateSelectedStartFlowBlock(data) {
    return {
      type: UPDATE_SELECTED_START_FLOW_BLOCK,
      payload: {
        data,
      },
    };
  },
  updateActionBlock(data) {
    return {
      type: UPDATE_SELECTED_ACTION_BLOCK,
      payload: {
        data,
      },
    };
  },
  updateIntegrationBlock(data, type) {
    return {
      type: UPDATE_INTEGRATION_BLOCK,
      payload: {
        data,
        type,
      },
    };
  },

  updateSelectedMenuBlockType(data) {
    return {
      type: UPDATE_SELECTED_MENU_BLOCK_TYPE,
      payload: {
        data,
      },
    };
  },
  pushToSelectedConditionBlock(data) {
    return {
      type: PUSH_TO_SELECTED_CONDITION_BLOCK,
      payload: {
        data,
      },
    };
  },
  updateSelectedConditionBlock(data) {
    return {
      type: UPDATE_SELECTED_CONDITION_BLOCK,
      payload: {
        data,
      },
    };
  },
  loadInProgress() {
    return {
      type: LOAD_IN_PROGRESS,
      payload: {
        status: 'load',
      },
    };
  },
  loadSuccess() {
    return {
      type: LOAD_SUCCESS,
      payload: {
        status: 'success',
      },
    };
  },
  loadFailure() {
    return {
      type: LOAD_ERROR,
      payload: {
        status: 'failure',
      },
    };
  },
};
