export const GET_BROADCAST_USERS_REQUEST = 'GET_BROADCAST_USERS_REQUEST';
export const GET_BROADCAST_USERS_SUCCESS = 'GET_BROADCAST_USERS_SUCCESS';
export const GET_BROADCAST_USERS_NEXT_REQUEST = 'GET_BROADCAST_USERS_NEXT_REQUEST';
export const GET_BROADCAST_USERS_NEXT_SUCCESS = 'GET_BROADCAST_USERS_NEXT_SUCCESS';
export const ADD_FILTER_TO_BROADCAST = 'ADD_FILTER_TO_BROADCAST';
export const DELETE_FILTER_FROM_BROADCAST = 'DELETE_FILTER_FROM_BROADCAST';
export const DELETE_ALL_FILTERS_FROM_BROADCAST = 'DELETE_ALL_FILTERS_FROM_BROADCAST';
export const SET_FILTER_TYPE_TO_BROADCAST = 'SET_FILTER_TYPE_TO_BROADCAST';
export const UPDATE_BROADCAST_USERS_SORT_TYPE = 'UPDATE_BROADCAST_USERS_SORT_TYPE';
export const UPDATE_BROADCAST_USERS_SUBSCRIBED_SORT_TYPE =
  'UPDATE_BROADCAST_USERS_SUBSCRIBED_SORT_TYPE';
export const SET_BROADCAST_QUERY_STRING = 'SET_BROADCAST_QUERY_STRING';
export const SET_BROADCAST_USERS_SEARCH_NAME = 'SET_BROADCAST_USERS_SEARCH_NAME';
export const SET_SHOW_USERS_IN_BROADCAST_CREATE_MODAL =
  'SET_SHOW_USERS_IN_BROADCAST_CREATE_MODAL';
export const CLEAR_REDUCER = 'CLEAR_REDUCER';
export const SET_SAVED_FILTER = 'SET_SAVED_FILTER';
export const CLEAR_NEXT = 'CLEAR_NEXT';
