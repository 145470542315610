import React from 'react';
import './BcToggleButton.scss';

export const BcToggleButton = ({ htmlFor, name, id, checked, onClick, disabled }) => (
  <div className="toggle-switch" onClick={onClick}>
    <input
      type="checkbox"
      className="toggle-switch-checkbox"
      name={name}
      id={id}
      checked={checked}
      disabled={disabled}
    />
    <label className="toggle-switch-label" htmlFor={htmlFor}>
      <span
        className={
          checked ? 'toggle-switch-inner toggle-switch-disabled' : 'toggle-switch-inner'
        }
      />
      <span
        className={
          checked ? 'toggle-switch-switch toggle-switch-disabled' : 'toggle-switch-switch'
        }
      />
    </label>
  </div>
);
