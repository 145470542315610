import { all, fork } from 'redux-saga/effects';

import { authSagas } from './auth/authSagas';
import { companiesSagas } from './companies/companiesSagas';
// import { flowsSagas } from './flows/flowsSagas';
import { audienceSagas } from './audience/audienceSagas';
import { constructorSagas } from './constructor/constructorSagas';
import { chatSagas } from './liveChat/chatSagas';
import { notificationsSagas } from './notifications/notificationsSagas';
import { dashBoardSagas } from './dashboard/dashBoardSagas';
import { variablesSagas } from './variables/variablesSagas';
import { tagTypesSagas } from './tagTypes/tagTypesSagas';
import { integrationEventsSagas } from './integrationEvents/integrationEventsSagas';
import { broadcastSagas } from './broadcast/broadcastSagas';
import { groupCampaignsSagas } from './groupCampaigns/groupCampaignsSagas';
import { groupsSagas } from './groups/groupsSagas';
import { customFieldsSagas } from './customFields/customFieldsSagas';
import { bcSplitTableSagas } from './bcSplitTable/bcSplitTableSagas';

export const rootSaga = function* rootSaga() {
  yield all([
    fork(authSagas),
    fork(companiesSagas),
    fork(audienceSagas),
    // fork(flowsSagas),
    fork(constructorSagas),
    fork(chatSagas),
    fork(notificationsSagas),
    fork(dashBoardSagas),
    fork(variablesSagas),
    fork(tagTypesSagas),
    fork(integrationEventsSagas),
    fork(broadcastSagas),
    fork(customFieldsSagas),
    fork(groupCampaignsSagas),
    fork(groupsSagas),
    fork(bcSplitTableSagas),
  ]);
};
