import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import debounce from 'lodash/debounce';
import FormField from '@wabb-ui-kit/FormField/FormField';
import { EComponentType, EIconDirection } from '@wabb-ui-kit/FormField/types/types';
import { audienceActions } from '@redux/audience/audienceActions';
import { useInternationalization } from '@hooks/useTranslationHook';

import {
  getAllUsersSelector,
  getFilters,
  getFilterType,
  getSearchNameSelector,
} from '@redux/audience/audienceSelectors';

import {
  getBroadcastFilterTypeSelector,
  getBroadcastUsersFiltersSelector,
  getBroadcastUsersSearchNameSelector,
} from '@redux/broadcast/broadcastSelector';
import { broadcastActions } from '@redux/broadcast/broadcastActions';

import { HeaderFilters } from './HeaderFilters/HeaderFilters';
import { LoopIcon } from '@wabb-assets/svg/FormField/loop-icon';
import '../audienceFilters.scss';

export const AudienceFiltersHeader = ({ broadcasts, userName, setUserName }) => {
  const { formatMessage } = useInternationalization();
  const dispatch = useDispatch();

  const onCrossClick = () => {
    setUserName('');
    delayedUpdateSearchName('');
  };

  const users = useSelector(getAllUsersSelector);

  const filters = broadcasts
    ? useSelector(getBroadcastUsersFiltersSelector)
    : useSelector(getFilters);

  const searchName = broadcasts
    ? useSelector(getBroadcastUsersSearchNameSelector)
    : useSelector(getSearchNameSelector);

  const filterType = broadcasts
    ? useSelector(getBroadcastFilterTypeSelector)
    : useSelector(getFilterType);

  const changeFilterType = () => {
    broadcasts
      ? dispatch(broadcastActions.setFilterTypeToBroadcast(filterType ? 0 : 1))
      : dispatch(audienceActions.setFilterType(filterType ? 0 : 1));
  };

  const delayedUpdateSearchName = useCallback(
    debounce(value => {
      dispatch(audienceActions.setSearchName(value));
    }, 800),
    [],
  );

  const handleSearchName = event => {
    const { value } = event.target;
    setUserName(value);
    delayedUpdateSearchName(value);
  };

  useEffect(() => {
    if (searchName) {
      setUserName(searchName);
    }
  }, [searchName]);

  return (
    <div
      className="audience-filters__btn-group_container"
      style={broadcasts && { paddingBottom: '10px' }}
    >
      <div className="audience-filters__btn-group_container_panel">
        <div>
          <HeaderFilters broadcasts={broadcasts} filters={filters} />
        </div>
      </div>
      {!broadcasts && (
        <div className="audience-filters__btn-group_container_panel_search">
          <div>
            <FormField
              placeholder="Search"
              value={userName}
              Component={EComponentType.Input}
              onChange={handleSearchName}
              icon={<LoopIcon color="#667085" />}
              onCrossClick={onCrossClick}
              width={320}
              iconDirection={EIconDirection.Left}
            />
          </div>
        </div>
      )}
    </div>
  );
};
