import { companiesApi } from '../../api/companiesApi';
import {
  GET_FLOW_DETAILS_REQUEST,
  GET_FLOW_DETAILS_SUCCESS,
  UPDATE_BLOCK_COORDINATES_REQUEST,
  UPDATE_BLOCK_COORDINATES_SUCCESS,
  ADD_NEW_BLOCK_REQUEST,
  ADD_NEW_BLOCK_SUCCESS,
  DELETE_BLOCK_REQUEST,
  DELETE_BLOCK_SUCCESS,
  ADD_SELECTED_BLOCK,
  DELETE_SELECTED_BLOCK,
  UPDATE_SELECTED_BLOCK,
  DELETE_SUB_BLOCK,
  DELETE_INTEGRATION_SUB_BLOCK,
  UPDATE_SELECTED_MENU_BLOCK,
  DELETE_MENU_SUB_BLOCK,
  DELETE_CONTENT_SUB_BLOCK,
  UPDATE_SELECTED_CONTENT_BLOCK,
  UPDATE_SELECTED_FILE_CONTENT_BLOCK,
  UPDATE_SELECTED_START_FLOW_BLOCK,
  UPDATE_SELECTED_ACTION_BLOCK,
  PUSH_TO_SELECTED_CONDITION_BLOCK,
  UPDATE_SELECTED_CONDITION_BLOCK,
  LOAD_ERROR,
  LOAD_IN_PROGRESS,
  LOAD_SUCCESS,
  UPDATE_BLOCK_COORDINATES_FAILURE,
  GET_SHARE_FLOW_DETAILS_FAILURE,
  UPDATE_SELECTED_INTEGRATION_BLOCK,
  UPDATE_INTEGRATION_BLOCK,
  UPDATE_SELECTED_SMART_DELAY_BLOCK_TYPE,
  UPDATE_SELECTED_MENU_BLOCK_TYPE,
  UPDATE_SELECTED_CONDITION_BLOCK_TYPE,
} from './constructorConstants';

const initialState = {
  selectedBlock: {},
  flowName: '',
  flowFolder: {},
  stateOfConstructor: 'success',
};

export const constructorReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_FLOW_DETAILS_REQUEST:
      return {
        ...state,
        selectedBlock: {},
        stateOfConstructor: 'load',
      };
    case GET_FLOW_DETAILS_SUCCESS:
      const { data } = action.payload;
      return {
        ...state,
        blocks: data.blocks ? [...data.blocks] : [...data],
        stateOfConstructor: 'success',
        flowName: data.flow_name,
        flowFolder: data.folder,
        details: {
          companyName: data.company_name,
          companyAvatar: data.company_avatar,
          flowName: data.name,
          cloneAllow: data.is_cloning_available,
          allowedCompaniesForCloning: data.allowed_companies_for_cloning,
          isFlowProtected: data.is_flow_protected,
        },
      };
    case GET_SHARE_FLOW_DETAILS_FAILURE:
      return {
        ...state,
        blocks: 'error',
      };
    case UPDATE_BLOCK_COORDINATES_REQUEST:
      return {
        ...state,
        stateOfConstructor: 'load',
      };
    case UPDATE_BLOCK_COORDINATES_SUCCESS:
      return {
        ...state,
        stateOfConstructor: 'success',
      };
    case UPDATE_BLOCK_COORDINATES_FAILURE:
      return {
        ...state,
        stateOfConstructor: 'failure',
      };
    case ADD_NEW_BLOCK_REQUEST:
      return {
        ...state,
      };
    case ADD_NEW_BLOCK_SUCCESS:
      return {
        ...state,
      };
    case DELETE_BLOCK_REQUEST:
      return {
        ...state,
        stateOfConstructor: 'load',
      };
    case DELETE_BLOCK_SUCCESS:
      return {
        ...state,
        stateOfConstructor: 'success',
      };
    case ADD_SELECTED_BLOCK:
      return {
        ...state,
        selectedBlock: action.payload.data,
      };
    case DELETE_SELECTED_BLOCK:
      return {
        ...state,
        selectedBlock: action.payload.data,
      };
    case UPDATE_SELECTED_BLOCK:
      const newSelectedBlock = { ...state.selectedBlock };
      newSelectedBlock.props.randomizer_block.randomizer_sub_block.push(
        action.payload.data,
      );
      return {
        ...state,
        selectedBlock: newSelectedBlock,
      };
    case DELETE_SUB_BLOCK:
      const newBlock = { ...state.selectedBlock };
      const updatedBlock = [
        ...newBlock.props.randomizer_block.randomizer_sub_block.filter(
          item => item.id !== action.payload.data,
        ),
      ];
      newBlock.props.randomizer_block.randomizer_sub_block = [...updatedBlock];
      return {
        ...state,
        selectedBlock: newBlock,
      };
    case DELETE_MENU_SUB_BLOCK:
      const newMenuBlock = { ...state.selectedBlock };
      const updatedMenuBlock = [
        ...newMenuBlock.props.menu_block.menu_sub_block.filter(
          item => item.id !== action.payload.data,
        ),
      ];
      newMenuBlock.props.menu_block.menu_sub_block = [...updatedMenuBlock];
      return {
        ...state,
        selectedBlock: newMenuBlock,
      };

    case DELETE_INTEGRATION_SUB_BLOCK:
      const newIntegrationBlock = { ...state.selectedBlock };
      const updatedIntegrationBlock = [
        ...newIntegrationBlock.props.integration_block.integration_sub_block.filter(
          item => item.id !== action.payload.data.id,
        ),
      ];
      newIntegrationBlock.props.integration_block.integration_sub_block = [
        ...updatedIntegrationBlock,
      ];
      return {
        ...state,
        selectedBlock: newIntegrationBlock,
      };
    case UPDATE_SELECTED_MENU_BLOCK:
      const newSelectedMenuBlock = { ...state.selectedBlock };
      const { typeData } = action.payload.data;
      if (typeData === 'menu') {
        newSelectedMenuBlock.props[`${typeData}_block`][`${typeData}_sub_block`].push(
          action.payload.data,
        );
      } else {
        newSelectedMenuBlock.props[`${typeData}_block`][`${typeData}_sub_block`].push({
          id: action.payload.data.action_sub_block,
          type: +action.payload.data.typeId,
          [`${action.payload.data.type}_action_sub_block`]: {
            ...action.payload.data,
          },
        });
      }

      return {
        ...state,
        selectedBlock: newSelectedMenuBlock,
      };
    case UPDATE_SELECTED_SMART_DELAY_BLOCK_TYPE:
      const newSelSmartDelayBlock = action.payload.data;
      return {
        ...state,
        selectedBlock: { ...state.selectedBlock, ...newSelSmartDelayBlock },
      };

    case UPDATE_SELECTED_CONDITION_BLOCK_TYPE:
      const updConditionBlock = { ...state.selectedBlock };
      const updSelectedConditionBlock = {
        ...updConditionBlock.props.condition_block,
        ...action.payload.data,
      };
      updConditionBlock.props.condition_block = { ...updSelectedConditionBlock };
      return {
        ...state,
        selectedBlock: updConditionBlock,
      };
    case UPDATE_SELECTED_INTEGRATION_BLOCK:
      const newSelectedIntegrationBlock = { ...state.selectedBlock };

      if (action.payload.type === 0) {
        newSelectedIntegrationBlock.props.integration_block.integration_sub_block.push({
          id: action.payload.data.integration_sub_block,
          type: 0,
          zapier_integration_sub_block: { ...action.payload.data },
        });
      } else {
        newSelectedIntegrationBlock.props.integration_block.integration_sub_block.push({
          id: action.payload.data.integration_sub_block,
          type: 1,
          request_integration_sub_block: { ...action.payload.data },
        });
      }

      return {
        ...state,
        selectedBlock: newSelectedIntegrationBlock,
      };
    case UPDATE_SELECTED_MENU_BLOCK_TYPE:
      const newSelMenuBlock = action.payload.data;
      return {
        ...state,
        selectedBlock: {
          ...state.selectedBlock,
          menu_sub_block: newSelMenuBlock.menu_sub_block,
          type_of_menu: newSelMenuBlock.type_of_menu,
        },
      };
    case UPDATE_INTEGRATION_BLOCK:
      const newZapierIntegrationBlock = { ...state.selectedBlock };

      if (action.payload.type === 0) {
        const updatedZapierIntegrationBlock = [
          ...newZapierIntegrationBlock.props.integration_block.integration_sub_block.filter(
            item => {
              if (item.id === action.payload.data.integration_sub_block) {
                return (item.zapier_integration_sub_block = action.payload.data);
              }

              return item;
            },
          ),
        ];
        newZapierIntegrationBlock.props.integration_block.integration_sub_block = [
          ...updatedZapierIntegrationBlock,
        ];
      } else {
        const updatedRequestIntegrationBlock = [
          ...newZapierIntegrationBlock.props.integration_block.integration_sub_block.filter(
            item => {
              if (item.id === action.payload.data.integration_sub_block) {
                return (item.request_integration_sub_block = action.payload.data);
              }

              return item;
            },
          ),
        ];
        newZapierIntegrationBlock.props.integration_block.integration_sub_block = [
          ...updatedRequestIntegrationBlock,
        ];
      }

      return {
        ...state,
        selectedBlock: newZapierIntegrationBlock,
      };
    case DELETE_CONTENT_SUB_BLOCK:
      const { id, type } = action.payload.data;
      const newContentBlock = { ...state.selectedBlock };
      const updatedContentBlock = [
        ...newContentBlock.props[`${type}_block`][`${type}_sub_block`].filter(
          item => item.id !== id,
        ),
      ];
      newContentBlock.props[`${type}_block`][`${type}_sub_block`] = [
        ...updatedContentBlock,
      ];
      return {
        ...state,
        selectedBlock: newContentBlock,
      };
    case UPDATE_SELECTED_CONTENT_BLOCK:
      const newSelectedContentBlock = { ...state.selectedBlock };
      newSelectedContentBlock.props.content_block.content_sub_block.push({
        id: action.payload.data.content_sub_block,
        type: action.payload.data.typeId,
        [`${action.payload.data.type}_content_sub_block`]: {
          ...action.payload.data,
        },
      });
      return {
        ...state,
        selectedBlock: newSelectedContentBlock,
      };
    case UPDATE_SELECTED_FILE_CONTENT_BLOCK:
      const newFileContentBlock = { ...state.selectedBlock };
      const updatedFileContentBlock = [
        ...newFileContentBlock.props.content_block.content_sub_block.filter(item => {
          if (item.id === action.payload.data.content_sub_block) {
            return (item[`${action.payload.data.type}_content_sub_block`] =
              action.payload.data);
          }

          return item;
        }),
      ];
      newFileContentBlock.props.content_block.content_sub_block = [
        ...updatedFileContentBlock,
      ];
      return {
        ...state,
        selectedBlock: newFileContentBlock,
      };
    case UPDATE_SELECTED_ACTION_BLOCK:
      const newActionBlock = { ...state.selectedBlock };
      const updatedActionBlock = [
        ...newActionBlock.props.action_block.action_sub_block.filter(item => {
          if (item.id === action.payload.data.action_sub_block) {
            return (item[`${action.payload.data.type}_action_sub_block`] =
              action.payload.data);
          }

          return item;
        }),
      ];
      newActionBlock.props.action_block.action_sub_block = [...updatedActionBlock];
      return {
        ...state,
        selectedBlock: newActionBlock,
      };
    case UPDATE_SELECTED_START_FLOW_BLOCK:
      const newSelectedStartFlowBlock = { ...state.selectedBlock };
      newSelectedStartFlowBlock.props.start_flow_block.to_flow = {
        ...action.payload.data,
      };
      return {
        ...state,
        selectedBlock: newSelectedStartFlowBlock,
      };
    case PUSH_TO_SELECTED_CONDITION_BLOCK:
      const newSelectedConditionBlock = { ...state.selectedBlock };
      newSelectedConditionBlock.props.condition_block.condition_sub_block.push({
        id: action.payload.data.condition_sub_block,
        type: action.payload.data.type,
        [`condition_${action.payload.data.url}_sub_block`]: {
          ...action.payload.data,
        },
      });
      return {
        ...state,
        selectedBlock: newSelectedConditionBlock,
      };
    case UPDATE_SELECTED_CONDITION_BLOCK:
      const newConditionBlock = { ...state.selectedBlock };
      const updatedConditionBlock = [
        ...newConditionBlock.props.condition_block.condition_sub_block.filter(item => {
          if (item.id === action.payload.data.condition_sub_block) {
            return (item[`condition_${action.payload.data.typeVar}_sub_block`] =
              action.payload.data);
          }

          return item;
        }),
      ];
      newConditionBlock.props.condition_block.condition_sub_block = [
        ...updatedConditionBlock,
      ];
      return {
        ...state,
        selectedBlock: newConditionBlock,
      };
    case LOAD_SUCCESS:
      const { status } = action.payload;
      return {
        ...state,
        stateOfConstructor: status,
      };
    case LOAD_IN_PROGRESS:
      return {
        ...state,
        stateOfConstructor: action.payload.status,
      };
    case LOAD_ERROR:
      return {
        ...state,
        stateOfConstructor: action.payload.status,
      };
    default:
      return state;
  }
};
