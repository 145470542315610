import { Button } from 'custom-ui-kit-with-for-react-app';
import './ErrorBoundary.scss';

const ErrorFallbackPage = ({ errorInfo }) => {

  const handleBackHome = () => {
    window.location.replace('/')
  };

  return (
    <div className="error-fallback-page">
      <div className="container">
        <div className="error-header">
          <span className="error-icon">✗</span>
          <span className="error-title">Error</span>
        </div>
        <pre className="error-message">
          <code>{errorInfo?.message}</code>
        </pre>
        <pre className="error-stack">
          <code>{errorInfo?.stack}</code>
        </pre>
        <Button
          type="negative"
          style="filled"
          className="back-home-btn"
          onClick={handleBackHome}
        >
          Back Home
        </Button>
      </div>
    </div>
  );
};

export default ErrorFallbackPage;