import React, { useEffect, useState, lazy, Suspense } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { useIntercom } from 'react-use-intercom';
import { useContextSelector } from 'use-context-selector';
import useMediaQuery from '@mui/material/useMediaQuery';
import { use100vh } from 'react-div-100vh';
import classNames from 'classnames';
import jwtDecode from 'jwt-decode';
import { Redirect, Route, Switch, useHistory, useLocation } from 'react-router-dom';

import { NotFoundPage } from '@pages';
import { NotificationContainer } from '@components/containers';
import { RequireAuth } from '@components/RequireAuth';
import { navigationMenuActions } from '@redux/navigationMenu/navigationMenuActions';
import { getLoaderSelector } from '@redux/loader/loaderSelector';
import { companiesActions } from '@redux/companies/companiesActions';
import { loaderActions } from '@redux/loader/loaderActions';
import { authActions } from '@redux/auth/authActions';
import { Modals } from '@components/containers/Modal/Modals';
import Modal from '@wabb-ui-kit/Modal/Modal';
import { EConfirmModalType } from '@wabb-ui-kit/ConfirmModal/types/types';
import {
  getIsNeedToRedirect,
  isAuthorizedSelector,
  isEmailRegistrationInProgressSelector,
  isFranchiseOwnerSelector,
} from '@redux/auth/authSelectors';
import {
  getCompaniesSelector,
  getSelectedCompanySelector,
} from '@redux/companies/companiesSelectors';
import {
  headerStateSelector,
  navigationMenuStateSelector,
} from '@redux/navigationMenu/navigationMenuSelectors';
import { LoadingScreen } from '@components/loadingScreen/LoadingScreen';

import NavigationMenu from '@wabb-ui-kit/NavigationMenu/NavigationMenu.tsx';
import { pagesItems } from '@wabb-ui-kit/NavigationMenu/utils.tsx';
import { usersApi } from '@api/usersApi';
import { FranchiseContext } from './contexts/franchiseContext';
import { liveChatRooms } from './const/liveChatRooms';
import { Routes } from './const/routes';

import './App.scss';
import { VITE_IS_DEV } from './app/config';

const DashboardPage = lazy(() => import('./pages/dashboard/index'));
const AutomationPage = lazy(() => import('./pages/automation/index'));
const AudiencePage = lazy(() => import('./pages/audience/index'));
const BroadcastPage = lazy(() => import('./pages/broadcast/index'));
const CampaignsPage = lazy(() => import('./pages/campagins/index'));
const ConstructorPage = lazy(() => import('./pages/constructor/index'));
const FlowsPage = lazy(() => import('./pages/flows/index'));
const Login = lazy(() => import('./pages/login/index'));
const NewLiveChatPage = lazy(() => import('./pages/newLiveChat/index'));
const ProfilePage = lazy(() => import('./pages/profile/index'));
const SettingsPage = lazy(() => import('./pages/settings/index'));
const ShareFlowPage = lazy(() => import('./pages/shareFlow/index'));
const ComponentsPage = lazy(() => import('./pages/componentsPage/index'));

const App = () => {
  const isAuthorized = useSelector(isAuthorizedSelector);
  const navigationClass = useSelector(navigationMenuStateSelector);
  const selectedCompany = useSelector(getSelectedCompanySelector);
  const isFranchiseOwner = useSelector(isFranchiseOwnerSelector);
  const frCode = useContextSelector(FranchiseContext, ({ code }) => code);
  const isEmailRegistrationInProgress = useSelector(
    isEmailRegistrationInProgressSelector,
  );
  const [companyId, setCompanyId] = useState('%');
  const loading = useSelector(getLoaderSelector);
  const companies = useSelector(getCompaniesSelector);
  const { isNeedToRedirect, redirectRoute } = useSelector(getIsNeedToRedirect);
  const [chatRoom, setChatRoom] = useState('all');
  const isShowHeader = useSelector(headerStateSelector);
  const mobileHeight = use100vh();
  const [isWarningModal, setIsWarningModal] = useState(false);

  const [currentUser, setCurrentUser] = useState(null);

  const dashboardPath = `${companyId && companyId !== '%' ? `/${companyId}` : ''}${Routes.DashboardRoute
    }`;

  const history = useHistory();
  const dispatch = useDispatch();
  const location = useLocation();
  const urlIdCompany = location.pathname.split('/')[1];

  useEffect(() => {
    localStorage.setItem('lastPath', location.pathname);
  }, [location.pathname]);

  const {
    audience,
    dashboard,
    campaigns,
    liveChat,
    settings,
    flows,
    automation,
    broadcasts,
  } = selectedCompany?.permissions || {};

  const { boot } = useIntercom();

  const isSmallScreen = useMediaQuery('(max-width:768px)');

  useEffect(() => {
    if (isAuthorized && !isSmallScreen) {
      boot();
    }
  }, [isAuthorized, isSmallScreen]);

  useEffect(() => {
    const handleResize = () => {
      setIsWarningModal(window.innerWidth <= 1000);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    const refreshToken = localStorage.getItem('refreshToken');
    const authToken = localStorage.getItem('authToken');
    const lastPath = localStorage.getItem('lastPath') || '/';
    const currentPath = window.location.pathname;
    const searchParams = new URLSearchParams(location.search);

    if (refreshToken && authToken) {
      const isSamePath = lastPath === currentPath;

      history.push({
        pathname: lastPath,
        search: isSamePath ? searchParams.toString() : '',
      });
    } else {
      history.push({
        pathname: '/login',
        search: '',
      });
    }
  }, [history]);

  useEffect(() => {
    const prevSelectedCompanyId = localStorage.getItem('selectedCompany');

    const setCompany = company => {
      dispatch(
        companiesActions.setSelectedCompany({
          company,
          reload: false,
        }),
      );
    };

    switch (true) {
      case Boolean(urlIdCompany && !Number.isNaN(Number(urlIdCompany))): {
        const company = companies.find(
          companyItem => companyItem.id.toString() === urlIdCompany,
        );
        setCompanyId(urlIdCompany);

        if (company) {
          setCompany(company);
        } else if (companies.length) {
          history.push('/not-found');
        }

        break;
      }

      case Boolean(selectedCompany && selectedCompany.hasOwnProperty('id')): {
        localStorage.setItem('selectedCompany', selectedCompany.id);
        setCompanyId(selectedCompany.id);
        break;
      }

      case Boolean(prevSelectedCompanyId): {
        const company =
          prevSelectedCompanyId &&
          companies.length &&
          companies.find(
            companyItem => companyItem.id.toString() === prevSelectedCompanyId,
          );

        if (company) {
          setCompany(company);
        } else {
          setCompany(companies[0]);
        }

        break;
      }

      default: {
        if (companies.length) {
          setCompany(companies[0]);
          localStorage.setItem('selectedCompany', companies[0].id);
        }
      }
    }
  }, [companies, selectedCompany, urlIdCompany]);

  useEffect(() => {
    if (!isEmailRegistrationInProgress && isAuthorized) {
      const token = localStorage.getItem('authToken');
      const { user_id: userId } = jwtDecode(token);
      dispatch(authActions.setUserId(userId));

      if (location.pathname.includes('/share-flow')) {
        dispatch(
          companiesActions.getCompaniesRequest({
            onlyActiveCompanies: true,
            frCode,
            isFranchiseOwner,
            isEmailRegistrationInProgress,
          }),
        );
        dispatch(loaderActions.loaderOn());
      } else {
        dispatch(
          companiesActions.getCompaniesRequest({
            frCode,
            isFranchiseOwner,
            isEmailRegistrationInProgress,
          }),
        );
        dispatch(loaderActions.loaderOn());
      }
    }

    if (!isAuthorized) {
      dispatch(loaderActions.loaderOff());
    }
  }, [isAuthorized, isEmailRegistrationInProgress]);

  useEffect(() => {
    if (selectedCompany?.permissions) {
      const availableRoom = liveChatRooms.find(
        ({ fieldName }) => selectedCompany.permissions[fieldName],
      );

      if (availableRoom) {
        setChatRoom(availableRoom.routeParam);
      }
    }
  }, [selectedCompany?.permissions]);

  useEffect(() => {
    if (isNeedToRedirect) {
      dispatch(authActions.redirectSuccess());
      history.push(redirectRoute);
    }
  }, [dispatch, history, redirectRoute, isNeedToRedirect]);

  const setNavigationClass = () => {
    if (navigationClass === 'open') {
      dispatch(navigationMenuActions.closeMenu());
    } else {
      dispatch(navigationMenuActions.openMenu());
    }
  };

  useEffect(() => {
    if (isAuthorized) {
      usersApi.getUsersMe().then(res => setCurrentUser(res.data));
    }
  }, [isAuthorized]);

  const showNavigationMenu =
    isAuthorized &&
    companyId &&
    !loading &&
    !location.pathname.startsWith(Routes.LoginRoute) &&
    !location.pathname.startsWith(Routes.ShareFlowRoute);

  const navigationMenuFilterOption = item => item.show;

  return (
    <div className="app">
      {loading ? (
        <LoadingScreen logo={false} />
      ) : (
        <>
          {showNavigationMenu && (
            <NavigationMenu
              items={pagesItems.filter(navigationMenuFilterOption)}
              open={navigationClass === 'open'}
              onToggle={setNavigationClass}
              userInfo={currentUser}
              selectedCompanyId={companyId}
            />
          )}
          <Switch>
            <Route path={Routes.LoginRoute} component={Login} />
            {VITE_IS_DEV && (
              <Route
                exact
                path={dashboardPath}
                render={() => (
                  <Suspense fallback={null}>
                    <RequireAuth booleanPermission={Boolean(dashboard)}>
                      <div
                        className={classNames('app__content-section', navigationClass)}
                      >
                        <div className="app__content-section_body">
                          <DashboardPage />
                        </div>
                      </div>
                    </RequireAuth>
                  </Suspense>
                )}
              />
            )}
            <Route
              path={`${companyId && `/${companyId}`}${Routes.AutomationRoute}`}
              render={() => (
                <Suspense fallback={null}>
                  <RequireAuth booleanPermission={Boolean(automation)}>
                    <div className={classNames('app__content-section', navigationClass)}>
                      <div className="app__content-section_body">
                        <AutomationPage />
                      </div>
                    </div>
                  </RequireAuth>
                </Suspense>
              )}
            />
            <Route
              path={`${companyId && `/${companyId}`}${Routes.BroadcastRoute}`}
              render={() => (
                <Suspense fallback={null}>
                  <RequireAuth booleanPermission={Boolean(broadcasts)}>
                    <div className={classNames('app__content-section', navigationClass)}>
                      <div className="app__content-section_body">
                        <BroadcastPage />
                      </div>
                    </div>
                  </RequireAuth>
                </Suspense>
              )}
            />
            <Route
              path={`${companyId && `/${companyId}`}${Routes.SettingsRoute}`}
              render={() => (
                <Suspense fallback={null}>
                  <RequireAuth booleanPermission={Boolean(settings)}>
                    <div className={classNames('app__content-section', navigationClass)}>
                      <div className="app__content-section_body">
                        <SettingsPage />
                      </div>
                    </div>
                  </RequireAuth>
                </Suspense>
              )}
            />
            <Route
              path={`${companyId && `/${companyId}`}${Routes.AudienceRoute}`}
              render={() => (
                <Suspense fallback={null}>
                  <RequireAuth booleanPermission={Boolean(audience)}>
                    <div className={classNames('app__content-section', navigationClass)}>
                      <div className="app__content-section_body">
                        <AudiencePage />
                      </div>
                    </div>
                  </RequireAuth>
                </Suspense>
              )}
            />
            <Route
              path={`${companyId && `/${companyId}`}${Routes.LiveChatRoute
                }/:chatGroup/:chatId?`}
              render={() => (
                <Suspense fallback={null}>
                  <RequireAuth booleanPermission={Boolean(liveChat)}>
                    <div className={classNames('app__content-section', navigationClass)}>
                      <div
                        className={classNames('app__content-section_body')}
                        style={{ height: !isShowHeader ? mobileHeight : '' }}
                      >
                        <NewLiveChatPage />
                      </div>
                    </div>
                  </RequireAuth>
                </Suspense>
              )}
            />
            <Redirect
              from={`${companyId && `/${companyId}`}${Routes.LiveChatRoute}`}
              to={`${companyId && `/${companyId}`}${Routes.LiveChatRoute}/${chatRoom}`}
            />
            <Route
              path={`${companyId && `/${companyId}`}${Routes.ComponentsRoute}`}
              render={() => (
                <Suspense fallback={null}>
                  <RequireAuth booleanPermission={VITE_IS_DEV}>
                    <div className={classNames('app__content-section', navigationClass)}>
                      <div className="app__content-section_body">
                        <ComponentsPage />
                      </div>
                    </div>
                  </RequireAuth>
                </Suspense>
              )}
            />
            <Route
              path={`${companyId && `/${companyId}`}${Routes.FlowsRoute}`}
              render={() => (
                <Suspense fallback={null}>
                  <RequireAuth booleanPermission={Boolean(flows)}>
                    <div className={classNames('app__content-section', navigationClass)}>
                      <div className="app__content-section_body">
                        <FlowsPage />
                      </div>
                    </div>
                  </RequireAuth>
                </Suspense>
              )}
            />
            <Route
              path={`${companyId && `/${companyId}`}${Routes.CampaignsRoute}`}
              render={() => (
                <Suspense fallback={null}>
                  <RequireAuth booleanPermission={Boolean(campaigns)}>
                    <div className={classNames('app__content-section', navigationClass)}>
                      <div className="app__content-section_body">
                        <CampaignsPage />
                      </div>
                    </div>
                  </RequireAuth>
                </Suspense>
              )}
            />
            <Route
              path={`${companyId && `/${companyId}`}${Routes.CampaignsRoute}`}
              render={() => (
                <Suspense fallback={null}>
                  <RequireAuth booleanPermission={Boolean(campaigns)}>
                    <div className={classNames('app__content-section', navigationClass)}>
                      <div className="app__content-section_body">
                        <CampaignsPage />
                      </div>
                    </div>
                  </RequireAuth>
                </Suspense>
              )}
            />
            <Route
              path={`${companyId && `/${companyId}`}${Routes.ConstructorRoute}`}
              render={() => (
                <Suspense fallback={null}>
                  <RequireAuth booleanPermission={Boolean(flows)}>
                    <div className={classNames('app__content-section', navigationClass)}>
                      <div className="app__content-section_body_constructor">
                        <ConstructorPage key={location.pathname} />
                      </div>
                    </div>
                  </RequireAuth>
                </Suspense>
              )}
            />
            <Route
              path={`${Routes.ProfileRoute}`}
              render={() => (
                <Suspense fallback={null}>
                  <RequireAuth booleanPermission>
                    <div className={classNames('app__content-section', navigationClass)}>
                      <ProfilePage />
                    </div>
                  </RequireAuth>
                </Suspense>
              )}
            />
            <Route
              path={`${Routes.ShareFlowRoute}`}
              render={() => (
                <Suspense fallback={null}>
                  <RequireAuth booleanPermission>
                    <ShareFlowPage />
                  </RequireAuth>
                </Suspense>
              )}
            />
            <Route
              exact
              path="/"
              render={() =>
                selectedCompany &&
                selectedCompany.hasOwnProperty('id') && (
                  <Redirect to={`/${selectedCompany.id}/audience`} />
                )
              }
            />
            <Route path="*">
              <NotFoundPage />
            </Route>
          </Switch>
          <Modals />
          <NotificationContainer />
          {isWarningModal && (
            <Modal
              isOpen={isWarningModal}
              size="small"
              confirmModalType={EConfirmModalType.CONFIRM}
              modalStyle={{ bottom: '125px', maxHeight: '8vh', textAlign: 'center' }}
              hideCloseBtn
            >
              <div className="app__content-section_warning-modal-text">
                Use please web desktop version on your PC
              </div>
            </Modal>
          )}
        </>
      )}
    </div>
  );
};

const mapStateToProps = state => ({
  isAuthorized: isAuthorizedSelector(state),
});
export default connect(mapStateToProps)(App);
