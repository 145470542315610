import React, { useEffect, useRef, useState } from 'react';
import classnames from 'classnames';

import { useInternationalization } from '@hooks/useTranslationHook';

import './newBcfield.scss';

export const NewBcField = ({
  label,
  value,
  onChange,
  onBlurFunction,
  name,
  type,
  helperText,
  placeholder,
  Icon,
  disabled,
  customClass,
  ...props
}) => {
  const { formatMessage } = useInternationalization();

  const [showClearingFieldIcon, setShowClearingFieldIcon] = useState(false);
  const htmlElRef = useRef(null);

  useEffect(() => {
    if (value && value.length > 0 && document.activeElement === htmlElRef.current)
      setShowClearingFieldIcon(true);
    if (value && (value?.length === 0 || value === '')) setShowClearingFieldIcon(false);
    if (!value) setShowClearingFieldIcon(false);
  }, [value, setShowClearingFieldIcon, htmlElRef]);

  const handleBlur = e => {
    setShowClearingFieldIcon(false);
    props.onBlur && props.onBlur(e);
  };

  return (
    <div className="fields">
      {label && <div className="fields__title">{label}</div>}
      <div className="fields__container">
        <input
          key={type}
          className={classnames(
            value && !helperText
              ? 'fields__filled-input'
              : helperText && value
              ? 'fields__error-input'
              : value?.length === 0 && helperText
              ? 'fields__empty-input'
              : 'fields__input',
            customClass,
          )}
          name={name}
          type={type}
          ref={htmlElRef}
          onChange={e => onChange(e, name)}
          value={value}
          onBlur={onBlurFunction || handleBlur}
          onMouseDown={() => value && value?.length > 0 && setShowClearingFieldIcon(true)}
          placeholder={placeholder}
          disabled={disabled}
          {...props}
        />
        {Icon && (
          <div className="password-field__button-container">
            {showClearingFieldIcon && (
              <button
                className="password-field__button"
                onMouseDown={e => {
                  onChange(e, name);
                }}
              >
                <span>{Icon}</span>
              </button>
            )}
          </div>
        )}
      </div>

      {helperText && (
        <div className="fields__error-value">{formatMessage(helperText)}</div>
      )}
    </div>
  );
};
