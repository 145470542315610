import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import Modal from 'react-modal';

import { SetUpSupportEmailModal } from '@components/modals/setUpSupportEmail/setUpSupportEmailModal';
import { DeleteSupportEmailModal } from '@components/modals/deleteSupportEmailModal/deleteSupportEmailModal';
import { EditCustomEmailModal } from '@components/modals/editCustomEmailModal/editCustomEmailModal';
import { DeleteEmailDomainModal } from '@components/modals/deleteEmailDomainModal/deleteEmailDomainModal';
import { EditFranchiseAvatarModal } from '@components/modals/editFranchiseAvatarModal/editFranchiseAvatarModal';
import { EditCompanyModal } from '@components/modals/editCompanyModal/editCompanyModal';
import { InviteUserModal } from '@components/modals/inviteUserModal/inviteUserModal';
import { AnnounceModal } from '@components/modals/announceModal/announceModal';
import { TwoFactorAuthenticationModal } from '@components/modals/TwoFactorAuthenticationModal/TwoFactorAuthenticationModal';
import { PasswordUpdateCheckModal } from '@components/modals/passwordUpdateCheckModal/passwordUpdateCheckModal';
import { SetUpHelpLinkModal } from '@components/modals/setUpHelpLinkModal/setUpHelpLinkModal';
import { CreateEditFastRepliesModal } from '@components/modals/createEditFastRepliesModal/createEditFastRepliesModal';
import { MoveToModal } from '../../modals/MoveToModal/MoveToModal';
import {
  NewCompanyModal,
  CustomFieldsModal,
  BotsModal,
  BotFoldersModal,
  CampaignModal,
  CampaignQRModal,
  DeleteItemModal,
  ShareFlowModal,
  SaveFlowModal,
  SeqSubModal,
  EmailModal,
  PasswordModal,
  ManagerModal,
  // FirstPromocodeModal,
  IntegrationEventModal,
  ChatModal,
  ChatPermissionsModal,
  UploadModal,
  DynamicRequestModal,
  ShareTemplateModal,
  BroadcastCreateModal,
  checkHTTPBodyModal,
  ClearDataModal,
  TagTypesModal,
  CreateGroupCampaignModal,
  CreatePaymentModal,
  ChangePasswordModal,
  TransferCompanyModal,
  LeaveManagerModal,
  CreateTemplateModal,
  CreateFranchiseUserModal,
  NewOwnerCompanyFranchiseModal,
  CreateCompanyFranchise,
  finishFranchiseRegistration,
  ShowTemporaryCreds,
  FranchisePermissionsModal,
  MdChatModal,
  AddNewContactInAudience,
  AudienceUserCardModal,
  ConfirmModal,
  AudienceDownloadReport,
  ContactNotExistModal,
  // AudienceUserDetailsModal,
  EditProfileModal,
  DeleteCompanyModal,
  CreateWebhookModal,
  CreateKeywordModal,
  CreateSequenceModal,
  QrScanningModal,
  DisconnectionModal,
  EditingCompanyModal,
  ChoiceFlowsModal,
  NotPaidModal,
  FrozenModal,
  CreateEditTagModal,
  EditFlowAndFolderModal,
  EditFieldAndFolderModal,
} from '../../modals';
import { MODALS } from './modalConstants';
import { getModalDataSelector } from './modalSelectors';

const modals = {
  // [MODALS.firstPromocode]: FirstPromocodeModal,
  [MODALS.newCompany]: NewCompanyModal,
  [MODALS.customFields]: CustomFieldsModal,
  [MODALS.bots]: BotsModal,
  [MODALS.folders]: BotFoldersModal,
  [MODALS.campaign]: CampaignModal,
  [MODALS.campaignQR]: CampaignQRModal,
  [MODALS.groupCampaigns]: CreateGroupCampaignModal,
  [MODALS.seqSub]: SeqSubModal,
  [MODALS.deleteItem]: DeleteItemModal,
  [MODALS.shareFlow]: ShareFlowModal,
  [MODALS.moveTo]: MoveToModal,
  [MODALS.saveFlow]: SaveFlowModal,
  [MODALS.email]: EmailModal,
  [MODALS.password]: PasswordModal,
  [MODALS.manager]: ManagerModal,
  [MODALS.integrationEvent]: IntegrationEventModal,
  [MODALS.userDetails]: AudienceUserCardModal,
  [MODALS.chat]: ChatModal,
  [MODALS.chatPermissions]: ChatPermissionsModal,
  [MODALS.franchisePermissions]: FranchisePermissionsModal,
  [MODALS.uploadUsers]: UploadModal,
  [MODALS.shareTemplate]: ShareTemplateModal,
  [MODALS.broadcast]: BroadcastCreateModal,
  [MODALS.dynamicRequest]: DynamicRequestModal,
  [MODALS.checkHTTPBody]: checkHTTPBodyModal,
  [MODALS.clearData]: ClearDataModal,
  [MODALS.changePassword]: ChangePasswordModal,
  [MODALS.editProfile]: EditProfileModal,
  [MODALS.transferCompany]: TransferCompanyModal,
  [MODALS.leaveManager]: LeaveManagerModal,
  [MODALS.deleteCompany]: DeleteCompanyModal,
  [MODALS.createTemplateModal]: CreateTemplateModal,
  [MODALS.createFranchiseUser]: CreateFranchiseUserModal,
  [MODALS.newOwnerCompanyFranchise]: NewOwnerCompanyFranchiseModal,
  [MODALS.createFranchiseCompany]: CreateCompanyFranchise,
  [MODALS.finishFranchiseRegistration]: finishFranchiseRegistration,
  [MODALS.showTemporaryCreds]: ShowTemporaryCreds,
  [MODALS.payment]: CreatePaymentModal,
  [MODALS.mdChat]: MdChatModal,
  [MODALS.addNewContact]: AddNewContactInAudience,
  [MODALS.tagTypes]: TagTypesModal,
  [MODALS.confirmModal]: ConfirmModal,
  [MODALS.downloadReport]: AudienceDownloadReport,
  [MODALS.contactNotExist]: ContactNotExistModal,
  [MODALS.createWebhookModal]: CreateWebhookModal,
  [MODALS.createKeywordModal]: CreateKeywordModal,
  [MODALS.createSequenceModal]: CreateSequenceModal,
  [MODALS.createEditTagModal]: CreateEditTagModal,
  [MODALS.createEditFastRepliesModal]: CreateEditFastRepliesModal,
  [MODALS.qrScanning]: QrScanningModal,
  [MODALS.disconnection]: DisconnectionModal,
  [MODALS.editingCompany]: EditingCompanyModal,
  [MODALS.setUpSupportEmail]: SetUpSupportEmailModal,
  [MODALS.deleteSupportEmail]: DeleteSupportEmailModal,
  [MODALS.editCustomEmail]: EditCustomEmailModal,
  [MODALS.deleteEmailDomainModal]: DeleteEmailDomainModal,
  [MODALS.openEditFranchiseAvatarModal]: EditFranchiseAvatarModal,
  [MODALS.editCompanyModal]: EditCompanyModal,
  [MODALS.choiceFlows]: ChoiceFlowsModal,
  [MODALS.inviteUser]: InviteUserModal,
  [MODALS.notPaid]: NotPaidModal,
  [MODALS.frozen]: FrozenModal,
  [MODALS.announce]: AnnounceModal,
  [MODALS.twoFactorAuthentication]: TwoFactorAuthenticationModal,
  [MODALS.passwordUpdateCheck]: PasswordUpdateCheckModal,
  [MODALS.setUpHelpLink]: SetUpHelpLinkModal,
  [MODALS.editFlowAndFolderModal]: EditFlowAndFolderModal,
  [MODALS.editFieldAndFolderModal]: EditFieldAndFolderModal,
};

export const ModalsComponent = (props) => {
  const { modalData } = props;
  const { modal } = modalData;

  const ModalType = modals[modal];

  useEffect(() => {
    const rootElement = document.getElementById('root');
    Modal.setAppElement(rootElement);
  }, []);

  return ModalType ? <ModalType modal={modal} /> : null;
};

// for some reason useSelector doesnt work (can"t find redux context)
// fortunately connect still working
const mapStateToProps = (state) => ({
  modalData: getModalDataSelector(state),
});

export const Modals = connect(mapStateToProps)(ModalsComponent);
