import React, { useEffect, useRef, useState } from 'react';
import moment from 'moment/moment';

import { AudienceFiltersMenuArrowIcon } from '@icons/audience/audience-filters-menu-arrow-icon';
import { useInternationalization } from '@hooks/useTranslationHook';
import { Popover } from '@ui-kit';
import classNames from 'classnames';
import { TimePopover } from '../../../pages/audience/components/audienceFilters/filtersPopover/components/dateTimePopover/TimePopover';
import { ListItemDateTime } from '../../../pages/audience/components/audienceFilters/filtersPopover/components/dateTimePopover/ListItemDateTime';

const months = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

const weekdays = ['Su', 'Mn', 'Tu', 'Wd', 'Th', 'Fr', 'Sa'];

export const NewDateTimePopover = (props) => {
  const { formatMessage } = useInternationalization();

  const refMonths = useRef();
  const refYears = useRef();

  const [selectedDate, setSelectedDate] = useState({
    day: moment().get('date'),
    month: moment().get('month'),
    year: moment().get('year'),
    hour: moment().get('hour'),
    minute: moment().get('minute'),
  });

  const [step, setStep] = useState(2);
  const [days, setDays] = useState([]);

  const years = Array.from({ length: 200 }, (v, k) => k + moment().year() - 99);

  useEffect(() => {
    props.changeDate(moment(selectedDate).format(props.format));
  }, [selectedDate]);

  useEffect(() => {
    const dayOfWeek = moment(
      `1-${selectedDate.month + 1}-${selectedDate.year}`,
      'DD-MM-YYYY',
    ).weekday();
    setDays(
      Array(dayOfWeek)
        .fill('', 0, dayOfWeek)
        .concat(
          Array.from(
            {
              length: moment().month(selectedDate.month).daysInMonth(),
            },
            (v, k) => k + 1,
          ),
        ),
    );
  }, [selectedDate.month, selectedDate.year]);

  useEffect(() => {
    if (refMonths.current) {
      refMonths.current.scrollTop = (selectedDate.month - 4 || 0) * 30;
    }

    if (refYears.current) {
      refYears.current.scrollTop = (Math.abs(moment().year() - 99 - selectedDate.year) - 4) * 30;
    }
  }, [step]);

  const changeMonthInDate = (n) => {
    let newDate;

    if (n > 0) {
      newDate = moment([selectedDate.year, selectedDate.month, selectedDate.day]).add(
        1,
        'months',
      );
    } else {
      newDate = moment([
        selectedDate.year,
        selectedDate.month,
        selectedDate.day,
      ]).subtract(1, 'months');
    }

    setSelectedDate({
      ...selectedDate,
      day: newDate.date(),
      month: newDate.month(),
      year: newDate.year(),
    });
  };

  return (
    <Popover renderTrigger={props.renderTrigger} size={{ width: 'auto' }}>
      <div className="date-time-popover">
        <div className="date-time-popover__date">
          <div className="date-time-popover__header">
            <div className="date-time-popover__header_label">
              <span>
                {formatMessage(months[selectedDate.month])}
              </span>
              <span>
                {selectedDate.year}
              </span>
              <div onClick={() => setStep(step === 1 ? 2 : 1)}>
                <AudienceFiltersMenuArrowIcon rotate={step === 1 ? 90 : 0} />
              </div>
            </div>
            {step === 2 && (
              <div className="date-time-popover__header_arrows">
                <div onClick={() => changeMonthInDate(-1)}>
                  <AudienceFiltersMenuArrowIcon rotate={180} />
                </div>
                <div onClick={() => changeMonthInDate(1)}>
                  <AudienceFiltersMenuArrowIcon rotate={0} />
                </div>
              </div>
            )}
          </div>
          <div className="date-time-popover__body">
            <div
              id="months"
              ref={refMonths}
              className="date-time-popover__body_container"
            >
              {months.map((el, index) => (
                <ListItemDateTime
                  key={el}
                  item={el}
                  active={index === selectedDate.month}
                  onClick={() => setSelectedDate({
                    ...selectedDate,
                    month: index,
                  })}
                />
              ))}
            </div>
            <div id="years" ref={refYears} className="date-time-popover__body_container">
              {years.map((el) => (
                <ListItemDateTime
                  key={el}
                  item={el}
                  active={el === selectedDate.year}
                  onClick={() => {
                    setSelectedDate({
                      ...selectedDate,
                      year: el,
                    });
                  }}
                />
              ))}
            </div>
            <div
              className={classNames({
                'date-time-popover__body_weekdays': step !== 2,
                'date-time-popover__body_weekdays_active': step === 2,
              })}
            >
              <div className="date-time-popover__body_weekdays_header">
                {weekdays.map((el) => (
                  <div
                    key={el}
                    className="date-time-popover__body_weekdays_header_element"
                  >
                    <span>{formatMessage(el)}</span>
                  </div>
                ))}
              </div>
              <div className="date-time-popover__body_weekdays_body">
                {days.map((el) => (
                  <div
                    key={el}
                    className={classNames(
                      'date-time-popover__body_weekdays_body_element',
                      {
                        'date-time-popover__body_weekdays_body_element_active':
                          selectedDate.day === el,
                        'date-time-popover__body_weekdays_body_block-element': el === '',
                      },
                    )}
                    onClick={() => {
                      if (el) {
                        setSelectedDate({
                          ...selectedDate,
                          day: el,
                        });
                      }
                    }}
                  >
                    <span>{el}</span>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
        {Boolean(props.dateTime) && (
          <div className="date-time-popover__datetime">
            <div className="date-time-popover__header">
              <div className="date-time-popover__header_label">
                <span>{formatMessage('Time')}</span>
              </div>
            </div>
            <TimePopover selectedDate={selectedDate} setSelectedDate={setSelectedDate} />
          </div>
        )}
      </div>
    </Popover>
  );
};
