export const PaymentLinks = {
  newCompanies: {
    userPro: 'replace',
    userStarter: 'replace',
  },
  notPaid: {
    userStarter: '',
    userPro: '',
  },
  billing: {
    renew: '',
    changePlan: '',
  },
  // studentPro: 'https://go.botconversa.com.br/subscribe/botconversa-pro-aluno',
  // studentStarter: 'https://go.botconversa.com.br/subscribe/botconversa-starter-aluno',
};
