import React, { FC, ReactNode } from 'react';
import { clsx } from 'clsx';
import { ELabelIconPosition, ELableSize, TLabelStyle, TLabelType } from './types/types';
import styles from './Label.module.scss';

interface ILabelProps {
  children: ReactNode;
  icon?: ReactNode;
  iconPosition?: ELabelIconPosition;
  size?: ELableSize;
  type: TLabelType;
  style: TLabelStyle;
  className?: string;
}

export const Label: FC<ILabelProps> = ({
  children,
  iconPosition,
  icon,
  size = ELableSize.M,
  type = 'primary',
  style = 'filled',
  className,
}) => {
  const labelClasses = clsx(styles.label, styles[type], styles[style], styles[size], {
    [styles['icon-left']]: iconPosition === ELabelIconPosition.LEFT,
    [styles['icon-right']]: iconPosition === ELabelIconPosition.RIGHT,
    className,
  });
  return (
    <div className={labelClasses}>
      {iconPosition === ELabelIconPosition.LEFT && icon}
      {children}
      {iconPosition === ELabelIconPosition.RIGHT && icon}
    </div>
  );
};
