import { keywordsApiRPC } from '@connectApi/baseApi';
import {
  KeyWordsBotId,
  KeywordsBulkActionDeleteRequest,
} from 'wabb-backend-api/build/es/keywords/keywords_pb';

export const keywordsApi = {
  getKeywords: (botId, searchName) => {
    return keywordsApiRPC.keyWords(
      KeyWordsBotId.fromJson({
        bot_id: botId,
         search_name:searchName ? searchName : ''
      }),
    );
  },

  createKeyWord: ({ name, botId, flow }) =>
    keywordsApiRPC.keyWordCreate({
      bot: botId,
      flow,
      name,
    }),

  updateKeyWord: (keywordId, data, botId) =>
    keywordsApiRPC.keyWordDetailPatch({
      ...data,
      bot: botId,
      id: keywordId,
    }),

  deleteKeyWord: id => keywordsApiRPC.keyWordDetailDelete({ pk: id }),

  bulkActionDelete: (botId, selectedOrUnselectedItemIds, selectedAllMode, searchName) =>
    keywordsApiRPC.keywordsBulkActionDelete(
      KeywordsBulkActionDeleteRequest.fromJson({
        bot_id: botId,
        selected_all: selectedAllMode,
        selected_or_unselected_items: selectedOrUnselectedItemIds,
        // search_name: searchName,
      }),
    ),
};
