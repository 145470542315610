import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Code, ConnectError } from '@connectrpc/connect';

import { getSelectedCompanySelector } from '@redux/companies/companiesSelectors';
import { useInternationalization } from '@hooks/useTranslationHook';
import { keywordsApi } from '@api/keywordsApi';
import { flowsApi } from '@api/flowsApi';
import { bcSplitTableActions } from '@redux/bcSplitTable/bcSplitTableActions';
import { ModalContainer } from '../../containers/Modal/ModalContainer';
import { MODALS } from '../../containers/Modal/modalConstants';
import { getModalDataSelector } from '../../containers/Modal/modalSelectors';
import FormField from '@wabb-ui-kit/FormField/FormField.tsx';
import Button from '@wabb-ui-kit/Button/Button.tsx';
import Dropdown from '@wabb-ui-kit/Dropdown/Dropdown.tsx';
import { EButtonSize } from '@wabb-ui-kit/Button/types/types.ts';

import './createKeywordModal.scss';

export const CreateKeywordModal = () => {
  const { formatMessage } = useInternationalization();
  const modalData = useSelector(getModalDataSelector).modalData;
  const botId = useSelector(getSelectedCompanySelector).bot;
  const dispatch = useDispatch();

  const [keywordName, setKeywordName] = useState(modalData.keywordName);
  const [flows, setFlows] = useState([]);
  const [toFlow, setToFlow] = useState(modalData.keywordId ? modalData.toFlow : null);
  const [error, setError] = useState('');

  useEffect(() => {
    if (botId) {
      flowsApi.getFlowsShort(botId).then(res => {
        setFlows(res.flowList);
      });
    }
  }, [botId]);

  const handleCleanKeywordName = () => {
    setKeywordName('');
  };

  const handleConfirm = () => {
    const { keywordId, onClose } = modalData;

    const apiCall = keywordId
      ? keywordsApi.updateKeyWord(keywordId, { name: keywordName, botId })
      : keywordsApi.createKeyWord({ name: keywordName, botId, flow: toFlow.id });

    apiCall
      .then(res => {
        const action = keywordId
          ? bcSplitTableActions.updateItemInSplitTable(res)
          : bcSplitTableActions.createNewItemSuccess(res);

        dispatch(action);
        onClose();
      })
      .catch(err => {
        const connectErr = ConnectError.from(err);

        if (connectErr && connectErr.code === Code.InvalidArgument) {
          setError('A keyword with this name already exists.');
        }
      });
  };

  return (
    <ModalContainer modal={MODALS.createKeywordModal} onClose={modalData.onClose}>
      <div className="keyword-modal">
        <div className="keyword-modal__body">
          <div className="keyword-modal__header">
            {modalData.keywordId
              ? formatMessage('Edit keyword')
              : formatMessage('Create new keyword')}
          </div>
          <div className="keyword-modal__vertical-block">
            <FormField
              label="Name"
              value={keywordName}
              placeholder="Type here..."
              isRequired
              width="100%"
              errorText={error}
              onChange={event => setKeywordName(event.target.value)}
              onCrossClick={handleCleanKeywordName}
            />
          </div>
          {!modalData.keywordId && (
            <div className="keyword-modal__vertical-block">
              <p className="keyword-modal__dropdown__title">Start flow</p>
              <Dropdown
                options={flows}
                singleSelectedOption={toFlow}
                setSingleSelectedOption={setToFlow}
                search
              />
            </div>
          )}
        </div>
        <div className="keyword-modal__body_button">
          <Button
            type="primary"
            size={EButtonSize.L}
            isDisabled={!modalData.keywordId ? !(keywordName && toFlow) : !keywordName}
            onClick={handleConfirm}
          >
            <span className="keyword-modal__text_white">
              {modalData.keywordId ? formatMessage('Save') : formatMessage('Create')}
            </span>
          </Button>
          <Button
            type="secondary"
            style="tint"
            size={EButtonSize.L}
            onClick={modalData.onClose}
          >
            {formatMessage('Cancel')}
          </Button>
        </div>
      </div>
    </ModalContainer>
  );
};
