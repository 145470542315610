import React from 'react';
import classNames from 'classnames';
import { useInternationalization } from '@hooks/useTranslationHook';

import './dateTimePopover.scss';

export const ListItemDateTime = ({ item, active, onClick, isTime }) => {
  const { formatMessage } = useInternationalization();
  return (
    <div
      className={classNames('date-time-popover__body_container_element', {
        'date-time-popover__body_container_element_time': isTime,
      })}
      onClick={onClick}
    >
      <span
        className={classNames({
          'date-time-popover__body_container_element_active': active,
        })}
      >
        {formatMessage(item)}
      </span>
    </div>
  );
};
