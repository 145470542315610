import React, { useEffect, useState } from 'react';
import { useInternationalization } from '@hooks/useTranslationHook';
import { CheckIcon } from '@icons/audience/check-icon';
import { PlusIcon } from '@icons/audience/plus-icon';
import { Popover } from '../../ui-kit/components/Popover/Popover';
import { SearchBlackIcon } from '../../icons/search-black-icon';

import './WabbSelectPopover.scss';

export const WabbSelectPopover = ({
  isOpen,
  placement = 'bottom-start',
  onClose,
  setIsOpen,
  renderTrigger,
  items,
  selectedItemId,
  handleChangeItem,
  isFormatedLabelName,
  handleAddNewItem,
  newItemTitle,
  search,
  labelName = 'name',
  idName = 'id',
  width = 210,
}) => {
  const { formatMessage } = useInternationalization();
  const [searchValue, setSearchValue] = useState('');
  const [filteredItems, setFilteredItems] = useState(items);

  useEffect(() => {
    setFilteredItems(
      items?.filter((item) => item[labelName]?.toLowerCase().includes(searchValue.toLowerCase()),),
    );
  }, [items, searchValue]);

  useEffect(() => {
    if (!isOpen) {
      setSearchValue('');
    }
  }, [isOpen]);

  return (
    <Popover
      renderTrigger={renderTrigger}
      placement={placement}
      size={{ width }}
      controllableOpenState={{ isOpen, setIsOpen }}
    >
      <div className="wabb-popover">
        <div className="wabb-popover__column">
          {search && (
            <div className="wabb-popover__column_search">
              <input
                type="text"
                placeholder={formatMessage('Search')}
                value={searchValue}
                onChange={(event) => setSearchValue(event.target.value)}
              />
              <SearchBlackIcon />
            </div>
          )}
          <div className="wabb-popover__column_body">
            {handleAddNewItem && (
              <div className="wabb-popover__column_body_new" onClick={handleAddNewItem}>
                <span>
                  {formatMessage('Add new')}
                  {' '}
                  {formatMessage(newItemTitle || 'item')}
                </span>
                <PlusIcon />
              </div>
            )}
            {filteredItems?.length > 0 ? (
              filteredItems?.map((item) => (
                <div
                  key={item[idName]}
                  className={
                    item[idName] === selectedItemId
                      ? 'wabb-popover__column_body_element_active'
                      : 'wabb-popover__column_body_element'
                  }
                  onClick={() => {
                    handleChangeItem(item, item[idName]);
                    onClose();
                  }}
                >
                  <span>
                    {isFormatedLabelName
                      ? formatMessage(item[labelName])
                      : item[labelName]}
                  </span>
                  {item[idName] === selectedItemId && <CheckIcon />}
                </div>
              ))
            ) : (
              <div className="wabb-popover__column_body_no-values">
                <span>{formatMessage('No values yet')}</span>
              </div>
            )}
          </div>
        </div>
      </div>
    </Popover>
  );
};
