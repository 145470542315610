import React from 'react';
import Tooltip from '@mui/material/Tooltip';

import './BcTooltip.scss';

export const BcTooltip = ({ className, children, ...props }) => (
  <Tooltip {...props} arrow classes={{ popper: className }}>
    {children}
  </Tooltip>
);
