import React, { useEffect, useState } from 'react';

import { ModalContainer } from '@components/containers/Modal/ModalContainer';
import { useSelector } from 'react-redux';
import { getModalDataSelector } from '@components/containers/Modal/modalSelectors';
import { useInternationalization } from '@hooks/useTranslationHook';
import { MODALS } from '@components/containers/Modal/modalConstants';
import { NewBcField } from '@components/common/fields/newBcField/newBcField';
import { Button } from '@components/common/Button';
import { FranchiseCompanyApi } from '@api/franchiseCompanyApi';
import { ClearingFieldIcon } from '@icons/clearing-field-icon';

import './setUpHelpLinkModal.scss';
import { regExp } from '@components/common/fields/newBcField/newBcFieldHelper';

const linkRegExp = /^(ftp|http|https):\/\/[^ "]+$/;

const isObjectWithoutError = object => Object.values(object).every(value => value === '');

export const SetUpHelpLinkModal = () => {
  const { modalData } = useSelector(getModalDataSelector);
  const { formatMessage } = useInternationalization();

  const updateObj = new FormData();

  const [help, setHelp] = useState({
    link: modalData.helpLink,
  });

  const [helpLinkError, setHelpLinkError] = useState({
    link: '',
  });

  const onClose = () => {
    modalData.onClose();
  };

  const handleChangeSupportEmail = (e, name) => {
    if (e.target && e.target.value) {
      setHelp({ ...help, [name]: e.target.value });
      setHelpLinkError({ ...helpLinkError, [name]: '' });
    } else {
      setHelp({ ...help, [name]: '' });
    }
  };

  const valueFieldsValidation = () => {
    const errorObject = {};

    for (const [key, value] of Object.entries(help)) {
      if (value === '') {
        errorObject[key] = 'This field must not be empty';
      } else if (!linkRegExp.test(String(value).toLowerCase())) {
        errorObject[key] = 'This link is not valid';
      } else {
        errorObject[key] = '';
      }
    }

    return errorObject;
  };

  const handleUpdateSupportEmail = () => {
    const errorObject = valueFieldsValidation();

    if (isObjectWithoutError(errorObject)) {
      updateObj.append('help_center_link', help.link);
      FranchiseCompanyApi.setFranchiseSettings(modalData.id, updateObj).then(
        res => console.log('res', res.data),
        // modalData.setHelpLink(res.data.help_link_center)
      );
      // modalData.setUpdateFranchiseData(true);
      onClose();
    } else {
      setHelpLinkError({ ...help, ...errorObject });
    }
  };

  useEffect(() => {
    modalData.setHelpLink(help.link);
  }, [help.link]);

  return (
    <ModalContainer modal={MODALS.setUpHelpLink} onClose={onClose}>
      <div className="email-support-container">
        <div className="email-support-container_title">{formatMessage('Help Link')}</div>
        <div className="email-support-container_title_border" />
        <div className="email-support-container_field">
          <NewBcField
            name="link"
            value={help.link}
            onChange={handleChangeSupportEmail}
            Icon={<ClearingFieldIcon />}
            helperText={Boolean(helpLinkError.link) && formatMessage(helpLinkError.link)}
          />
        </div>
        <div
          className="email-support-container_button-block"
          onClick={handleUpdateSupportEmail}
        >
          <Button
            color="green"
            width="100%"
            height="56px"
            customClass="email-support-container_button-block_button"
            disabled={help.link === ''}
          >
            {formatMessage('Save')}
          </Button>
        </div>
      </div>
    </ModalContainer>
  );
};
