import React from 'react';
import { useContextSelector } from 'use-context-selector';

import { BcToast } from '@components/common/BcToast/BcToast';
import { ToastContext } from '../../../contexts/toastsContext';

export const BcToastsList = () => {
  const toasts = useContextSelector(ToastContext, ({ toasts }) => toasts);
  return (
    <div>
      {toasts.map(toast => (
        <BcToast key={toast.id} {...toast} />
      ))}
    </div>
  );
};
