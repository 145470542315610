import { all, takeLatest, put, fork } from 'redux-saga/effects';
import { variablesActions } from './variablesActions';
import { constructorActions } from '../constructor/constructorActions';
import { modalActions } from '../../components/containers/Modal/modalActions';
import { variablesApi } from '../../api/variablesApi';
import { constructorApi } from '../../api/constructorApi';
import {  ConnectError, Code } from '@connectrpc/connect';
import {
  GET_VARIABLES_REQUEST,
  GET_BOT_VARIABLES_REQUEST,
  CREATE_VARIABLE_REQUEST,
  REMOVE_FIELD_REQUEST,
  UPDATE_VARIABLES_REQUEST,
  UPDATE_BOT_VARIABLES_REQUEST,
} from './variablesConstants';

function* getVariablesRequestSaga(action, variableId) {
  try {
    const { companyBot, handleSelectVariableEvent } = action.payload;
    const response = yield variablesApi.getAllUserVariables(companyBot);

    if (response.variables) {
      yield put(variablesActions.getVariablesSuccess(response.variables));

      if (handleSelectVariableEvent) {
        yield fork(handleSelectedVariableSaga, action, variableId);
      }
    }
  } catch (err) {}
}

function* getVariablesBotRequestSaga(action) {
  try {
    const { companyBot } = action.payload;
    const response = yield variablesApi.getAllBotVariables(companyBot);

    if (response.variables) {
      yield put(variablesActions.getBotVariablesSuccess(response.variables));
    }
  } catch (err) {}
}

function* createVariableRequestSaga(action) {
  try {
    const { fieldType } = action.payload;
    const response = yield variablesApi.createVariable(action.payload);

    if (response) {
      if (fieldType === 'user') {
        yield fork(getVariablesRequestSaga, action, response.id);
      } else if (fieldType === 'bot') {
        yield fork(getVariablesBotRequestSaga, action);
      }

      yield put(modalActions.closeModal());
    }
  } catch (err) {
    const connectErr = ConnectError.from(e);
    if (connectErr.code === Code.InvalidArgument) {
      yield put(variablesActions.createVariableError(err.response.data.error));
    }
  }
}

function* removeVariableRequestSaga(action) {
  try {
    const { id, fieldType } = action.payload;
    const response = yield variablesApi.removeVariable(id, fieldType);

    if (response) {
      if (fieldType === 'user') {
        yield fork(getVariablesRequestSaga, action);
      } else if (fieldType === 'bot') {
        yield fork(getVariablesBotRequestSaga, action);
      }
    }
  } catch (err) {}
}

function* handleSelectedVariableSaga(action, variableId) {
  try {
    yield put(constructorActions.loadInProgress());
    const { handleSelectVariableEvent } = action.payload;
    const response = yield constructorApi.setUserInputVariable(
      handleSelectVariableEvent,
      variableId,
    );

    if (response.data) {
      yield put(
        constructorActions.updateSelectedFileContentBlock({
          ...response.data,
          type: 'user_input',
        }),
      );
      yield put(constructorActions.loadSuccess());
    }
  } catch (err) {
    yield put(constructorActions.loadFailure());
  }
}

function* updateVariableRequestSaga(action) {
  const data = action.payload;

  try {
    const response = yield variablesApi.updateVariableTypes({
      description: data.description,
      pk: data.id,
    });

    if (response) {
      yield put(variablesActions.updateVariableSuccess(response));
    }
  } catch (err) {
    console.log(err);
  }
}

function* updateBotVariableRequestSaga(action) {
  const data = action.payload;

  try {
    const response = yield variablesApi.updateVariableBot({
      pk: data.id,
      description: data.description,
    });

    if (response) {
      yield put(variablesActions.updateBotVariableSuccess(response));
    }
  } catch (err) {
    console.log(err);
  }
}

function* sagas() {
  yield all([takeLatest(GET_VARIABLES_REQUEST, getVariablesRequestSaga)]);
  yield all([takeLatest(GET_BOT_VARIABLES_REQUEST, getVariablesBotRequestSaga)]);
  yield all([takeLatest(CREATE_VARIABLE_REQUEST, createVariableRequestSaga)]);
  yield all([takeLatest(REMOVE_FIELD_REQUEST, removeVariableRequestSaga)]);
  yield all([takeLatest(UPDATE_VARIABLES_REQUEST, updateVariableRequestSaga)]);
  yield all([takeLatest(UPDATE_BOT_VARIABLES_REQUEST, updateBotVariableRequestSaga)]);
}

export const variablesSagas = sagas;
