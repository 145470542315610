import {
  GET_COMPANIES_REQUEST,
  GET_COMPANIES_FAILURE,
  GET_COMPANIES_SUCCESS,
  SET_SELECTED_COMPANY,
  SET_UPDATED_COMPANY,
  UNLINK_SERVER,
  RELOAD_COMPANY,
  UPDATE_SELECTED_COMPANY,
} from './companiesConstants';

export const companiesActions = {
  getCompaniesRequest(data) {
    return {
      type: GET_COMPANIES_REQUEST,
      payload: data,
    };
  },
  getCompaniesSuccess(data) {
    return {
      type: GET_COMPANIES_SUCCESS,
      payload: {
        data,
      },
    };
  },
  getCompaniesError(error) {
    return {
      type: GET_COMPANIES_FAILURE,
      payload: {
        error,
      },
    };
  },
  setSelectedCompany(data) {
    return {
      type: SET_SELECTED_COMPANY,
      payload: data,
    };
  },
  setUpdatedCompany(updatedCompany) {
    return {
      type: SET_UPDATED_COMPANY,
      payload: {
        updatedCompany,
      },
    };
  },
  unlinkServer(selectedCompanyId) {
    return {
      type: UNLINK_SERVER,
      payload: {
        selectedCompanyId,
      },
    };
  },
  reloadCompany(data) {
    return {
      type: RELOAD_COMPANY,
      payload: data,
    };
  },
  updateSelectedCompany(data) {
    return {
      type: UPDATE_SELECTED_COMPANY,
      payload: data,
    };
  },
};
