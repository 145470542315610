import api from './baseApi';

export const templatesApi = {
  getTemplate: templateId => api.get(`/templates/detail/${templateId}/`),
  getTemplateByShareCode: shareCode =>
    api.get(`/template/detail/?share_code=${shareCode}`),
  createTemplate: data => api.post('/templates/create/', data),
  updateTemplate: (templateId, updateData) =>
    api.patch(`/templates/detail/${templateId}/`, updateData),
  deleteTemplate: templateId => api.delete(`/templates/detail/${templateId}/`),
  installTemplate: data => api.post('/template/install/', data),
  getCategories: () => api.get('/templates/categories/'),
  getAllTemplates: (subscribeType, categoryId) => {
    if ((subscribeType === 0 || subscribeType === 1) && categoryId) {
      return api.get(
        `/templates/all/?pro_status=${subscribeType}&category_id=${categoryId}`,
      );
    }

    if ((subscribeType === 0 || subscribeType === 1) && !categoryId) {
      return api.get(`/templates/all/?pro_status=${subscribeType}`);
    }

    if (categoryId && !subscribeType) {
      return api.get(`/templates/all/?category_id=${categoryId}`);
    }

    return api.get('/templates/all/');
  },
  getMyTemplates: () => api.get('/templates/my_templates/'),
  getRelatedTemplates: templateId =>
    api.get(`/templates/related_templates/${templateId}/`),
  getVariables: flowsId => api.post('/blocks/flow/variables/', { flows_id: flowsId }),
};
