import api, { API_URL_V1 } from './baseApi';

export const groupCampaignsApi = {
  getGroupCampaigns: bot_id => api.get(`${API_URL_V1}/group_campaigns/bot/${bot_id}/`),
  getGroupCampaignsWithGroups: bot_id =>
    api.get(`${API_URL_V1}/group_campaigns/groups/bot/${bot_id}/`),
  updateGroupCampaigns: (group_id, data) =>
    api.patch(`${API_URL_V1}/group_campaigns/${group_id}/`, data),
  getDetailGroupCampaign: id => api.get(`${API_URL_V1}/group_campaigns/${id}/`),
  getDetailGroupCampaignWithGroups: id =>
    api.get(`${API_URL_V1}/group_campaigns/groups/${id}/`),
  createGroupCampaign: data => api.post(`${API_URL_V1}/group_campaigns/`, data),
  deleteGroupCampaign: id => api.delete(`${API_URL_V1}/group_campaigns/${id}/`),
  getGroupCampaignStatistic: groupId =>
    api.get(`${API_URL_V1}/group_campaigns/${groupId}/statistic/`),
  createBroadcastsMessage: data =>
    api.post(`${API_URL_V1}/group_broadcast_messages/`, data),
  updateBroadcastsMessage: (messageId, data) =>
    api.patch(`${API_URL_V1}/group_broadcast_messages/${messageId}/`, data),
  deleteBroadcastMessage: messageId =>
    api.delete(`${API_URL_V1}/group_broadcast_messages/${messageId}/`),
  getBroadcasts: groupCampaignId =>
    api.get(`${API_URL_V1}/group_broadcasts/group_campaign/${groupCampaignId}/`),
  createBroadcast: data => api.post(`${API_URL_V1}/group_broadcasts/`, data),
  createPayment: data => api.post(`${API_URL_V1}/payment_webhook/create/`, data),
  getPayments: botId => api.get(`${API_URL_V1}/payment_webhook/${botId}/`),
  deletePayment: paymentId =>
    api.delete(`${API_URL_V1}/payment_webhook/detail/${paymentId}/`),
  updatePayment: (paymentId, form) =>
    api.put(`${API_URL_V1}/payment_webhook/detail/${paymentId}/`, form),
  getSales: groupCampaignId =>
    api.get(`${API_URL_V1}/group_campaigns/sales/${groupCampaignId}/`),
  getSalesStatistic: groupCampaignId =>
    api.get(`${API_URL_V1}/group_campaigns/sales/stat/${groupCampaignId}/`),
};
