import React from 'react';

export const ArrowIcon = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g id="Arrows/right-small">
      <g id="Vector">
        <path
          d="M4.375 10.0035C4.375 9.65828 4.65482 9.37846 5 9.37846H15C15.3452 9.37846 15.625 9.65828 15.625 10.0035C15.625 10.3486 15.3452 10.6285 15 10.6285H5C4.65482 10.6285 4.375 10.3486 4.375 10.0035Z"
          fill="#101828"
        />
        <path
          d="M9.55806 4.55806C9.80214 4.31398 10.1979 4.31398 10.4419 4.55806L15.4419 9.55806C15.686 9.80214 15.686 10.1979 15.4419 10.4419L10.4419 15.4419C10.1979 15.686 9.80214 15.686 9.55806 15.4419C9.31398 15.1979 9.31398 14.8021 9.55806 14.5581L14.1161 10L9.55806 5.44194C9.31398 5.19786 9.31398 4.80214 9.55806 4.55806Z"
          fill="#101828"
        />
      </g>
    </g>
  </svg>
);
