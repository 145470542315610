import { GET_GROUPS_SUCCESS, GET_GROUPS_REQUEST } from './groupsConstants';

export const groupsActions = {
  getGroupsRequest(data) {
    return {
      type: GET_GROUPS_REQUEST,
      payload: data,
    };
  },

  getGroupsSuccess(data) {
    return {
      type: GET_GROUPS_SUCCESS,
      payload: {
        data,
      },
    };
  },
};
