import React from 'react';

export const PlusConstEditPannel = ({ color = '#4A1FB8' }) => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.0343 5C12.4886 5.0006 12.8564 5.36936 12.8558 5.82366L12.8357 18.1785C12.8351 18.6328 12.4663 19.0006 12.012 19C11.5577 18.9994 11.1899 18.6306 11.1905 18.1763L11.2107 5.8215C11.2113 5.3672 11.58 4.99941 12.0343 5Z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5 12C5 11.5457 5.36828 11.1774 5.82258 11.1774L18.1774 11.1774C18.6317 11.1774 19 11.5457 19 12C19 12.4543 18.6317 12.8226 18.1774 12.8226L5.82258 12.8226C5.36828 12.8226 5 12.4543 5 12Z"
      fill={color}
    />
  </svg>
);
