import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useContextSelector } from 'use-context-selector';

import { AudienceFiltersMenuArrowIcon } from '@icons/audience/audience-filters-menu-arrow-icon';
import { useInternationalization } from '@hooks/useTranslationHook';
import { getSelectedCompanySelector } from '@redux/companies/companiesSelectors';
import { getQueryString } from '@redux/audience/audienceSelectors';
import { audienceApi } from '@api/audience';
import { ToastContext } from '../../../../../../contexts/toastsContext';

import '../filtersPopover.scss';

export const OptionsList = ({ planType, options, setStep, broadcasts }) => {
  const { formatMessage } = useInternationalization();
  const addToast = useContextSelector(ToastContext, ({ add }) => add);

  const botId = useSelector(getSelectedCompanySelector).bot;
  const queryString = useSelector(getQueryString);
  const [anchorEl, setAnchorEl] = useState(null);

  const handleSaveFilters = name => {
    audienceApi
      .saveFilters(botId, {
        name,
        bot: botId,
        filters: queryString.replace(/search_name=[^&]+&/, '&'),
      })
      .then(() => {
        addToast({
          type: 'success',
          title: formatMessage('Filters successfully saved'),
          message: formatMessage('Filters successfully saved'),
        });
      });
  };

  return (
    <div className="filters-popover">
      {/*<div*/}
      {/*  className={`filters-popover__element${planType !== 1 ? '_static' : ''}`}*/}
      {/*  onClick={() => {*/}
      {/*    planType === 1 && setStep('savedFilters');*/}
      {/*  }}*/}
      {/*>*/}
      {/*  <span>{formatMessage('Saved Searches')}</span>*/}
      {/*  {planType === 1 ? <AudienceFiltersMenuArrowIcon /> : <OrangeProPlanIcon />}*/}
      {/*</div>*/}
      {/*{planType === 1 && !broadcasts && (*/}
      {/*  <div className="filters-popover__element">*/}
      {/*    <AudienceButton*/}
      {/*      customClass="filters-popover__save-button"*/}
      {/*      onClick={event => setAnchorEl(event.currentTarget)}*/}
      {/*    >*/}
      {/*      {formatMessage('Save these filters')}*/}
      {/*    </AudienceButton>*/}
      {/*    <CreateItemPopover*/}
      {/*      anchorEl={anchorEl}*/}
      {/*      onClose={() => {*/}
      {/*        setAnchorEl(null);*/}
      {/*      }}*/}
      {/*      confirmCreateFunction={handleSaveFilters}*/}
      {/*      label={formatMessage('Save filters')}*/}
      {/*      placeholder={formatMessage('Name for these filters')}*/}
      {/*    />*/}
      {/*  </div>*/}
      {/*)}*/}
      {/*<div className="filters-popover__element_static">*/}
      {/*  <span className="filters-popover__label">{formatMessage('ADD FILTERS')}</span>*/}
      {/*</div>*/}
      {options.map(item => (
        <div
          key={item.label}
          className="filters-popover__element"
          onClick={() => {
            setStep(item.label);
          }}
        >
          <span>{formatMessage(item.label)}</span>
          <AudienceFiltersMenuArrowIcon />
        </div>
      ))}
    </div>
  );
};
