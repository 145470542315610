import React from 'react';

export const SelectedFolderIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M1.66651 4.25659C1.66651 3.51648 2.27387 2.9165 3.0231 2.9165H8.06185C8.2344 2.9165 8.39803 2.99221 8.50849 3.12315L10.2721 5.21379H15.2324C15.9816 5.21379 16.589 5.81376 16.589 6.55388V8.85117C16.589 9.16836 16.3287 9.42549 16.0076 9.42549H4.63983L2.81194 16.6481C2.74073 16.9295 2.46829 17.1143 2.17687 17.0788C1.88544 17.0434 1.66651 16.7989 1.66651 16.5088V4.25659ZM3.0231 4.06515C2.91607 4.06515 2.8293 4.15086 2.8293 4.25659V11.8435L3.62185 8.71188C3.68656 8.45621 3.9191 8.27685 4.18589 8.27685H15.4262V6.55388C15.4262 6.44814 15.3394 6.36244 15.2324 6.36244H9.99984C9.82729 6.36244 9.66366 6.28673 9.5532 6.15579L7.78954 4.06515H3.0231Z"
      fill="#667085"
    />
    <path
      d="M16.2014 16.5088L17.7518 8.85117H4.11321L2.2479 16.5088H16.2014Z"
      fill="#667085"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M3.54795 8.71681C3.61081 8.45874 3.84451 8.27685 4.11321 8.27685H17.7518C17.926 8.27685 18.091 8.35399 18.2014 8.48706C18.3118 8.62012 18.356 8.79508 18.3219 8.96381L16.7715 16.6214C16.7171 16.8899 16.4785 17.0831 16.2014 17.0831H2.2479C2.06977 17.0831 1.90147 17.0025 1.79123 16.8643C1.68099 16.726 1.64097 16.5455 1.68264 16.3744L3.54795 8.71681ZM4.57131 9.42549L2.98579 15.9345H15.7248L17.0426 9.42549H4.57131Z"
      fill="#667085"
    />
  </svg>
);
