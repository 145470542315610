import React from 'react';
import Select from 'react-select';

import { useInternationalization } from '@hooks/useTranslationHook';
import '../../../styles/variables.scss';
import Check from '../../../wabb-assets/svg/Dropdown/check.svg';

export const BcSelect = ({
  outlined,
  width = '100%',
  customStyle,
  color,
  value,
  options,
  onChange,
  isValid,
  ...otherProps
}) => {
  const { formatMessage } = useInternationalization();

  const customStyles = {
    indicatorSeparator: () => ({
      width: '0px',
    }),
    container: styles => ({
      ...styles,
      width,
      height: '40px',
    }),
    menu: styles => ({
      ...styles,
      bottom:'auto',
      zIndex: '4',
    }),
    imput: styles => ({
      ...styles,
      fontFamily: 'Inter',
      fontSize: '14px',
      fontStyle: 'normal',
      fontWeight: 400,
      lineHeight: '20px',
    }),
    option: (styles, { data, isSelected }) => {
      const isSpecialOption = data.value === -1; // Вынесенное условие

      return {
        ...styles,
        display: 'flex',
        alignItems: 'center',
        padding: '8px 12px',
        borderTop: isSpecialOption ? '1px solid #E4E7EC' : 'none',
        borderBottom: isSpecialOption ? '1px solid #E4E7EC' : 'none',
        backgroundColor: isSpecialOption ? '#F2F4F7' : (isSelected ? '#F2F4F7' : '#FFFFFF'),
        color: isSpecialOption ? '#667085' : '#101828',
        cursor: 'pointer',
        '&:hover': {
          backgroundColor: '#F2F4F7',
        },
        '&:active': {
          backgroundColor: isSelected ? '#F2F4F7' : '#f5f5f5',
        },
        fontFamily: 'Inter',
        fontSize: '14px',
        fontStyle: 'normal',
        textTransform: isSpecialOption ? 'uppercase' : 'none',
        fontWeight: 400,
        lineHeight: '20px',
      };
    },
    singleValue: styles => ({
      ...styles,
      display: 'flex',
      alignItems: 'center',
    }),
    dropdownIndicator: (styles, { selectProps: { menuIsOpen } }) => ({
      ...styles,
      transition: 'transform 0.2s ease-in-out',
      transform: menuIsOpen ? 'rotate(180deg)' : 'rotate(0deg)',
    }),
    menuList: styles => ({
      ...styles,
      paddingBottom: '0px',
      paddingTop: '0px',
    }),
    control: (styles, state) => ({
      ...styles,
      height: '40px',
      borderRadius: '4px',
      border: '1px solid #D0D5DD',
      outline: state.menuIsOpen || state.isFocused ? '4px solid #ebe9fe' : '',
      borderColor: isValid
        ? 'red'
        : state.isFocused
        ? '#8061FF !important'
        : outlined
        ? '#E5E5E5'
        : '#F4F4F4',
      backgroundColor: '#FFFF',
      boxShadow: state.isFocused || state.isSelected ? 'none' : 'none',
      '&:hover': {
        borderColor: '#D0D5DD',
      },
      cursor: 'pointer',
    }),
    ...customStyle,
  };

  const formatOptionLabel = ({ label, value }, { context, selectValue }) => (
    <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
      <span>{label}</span>
      {context === 'menu' && selectValue.some(val => val.value === value) && (
        <img src={Check} />
      )}
    </div>
  );

  return (
    <Select
      styles={customStyles}
      value={value}
      onChange={onChange}
      formatOptionLabel={formatOptionLabel}
      options={options}
      menuPlacement="auto"
      placeholder={formatMessage('Select')}
      {...otherProps}
    />
  );
};
