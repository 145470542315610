import React from 'react';
import { clsx } from 'clsx';
import styles from './SearchSelect.module.scss';
import LoopIcon from '../../../wabb-assets/svg/Dropdown/search.svg';
import InputCross from '../../../wabb-assets/svg/FormField/input-cross.svg';

interface SearchSelectProps {
  searchTerm: string;
  setSearchTerm: (term: string) => void;
  hasSelectedOptions: boolean;
  isHeader?: boolean;
}

const SearchSelect: React.FC<SearchSelectProps> = ({
  searchTerm,
  setSearchTerm,
  isHeader,
}) => (
  <div className={styles.inputContainer}>
    <input
      type="text"
      placeholder="Search"
      value={searchTerm}
      onChange={e => setSearchTerm(e.target.value)}
      className={clsx(styles.dropdownSearch, {
        [styles.dropdownSearchHeader]: isHeader,
      })}
    />
    {searchTerm ? (
      <div onClick={() => setSearchTerm('')} className={styles.iconRight}>
        <img src={InputCross} />
      </div>
    ) : (
      <div className={styles.iconRight}>
        <img src={LoopIcon} />
      </div>
    )}
  </div>
);

export default SearchSelect;
