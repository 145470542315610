import React, { useEffect, useState } from 'react';
import cn from 'classnames';
import { Popover } from '@ui-kit';
import classNames from 'classnames';
import Button from '@wabb-ui-kit/Button/Button';
import { EButtonSize, EIconPosition } from '@wabb-ui-kit/Button/types/types';
// import { BotConversaLogoSmall } from '@icons/bot-conversa-logo-small';
import { ArrowIcon } from '@wabb-assets/svg/Button/arrow-icon';
import { BcSelect } from '@components/common/BcSelect/BcSelect';
import { useInternationalization } from '@hooks/useTranslationHook';
import { useContextSelector } from 'use-context-selector';
import { FranchiseContext } from '../../../../../contexts/franchiseContext';

import './UserCardInfoPausePanel.scss';

const minutes = Array.from({ length: 99 }, (v, i) => i + 1).map(min => ({
  name: min.toString(),
  id: min,
}));

const PAUSE_CASES = {
  PauseByTimer: 'Pause by timer',
  StopIndefinitely: 'Stop indefinitely',
  StartByTimer: 'Start by timer',
  StartImmediately: 'Start immediately',
};

export const UserCardInfoPausePanel = ({ data, updateUserCard, isContacts }) => {
  const { formatMessage } = useInternationalization();
  const [isOpen, setIsOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [pauseTime, setPauseTime] = useState({
    id: 1,
    name: 1,
  });
  const code = useContextSelector(FranchiseContext, frData => frData.code);
  const [pauseTimeType, setPauseTimeType] = useState({
    id: 'Minutes',
    name: formatMessage('Minutes'),
  });
  const [isPauseEnabled, setIsPauseEnabled] = useState(false);

  useEffect(() => {
    setIsPauseEnabled(data?.isAutomationPausedEnabled);
  }, [data.isAutomationPausedEnabled]);

  useEffect(() => {
    if (data.automationPausedFor && isPauseEnabled) {
      let timeDiff = data.automationPausedFor;

      if (timeDiff / (60 * 60 * 24) > 1) {
        timeDiff = Math.ceil(timeDiff / (60 * 60 * 24));
        setPauseTimeType({
          id: 'Days',
          name: formatMessage('Days'),
        });
      } else if (timeDiff / (60 * 60) > 1) {
        timeDiff = Math.ceil(timeDiff / (60 * 60));
        setPauseTimeType({
          id: 'Hours',
          name: formatMessage('Hours'),
        });
      } else {
        timeDiff = Math.ceil(timeDiff / 60);
        setPauseTimeType({
          id: 'Minutes',
          name: formatMessage('Minutes'),
        });
      }

      setPauseTime({
        id: timeDiff,
        name: timeDiff,
      });
    }
  }, [isPauseEnabled, data.automationPausedFor]);

  const calculateTimeStart = () => {
    if (pauseTimeType?.id === 'Minutes') {
      return Number(pauseTime.name) * 60;
    }

    if (pauseTimeType.id === 'Hours') {
      return Number(pauseTime.name) * 60 * 60;
    }

    return Number(pauseTime.name) * 24 * 60 * 60;
  };

  const handlePause = typeButton => {
    switch (typeButton) {
      case PAUSE_CASES.PauseByTimer:
        updateUserCard({
          is_automation_paused_enabled: true,
          automation_paused_for: calculateTimeStart(),
        });
        setIsPauseEnabled(true);
        setIsOpen(false);
        break;
      case PAUSE_CASES.StopIndefinitely:
        updateUserCard({
          is_automation_paused_enabled: true,
          automation_paused_till: '',
          automation_paused_for: 0,
        });
        setIsPauseEnabled(true);
        setPauseTime({
          id: 1,
          name: 1,
        });
        setPauseTimeType({
          id: 'Minutes',
          name: 'Minutes',
        });
        setIsOpen(false);
        break;
      case PAUSE_CASES.StartByTimer:
        updateUserCard({
          is_automation_paused_enabled: true,
          automation_paused_for: calculateTimeStart(),
        });
        setIsPauseEnabled(true);
        setIsOpen(false);
        break;
      case PAUSE_CASES.StartImmediately:
        updateUserCard({
          is_automation_paused_enabled: false,
          automation_paused_till: '',
          automation_paused_for: 0,
        });
        setIsPauseEnabled(false);
        setPauseTime({
          id: 1,
          name: 1,
        });
        setPauseTimeType({
          id: 'Minutes',
          name: 'Minutes',
        });
        setIsOpen(false);
        break;
      default:
    }

    setAnchorEl(null);
  };

  return (
    <div className="user-card-info-pause-panel">
      <div
        className={cn('user-card-info-pause-panel__container', {
          'user-card-info-pause-panel__container--contacts': isContacts,
        })}
      >
        <div
          className={classNames({
            'user-card-info-pause-panel__bot-paused': isPauseEnabled,
            'user-card-info-pause-panel__bot-working': !isPauseEnabled,
          })}
        >
          <div className="user-card-info-pause-panel__text-wrapper">
            <span className="user-card-info-pause-panel__text-wrapper-title">
              {isPauseEnabled && data.automationPausedTill
                ? formatMessage('Wabb is paused')
                : isPauseEnabled
                  ? formatMessage('Wabb is not working')
                  : formatMessage('Wabb is working')}
            </span>
            {isPauseEnabled && !data.automationPausedTill ? (
              <span className="user-card-info-pause-panel__text-wrapper-description">
                {formatMessage('Automation is off')}
              </span>
            ) : (
              <>
                {!(
                  data.automationPausedTill === null && data.automationPausedFor === null
                ) && (
                  <div>
                    <span className="user-card-info-pause-panel__text-wrapper-description">
                      {formatMessage('Will start in ')}
                    </span>
                    <span className="user-card-info-pause-panel__time">
                      {`${pauseTime.name} `}
                      {formatMessage(
                        Number(pauseTime.name) === 1
                          ? pauseTimeType.name.toLowerCase().slice(0, -1)
                          : pauseTimeType.name.toLowerCase(),
                      )}
                    </span>
                  </div>
                )}
              </>
            )}
          </div>
        </div>
        <div className="user-card-info-pause-panel__buttons-right">
          <Popover
            size={{ width: 'auto' }}
            controllableOpenState={{ isOpen, setIsOpen }}
            renderTrigger={() => (
              <div className="user-card-info-pause-panel__btns-wrapper">
                <Button
                  type="secondary"
                  style="outlined"
                  size={EButtonSize.S}
                  icon={<ArrowIcon rotate={anchorEl ? 180 : 0} color="#202F45" />}
                  iconPosition={EIconPosition.RIGHT}
                  onClick={event => setAnchorEl(event.currentTarget)}
                  width="140px"
                >
                  {isPauseEnabled ? (
                    <span>{formatMessage('Start delayed ')}</span>
                  ) : (
                    <span>{formatMessage('Pause for')}</span>
                  )}
                </Button>
              </div>
            )}
          >
            <div className="user-card-info-pause-panel__popover">
              <div className="user-card-info-pause-panel__popover__selects">
                <BcSelect
                  values={minutes}
                  selectedItem={pauseTime}
                  handleChangeItem={value => {
                    setPauseTime(value);
                  }}
                  height={40}
                />
                <BcSelect
                  values={[
                    {
                      id: 'Minutes',
                      name: formatMessage('Minutes'),
                    },
                    {
                      id: 'Hours',
                      name: formatMessage('Hours'),
                    },
                    {
                      id: 'Days',
                      name: formatMessage('Days'),
                    },
                  ]}
                  handleChangeItem={value => setPauseTimeType(value)}
                  selectedItem={pauseTimeType}
                  height={40}
                />
              </div>
              <div className="user-card-info-pause-panel__popover_buttons">
                {!isPauseEnabled && !data.automationPausedTill ? (
                  <Button
                    type="primary"
                    onClick={() => handlePause(PAUSE_CASES.PauseByTimer)}
                    width="100%"
                    height="42px"
                  >
                    <span>{formatMessage('Pause automation by timer')}</span>
                  </Button>
                ) : isPauseEnabled ? (
                  <>
                    <Button
                      type="primary"
                      onClick={() => handlePause(PAUSE_CASES.StartByTimer)}
                      width="100%"
                      height="42px"
                    >
                      <span>{formatMessage('Start automation by timer')}</span>
                    </Button>
                    <Button
                      type="negative"
                      onClick={() => handlePause(PAUSE_CASES.StopIndefinitely)}
                      width="100%"
                      height="42px"
                    >
                      <span>{formatMessage('Stop indefinitely')}</span>
                    </Button>
                  </>
                ) : null}
              </div>
            </div>
          </Popover>
          {isPauseEnabled && (
            <Button
              type="primary"
              style="outlined"
              onClick={() => handlePause(PAUSE_CASES.StartImmediately)}
              size={EButtonSize.S}
              height="28px"
            >
              <span>{formatMessage('Start')}</span>
            </Button>
          )}
          {!isPauseEnabled && !data.automationPausedTill && (
            <div className="user-card-info-pause-panel__btns-wrapper">
              <Button
                type="negative"
                style="outlined"
                onClick={() => handlePause(PAUSE_CASES.StopIndefinitely)}
                size={EButtonSize.S}
                // width={45}
                height="28px"
              >
                <span>{formatMessage('Stop')}</span>
              </Button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
