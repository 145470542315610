import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { PlusIcon } from '@wabb-assets/svg/Button/plus-icon';
import { MarkerItem } from '@components/common/UserCardComponents/MarkerItem/MarkerItem';
import { useInternationalization } from '@hooks/useTranslationHook';
import { MarkerItemVariable } from '@components/common/UserCardComponents/MarkerItemVariable/MarkerItemVariable';
import { getSelectedCompanySelector } from '@redux/companies/companiesSelectors';
import TagsDropdown from '@wabb-ui-kit/TagsDropdown/TagsDropdown';
import { audienceApi } from '@api/audience';
import { tagTypesApi } from '@api/tagTypesApi';
import { sequencesApi } from '@api/sequencesApi';
import { adsSourceApi } from '@api/adsSourceApi';
import { variablesApi } from '@api/variablesApi';

import './EntityList.scss';

export const EntityList = ({ label, markerItems, userId }) => {
  const { formatMessage } = useInternationalization();
  const company = useSelector(getSelectedCompanySelector);

  const typeOfEntity = label !== 'Campaigns' ? label.toLowerCase().slice(0, -1) : 'ads_source';

  const [markers, setMarkers] = useState([]);
  const [items, setItems] = useState(null);
  const [createVariable, setCreateVariable] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    setMarkers(markerItems);
    setCreateVariable(false);
  }, [markerItems]);

  useEffect(() => {
    if (isOpen) {
      getEntities(typeOfEntity);
    }
  }, [isOpen]);

  const handleRemoveMarker = (idMarker) => {
    if (idMarker === null) {
      setCreateVariable(false);
    } else {
      audienceApi.removeMarker(userId, company.bot, idMarker, typeOfEntity).then(() => {
        setMarkers(markers.filter((el) => el.id !== idMarker));
        setItems(null);
      });
    }
  };

  const handleUpdateVariableMarker = (item) => {
    if (item.value) {
      audienceApi
        .editVariable(userId, company.bot, item.id, item.value)
        .then((res) => {
          setMarkers(markers.map((el) => (el.id === item.id ? res : el)));
        });
    }
  };

  const handleAddEntity = (item) => {
    if (typeOfEntity !== 'variable' || item.value) {
      audienceApi
        .addMarker(
          userId,
          company.bot,
          item.type ? item.type.id : item.id,
          typeOfEntity,
          item.value,
        )
        .then((res) => {
          setMarkers([...markers, res]);
          setCreateVariable(false);
        });
    } else {
      setCreateVariable({
        id: null,
        type: {
          id: item.id,
          key: item.key,
          type: item.type,
        },
        value: '',
      });
    }

    setItems(items.filter((el) => item.id !== el.id));
  };

  const getEntities = (type) => {
    switch (type) {
      case 'tag':
        if (items === null) {
          tagTypesApi.getTagTypesShort(company.bot).then((res) => {
            const markersId = markers.map((el) => el.tagType.id);
            setItems(res.tagTypes.filter((el) => !markersId.includes(el.id)));
          });
        }

        break;
      case 'sequence':
        if (items === null) {
          sequencesApi.getSequencesShort(company.bot).then((res) => {
            const markersId = markers.map((el) => el.sequenceType.id);
            setItems(res.shortSequence.filter((el) => !markersId.includes(el.id)));
          });
        }

        break;
      case 'ads_source':
        if (items === null) {
          adsSourceApi.getAdsSourcesShort(company.bot).then((res) => {
            const markersId = markers.map((el) => el.id);
            setItems(res.adsSources.filter((el) => !markersId.includes(el.id)));
          });
        }

        break;
      case 'variable':
        if (items === null) {
          variablesApi.getAllUserVariables(company.bot).then((res) => {
            const markersId = markers.map((el) => el.type.id);
            setItems(res.variables.filter((el) => !markersId.includes(el.id)));
          });
        }

        break;
      default:
    }
  };

  const getPopoverStyle = (label) => {
    switch (label) {
      case 'Tags':
        return { top: '-8px' };
      case 'Sequences':
        return { top: '-38px' };
      case 'Campaigns':
        return { top: '-100px' };
      case 'Variables':
        return { top: '-150px' };
      default:
        return {};
    }
  };

  return (
    <div className="content-detail-new">
      <div className="content-detail-new__header">
        <div className="content-detail-new__header__label">
          {formatMessage(label)}
          {' '}
          {markers.length > 0 && markers.length}
        </div>
        <div
          onClick={() => {
            setIsOpen(true);
          }}
          className="content-detail-new__header__label__add"
        >
          <PlusIcon color="#4F55EC" />
        </div>
        {isOpen && items !== null && (
          <div className="content-detail-new__header_popover" role="presentation" style={getPopoverStyle(label)}>
            <TagsDropdown
              options={items}
              setOptions={setItems}
              isHeader
              headerTitle={`Add ${label}`}
              isOpen={isOpen}
              setIsOpen={setIsOpen}
              handleChangeItem={handleAddEntity}
              labelName={typeOfEntity === 'variable' ? 'key' : 'name'}
              idName="id"
            />
          </div>
        )}
      </div>
      <div className="content-detail-new__item-list">
        {markers?.map((marker) => (typeOfEntity === 'variable' ? (
          <MarkerItemVariable
            marker={marker}
            removeMarker={handleRemoveMarker}
            updateVariableMarker={handleUpdateVariableMarker}
            key={marker.id}
          />
        ) : (
          <MarkerItem
            marker={marker}
            removeMarker={handleRemoveMarker}
            key={marker.id}
          />
        )),)}
        {createVariable && (
          <MarkerItemVariable
            marker={createVariable}
            removeMarker={handleRemoveMarker}
            updateVariableMarker={handleAddEntity}
            isVariableMarker
          />
        )}
      </div>
    </div>
  );
};
