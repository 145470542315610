import React, { FC, useState } from 'react';
import { clsx } from 'clsx';

import CheckBox from '@wabb-ui-kit//CheckBox/CheckBox';

import styles from './RadioGroup.module.scss';

interface IRadioOption {
  id: number;
  title: string;
  description?: string;
  isDisabled?: boolean;
  isChecked?: boolean;
  groupName: string;
}

interface IRadioGroupProps {
  options: IRadioOption[];
  defaultValue: number;
  column: boolean;
  onChange: (id: number) => void;
}

const RadioGroup: FC<IRadioGroupProps> = ({
  options,
  onChange,
  defaultValue,
  column,
}) => {
  const [selectedId, setSelectedId] = useState<number | null>(defaultValue);

  const handleChange = (id: number): void => {
    new Promise<void>(resolve => {
      onChange(id);
      resolve();
    }).then(() => {
      setSelectedId(id);
    });
  };

  const classes = clsx(styles['radio-group__list'], {
    [styles.column]: column,
  });

  return (
    <div className={classes}>
      {options.map(({ title, description, groupName, id, isDisabled, isChecked }) => {
        return (
          <div
            className="radio-group__item"
            key={id}
            role="radio"
            aria-checked={selectedId === id}
          >
            <CheckBox
              type="radio"
              onChange={() => handleChange(id)}
              title={title}
              description={description}
              name={groupName}
              isChecked={selectedId === id || isChecked}
              isDisabled={isDisabled}
            />
          </div>
        );
      })}
    </div>
  );
};

export default RadioGroup;
