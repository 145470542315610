import React, { useEffect, useState } from 'react';
import { EditIcon } from '@wabb-assets/svg/Button/edit-icon';
import { audienceApi } from '@api/audience';
import { audienceActions } from '@redux/audience/audienceActions';
import { EntityList } from '@components/common/UserCardComponents/EntityList/EntityList';
import { EditingField } from '@components/common/EditingField/EditingField';
import { getSelectedCompanySelector } from '@redux/companies/companiesSelectors';
import { useDispatch, useSelector } from 'react-redux';
import { Popover } from '@ui-kit';
import { Actions } from './Actions/Actions';

import '../UserCardHeader/UserCardHeader.scss';

const CustomEditIcon = () => <EditIcon height={22} width={22} viewBox="0 0 26 26" />;

export const UserCardEntities = ({
  data,
  setData,
  fullName,
  id,
  updateUserCard,
  ownerCompanyId,
  managers,
  isContacts,
}) => {
  const [contactFullName, setContactFullName] = useState('');
  const botId = useSelector(getSelectedCompanySelector).bot;
  const dispatch = useDispatch();

  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    setContactFullName(fullName);
  }, [fullName]);

  const handleUpdateContactName = value => {
    setContactFullName(value);
    audienceApi
      .updateUserCard(id, botId, { fullName: value })
      .then(res => {
        dispatch(audienceActions.updateUserFullName({ id, fullName: res.fullName }));
        setData(res);
        setIsOpen(false);
      });
  };

  return (
    <div className="current-user-card-entity-lists">
      {isContacts && (
        <div>
          <div className="user-card-header__name">
            <div className="user-card-header__name-info">
              <div className="user-card-header__name-info-title">{contactFullName}</div>
              <div className="user-card-header__name-info-id">{id}</div>
            </div>
            <Popover
              size={{ width: 'auto' }}
              controllableOpenState={{ isOpen, setIsOpen }}
              renderTrigger={() => (
                <div
                  onClick={() => setIsOpen(true)}
                  className="user-card-header__name-edit"
                >
                  <EditIcon />
                </div>
              )}
            >
              <EditingField
                onClose={() => setIsOpen(false)}
                width={300}
                onClick={handleUpdateContactName}
                value={contactFullName}
                maxLength={50}
              />
            </Popover>
          </div>
          <div>
            <Actions
              data={data}
              updateUserCard={updateUserCard}
              ownerCompanyId={ownerCompanyId}
              managers={managers}
              isContacts={true}
            />
          </div>
        </div>
      )}

      <EntityList key="Tags" label="Tags" markerItems={data.tags} userId={data.id} />
      <EntityList
        key="Sequences"
        label="Sequences"
        markerItems={data.sequences}
        userId={data.id}
      />
      <EntityList
        key="Campaigns"
        label="Campaigns"
        markerItems={data.adsSources}
        userId={data.id}
      />
      <EntityList
        key="Variables"
        label="Variables"
        markerItems={data.variableSet}
        userId={data.id}
      />
    </div>
  );
};
