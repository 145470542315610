import { SET_SELECTED_KEYS_LIST, SET_SELECTED_KEY } from './webhooksConstants';

const initialState = {
  selectedKeys: {},
};

export const webhooksReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_SELECTED_KEYS_LIST:
      return {
        ...state,
        selectedKeys: { ...state.selectedKeys, ...action.payload },
      };

    case SET_SELECTED_KEY:
      return {
        ...state,
        selectedKeys: state.selectedKeys[action.payload.key]
          ? {
              ...state.selectedKeys,
              [action.payload.key]:
                state.selectedKeys[action.payload.key] + action.payload.value,
            }
          : { ...state.selectedKeys, [action.payload.key]: action.payload.value },
      };

    default:
      return state;
  }
};
